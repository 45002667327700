/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MapTemplateRequestTemplateFieldMapInner } from './MapTemplateRequestTemplateFieldMapInner';
import {
    MapTemplateRequestTemplateFieldMapInnerFromJSON,
    MapTemplateRequestTemplateFieldMapInnerFromJSONTyped,
    MapTemplateRequestTemplateFieldMapInnerToJSON,
} from './MapTemplateRequestTemplateFieldMapInner';

/**
 * 
 * @export
 * @interface MapTemplateRequest
 */
export interface MapTemplateRequest {
    /**
     * 
     * @type {Array<MapTemplateRequestTemplateFieldMapInner>}
     * @memberof MapTemplateRequest
     */
    templateFieldMap: Array<MapTemplateRequestTemplateFieldMapInner>;
}

/**
 * Check if a given object implements the MapTemplateRequest interface.
 */
export function instanceOfMapTemplateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateFieldMap" in value;

    return isInstance;
}

export function MapTemplateRequestFromJSON(json: any): MapTemplateRequest {
    return MapTemplateRequestFromJSONTyped(json, false);
}

export function MapTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldMap': ((json['templateFieldMap'] as Array<any>).map(MapTemplateRequestTemplateFieldMapInnerFromJSON)),
    };
}

export function MapTemplateRequestToJSON(value?: MapTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldMap': ((value.templateFieldMap as Array<any>).map(MapTemplateRequestTemplateFieldMapInnerToJSON)),
    };
}

