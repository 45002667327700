import { Heading, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer } from "../../components/Spacer";
import { StyleSheet, View } from "react-native";
import type { OPTestProps } from "@src/types/TestProps";

type Props = {
  readonly testProps: OPTestProps;
};

export const ColumnsHeader = ({ testProps }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    fieldWrapper: {
      width: 208,
    },
  });

  return (
    <View style={styles.container}>
      <View style={{ width: 244 }}>
        <Heading
          level="5"
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}FieldNameHeader`,
          }}
        >
          Field name
        </Heading>
      </View>
      <HorizontalSpacer />
      <View style={styles.fieldWrapper}>
        <Heading
          level="5"
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}MappedHeader`,
          }}
        >
          Mapped to
        </Heading>
      </View>
      <HorizontalSpacer size={48} />
      <View style={styles.fieldWrapper}>
        <Heading
          level="5"
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}SourceHeader`,
          }}
        >
          Source
        </Heading>
      </View>
      <HorizontalSpacer />
      <View style={styles.fieldWrapper}>
        <Heading
          level="5"
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}FieldHeader`,
          }}
        >
          Field
        </Heading>
      </View>
    </View>
  );
};
