/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDatasourceStatistics200Response
 */
export interface GetDatasourceStatistics200Response {
    /**
     * 
     * @type {number}
     * @memberof GetDatasourceStatistics200Response
     */
    numRecords: number;
    /**
     * 
     * @type {number}
     * @memberof GetDatasourceStatistics200Response
     */
    numSuccessful: number;
    /**
     * 
     * @type {number}
     * @memberof GetDatasourceStatistics200Response
     */
    numErrors: number;
}

/**
 * Check if a given object implements the GetDatasourceStatistics200Response interface.
 */
export function instanceOfGetDatasourceStatistics200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numRecords" in value;
    isInstance = isInstance && "numSuccessful" in value;
    isInstance = isInstance && "numErrors" in value;

    return isInstance;
}

export function GetDatasourceStatistics200ResponseFromJSON(json: any): GetDatasourceStatistics200Response {
    return GetDatasourceStatistics200ResponseFromJSONTyped(json, false);
}

export function GetDatasourceStatistics200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasourceStatistics200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numRecords': json['numRecords'],
        'numSuccessful': json['numSuccessful'],
        'numErrors': json['numErrors'],
    };
}

export function GetDatasourceStatistics200ResponseToJSON(value?: GetDatasourceStatistics200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numRecords': value.numRecords,
        'numSuccessful': value.numSuccessful,
        'numErrors': value.numErrors,
    };
}

