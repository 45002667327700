/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseSuccess,
  ProjectsGETSuccess,
  ProjectsPOSTRequest,
  ProjectsPOSTSuccess,
  ProjectsProjectIDClonePOSTRequest,
  ProjectsProjectIDClonePOSTSuccess,
  ProjectsProjectIDError403,
  ProjectsProjectIDError404,
  ProjectsProjectIDGETSuccess,
  ProjectsProjectIDPATCHRequest,
} from '../models/index';
import {
    BaseSuccessFromJSON,
    BaseSuccessToJSON,
    ProjectsGETSuccessFromJSON,
    ProjectsGETSuccessToJSON,
    ProjectsPOSTRequestFromJSON,
    ProjectsPOSTRequestToJSON,
    ProjectsPOSTSuccessFromJSON,
    ProjectsPOSTSuccessToJSON,
    ProjectsProjectIDClonePOSTRequestFromJSON,
    ProjectsProjectIDClonePOSTRequestToJSON,
    ProjectsProjectIDClonePOSTSuccessFromJSON,
    ProjectsProjectIDClonePOSTSuccessToJSON,
    ProjectsProjectIDError403FromJSON,
    ProjectsProjectIDError403ToJSON,
    ProjectsProjectIDError404FromJSON,
    ProjectsProjectIDError404ToJSON,
    ProjectsProjectIDGETSuccessFromJSON,
    ProjectsProjectIDGETSuccessToJSON,
    ProjectsProjectIDPATCHRequestFromJSON,
    ProjectsProjectIDPATCHRequestToJSON,
} from '../models/index';

export interface ProjectsGetRequest {
    projectId?: string;
    projectIds?: Array<string>;
    brandId?: number;
    brandCode?: string;
    mediaType?: ProjectsGetMediaTypeEnum;
    mediaTypes?: Array<ProjectsGetMediaTypesEnum>;
    projectStatus?: ProjectsGetProjectStatusEnum;
    projectStatuses?: Array<ProjectsGetProjectStatusesEnum>;
    tag?: string;
    tags?: Array<string>;
    projectOwnership?: ProjectsGetProjectOwnershipEnum;
    sourceTemplateId?: number;
    sourceTemplateIds?: Array<number>;
    sourceTemplateCode?: string;
    sourceTemplateCodes?: Array<string>;
    width?: number;
    height?: number;
    unitType?: ProjectsGetUnitTypeEnum;
    search?: string;
    sort?: string;
    order?: ProjectsGetOrderEnum;
    limit?: number;
    page?: number;
    fields?: Array<ProjectsGetFieldsEnum>;
}

export interface ProjectsPostRequest {
    projectsPOSTRequest: ProjectsPOSTRequest;
}

export interface ProjectsProjectIdClonePostRequest {
    projectId: string;
    projectsProjectIDClonePOSTRequest: ProjectsProjectIDClonePOSTRequest;
}

export interface ProjectsProjectIdDeleteRequest {
    projectId: string;
}

export interface ProjectsProjectIdGetRequest {
    projectId: string;
    generateLatestThumbnail?: boolean;
}

export interface ProjectsProjectIdPatchRequest {
    projectId: string;
    projectsProjectIDPATCHRequest: ProjectsProjectIDPATCHRequest;
}

/**
 * 
 */
export class ProjectsApi extends runtime.BaseAPI {

    /**
     * List projects
     */
    async projectsGetRaw(requestParameters: ProjectsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsGETSuccess>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.projectIds) {
            queryParameters['project_ids'] = requestParameters.projectIds;
        }

        if (requestParameters.brandId !== undefined) {
            queryParameters['brand_id'] = requestParameters.brandId;
        }

        if (requestParameters.brandCode !== undefined) {
            queryParameters['brand_code'] = requestParameters.brandCode;
        }

        if (requestParameters.mediaType !== undefined) {
            queryParameters['media_type'] = requestParameters.mediaType;
        }

        if (requestParameters.mediaTypes) {
            queryParameters['media_types'] = requestParameters.mediaTypes;
        }

        if (requestParameters.projectStatus !== undefined) {
            queryParameters['project_status'] = requestParameters.projectStatus;
        }

        if (requestParameters.projectStatuses) {
            queryParameters['project_statuses'] = requestParameters.projectStatuses;
        }

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.projectOwnership !== undefined) {
            queryParameters['project_ownership'] = requestParameters.projectOwnership;
        }

        if (requestParameters.sourceTemplateId !== undefined) {
            queryParameters['source_template_id'] = requestParameters.sourceTemplateId;
        }

        if (requestParameters.sourceTemplateIds) {
            queryParameters['source_template_ids'] = requestParameters.sourceTemplateIds;
        }

        if (requestParameters.sourceTemplateCode !== undefined) {
            queryParameters['source_template_code'] = requestParameters.sourceTemplateCode;
        }

        if (requestParameters.sourceTemplateCodes) {
            queryParameters['source_template_codes'] = requestParameters.sourceTemplateCodes;
        }

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.height !== undefined) {
            queryParameters['height'] = requestParameters.height;
        }

        if (requestParameters.unitType !== undefined) {
            queryParameters['unit_type'] = requestParameters.unitType;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.fields) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsGETSuccessFromJSON(jsonValue));
    }

    /**
     * List projects
     */
    async projectsGet(requestParameters: ProjectsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsGETSuccess> {
        const response = await this.projectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a project
     */
    async projectsPostRaw(requestParameters: ProjectsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsPOSTSuccess>> {
        if (requestParameters.projectsPOSTRequest === null || requestParameters.projectsPOSTRequest === undefined) {
            throw new runtime.RequiredError('projectsPOSTRequest','Required parameter requestParameters.projectsPOSTRequest was null or undefined when calling projectsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectsPOSTRequestToJSON(requestParameters.projectsPOSTRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsPOSTSuccessFromJSON(jsonValue));
    }

    /**
     * Create a project
     */
    async projectsPost(requestParameters: ProjectsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsPOSTSuccess> {
        const response = await this.projectsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Clone a project
     */
    async projectsProjectIdClonePostRaw(requestParameters: ProjectsProjectIdClonePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsProjectIDClonePOSTSuccess>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectsProjectIdClonePost.');
        }

        if (requestParameters.projectsProjectIDClonePOSTRequest === null || requestParameters.projectsProjectIDClonePOSTRequest === undefined) {
            throw new runtime.RequiredError('projectsProjectIDClonePOSTRequest','Required parameter requestParameters.projectsProjectIDClonePOSTRequest was null or undefined when calling projectsProjectIdClonePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/clone`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectsProjectIDClonePOSTRequestToJSON(requestParameters.projectsProjectIDClonePOSTRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsProjectIDClonePOSTSuccessFromJSON(jsonValue));
    }

    /**
     * Clone a project
     */
    async projectsProjectIdClonePost(requestParameters: ProjectsProjectIdClonePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsProjectIDClonePOSTSuccess> {
        const response = await this.projectsProjectIdClonePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive a project
     */
    async projectsProjectIdDeleteRaw(requestParameters: ProjectsProjectIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseSuccess>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectsProjectIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessFromJSON(jsonValue));
    }

    /**
     * Archive a project
     */
    async projectsProjectIdDelete(requestParameters: ProjectsProjectIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseSuccess> {
        const response = await this.projectsProjectIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a project
     */
    async projectsProjectIdGetRaw(requestParameters: ProjectsProjectIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsProjectIDGETSuccess>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectsProjectIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.generateLatestThumbnail !== undefined) {
            queryParameters['generate_latest_thumbnail'] = requestParameters.generateLatestThumbnail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsProjectIDGETSuccessFromJSON(jsonValue));
    }

    /**
     * Retrieve a project
     */
    async projectsProjectIdGet(requestParameters: ProjectsProjectIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsProjectIDGETSuccess> {
        const response = await this.projectsProjectIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a project
     */
    async projectsProjectIdPatchRaw(requestParameters: ProjectsProjectIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseSuccess>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectsProjectIdPatch.');
        }

        if (requestParameters.projectsProjectIDPATCHRequest === null || requestParameters.projectsProjectIDPATCHRequest === undefined) {
            throw new runtime.RequiredError('projectsProjectIDPATCHRequest','Required parameter requestParameters.projectsProjectIDPATCHRequest was null or undefined when calling projectsProjectIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectsProjectIDPATCHRequestToJSON(requestParameters.projectsProjectIDPATCHRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessFromJSON(jsonValue));
    }

    /**
     * Update a project
     */
    async projectsProjectIdPatch(requestParameters: ProjectsProjectIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseSuccess> {
        const response = await this.projectsProjectIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ProjectsGetMediaTypeEnum = {
    Digital: 'digital',
    Video: 'video',
    Print: 'print',
    Presentation: 'presentation'
} as const;
export type ProjectsGetMediaTypeEnum = typeof ProjectsGetMediaTypeEnum[keyof typeof ProjectsGetMediaTypeEnum];
/**
 * @export
 */
export const ProjectsGetMediaTypesEnum = {
    Digital: 'digital',
    Video: 'video',
    Print: 'print',
    Presentation: 'presentation'
} as const;
export type ProjectsGetMediaTypesEnum = typeof ProjectsGetMediaTypesEnum[keyof typeof ProjectsGetMediaTypesEnum];
/**
 * @export
 */
export const ProjectsGetProjectStatusEnum = {
    Active: 'active',
    Archived: 'archived'
} as const;
export type ProjectsGetProjectStatusEnum = typeof ProjectsGetProjectStatusEnum[keyof typeof ProjectsGetProjectStatusEnum];
/**
 * @export
 */
export const ProjectsGetProjectStatusesEnum = {
    Active: 'active',
    Archived: 'archived'
} as const;
export type ProjectsGetProjectStatusesEnum = typeof ProjectsGetProjectStatusesEnum[keyof typeof ProjectsGetProjectStatusesEnum];
/**
 * @export
 */
export const ProjectsGetProjectOwnershipEnum = {
    AllProjects: 'all_projects',
    MyProjects: 'my_projects',
    SharedWithMe: 'shared_with_me'
} as const;
export type ProjectsGetProjectOwnershipEnum = typeof ProjectsGetProjectOwnershipEnum[keyof typeof ProjectsGetProjectOwnershipEnum];
/**
 * @export
 */
export const ProjectsGetUnitTypeEnum = {
    Px: 'px',
    In: 'in',
    Mm: 'mm'
} as const;
export type ProjectsGetUnitTypeEnum = typeof ProjectsGetUnitTypeEnum[keyof typeof ProjectsGetUnitTypeEnum];
/**
 * @export
 */
export const ProjectsGetOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ProjectsGetOrderEnum = typeof ProjectsGetOrderEnum[keyof typeof ProjectsGetOrderEnum];
/**
 * @export
 */
export const ProjectsGetFieldsEnum = {
    ProjectId: 'project_id',
    User: 'user',
    MediaType: 'media_type',
    ProjectTitle: 'project_title',
    ProjectStatus: 'project_status',
    Dimensions: 'dimensions',
    PageCount: 'page_count',
    SceneCount: 'scene_count',
    Duration: 'duration',
    ThumbnailUrl: 'thumbnail_url',
    SourceTemplate: 'source_template',
    Brand: 'brand',
    ProjectApprovalStatus: 'project_approval_status',
    Tags: 'tags',
    Metadata: 'metadata',
    DateCreated: 'date_created',
    DateUpdated: 'date_updated',
    LastEdited: 'last_edited',
    LastOpened: 'last_opened',
    TemplateCount: 'template_count',
    PresentationCount: 'presentation_count'
} as const;
export type ProjectsGetFieldsEnum = typeof ProjectsGetFieldsEnum[keyof typeof ProjectsGetFieldsEnum];
