// Copyright 2023 Merit International Inc. All Rights Reserved

import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer } from "../../../components/Spacer";
import { Hoverable } from "react-native-web-hooks";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";
import { SCREEN_NAME } from "../ConfigureTemplate";
import React from "react";
import type { FormFields } from "./TemplateFields";

type Props = {
  readonly onExpand: () => void;
  readonly field: FormFields;
  readonly onMoveUp: () => void;
  readonly onMoveDown: () => void;
  readonly index: number;
  readonly fieldIdToExpand: string | undefined;
  readonly templateFieldsLength: number;
};

export const TemplateFieldListItem = ({
  field,
  fieldIdToExpand,
  index,
  onExpand,
  onMoveDown,
  onMoveUp,
  templateFieldsLength,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    listItem: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xl,
    },
  });

  const isDownArrowDisabled = index === templateFieldsLength - 1;

  return (
    <Hoverable>
      {isHovered => (
        <View
          style={[
            styles.listItem,
            isHovered && {
              backgroundColor: theme.colors.surface.hovered,
            },
          ]}
        >
          <TouchableOpacity onPress={onExpand} style={{ flex: 1, flexDirection: "row" }}>
            <Icon
              name={
                fieldIdToExpand === field.fieldID
                  ? "chevronUpMediumDefault"
                  : "chevronDownMediumDefault"
              }
              testProps={{
                elementId: field.fieldID,
                elementName: `configureTemplateFieldsTabFieldsListItemChevron${
                  fieldIdToExpand === field.fieldID ? "Up" : "Down"
                }Icon`,
                screenName: SCREEN_NAME,
              }}
            />
            {field.isInherited && (
              <>
                <HorizontalSpacer />
                <Icon
                  name="inheritedFieldMediumDefault"
                  testProps={{
                    elementId: field.fieldID,
                    elementName: "configureTemplateFieldsTabFieldsListItemInheritedIcon",
                    screenName: SCREEN_NAME,
                  }}
                />
              </>
            )}
            <HorizontalSpacer />
            <Heading
              level="3"
              numberOfLines={1}
              testProps={{
                elementId: field.fieldID,
                elementName: "configureTemplateFieldsTabFieldsListItemFieldName",
                screenName: SCREEN_NAME,
              }}
            >
              {field.nameToDisplay}
            </Heading>
          </TouchableOpacity>

          <View style={{ flexDirection: "row" }}>
            <Pressable disabled={index === 0} onPress={onMoveUp}>
              <Icon
                name={index === 0 ? "arrowUpwardMediumDisabled" : "arrowUpwardMediumAction"}
                testProps={{
                  elementId: field.fieldID,
                  elementName: `configureTemplateFieldsTabFieldsListItemArrowUpward${
                    index === 0 ? "Disabled" : "Action"
                  }Button`,
                  screenName: SCREEN_NAME,
                }}
              />
            </Pressable>
            <HorizontalSpacer />
            <Pressable disabled={isDownArrowDisabled} onPress={onMoveDown}>
              <Icon
                name={
                  isDownArrowDisabled ? "arrowDownwardMediumDisabled" : "arrowDownwardMediumAction"
                }
                testProps={{
                  elementId: field.fieldID,
                  elementName: `configureTemplateFieldsTabFieldsListItemArrowUpward${
                    isDownArrowDisabled ? "Disabled" : "Action"
                  }Button`,
                  screenName: SCREEN_NAME,
                }}
              />
            </Pressable>
          </View>
        </View>
      )}
    </Hoverable>
  );
};
