// Copyright 2024 Merit International Inc. All Rights Reserved

import "reactflow/dist/style.css";
import { Body, useTheme } from "@merit/frontend-components";
import { EditableFieldNode } from "@src/screens/AutoMapTemplate/MappingView/EditableFieldNode";
import { FieldNode } from "./FieldNode";
import { Helpers } from "@merit/frontend-utils";
import { LabelNode } from "./LabelNode";
import { View } from "react-native";
import { useAlertStore } from "../../../stores";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, { Position, addEdge, applyEdgeChanges } from "reactflow";
import type { Connection, Edge, EdgeChange, Node, ReactFlowInstance } from "reactflow";
import type { CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum } from "@src/gen/org-portal";
import type { FormValues as DataSource } from "../Datasource/PreviewDataSource";

const { Some } = Helpers;

export const NODE_HEIGHT = 64;

export type TemplateField = {
  readonly contact: boolean;
  readonly displayName: string;
  readonly fieldKindID: string;
  readonly isBaseField: boolean;
  readonly error: string | undefined;
  readonly name: string;
  readonly type: CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum;
};

export type NodeData = {
  readonly dataType: CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum | undefined;
  readonly label: string;
  readonly name: string;
  readonly type: string;
};

type Props = {
  readonly dataSource: DataSource;
  readonly templateFieldsLength: number;
  readonly templateNodes: readonly Node<NodeData>[];
  readonly updateEdges: (edges: readonly Edge[]) => void;
  readonly edges: readonly Edge[];
};

export const MappingFlow = React.memo(
  ({ dataSource, edges, templateFieldsLength, templateNodes, updateEdges }: Props) => {
    const { theme } = useTheme();
    const { deleteAlert, setAlert } = useAlertStore();

    const [columnNodes, setColumnNodes] = useState<readonly Node<NodeData>[]>([]);

    useEffect(() => {
      const generateNodes = () => {
        const columnNodesHeader = {
          columnDataType: undefined,
          columnName: "Datasource columns",
          id: uuidv4(),
        };
        const generatedColumnNodes = [columnNodesHeader, ...dataSource.schemaColumns].map(
          (column, index) => ({
            data: {
              dataType: column.columnDataType,
              label: column.columnName,
              name: column.columnName,
              type: "target",
            },
            id: column.id,
            position: { x: 0, y: index * NODE_HEIGHT },
            targetPosition: Position.Right,
            type: index === 0 ? "labelNode" : "fieldNode",
          })
        );
        setColumnNodes(generatedColumnNodes);
      };

      generateNodes();
    }, [dataSource.schemaColumns, templateNodes]);

    const generateEdges = (instance: ReactFlowInstance) => {
      const initialEdges = templateNodes
        .map(field => {
          const column = columnNodes.find(({ data }) => data.name === field.data.name);

          if (Some(column)) {
            return {
              id: `${field.id}-${column.id}`,
              label: "Mapped to",
              labelBgPadding: [4, 2],
              labelStyle: {
                fontFamily: theme.fonts.normal,
              },
              source: `${field.id}`,
              sourceHandle: field.data.label,
              target: `${column.id}`,
              targetHandle: column.data.label,
            };
          }

          return undefined;
        })
        .filter(_ => Some(_));

      instance.addEdges(initialEdges.map(field => field as Edge));
    };

    const onEdgesChange = useCallback(
      (changes: readonly EdgeChange[]) => {
        updateEdges(applyEdgeChanges([...changes], [...edges]));
      },
      [edges, updateEdges]
    );

    const onConnect = useCallback(
      (params: Connection) => {
        const nodes = [...templateNodes, ...columnNodes];
        const sourceNode = nodes.find(node => node.id === params.source);
        const targetNode = nodes.find(node => node.id === params.target);
        if (sourceNode?.data.dataType !== targetNode?.data.dataType) {
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            text: `Cannot map from data type of '${sourceNode?.data.dataType ?? ""}' to '${
              targetNode?.data.dataType ?? ""
            }'`,
            type: "error",
          });

          return;
        }

        const updated = edges.filter(edge => {
          if (edge.source === params.source || edge.target === params.target) {
            return undefined;
          }

          return edge;
        });
        const edgeWithLabel = {
          ...params,
          label: "Mapped to",
          labelBgPadding: [4, 2],
          labelStyle: {
            fontFamily: theme.fonts.normal,
          },
        };
        updateEdges(addEdge(edgeWithLabel, updated));
      },

      [columnNodes, deleteAlert, edges, setAlert, templateNodes, theme.fonts.normal, updateEdges]
    );

    const nodeTypes = useMemo(
      () => ({ editableFieldNode: EditableFieldNode, fieldNode: FieldNode, labelNode: LabelNode }),
      []
    );

    if (columnNodes.length === 0) {
      return (
        <View style={{ alignItems: "center", paddingVertical: theme.spacing.xxl }}>
          <Body>Mapping...</Body>
        </View>
      );
    }

    return (
      <View style={{ flex: 1 }}>
        <ReactFlow
          defaultViewport={{ x: 0, y: 0, zoom: 1 }}
          edges={[...edges]}
          nodeTypes={nodeTypes}
          nodes={[...templateNodes, ...columnNodes]}
          onConnect={onConnect}
          onEdgesChange={onEdgesChange}
          onInit={generateEdges}
          panOnScroll
          proOptions={{ hideAttribution: true }}
          translateExtent={[
            [0, 0],
            [680, (templateFieldsLength + 1) * NODE_HEIGHT],
          ]}
        />
      </View>
    );
  }
);
