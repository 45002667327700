/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTemplateRules200ResponseRulesBoolFieldHasValue } from './GetTemplateRules200ResponseRulesBoolFieldHasValue';
import {
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON,
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSONTyped,
    GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON,
} from './GetTemplateRules200ResponseRulesBoolFieldHasValue';

/**
 * 
 * @export
 * @interface GetTemplateRules200ResponseRulesDate
 */
export interface GetTemplateRules200ResponseRulesDate {
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesDate
     */
    beforeTodayMinusXDays?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesDate
     */
    afterThisDate?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesDate
     */
    beforeThisDate?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesDate
     */
    afterToday?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesDate
     */
    beforeToday?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesDate
     */
    equalToDate?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesDate
     */
    fieldHasValue?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
}

/**
 * Check if a given object implements the GetTemplateRules200ResponseRulesDate interface.
 */
export function instanceOfGetTemplateRules200ResponseRulesDate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseRulesDateFromJSON(json: any): GetTemplateRules200ResponseRulesDate {
    return GetTemplateRules200ResponseRulesDateFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseRulesDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200ResponseRulesDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beforeTodayMinusXDays': !exists(json, 'BeforeTodayMinusXDays') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['BeforeTodayMinusXDays']),
        'afterThisDate': !exists(json, 'AfterThisDate') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['AfterThisDate']),
        'beforeThisDate': !exists(json, 'BeforeThisDate') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['BeforeThisDate']),
        'afterToday': !exists(json, 'AfterToday') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['AfterToday']),
        'beforeToday': !exists(json, 'BeforeToday') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['BeforeToday']),
        'equalToDate': !exists(json, 'EqualToDate') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['EqualToDate']),
        'fieldHasValue': !exists(json, 'FieldHasValue') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['FieldHasValue']),
    };
}

export function GetTemplateRules200ResponseRulesDateToJSON(value?: GetTemplateRules200ResponseRulesDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BeforeTodayMinusXDays': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.beforeTodayMinusXDays),
        'AfterThisDate': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.afterThisDate),
        'BeforeThisDate': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.beforeThisDate),
        'AfterToday': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.afterToday),
        'BeforeToday': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.beforeToday),
        'EqualToDate': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.equalToDate),
        'FieldHasValue': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.fieldHasValue),
    };
}

