/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The state of this container.
 * @export
 * @interface ContainerState
 */
export interface ContainerState {
    /**
     * The state of the container.
     * @type {string}
     * @memberof ContainerState
     */
    name?: string;
    /**
     * The time the state transition occurred.
     * @type {Date}
     * @memberof ContainerState
     */
    occurredAt?: Date | null;
}

/**
 * Check if a given object implements the ContainerState interface.
 */
export function instanceOfContainerState(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContainerStateFromJSON(json: any): ContainerState {
    return ContainerStateFromJSONTyped(json, false);
}

export function ContainerStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'occurredAt': !exists(json, 'occurredAt') ? undefined : (json['occurredAt'] === null ? null : new Date(json['occurredAt'])),
    };
}

export function ContainerStateToJSON(value?: ContainerState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'occurredAt': value.occurredAt === undefined ? undefined : (value.occurredAt === null ? null : value.occurredAt.toISOString()),
    };
}

