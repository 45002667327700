// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Link } from "@merit/frontend-components";
import { HorizontalSpacer } from "@src/components";
import { StyleSheet, View } from "react-native";
import React from "react";

const SCREEN_NAME = "PreLoginFooter";

export const PreLoginFooter = () => {
  const styles = StyleSheet.create({
    footer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      paddingBottom: 24,
      paddingTop: 16,
    },
  });

  return (
    <View style={styles.footer}>
      <Body testProps={{ elementName: "copyRightsText", screenName: SCREEN_NAME }}>
        Merit International © 2024
      </Body>
      <HorizontalSpacer />
      <Body>|</Body>
      <HorizontalSpacer />
      <Link
        onPress={() => {
          window.open("https://knowledge.merits.com/");
        }}
        testProps={{ elementName: "helpLink", screenName: SCREEN_NAME }}
      >
        Help
      </Link>
      <HorizontalSpacer />
      <Body>|</Body>
      <HorizontalSpacer />
      <Link
        onPress={() => {
          window.open("https://app.merits.com/privacy-policy");
        }}
        testProps={{ elementName: "privacyPolicyLink", screenName: SCREEN_NAME }}
      >
        Privacy
      </Link>
      <HorizontalSpacer />
      <Body>|</Body>
      <HorizontalSpacer />
      <Link
        onPress={() => {
          window.open("https://app.merits.com/terms-of-service");
        }}
        testProps={{ elementName: "termsOfServiceLink", screenName: SCREEN_NAME }}
      >
        Terms
      </Link>
    </View>
  );
};
