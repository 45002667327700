/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataSource } from './DataSource';
import {
    DataSourceFromJSON,
    DataSourceFromJSONTyped,
    DataSourceToJSON,
} from './DataSource';

/**
 * A list of data sources
 * @export
 * @interface DataSources
 */
export interface DataSources {
    /**
     * 
     * @type {Array<DataSource>}
     * @memberof DataSources
     */
    dataSources?: Array<DataSource>;
    /**
     * indicates whether there are more pages of data sources
     * @type {boolean}
     * @memberof DataSources
     */
    hasMore?: boolean;
}

/**
 * Check if a given object implements the DataSources interface.
 */
export function instanceOfDataSources(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataSourcesFromJSON(json: any): DataSources {
    return DataSourcesFromJSONTyped(json, false);
}

export function DataSourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSources {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSources': !exists(json, 'dataSources') ? undefined : ((json['dataSources'] as Array<any>).map(DataSourceFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
    };
}

export function DataSourcesToJSON(value?: DataSources | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSources': value.dataSources === undefined ? undefined : ((value.dataSources as Array<any>).map(DataSourceToJSON)),
        'hasMore': value.hasMore,
    };
}

