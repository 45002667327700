// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { RuleList } from "./RuleList";
import { SCREEN_NAME } from "./TemplateDetails";
import { Spin } from "../../components/Spin";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../../components/Spacer";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useMemo, useState } from "react";
import type {
  GetTemplateRules200ResponseRules,
  GetDatasource200ResponseMappedTemplatesInner as Template,
} from "../../gen/org-portal";

const { Some } = Helpers;

type Props = {
  readonly templateDetails: {
    readonly inheritedCompletenessRules: Template["inheritedCompletenessRules"];
    readonly ownCompletenessRule: Template["ownCompletenessRule"];
    readonly inheritedActivenessRules: Template["inheritedActivenessRules"];
    readonly ownActivenessRule: Template["ownActivenessRule"];
    readonly templateFields: Template["templateFields"];
  };
  readonly isOwnTemplate: boolean;
};

export const Rules = ({ isOwnTemplate, templateDetails }: Props) => {
  const { theme } = useTheme();
  const [rules, setRules] = useState<GetTemplateRules200ResponseRules>();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const [isLoading, setIsLoading] = useState(true);
  const { errorHandler } = useServerErrorHandler();

  const styles = StyleSheet.create({
    loadingContainer: {
      alignItems: "center",
      height: 100,
      justifyContent: "center",
      paddingVertical: theme.spacing.xxl,
    },
    noFields: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingVertical: theme.spacing.l,
    },
  });

  useEffect(() => {
    const getRules = async () => {
      try {
        setIsLoading(true);
        const response = await api.getTemplateRules({ orgID: selectedOrgId });
        setRules(response.rules);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    getRules();
  }, [api, deleteAlert, errorHandler, selectedOrgId, setAlert]);

  const activenessRules = useMemo(() => {
    const inheritedActivenessRules = templateDetails.inheritedActivenessRules ?? [];
    const ownActivenessRule = templateDetails.ownActivenessRule;

    if (!isOwnTemplate) {
      if (Some(ownActivenessRule)) {
        return [...inheritedActivenessRules, ownActivenessRule];
      }

      return inheritedActivenessRules;
    }

    return inheritedActivenessRules;
  }, [isOwnTemplate, templateDetails.inheritedActivenessRules, templateDetails.ownActivenessRule]);

  const completenessRules = useMemo(() => {
    const inheritedCompletenessRules = templateDetails.inheritedCompletenessRules ?? [];
    const ownCompletenessRule = templateDetails.ownCompletenessRule;

    if (!isOwnTemplate) {
      if (Some(ownCompletenessRule)) {
        return [...inheritedCompletenessRules, ownCompletenessRule];
      }

      return inheritedCompletenessRules;
    }

    return inheritedCompletenessRules;
  }, [
    isOwnTemplate,
    templateDetails.inheritedCompletenessRules,
    templateDetails.ownCompletenessRule,
  ]);

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Spin />
      </View>
    );
  }

  const { ownActivenessRule, ownCompletenessRule, templateFields } = templateDetails;

  return (
    <View style={{ paddingHorizontal: 32 }}>
      <VerticalSpacer size={theme.spacing.xxl} />
      <Heading
        level="4"
        testProps={{
          elementName: "detailsViewRulesTabCompletenessRulesText",
          screenName: SCREEN_NAME,
        }}
      >
        Completeness rules
      </Heading>
      <>
        <VerticalSpacer />
        <Heading
          level="5"
          testProps={{
            elementName: "detailsViewRulesTabCompletenessRulesInheritedText",
            screenName: SCREEN_NAME,
          }}
        >
          Inherited
        </Heading>
        {Some(rules) && completenessRules.length > 0 ? (
          completenessRules.map(inheritedRule => (
            <RuleList
              key={`section-${inheritedRule.ruleConditions[0].target}`}
              ruleConditions={inheritedRule.ruleConditions}
              rules={rules}
              templateFields={templateFields}
              testProps={{
                elementName: "detailsViewRulesTabCompletenessRulesInherited",
                screenName: SCREEN_NAME,
              }}
            />
          ))
        ) : (
          <View style={styles.noFields}>
            <Body
              testProps={{
                elementName: "detailsViewRulesTabCompletenessRulesInheritedNoDataPlaceholder",
                screenName: SCREEN_NAME,
              }}
            >
              There are no inherited mapping rules
            </Body>
          </View>
        )}
      </>

      {isOwnTemplate && (
        <>
          <VerticalSpacer />
          <Heading
            level="5"
            testProps={{
              elementName: "detailsViewRulesTabCompletenessRulesOrgCreatedRulesText",
              screenName: SCREEN_NAME,
            }}
          >
            Org created
          </Heading>
          {Some(ownCompletenessRule) && Some(rules) ? (
            <RuleList
              ruleConditions={ownCompletenessRule.ruleConditions}
              rules={rules}
              templateFields={templateFields}
              testProps={{
                elementName: "detailsViewRulesTabCompletenessRulesOrgCreated",
                screenName: SCREEN_NAME,
              }}
            />
          ) : (
            <View style={styles.noFields}>
              <Body
                testProps={{
                  elementName: "detailsViewRulesTabCompletenessRulesOrgCreatedNoDataPlaceholder",
                  screenName: SCREEN_NAME,
                }}
              >
                There are no org created mapping rules
              </Body>
            </View>
          )}
        </>
      )}

      <VerticalSpacer />
      <Heading
        level="4"
        testProps={{
          elementName: "detailsViewRulesTabActivityRulesText",
          screenName: SCREEN_NAME,
        }}
      >
        Activity rules
      </Heading>
      <>
        <VerticalSpacer />
        <Heading
          level="5"
          testProps={{
            elementName: "detailsViewRulesTabActivityRulesInheritedText",
            screenName: SCREEN_NAME,
          }}
        >
          Inherited
        </Heading>
        {Some(rules) && activenessRules.length > 0 ? (
          activenessRules.map(inheritedRule => (
            <RuleList
              key={`section-${inheritedRule.ruleConditions[0].target}`}
              ruleConditions={inheritedRule.ruleConditions}
              rules={rules}
              templateFields={templateFields}
              testProps={{
                elementName: "detailsViewRulesTabActivityRulesInherited",
                screenName: SCREEN_NAME,
              }}
            />
          ))
        ) : (
          <View style={styles.noFields}>
            <Body
              testProps={{
                elementName: "detailsViewRulesTabActivityRulesInheritedNoDataPlaceholder",
                screenName: SCREEN_NAME,
              }}
            >
              There are no inherited rules
            </Body>
          </View>
        )}
      </>

      {isOwnTemplate && (
        <>
          <VerticalSpacer />
          <Heading
            level="5"
            testProps={{
              elementName: "detailsViewRulesTabActivityRulesOrgCreatedRulesText",
              screenName: SCREEN_NAME,
            }}
          >
            Org created
          </Heading>
          {Some(ownActivenessRule) && Some(rules) ? (
            <RuleList
              ruleConditions={ownActivenessRule.ruleConditions}
              rules={rules}
              templateFields={templateFields}
              testProps={{
                elementName: "detailsViewRulesTabActivityRulesOrgCreated",
                screenName: SCREEN_NAME,
              }}
            />
          ) : (
            <View style={styles.noFields}>
              <Body
                testProps={{
                  elementName: "detailsViewRulesTabActivityRulesOrgCreatedNoDataPlaceholder",
                  screenName: SCREEN_NAME,
                }}
              >
                There are no org created rules
              </Body>
            </View>
          )}
        </>
      )}
    </View>
  );
};
