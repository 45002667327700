/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSourceIngestionStatus
 */
export interface DataSourceIngestionStatus {
    /**
     * The DataSource ID
     * @type {string}
     * @memberof DataSourceIngestionStatus
     */
    dataSourceID: string;
    /**
     * The name of the DataSource
     * @type {string}
     * @memberof DataSourceIngestionStatus
     */
    name: string;
    /**
     * The current ingestion status of an integration. In a paused state, the integration is not currently being ingested.
     * In a queued state, the integration is queued for ingesting. In an ingesting state, the integration is currently being
     * ingested. In a not ingesting state, the integration failed ingesting and needs attention.
     * 
     * @type {string}
     * @memberof DataSourceIngestionStatus
     */
    ingestionStatus?: DataSourceIngestionStatusIngestionStatusEnum;
}


/**
 * @export
 */
export const DataSourceIngestionStatusIngestionStatusEnum = {
    IngestionPaused: 'ingestion paused',
    Queued: 'queued',
    Ingesting: 'ingesting',
    NotIngesting: 'not ingesting'
} as const;
export type DataSourceIngestionStatusIngestionStatusEnum = typeof DataSourceIngestionStatusIngestionStatusEnum[keyof typeof DataSourceIngestionStatusIngestionStatusEnum];


/**
 * Check if a given object implements the DataSourceIngestionStatus interface.
 */
export function instanceOfDataSourceIngestionStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataSourceID" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DataSourceIngestionStatusFromJSON(json: any): DataSourceIngestionStatus {
    return DataSourceIngestionStatusFromJSONTyped(json, false);
}

export function DataSourceIngestionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceIngestionStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSourceID': json['dataSourceID'],
        'name': json['name'],
        'ingestionStatus': !exists(json, 'ingestionStatus') ? undefined : json['ingestionStatus'],
    };
}

export function DataSourceIngestionStatusToJSON(value?: DataSourceIngestionStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSourceID': value.dataSourceID,
        'name': value.name,
        'ingestionStatus': value.ingestionStatus,
    };
}

