/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Column DataType corresponding to Blob data.
 * @export
 * @interface Blob
 */
export interface Blob extends DataType {
}

/**
 * Check if a given object implements the Blob interface.
 */
export function instanceOfBlob(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BlobFromJSON(json: any): Blob {
    return BlobFromJSONTyped(json, false);
}

export function BlobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Blob {
    return json;
}

export function BlobToJSON(value?: Blob | null): any {
    return value;
}

