// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Pressable, View } from "react-native";
import { SCREEN_NAME } from "./TemplateDetails";
import { TemplateMetrics } from "./TemplateMetrics";
import { ViewPermissions } from "../../components/ViewPermissions";
import { getDateTimeString } from "../../utils/time";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import type { GetDatasource200ResponseMappedTemplatesInner } from "../../gen/org-portal";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PostLoginRouteParams } from "../../Router";

const { Some } = Helpers;

export type TemplateLineage = {
  readonly name: string | undefined;
  readonly id: string;
};

type Props = {
  readonly template: GetDatasource200ResponseMappedTemplatesInner;
  readonly lineage: readonly TemplateLineage[];
};

export const Overview = ({
  lineage,
  template: {
    autoApprove,
    autoRevoke,
    createdAt,
    createdBy,
    description,
    id,
    ownerID,
    ownerName,
    permissions,
    type,
  },
}: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const navigation = useNavigation<NativeStackNavigationProp<PostLoginRouteParams>>();
  const { showTemplateAnalytics } = useFlags<LDFeatureFlags>();

  const isOwnTemplate = selectedOrgId === ownerID;

  return (
    <View style={{ marginHorizontal: 32 }}>
      {showTemplateAnalytics && isOwnTemplate && (
        <>
          <VerticalSpacer size={theme.spacing.xxl} />
          <Heading level="5">{type ?? "--"}</Heading>
          <VerticalSpacer size={10} />
          <TemplateMetrics
            templateID={id}
            templateOwnerID={ownerID}
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTab",
              screenName: SCREEN_NAME,
            }}
          />
        </>
      )}
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">Description</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabDescription",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(description) && description !== "" ? description : "--"}
        </Body>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">Type</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabTemplateType",
            screenName: SCREEN_NAME,
          }}
        >
          {type ?? "--"}
        </Body>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">Owner</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <View style={{ flexDirection: "row" }}>
          <Body
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTabOwnerName",
              screenName: SCREEN_NAME,
            }}
          >
            {Some(ownerName) && ownerName !== "" ? ownerName : "--"}
          </Body>
          <HorizontalSpacer size={10} />
          {isOwnTemplate && (
            <>
              <Body color={theme.colors.border.subdued}>|</Body>
              <HorizontalSpacer size={10} />
              <Body
                testProps={{
                  elementId: id,
                  elementName: "detailsViewOverviewTabViewOwnerID",
                  screenName: SCREEN_NAME,
                }}
              >
                {ownerID}
              </Body>
            </>
          )}
        </View>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">{`Created ${isOwnTemplate ? "by" : "at"}`}</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <View style={{ flexDirection: "row" }}>
          {isOwnTemplate && (
            <>
              <Body
                testProps={{
                  elementId: id,
                  elementName: "detailsViewOverviewTabCreatedBy",
                  screenName: SCREEN_NAME,
                }}
              >
                {Some(createdBy) && createdBy !== "" ? createdBy : "--"}
              </Body>
              <HorizontalSpacer size={10} />
              <Body color={theme.colors.border.subdued}>|</Body>
              <HorizontalSpacer size={10} />
            </>
          )}
          <Body
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTabCreatedAt",
              screenName: SCREEN_NAME,
            }}
          >
            {Some(createdAt) ? getDateTimeString(createdAt) : "--"}
          </Body>
        </View>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <ViewPermissions
          permissions={permissions}
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTab",
            screenName: SCREEN_NAME,
          }}
        />
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">Auto-approve</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          capitalize
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabAutoApprove",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(autoApprove) && autoApprove ? "on" : "off"}
        </Body>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">Auto-remove</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          capitalize
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabAutoRemove",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(autoRevoke) && autoRevoke ? "on" : "off"}
        </Body>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Lineage</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        {lineage.length > 0
          ? lineage.map(template => (
              <View key={template.id}>
                <Pressable
                  onPress={() => {
                    navigation.navigate("Templates", { initialTemplateId: template.id });
                  }}
                >
                  <Body
                    color={theme.colors.border.highlight.default}
                    testProps={{
                      elementId: template.id,
                      elementName: "detailsViewOverviewTabLineage",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {template.name}
                  </Body>
                </Pressable>
                <VerticalSpacer size={theme.spacing.xs} />
              </View>
            ))
          : "--"}
      </>
    </View>
  );
};
