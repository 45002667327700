/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnIntegrationMappingProperties } from './ColumnIntegrationMappingProperties';
import {
    ColumnIntegrationMappingPropertiesFromJSON,
    ColumnIntegrationMappingPropertiesFromJSONTyped,
    ColumnIntegrationMappingPropertiesToJSON,
} from './ColumnIntegrationMappingProperties';

/**
 * Mapping of a template to a data source.
 * @export
 * @interface DataSourceIntegrationMapping
 */
export interface DataSourceIntegrationMapping {
    /**
     * The id of the integration.
     * @type {string}
     * @memberof DataSourceIntegrationMapping
     */
    integrationID: string;
    /**
     * The id of the data source.
     * @type {string}
     * @memberof DataSourceIntegrationMapping
     */
    dataSourceID: string;
    /**
     * The name of the table from the integration.
     * @type {string}
     * @memberof DataSourceIntegrationMapping
     */
    tableName?: string | null;
    /**
     * The mapping from a template field to a column.
     * @type {Array<ColumnIntegrationMappingProperties>}
     * @memberof DataSourceIntegrationMapping
     */
    columnToTemplateField?: Array<ColumnIntegrationMappingProperties>;
}

/**
 * Check if a given object implements the DataSourceIntegrationMapping interface.
 */
export function instanceOfDataSourceIntegrationMapping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "integrationID" in value;
    isInstance = isInstance && "dataSourceID" in value;

    return isInstance;
}

export function DataSourceIntegrationMappingFromJSON(json: any): DataSourceIntegrationMapping {
    return DataSourceIntegrationMappingFromJSONTyped(json, false);
}

export function DataSourceIntegrationMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceIntegrationMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'integrationID': json['integrationID'],
        'dataSourceID': json['dataSourceID'],
        'tableName': !exists(json, 'tableName') ? undefined : json['tableName'],
        'columnToTemplateField': !exists(json, 'columnToTemplateField') ? undefined : ((json['columnToTemplateField'] as Array<any>).map(ColumnIntegrationMappingPropertiesFromJSON)),
    };
}

export function DataSourceIntegrationMappingToJSON(value?: DataSourceIntegrationMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integrationID': value.integrationID,
        'dataSourceID': value.dataSourceID,
        'tableName': value.tableName,
        'columnToTemplateField': value.columnToTemplateField === undefined ? undefined : ((value.columnToTemplateField as Array<any>).map(ColumnIntegrationMappingPropertiesToJSON)),
    };
}

