/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of a FieldKind search result.
 * @export
 * @interface Search200ResponseFieldKindsInner
 */
export interface Search200ResponseFieldKindsInner {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof Search200ResponseFieldKindsInner
     */
    fieldKindID: string;
    /**
     * The name of the object
     * @type {string}
     * @memberof Search200ResponseFieldKindsInner
     */
    name: string;
    /**
     * The UUID of this object's owner
     * @type {string}
     * @memberof Search200ResponseFieldKindsInner
     */
    owner?: string;
    /**
     * The type of data this kind is.
     * @type {string}
     * @memberof Search200ResponseFieldKindsInner
     */
    dataType: string;
    /**
     * The description of this FieldKind.
     * @type {string}
     * @memberof Search200ResponseFieldKindsInner
     */
    description?: string;
    /**
     * The id of the FieldKind this was extended from.
     * @type {string}
     * @memberof Search200ResponseFieldKindsInner
     */
    parent?: string;
}

/**
 * Check if a given object implements the Search200ResponseFieldKindsInner interface.
 */
export function instanceOfSearch200ResponseFieldKindsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fieldKindID" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "dataType" in value;

    return isInstance;
}

export function Search200ResponseFieldKindsInnerFromJSON(json: any): Search200ResponseFieldKindsInner {
    return Search200ResponseFieldKindsInnerFromJSONTyped(json, false);
}

export function Search200ResponseFieldKindsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Search200ResponseFieldKindsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldKindID': json['fieldKindID'],
        'name': json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'dataType': json['dataType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
    };
}

export function Search200ResponseFieldKindsInnerToJSON(value?: Search200ResponseFieldKindsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldKindID': value.fieldKindID,
        'name': value.name,
        'owner': value.owner,
        'dataType': value.dataType,
        'description': value.description,
        'parent': value.parent,
    };
}

