/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnIntegrationMappingProperties } from './ColumnIntegrationMappingProperties';
import {
    ColumnIntegrationMappingPropertiesFromJSON,
    ColumnIntegrationMappingPropertiesFromJSONTyped,
    ColumnIntegrationMappingPropertiesToJSON,
} from './ColumnIntegrationMappingProperties';

/**
 * The required inputs to map a data source.
 * @export
 * @interface DataSourceIntegrationMappingProperties
 */
export interface DataSourceIntegrationMappingProperties {
    /**
     * The id of the integration being mapped
     * @type {string}
     * @memberof DataSourceIntegrationMappingProperties
     */
    integrationID: string;
    /**
     * The name of the table from the integration available. All columns in the mapping should belong to this table.
     * @type {string}
     * @memberof DataSourceIntegrationMappingProperties
     */
    tableName: string;
    /**
     * The mappings from column to template field.
     * @type {Array<ColumnIntegrationMappingProperties>}
     * @memberof DataSourceIntegrationMappingProperties
     */
    columnToTableColumn: Array<ColumnIntegrationMappingProperties>;
}

/**
 * Check if a given object implements the DataSourceIntegrationMappingProperties interface.
 */
export function instanceOfDataSourceIntegrationMappingProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "integrationID" in value;
    isInstance = isInstance && "tableName" in value;
    isInstance = isInstance && "columnToTableColumn" in value;

    return isInstance;
}

export function DataSourceIntegrationMappingPropertiesFromJSON(json: any): DataSourceIntegrationMappingProperties {
    return DataSourceIntegrationMappingPropertiesFromJSONTyped(json, false);
}

export function DataSourceIntegrationMappingPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceIntegrationMappingProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'integrationID': json['integrationID'],
        'tableName': json['tableName'],
        'columnToTableColumn': ((json['columnToTableColumn'] as Array<any>).map(ColumnIntegrationMappingPropertiesFromJSON)),
    };
}

export function DataSourceIntegrationMappingPropertiesToJSON(value?: DataSourceIntegrationMappingProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integrationID': value.integrationID,
        'tableName': value.tableName,
        'columnToTableColumn': ((value.columnToTableColumn as Array<any>).map(ColumnIntegrationMappingPropertiesToJSON)),
    };
}

