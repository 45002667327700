/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A column from a connected integration sync'ed
 * @export
 * @interface IntegrationTableColumn
 */
export interface IntegrationTableColumn {
    /**
     * The name of the column
     * @type {string}
     * @memberof IntegrationTableColumn
     */
    name?: string;
    /**
     * The data type of the column
     * @type {string}
     * @memberof IntegrationTableColumn
     */
    dataType?: IntegrationTableColumnDataTypeEnum;
    /**
     * Indicates if the column may be null
     * @type {boolean}
     * @memberof IntegrationTableColumn
     */
    nullable?: boolean;
}


/**
 * @export
 */
export const IntegrationTableColumnDataTypeEnum = {
    Boolean: 'Boolean',
    Number: 'Number',
    Short: 'Short',
    Int: 'Int',
    Long: 'Long',
    Float: 'Float',
    Double: 'Double',
    BigDecimal: 'BigDecimal',
    LocalDate: 'LocalDate',
    Instant: 'Instant',
    LocalDateTime: 'LocalDateTime',
    String: 'String',
    Xml: 'XML',
    Json: 'JSON',
    Binary: 'Binary'
} as const;
export type IntegrationTableColumnDataTypeEnum = typeof IntegrationTableColumnDataTypeEnum[keyof typeof IntegrationTableColumnDataTypeEnum];


/**
 * Check if a given object implements the IntegrationTableColumn interface.
 */
export function instanceOfIntegrationTableColumn(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntegrationTableColumnFromJSON(json: any): IntegrationTableColumn {
    return IntegrationTableColumnFromJSONTyped(json, false);
}

export function IntegrationTableColumnFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationTableColumn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'nullable': !exists(json, 'nullable') ? undefined : json['nullable'],
    };
}

export function IntegrationTableColumnToJSON(value?: IntegrationTableColumn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dataType': value.dataType,
        'nullable': value.nullable,
    };
}

