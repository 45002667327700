/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityInfo } from './EntityInfo';
import {
    EntityInfoFromJSON,
    EntityInfoFromJSONTyped,
    EntityInfoToJSON,
} from './EntityInfo';
import type { FormulaErrorResult } from './FormulaErrorResult';
import {
    FormulaErrorResultFromJSON,
    FormulaErrorResultFromJSONTyped,
    FormulaErrorResultToJSON,
} from './FormulaErrorResult';

/**
 * Representation of a named Policy Request.
 * @export
 * @interface PolicyRequest
 */
export interface PolicyRequest {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof PolicyRequest
     */
    id: string;
    /**
     * 
     * @type {EntityInfo}
     * @memberof PolicyRequest
     */
    owner: EntityInfo;
    /**
     * 
     * @type {Date}
     * @memberof PolicyRequest
     */
    createdAt: Date;
    /**
     * The ID of the Policy to run.
     * @type {string}
     * @memberof PolicyRequest
     */
    policy?: string;
    /**
     * The result of the Policy Request.
     * @type {boolean}
     * @memberof PolicyRequest
     */
    result?: boolean | null;
    /**
     * The result message of the Policy Request.
     * @type {string}
     * @memberof PolicyRequest
     */
    resultMessage?: string | null;
    /**
     * The result error of the Policy Request.
     * @type {Array<FormulaErrorResult>}
     * @memberof PolicyRequest
     */
    errorResults?: Array<FormulaErrorResult> | null;
    /**
     * When the Policy Request was run.
     * @type {Date}
     * @memberof PolicyRequest
     */
    runTimestamp?: Date | null;
    /**
     * The status of the Policy Request.
     * @type {string}
     * @memberof PolicyRequest
     */
    status?: PolicyRequestStatusEnum;
    /**
     * The ID of the Entity to run the Policy against.
     * @type {string}
     * @memberof PolicyRequest
     */
    target?: string;
}


/**
 * @export
 */
export const PolicyRequestStatusEnum = {
    Approved: 'Approved',
    Completed: 'Completed',
    Denied: 'Denied',
    Requested: 'Requested'
} as const;
export type PolicyRequestStatusEnum = typeof PolicyRequestStatusEnum[keyof typeof PolicyRequestStatusEnum];


/**
 * Check if a given object implements the PolicyRequest interface.
 */
export function instanceOfPolicyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function PolicyRequestFromJSON(json: any): PolicyRequest {
    return PolicyRequestFromJSONTyped(json, false);
}

export function PolicyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': EntityInfoFromJSON(json['owner']),
        'createdAt': (new Date(json['createdAt'])),
        'policy': !exists(json, 'policy') ? undefined : json['policy'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'resultMessage': !exists(json, 'resultMessage') ? undefined : json['resultMessage'],
        'errorResults': !exists(json, 'errorResults') ? undefined : (json['errorResults'] === null ? null : (json['errorResults'] as Array<any>).map(FormulaErrorResultFromJSON)),
        'runTimestamp': !exists(json, 'runTimestamp') ? undefined : (json['runTimestamp'] === null ? null : new Date(json['runTimestamp'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'target': !exists(json, 'target') ? undefined : json['target'],
    };
}

export function PolicyRequestToJSON(value?: PolicyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner': EntityInfoToJSON(value.owner),
        'createdAt': (value.createdAt.toISOString()),
        'policy': value.policy,
        'result': value.result,
        'resultMessage': value.resultMessage,
        'errorResults': value.errorResults === undefined ? undefined : (value.errorResults === null ? null : (value.errorResults as Array<any>).map(FormulaErrorResultToJSON)),
        'runTimestamp': value.runTimestamp === undefined ? undefined : (value.runTimestamp === null ? null : value.runTimestamp.toISOString()),
        'status': value.status,
        'target': value.target,
    };
}

