// Copyright 2022 Merit International Inc. All Rights Reserved

import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Pressable, View } from "react-native";
import { useNavigation } from "@react-navigation/core";
import React from "react";
import type { GestureResponderEvent } from "react-native";
import type { OPTestProps } from "../../src/types/TestProps";
import type { ReactNode } from "react";

const Some = Helpers.Some;

type Props = {
  readonly children: ReactNode;
  readonly title: string;
  readonly onClose?: (e: GestureResponderEvent) => void;
  readonly testProps?: OPTestProps;
};

export const FullScreenModalLayout = ({ children, onClose, testProps, title }: Props) => {
  const { theme } = useTheme();
  const navigation = useNavigation();

  const onCloseHandle =
    onClose ??
    (() => {
      navigation.goBack();
    });

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          alignItems: "center",
          backgroundColor: theme.colors.border.highlight.default,
          flexDirection: "row",
          height: 72,
          justifyContent: "space-between",
          paddingHorizontal: 32,
          paddingVertical: theme.spacing.xxl,
        }}
      >
        <View style={{ flex: 1 }}>
          <Heading
            bold
            center
            color="white"
            level="3"
            testProps={
              Some(testProps)
                ? { ...testProps, elementName: `${testProps.elementName}Title` }
                : testProps
            }
          >
            {title}
          </Heading>
        </View>
        <Pressable onPress={onCloseHandle}>
          <Icon
            name="closeMediumActionSecondary"
            testProps={
              Some(testProps)
                ? { ...testProps, elementName: `${testProps.elementName}CloseButton` }
                : testProps
            }
          />
        </Pressable>
      </View>
      {children}
    </View>
  );
};
