/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateCredentialRequestAllOfSpecialTextSourcesInner } from './CreateCredentialRequestAllOfSpecialTextSourcesInner';
import {
    CreateCredentialRequestAllOfSpecialTextSourcesInnerFromJSON,
    CreateCredentialRequestAllOfSpecialTextSourcesInnerFromJSONTyped,
    CreateCredentialRequestAllOfSpecialTextSourcesInnerToJSON,
} from './CreateCredentialRequestAllOfSpecialTextSourcesInner';
import type { CreateCredentialRequestAllOfTextSourcesInner } from './CreateCredentialRequestAllOfTextSourcesInner';
import {
    CreateCredentialRequestAllOfTextSourcesInnerFromJSON,
    CreateCredentialRequestAllOfTextSourcesInnerFromJSONTyped,
    CreateCredentialRequestAllOfTextSourcesInnerToJSON,
} from './CreateCredentialRequestAllOfTextSourcesInner';

/**
 * Create credential request body
 * @export
 * @interface CreateCredentialRequest
 */
export interface CreateCredentialRequest {
    /**
     * The container template id to which the credential should be attached
     * @type {string}
     * @memberof CreateCredentialRequest
     */
    targetContainerTemplateID: string;
    /**
     * The ID of this credential as known to DesignHuddle
     * @type {string}
     * @memberof CreateCredentialRequest
     */
    designHuddleProjectID: string;
    /**
     * A description of the credential
     * @type {string}
     * @memberof CreateCredentialRequest
     */
    description?: string;
    /**
     * The credential name
     * @type {string}
     * @memberof CreateCredentialRequest
     */
    name: string;
    /**
     * Format for date fields on a credential
     * @type {string}
     * @memberof CreateCredentialRequest
     */
    dateFormat?: CreateCredentialRequestDateFormatEnum;
    /**
     * 
     * @type {Array<CreateCredentialRequestAllOfTextSourcesInner>}
     * @memberof CreateCredentialRequest
     */
    textSources?: Array<CreateCredentialRequestAllOfTextSourcesInner>;
    /**
     * 
     * @type {Array<CreateCredentialRequestAllOfSpecialTextSourcesInner>}
     * @memberof CreateCredentialRequest
     */
    specialTextSources?: Array<CreateCredentialRequestAllOfSpecialTextSourcesInner>;
}


/**
 * @export
 */
export const CreateCredentialRequestDateFormatEnum = {
    AlphabeticFull: 'AlphabeticFull',
    AlphabeticShort: 'AlphabeticShort',
    NumericDash: 'NumericDash',
    NumericSlash: 'NumericSlash'
} as const;
export type CreateCredentialRequestDateFormatEnum = typeof CreateCredentialRequestDateFormatEnum[keyof typeof CreateCredentialRequestDateFormatEnum];


/**
 * Check if a given object implements the CreateCredentialRequest interface.
 */
export function instanceOfCreateCredentialRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "targetContainerTemplateID" in value;
    isInstance = isInstance && "designHuddleProjectID" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateCredentialRequestFromJSON(json: any): CreateCredentialRequest {
    return CreateCredentialRequestFromJSONTyped(json, false);
}

export function CreateCredentialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCredentialRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetContainerTemplateID': json['targetContainerTemplateID'],
        'designHuddleProjectID': json['designHuddleProjectID'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'dateFormat': !exists(json, 'dateFormat') ? undefined : json['dateFormat'],
        'textSources': !exists(json, 'textSources') ? undefined : ((json['textSources'] as Array<any>).map(CreateCredentialRequestAllOfTextSourcesInnerFromJSON)),
        'specialTextSources': !exists(json, 'specialTextSources') ? undefined : ((json['specialTextSources'] as Array<any>).map(CreateCredentialRequestAllOfSpecialTextSourcesInnerFromJSON)),
    };
}

export function CreateCredentialRequestToJSON(value?: CreateCredentialRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetContainerTemplateID': value.targetContainerTemplateID,
        'designHuddleProjectID': value.designHuddleProjectID,
        'description': value.description,
        'name': value.name,
        'dateFormat': value.dateFormat,
        'textSources': value.textSources === undefined ? undefined : ((value.textSources as Array<any>).map(CreateCredentialRequestAllOfTextSourcesInnerToJSON)),
        'specialTextSources': value.specialTextSources === undefined ? undefined : ((value.specialTextSources as Array<any>).map(CreateCredentialRequestAllOfSpecialTextSourcesInnerToJSON)),
    };
}

