/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';

/**
 * 
 * @export
 * @interface EditTemplateFieldRequest
 */
export interface EditTemplateFieldRequest {
    /**
     * The template field name
     * @type {string}
     * @memberof EditTemplateFieldRequest
     */
    name?: string;
    /**
     * The template field description
     * @type {string}
     * @memberof EditTemplateFieldRequest
     */
    description?: string;
    /**
     * If this template field is contactable
     * @type {boolean}
     * @memberof EditTemplateFieldRequest
     */
    contact?: boolean;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof EditTemplateFieldRequest
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
}

/**
 * Check if a given object implements the EditTemplateFieldRequest interface.
 */
export function instanceOfEditTemplateFieldRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditTemplateFieldRequestFromJSON(json: any): EditTemplateFieldRequest {
    return EditTemplateFieldRequestFromJSONTyped(json, false);
}

export function EditTemplateFieldRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTemplateFieldRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
    };
}

export function EditTemplateFieldRequestToJSON(value?: EditTemplateFieldRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'contact': value.contact,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
    };
}

