// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Images } from "../../utils/Image";
import { SCREEN_NAME } from "./TemplateDetails";
import { getReadableDataType } from "@src/utils/getReadableDataType";
import React from "react";
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as GetTemplateFields } from "../../gen/org-portal";

const { Some } = Helpers;

type Props = {
  readonly templateFields: readonly GetTemplateFields[];
};

export const Fields = ({ templateFields }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    fieldRow: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingLeft: 32,
      paddingRight: theme.spacing.xxl,
      paddingVertical: 10,
    },
    noFieldsSection: {
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xxl,
    },
  });

  return (
    <>
      <VerticalSpacer size={theme.spacing.xxl} />
      {Some(templateFields) && templateFields.length > 0 ? (
        templateFields.map(templateField => (
          <View style={styles.fieldRow}>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <Body
                numberOfLines={1}
                style={{ fontWeight: theme.fontWeights.semiBold }}
                testProps={{
                  elementId: templateField.fieldID,
                  elementName: "detailsViewFieldsTabTemplateFieldName",
                  screenName: SCREEN_NAME,
                }}
              >
                {templateField.name}
              </Body>
              <>
                <Pressable
                  onPress={() => {
                    if (Some(templateField.fieldKindID)) {
                      window.open(`/fields?fieldId=${templateField.fieldKindID.toString()}`);
                    }
                  }}
                  {...Helpers.generateTestIdProps({
                    elementId: templateField.fieldID,
                    elementName: "detailsViewFieldsTabFieldViewLink",
                    screenName: SCREEN_NAME,
                  })}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Body color={theme.colors.border.highlight.default}>View</Body>
                    <HorizontalSpacer size={theme.spacing.s} />
                    <Image
                      accessibilityLabel="link-to-field-detail"
                      source={Images.arrowExpand}
                      style={{ height: 20, width: 20 }}
                    />
                  </View>
                </Pressable>
              </>
            </View>
            <VerticalSpacer size={theme.spacing.s} />
            <View style={{ flexDirection: "row" }}>
              <Body
                numberOfLines={1}
                testProps={{
                  elementId: templateField.fieldID,
                  elementName: "detailsViewFieldsTabTemplateFieldID",
                  screenName: SCREEN_NAME,
                }}
              >
                Template field ID: {templateField.fieldID}
              </Body>
              <HorizontalSpacer size={6} />
              <Body color={theme.colors.border.default}>|</Body>
              <HorizontalSpacer size={6} />
              <Body
                numberOfLines={1}
                testProps={{
                  elementId: templateField.fieldID,
                  elementName: "detailsViewFieldsTabTemplateDataType",
                  screenName: SCREEN_NAME,
                }}
              >
                Data type:{" "}
                {Some(templateField.type) ? getReadableDataType(templateField.type) : "--"}
              </Body>
            </View>
          </View>
        ))
      ) : (
        <View style={styles.noFieldsSection}>
          <Body
            style={{ fontWeight: theme.fontWeights.semiBold }}
            testProps={{
              elementName: "detailsViewFieldsTabNoDataPlaceholder",
              screenName: SCREEN_NAME,
            }}
          >
            There are no template fields
          </Body>
        </View>
      )}
    </>
  );
};
