/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes whether an entity has accepted an agent's TOS and approved its capabilities
 * @export
 * @interface AgentEntityLink
 */
export interface AgentEntityLink {
    /**
     * True if the entity has accepted the agent's terms of service
     * @type {boolean}
     * @memberof AgentEntityLink
     */
    tosAccepted: boolean;
    /**
     * True if the entity has approved the agent's capabilities
     * @type {boolean}
     * @memberof AgentEntityLink
     */
    capabilitiesApproved: boolean;
}

/**
 * Check if a given object implements the AgentEntityLink interface.
 */
export function instanceOfAgentEntityLink(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tosAccepted" in value;
    isInstance = isInstance && "capabilitiesApproved" in value;

    return isInstance;
}

export function AgentEntityLinkFromJSON(json: any): AgentEntityLink {
    return AgentEntityLinkFromJSONTyped(json, false);
}

export function AgentEntityLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentEntityLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tosAccepted': json['tosAccepted'],
        'capabilitiesApproved': json['capabilitiesApproved'],
    };
}

export function AgentEntityLinkToJSON(value?: AgentEntityLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tosAccepted': value.tosAccepted,
        'capabilitiesApproved': value.capabilitiesApproved,
    };
}

