// Copyright 2024 Merit International Inc. All Rights Reserved

import * as ExpoDocumentPicker from "expo-document-picker";
import { Body, Button, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Images } from "../../utils/Image";
import React, { useState } from "react";
import type { OPTestProps } from "../../types/TestProps";

const { None, Some, generateTestIdProps } = Helpers;

const VALID_FILE_EXTENSIONS = ["jpeg", "png", "jpg"];

const MAX_FILE_SIZE_BYTES = 25 * 1024 * 1024;

type Props = {
  readonly onSave: (file: ExpoDocumentPicker.DocumentPickerAsset) => void;
  readonly onCancel: () => void;
  readonly onError: (errMsg: string) => void;
  readonly testProps: OPTestProps;
};

export const UploadFileModal = ({ onCancel, onError, onSave, testProps }: Props) => {
  const { theme } = useTheme();
  const [fileName, setFileName] = useState<string>();
  const [uri, setUri] = useState<ExpoDocumentPicker.DocumentPickerAsset>();

  const pickDocument = async () => {
    const pickerResult = await ExpoDocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      type: ["image/jpeg", "image/png"],
    });

    if (pickerResult.canceled) {
      return;
    }

    const result =
      Some(pickerResult.assets) && pickerResult.assets.length > 0
        ? pickerResult.assets[0]
        : undefined;

    if (None(result)) {
      onError("Something wrong with file. Please try again");

      return;
    }

    if (Some(result.mimeType) && !VALID_FILE_EXTENSIONS.includes(result.mimeType.split("/")[1])) {
      onError("Selected file type not supported");

      return;
    }

    if (Some(result.size) && result.size >= MAX_FILE_SIZE_BYTES) {
      onError("Select a file which is below 25MB");

      return;
    }

    setFileName(result.name);
    setUri(result);
  };

  const styles = StyleSheet.create({
    buttonsContainer: {
      ...(None(fileName) && { alignSelf: "flex-end" }),
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    container: {
      alignItems: "center",
      borderColor: theme.colors.border.subdued,
      borderWidth: 1,
      justifyContent: "center",
      minHeight: 200,
      minWidth: 456,
    },
    icon: { height: 48, width: 48 },
  });

  return (
    <>
      <View style={styles.container}>
        <View style={{ paddingVertical: 38 }}>
          <VerticalSpacer size={theme.spacing.s} />
          {Some(fileName) ? (
            <View style={{ alignItems: "center" }}>
              <Image accessible source={Images.uploadSuccess} style={styles.icon} />
              <VerticalSpacer size={theme.spacing.xxl} />
              <Body
                style={{ color: theme.colors.text.subdued }}
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}FileName`,
                }}
              >
                File name: {fileName}
              </Body>
            </View>
          ) : (
            <>
              <View style={{ alignItems: "center" }}>
                <Pressable
                  onPress={() => {
                    pickDocument();
                  }}
                >
                  <Image
                    accessible
                    source={Images.selectFile}
                    style={styles.icon}
                    {...generateTestIdProps({
                      ...testProps,
                      elementName: `${testProps.elementName}BrowseImageIcon`,
                    })}
                  />
                </Pressable>
              </View>
              <VerticalSpacer size={theme.spacing.xxl} />
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <Body
                  testProps={{
                    ...testProps,
                    elementName: `${testProps.elementName}BrowseImageText`,
                  }}
                >
                  Click browse to upload your image
                </Body>
                <HorizontalSpacer size={theme.spacing.xs} />
                <Pressable
                  onPress={() => {
                    setFileName(undefined);
                    pickDocument();
                  }}
                >
                  <Body
                    color={theme.colors.brand.oceanBlue}
                    testProps={{
                      ...testProps,
                      elementName: `${testProps.elementName}BrowseImageAction`,
                    }}
                  >
                    browse
                  </Body>
                </Pressable>
              </View>
              <VerticalSpacer size={theme.spacing.s} />
              <Body
                style={{ color: theme.colors.text.subdued }}
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}ImageRecommendation`,
                }}
              >
                Recommended image dimensions 150x150 px
              </Body>
            </>
          )}
        </View>
      </View>

      <VerticalSpacer />

      <View style={styles.buttonsContainer}>
        {Some(fileName) && (
          <Pressable
            onPress={() => {
              setFileName(undefined);
              pickDocument();
            }}
          >
            <Body
              style={{ color: theme.colors.interactive.hovered }}
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}ChooseNewImage`,
              }}
            >
              Choose new image
            </Body>
          </Pressable>
        )}

        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Button
            onPress={() => {
              onCancel();
            }}
            testProps={{ ...testProps, elementName: `${testProps.elementName}CancelButton` }}
            text="Cancel"
            type="secondary"
          />
          <HorizontalSpacer />
          <Button
            disabled={None(fileName)}
            onPress={() => {
              if (Some(fileName) && Some(uri)) {
                onSave(uri);
              }
            }}
            testProps={{ ...testProps, elementName: `${testProps.elementName}OkButton` }}
            text="Save"
            type="primary"
          />
        </View>
      </View>
    </>
  );
};
