/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerIssuer } from './OrgsGet200ResponseContainersInnerIssuer';
import {
    OrgsGet200ResponseContainersInnerIssuerFromJSON,
    OrgsGet200ResponseContainersInnerIssuerFromJSONTyped,
    OrgsGet200ResponseContainersInnerIssuerToJSON,
} from './OrgsGet200ResponseContainersInnerIssuer';

/**
 * 
 * @export
 * @interface GetLinkedOrgs200ResponseLinkedOrgsInner
 */
export interface GetLinkedOrgs200ResponseLinkedOrgsInner {
    /**
     * 
     * @type {string}
     * @memberof GetLinkedOrgs200ResponseLinkedOrgsInner
     */
    orgID: string;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInnerIssuer}
     * @memberof GetLinkedOrgs200ResponseLinkedOrgsInner
     */
    orgInfo: OrgsGet200ResponseContainersInnerIssuer;
}

/**
 * Check if a given object implements the GetLinkedOrgs200ResponseLinkedOrgsInner interface.
 */
export function instanceOfGetLinkedOrgs200ResponseLinkedOrgsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgID" in value;
    isInstance = isInstance && "orgInfo" in value;

    return isInstance;
}

export function GetLinkedOrgs200ResponseLinkedOrgsInnerFromJSON(json: any): GetLinkedOrgs200ResponseLinkedOrgsInner {
    return GetLinkedOrgs200ResponseLinkedOrgsInnerFromJSONTyped(json, false);
}

export function GetLinkedOrgs200ResponseLinkedOrgsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLinkedOrgs200ResponseLinkedOrgsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgID': json['OrgID'],
        'orgInfo': OrgsGet200ResponseContainersInnerIssuerFromJSON(json['OrgInfo']),
    };
}

export function GetLinkedOrgs200ResponseLinkedOrgsInnerToJSON(value?: GetLinkedOrgs200ResponseLinkedOrgsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OrgID': value.orgID,
        'OrgInfo': OrgsGet200ResponseContainersInnerIssuerToJSON(value.orgInfo),
    };
}

