// Copyright 2024 Merit International Inc. All Rights Reserved

import { CreatePolicyFormikWrapper } from "./components";
import { StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { FC } from "react";

export const CreatePolicy: FC = () => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
  });

  return (
    <View style={styles.container}>
      <CreatePolicyFormikWrapper />
    </View>
  );
};
