// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, Checkbox, Heading, Icon, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../../components/Modals";
import { Datagrid, DatagridBody, Pagination } from "../../../components";
import { Helpers } from "@merit/frontend-utils";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { SCREEN_NAME } from "../ConfigureTemplate";
import { VerticalSpacer } from "../../../components/Spacer";
import { useApi } from "../../../api/api";
import { useFieldsData } from "../../Fields/useFieldsData";
import { useIsFocused } from "@react-navigation/native";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import React, { useState } from "react";
import type { DatagridColumn } from "../../../components/Datagrid/types";
import type { GetFieldKind200Response } from "../../../gen/org-portal";

const { None } = Helpers;

type Props = {
  readonly onDrawerClose: () => void;
  readonly templateID: string;
  readonly onAdd: (fields: readonly GetFieldKind200Response[]) => void;
};

export const FieldKindsList = ({ onAdd, onDrawerClose }: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const isFocused = useIsFocused();
  const fieldKindsData = useFieldsData(api, selectedOrgId, isFocused);

  if (None(selectedOrgId)) {
    throw new Error("Somehow org id not found");
  }

  const styles = StyleSheet.create({
    heading: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 32,
    },
    modalContainer: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      paddingTop: theme.spacing.xxl,
    },
  });

  const [selectedFields, setSelectedFields] = useState<readonly GetFieldKind200Response[]>([]);
  const [isUnSaveModalOpen, setIsUnSaveModalOpen] = useState(false);
  const [selectAllCheckboxKey, setSelectAllCheckboxKey] = useState(0);

  const defaultCheck =
    fieldKindsData.data.length > 0
      ? fieldKindsData.data.filter(
          _ => !selectedFields.some(fieldID => fieldID.fieldKindID === _.fieldKindID)
        ).length === 0
      : false;

  const columns: readonly DatagridColumn<GetFieldKind200Response>[] = [
    {
      key: "actions",
      label: (
        <Checkbox
          defaultChecked={defaultCheck}
          disabled={fieldKindsData.data.length === 0}
          key={selectAllCheckboxKey}
          onChange={isChecked => {
            if (isChecked) {
              setSelectedFields(prevState => [...prevState, ...fieldKindsData.data]);
            } else {
              const newFieldIDs = selectedFields.filter(_ =>
                fieldKindsData.data.every(template => template.fieldKindID !== _.fieldKindID)
              );
              setSelectedFields(newFieldIDs);
            }
          }}
          testProps={{
            elementName: "configureTemplateFieldsTabFieldKindsListViewSelectAll",
            screenName: SCREEN_NAME,
          }}
        />
      ),
      renderer: item => (
        <Checkbox
          defaultChecked={selectedFields.some(_ => _.fieldKindID === item.fieldKindID)}
          onChange={isChecked => {
            if (isChecked && !selectedFields.some(_ => _.fieldKindID === item.fieldKindID)) {
              setSelectedFields(prevState => [...prevState, item]);
            } else {
              setSelectedFields(prevState =>
                prevState.filter(_ => _.fieldKindID !== item.fieldKindID)
              );
            }
          }}
          testProps={{
            elementId: item.fieldKindID,
            elementName: "configureTemplateFieldsTabFieldKindsListView",
            screenName: SCREEN_NAME,
          }}
        />
      ),
      size: 60,
    },
    {
      key: "name",
      label: "Field Name",
      size: "flex",
    },
    {
      key: "dataType",
      label: "Data type",
      size: "flex",
    },
  ];

  return (
    <>
      <View style={styles.modalContainer}>
        <View style={styles.heading}>
          <Heading
            bold
            level="3"
            testProps={{
              elementName: "configureTemplateFieldsTabFieldKindsListViewAddFieldsText",
              screenName: SCREEN_NAME,
            }}
          >
            Add fields(s)
          </Heading>
          <Pressable
            onPress={() => {
              if (selectedFields.length === 0) {
                onDrawerClose();
              } else {
                setIsUnSaveModalOpen(true);
              }
            }}
          >
            <Icon
              name="closeSmallDefault"
              testProps={{
                elementName: "configureTemplateFieldsTabFieldKindsListViewCloseButton",
                screenName: SCREEN_NAME,
              }}
            />
          </Pressable>
        </View>

        <VerticalSpacer size={theme.spacing.xxl} />

        <View style={{ alignSelf: "flex-end", justifyContent: "flex-end", paddingHorizontal: 32 }}>
          <Button
            disabled={selectedFields.length === 0}
            onPress={() => {
              onAdd(selectedFields);
              setSelectedFields([]);
              onDrawerClose();
            }}
            size="small"
            testProps={{
              elementName: "configureTemplateFieldsTabFieldKindsListViewAddFieldButton",
              screenName: SCREEN_NAME,
            }}
            text="Add"
            type="secondary"
          />
        </View>

        <VerticalSpacer />

        <View style={{ flex: 1 }}>
          <ScrollView>
            <Datagrid
              columns={columns}
              loading={fieldKindsData.loading}
              testProps={{
                elementName: "configureTemplateFieldsTabFieldKindsListView",
                screenName: SCREEN_NAME,
              }}
            >
              <DatagridBody
                columns={columns}
                data={fieldKindsData.data}
                noDataText="There are no fields for this template"
                testProps={{
                  elementName: "configureTemplateFieldsTabFieldKindsListView",
                  screenName: SCREEN_NAME,
                }}
                testPropsElementIdKey="fieldKindID"
              />
            </Datagrid>
          </ScrollView>
        </View>
        <Pagination
          disableNext={!fieldKindsData.pagination.hasNextPage}
          disablePrev={!fieldKindsData.pagination.hasPrevPage}
          hasBottomMargin={false}
          onNext={() => {
            setSelectAllCheckboxKey(state => state + 1);
            fieldKindsData.nextPage();
          }}
          onPrev={() => {
            setSelectAllCheckboxKey(state => state + 1);
            fieldKindsData.prevPage();
          }}
          testProps={{
            elementName: "configureTemplateFieldsTabFieldKindsListView",
            screenName: SCREEN_NAME,
          }}
        />
      </View>
      {isUnSaveModalOpen && (
        <ConfirmationModal
          onClose={() => {
            setIsUnSaveModalOpen(false);
          }}
          onOk={() => {
            setSelectedFields([]);
            setIsUnSaveModalOpen(false);
            onDrawerClose();
          }}
          testProps={{
            elementName: "configureTemplateFieldsTabFieldKindsListViewUnSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </>
  );
};
