/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A SignedURL representing where to upload a file and a CorrelationID identifying the upload
 * 
 * @export
 * @interface UploadURL
 */
export interface UploadURL {
    /**
     * 
     * @type {string}
     * @memberof UploadURL
     */
    signedURL: string;
    /**
     * 
     * @type {string}
     * @memberof UploadURL
     */
    correlationID: string;
}

/**
 * Check if a given object implements the UploadURL interface.
 */
export function instanceOfUploadURL(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "signedURL" in value;
    isInstance = isInstance && "correlationID" in value;

    return isInstance;
}

export function UploadURLFromJSON(json: any): UploadURL {
    return UploadURLFromJSONTyped(json, false);
}

export function UploadURLFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadURL {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signedURL': json['signedURL'],
        'correlationID': json['correlationID'],
    };
}

export function UploadURLToJSON(value?: UploadURL | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signedURL': value.signedURL,
        'correlationID': value.correlationID,
    };
}

