/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseRules } from './ResponseRules';
import {
    ResponseRulesFromJSON,
    ResponseRulesFromJSONTyped,
    ResponseRulesToJSON,
} from './ResponseRules';

/**
 * The rules that govern completeness and activeness on containers created from this template.
 * @export
 * @interface TemplatePropsRules
 */
export interface TemplatePropsRules {
    /**
     * 
     * @type {ResponseRules}
     * @memberof TemplatePropsRules
     */
    completeness?: ResponseRules;
    /**
     * 
     * @type {ResponseRules}
     * @memberof TemplatePropsRules
     */
    activeness?: ResponseRules;
}

/**
 * Check if a given object implements the TemplatePropsRules interface.
 */
export function instanceOfTemplatePropsRules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TemplatePropsRulesFromJSON(json: any): TemplatePropsRules {
    return TemplatePropsRulesFromJSONTyped(json, false);
}

export function TemplatePropsRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplatePropsRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completeness': !exists(json, 'completeness') ? undefined : ResponseRulesFromJSON(json['completeness']),
        'activeness': !exists(json, 'activeness') ? undefined : ResponseRulesFromJSON(json['activeness']),
    };
}

export function TemplatePropsRulesToJSON(value?: TemplatePropsRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completeness': ResponseRulesToJSON(value.completeness),
        'activeness': ResponseRulesToJSON(value.activeness),
    };
}

