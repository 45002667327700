// Copyright 2024 Merit International Inc. All Rights Reserved

import { AppDetails } from "../OrgSettings/AppDetails/AppDetails";
import { AppsTable } from "../OrgSettings/AppTable/AppsTable";
import { Drawer } from "@src/components/Drawer";
import { GetAllAgentsVersionsSortByEnum } from "@src/gen/org-portal";
import { Helpers } from "@merit/frontend-utils";
import { Pagination, Spin, VerticalSpacer } from "@src/components";
import { View } from "react-native";
import { useAgentsVersionsData } from "../OrgSettings/MyApps/useAgentsVersionsData";
import { useAlertStore } from "@src/stores";
import { useApi } from "@src/api/api";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const { Some } = Helpers;

export const Apps = () => {
  const { api } = useApi();

  const { selectedOrgId } = useLoggedInAuthState();
  const { errorHandler } = useServerErrorHandler();
  const { deleteAlert, setAlert } = useAlertStore();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAppID, setSelectedAppID] = useState<string>();

  const {
    agentsVersions,
    isLoading: isFetching,
    nextPage,
    paginationData,
    prevPage,
    refresh,
  } = useAgentsVersionsData(api, {
    includeApprovedAgents: false,
    includeApprovedVersions: false,
    limit: 11,
    orgID: selectedOrgId,
    sortBy: GetAllAgentsVersionsSortByEnum.Name,
  });

  const approveAgent = async (agentID: string, versionID: string) => {
    try {
      setIsLoading(true);
      await api.approveAgentVersion({
        agentID,
        orgID: selectedOrgId,
        versionID,
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: "App has been approved.",
        type: "success",
      });
      refresh();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isFetching ? (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Spin />
        </View>
      ) : (
        <>
          <AppsTable
            appsList={agentsVersions}
            isLoading={isLoading}
            onPressApprove={(agentID, versionID) => {
              approveAgent(agentID, versionID);
            }}
            onPressView={id => {
              setSelectedAppID(id);
            }}
          />
          <VerticalSpacer size={48} />
          <Drawer isOpen={Some(selectedAppID)}>
            <AppDetails
              appID={selectedAppID}
              appsListType="own"
              onClose={() => {
                setSelectedAppID(undefined);
              }}
              onPressApprove={(agentID, versionID) => {
                approveAgent(agentID, versionID);
              }}
              onStatusChange={refresh}
            />
          </Drawer>
        </>
      )}

      <Pagination
        disableNext={!paginationData.hasNextPage || isFetching}
        disablePrev={!paginationData.hasPrevPage || isFetching}
        onNext={nextPage}
        onPrev={prevPage}
      />
    </>
  );
};
