// Copyright 2023 Merit International Inc. All Rights Reserved

import { ResponseError } from "../gen/org-portal";
import { useAlertStore } from "../stores/alertStore";
import { useCallback } from "react";
import { useDefaultErrorHandler } from "./useDefaultErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type { OPTestProps } from "../types/TestProps";

export const useServerErrorHandler = () => {
  const { deleteAlert, setAlert } = useAlertStore();
  const { errorHandler: defaultErrorHandler } = useDefaultErrorHandler();

  const errorHandler = useCallback(
    async (err: unknown, testProps?: OPTestProps) => {
      if (err instanceof ResponseError) {
        try {
          if (err.response.status === 504) {
            setAlert({
              closable: true,
              id: uuidv4(),
              onPressDelete: id => {
                deleteAlert(id);
              },
              text: "The operation has timed out. Please try again later.",
              type: "error",
            });

            return;
          }
          const error = await err.response.json();
          if (error.code >= 500) {
            defaultErrorHandler(err);

            return;
          }
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            testProps,
            text: error?.errors?.toString() ?? "Something went wrong",
            type: "error",
          });
        } catch (error) {
          defaultErrorHandler(error);
        }
      }
    },
    [deleteAlert, defaultErrorHandler, setAlert]
  );

  return { errorHandler };
};
