/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of a search query.
 * @export
 * @interface SearchRequestContainerFieldsInner
 */
export interface SearchRequestContainerFieldsInner {
    /**
     * the ID of the exact container field to search
     * @type {string}
     * @memberof SearchRequestContainerFieldsInner
     */
    templateFieldID?: string | null;
    /**
     * the base field for all fields in scope for the query
     * @type {string}
     * @memberof SearchRequestContainerFieldsInner
     */
    baseTemplateFieldID?: string | null;
    /**
     * the value of the search query
     * @type {string}
     * @memberof SearchRequestContainerFieldsInner
     */
    query?: string;
    /**
     * whether to match only full, exact values
     * @type {boolean}
     * @memberof SearchRequestContainerFieldsInner
     */
    exactMatch?: boolean;
}

/**
 * Check if a given object implements the SearchRequestContainerFieldsInner interface.
 */
export function instanceOfSearchRequestContainerFieldsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchRequestContainerFieldsInnerFromJSON(json: any): SearchRequestContainerFieldsInner {
    return SearchRequestContainerFieldsInnerFromJSONTyped(json, false);
}

export function SearchRequestContainerFieldsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequestContainerFieldsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldID': !exists(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'baseTemplateFieldID': !exists(json, 'baseTemplateFieldID') ? undefined : json['baseTemplateFieldID'],
        'query': !exists(json, 'query') ? undefined : json['query'],
        'exactMatch': !exists(json, 'exactMatch') ? undefined : json['exactMatch'],
    };
}

export function SearchRequestContainerFieldsInnerToJSON(value?: SearchRequestContainerFieldsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldID': value.templateFieldID,
        'baseTemplateFieldID': value.baseTemplateFieldID,
        'query': value.query,
        'exactMatch': value.exactMatch,
    };
}

