/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner';

/**
 * A container template
 * @export
 * @interface ListTemplates200ResponseTemplatesInner
 */
export interface ListTemplates200ResponseTemplatesInner {
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    type?: ListTemplates200ResponseTemplatesInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    status?: ListTemplates200ResponseTemplatesInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    approval?: ListTemplates200ResponseTemplatesInnerApprovalEnum;
    /**
     * 
     * @type {number}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    issuedCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    createdBy?: string;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner>}
     * @memberof ListTemplates200ResponseTemplatesInner
     */
    templateFields?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner>;
}


/**
 * @export
 */
export const ListTemplates200ResponseTemplatesInnerTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
} as const;
export type ListTemplates200ResponseTemplatesInnerTypeEnum = typeof ListTemplates200ResponseTemplatesInnerTypeEnum[keyof typeof ListTemplates200ResponseTemplatesInnerTypeEnum];

/**
 * @export
 */
export const ListTemplates200ResponseTemplatesInnerStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type ListTemplates200ResponseTemplatesInnerStatusEnum = typeof ListTemplates200ResponseTemplatesInnerStatusEnum[keyof typeof ListTemplates200ResponseTemplatesInnerStatusEnum];

/**
 * @export
 */
export const ListTemplates200ResponseTemplatesInnerApprovalEnum = {
    Manual: 'Manual'
} as const;
export type ListTemplates200ResponseTemplatesInnerApprovalEnum = typeof ListTemplates200ResponseTemplatesInnerApprovalEnum[keyof typeof ListTemplates200ResponseTemplatesInnerApprovalEnum];


/**
 * Check if a given object implements the ListTemplates200ResponseTemplatesInner interface.
 */
export function instanceOfListTemplates200ResponseTemplatesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ListTemplates200ResponseTemplatesInnerFromJSON(json: any): ListTemplates200ResponseTemplatesInner {
    return ListTemplates200ResponseTemplatesInnerFromJSONTyped(json, false);
}

export function ListTemplates200ResponseTemplatesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTemplates200ResponseTemplatesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'approval': !exists(json, 'approval') ? undefined : json['approval'],
        'issuedCount': !exists(json, 'issuedCount') ? undefined : json['issuedCount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'templateFields': !exists(json, 'templateFields') ? undefined : ((json['templateFields'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSON)),
    };
}

export function ListTemplates200ResponseTemplatesInnerToJSON(value?: ListTemplates200ResponseTemplatesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': value.type,
        'id': value.id,
        'status': value.status,
        'approval': value.approval,
        'issuedCount': value.issuedCount,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'templateFields': value.templateFields === undefined ? undefined : ((value.templateFields as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerToJSON)),
    };
}

