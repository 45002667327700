// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { VerticalSpacer } from "../Spacer";
import { useHover } from "react-native-web-hooks";
import React, { useRef } from "react";
import type { TextStyle } from "react-native";

type Props = {
  readonly onPress: () => void;
  readonly text: string;
  readonly subtext: string;
  readonly size: "large" | "oneline" | "small";
};
const SCREEN_NAME = "MenuLink";
const { generateTestIdProps } = Helpers;
export const MenuLink = ({ onPress, size, subtext, text }: Props) => {
  const { theme } = useTheme();
  const containerRef = useRef(null);
  const isHovered = useHover(containerRef);

  const styles = StyleSheet.create<{
    readonly hoveredHeading: TextStyle;
  }>({
    hoveredHeading: {
      textDecorationLine: "underline",
    },
  });

  const getTitleContent = () => {
    switch (size) {
      case "large":
        return (
          <Heading bold level="3" style={isHovered && styles.hoveredHeading}>
            {text}
          </Heading>
        );
      case "small":
        return (
          <Heading level="4" style={isHovered && styles.hoveredHeading}>
            {text}
          </Heading>
        );
      case "oneline":
        return <Body style={isHovered && styles.hoveredHeading}>{text}</Body>;
      default:
        return undefined;
    }
  };

  const getSubTitleContent = () => {
    switch (size) {
      case "large":
        return <Body color={theme.colors.text.subdued}>{subtext}</Body>;
      case "small":
        return (
          <Body color={theme.colors.text.subdued} size="s">
            {subtext}
          </Body>
        );
      case "oneline":
        return undefined;
      default:
        return undefined;
    }
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      {...generateTestIdProps({
        elementName: `${text}`,
        screenName: SCREEN_NAME,
      })}
    >
      <View ref={containerRef}>
        <VerticalSpacer size={16} />
        {getTitleContent()}
        {size !== "oneline" && (
          <>
            <VerticalSpacer size={4} />
            {getSubTitleContent()}
          </>
        )}
        <VerticalSpacer size={16} />
      </View>
    </TouchableOpacity>
  );
};
