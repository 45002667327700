/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A formula of conjunctions, disjunctions, and predicate rules for the new policy
 * @export
 * @interface ExtendPolicyRequestFormula
 */
export interface ExtendPolicyRequestFormula {
    /**
     * 
     * @type {string}
     * @memberof ExtendPolicyRequestFormula
     */
    formulaType: ExtendPolicyRequestFormulaFormulaTypeEnum;
}


/**
 * @export
 */
export const ExtendPolicyRequestFormulaFormulaTypeEnum = {
    Conjunction: 'Conjunction',
    Disjunction: 'Disjunction',
    CompoundAtom: 'CompoundAtom'
} as const;
export type ExtendPolicyRequestFormulaFormulaTypeEnum = typeof ExtendPolicyRequestFormulaFormulaTypeEnum[keyof typeof ExtendPolicyRequestFormulaFormulaTypeEnum];


/**
 * Check if a given object implements the ExtendPolicyRequestFormula interface.
 */
export function instanceOfExtendPolicyRequestFormula(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "formulaType" in value;

    return isInstance;
}

export function ExtendPolicyRequestFormulaFromJSON(json: any): ExtendPolicyRequestFormula {
    return ExtendPolicyRequestFormulaFromJSONTyped(json, false);
}

export function ExtendPolicyRequestFormulaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendPolicyRequestFormula {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formulaType': json['formulaType'],
    };
}

export function ExtendPolicyRequestFormulaToJSON(value?: ExtendPolicyRequestFormula | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formulaType': value.formulaType,
    };
}

