/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgPortalError } from './OrgPortalError';
import {
    OrgPortalErrorFromJSON,
    OrgPortalErrorFromJSONTyped,
    OrgPortalErrorToJSON,
} from './OrgPortalError';

/**
 * 
 * @export
 * @interface CreateTemplateFields201ResponseFieldsInner
 */
export interface CreateTemplateFields201ResponseFieldsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateTemplateFields201ResponseFieldsInner
     */
    fieldKindID?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTemplateFields201ResponseFieldsInner
     */
    templateFieldID?: string;
    /**
     * 
     * @type {OrgPortalError}
     * @memberof CreateTemplateFields201ResponseFieldsInner
     */
    error?: OrgPortalError;
}

/**
 * Check if a given object implements the CreateTemplateFields201ResponseFieldsInner interface.
 */
export function instanceOfCreateTemplateFields201ResponseFieldsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateTemplateFields201ResponseFieldsInnerFromJSON(json: any): CreateTemplateFields201ResponseFieldsInner {
    return CreateTemplateFields201ResponseFieldsInnerFromJSONTyped(json, false);
}

export function CreateTemplateFields201ResponseFieldsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTemplateFields201ResponseFieldsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldKindID': !exists(json, 'fieldKindID') ? undefined : json['fieldKindID'],
        'templateFieldID': !exists(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'error': !exists(json, 'error') ? undefined : OrgPortalErrorFromJSON(json['error']),
    };
}

export function CreateTemplateFields201ResponseFieldsInnerToJSON(value?: CreateTemplateFields201ResponseFieldsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldKindID': value.fieldKindID,
        'templateFieldID': value.templateFieldID,
        'error': OrgPortalErrorToJSON(value.error),
    };
}

