// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "@src/components/Modals";
import { DetailsDrawer } from "../../layouts/DetailsDrawer";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { Overview } from "./Overview";
import { Rules } from "./Rules";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin, Tabs } from "../../components";
import { useApi } from "@src/api/api";
import { useDefaultErrorHandler } from "@src/utils/useDefaultErrorHandler";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/core";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type {
  ExtendFieldKindRequestExtendPermissionEnum,
  ExtendFieldKindRequestReadPermissionEnum,
  ExtendFieldKindRequestShareExtendPermissionEnum,
  ExtendFieldKindRequestShareReadPermissionEnum,
  GetFieldKind200Response,
} from "../../gen/org-portal/models";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";

const tabs = [
  {
    key: "overview",
    label: "Overview",
  },
  { key: "rules", label: "Rules" },
];

const { None, Some } = Helpers;
const screenName = "FieldDetails";

type Props = {
  readonly fieldKindID: string | undefined;
  readonly onClose: () => void;
  readonly onPressEdit: () => void;
  readonly onClone: () => void;
};

export const FieldDetailsScreen = ({ fieldKindID, onClone, onClose, onPressEdit }: Props) => {
  const { errorHandler: serverErrorHandler } = useServerErrorHandler();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].key);
  const [fieldKindData, setFieldKindData] = useState<GetFieldKind200Response>();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { errorHandler } = useDefaultErrorHandler();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      justifyContent: "center",
    },
    tabsContainer: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingHorizontal: 32,
    },
  });

  useEffect(() => {
    const fetchFieldKindData = async () => {
      try {
        setIsLoading(true);
        if (Some(fieldKindID)) {
          const res = await api.getFieldKind({ fieldKindID, orgID: selectedOrgId });
          setFieldKindData(res);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFieldKindData();
  }, [api, errorHandler, fieldKindID, selectedOrgId]);

  const getRootFieldKind = async (
    parentFieldKindID: string,
    iterCount: number
  ): Promise<GetFieldKind200Response> => {
    if (iterCount >= 100) {
      // A field kind depth of 100+ seems extremely unlikely
      throw new Error("Cannot get root field kind");
    }
    const response = await api.getFieldKind({
      fieldKindID: parentFieldKindID,
      orgID: selectedOrgId,
    });
    if (response.parentFieldKindID === "") {
      return response;
    }

    return getRootFieldKind(response.parentFieldKindID, iterCount + 1);
  };

  const cloneField = async () => {
    setIsConfirmationModalOpen(false);
    setIsLoading(true);

    if (None(fieldKindData)) {
      throw new Error("Field kind is not defined");
    }

    const parentFieldKindID: string = fieldKindData.parentFieldKindID;

    try {
      const root = await getRootFieldKind(parentFieldKindID, 1);

      const child = fieldKindData;
      const rootRules = root.ownValidationRules;
      const rootRuleConditions = Some(root.ownValidationRules) ? rootRules.ruleConditions : [];

      const childRules = child.ownValidationRules;
      const inheritedRules = child.inheritedValidationRules ?? [];
      const inheritedRuleConditions = inheritedRules.map(rule => rule.ruleConditions);

      // If there are any inherited rules that don't match the root rule condition,
      // they need to be copied to the new field
      const requiredInheritedRules = inheritedRuleConditions.filter(
        childRC => rootRuleConditions !== childRC
      );

      const allRequiredRules = requiredInheritedRules.flat(1).concat(childRules.ruleConditions);

      const fieldKindPerms = fieldKindData.permissions ?? [];
      const readPerm = fieldKindPerms.find(p => p.action === "read");
      const extendPerm = fieldKindPerms.find(p => p.action === "extend");
      if (None(readPerm) || None(extendPerm)) {
        throw new Error("Permissions not defined on this field kind.");
      }

      const searchCloneName = `Duplicate of '${child.name}'`;

      // search for any field kinds that have the duplicate's name
      // if we find any, increment the name until i
      const searchRes = await api.search({
        orgID: selectedOrgId,
        query: {
          objectName: searchCloneName,
          objectType: "fieldkind",
        },
      });

      const similarNames = new Set(searchRes.fieldKinds?.map(fk => fk.name));
      const candidateCloneName =
        similarNames.size > 0
          ? `Duplicate of '${child.name}' ${similarNames.size + 1}`
          : searchCloneName;

      const cloneResp = await api.extendFieldKind({
        extendFieldKindRequest: {
          description: child.description,
          extendPermission: extendPerm.permitted
            .grantedToName as ExtendFieldKindRequestExtendPermissionEnum,
          name: candidateCloneName,
          readPermission: readPerm.permitted
            .grantedToName as ExtendFieldKindRequestReadPermissionEnum,
          ruleValid: allRequiredRules.map(rule => ({
            arguments: rule.arguments,
            errorMessage: rule.errorMessage,
            predicate: rule.predicate,
            // a null target implies the field itself
            target: rule.target === "" ? null : rule.target,
          })),

          shareExtendPermission: extendPerm.permissibleToPermit
            .grantedToName as ExtendFieldKindRequestShareExtendPermissionEnum,

          shareReadPermission: readPerm.permissibleToPermit
            .grantedToName as ExtendFieldKindRequestShareReadPermissionEnum,
        },
        fieldKindID: root.fieldKindID,
        orgID: selectedOrgId,
      });
      onClone();

      if (None(cloneResp.fieldKind)) {
        throw new Error("extendFieldKind did not return cloned field kind.");
      }
      navigation.push("Fields", {
        fieldId: cloneResp.fieldKind.fieldKindID,
      });
    } catch (error) {
      serverErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <View style={styles.container}>
        <Spin />
      </View>
    );
  }

  if (None(fieldKindData)) {
    return (
      <View style={styles.container}>
        <Body size="l">No data</Body>
      </View>
    );
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <DetailsDrawer
          actionButtons={
            <View style={{ flexDirection: "row" }}>
              {Some(fieldKindData.isMine) && fieldKindData.isMine && (
                <>
                  <Button
                    onPress={() => {
                      onPressEdit();
                      navigation.navigate("EditField", { id: fieldKindData.fieldKindID });
                    }}
                    size="small"
                    testProps={{
                      elementId: fieldKindData.fieldKindID,
                      elementName: "EditButton",
                      screenName,
                    }}
                    text="Edit"
                    type="secondary"
                  />
                  <HorizontalSpacer />
                </>
              )}
              {Some(fieldKindData.extendable) && fieldKindData.extendable && (
                <>
                  <Button
                    onPress={() => {
                      navigation.navigate("ExtendField", {
                        id: fieldKindData.fieldKindID,
                      });
                    }}
                    size="small"
                    testProps={{
                      elementId: fieldKindData.fieldKindID,
                      elementName: "ExtendButton",
                      screenName,
                    }}
                    text="Extend"
                    type="secondary"
                  />
                  <HorizontalSpacer />
                </>
              )}

              <Button
                onPress={() => {
                  setIsConfirmationModalOpen(true);
                }}
                size="small"
                testProps={{
                  elementId: fieldKindData.fieldKindID,
                  elementName: "Clone",
                  screenName,
                }}
                text="Clone"
                type="secondary"
              />
            </View>
          }
          onPressClose={() => {
            setSelectedTab(tabs[0].key);
            onClose();
          }}
          subTitle={`Field kind ID: ${fieldKindData.fieldKindID}`}
          testProps={{
            elementId: fieldKindData.fieldKindID,
            elementName: "fieldDetails",
            screenName,
          }}
          title={fieldKindData.name}
        >
          <View style={styles.tabsContainer}>
            <Tabs
              items={tabs}
              onChange={event => {
                setSelectedTab(event);
              }}
              selected={selectedTab}
              testProps={{
                elementId: fieldKindData.fieldKindID,
                elementName: "fieldDetails",
                screenName,
              }}
            />
          </View>
          <ScrollView>
            <View style={{ paddingVertical: theme.spacing.xxl }}>
              {selectedTab === "overview" && (
                <Overview
                  fieldKindData={fieldKindData}
                  testProps={{
                    elementId: fieldKindData.fieldKindID,
                    elementName: "OverviewTab",
                    screenName,
                  }}
                />
              )}
              {selectedTab === "rules" && (
                <Rules
                  fieldKindData={{
                    fieldType: fieldKindData.dataType,
                    inheritedValidationRules: fieldKindData.inheritedValidationRules,
                    isOwnField: fieldKindData.isMine,
                    ownValidationRules: fieldKindData.ownValidationRules,
                  }}
                  testProps={{
                    elementId: fieldKindData.fieldKindID,
                    elementName: "rulesTab",
                    screenName,
                  }}
                />
              )}
            </View>
          </ScrollView>
        </DetailsDrawer>
      </Spin>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="ok"
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            cloneField();
          }}
          testProps={{
            elementName: "cloneFieldConfirm",
            screenName,
          }}
          text="Cloning creates a duplicate without any connections to the original field"
          title="Are you sure you want to clone this field?"
        />
      )}
    </>
  );
};
