// Copyright 2024 Merit International Inc. All Rights Reserved

import { type FC, useEffect, useRef } from "react";
import { TextInput as FETextInput } from "@merit/frontend-components";
import { useMaskedInputProps } from "react-native-mask-input";
import type { Mask } from "react-native-mask-input";
import type { TextInput } from "react-native";
import type { TextInputProps } from "@merit/frontend-components";

export type MaskedTextInputProps = TextInputProps & {
  readonly mask?: Mask;
  readonly name: string;
};

export const MaskedTextInput: FC<MaskedTextInputProps> = props => {
  const { mask, onChangeText, value, ...rest } = props;
  const { name } = props;
  const ref = useRef<TextInput>(null);

  // TODO: MA-2910 adds the name prop to TextInput so we won't need to do this craziness
  useEffect(() => {
    if (ref.current !== null) {
      const htmlInput = ref.current as unknown as HTMLInputElement;
      htmlInput.setAttribute("name", name);
    }
  }, [name]);

  const inputProps = useMaskedInputProps({
    mask,
    onChangeText,
    value,
  });

  if (mask === undefined) {
    return <FETextInput {...props} ref={ref} />;
  }

  return <FETextInput {...rest} {...inputProps} />;
};
