// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body } from "@merit/frontend-components";
import { EllipsisText } from "../../components/EllipsisText";
import React from "react";
import type { OPTestProps } from "../../types/TestProps";

type Props = {
  readonly text: string;
  readonly testProps: OPTestProps | undefined;
  readonly capitalize?: boolean;
};

export const MaybeEliptText = ({ capitalize, testProps, text }: Props) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (text.length > 15) {
    return <EllipsisText capitalize={capitalize} testProps={testProps} text={text} />;
  }

  return (
    <Body capitalize={capitalize} testProps={testProps}>
      {text}
    </Body>
  );
};
