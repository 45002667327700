// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { Pressable, View } from "react-native";
import { getDateTimeString } from "../../../utils/time";
import { useNavigation } from "@react-navigation/native";
import type { GetDatasource200Response } from "../../../gen/org-portal";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { OPTestProps } from "../../../types/TestProps";
import type { RouteParams } from "../../../Router";

type Props = {
  readonly datasource: GetDatasource200Response;
  readonly testProps: OPTestProps;
};

const { None, Some } = Helpers;
const SCREEN_NAME = "DatasourcesOverview";

export const Overview = ({
  datasource: { datasource, ingestionLog, mappedTemplates },
  testProps,
}: Props) => {
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "Templates">>();

  return (
    <>
      <View style={{ marginTop: theme.spacing.xxl }}>
        <Body
          style={{ fontWeight: theme.fontWeights.semiBold }}
          testProps={{
            elementId: datasource.id,
            elementName: Some(testProps)
              ? `${testProps.elementName}DescriptionLabel`
              : "DescriptionLabel",
            screenName: SCREEN_NAME,
          }}
        >
          Description
        </Body>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: datasource.id,
            elementName: Some(testProps)
              ? `${testProps.elementName}DescriptionValue`
              : "DescriptionValue",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(datasource.description) ? datasource.description : "--"}
        </Body>
      </View>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading
          level="5"
          testProps={{
            elementId: datasource.id,
            elementName: Some(testProps)
              ? `${testProps.elementName}LastIngestedByLabel`
              : "LastIngestedByLabel",
            screenName: SCREEN_NAME,
          }}
        >
          Last ingested by
        </Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <View style={{ flexDirection: "row" }}>
          <Body
            testProps={{
              elementId: datasource.id,
              elementName: Some(testProps)
                ? `${testProps.elementName}LastIngestedByEmailValue`
                : "LastIngestedByEmailValue",
              screenName: SCREEN_NAME,
            }}
          >
            {None(ingestionLog.adminEmail) || ingestionLog.adminEmail === ""
              ? "--"
              : ingestionLog.adminEmail}
          </Body>
          <HorizontalSpacer size={10} />
          <Body style={{ color: theme.colors.text.subdued }}>|</Body>
          <HorizontalSpacer size={10} />
          <Body
            testProps={{
              elementId: datasource.id,
              elementName: Some(testProps)
                ? `${testProps.elementName}LastIngestedByTimeValue`
                : "LastIngestedByTimeValue",
              screenName: SCREEN_NAME,
            }}
          >
            {None(ingestionLog.createdAt) || ingestionLog.createdAt === ""
              ? "--"
              : getDateTimeString(ingestionLog.createdAt)}
          </Body>
        </View>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <View style={{ alignItems: "flex-start", flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <Body
            style={{ fontWeight: theme.fontWeights.semiBold }}
            testProps={{
              elementId: datasource.id,
              elementName: Some(testProps)
                ? `${testProps.elementName}LastUpdatedLabel`
                : "LastUpdatedLabel",
              screenName: SCREEN_NAME,
            }}
          >
            Last updated
          </Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <View style={{ flexDirection: "row" }}>
            <Body
              testProps={{
                elementId: datasource.id,
                elementName: Some(testProps)
                  ? `${testProps.elementName}LastUpdatedValue`
                  : "LastUpdatedValue",
                screenName: SCREEN_NAME,
              }}
            >
              {Some(datasource.updatedAt) && datasource.updatedAt !== ""
                ? getDateTimeString(datasource.updatedAt)
                : "--"}
            </Body>
          </View>
        </View>

        <View style={{ flex: 1 }}>
          <Body
            style={{ fontWeight: theme.fontWeights.semiBold }}
            testProps={{
              elementId: datasource.id,
              elementName: Some(testProps)
                ? `${testProps.elementName}CreatedByLabel`
                : "CreatedByLabel",
              screenName: SCREEN_NAME,
            }}
          >
            Created by
          </Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <Body testProps={testProps}>
            <Body
              testProps={{
                elementId: datasource.id,
                elementName: Some(testProps)
                  ? `${testProps.elementName}CreatedByEmailValue`
                  : "CreatedByEmailValue",
                screenName: SCREEN_NAME,
              }}
            >
              {datasource.createdBy ?? "--"}
            </Body>
            <HorizontalSpacer size={10} />
            <Body style={{ color: theme.colors.text.subdued }}>|</Body>
            <HorizontalSpacer size={10} />
            <Body
              testProps={{
                elementId: datasource.id,
                elementName: Some(testProps)
                  ? `${testProps.elementName}CreatedByTimeValue`
                  : "CreatedByTimeValue",
                screenName: SCREEN_NAME,
              }}
            >
              {None(datasource.createdAt) || datasource.createdAt === ""
                ? "--"
                : getDateTimeString(datasource.createdAt)}
            </Body>
          </Body>
        </View>
      </View>
      <VerticalSpacer size={theme.spacing.xxl} />
      <View>
        {Some(mappedTemplates) && mappedTemplates.length !== 0 ? (
          <>
            <Body
              style={{ fontWeight: theme.fontWeights.semiBold }}
              testProps={{
                elementId: datasource.id,
                elementName: Some(testProps)
                  ? `${testProps.elementName}MappedToLabel`
                  : "MappedToLabel",
                screenName: SCREEN_NAME,
              }}
            >
              Templates this data source is mapped to
            </Body>
            <VerticalSpacer size={theme.spacing.xs} />
            {mappedTemplates.map(template => (
              <Pressable
                key={template.id}
                onPress={() => {
                  navigation.navigate("Templates", { initialTemplateId: template.id });
                }}
              >
                <Body
                  style={{ color: theme.colors.interactive.default }}
                  testProps={{
                    elementId: datasource.id,
                    elementName: Some(testProps)
                      ? `${testProps.elementName}MappedToValue`
                      : "MappedToValue",
                    screenName: SCREEN_NAME,
                  }}
                >
                  {template.name}
                </Body>
              </Pressable>
            ))}
          </>
        ) : (
          <Body
            style={{ fontWeight: theme.fontWeights.semiBold }}
            testProps={{
              elementId: datasource.id,
              elementName: Some(testProps)
                ? `${testProps.elementName}MappedToValue`
                : "MappedToValue",
              screenName: SCREEN_NAME,
            }}
          >
            This data source is not mapped to any templates
          </Body>
        )}
      </View>
    </>
  );
};
