// Copyright 2024 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { Button, Heading, Icon, TextInput, useTheme } from "@merit/frontend-components";
import { ErrorMessage, Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../Spacer";
import { Pressable, View } from "react-native";
import { useFocus } from "react-native-web-hooks";
import { useRef } from "react";
import { useRoute } from "@react-navigation/native";
import type { DimensionValue } from "react-native";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

const { Some } = Helpers;

type Route = RouteProp<RouteParams>;

type Props = {
  readonly disabled?: boolean;
  readonly onSearch: (value: string) => void;
  readonly onClear?: () => void;
  readonly initialValue?: string;
  readonly width?: DimensionValue;
  readonly size?: "large" | "medium" | "small";
};

export const SearchForm = ({
  disabled = false,
  initialValue,
  onClear,
  onSearch,
  size,
  width,
}: Props) => {
  const { theme } = useTheme();

  const ref = useRef(null);
  const route = useRoute<Route>();
  const isFocused = useFocus(ref);

  const validationSchema = yup.object({
    name: yup.string().trim().min(3, "Search query must be at least 3 characters long"),
  });

  const errorText = (error: string) => (
    <View style={{ paddingVertical: theme.spacing.xs }}>
      <Heading color={theme.colors.text.alert.critical} level="6">
        {error}
      </Heading>
    </View>
  );

  const getClearIconPosition = () => {
    if (size === "large") {
      return 12;
    }

    if (size === "medium") {
      return 10;
    }

    return 6;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ name: initialValue ?? "" }}
      onSubmit={(values, formikHelper) => {
        if (values.name.length > 0) {
          onSearch(values.name);
          formikHelper.setTouched({});
        }
      }}
      validationSchema={validationSchema}
    >
      {({ getFieldMeta, handleChange, handleSubmit, setFieldValue, values }) => (
        <View>
          <View style={{ flexDirection: "row" }}>
            <View style={[{ width: width ?? 258 }, isFocused && theme.elevations.depth4]}>
              <TextInput
                leftIcon="searchSmallSubdued"
                onBlur={() => {
                  if (values.name.trim().length === 0 && Some(onClear)) {
                    onClear();
                  }
                }}
                onChangeText={handleChange("name")}
                onKeyPress={event => {
                  if (event.nativeEvent.key === "Enter" && !disabled) {
                    handleSubmit();
                  }
                }}
                placeholder="Search"
                ref={ref}
                size={size ?? "small"}
                testProps={{
                  elementName: "searchInput",
                  screenName: route.name,
                }}
                value={values.name}
              />
              {values.name.trim().length > 0 && (
                <Pressable
                  accessibilityLabel="clear-search"
                  accessibilityRole="imagebutton"
                  accessible
                  disabled={disabled}
                  onPress={() => {
                    setFieldValue("name", "");
                    if (Some(onClear)) {
                      onClear();
                    }
                  }}
                  style={{ position: "absolute", right: 8, top: getClearIconPosition(), zIndex: 1 }}
                >
                  <Icon name="closeSmallDefault" />
                </Pressable>
              )}
            </View>

            <HorizontalSpacer size={theme.spacing.s} />
            <Button
              disabled={disabled}
              onPress={handleSubmit}
              size={size ?? "small"}
              testProps={{
                elementName: "searchButton",
                screenName: route.name,
              }}
              text="Search"
              type="secondary"
            />
          </View>
          {Some(getFieldMeta("name").error) && getFieldMeta("name").touched ? (
            <ErrorMessage name="name">{errorText}</ErrorMessage>
          ) : (
            <View style={{ paddingVertical: theme.spacing.xs }}>
              <Heading
                level="6"
                testProps={{
                  elementName: "minimumCharactersText",
                  screenName: route.name,
                }}
              >
                Minimum 3 characters
              </Heading>
            </View>
          )}
        </View>
      )}
    </Formik>
  );
};
