/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner } from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner';
import type { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner } from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner';

/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule
 */
export interface GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule {
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule
     */
    readableRuleConditions: Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner>;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule
     */
    ruleConditions: Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner>;
}

/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "readableRuleConditions" in value;
    isInstance = isInstance && "ruleConditions" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule {
    return GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readableRuleConditions': ((json['readableRuleConditions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerFromJSON)),
        'ruleConditions': ((json['ruleConditions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerFromJSON)),
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readableRuleConditions': ((value.readableRuleConditions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerToJSON)),
        'ruleConditions': ((value.ruleConditions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerToJSON)),
    };
}

