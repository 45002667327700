// Copyright 2023 Merit International Inc. All Rights Reserved

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(tz);

const DATE_FORMAT = "YYYY-MM-DD";
const DATETIME_FORMAT = "YYYY-MM-DD h:mm A";

export const getDateTimeString = (dateTime: Date | string): string =>
  dayjs(dateTime).format(DATETIME_FORMAT);
export const getDateString = (date: Date | string): string => dayjs(date).format(DATE_FORMAT);

export const convertDateToTimeZone = (date: Date | string, timezone: string | undefined) =>
  dayjs.utc(date).tz(timezone).toDate();

export const endOfDate = (date: Date | string) => dayjs.utc(date).endOf("day");
