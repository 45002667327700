/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectsProjectIDExportPOSTRequestOneOf1Bleed } from './ProjectsProjectIDExportPOSTRequestOneOf1Bleed';
import {
    ProjectsProjectIDExportPOSTRequestOneOf1BleedFromJSON,
    ProjectsProjectIDExportPOSTRequestOneOf1BleedFromJSONTyped,
    ProjectsProjectIDExportPOSTRequestOneOf1BleedToJSON,
} from './ProjectsProjectIDExportPOSTRequestOneOf1Bleed';

/**
 * 
 * @export
 * @interface ProjectsProjectIDExportPOSTRequestOneOf1
 */
export interface ProjectsProjectIDExportPOSTRequestOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1
     */
    format: ProjectsProjectIDExportPOSTRequestOneOf1FormatEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1
     */
    pageId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1
     */
    pageIds?: Array<string>;
    /**
     * 300 by default for normal sizes, 150 for large format.
     * @type {number}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1
     */
    dpi?: number;
    /**
     * 
     * @type {ProjectsProjectIDExportPOSTRequestOneOf1Bleed}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1
     */
    bleed?: ProjectsProjectIDExportPOSTRequestOneOf1Bleed;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1
     */
    notificationUrl?: string;
}


/**
 * @export
 */
export const ProjectsProjectIDExportPOSTRequestOneOf1FormatEnum = {
    Pdf: 'pdf'
} as const;
export type ProjectsProjectIDExportPOSTRequestOneOf1FormatEnum = typeof ProjectsProjectIDExportPOSTRequestOneOf1FormatEnum[keyof typeof ProjectsProjectIDExportPOSTRequestOneOf1FormatEnum];


/**
 * Check if a given object implements the ProjectsProjectIDExportPOSTRequestOneOf1 interface.
 */
export function instanceOfProjectsProjectIDExportPOSTRequestOneOf1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "format" in value;

    return isInstance;
}

export function ProjectsProjectIDExportPOSTRequestOneOf1FromJSON(json: any): ProjectsProjectIDExportPOSTRequestOneOf1 {
    return ProjectsProjectIDExportPOSTRequestOneOf1FromJSONTyped(json, false);
}

export function ProjectsProjectIDExportPOSTRequestOneOf1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportPOSTRequestOneOf1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': json['format'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'pageId': !exists(json, 'page_id') ? undefined : json['page_id'],
        'pageIds': !exists(json, 'page_ids') ? undefined : json['page_ids'],
        'dpi': !exists(json, 'dpi') ? undefined : json['dpi'],
        'bleed': !exists(json, 'bleed') ? undefined : ProjectsProjectIDExportPOSTRequestOneOf1BleedFromJSON(json['bleed']),
        'notificationUrl': !exists(json, 'notification_url') ? undefined : json['notification_url'],
    };
}

export function ProjectsProjectIDExportPOSTRequestOneOf1ToJSON(value?: ProjectsProjectIDExportPOSTRequestOneOf1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': value.format,
        'filename': value.filename,
        'page_id': value.pageId,
        'page_ids': value.pageIds,
        'dpi': value.dpi,
        'bleed': ProjectsProjectIDExportPOSTRequestOneOf1BleedToJSON(value.bleed),
        'notification_url': value.notificationUrl,
    };
}

