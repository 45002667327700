/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContainerField } from './ContainerField';
import {
    ContainerFieldFromJSON,
    ContainerFieldFromJSONTyped,
    ContainerFieldToJSON,
} from './ContainerField';
import type { ContainerState } from './ContainerState';
import {
    ContainerStateFromJSON,
    ContainerStateFromJSONTyped,
    ContainerStateToJSON,
} from './ContainerState';
import type { EntityInfo } from './EntityInfo';
import {
    EntityInfoFromJSON,
    EntityInfoFromJSONTyped,
    EntityInfoToJSON,
} from './EntityInfo';
import type { ValidationErrorResult } from './ValidationErrorResult';
import {
    ValidationErrorResultFromJSON,
    ValidationErrorResultFromJSONTyped,
    ValidationErrorResultToJSON,
} from './ValidationErrorResult';

/**
 * A collection of data issued to an entity.
 * @export
 * @interface Container
 */
export interface Container {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof Container
     */
    id: string;
    /**
     * The id of the template that generated this container.
     * @type {string}
     * @memberof Container
     */
    templateId?: string;
    /**
     * The name of the template that generated this container.
     * @type {string}
     * @memberof Container
     */
    name?: string;
    /**
     * The description of the template that generated this container.
     * @type {string}
     * @memberof Container
     */
    description?: string;
    /**
     * 
     * @type {EntityInfo}
     * @memberof Container
     */
    issuer?: EntityInfo;
    /**
     * 
     * @type {EntityInfo}
     * @memberof Container
     */
    recipient?: EntityInfo;
    /**
     * Whether the container is complete.
     * @type {boolean}
     * @memberof Container
     */
    completed?: boolean;
    /**
     * A list of errors that explain failed completeness rules.
     * @type {Array<ValidationErrorResult>}
     * @memberof Container
     */
    completenessFailures?: Array<ValidationErrorResult>;
    /**
     * Whether the container is active.
     * @type {boolean}
     * @memberof Container
     */
    active?: boolean;
    /**
     * A list of errors that explain failed activeness rules.
     * @type {Array<ValidationErrorResult>}
     * @memberof Container
     */
    activenessFailures?: Array<ValidationErrorResult>;
    /**
     * 
     * @type {ContainerState}
     * @memberof Container
     */
    state?: ContainerState;
    /**
     * 
     * @type {Date}
     * @memberof Container
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Container
     */
    acceptedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Container
     */
    authorizedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Container
     */
    rejectedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Container
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Container
     */
    revokedAt?: Date | null;
    /**
     * Field level values.
     * @type {Array<ContainerField>}
     * @memberof Container
     */
    fields?: Array<ContainerField>;
}

/**
 * Check if a given object implements the Container interface.
 */
export function instanceOfContainer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ContainerFromJSON(json: any): Container {
    return ContainerFromJSONTyped(json, false);
}

export function ContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Container {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'issuer': !exists(json, 'issuer') ? undefined : EntityInfoFromJSON(json['issuer']),
        'recipient': !exists(json, 'recipient') ? undefined : EntityInfoFromJSON(json['recipient']),
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'completenessFailures': !exists(json, 'completenessFailures') ? undefined : ((json['completenessFailures'] as Array<any>).map(ValidationErrorResultFromJSON)),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'activenessFailures': !exists(json, 'activenessFailures') ? undefined : ((json['activenessFailures'] as Array<any>).map(ValidationErrorResultFromJSON)),
        'state': !exists(json, 'state') ? undefined : ContainerStateFromJSON(json['state']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'acceptedAt': !exists(json, 'acceptedAt') ? undefined : (json['acceptedAt'] === null ? null : new Date(json['acceptedAt'])),
        'authorizedAt': !exists(json, 'authorizedAt') ? undefined : (json['authorizedAt'] === null ? null : new Date(json['authorizedAt'])),
        'rejectedAt': !exists(json, 'rejectedAt') ? undefined : (json['rejectedAt'] === null ? null : new Date(json['rejectedAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'revokedAt': !exists(json, 'revokedAt') ? undefined : (json['revokedAt'] === null ? null : new Date(json['revokedAt'])),
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(ContainerFieldFromJSON)),
    };
}

export function ContainerToJSON(value?: Container | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'templateId': value.templateId,
        'name': value.name,
        'description': value.description,
        'issuer': EntityInfoToJSON(value.issuer),
        'recipient': EntityInfoToJSON(value.recipient),
        'completed': value.completed,
        'completenessFailures': value.completenessFailures === undefined ? undefined : ((value.completenessFailures as Array<any>).map(ValidationErrorResultToJSON)),
        'active': value.active,
        'activenessFailures': value.activenessFailures === undefined ? undefined : ((value.activenessFailures as Array<any>).map(ValidationErrorResultToJSON)),
        'state': ContainerStateToJSON(value.state),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'acceptedAt': value.acceptedAt === undefined ? undefined : (value.acceptedAt === null ? null : value.acceptedAt.toISOString()),
        'authorizedAt': value.authorizedAt === undefined ? undefined : (value.authorizedAt === null ? null : value.authorizedAt.toISOString()),
        'rejectedAt': value.rejectedAt === undefined ? undefined : (value.rejectedAt === null ? null : value.rejectedAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'revokedAt': value.revokedAt === undefined ? undefined : (value.revokedAt === null ? null : value.revokedAt.toISOString()),
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(ContainerFieldToJSON)),
    };
}

