/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Agent,
  AgentCreateProperties,
  AgentList,
  AgentUpdateProperties,
  Errors,
} from '../models/index';
import {
    AgentFromJSON,
    AgentToJSON,
    AgentCreatePropertiesFromJSON,
    AgentCreatePropertiesToJSON,
    AgentListFromJSON,
    AgentListToJSON,
    AgentUpdatePropertiesFromJSON,
    AgentUpdatePropertiesToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
} from '../models/index';

export interface AcceptEntityTOSRequest {
    agentID: string;
    authorization?: string;
}

export interface CreateAgentRequest {
    agent: AgentCreateProperties;
    authorization?: string;
}

export interface GetAgentRequest {
    agentID: string;
    authorization?: string;
}

export interface GetAgentsRequest {
    authorization?: string;
    owner?: string;
    endingBefore?: string;
    startingAfter?: string;
    limit?: number;
    sortBy?: GetAgentsSortByEnum;
}

export interface LinkAgentRequest {
    agentID: string;
    entityID: string;
    authorization?: string;
}

export interface RemoveAgentRequest {
    agentID: string;
    authorization?: string;
}

export interface ReportLinkRequest {
    sessionToken: string;
    state: string;
}

export interface UpdateAgentRequest {
    agentID: string;
    authorization?: string;
    properties?: AgentUpdateProperties;
}

/**
 * 
 */
export class AgentsApi extends runtime.BaseAPI {

    /**
     * `create:agent_entity_link`  Acknowledge that the authenticated entity has accepted the terms of service for an agent 
     * Accept ToS/Privacy Policies
     */
    async acceptEntityTOSRaw(requestParameters: AcceptEntityTOSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling acceptEntityTOS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents/{agentID}/accept-tos`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `create:agent_entity_link`  Acknowledge that the authenticated entity has accepted the terms of service for an agent 
     * Accept ToS/Privacy Policies
     */
    async acceptEntityTOS(requestParameters: AcceptEntityTOSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptEntityTOSRaw(requestParameters, initOverrides);
    }

    /**
     * `create:agent`  Create a new agent 
     * Create Agent
     */
    async createAgentRaw(requestParameters: CreateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Agent>> {
        if (requestParameters.agent === null || requestParameters.agent === undefined) {
            throw new runtime.RequiredError('agent','Required parameter requestParameters.agent was null or undefined when calling createAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgentCreatePropertiesToJSON(requestParameters.agent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentFromJSON(jsonValue));
    }

    /**
     * `create:agent`  Create a new agent 
     * Create Agent
     */
    async createAgent(requestParameters: CreateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Agent> {
        const response = await this.createAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:agent`  Get a single agent. This endpoint should only be called for agents that a given entity owns, because this  endpoint provides additional information to an entity beyond what the `/v1/links/entity/{entityID}` endpoint does only for agents that the entity owns.  To obtain information on agents that an entity is linked to and doesn\'t own, the  `/v1/links/entity/{entityID}` endpoint should be called.  The  `/v1/links/entity/{entityID}` endpoint will provide all the information accessible to a given entity for  linked agents that an entity doesn\'t own. 
     * Get Agent
     */
    async getAgentRaw(requestParameters: GetAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Agent>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling getAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents/{agentID}`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentFromJSON(jsonValue));
    }

    /**
     * `read:agent`  Get a single agent. This endpoint should only be called for agents that a given entity owns, because this  endpoint provides additional information to an entity beyond what the `/v1/links/entity/{entityID}` endpoint does only for agents that the entity owns.  To obtain information on agents that an entity is linked to and doesn\'t own, the  `/v1/links/entity/{entityID}` endpoint should be called.  The  `/v1/links/entity/{entityID}` endpoint will provide all the information accessible to a given entity for  linked agents that an entity doesn\'t own. 
     * Get Agent
     */
    async getAgent(requestParameters: GetAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Agent> {
        const response = await this.getAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:agent`  Get a paginated list of agents optionally sorted by owner 
     * Get Agents
     */
    async getAgentsRaw(requestParameters: GetAgentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgentList>> {
        const queryParameters: any = {};

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentListFromJSON(jsonValue));
    }

    /**
     * `read:agent`  Get a paginated list of agents optionally sorted by owner 
     * Get Agents
     */
    async getAgents(requestParameters: GetAgentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgentList> {
        const response = await this.getAgentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `create:agent_entity_link`  Allow an agent to make API requests on behalf of an entity 
     * Link Entity To Agent
     */
    async linkAgentRaw(requestParameters: LinkAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling linkAgent.');
        }

        if (requestParameters.entityID === null || requestParameters.entityID === undefined) {
            throw new runtime.RequiredError('entityID','Required parameter requestParameters.entityID was null or undefined when calling linkAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents/{agentID}/link/{entityID}`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))).replace(`{${"entityID"}}`, encodeURIComponent(String(requestParameters.entityID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `create:agent_entity_link`  Allow an agent to make API requests on behalf of an entity 
     * Link Entity To Agent
     */
    async linkAgent(requestParameters: LinkAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.linkAgentRaw(requestParameters, initOverrides);
    }

    /**
     * `remove:agent`  Remove an agent from the platform. This will permanently remove the agent from the platform, and the agent will no longer be allowed to make API requests.  
     * Remove Agent
     */
    async removeAgentRaw(requestParameters: RemoveAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling removeAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents/{agentID}`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `remove:agent`  Remove an agent from the platform. This will permanently remove the agent from the platform, and the agent will no longer be allowed to make API requests.  
     * Remove Agent
     */
    async removeAgent(requestParameters: RemoveAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeAgentRaw(requestParameters, initOverrides);
    }

    /**
     * Report an entity has linked to an agent
     */
    async reportLinkRaw(requestParameters: ReportLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionToken === null || requestParameters.sessionToken === undefined) {
            throw new runtime.RequiredError('sessionToken','Required parameter requestParameters.sessionToken was null or undefined when calling reportLink.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling reportLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.sessionToken !== undefined) {
            queryParameters['session_token'] = requestParameters.sessionToken;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/links/report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Report an entity has linked to an agent
     */
    async reportLink(requestParameters: ReportLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportLinkRaw(requestParameters, initOverrides);
    }

    /**
     * `update:agent`  Create a new version of the provided agent, with the updates from the request applied to the existing agent. If Sol is the agent owner, the changes will automatically take effect. If Sol is not the agent owner, Sol must approve the changes before they take effect. 
     * Update Agent
     */
    async updateAgentRaw(requestParameters: UpdateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Agent>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling updateAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents/{agentID}`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AgentUpdatePropertiesToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentFromJSON(jsonValue));
    }

    /**
     * `update:agent`  Create a new version of the provided agent, with the updates from the request applied to the existing agent. If Sol is the agent owner, the changes will automatically take effect. If Sol is not the agent owner, Sol must approve the changes before they take effect. 
     * Update Agent
     */
    async updateAgent(requestParameters: UpdateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Agent> {
        const response = await this.updateAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAgentsSortByEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type GetAgentsSortByEnum = typeof GetAgentsSortByEnum[keyof typeof GetAgentsSortByEnum];
