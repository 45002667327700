/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditAdminRolesRequest
 */
export interface EditAdminRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof EditAdminRolesRequest
     */
    adminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof EditAdminRolesRequest
     */
    adminOrgUUID: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditAdminRolesRequest
     */
    adminRoles: Array<string>;
}

/**
 * Check if a given object implements the EditAdminRolesRequest interface.
 */
export function instanceOfEditAdminRolesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "adminEmail" in value;
    isInstance = isInstance && "adminOrgUUID" in value;
    isInstance = isInstance && "adminRoles" in value;

    return isInstance;
}

export function EditAdminRolesRequestFromJSON(json: any): EditAdminRolesRequest {
    return EditAdminRolesRequestFromJSONTyped(json, false);
}

export function EditAdminRolesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditAdminRolesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminEmail': json['adminEmail'],
        'adminOrgUUID': json['adminOrgUUID'],
        'adminRoles': json['adminRoles'],
    };
}

export function EditAdminRolesRequestToJSON(value?: EditAdminRolesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminEmail': value.adminEmail,
        'adminOrgUUID': value.adminOrgUUID,
        'adminRoles': value.adminRoles,
    };
}

