/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';
import type { TemplateFieldColumnMapping } from './TemplateFieldColumnMapping';
import {
    TemplateFieldColumnMappingFromJSON,
    TemplateFieldColumnMappingFromJSONTyped,
    TemplateFieldColumnMappingToJSON,
} from './TemplateFieldColumnMapping';
import type { TemplateFieldFieldValidation } from './TemplateFieldFieldValidation';
import {
    TemplateFieldFieldValidationFromJSON,
    TemplateFieldFieldValidationFromJSONTyped,
    TemplateFieldFieldValidationToJSON,
} from './TemplateFieldFieldValidation';
import type { TemplateFieldKindInfo } from './TemplateFieldKindInfo';
import {
    TemplateFieldKindInfoFromJSON,
    TemplateFieldKindInfoFromJSONTyped,
    TemplateFieldKindInfoToJSON,
} from './TemplateFieldKindInfo';

/**
 * Representation of a named template field belonging to a template in an Org.
 * @export
 * @interface TemplateField
 */
export interface TemplateField {
    /**
     * The id of this template field.
     * @type {string}
     * @memberof TemplateField
     */
    id: string;
    /**
     * The given name of this template field.
     * @type {string}
     * @memberof TemplateField
     */
    name: string;
    /**
     * The description of this template field.
     * @type {string}
     * @memberof TemplateField
     */
    description?: string;
    /**
     * The id of the owning template.
     * @type {string}
     * @memberof TemplateField
     */
    templateID: string;
    /**
     * The ordered lineage of parent template fields by their external id. The order is from direct parent to
     * grandparent. So if Template3 extended from Template2 which extended from Template1, Template3's TemplateField
     * would have a lineage array of Template2's TemplateField ID and then Template1's TemplateFieldID.
     * 
     * @type {Array<string>}
     * @memberof TemplateField
     */
    lineage?: Array<string>;
    /**
     * 
     * @type {TemplateFieldKindInfo}
     * @memberof TemplateField
     */
    fieldKind: TemplateFieldKindInfo;
    /**
     * Whether this template field can be used to contact the recipient.
     * @type {boolean}
     * @memberof TemplateField
     */
    contact: boolean;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof TemplateField
     */
    permissions: Array<PermissionPair>;
    /**
     * 
     * @type {TemplateFieldColumnMapping}
     * @memberof TemplateField
     */
    fieldMapping?: TemplateFieldColumnMapping;
    /**
     * 
     * @type {TemplateFieldFieldValidation}
     * @memberof TemplateField
     */
    fieldValidation?: TemplateFieldFieldValidation;
}

/**
 * Check if a given object implements the TemplateField interface.
 */
export function instanceOfTemplateField(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "templateID" in value;
    isInstance = isInstance && "fieldKind" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function TemplateFieldFromJSON(json: any): TemplateField {
    return TemplateFieldFromJSONTyped(json, false);
}

export function TemplateFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'templateID': json['templateID'],
        'lineage': !exists(json, 'lineage') ? undefined : json['lineage'],
        'fieldKind': TemplateFieldKindInfoFromJSON(json['fieldKind']),
        'contact': json['contact'],
        'permissions': ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'fieldMapping': !exists(json, 'fieldMapping') ? undefined : TemplateFieldColumnMappingFromJSON(json['fieldMapping']),
        'fieldValidation': !exists(json, 'fieldValidation') ? undefined : TemplateFieldFieldValidationFromJSON(json['fieldValidation']),
    };
}

export function TemplateFieldToJSON(value?: TemplateField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'templateID': value.templateID,
        'lineage': value.lineage,
        'fieldKind': TemplateFieldKindInfoToJSON(value.fieldKind),
        'contact': value.contact,
        'permissions': ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'fieldMapping': TemplateFieldColumnMappingToJSON(value.fieldMapping),
        'fieldValidation': TemplateFieldFieldValidationToJSON(value.fieldValidation),
    };
}

