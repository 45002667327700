/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrgsGet200ResponseContainersInnerIssuer
 */
export interface OrgsGet200ResponseContainersInnerIssuer {
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerIssuer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerIssuer
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerIssuer
     */
    type: OrgsGet200ResponseContainersInnerIssuerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerIssuer
     */
    state: OrgsGet200ResponseContainersInnerIssuerStateEnum;
}


/**
 * @export
 */
export const OrgsGet200ResponseContainersInnerIssuerTypeEnum = {
    Organization: 'organization',
    Person: 'person'
} as const;
export type OrgsGet200ResponseContainersInnerIssuerTypeEnum = typeof OrgsGet200ResponseContainersInnerIssuerTypeEnum[keyof typeof OrgsGet200ResponseContainersInnerIssuerTypeEnum];

/**
 * @export
 */
export const OrgsGet200ResponseContainersInnerIssuerStateEnum = {
    None: 'none',
    Invited: 'invited',
    Active: 'active',
    Rejected: 'rejected',
    Removed: 'removed'
} as const;
export type OrgsGet200ResponseContainersInnerIssuerStateEnum = typeof OrgsGet200ResponseContainersInnerIssuerStateEnum[keyof typeof OrgsGet200ResponseContainersInnerIssuerStateEnum];


/**
 * Check if a given object implements the OrgsGet200ResponseContainersInnerIssuer interface.
 */
export function instanceOfOrgsGet200ResponseContainersInnerIssuer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function OrgsGet200ResponseContainersInnerIssuerFromJSON(json: any): OrgsGet200ResponseContainersInnerIssuer {
    return OrgsGet200ResponseContainersInnerIssuerFromJSONTyped(json, false);
}

export function OrgsGet200ResponseContainersInnerIssuerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200ResponseContainersInnerIssuer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': json['type'],
        'state': json['state'],
    };
}

export function OrgsGet200ResponseContainersInnerIssuerToJSON(value?: OrgsGet200ResponseContainersInnerIssuer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'state': value.state,
    };
}

