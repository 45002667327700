/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListCredentialsResponseAllOfCredentialsInner } from './ListCredentialsResponseAllOfCredentialsInner';
import {
    ListCredentialsResponseAllOfCredentialsInnerFromJSON,
    ListCredentialsResponseAllOfCredentialsInnerFromJSONTyped,
    ListCredentialsResponseAllOfCredentialsInnerToJSON,
} from './ListCredentialsResponseAllOfCredentialsInner';

/**
 * List credentials response
 * @export
 * @interface ListCredentialsResponse
 */
export interface ListCredentialsResponse {
    /**
     * 
     * @type {Array<ListCredentialsResponseAllOfCredentialsInner>}
     * @memberof ListCredentialsResponse
     */
    credentials: Array<ListCredentialsResponseAllOfCredentialsInner>;
}

/**
 * Check if a given object implements the ListCredentialsResponse interface.
 */
export function instanceOfListCredentialsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "credentials" in value;

    return isInstance;
}

export function ListCredentialsResponseFromJSON(json: any): ListCredentialsResponse {
    return ListCredentialsResponseFromJSONTyped(json, false);
}

export function ListCredentialsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCredentialsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credentials': ((json['credentials'] as Array<any>).map(ListCredentialsResponseAllOfCredentialsInnerFromJSON)),
    };
}

export function ListCredentialsResponseToJSON(value?: ListCredentialsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credentials': ((value.credentials as Array<any>).map(ListCredentialsResponseAllOfCredentialsInnerToJSON)),
    };
}

