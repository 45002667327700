/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of a template search result.
 * @export
 * @interface Search200ResponseTemplatesInner
 */
export interface Search200ResponseTemplatesInner {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof Search200ResponseTemplatesInner
     */
    id: string;
    /**
     * The name of the object
     * @type {string}
     * @memberof Search200ResponseTemplatesInner
     */
    name?: string;
    /**
     * The UUID of this object's owner
     * @type {string}
     * @memberof Search200ResponseTemplatesInner
     */
    owner?: string;
    /**
     * The type of Template this is.
     * @type {string}
     * @memberof Search200ResponseTemplatesInner
     */
    type?: Search200ResponseTemplatesInnerTypeEnum;
    /**
     * The description of this Template.
     * @type {string}
     * @memberof Search200ResponseTemplatesInner
     */
    description?: string;
    /**
     * Whether the template is paused or live.
     * @type {string}
     * @memberof Search200ResponseTemplatesInner
     */
    status?: Search200ResponseTemplatesInnerStatusEnum;
    /**
     * Whether or not containers created from this template should be automatically approved.
     * @type {string}
     * @memberof Search200ResponseTemplatesInner
     */
    approval?: string;
}


/**
 * @export
 */
export const Search200ResponseTemplatesInnerTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
} as const;
export type Search200ResponseTemplatesInnerTypeEnum = typeof Search200ResponseTemplatesInnerTypeEnum[keyof typeof Search200ResponseTemplatesInnerTypeEnum];

/**
 * @export
 */
export const Search200ResponseTemplatesInnerStatusEnum = {
    Paused: 'paused',
    Live: 'live'
} as const;
export type Search200ResponseTemplatesInnerStatusEnum = typeof Search200ResponseTemplatesInnerStatusEnum[keyof typeof Search200ResponseTemplatesInnerStatusEnum];


/**
 * Check if a given object implements the Search200ResponseTemplatesInner interface.
 */
export function instanceOfSearch200ResponseTemplatesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function Search200ResponseTemplatesInnerFromJSON(json: any): Search200ResponseTemplatesInner {
    return Search200ResponseTemplatesInnerFromJSONTyped(json, false);
}

export function Search200ResponseTemplatesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Search200ResponseTemplatesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'approval': !exists(json, 'approval') ? undefined : json['approval'],
    };
}

export function Search200ResponseTemplatesInnerToJSON(value?: Search200ResponseTemplatesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner': value.owner,
        'type': value.type,
        'description': value.description,
        'status': value.status,
        'approval': value.approval,
    };
}

