/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Records validated against a Schema with this Column are expected to have a header with this Column's name
 * and the value is expected to match this Column's DataType. Column includes the column id.
 * 
 * @export
 * @interface Column
 */
export interface Column {
    /**
     * 
     * @type {DataType}
     * @memberof Column
     */
    dataType: DataType;
    /**
     * The data in Records associated with the identifier Columns combine to form an identifying key for this Schema.
     * Having two Records with the same identifying key in a Schema results in the later value overriding the earlier value.
     * At least one Column with identifier = true must exist for every Schema.
     * 
     * @type {boolean}
     * @memberof Column
     */
    identifier?: boolean;
    /**
     * The name of this Column - expected to match a header in Record data.
     * @type {string}
     * @memberof Column
     */
    name: string;
    /**
     * The data in Records associated with the identifier Columns in combination with a truthy value in the column 
     * marked as 'usedAsDeletion' will result in the Record being removed from the DataSource and removing all prior
     * history of the Record identified by the identifier Columns.
     * It is required that the dataType of this column is a bool.
     * At most one Column with usedAsDeletion = true may exist for every Schema.
     * 
     * @type {boolean}
     * @memberof Column
     */
    usedAsDeletion?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    id: string;
}

/**
 * Check if a given object implements the Column interface.
 */
export function instanceOfColumn(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ColumnFromJSON(json: any): Column {
    return ColumnFromJSONTyped(json, false);
}

export function ColumnFromJSONTyped(json: any, ignoreDiscriminator: boolean): Column {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataType': DataTypeFromJSON(json['dataType']),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'name': json['name'],
        'usedAsDeletion': !exists(json, 'usedAsDeletion') ? undefined : json['usedAsDeletion'],
        'id': json['id'],
    };
}

export function ColumnToJSON(value?: Column | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataType': DataTypeToJSON(value.dataType),
        'identifier': value.identifier,
        'name': value.name,
        'usedAsDeletion': value.usedAsDeletion,
        'id': value.id,
    };
}

