// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { FloatingPortal, useFloating, useHover, useInteractions } from "@floating-ui/react";
import { Helpers } from "@merit/frontend-utils";
import { View } from "react-native";
import React, { useState } from "react";
import type { IconName } from "@merit/frontend-components";
import type { OPTestProps } from "@src/types/TestProps";
import type { Placement } from "@floating-ui/react";

const Some = Helpers.Some;

type Props = {
  readonly text: string;
  readonly testProps?: OPTestProps;
  readonly icon?: IconName;
  readonly width?: number;
  readonly position?: Placement;
};

export const Tooltip = ({ icon, position, testProps, text, width }: Props) => {
  const { theme } = useTheme();
  const [isShown, setIsShown] = useState(false);
  const { context, floatingStyles, refs } = useFloating({
    onOpenChange: setIsShown,
    open: isShown,
    placement: position ?? "bottom-start",
  });
  const hover = useHover(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([hover]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <Icon
          alt="Tooltip Information"
          name={icon ?? "informationOutlinedMediumDefault"}
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `${testProps.elementName}Icon` }
              : testProps
          }
        />
      </div>
      {isShown && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              backgroundColor: theme.colors.brand.oceanBlue,
              borderRadius: theme.borderRadii.s,
            }}
            {...getFloatingProps()}
          >
            <View
              style={{
                paddingHorizontal: theme.spacing.l,
                paddingVertical: theme.spacing.m,
                width: width ?? 320,
              }}
            >
              <Body color={theme.colors.surface.default}>{text}</Body>
            </View>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
