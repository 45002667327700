/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * summary information describing a FieldKind
 * @export
 * @interface OrgsGet200ResponseContainersInnerFieldsInnerFieldKind
 */
export interface OrgsGet200ResponseContainersInnerFieldsInnerFieldKind {
    /**
     * The id of the field kind.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInnerFieldKind
     */
    id: string;
    /**
     * The assigned name of the field kind
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInnerFieldKind
     */
    name?: string;
    /**
     * Abstract representation of a Column's DataType - use one of its implementing children like Bool or Date.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInnerFieldKind
     */
    fieldType?: OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFieldTypeEnum;
}


/**
 * @export
 */
export const OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFieldTypeEnum = {
    Bool: 'Bool',
    Date: 'Date',
    DateTime: 'DateTime',
    Email: 'Email',
    Markdown: 'Markdown',
    Number: 'Number',
    PhoneNumber: 'PhoneNumber',
    Text: 'Text',
    Blob: 'Blob',
    Json: 'JSON'
} as const;
export type OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFieldTypeEnum = typeof OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFieldTypeEnum[keyof typeof OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFieldTypeEnum];


/**
 * Check if a given object implements the OrgsGet200ResponseContainersInnerFieldsInnerFieldKind interface.
 */
export function instanceOfOrgsGet200ResponseContainersInnerFieldsInnerFieldKind(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFromJSON(json: any): OrgsGet200ResponseContainersInnerFieldsInnerFieldKind {
    return OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFromJSONTyped(json, false);
}

export function OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200ResponseContainersInnerFieldsInnerFieldKind {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fieldType': !exists(json, 'fieldType') ? undefined : json['fieldType'],
    };
}

export function OrgsGet200ResponseContainersInnerFieldsInnerFieldKindToJSON(value?: OrgsGet200ResponseContainersInnerFieldsInnerFieldKind | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fieldType': value.fieldType,
    };
}

