/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Auth0 options associated with agent
 * @export
 * @interface Auth0Options
 */
export interface Auth0Options {
    /**
     * logout urls allowed in auth0 for this agent
     * @type {Array<string>}
     * @memberof Auth0Options
     */
    allowedLogoutURLs?: Array<string>;
    /**
     * origins allowed in auth0 for this agent
     * @type {Array<string>}
     * @memberof Auth0Options
     */
    allowedOrigins?: Array<string>;
    /**
     * auth0 callbacks for this agent
     * @type {Array<string>}
     * @memberof Auth0Options
     */
    callbacks?: Array<string>;
    /**
     * auth0 web origins for this agent
     * @type {Array<string>}
     * @memberof Auth0Options
     */
    webOrigins?: Array<string>;
    /**
     * logo uri for this agent
     * @type {string}
     * @memberof Auth0Options
     */
    logoURI?: string;
    /**
     * if the agent is first party
     * @type {boolean}
     * @memberof Auth0Options
     */
    readonly isFirstParty?: boolean;
    /**
     * Leave blank to create an agent for exclusive use by individuals. Set to 'require' to create an agent
     * for use by org admins exclusively, and set to 'allow' to create an agent for use by either individuals
     * or org admins
     * 
     * @type {string}
     * @memberof Auth0Options
     */
    organizationUsage?: Auth0OptionsOrganizationUsageEnum | null;
}


/**
 * @export
 */
export const Auth0OptionsOrganizationUsageEnum = {
    Require: 'require',
    Allow: 'allow'
} as const;
export type Auth0OptionsOrganizationUsageEnum = typeof Auth0OptionsOrganizationUsageEnum[keyof typeof Auth0OptionsOrganizationUsageEnum];


/**
 * Check if a given object implements the Auth0Options interface.
 */
export function instanceOfAuth0Options(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Auth0OptionsFromJSON(json: any): Auth0Options {
    return Auth0OptionsFromJSONTyped(json, false);
}

export function Auth0OptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Auth0Options {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedLogoutURLs': !exists(json, 'allowedLogoutURLs') ? undefined : json['allowedLogoutURLs'],
        'allowedOrigins': !exists(json, 'allowedOrigins') ? undefined : json['allowedOrigins'],
        'callbacks': !exists(json, 'callbacks') ? undefined : json['callbacks'],
        'webOrigins': !exists(json, 'webOrigins') ? undefined : json['webOrigins'],
        'logoURI': !exists(json, 'logoURI') ? undefined : json['logoURI'],
        'isFirstParty': !exists(json, 'isFirstParty') ? undefined : json['isFirstParty'],
        'organizationUsage': !exists(json, 'organizationUsage') ? undefined : json['organizationUsage'],
    };
}

export function Auth0OptionsToJSON(value?: Auth0Options | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedLogoutURLs': value.allowedLogoutURLs,
        'allowedOrigins': value.allowedOrigins,
        'callbacks': value.callbacks,
        'webOrigins': value.webOrigins,
        'logoURI': value.logoURI,
        'organizationUsage': value.organizationUsage,
    };
}

