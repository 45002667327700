// Copyright 2023 Merit International Inc. All Rights Reserved

import { StyleSheet } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

type DatasourcesUpdateStyles = {
  readonly container: ViewStyle;
  readonly content: ViewStyle;
  readonly footer: ViewStyle;
  readonly formField: ViewStyle;
  readonly separator: ViewStyle;
  readonly tag: ViewStyle;
  readonly tagContainer: ViewStyle;
};

export const useDatasourcesUpdateScreenStyles = () => {
  const { theme } = useTheme();

  return StyleSheet.create<DatasourcesUpdateStyles>({
    container: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
    content: {
      alignSelf: "center",
      flex: 1,
      paddingVertical: theme.spacing.xxl,
      width: 960,
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: "#DFE1E6",
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    formField: {
      flexDirection: "column",
    },
    separator: {
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      marginVertical: theme.spacing.xxl,
    },
    tag: {
      backgroundColor: theme.colors.brand.turquiose,
      borderRadius: 20,
      margin: theme.spacing.xs,
      paddingHorizontal: 6,
      paddingVertical: theme.spacing.xxs,
    },
    tagContainer: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      maxWidth: 500,
    },
  });
};
