// Copyright 2023 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { OPTestProps } from "../../../src/types/TestProps";

type Props = {
  readonly type?: "date" | "datetime-local" | "time";
  readonly onChange: (dateTime: string) => void;
  readonly defaultValue?: string;
  readonly size?: "large" | "medium" | "small";
  readonly testProps?: OPTestProps;
  readonly width?: number;
};

export const DateTimePicker = ({
  defaultValue,
  onChange,
  size = "medium",
  testProps,
  type = "date",
  width,
}: Props) => {
  const { theme } = useTheme();

  const getSize = () => {
    if (size === "large") {
      return 46;
    }
    if (size === "small") {
      return 30;
    }

    return 38;
  };

  return (
    <input
      defaultValue={defaultValue}
      onChange={({ target }) => {
        onChange(target.value);
      }}
      style={{
        border: `1px solid ${theme.colors.border.default}`,
        borderRadius: theme.borderRadii.s,
        height: getSize(),
        paddingLeft: theme.spacing.m,
        paddingRight: theme.spacing.m,
        width: width ?? "100%",
      }}
      type={type}
      {...Helpers.generateTestIdProps(testProps, { isHTML: true })}
    />
  );
};
