/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDatasourceRequestSchemaColumnsInner } from './CreateDatasourceRequestSchemaColumnsInner';
import {
    CreateDatasourceRequestSchemaColumnsInnerFromJSON,
    CreateDatasourceRequestSchemaColumnsInnerFromJSONTyped,
    CreateDatasourceRequestSchemaColumnsInnerToJSON,
} from './CreateDatasourceRequestSchemaColumnsInner';

/**
 * 
 * @export
 * @interface CreateDatasourceRequest
 */
export interface CreateDatasourceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequest
     */
    integrationType: CreateDatasourceRequestIntegrationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequest
     */
    schemaDelimiter: CreateDatasourceRequestSchemaDelimiterEnum;
    /**
     * 
     * @type {Array<CreateDatasourceRequestSchemaColumnsInner>}
     * @memberof CreateDatasourceRequest
     */
    schemaColumns: Array<CreateDatasourceRequestSchemaColumnsInner>;
    /**
     * DataSource creation timestamp
     * @type {Date}
     * @memberof CreateDatasourceRequest
     */
    createdAt?: Date;
}


/**
 * @export
 */
export const CreateDatasourceRequestIntegrationTypeEnum = {
    Csv: 'CSV',
    Custom: 'CUSTOM'
} as const;
export type CreateDatasourceRequestIntegrationTypeEnum = typeof CreateDatasourceRequestIntegrationTypeEnum[keyof typeof CreateDatasourceRequestIntegrationTypeEnum];

/**
 * @export
 */
export const CreateDatasourceRequestSchemaDelimiterEnum = {
    Comma: 'comma',
    Tab: 'tab',
    Pipe: 'pipe'
} as const;
export type CreateDatasourceRequestSchemaDelimiterEnum = typeof CreateDatasourceRequestSchemaDelimiterEnum[keyof typeof CreateDatasourceRequestSchemaDelimiterEnum];


/**
 * Check if a given object implements the CreateDatasourceRequest interface.
 */
export function instanceOfCreateDatasourceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "integrationType" in value;
    isInstance = isInstance && "schemaDelimiter" in value;
    isInstance = isInstance && "schemaColumns" in value;

    return isInstance;
}

export function CreateDatasourceRequestFromJSON(json: any): CreateDatasourceRequest {
    return CreateDatasourceRequestFromJSONTyped(json, false);
}

export function CreateDatasourceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDatasourceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'integrationType': json['integrationType'],
        'schemaDelimiter': json['schemaDelimiter'],
        'schemaColumns': ((json['schemaColumns'] as Array<any>).map(CreateDatasourceRequestSchemaColumnsInnerFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function CreateDatasourceRequestToJSON(value?: CreateDatasourceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'integrationType': value.integrationType,
        'schemaDelimiter': value.schemaDelimiter,
        'schemaColumns': ((value.schemaColumns as Array<any>).map(CreateDatasourceRequestSchemaColumnsInnerToJSON)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

