export enum Scopes {
  CreateAgent = "agents:create-agent",
  UpdateAgent = "update:agent",
  ReadAgent = "read:agent",
  DeleteAgent = "delete:agent",
  CreateAgentEntityLink = "create:agent_entity_link",
  ReadAgentEntityLink = "read:agent_entity_link",
  ReadAgentVersion = "read:agent_version",
  ApproveAgentVersion = "approve:agent_version",

  // issuance scopes
  ExtendTemplate = "extend:template",
  UpdateTemplate = "update:template",
  ReadTemplate = "read:template",
  CreatePersona = "create:persona",
  ReadContainer = "read:container",
  TransitionContainer = "transition:container",
  DeleteContainer = "delete:container",
  ExtendFieldKind = "extend:field_kind",
  ReadFieldKind = "read:field_kind",
  UpdateFieldKind = "update:field_kind",
  ExtendPolicy = "extend:policy",
  ReadPolicy = "read:policy",
  UpdatePolicy = "update:policy",
  CreatePolicyRequest = "create:policy_request",
  ReadPolicyRequest = "read:policy_request",
  UpdatePolicyRequest = "update:policy_request",
  ReadEntity = "read:entity",
  MergeEntity = "merge:entity",

  // wormhole scopes
  CreateDatasource = "create:datasource",
  UpdateDatasource = "update:datasource",
  ReadDatasource = "read:datasource",
  DeleteDatasource = "delete:datasource",
  ReadFileIngestionStatus = "read:file_ingestion_status",
  CreateRecord = "create:record",
  CreateIntegration = "create:integration",
  UpdateIntegration = "update:integration",
  ReadIntegration = "read:integration",
  DeleteIntegration = "delete:integration",
}
