/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin
 */
export interface ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin {
    /**
     * 
     * @type {number}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin
     */
    top?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin
     */
    right?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin
     */
    bottom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin
     */
    left?: number;
}

/**
 * Check if a given object implements the ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin interface.
 */
export function instanceOfProjectsProjectIDExportPOSTRequestOneOf1BleedMargin(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginFromJSON(json: any): ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin {
    return ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginFromJSONTyped(json, false);
}

export function ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'top': !exists(json, 'top') ? undefined : json['top'],
        'right': !exists(json, 'right') ? undefined : json['right'],
        'bottom': !exists(json, 'bottom') ? undefined : json['bottom'],
        'left': !exists(json, 'left') ? undefined : json['left'],
    };
}

export function ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginToJSON(value?: ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'top': value.top,
        'right': value.right,
        'bottom': value.bottom,
        'left': value.left,
    };
}

