/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An Org's Datasource contains Schemas.
 * @export
 * @interface UpdateDataSource
 */
export interface UpdateDataSource {
    /**
     * 
     * @type {string}
     * @memberof UpdateDataSource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataSource
     */
    description?: string;
}

/**
 * Check if a given object implements the UpdateDataSource interface.
 */
export function instanceOfUpdateDataSource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateDataSourceFromJSON(json: any): UpdateDataSource {
    return UpdateDataSourceFromJSONTyped(json, false);
}

export function UpdateDataSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDataSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function UpdateDataSourceToJSON(value?: UpdateDataSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

