/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInner } from './OrgsGet200ResponseContainersInner';
import {
    OrgsGet200ResponseContainersInnerFromJSON,
    OrgsGet200ResponseContainersInnerFromJSONTyped,
    OrgsGet200ResponseContainersInnerToJSON,
} from './OrgsGet200ResponseContainersInner';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * The list of containers.
 * @export
 * @interface OrgsGet200Response
 */
export interface OrgsGet200Response {
    /**
     * 
     * @type {Array<OrgsGet200ResponseContainersInner>}
     * @memberof OrgsGet200Response
     */
    containers?: Array<OrgsGet200ResponseContainersInner>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof OrgsGet200Response
     */
    paginationInfo?: PaginationInfo;
}

/**
 * Check if a given object implements the OrgsGet200Response interface.
 */
export function instanceOfOrgsGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrgsGet200ResponseFromJSON(json: any): OrgsGet200Response {
    return OrgsGet200ResponseFromJSONTyped(json, false);
}

export function OrgsGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containers': !exists(json, 'containers') ? undefined : ((json['containers'] as Array<any>).map(OrgsGet200ResponseContainersInnerFromJSON)),
        'paginationInfo': !exists(json, 'PaginationInfo') ? undefined : PaginationInfoFromJSON(json['PaginationInfo']),
    };
}

export function OrgsGet200ResponseToJSON(value?: OrgsGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containers': value.containers === undefined ? undefined : ((value.containers as Array<any>).map(OrgsGet200ResponseContainersInnerToJSON)),
        'PaginationInfo': PaginationInfoToJSON(value.paginationInfo),
    };
}

