// Copyright 2024 Merit International Inc. All Rights Reserved

import { EditPolicyDeprecatedScreen } from "./EditPolicyDeprecated";
import { useAlertStore } from "@src/stores";
import { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { v4 as uuidv4 } from "uuid";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";

export const EditPolicyScreen = () => {
  const { showM6CreateEditPolicyScreens } = useFlags<LDFeatureFlags>();
  const { deleteAlert, setAlert } = useAlertStore();

  useEffect(() => {
    if (showM6CreateEditPolicyScreens) {
      setAlert({
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: "New edit policy screen not yet implemented",
        type: "warning",
      });
    }
  }, [deleteAlert, setAlert, showM6CreateEditPolicyScreens]);

  return <EditPolicyDeprecatedScreen />;
};
