/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule } from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule';
import {
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import type { GetFieldKind200ResponseTemplatesInner } from './GetFieldKind200ResponseTemplatesInner';
import {
    GetFieldKind200ResponseTemplatesInnerFromJSON,
    GetFieldKind200ResponseTemplatesInnerFromJSONTyped,
    GetFieldKind200ResponseTemplatesInnerToJSON,
} from './GetFieldKind200ResponseTemplatesInner';

/**
 * 
 * @export
 * @interface GetFieldKind200Response
 */
export interface GetFieldKind200Response {
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    fieldKindID: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    parentFieldKindID: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    dataType: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    ownerEntityName: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    ownerEntityID: string;
    /**
     * 
     * @type {Date}
     * @memberof GetFieldKind200Response
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    createdBy: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetFieldKind200Response
     */
    shareablePermissions?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetFieldKind200Response
     */
    isMine?: boolean;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule}
     * @memberof GetFieldKind200Response
     */
    ownValidationRules: GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule>}
     * @memberof GetFieldKind200Response
     */
    inheritedValidationRules?: Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule>;
    /**
     * 
     * @type {boolean}
     * @memberof GetFieldKind200Response
     */
    extendable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    creatorEntityID?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKind200Response
     */
    creatorEntityName?: string;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof GetFieldKind200Response
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
    /**
     * 
     * @type {Array<GetFieldKind200ResponseTemplatesInner>}
     * @memberof GetFieldKind200Response
     */
    templates?: Array<GetFieldKind200ResponseTemplatesInner>;
    /**
     * 
     * @type {number}
     * @memberof GetFieldKind200Response
     */
    childCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFieldKind200Response
     */
    descendantCount?: number;
}

/**
 * Check if a given object implements the GetFieldKind200Response interface.
 */
export function instanceOfGetFieldKind200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "fieldKindID" in value;
    isInstance = isInstance && "parentFieldKindID" in value;
    isInstance = isInstance && "dataType" in value;
    isInstance = isInstance && "ownerEntityName" in value;
    isInstance = isInstance && "ownerEntityID" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "ownValidationRules" in value;

    return isInstance;
}

export function GetFieldKind200ResponseFromJSON(json: any): GetFieldKind200Response {
    return GetFieldKind200ResponseFromJSONTyped(json, false);
}

export function GetFieldKind200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFieldKind200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fieldKindID': json['fieldKindID'],
        'parentFieldKindID': json['parentFieldKindID'],
        'dataType': json['dataType'],
        'ownerEntityName': json['ownerEntityName'],
        'ownerEntityID': json['ownerEntityID'],
        'createdAt': (new Date(json['createdAt'])),
        'createdBy': json['createdBy'],
        'shareablePermissions': !exists(json, 'shareablePermissions') ? undefined : json['shareablePermissions'],
        'isMine': !exists(json, 'isMine') ? undefined : json['isMine'],
        'ownValidationRules': GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON(json['ownValidationRules']),
        'inheritedValidationRules': !exists(json, 'inheritedValidationRules') ? undefined : ((json['inheritedValidationRules'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON)),
        'extendable': !exists(json, 'extendable') ? undefined : json['extendable'],
        'creatorEntityID': !exists(json, 'creatorEntityID') ? undefined : json['creatorEntityID'],
        'creatorEntityName': !exists(json, 'creatorEntityName') ? undefined : json['creatorEntityName'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'templates': !exists(json, 'templates') ? undefined : ((json['templates'] as Array<any>).map(GetFieldKind200ResponseTemplatesInnerFromJSON)),
        'childCount': !exists(json, 'childCount') ? undefined : json['childCount'],
        'descendantCount': !exists(json, 'descendantCount') ? undefined : json['descendantCount'],
    };
}

export function GetFieldKind200ResponseToJSON(value?: GetFieldKind200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'fieldKindID': value.fieldKindID,
        'parentFieldKindID': value.parentFieldKindID,
        'dataType': value.dataType,
        'ownerEntityName': value.ownerEntityName,
        'ownerEntityID': value.ownerEntityID,
        'createdAt': (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'shareablePermissions': value.shareablePermissions,
        'isMine': value.isMine,
        'ownValidationRules': GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON(value.ownValidationRules),
        'inheritedValidationRules': value.inheritedValidationRules === undefined ? undefined : ((value.inheritedValidationRules as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON)),
        'extendable': value.extendable,
        'creatorEntityID': value.creatorEntityID,
        'creatorEntityName': value.creatorEntityName,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'templates': value.templates === undefined ? undefined : ((value.templates as Array<any>).map(GetFieldKind200ResponseTemplatesInnerToJSON)),
        'childCount': value.childCount,
        'descendantCount': value.descendantCount,
    };
}

