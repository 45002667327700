/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Restricts a given capability to a select object or objects
 * @export
 * @interface GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner
 */
export interface GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner {
    /**
     * The object attribute that determines which objects the agent can act on
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner
     */
    identifier?: string;
    /**
     * The value the object's attribute must match for the capability to apply
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner
     */
    value?: string;
    /**
     * Determines how to interpret the value field
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner
     */
    valueType?: GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerValueTypeEnum;
}


/**
 * @export
 */
export const GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerValueTypeEnum = {
    String: 'string',
    Number: 'number'
} as const;
export type GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerValueTypeEnum = typeof GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerValueTypeEnum[keyof typeof GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerValueTypeEnum];


/**
 * Check if a given object implements the GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner interface.
 */
export function instanceOfGetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSON(json: any): GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner {
    return GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSONTyped(json, false);
}

export function GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueType': !exists(json, 'valueType') ? undefined : json['valueType'],
    };
}

export function GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerToJSON(value?: GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'value': value.value,
        'valueType': value.valueType,
    };
}

