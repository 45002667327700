/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TemplateCategoriesGETSuccess,
  TemplateCustomizationObjectsPOSTRequest,
  TemplateCustomizationObjectsPOSTSuccess,
  TemplateJobsJobIDError403,
  TemplateJobsJobIDGETSuccess,
  TemplatesGETSuccess,
  TemplatesPOSTRequest,
  TemplatesPOSTSuccess,
  TemplatesTemplateIDBaseSuccess,
  TemplatesTemplateIDError404,
  TemplatesTemplateIDGETSuccess,
  TemplatesTemplateIDPATCHRequest,
  TemplatesTemplateIDPUTRequest,
  TemplatesTemplateIdTemplateCodeGetTemplateIdTemplateCodeParameter,
} from '../models/index';
import {
    TemplateCategoriesGETSuccessFromJSON,
    TemplateCategoriesGETSuccessToJSON,
    TemplateCustomizationObjectsPOSTRequestFromJSON,
    TemplateCustomizationObjectsPOSTRequestToJSON,
    TemplateCustomizationObjectsPOSTSuccessFromJSON,
    TemplateCustomizationObjectsPOSTSuccessToJSON,
    TemplateJobsJobIDError403FromJSON,
    TemplateJobsJobIDError403ToJSON,
    TemplateJobsJobIDGETSuccessFromJSON,
    TemplateJobsJobIDGETSuccessToJSON,
    TemplatesGETSuccessFromJSON,
    TemplatesGETSuccessToJSON,
    TemplatesPOSTRequestFromJSON,
    TemplatesPOSTRequestToJSON,
    TemplatesPOSTSuccessFromJSON,
    TemplatesPOSTSuccessToJSON,
    TemplatesTemplateIDBaseSuccessFromJSON,
    TemplatesTemplateIDBaseSuccessToJSON,
    TemplatesTemplateIDError404FromJSON,
    TemplatesTemplateIDError404ToJSON,
    TemplatesTemplateIDGETSuccessFromJSON,
    TemplatesTemplateIDGETSuccessToJSON,
    TemplatesTemplateIDPATCHRequestFromJSON,
    TemplatesTemplateIDPATCHRequestToJSON,
    TemplatesTemplateIDPUTRequestFromJSON,
    TemplatesTemplateIDPUTRequestToJSON,
    TemplatesTemplateIdTemplateCodeGetTemplateIdTemplateCodeParameterFromJSON,
    TemplatesTemplateIdTemplateCodeGetTemplateIdTemplateCodeParameterToJSON,
} from '../models/index';

export interface TemplateCategoriesGetRequest {
    search?: string;
    templateCategoryStatus?: TemplateCategoriesGetTemplateCategoryStatusEnum;
    templateCategoryStatuses?: Array<TemplateCategoriesGetTemplateCategoryStatusesEnum>;
    inUse?: boolean;
    mediaType?: TemplateCategoriesGetMediaTypeEnum;
    primaryTemplateCategoryItemId?: number;
    sort?: string;
    order?: TemplateCategoriesGetOrderEnum;
    fields?: Array<TemplateCategoriesGetFieldsEnum>;
}

export interface TemplateCustomizationObjectsPostRequest {
    templateCustomizationObjectsPOSTRequest: TemplateCustomizationObjectsPOSTRequest;
}

export interface TemplateJobsJobIdGetRequest {
    jobId: string;
}

export interface TemplatesGetRequest {
    brandId?: number;
    brandCode?: string;
    mediaType?: TemplatesGetMediaTypeEnum;
    mediaTypes?: Array<TemplatesGetMediaTypesEnum>;
    templateStatus?: TemplatesGetTemplateStatusEnum;
    templateStatuses?: Array<TemplatesGetTemplateStatusesEnum>;
    primaryTemplateCategoryItemId?: number;
    primaryTemplateCategoryItemIds?: Array<number>;
    categoryId?: number;
    sourceProjectId?: string;
    sourceProjectIds?: Array<string>;
    width?: number;
    height?: number;
    unitType?: TemplatesGetUnitTypeEnum;
    search?: string;
    sort?: string;
    order?: TemplatesGetOrderEnum;
    limit?: number;
    page?: number;
    fields?: Array<TemplatesGetFieldsEnum>;
}

export interface TemplatesPostRequest {
    templatesPOSTRequest: TemplatesPOSTRequest;
}

export interface TemplatesTemplateIdTemplateCodeDeleteRequest {
    templateIdTemplateCode: TemplatesTemplateIdTemplateCodeGetTemplateIdTemplateCodeParameter;
}

export interface TemplatesTemplateIdTemplateCodeGetRequest {
    templateIdTemplateCode: TemplatesTemplateIdTemplateCodeGetTemplateIdTemplateCodeParameter;
}

export interface TemplatesTemplateIdTemplateCodePatchRequest {
    templateIdTemplateCode: TemplatesTemplateIdTemplateCodeGetTemplateIdTemplateCodeParameter;
    templatesTemplateIDPATCHRequest: TemplatesTemplateIDPATCHRequest;
}

export interface TemplatesTemplateIdTemplateCodePutRequest {
    templateIdTemplateCode: TemplatesTemplateIdTemplateCodeGetTemplateIdTemplateCodeParameter;
    templatesTemplateIDPUTRequest: TemplatesTemplateIDPUTRequest;
}

/**
 * 
 */
export class TemplatesApi extends runtime.BaseAPI {

    /**
     * List template categories
     */
    async templateCategoriesGetRaw(requestParameters: TemplateCategoriesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateCategoriesGETSuccess>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.templateCategoryStatus !== undefined) {
            queryParameters['template_category_status'] = requestParameters.templateCategoryStatus;
        }

        if (requestParameters.templateCategoryStatuses) {
            queryParameters['template_category_statuses'] = requestParameters.templateCategoryStatuses;
        }

        if (requestParameters.inUse !== undefined) {
            queryParameters['in_use'] = requestParameters.inUse;
        }

        if (requestParameters.mediaType !== undefined) {
            queryParameters['media_type'] = requestParameters.mediaType;
        }

        if (requestParameters.primaryTemplateCategoryItemId !== undefined) {
            queryParameters['primary_template_category_item_id'] = requestParameters.primaryTemplateCategoryItemId;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.fields) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/template/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateCategoriesGETSuccessFromJSON(jsonValue));
    }

    /**
     * List template categories
     */
    async templateCategoriesGet(requestParameters: TemplateCategoriesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateCategoriesGETSuccess> {
        const response = await this.templateCategoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a template customization object
     */
    async templateCustomizationObjectsPostRaw(requestParameters: TemplateCustomizationObjectsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateCustomizationObjectsPOSTSuccess>> {
        if (requestParameters.templateCustomizationObjectsPOSTRequest === null || requestParameters.templateCustomizationObjectsPOSTRequest === undefined) {
            throw new runtime.RequiredError('templateCustomizationObjectsPOSTRequest','Required parameter requestParameters.templateCustomizationObjectsPOSTRequest was null or undefined when calling templateCustomizationObjectsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/template/customization-objects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateCustomizationObjectsPOSTRequestToJSON(requestParameters.templateCustomizationObjectsPOSTRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateCustomizationObjectsPOSTSuccessFromJSON(jsonValue));
    }

    /**
     * Create a template customization object
     */
    async templateCustomizationObjectsPost(requestParameters: TemplateCustomizationObjectsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateCustomizationObjectsPOSTSuccess> {
        const response = await this.templateCustomizationObjectsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve template publish progress
     */
    async templateJobsJobIdGetRaw(requestParameters: TemplateJobsJobIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateJobsJobIDGETSuccess>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling templateJobsJobIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/template/jobs/{job_id}`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateJobsJobIDGETSuccessFromJSON(jsonValue));
    }

    /**
     * Retrieve template publish progress
     */
    async templateJobsJobIdGet(requestParameters: TemplateJobsJobIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateJobsJobIDGETSuccess> {
        const response = await this.templateJobsJobIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List templates
     */
    async templatesGetRaw(requestParameters: TemplatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplatesGETSuccess>> {
        const queryParameters: any = {};

        if (requestParameters.brandId !== undefined) {
            queryParameters['brand_id'] = requestParameters.brandId;
        }

        if (requestParameters.brandCode !== undefined) {
            queryParameters['brand_code'] = requestParameters.brandCode;
        }

        if (requestParameters.mediaType !== undefined) {
            queryParameters['media_type'] = requestParameters.mediaType;
        }

        if (requestParameters.mediaTypes) {
            queryParameters['media_types'] = requestParameters.mediaTypes;
        }

        if (requestParameters.templateStatus !== undefined) {
            queryParameters['template_status'] = requestParameters.templateStatus;
        }

        if (requestParameters.templateStatuses) {
            queryParameters['template_statuses'] = requestParameters.templateStatuses;
        }

        if (requestParameters.primaryTemplateCategoryItemId !== undefined) {
            queryParameters['primary_template_category_item_id'] = requestParameters.primaryTemplateCategoryItemId;
        }

        if (requestParameters.primaryTemplateCategoryItemIds) {
            queryParameters['primary_template_category_item_ids'] = requestParameters.primaryTemplateCategoryItemIds;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['category_{id}[]'] = requestParameters.categoryId;
        }

        if (requestParameters.sourceProjectId !== undefined) {
            queryParameters['source_project_id'] = requestParameters.sourceProjectId;
        }

        if (requestParameters.sourceProjectIds) {
            queryParameters['source_project_ids'] = requestParameters.sourceProjectIds;
        }

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.height !== undefined) {
            queryParameters['height'] = requestParameters.height;
        }

        if (requestParameters.unitType !== undefined) {
            queryParameters['unit_type'] = requestParameters.unitType;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.fields) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplatesGETSuccessFromJSON(jsonValue));
    }

    /**
     * List templates
     */
    async templatesGet(requestParameters: TemplatesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplatesGETSuccess> {
        const response = await this.templatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish a template
     */
    async templatesPostRaw(requestParameters: TemplatesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplatesPOSTSuccess>> {
        if (requestParameters.templatesPOSTRequest === null || requestParameters.templatesPOSTRequest === undefined) {
            throw new runtime.RequiredError('templatesPOSTRequest','Required parameter requestParameters.templatesPOSTRequest was null or undefined when calling templatesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplatesPOSTRequestToJSON(requestParameters.templatesPOSTRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplatesPOSTSuccessFromJSON(jsonValue));
    }

    /**
     * Publish a template
     */
    async templatesPost(requestParameters: TemplatesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplatesPOSTSuccess> {
        const response = await this.templatesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive a template
     */
    async templatesTemplateIdTemplateCodeDeleteRaw(requestParameters: TemplatesTemplateIdTemplateCodeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplatesTemplateIDBaseSuccess>> {
        if (requestParameters.templateIdTemplateCode === null || requestParameters.templateIdTemplateCode === undefined) {
            throw new runtime.RequiredError('templateIdTemplateCode','Required parameter requestParameters.templateIdTemplateCode was null or undefined when calling templatesTemplateIdTemplateCodeDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates/{template_id/template_code}`.replace(`{${"template_id/template_code"}}`, encodeURIComponent(String(requestParameters.templateIdTemplateCode))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplatesTemplateIDBaseSuccessFromJSON(jsonValue));
    }

    /**
     * Archive a template
     */
    async templatesTemplateIdTemplateCodeDelete(requestParameters: TemplatesTemplateIdTemplateCodeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplatesTemplateIDBaseSuccess> {
        const response = await this.templatesTemplateIdTemplateCodeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a template
     */
    async templatesTemplateIdTemplateCodeGetRaw(requestParameters: TemplatesTemplateIdTemplateCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplatesTemplateIDGETSuccess>> {
        if (requestParameters.templateIdTemplateCode === null || requestParameters.templateIdTemplateCode === undefined) {
            throw new runtime.RequiredError('templateIdTemplateCode','Required parameter requestParameters.templateIdTemplateCode was null or undefined when calling templatesTemplateIdTemplateCodeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates/{template_id/template_code}`.replace(`{${"template_id/template_code"}}`, encodeURIComponent(String(requestParameters.templateIdTemplateCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplatesTemplateIDGETSuccessFromJSON(jsonValue));
    }

    /**
     * Retrieve a template
     */
    async templatesTemplateIdTemplateCodeGet(requestParameters: TemplatesTemplateIdTemplateCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplatesTemplateIDGETSuccess> {
        const response = await this.templatesTemplateIdTemplateCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a template
     */
    async templatesTemplateIdTemplateCodePatchRaw(requestParameters: TemplatesTemplateIdTemplateCodePatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplatesTemplateIDBaseSuccess>> {
        if (requestParameters.templateIdTemplateCode === null || requestParameters.templateIdTemplateCode === undefined) {
            throw new runtime.RequiredError('templateIdTemplateCode','Required parameter requestParameters.templateIdTemplateCode was null or undefined when calling templatesTemplateIdTemplateCodePatch.');
        }

        if (requestParameters.templatesTemplateIDPATCHRequest === null || requestParameters.templatesTemplateIDPATCHRequest === undefined) {
            throw new runtime.RequiredError('templatesTemplateIDPATCHRequest','Required parameter requestParameters.templatesTemplateIDPATCHRequest was null or undefined when calling templatesTemplateIdTemplateCodePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates/{template_id/template_code}`.replace(`{${"template_id/template_code"}}`, encodeURIComponent(String(requestParameters.templateIdTemplateCode))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TemplatesTemplateIDPATCHRequestToJSON(requestParameters.templatesTemplateIDPATCHRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplatesTemplateIDBaseSuccessFromJSON(jsonValue));
    }

    /**
     * Update a template
     */
    async templatesTemplateIdTemplateCodePatch(requestParameters: TemplatesTemplateIdTemplateCodePatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplatesTemplateIDBaseSuccess> {
        const response = await this.templatesTemplateIdTemplateCodePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upsert a template
     */
    async templatesTemplateIdTemplateCodePutRaw(requestParameters: TemplatesTemplateIdTemplateCodePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplatesTemplateIDBaseSuccess>> {
        if (requestParameters.templateIdTemplateCode === null || requestParameters.templateIdTemplateCode === undefined) {
            throw new runtime.RequiredError('templateIdTemplateCode','Required parameter requestParameters.templateIdTemplateCode was null or undefined when calling templatesTemplateIdTemplateCodePut.');
        }

        if (requestParameters.templatesTemplateIDPUTRequest === null || requestParameters.templatesTemplateIDPUTRequest === undefined) {
            throw new runtime.RequiredError('templatesTemplateIDPUTRequest','Required parameter requestParameters.templatesTemplateIDPUTRequest was null or undefined when calling templatesTemplateIdTemplateCodePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates/{template_id/template_code}`.replace(`{${"template_id/template_code"}}`, encodeURIComponent(String(requestParameters.templateIdTemplateCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TemplatesTemplateIDPUTRequestToJSON(requestParameters.templatesTemplateIDPUTRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplatesTemplateIDBaseSuccessFromJSON(jsonValue));
    }

    /**
     * Upsert a template
     */
    async templatesTemplateIdTemplateCodePut(requestParameters: TemplatesTemplateIdTemplateCodePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplatesTemplateIDBaseSuccess> {
        const response = await this.templatesTemplateIdTemplateCodePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const TemplateCategoriesGetTemplateCategoryStatusEnum = {
    Active: 'active',
    Hidden: 'hidden',
    Archived: 'archived'
} as const;
export type TemplateCategoriesGetTemplateCategoryStatusEnum = typeof TemplateCategoriesGetTemplateCategoryStatusEnum[keyof typeof TemplateCategoriesGetTemplateCategoryStatusEnum];
/**
 * @export
 */
export const TemplateCategoriesGetTemplateCategoryStatusesEnum = {
    Active: 'active',
    Hidden: 'hidden',
    Archived: 'archived'
} as const;
export type TemplateCategoriesGetTemplateCategoryStatusesEnum = typeof TemplateCategoriesGetTemplateCategoryStatusesEnum[keyof typeof TemplateCategoriesGetTemplateCategoryStatusesEnum];
/**
 * @export
 */
export const TemplateCategoriesGetMediaTypeEnum = {
    Digital: 'digital',
    Video: 'video',
    Print: 'print',
    Presentation: 'presentation'
} as const;
export type TemplateCategoriesGetMediaTypeEnum = typeof TemplateCategoriesGetMediaTypeEnum[keyof typeof TemplateCategoriesGetMediaTypeEnum];
/**
 * @export
 */
export const TemplateCategoriesGetOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type TemplateCategoriesGetOrderEnum = typeof TemplateCategoriesGetOrderEnum[keyof typeof TemplateCategoriesGetOrderEnum];
/**
 * @export
 */
export const TemplateCategoriesGetFieldsEnum = {
    TemplateCategoryId: 'template_category_id',
    CategoryName: 'category_name',
    PrimaryTemplateCategory: 'primary_template_category',
    SortOrder: 'sort_order',
    TemplateCategoryStatus: 'template_category_status',
    TemplateCategoryItems: 'template_category_items',
    DateCreated: 'date_created',
    DateUpdated: 'date_updated'
} as const;
export type TemplateCategoriesGetFieldsEnum = typeof TemplateCategoriesGetFieldsEnum[keyof typeof TemplateCategoriesGetFieldsEnum];
/**
 * @export
 */
export const TemplatesGetMediaTypeEnum = {
    Digital: 'digital',
    Video: 'video',
    Print: 'print',
    Presentation: 'presentation'
} as const;
export type TemplatesGetMediaTypeEnum = typeof TemplatesGetMediaTypeEnum[keyof typeof TemplatesGetMediaTypeEnum];
/**
 * @export
 */
export const TemplatesGetMediaTypesEnum = {
    Digital: 'digital',
    Video: 'video',
    Print: 'print',
    Presentation: 'presentation'
} as const;
export type TemplatesGetMediaTypesEnum = typeof TemplatesGetMediaTypesEnum[keyof typeof TemplatesGetMediaTypesEnum];
/**
 * @export
 */
export const TemplatesGetTemplateStatusEnum = {
    Active: 'active',
    Hidden: 'hidden',
    Archived: 'archived'
} as const;
export type TemplatesGetTemplateStatusEnum = typeof TemplatesGetTemplateStatusEnum[keyof typeof TemplatesGetTemplateStatusEnum];
/**
 * @export
 */
export const TemplatesGetTemplateStatusesEnum = {
    Active: 'active',
    Hidden: 'hidden',
    Archived: 'archived'
} as const;
export type TemplatesGetTemplateStatusesEnum = typeof TemplatesGetTemplateStatusesEnum[keyof typeof TemplatesGetTemplateStatusesEnum];
/**
 * @export
 */
export const TemplatesGetUnitTypeEnum = {
    Px: 'px',
    In: 'in',
    Mm: 'mm'
} as const;
export type TemplatesGetUnitTypeEnum = typeof TemplatesGetUnitTypeEnum[keyof typeof TemplatesGetUnitTypeEnum];
/**
 * @export
 */
export const TemplatesGetOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type TemplatesGetOrderEnum = typeof TemplatesGetOrderEnum[keyof typeof TemplatesGetOrderEnum];
/**
 * @export
 */
export const TemplatesGetFieldsEnum = {
    TemplateId: 'template_id',
    TemplateCode: 'template_code',
    InternalProjectId: 'internal_project_id',
    SourceProject: 'source_project',
    MediaType: 'media_type',
    TemplateTitle: 'template_title',
    TemplateStatus: 'template_status',
    Dimensions: 'dimensions',
    PageCount: 'page_count',
    SceneCount: 'scene_count',
    Duration: 'duration',
    PreviewUrl: 'preview_url',
    ThumbnailUrl: 'thumbnail_url',
    PrimaryTemplateCategory: 'primary_template_category',
    PrimaryTemplateCategoryItem: 'primary_template_category_item',
    Brand: 'brand',
    UserGroupsMatchType: 'user_groups_match_type',
    DefaultSocialPostText: 'default_social_post_text',
    DateCreated: 'date_created',
    DateUpdated: 'date_updated',
    ProjectCount: 'project_count'
} as const;
export type TemplatesGetFieldsEnum = typeof TemplatesGetFieldsEnum[keyof typeof TemplatesGetFieldsEnum];
