// Copyright 2024 Merit International Inc. All Rights Reserved

import { type Accept, type FileRejection, useDropzone } from "react-dropzone";
import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Images } from "@src/utils/Image";
import { VerticalSpacer } from "../Spacer";
import { useAlertStore } from "@src/stores";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback } from "react";

type Props = {
  readonly acceptedFileTypes: Accept;
  readonly onDrop: (
    acceptedFiles: readonly File[],
    fileRejections: readonly FileRejection[]
  ) => void;
  readonly isTemplateSelected?: boolean;
  readonly fileName?: string;
};

const { Some } = Helpers;

export const Dropzone = ({ acceptedFileTypes, fileName, isTemplateSelected, onDrop }: Props) => {
  const { theme } = useTheme();
  const { deleteAlert, setAlert } = useAlertStore();

  const { getInputProps, getRootProps, open } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: 1,
    onDrop,
  });

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      color: "#bdbdbd",
      margin: theme.spacing.xl,
      padding: theme.spacing.xl,
      textAlign: "center",
    },
  });

  const showErrorAlert = useCallback(() => {
    setAlert({
      closable: true,
      id: uuidv4(),
      onPressDelete: id => {
        deleteAlert(id);
      },
      size: "medium",
      text: "Please select template type",
      type: "error",
    });
  }, [deleteAlert, setAlert]);

  return (
    <div
      {...getRootProps({
        onClick: event => {
          if (Some(isTemplateSelected) && !isTemplateSelected) {
            event.preventDefault();
            event.stopPropagation();
            showErrorAlert();
          }
        },
      })}
      style={{ outline: "none" }}
    >
      <input {...getInputProps()} />
      <View style={styles.container}>
        <Image
          accessibilityLabel="select-document"
          source={Some(fileName) ? Images.uploadSuccess : Images.selectFile}
          style={{ height: 48, marginVertical: theme.spacing.xl, width: 48 }}
        />
        {Some(fileName) ? (
          <>
            <Body center size="l" style={{ color: theme.colors.text.subdued }}>
              File name: {fileName}
            </Body>
            <VerticalSpacer />
            <View style={{ flexDirection: "row" }}>
              <Pressable onPress={open}>
                <Body color={theme.colors.border.highlight.default} size="l">
                  Choose&nbsp;
                </Body>
              </Pressable>
              <Body center size="l">
                new file or drop here
              </Body>
            </View>
          </>
        ) : (
          <>
            <View style={{ flexDirection: "row" }}>
              <Body center size="l">
                Drop your file here or
              </Body>
              <Pressable onPress={open}>
                <Body color={theme.colors.border.highlight.default} size="l">
                  &nbsp;browse
                </Body>
              </Pressable>
            </View>
          </>
        )}
        <Body
          center
          size="l"
          style={{ color: theme.colors.text.subdued, paddingVertical: theme.spacing.m }}
        >
          Supported files: CSV
        </Body>
      </View>
    </div>
  );
};
