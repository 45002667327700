// Copyright 2023 Merit International Inc. All Rights Reserved

import { useBaseFieldIdStore } from "../stores";
import type { AppConstants } from "../stores";
import type { OrgsGet200ResponseContainersInner as Container } from "../gen/org-portal";

export const getContainerFieldValue = (fieldName: string, container: Container) => {
  const field = container.fields?.find(f => f.name === fieldName);

  if (field === undefined) {
    return "";
  }

  return field.value;
};

type MeritFieldNameKey = keyof AppConstants["meritFieldNames"];
type MeritParams = {
  readonly templateType: "merit";
  readonly fieldName: MeritFieldNameKey;
  readonly container: Container;
};

type FolioFieldNameKey = keyof AppConstants["folioFieldNames"];
type FolioParams = {
  readonly templateType: "folio";
  readonly fieldName: FolioFieldNameKey;
  readonly container: Container;
};

export const useGetContainerValueByBaseFieldName = () => {
  const { fieldIds } = useBaseFieldIdStore();

  const getContainerValueByBaseFieldName = ({
    container,
    fieldName,
    templateType,
  }: FolioParams | MeritParams): string => {
    if (fieldIds === undefined) {
      return "--";
    }

    // get id of base field
    const baseFieldId =
      templateType === "merit" ? fieldIds[templateType][fieldName] : fieldIds.folio[fieldName];

    if (baseFieldId === undefined) {
      throw new Error("could not find expected field on base template");
    }

    // iterate through fields on container to find one with correct id in lineage
    const field = container.fields?.find(f => f.templateFieldLineage?.includes(baseFieldId));

    if (field === undefined) {
      return "--";
    }

    if (field.value === undefined) {
      return "--";
    }

    return field.value;
  };

  return { getContainerValueByBaseFieldName };
};
