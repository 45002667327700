/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgentVersions,
  Errors,
} from '../models/index';
import {
    AgentVersionsFromJSON,
    AgentVersionsToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
} from '../models/index';

export interface ApproveAgentVersionRequest {
    agentID: string;
    versionID: string;
    authorization?: string;
}

export interface GetAgentVersionsRequest {
    agentID: string;
    authorization?: string;
    endingBefore?: string;
    startingAfter?: string;
    limit?: number;
    sortBy?: GetAgentVersionsSortByEnum;
}

export interface GetAgentsVersionsRequest {
    authorization?: string;
    endingBefore?: string;
    startingAfter?: string;
    limit?: number;
    sortBy?: GetAgentsVersionsSortByEnum;
    includeApprovedAgents?: boolean;
    includeApprovedVersions?: boolean;
    versionsPerAgent?: number;
    owner?: string;
}

/**
 * 
 */
export class AgentVersionsApi extends runtime.BaseAPI {

    /**
     * `approve:agent_version`  Approve a version of agent for use on the platform. If there is another approved version of the same agent, the new version will override its data and behavior. 
     * Approve Agent
     */
    async approveAgentVersionRaw(requestParameters: ApproveAgentVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling approveAgentVersion.');
        }

        if (requestParameters.versionID === null || requestParameters.versionID === undefined) {
            throw new runtime.RequiredError('versionID','Required parameter requestParameters.versionID was null or undefined when calling approveAgentVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents/{agentID}/version/{versionID}`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))).replace(`{${"versionID"}}`, encodeURIComponent(String(requestParameters.versionID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `approve:agent_version`  Approve a version of agent for use on the platform. If there is another approved version of the same agent, the new version will override its data and behavior. 
     * Approve Agent
     */
    async approveAgentVersion(requestParameters: ApproveAgentVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveAgentVersionRaw(requestParameters, initOverrides);
    }

    /**
     * `read:agent_version`  Get a paginated list of a single agent\'s versions 
     * Get Agent Versions
     */
    async getAgentVersionsRaw(requestParameters: GetAgentVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgentVersions>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling getAgentVersions.');
        }

        const queryParameters: any = {};

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/agents/{agentID}/version`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentVersionsFromJSON(jsonValue));
    }

    /**
     * `read:agent_version`  Get a paginated list of a single agent\'s versions 
     * Get Agent Versions
     */
    async getAgentVersions(requestParameters: GetAgentVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgentVersions> {
        const response = await this.getAgentVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:agent_version`  Return a paginated list of multiple agents\' versions. 
     * Get Agents Versions
     */
    async getAgentsVersionsRaw(requestParameters: GetAgentsVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgentVersions>>> {
        const queryParameters: any = {};

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.includeApprovedAgents !== undefined) {
            queryParameters['include_approved_agents'] = requestParameters.includeApprovedAgents;
        }

        if (requestParameters.includeApprovedVersions !== undefined) {
            queryParameters['include_approved_versions'] = requestParameters.includeApprovedVersions;
        }

        if (requestParameters.versionsPerAgent !== undefined) {
            queryParameters['versions_per_agent'] = requestParameters.versionsPerAgent;
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgentVersionsFromJSON));
    }

    /**
     * `read:agent_version`  Return a paginated list of multiple agents\' versions. 
     * Get Agents Versions
     */
    async getAgentsVersions(requestParameters: GetAgentsVersionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgentVersions>> {
        const response = await this.getAgentsVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAgentVersionsSortByEnum = {
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at'
} as const;
export type GetAgentVersionsSortByEnum = typeof GetAgentVersionsSortByEnum[keyof typeof GetAgentVersionsSortByEnum];
/**
 * @export
 */
export const GetAgentsVersionsSortByEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type GetAgentsVersionsSortByEnum = typeof GetAgentsVersionsSortByEnum[keyof typeof GetAgentsVersionsSortByEnum];
