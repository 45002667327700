// Copyright 2023 Merit International Inc. All Rights Reserved

import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type {
  DefaultApi,
  GetDatasource200Response,
  GetDatasourceRequest,
  ListDatasourcesRequest,
} from "../../gen/org-portal";

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

export const useDatasourcesItemData = (api: DefaultApi, orgID: string, datasourceID: string) => {
  const DEFAULT_PARAMS = {
    datasourceID,
    orgID,
  } as const;

  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<GetDatasourceRequest>(DEFAULT_PARAMS);
  const [response, setResponse] = useState<GetDatasource200Response>();
  const { errorHandler } = useServerErrorHandler();

  const data = response?.datasource;

  const refresh = (nextParams: Partial<ListDatasourcesRequest> = {}) => {
    if (loading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setLoading(true);

        const res = await api.getDatasource(params);

        setResponse(res);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [api, errorHandler, params]);

  return {
    data,
    loading,
    refresh,
  };
};
