// Copyright 2024 Merit International Inc. All Rights Reserved

export const Agents = [
  {
    description: "Approve agent",
    key: "approve:agent_version",
    name: "Approve agent",
  },
  {
    description: "Create an agent",
    key: "create:agent",
    name: "Create agent",
  },
  {
    description: "Update an agent",
    key: "update:agent",
    name: "Update agent",
  },
  {
    description: "Fetch information on an agent",
    key: "read:agent",
    name: "Read agent",
  },
  {
    description: "Delete an agent",
    key: "delete:agent",
    name: "Delete agent",
  },
  {
    description: "Link an entity to an agent",
    key: "create:agent_entity_link",
    name: "Create agent entity link",
  },
  {
    description: "Fetch information on agents an entity is linked to",
    key: "read:agent_entity_link",
    name: "Read agent entity links",
  },
];

export const Container = [
  {
    description: "Read containers",
    key: "read:container",
    name: "Read containers",
  },
  {
    description: "Authorize, approve, reject or revoke a container",
    key: "transition:container",
    name: "Transition containers",
  },
  {
    description: "Remove a container",
    key: "delete:container",
    name: "Remove a container",
  },
];

export const Datasource = [
  {
    description: "Create a data source to ingest records into",
    key: "create:datasource",
    name: "Create a new data source",
  },
  {
    description: "Update an existing data source",
    key: "update:datasource",
    name: "Update data source",
  },
  {
    description: "Read data source",
    key: "read:datasource",
    name: "Read one or more data sources",
  },
  {
    description:
      "Delete a data source. This will stop ingesting data, but all data already in the platform remains intact.",
    key: "delete:datasource",
    name: "Delete data source",
  },
];

export const Entity = [
  {
    description: "Get a JWT containing an entity’s ID",
    key: "read:entity",
    name: "Get a JWT containing an entity’s ID",
  },
  {
    description: "Merge entity",
    key: "merge:entity",
    name: "Merge entity",
  },
];

export const Fields = [
  {
    description: "Create a field kind",
    key: "extend:field_kind",
    name: "Create a field kind",
  },
  {
    description: "Read a field kind",
    key: "read:field_kind",
    name: "Read a field kind",
  },
  {
    description: "Update an existing field kind",
    key: "update:field_kind",
    name: "Update an existing field kind",
  },
];

export const Ingestion = [
  {
    description: "Get the status of a file upload",
    key: "read:file_ingestion_status",
    name: "Get the status of a file upload",
  },
];

export const Integration = [
  {
    description:
      "Create an integration to an external data source, such as an SFTP server or Google sheet",
    key: "create:integration",
    name: "Create integration",
  },
  {
    description:
      "Update an integration to an external data source, such as an SFTP server or Google sheet",
    key: "update:integration",
    name: "Update integration",
  },
  {
    description:
      "Read one or more integrations to external data sources, such as an SFTP server or Google sheet",
    key: "read:integration",
    name: "Read integration",
  },
  {
    description:
      "Delete an integration to an external data source, such as an SFTP server or Google sheet, and stop ingesting new data from that source",
    key: "delete:integration",
    name: "Delete integration",
  },
];

export const Persona = [
  {
    description: "Create a persona",
    key: "create:persona",
    name: "Create persona",
  },
];

export const Policy = [
  {
    description: "Create a policy",
    key: "extend:policy",
    name: "Create a policy",
  },
  {
    description: "Read policy",
    key: "read:policy",
    name: "Read policy",
  },
  {
    description: "Update policy",
    key: "update:policy",
    name: "Update policy",
  },
  {
    description: "Request to run a policy on a user",
    key: "create:policy_request",
    name: "Create policy request",
  },
  {
    description: "Read the results of a policy request",
    key: "read:policy_request",
    name: "Read policy request",
  },
  {
    description: "Update policy request",
    key: "update:policy_request",
    name: "Update policy request",
  },
];

export const Record = [
  {
    description:
      "Ingest data into the platform, whether through file upload, POST with a single record, or mapping a datasource to an integration.",
    key: "create:record",
    name: "Create record",
  },
];

export const Templates = [
  {
    description: "Extend template",
    key: "extend:template",
    name: "Extend template",
  },
  {
    description: "Update template",
    key: "update:template",
    name: "Update template",
  },
  {
    description: "Read template",
    key: "read:template",
    name: "Read template",
  },
];

export const Scopes = {
  Agents,
  Container,
  Datasource,
  Entity,
  Fields,
  Ingestion,
  Integration,
  Persona,
  Policy,
  Record,
  Templates,
};
