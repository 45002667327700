/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProjectsPOSTRequestOneOf } from './ProjectsPOSTRequestOneOf';
import {
    instanceOfProjectsPOSTRequestOneOf,
    ProjectsPOSTRequestOneOfFromJSON,
    ProjectsPOSTRequestOneOfFromJSONTyped,
    ProjectsPOSTRequestOneOfToJSON,
} from './ProjectsPOSTRequestOneOf';
import type { ProjectsPOSTRequestOneOf1 } from './ProjectsPOSTRequestOneOf1';
import {
    instanceOfProjectsPOSTRequestOneOf1,
    ProjectsPOSTRequestOneOf1FromJSON,
    ProjectsPOSTRequestOneOf1FromJSONTyped,
    ProjectsPOSTRequestOneOf1ToJSON,
} from './ProjectsPOSTRequestOneOf1';
import type { ProjectsPOSTRequestOneOf2 } from './ProjectsPOSTRequestOneOf2';
import {
    instanceOfProjectsPOSTRequestOneOf2,
    ProjectsPOSTRequestOneOf2FromJSON,
    ProjectsPOSTRequestOneOf2FromJSONTyped,
    ProjectsPOSTRequestOneOf2ToJSON,
} from './ProjectsPOSTRequestOneOf2';
import type { ProjectsPOSTRequestOneOf3 } from './ProjectsPOSTRequestOneOf3';
import {
    instanceOfProjectsPOSTRequestOneOf3,
    ProjectsPOSTRequestOneOf3FromJSON,
    ProjectsPOSTRequestOneOf3FromJSONTyped,
    ProjectsPOSTRequestOneOf3ToJSON,
} from './ProjectsPOSTRequestOneOf3';
import type { ProjectsPOSTRequestOneOf4 } from './ProjectsPOSTRequestOneOf4';
import {
    instanceOfProjectsPOSTRequestOneOf4,
    ProjectsPOSTRequestOneOf4FromJSON,
    ProjectsPOSTRequestOneOf4FromJSONTyped,
    ProjectsPOSTRequestOneOf4ToJSON,
} from './ProjectsPOSTRequestOneOf4';
import type { ProjectsPOSTRequestOneOf5 } from './ProjectsPOSTRequestOneOf5';
import {
    instanceOfProjectsPOSTRequestOneOf5,
    ProjectsPOSTRequestOneOf5FromJSON,
    ProjectsPOSTRequestOneOf5FromJSONTyped,
    ProjectsPOSTRequestOneOf5ToJSON,
} from './ProjectsPOSTRequestOneOf5';
import type { ProjectsPOSTRequestOneOf6 } from './ProjectsPOSTRequestOneOf6';
import {
    instanceOfProjectsPOSTRequestOneOf6,
    ProjectsPOSTRequestOneOf6FromJSON,
    ProjectsPOSTRequestOneOf6FromJSONTyped,
    ProjectsPOSTRequestOneOf6ToJSON,
} from './ProjectsPOSTRequestOneOf6';
import type { ProjectsPOSTRequestOneOf7 } from './ProjectsPOSTRequestOneOf7';
import {
    instanceOfProjectsPOSTRequestOneOf7,
    ProjectsPOSTRequestOneOf7FromJSON,
    ProjectsPOSTRequestOneOf7FromJSONTyped,
    ProjectsPOSTRequestOneOf7ToJSON,
} from './ProjectsPOSTRequestOneOf7';

/**
 * @type ProjectsPOSTRequest
 * 
 * @export
 */
export type ProjectsPOSTRequest = ProjectsPOSTRequestOneOf | ProjectsPOSTRequestOneOf1 | ProjectsPOSTRequestOneOf2 | ProjectsPOSTRequestOneOf3 | ProjectsPOSTRequestOneOf4 | ProjectsPOSTRequestOneOf5 | ProjectsPOSTRequestOneOf6 | ProjectsPOSTRequestOneOf7;

export function ProjectsPOSTRequestFromJSON(json: any): ProjectsPOSTRequest {
    return ProjectsPOSTRequestFromJSONTyped(json, false);
}

export function ProjectsPOSTRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsPOSTRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ProjectsPOSTRequestOneOfFromJSONTyped(json, true), ...ProjectsPOSTRequestOneOf1FromJSONTyped(json, true), ...ProjectsPOSTRequestOneOf2FromJSONTyped(json, true), ...ProjectsPOSTRequestOneOf3FromJSONTyped(json, true), ...ProjectsPOSTRequestOneOf4FromJSONTyped(json, true), ...ProjectsPOSTRequestOneOf5FromJSONTyped(json, true), ...ProjectsPOSTRequestOneOf6FromJSONTyped(json, true), ...ProjectsPOSTRequestOneOf7FromJSONTyped(json, true) };
}

export function ProjectsPOSTRequestToJSON(value?: ProjectsPOSTRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfProjectsPOSTRequestOneOf(value)) {
        return ProjectsPOSTRequestOneOfToJSON(value as ProjectsPOSTRequestOneOf);
    }
    if (instanceOfProjectsPOSTRequestOneOf1(value)) {
        return ProjectsPOSTRequestOneOf1ToJSON(value as ProjectsPOSTRequestOneOf1);
    }
    if (instanceOfProjectsPOSTRequestOneOf2(value)) {
        return ProjectsPOSTRequestOneOf2ToJSON(value as ProjectsPOSTRequestOneOf2);
    }
    if (instanceOfProjectsPOSTRequestOneOf3(value)) {
        return ProjectsPOSTRequestOneOf3ToJSON(value as ProjectsPOSTRequestOneOf3);
    }
    if (instanceOfProjectsPOSTRequestOneOf4(value)) {
        return ProjectsPOSTRequestOneOf4ToJSON(value as ProjectsPOSTRequestOneOf4);
    }
    if (instanceOfProjectsPOSTRequestOneOf5(value)) {
        return ProjectsPOSTRequestOneOf5ToJSON(value as ProjectsPOSTRequestOneOf5);
    }
    if (instanceOfProjectsPOSTRequestOneOf6(value)) {
        return ProjectsPOSTRequestOneOf6ToJSON(value as ProjectsPOSTRequestOneOf6);
    }
    if (instanceOfProjectsPOSTRequestOneOf7(value)) {
        return ProjectsPOSTRequestOneOf7ToJSON(value as ProjectsPOSTRequestOneOf7);
    }

    return {};
}

