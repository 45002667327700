// Copyright 2023 Merit International Inc. All Rights Reserved

import { StyleSheet } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

type RecordsSearchStyles = {
  readonly container: ViewStyle;
  readonly tabBody: ViewStyle;
};

export const useRecordsSearchStyles = () => {
  const { theme } = useTheme();

  return StyleSheet.create<RecordsSearchStyles>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    tabBody: {
      flex: 1,
      paddingTop: theme.spacing.xxl,
    },
  });
};

type RecordsSearchFormStyles = {
  readonly formContainer: ViewStyle;
  readonly formItem: ViewStyle;
  readonly formRow: ViewStyle;
  readonly tabContainer: ViewStyle;
  readonly formItemWide: ViewStyle;
  readonly inputFieldsContainer: ViewStyle;
  readonly searchButtonContainer: ViewStyle;
  readonly formWrapper: ViewStyle;
  readonly issueDateContainer: ViewStyle;
};

export const useRecordsSearchFormStyles = () => {
  const { theme } = useTheme();

  return StyleSheet.create<RecordsSearchFormStyles>({
    formContainer: {
      backgroundColor: theme.colors.surface.subdued,
    },
    formItem: {
      width: 250,
    },
    formItemWide: {
      width: 350,
    },
    formRow: {
      alignContent: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    inputFieldsContainer: {
      paddingLeft: 32,
      paddingVertical: theme.spacing.l,
    },
    issueDateContainer: {
      borderColor: theme.colors.border.subdued,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    },
    searchButtonContainer: {
      alignSelf: "flex-end",
      marginVertical: theme.spacing.l,
    },
    tabContainer: {
      paddingHorizontal: 32,
    },
  });
};
