/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Authenticate response
 * @export
 * @interface Authenticate200Response
 */
export interface Authenticate200Response {
    /**
     * DesignHuddle access token
     * @type {string}
     * @memberof Authenticate200Response
     */
    accessToken: string;
}

/**
 * Check if a given object implements the Authenticate200Response interface.
 */
export function instanceOfAuthenticate200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;

    return isInstance;
}

export function Authenticate200ResponseFromJSON(json: any): Authenticate200Response {
    return Authenticate200ResponseFromJSONTyped(json, false);
}

export function Authenticate200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Authenticate200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
    };
}

export function Authenticate200ResponseToJSON(value?: Authenticate200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
    };
}

