/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';

/**
 * A pair of permissions applicable for an action to be performed on an object
 * @export
 * @interface PermissionPair
 */
export interface PermissionPair {
    /**
     * 
     * @type {string}
     * @memberof PermissionPair
     */
    action: string;
    /**
     * 
     * @type {Permission}
     * @memberof PermissionPair
     */
    permitted: Permission;
    /**
     * 
     * @type {Permission}
     * @memberof PermissionPair
     */
    permissibleToPermit: Permission;
}

/**
 * Check if a given object implements the PermissionPair interface.
 */
export function instanceOfPermissionPair(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "permitted" in value;
    isInstance = isInstance && "permissibleToPermit" in value;

    return isInstance;
}

export function PermissionPairFromJSON(json: any): PermissionPair {
    return PermissionPairFromJSONTyped(json, false);
}

export function PermissionPairFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionPair {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'permitted': PermissionFromJSON(json['permitted']),
        'permissibleToPermit': PermissionFromJSON(json['permissibleToPermit']),
    };
}

export function PermissionPairToJSON(value?: PermissionPair | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'permitted': PermissionToJSON(value.permitted),
        'permissibleToPermit': PermissionToJSON(value.permissibleToPermit),
    };
}

