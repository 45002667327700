// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Image, StyleSheet, View } from "react-native";
import { Images } from "../../utils/Image";
import { VerticalSpacer } from "../../components/Spacer";
import React from "react";
import type { OPTestProps } from "../../types/TestProps";

type Props = {
  readonly createDatasource: () => void;
  readonly testProps: OPTestProps;
};

const { Some } = Helpers;
const SCREEN_NAME = "NoDatasources";

export const NoDatasourcesScreen = ({ createDatasource, testProps }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    centeredView: {
      alignItems: "center",
      flex: 1,
      height: "auto",
      justifyContent: "center",
    },
    wrapper: {
      alignItems: "center",
      justifyContent: "center",
    },
  });

  return (
    <View style={styles.centeredView}>
      <View style={styles.wrapper}>
        <Image accessible source={Images.emptyBin} style={{ height: 100, width: 100 }} />

        <View style={{ alignItems: "center", paddingVertical: theme.spacing.xl }}>
          <Heading
            level="2"
            testProps={{
              elementName: Some(testProps) ? `${testProps.elementName}HeaderText` : "HeaderText",
              screenName: SCREEN_NAME,
            }}
          >
            No data sources yet
          </Heading>
          <VerticalSpacer size={theme.spacing.xl} />
          <Button
            onPress={createDatasource}
            testProps={{
              elementName: Some(testProps)
                ? `${testProps.elementName}CreateDatasourcesButton`
                : "CreateDatasourcesButton",
              screenName: SCREEN_NAME,
            }}
            text="Create data source"
          />
        </View>

        <Image accessible source={Images.emptyDatasource} style={{ height: 150, width: 600 }} />
      </View>
    </View>
  );
};
