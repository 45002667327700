// Copyright 2023 Merit International Inc. All Rights Reserved

import { useAppConstantsStore } from "../../stores";
import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type {
  DefaultApi,
  GetFieldKinds200Response,
  GetFieldKindsRequest,
} from "../../gen/org-portal";

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

export const useFieldsData = (
  api: DefaultApi,
  orgID: string,
  isFocused: boolean,
  ownerOrgID?: string
) => {
  const appConstants = useAppConstantsStore();
  const DEFAULT_PARAMS = {
    limit: appConstants.fieldsLimit,
    orgID,
    ownerOrgID,
  } as const;

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<GetFieldKinds200Response>();
  const [params, setParams] = useState<GetFieldKindsRequest>(DEFAULT_PARAMS);
  const { errorHandler } = useServerErrorHandler();

  const data = response?.fieldKinds ?? [];
  const pagination: PaginationMeta = {
    hasNextPage: response?.paginationInfo?.nextStartAfter !== undefined,
    hasPrevPage: response?.paginationInfo?.previousEndBefore !== undefined,
    nextStartAfter: response?.paginationInfo?.nextStartAfter,
    prevEndBefore: response?.paginationInfo?.previousEndBefore,
  };

  const refresh = (nextParams: Partial<GetFieldKindsRequest> = {}) => {
    if (loading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  const nextPage = () => {
    if (pagination.nextStartAfter === undefined) {
      return;
    }

    refresh({
      end: undefined,
      start: pagination.nextStartAfter,
    });
  };

  const prevPage = () => {
    if (pagination.prevEndBefore === undefined) {
      return;
    }

    refresh({
      end: pagination.prevEndBefore,
      start: undefined,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setLoading(true);

        const res = await api.getFieldKinds(params);

        setResponse(res);
      } catch (error) {
        setResponse(undefined);
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }

    if (isFocused) {
      fetchData();
    }
  }, [api, errorHandler, isFocused, params]);

  return {
    data,
    loading,
    nextPage,
    pagination,
    prevPage,
    refresh,
  };
};
