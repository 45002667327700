/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityInfo } from './EntityInfo';
import {
    EntityInfoFromJSON,
    EntityInfoFromJSONTyped,
    EntityInfoToJSON,
} from './EntityInfo';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';
import type { ResponseFormulas } from './ResponseFormulas';
import {
    ResponseFormulasFromJSON,
    ResponseFormulasFromJSONTyped,
    ResponseFormulasToJSON,
} from './ResponseFormulas';
import type { ResponseRules } from './ResponseRules';
import {
    ResponseRulesFromJSON,
    ResponseRulesFromJSONTyped,
    ResponseRulesToJSON,
} from './ResponseRules';

/**
 * Representation of a named Policy belonging to an entity.
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof Policy
     */
    id: string;
    /**
     * 
     * @type {EntityInfo}
     * @memberof Policy
     */
    owner: EntityInfo;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    createdAt: Date;
    /**
     * The given name of this Policy.
     * @type {string}
     * @memberof Policy
     */
    name: string;
    /**
     * A description of this Policy.
     * @type {string}
     * @memberof Policy
     */
    description?: string;
    /**
     * The message response when a Policy run returns false.
     * @type {string}
     * @memberof Policy
     */
    falseMessage: string;
    /**
     * The message response when a Policy run returns true.
     * @type {string}
     * @memberof Policy
     */
    trueMessage: string;
    /**
     * The id of the Policy this was extended from.
     * @type {string}
     * @memberof Policy
     */
    parent?: string;
    /**
     * 
     * @type {ResponseRules}
     * @memberof Policy
     */
    rules?: ResponseRules;
    /**
     * 
     * @type {ResponseFormulas}
     * @memberof Policy
     */
    formula?: ResponseFormulas;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof Policy
     */
    permissions: Array<PermissionPair>;
    /**
     * The state of this policy, whether it's live or paused.
     * @type {string}
     * @memberof Policy
     */
    state: PolicyStateEnum;
    /**
     * Whether or not the policy has any children.
     * @type {boolean}
     * @memberof Policy
     */
    hasChildren?: boolean;
}


/**
 * @export
 */
export const PolicyStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type PolicyStateEnum = typeof PolicyStateEnum[keyof typeof PolicyStateEnum];


/**
 * Check if a given object implements the Policy interface.
 */
export function instanceOfPolicy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "falseMessage" in value;
    isInstance = isInstance && "trueMessage" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function PolicyFromJSON(json: any): Policy {
    return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': EntityInfoFromJSON(json['owner']),
        'createdAt': (new Date(json['createdAt'])),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'falseMessage': json['falseMessage'],
        'trueMessage': json['trueMessage'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'rules': !exists(json, 'rules') ? undefined : ResponseRulesFromJSON(json['rules']),
        'formula': !exists(json, 'formula') ? undefined : ResponseFormulasFromJSON(json['formula']),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'state': json['state'],
        'hasChildren': !exists(json, 'hasChildren') ? undefined : json['hasChildren'],
    };
}

export function PolicyToJSON(value?: Policy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner': EntityInfoToJSON(value.owner),
        'createdAt': (value.createdAt.toISOString()),
        'name': value.name,
        'description': value.description,
        'falseMessage': value.falseMessage,
        'trueMessage': value.trueMessage,
        'parent': value.parent,
        'rules': ResponseRulesToJSON(value.rules),
        'formula': ResponseFormulasToJSON(value.formula),
        'permissions': ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'state': value.state,
        'hasChildren': value.hasChildren,
    };
}

