// Copyright 2024 Merit International Inc. All Rights Reserved

import { Badge, Body, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks/useGetTestProps";
import type { BadgeProps } from "@merit/frontend-components";
import type { OPTestProps } from "@src/types/TestProps";
import type { TextStyle, ViewStyle } from "react-native";

type BadgeGroupProps = {
  readonly allowWrap?: boolean;
  /** Display only the first `limit` number of texts. Texts with their index over this values will be hidden,
   * and in their place a `+n` text  will be displayed instead. */
  readonly limit?: number;
  readonly singleColor?: BadgeProps["color"];
  readonly texts: readonly string[];
  readonly testProps?: OPTestProps;
};

// TODO: consider deterministic matching of individual role string to colors in the future
const palette: readonly BadgeProps["color"][] = [
  "oceanBlue",
  "turquoise",
  "jade",
  "citrine",
  "coral",
];
export const BadgeGroup = ({
  allowWrap = false,
  limit = Infinity,
  singleColor,
  testProps,
  texts,
}: BadgeGroupProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly badgeWrapper: ViewStyle;
    readonly containerWrap: ViewStyle;
    readonly remainderText: TextStyle;
  }>({
    badgeWrapper: {
      alignItems: "center",
      flexDirection: "row",
      marginBottom: theme.spacing.xxs,
      marginRight: theme.spacing.xxs,
    },
    containerWrap: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    remainderText: {
      paddingLeft: theme.spacing.xs,
    },
  });

  const getTestProps = useGetTestProps();

  const textsToShow = limit > 0 ? texts.slice(0, limit) : texts;
  const remainder = texts.length - textsToShow.length;

  return (
    <View style={allowWrap && styles.containerWrap} {...getTestProps(testProps)}>
      {textsToShow.toSorted().map((text, index) => {
        const bubbleColor =
          singleColor === undefined ? palette[index % palette.length] : singleColor;

        return (
          <View key={text} style={styles.badgeWrapper}>
            <Badge
              color={bubbleColor}
              testProps={{
                elementId: text,
                elementName: "Badge",
                screenName: testProps?.screenName ?? "",
              }}
              text={text}
            />
            {index === textsToShow.length - 1 && remainder > 0 && (
              <Body
                style={styles.remainderText}
                testProps={{
                  elementId: "remainder",
                  elementName: "Badge",
                  screenName: testProps?.screenName ?? "",
                }}
              >{`+${remainder}`}</Body>
            )}
          </View>
        );
      })}
    </View>
  );
};
