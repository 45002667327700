// Copyright 2023 Merit International Inc. All Rights Reserved.

import { create } from "zustand";

const ADMIN_ROLE_NAMES = {
  checkinAdmin: "Check-in Admin",
  dashboardAdmin: "Dashboard Admin",
  designAdmin: "Design Admin",
  orgAdmin: "Org Admin",
} as const;
type AdminRoleNamesMap = typeof ADMIN_ROLE_NAMES;
export type AdminRoleNamesValue = AdminRoleNamesMap[keyof AdminRoleNamesMap];

export const adminRoleNamesSortedByImportance: readonly AdminRoleNamesValue[] = [
  ADMIN_ROLE_NAMES.orgAdmin,
  ADMIN_ROLE_NAMES.designAdmin,
  ADMIN_ROLE_NAMES.dashboardAdmin,
  ADMIN_ROLE_NAMES.checkinAdmin,
];

const ORG_ADD_ON_NAMES = {
  checkIn: "Check-in",
  customIngestionInterval: "Custom Ingestion Interval",
  dashboardAndAnalytics: "Dashboards + Analytics",
  studio: "Studio",
} as const;
type OrgAddOnNamesMap = typeof ORG_ADD_ON_NAMES;
export type OrgAddOnNamesValue = OrgAddOnNamesMap[keyof OrgAddOnNamesMap];

const ORG_ADD_ON_TO_ADMIN_ROLE_MAP: Record<OrgAddOnNamesValue, AdminRoleNamesValue | undefined> = {
  [ORG_ADD_ON_NAMES.checkIn]: ADMIN_ROLE_NAMES.checkinAdmin,
  [ORG_ADD_ON_NAMES.customIngestionInterval]: undefined,
  [ORG_ADD_ON_NAMES.dashboardAndAnalytics]: ADMIN_ROLE_NAMES.dashboardAdmin,
  [ORG_ADD_ON_NAMES.studio]: ADMIN_ROLE_NAMES.designAdmin,
};
type OrgAddOnToAdminRoleMap = typeof ORG_ADD_ON_TO_ADMIN_ROLE_MAP;

export type AppConstants = {
  readonly datasourcesLimit: number;
  readonly templatesLimit: number;
  readonly sharedTemplatesLimit: number;
  readonly recordsLimit: number;
  readonly approvalsLimit: number;
  readonly folioInboxLimit: number;
  readonly fieldsLimit: number;
  readonly sharedFieldsLimit: number;
  readonly fieldMappingFolioLimit: number;
  readonly adminsLimit: number;
  readonly policiesLimit: number;
  readonly agentsLimit: number;
  readonly folioFieldNames: {
    readonly organizationName: string;
    readonly issuingOrgName: string;
  };
  readonly meritFieldNames: {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly issuingOrgName: string;
  };
  readonly accountFolioFieldNames: {
    readonly name: string;
    readonly description: string;
    readonly website: string;
    readonly addressLine1: string;
    readonly addressLine2: string;
    readonly addressCity: string;
    readonly addressState: string;
    readonly addressZipCode: string;
    readonly addressCountry: string;
    readonly phoneNumber: string;
    readonly timeZone: string;
    readonly logo: string;
    readonly governingCountry: string;
    readonly governingState: string;
    readonly governmentIDNumber: string;
    readonly governmentIDType: string;
    readonly governmentLegalName: string;
    readonly addOns: string;
  };
  readonly adminMeritFieldNames: {
    readonly roles: string;
  };
  readonly adminRoleNames: AdminRoleNamesMap;
  readonly adminRoleNamesSortedByImportance: readonly AdminRoleNamesValue[];
  readonly orgAddOnNames: OrgAddOnNamesMap;
  readonly orgAddOnToAdminRoleMap: OrgAddOnToAdminRoleMap;
};

export const useAppConstantsStore = create<AppConstants>()(() => ({
  accountFolioFieldNames: {
    addOns: "Add-ons",
    addressCity: "City",
    addressCountry: "Country",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    addressState: "State",
    addressZipCode: "Zip Code",
    description: "Org Description",
    governingCountry: "Governing Country",
    governingState: "Governing State",
    governmentIDNumber: "Government ID Number",
    governmentIDType: "Government ID Type",
    governmentLegalName: "Org Legal Name",
    logo: "Org Logo",
    name: "Organization Name",
    phoneNumber: "Org Phone Number",
    timeZone: "Timezone",
    website: "Org Website",
  },
  adminMeritFieldNames: {
    roles: "Roles",
  },
  adminRoleNames: ADMIN_ROLE_NAMES,
  adminRoleNamesSortedByImportance,
  adminsLimit: 25,
  agentsLimit: 100,
  approvalsLimit: 100,
  datasourcesLimit: 10,
  fieldMappingFolioLimit: 20,
  fieldsLimit: 10,
  folioFieldNames: {
    issuingOrgName: "Issuing Org Name",
    organizationName: "Organization Name",
  },
  folioInboxLimit: 100,
  meritFieldNames: {
    email: "Email",
    firstName: "First Name",
    issuingOrgName: "Issuing Org Name",
    lastName: "Last Name",
  },
  orgAddOnNames: ORG_ADD_ON_NAMES,
  orgAddOnToAdminRoleMap: ORG_ADD_ON_TO_ADMIN_ROLE_MAP,
  policiesLimit: 100,
  recordsLimit: 100,
  sharedFieldsLimit: 10,
  sharedTemplatesLimit: 10,
  templatesLimit: 10,
}));
