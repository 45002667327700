/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * An object that represents a failed rule predicate.
 * @export
 * @interface OrgsGet200ResponseContainersInnerCompletenessFailuresInner
 */
export interface OrgsGet200ResponseContainersInnerCompletenessFailuresInner {
    /**
     * The list of field kinds available.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInner
     */
    templateFieldID?: string;
    /**
     * The template id where the rule predicate originates from
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInner
     */
    templateID?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInner
     */
    fieldKindID?: string | null;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInner
     */
    atom?: OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInner
     */
    message?: string;
}

/**
 * Check if a given object implements the OrgsGet200ResponseContainersInnerCompletenessFailuresInner interface.
 */
export function instanceOfOrgsGet200ResponseContainersInnerCompletenessFailuresInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSON(json: any): OrgsGet200ResponseContainersInnerCompletenessFailuresInner {
    return OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSONTyped(json, false);
}

export function OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200ResponseContainersInnerCompletenessFailuresInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldID': !exists(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'templateID': !exists(json, 'templateID') ? undefined : json['templateID'],
        'fieldKindID': !exists(json, 'fieldKindID') ? undefined : json['fieldKindID'],
        'atom': !exists(json, 'atom') ? undefined : OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON(json['atom']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function OrgsGet200ResponseContainersInnerCompletenessFailuresInnerToJSON(value?: OrgsGet200ResponseContainersInnerCompletenessFailuresInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldID': value.templateFieldID,
        'templateID': value.templateID,
        'fieldKindID': value.fieldKindID,
        'atom': OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON(value.atom),
        'message': value.message,
    };
}

