/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectsPOSTRequestOneOf3Dimensions } from './ProjectsPOSTRequestOneOf3Dimensions';
import {
    ProjectsPOSTRequestOneOf3DimensionsFromJSON,
    ProjectsPOSTRequestOneOf3DimensionsFromJSONTyped,
    ProjectsPOSTRequestOneOf3DimensionsToJSON,
} from './ProjectsPOSTRequestOneOf3Dimensions';

/**
 * 
 * @export
 * @interface ProjectsProjectIDClonePOSTRequest
 */
export interface ProjectsProjectIDClonePOSTRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDClonePOSTRequest
     */
    projectTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDClonePOSTRequest
     */
    mediaType?: ProjectsProjectIDClonePOSTRequestMediaTypeEnum;
    /**
     * 
     * @type {ProjectsPOSTRequestOneOf3Dimensions}
     * @memberof ProjectsProjectIDClonePOSTRequest
     */
    dimensions?: ProjectsPOSTRequestOneOf3Dimensions;
    /**
     * Override the owner of the new project, otherwise uses the current token user context.
     * @type {number}
     * @memberof ProjectsProjectIDClonePOSTRequest
     */
    targetUserId?: number;
}


/**
 * @export
 */
export const ProjectsProjectIDClonePOSTRequestMediaTypeEnum = {
    Digital: 'digital',
    Video: 'video',
    Print: 'print',
    Presentation: 'presentation'
} as const;
export type ProjectsProjectIDClonePOSTRequestMediaTypeEnum = typeof ProjectsProjectIDClonePOSTRequestMediaTypeEnum[keyof typeof ProjectsProjectIDClonePOSTRequestMediaTypeEnum];


/**
 * Check if a given object implements the ProjectsProjectIDClonePOSTRequest interface.
 */
export function instanceOfProjectsProjectIDClonePOSTRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectsProjectIDClonePOSTRequestFromJSON(json: any): ProjectsProjectIDClonePOSTRequest {
    return ProjectsProjectIDClonePOSTRequestFromJSONTyped(json, false);
}

export function ProjectsProjectIDClonePOSTRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDClonePOSTRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectTitle': !exists(json, 'project_title') ? undefined : json['project_title'],
        'mediaType': !exists(json, 'media_type') ? undefined : json['media_type'],
        'dimensions': !exists(json, 'dimensions') ? undefined : ProjectsPOSTRequestOneOf3DimensionsFromJSON(json['dimensions']),
        'targetUserId': !exists(json, 'target_user_id') ? undefined : json['target_user_id'],
    };
}

export function ProjectsProjectIDClonePOSTRequestToJSON(value?: ProjectsProjectIDClonePOSTRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_title': value.projectTitle,
        'media_type': value.mediaType,
        'dimensions': ProjectsPOSTRequestOneOf3DimensionsToJSON(value.dimensions),
        'target_user_id': value.targetUserId,
    };
}

