// Copyright 2022 Merit International Inc. All Rights Reserved.

import { AssertIsDefined, AssertIsString } from "../utils/asserts";
import { useConfigurationStore } from "../stores";
import type { GetConfiguration200Response } from "../gen/org-portal";

export type ConfigurationState = {
  readonly configuration: GetConfiguration200Response;
  readonly environmentName: string;
};

export const useLoadedConfigurationState = (): ConfigurationState => {
  const { configuration, environmentName } = useConfigurationStore();

  AssertIsDefined(configuration, "Configuration must be loaded");
  AssertIsString(environmentName, "Environment name must be loaded from configuration");

  return { configuration, environmentName };
};
