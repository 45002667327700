// Copyright 2023 Merit International Inc. All Rights Reserved

import { AdminForm } from "./CreateAdmin";
import { AdminManagement } from "./AdminManagement/AdminManagement";
import { Button, Modal, useTheme } from "@merit/frontend-components";
import { ConnectedOrganizations } from "./ConnectedOrganizations/ConnectedOrganizations";
import { HorizontalSpacer, Tabs } from "../../components";
import { LinkedApps } from "./LinkedApps/LinkedApps";
import { MyApps } from "./MyApps/MyApps";
import { OrganizationProfile } from "./OrganizationProfile";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { StyleSheet, View } from "react-native";
import { useAcceptedFolioStore } from "../../stores";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { useLoggedInUserRoles } from "@src/hooks/loggedInUserRoles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { LDFeatureFlags } from "../../configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "@src/Router";
import type { RouteProp } from "@react-navigation/native";

export type OrgSettingsTabKey =
  | "adminManagement"
  | "connectedOrganizations"
  | "linkedApps"
  | "myApps"
  | "organizationProfile";

const SCREEN_NAME = "OrganizationSettings";

type Navigation = NativeStackNavigationProp<RouteParams, "OrgSettings">;

export const OrgSettings = () => {
  const { theme } = useTheme();
  const navigation = useNavigation<Navigation>();
  const { allowOrgPortalAgentsCreate, showLinkedAppsFeature } = useFlags<LDFeatureFlags>();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const { isFoliosLoaded, userAcceptedAccountFolio } = useAcceptedFolioStore();
  const { params: routeParams } = useRoute<RouteProp<RouteParams, "OrgSettings">>();
  const [selectedTab, setSelectedTab] = useState<OrgSettingsTabKey>(
    routeParams?.initialTab ?? "organizationProfile"
  );
  const [headerElements, setHeaderElements] = useState<React.JSX.Element>(<></>);
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const { DefaultLayout } = useFlaggedLayout();
  const { isOrgAdmin } = useLoggedInUserRoles();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
  });

  type Tab = {
    readonly key: OrgSettingsTabKey;
    readonly label: string;
    readonly disabled?: boolean;
  };

  const tabs: readonly Tab[] = useMemo(() => {
    const defaultTabs: readonly Tab[] = [
      {
        disabled: !isOrgAdmin,
        key: "organizationProfile",
        label: "Profile",
      },
      { disabled: !isOrgAdmin, key: "adminManagement", label: "Admins" },
      {
        disabled: !isOrgAdmin,
        key: "connectedOrganizations",
        label: "Connected organizations",
      },
    ];

    if (showLinkedAppsFeature) {
      return [
        ...defaultTabs,
        { disabled: !isOrgAdmin, key: "linkedApps", label: "Linked apps" },
        { disabled: !isOrgAdmin, key: "myApps", label: "My apps" },
      ];
    }

    return defaultTabs;
  }, [isOrgAdmin, showLinkedAppsFeature]);

  // Load initial tab from route params
  useEffect(() => {
    const setInitialTab = async () => {
      if (routeParams !== undefined) {
        // Somehow routeParams is sometimes {} - this check is required to not crash in that case
        if ("initialTab" in routeParams && tabs.some(f => f.key === routeParams.initialTab)) {
          if (
            (routeParams.initialTab === "linkedApps" || routeParams.initialTab === "myApps") &&
            showLinkedAppsFeature
          ) {
            // Hack: Delays in setting Tab styles due to LD flag result in a 500 error screen
            await new Promise(resolve => setTimeout(resolve, 500));
            setSelectedTab(routeParams.initialTab);

            return;
          }

          setSelectedTab(routeParams.initialTab);
        }
      }
    };

    setInitialTab();
  }, [tabs, routeParams, showLinkedAppsFeature]);

  useEffect(() => {
    if (isFoliosLoaded && !userAcceptedAccountFolio) {
      setSelectedTab(routeParams?.initialTab ?? "connectedOrganizations");
    }
  }, [isFoliosLoaded, routeParams, userAcceptedAccountFolio]);

  useFocusEffect(
    useCallback(() => {
      switch (selectedTab) {
        case "adminManagement":
          const adminManagementElements = (
            <>
              <HorizontalSpacer />
              <View style={{ width: 116 }}>
                <Button
                  onPress={() => {
                    setShowAddAdminModal(true);
                  }}
                  size="small"
                  testProps={{
                    elementName: "addAdminButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="New admin"
                />
              </View>
            </>
          );
          setHeaderElements(adminManagementElements);
          break;

        case "connectedOrganizations":
          const connectedOrganizationsElements = (
            <>
              <SearchForm
                onClear={() => {
                  setSearchQuery(undefined);
                }}
                onSearch={value => {
                  setSearchQuery(value);
                }}
              />
              <HorizontalSpacer size={140} />
            </>
          );

          setHeaderElements(connectedOrganizationsElements);
          break;

        case "myApps":
          const linkedAppsElements = (
            <>
              {Boolean(allowOrgPortalAgentsCreate) && (
                <>
                  <HorizontalSpacer />
                  <View style={{ width: 116 }}>
                    <Button
                      onPress={() => {
                        navigation.navigate("CreateApp");
                      }}
                      size="small"
                      testProps={{ elementName: "createAppButton", screenName: SCREEN_NAME }}
                      text="Create app"
                    />
                  </View>
                </>
              )}
            </>
          );
          setHeaderElements(linkedAppsElements);
          break;

        default:
          setHeaderElements(<></>);
          break;
      }

      return () => {
        setHeaderElements(<></>);
        setSearchQuery(undefined);
      };
    }, [allowOrgPortalAgentsCreate, navigation, selectedTab])
  );

  return (
    <DefaultLayout
      breadcrumbs={[{ name: "Organization Settings" }]}
      headerRightElements={headerElements}
      tabs={
        <Tabs
          items={tabs}
          onChange={tab => {
            navigation.setParams({ initialTab: tab });
            setSelectedTab(tab);
          }}
          selected={selectedTab}
          testProps={{
            elementName: "orgSettings",
            screenName: SCREEN_NAME,
          }}
        />
      }
      testProps={{
        elementName: "orgSettings",
        screenName: SCREEN_NAME,
      }}
      title="Settings"
    >
      <View style={styles.container}>
        {selectedTab === "organizationProfile" && <OrganizationProfile />}

        {selectedTab === "adminManagement" && <AdminManagement />}

        {selectedTab === "linkedApps" && <LinkedApps />}
        {selectedTab === "myApps" && <MyApps />}

        {selectedTab === "connectedOrganizations" && (
          <ConnectedOrganizations searchQuery={searchQuery} />
        )}
      </View>

      {showAddAdminModal && (
        <Modal
          onClose={() => {
            setShowAddAdminModal(false);
          }}
          testProps={{
            elementName: "addAdmin",
            screenName: SCREEN_NAME,
          }}
          title="New admin"
        >
          <AdminForm
            onClose={() => {
              setShowAddAdminModal(false);
            }}
            testProps={{
              elementName: "addAdmin",
              screenName: SCREEN_NAME,
            }}
          />
        </Modal>
      )}
    </DefaultLayout>
  );
};
