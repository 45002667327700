/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Auth0Options } from './Auth0Options';
import {
    Auth0OptionsFromJSON,
    Auth0OptionsFromJSONTyped,
    Auth0OptionsToJSON,
} from './Auth0Options';
import type { CapabilityWithRestriction } from './CapabilityWithRestriction';
import {
    CapabilityWithRestrictionFromJSON,
    CapabilityWithRestrictionFromJSONTyped,
    CapabilityWithRestrictionToJSON,
} from './CapabilityWithRestriction';

/**
 * The properties provided when updating an agent
 * @export
 * @interface AgentUpdateProperties
 */
export interface AgentUpdateProperties {
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    status?: AgentUpdatePropertiesStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    description?: string;
    /**
     * Optional additional properties for this agent
     * @type {{ [key: string]: string; }}
     * @memberof AgentUpdateProperties
     */
    additionals?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    name?: string;
    /**
     * 
     * @type {Auth0Options}
     * @memberof AgentUpdateProperties
     */
    auth0Options?: Auth0Options;
    /**
     * The agent's capabilities. Each capability allows an agent to take a certain action on a class of objects
     * on stellar. Entities that wish to use the agent must grant the agent permission to use its capabilities
     * on objects belonging to the entities.
     * 
     * @type {Array<CapabilityWithRestriction>}
     * @memberof AgentUpdateProperties
     */
    capabilities?: Array<CapabilityWithRestriction>;
    /**
     * Contact information for the agent/app developer
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    developerContactEmail?: string;
    /**
     * How the agent handles an entity's privacy
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    privacyTos?: string;
    /**
     * Description of acceptable use of the agent
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    usageTos?: string;
    /**
     * How the agent handles an entity's data from Stellar
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    dataTos?: string;
    /**
     * URI to which the user is re-directed after a successful login. Must be https.
     * @type {string}
     * @memberof AgentUpdateProperties
     */
    loginURI?: string;
}


/**
 * @export
 */
export const AgentUpdatePropertiesStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type AgentUpdatePropertiesStatusEnum = typeof AgentUpdatePropertiesStatusEnum[keyof typeof AgentUpdatePropertiesStatusEnum];


/**
 * Check if a given object implements the AgentUpdateProperties interface.
 */
export function instanceOfAgentUpdateProperties(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgentUpdatePropertiesFromJSON(json: any): AgentUpdateProperties {
    return AgentUpdatePropertiesFromJSONTyped(json, false);
}

export function AgentUpdatePropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentUpdateProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'additionals': !exists(json, 'additionals') ? undefined : json['additionals'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'auth0Options': !exists(json, 'auth0Options') ? undefined : Auth0OptionsFromJSON(json['auth0Options']),
        'capabilities': !exists(json, 'capabilities') ? undefined : ((json['capabilities'] as Array<any>).map(CapabilityWithRestrictionFromJSON)),
        'developerContactEmail': !exists(json, 'developerContactEmail') ? undefined : json['developerContactEmail'],
        'privacyTos': !exists(json, 'privacyTos') ? undefined : json['privacyTos'],
        'usageTos': !exists(json, 'usageTos') ? undefined : json['usageTos'],
        'dataTos': !exists(json, 'dataTos') ? undefined : json['dataTos'],
        'loginURI': !exists(json, 'loginURI') ? undefined : json['loginURI'],
    };
}

export function AgentUpdatePropertiesToJSON(value?: AgentUpdateProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'description': value.description,
        'additionals': value.additionals,
        'name': value.name,
        'auth0Options': Auth0OptionsToJSON(value.auth0Options),
        'capabilities': value.capabilities === undefined ? undefined : ((value.capabilities as Array<any>).map(CapabilityWithRestrictionToJSON)),
        'developerContactEmail': value.developerContactEmail,
        'privacyTos': value.privacyTos,
        'usageTos': value.usageTos,
        'dataTos': value.dataTos,
        'loginURI': value.loginURI,
    };
}

