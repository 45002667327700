// Copyright 2024 Merit International Inc. All Rights Reserved

import { LegacyLoginSuccess } from "./LegacyLoginSuccess";
import { OrgAuthLoginSuccess } from "./OrgAuthLoginSuccess";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { LDFeatureFlags } from "../configuration/featureFlags";

export const LoginSuccess = () => {
  const { rolloutAdminPortalAuth0OrgAuth } = useFlags<LDFeatureFlags>();

  if (!rolloutAdminPortalAuth0OrgAuth) {
    return LegacyLoginSuccess();
  }

  return OrgAuthLoginSuccess();
};
