/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTemplateRules200ResponseRulesBoolFieldHasValue } from './GetTemplateRules200ResponseRulesBoolFieldHasValue';
import {
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON,
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSONTyped,
    GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON,
} from './GetTemplateRules200ResponseRulesBoolFieldHasValue';

/**
 * 
 * @export
 * @interface GetTemplateRules200ResponseRulesEmail
 */
export interface GetTemplateRules200ResponseRulesEmail {
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesEmail
     */
    isEmailDomain?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesEmail
     */
    fieldHasValue?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
}

/**
 * Check if a given object implements the GetTemplateRules200ResponseRulesEmail interface.
 */
export function instanceOfGetTemplateRules200ResponseRulesEmail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseRulesEmailFromJSON(json: any): GetTemplateRules200ResponseRulesEmail {
    return GetTemplateRules200ResponseRulesEmailFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseRulesEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200ResponseRulesEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEmailDomain': !exists(json, 'IsEmailDomain') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['IsEmailDomain']),
        'fieldHasValue': !exists(json, 'FieldHasValue') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['FieldHasValue']),
    };
}

export function GetTemplateRules200ResponseRulesEmailToJSON(value?: GetTemplateRules200ResponseRulesEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IsEmailDomain': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.isEmailDomain),
        'FieldHasValue': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.fieldHasValue),
    };
}

