/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResponseCursor } from './PaginationResponseCursor';
import {
    PaginationResponseCursorFromJSON,
    PaginationResponseCursorFromJSONTyped,
    PaginationResponseCursorToJSON,
} from './PaginationResponseCursor';
import type { PolicyRequest } from './PolicyRequest';
import {
    PolicyRequestFromJSON,
    PolicyRequestFromJSONTyped,
    PolicyRequestToJSON,
} from './PolicyRequest';

/**
 * An object that contains a list of Policy Requests.
 * @export
 * @interface PolicyRequestsResponse
 */
export interface PolicyRequestsResponse {
    /**
     * Indicates whether more data is available after incrementing the offset
     * @type {boolean}
     * @memberof PolicyRequestsResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {PaginationResponseCursor}
     * @memberof PolicyRequestsResponse
     */
    cursor?: PaginationResponseCursor;
    /**
     * The list of policy requests available.
     * @type {Array<PolicyRequest>}
     * @memberof PolicyRequestsResponse
     */
    policyRequests?: Array<PolicyRequest>;
}

/**
 * Check if a given object implements the PolicyRequestsResponse interface.
 */
export function instanceOfPolicyRequestsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolicyRequestsResponseFromJSON(json: any): PolicyRequestsResponse {
    return PolicyRequestsResponseFromJSONTyped(json, false);
}

export function PolicyRequestsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyRequestsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !exists(json, 'cursor') ? undefined : PaginationResponseCursorFromJSON(json['cursor']),
        'policyRequests': !exists(json, 'policyRequests') ? undefined : ((json['policyRequests'] as Array<any>).map(PolicyRequestFromJSON)),
    };
}

export function PolicyRequestsResponseToJSON(value?: PolicyRequestsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasMore': value.hasMore,
        'cursor': PaginationResponseCursorToJSON(value.cursor),
        'policyRequests': value.policyRequests === undefined ? undefined : ((value.policyRequests as Array<any>).map(PolicyRequestToJSON)),
    };
}

