/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListDatasources200ResponseDatasourcesInnerJoin
 */
export interface ListDatasources200ResponseDatasourcesInnerJoin {
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerJoin
     */
    sourceDataSourceID?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerJoin
     */
    targetDataSourceID?: string;
}

/**
 * Check if a given object implements the ListDatasources200ResponseDatasourcesInnerJoin interface.
 */
export function instanceOfListDatasources200ResponseDatasourcesInnerJoin(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListDatasources200ResponseDatasourcesInnerJoinFromJSON(json: any): ListDatasources200ResponseDatasourcesInnerJoin {
    return ListDatasources200ResponseDatasourcesInnerJoinFromJSONTyped(json, false);
}

export function ListDatasources200ResponseDatasourcesInnerJoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDatasources200ResponseDatasourcesInnerJoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceDataSourceID': !exists(json, 'sourceDataSourceID') ? undefined : json['sourceDataSourceID'],
        'targetDataSourceID': !exists(json, 'targetDataSourceID') ? undefined : json['targetDataSourceID'],
    };
}

export function ListDatasources200ResponseDatasourcesInnerJoinToJSON(value?: ListDatasources200ResponseDatasourcesInnerJoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceDataSourceID': value.sourceDataSourceID,
        'targetDataSourceID': value.targetDataSourceID,
    };
}

