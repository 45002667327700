// Copyright 2024 Merit International Inc. All Rights Reserved

import { View } from "react-native";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";

const SCREEN_NAME = "NoRoles";

export const NoRolesScreen = () => {
  const { DefaultLayout } = useFlaggedLayout();

  // intentionally blank, this screen is really a glorified proxy to NewDefaultLayout's checking of a user's
  // roles, and if there are no recognized roles, it shows the NoAccess component instead of the actual children
  return (
    <DefaultLayout
      testProps={{
        elementName: "noroles",
        screenName: SCREEN_NAME,
      }}
      title="No Roles"
    >
      <View />
    </DefaultLayout>
  );
};
