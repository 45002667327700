import { Body, useTheme } from "@merit/frontend-components";
import { EllipsisText } from "../EllipsisText/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { StyleSheet, View } from "react-native";
import { getColumnWidthStyle } from "./utils";
import React from "react";
import type { DatagridColumn } from "./types";
import type { OPTestProps } from "../../../src/types/TestProps";

const Some = Helpers.Some;

type ObjectWithError = object & {
  readonly hasError?: boolean;
};

export type DatagridBodyProps<T extends ObjectWithError> = {
  readonly data: readonly T[];
  readonly columns: readonly DatagridColumn<T>[];
  readonly noDataText?: string;
  readonly testProps?: OPTestProps;
  readonly testPropsElementIdKey?: keyof T;
  readonly getItemKey?: (item: T) => string;
};

export function DatagridBody<T extends ObjectWithError>({
  columns,
  data,
  getItemKey,
  noDataText,
  testProps,
  testPropsElementIdKey,
}: DatagridBodyProps<T>) {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    cell: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: theme.spacing.l,
    },
    row: {
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: theme.spacing.l,
    },
    rowWithError: {
      backgroundColor: "rgba(255, 237, 239, 1)", // theme.colors.action.critical.default is wrong in theme
    },
  });

  const itemKeyFunc = getItemKey === undefined ? (item: T) => JSON.stringify(item) : getItemKey;
  if (data.length === 0) {
    return (
      <View style={{ alignItems: "center", flex: 1, justifyContent: "center" }}>
        <Body
          testProps={
            Some(testProps)
              ? {
                  elementName: `${testProps.elementName}NoDataPlaceholder`,
                  screenName: testProps.screenName,
                }
              : testProps
          }
        >
          {noDataText ?? "No data available"}
        </Body>
      </View>
    );
  }

  return (
    <>
      {data.map(item => (
        <View
          key={itemKeyFunc(item)}
          style={[styles.row, item.hasError === true && styles.rowWithError]}
          {...(Some(testProps)
            ? Helpers.generateTestIdProps({
                ...testProps,
                ...(Some(testPropsElementIdKey) && {
                  elementId: item[testPropsElementIdKey] as string,
                }),
                elementName: `${testProps.elementName}Row`,
              })
            : testProps)}
        >
          {columns.map(column => (
            <View key={String(column.key)} style={[styles.cell, getColumnWidthStyle(column.size)]}>
              {column.renderer === undefined ? (
                <EllipsisText
                  capitalize={column.capitalize}
                  testProps={
                    Some(testProps)
                      ? {
                          ...testProps,
                          ...(Some(testPropsElementIdKey) && {
                            elementId: item[testPropsElementIdKey] as string,
                          }),
                          elementName: `${String(column.key)}${String(
                            testProps.elementName
                          )}RowItem`,
                        }
                      : testProps
                  }
                  text={item[column.key as keyof T] as string}
                />
              ) : (
                column.renderer(
                  item,
                  Some(testProps)
                    ? {
                        ...testProps,
                        ...(Some(testPropsElementIdKey) && {
                          elementId: item[testPropsElementIdKey] as string,
                        }),
                        elementName: `${String(column.key)}${testProps.elementName}RowItem`,
                      }
                    : testProps
                )
              )}
            </View>
          ))}
        </View>
      ))}
    </>
  );
}
