import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody, EllipsisText, Spin, VerticalSpacer } from "@src/components";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "@src/screens/Templates/Templates";
import { ScrollView, View } from "react-native";
import { TemplatesFilter } from "@src/screens/Templates/TemplatesFilter";
import { useAlertStore } from "@src/stores";
import { useApi } from "@src/api/api";
import { useEffect } from "react";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useTemplateDataSearch } from "./useTemplateDataSearch";
import { v4 as uuidv4 } from "uuid";
import lowerCase from "lodash.lowercase";
import type { DatagridColumn } from "@src/components/Datagrid/types";
import type { Search200ResponseTemplatesInner } from "@src/gen/org-portal";

type Props = {
  readonly setTemplateID: (id: string) => void;
  readonly searchQuery: string | undefined;
  readonly refresh: boolean;
  readonly setRefresh: (value: boolean) => void;
};

const { Some } = Helpers;

export const TemplatesDatagridSearch = ({
  refresh,
  searchQuery,
  setRefresh,
  setTemplateID,
}: Props) => {
  const { theme } = useTheme();
  const auth = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();

  const { data, loading, searchTemplates } = useTemplateDataSearch(api);

  useEffect(() => {
    if (Some(searchQuery)) {
      searchTemplates({
        orgID: auth.selectedOrgId,
        query: { objectName: searchQuery },
      });
    }
  }, [auth.selectedOrgId, searchTemplates, searchQuery]);

  useEffect(() => {
    if (refresh) {
      searchTemplates({
        orgID: auth.selectedOrgId,
        query: { objectName: searchQuery },
      });
      setRefresh(false);
    }
  }, [auth.selectedOrgId, refresh, searchQuery, searchTemplates, setRefresh]);

  useEffect(() => {
    if (data.length === 500) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `More than 500 records found, please refine your search.`,
        type: "warning",
      });
    }
  }, [data, deleteAlert, setAlert]);

  const columns: readonly DatagridColumn<Search200ResponseTemplatesInner>[] = [
    { key: "name", label: "Name", size: "flex" },
    { key: "type", label: "Type", size: 128 },
    { key: "id", label: "Template ID", size: 360 },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }) => (
        <EllipsisText text={Some(description) && description.length > 0 ? description : "--"} />
      ),
      size: "flex",
    },
    {
      key: "status",
      label: "State",
      renderer: item => (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <View
            style={{
              backgroundColor:
                lowerCase(item.status) === "live"
                  ? theme.colors.icon.alert.success
                  : theme.colors.icon.disabled,
              borderRadius: theme.borderRadii.m,
              height: 8,
              marginRight: theme.spacing.m,
              width: 8,
            }}
          />
          <Body capitalize>{item.status ?? "--"}</Body>
        </View>
      ),
      size: 128,
    },
    { key: "approval", label: "Approval", size: 128 },
    {
      key: "actions",
      label: "Actions",
      renderer: item => (
        <Button
          onPress={() => {
            setTemplateID(item.id);
          }}
          size="small"
          testProps={{
            elementId: item.id,
            elementName: "templatesListViewRowItemViewButton",
            screenName: SCREEN_NAME,
          }}
          text="View"
          type="secondary"
        />
      ),

      size: 128,
    },
  ];

  return (
    <>
      <TemplatesFilter
        onSelect={values => {
          searchTemplates({
            orgID: auth.selectedOrgId,
            query: { objectName: searchQuery },
            templateType: values?.type,
          });
        }}
        showStatus={false}
        typePlaceholder="All"
      />
      <VerticalSpacer size={theme.spacing.xxl} />
      {loading ? (
        <View style={{ flex: 1 }}>
          <Spin />
        </View>
      ) : (
        <ScrollView>
          <View style={{ paddingLeft: 32 }}>
            <Heading level="4">{`Results (${data.length})`}</Heading>
          </View>
          <Datagrid
            columns={columns}
            loading={loading}
            testProps={{ elementName: "ownTemplatesListView", screenName: SCREEN_NAME }}
          >
            <DatagridBody
              columns={columns}
              data={data}
              testProps={{
                elementName: "ownTemplatesListView",
                screenName: SCREEN_NAME,
              }}
              testPropsElementIdKey="id"
            />
          </Datagrid>
        </ScrollView>
      )}
    </>
  );
};
