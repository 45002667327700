/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Capability } from './Capability';
import {
    CapabilityFromJSON,
    CapabilityFromJSONTyped,
    CapabilityToJSON,
} from './Capability';

/**
 * Metadata on an agent
 * @export
 * @interface AgentVersion
 */
export interface AgentVersion {
    /**
     * Unique identifier for the agent version
     * @type {string}
     * @memberof AgentVersion
     */
    id: string;
    /**
     * The agent's name
     * @type {string}
     * @memberof AgentVersion
     */
    name: string;
    /**
     * A description of what the agent does
     * @type {string}
     * @memberof AgentVersion
     */
    description: string;
    /**
     * How the agent uses the user's data
     * @type {string}
     * @memberof AgentVersion
     */
    dataTos: string;
    /**
     * How the agent protects a user's privacy
     * @type {string}
     * @memberof AgentVersion
     */
    privacyTos: string;
    /**
     * The user's agreement for using the agent
     * @type {string}
     * @memberof AgentVersion
     */
    usageTos: string;
    /**
     * Determines whether this version is approved for use by entities
     * @type {boolean}
     * @memberof AgentVersion
     */
    approved?: boolean;
    /**
     * A monotonically increasing counter indicating which agent versions are newer/older
     * @type {number}
     * @memberof AgentVersion
     */
    version: number;
    /**
     * 
     * @type {Array<Capability>}
     * @memberof AgentVersion
     */
    capabilities?: Array<Capability>;
    /**
     * Contact information for the agent/app developer
     * @type {string}
     * @memberof AgentVersion
     */
    developerContactEmail: string;
    /**
     * The time the version was created
     * @type {Date}
     * @memberof AgentVersion
     */
    createdAt?: Date;
    /**
     * The time the version was created or approved
     * @type {Date}
     * @memberof AgentVersion
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the AgentVersion interface.
 */
export function instanceOfAgentVersion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "dataTos" in value;
    isInstance = isInstance && "privacyTos" in value;
    isInstance = isInstance && "usageTos" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "developerContactEmail" in value;

    return isInstance;
}

export function AgentVersionFromJSON(json: any): AgentVersion {
    return AgentVersionFromJSONTyped(json, false);
}

export function AgentVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'dataTos': json['dataTos'],
        'privacyTos': json['privacyTos'],
        'usageTos': json['usageTos'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'version': json['version'],
        'capabilities': !exists(json, 'capabilities') ? undefined : ((json['capabilities'] as Array<any>).map(CapabilityFromJSON)),
        'developerContactEmail': json['developerContactEmail'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function AgentVersionToJSON(value?: AgentVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'dataTos': value.dataTos,
        'privacyTos': value.privacyTos,
        'usageTos': value.usageTos,
        'approved': value.approved,
        'version': value.version,
        'capabilities': value.capabilities === undefined ? undefined : ((value.capabilities as Array<any>).map(CapabilityToJSON)),
        'developerContactEmail': value.developerContactEmail,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

