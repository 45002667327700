/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListDatasources200ResponseDatasourcesInnerColumnsInner } from './ListDatasources200ResponseDatasourcesInnerColumnsInner';
import {
    ListDatasources200ResponseDatasourcesInnerColumnsInnerFromJSON,
    ListDatasources200ResponseDatasourcesInnerColumnsInnerFromJSONTyped,
    ListDatasources200ResponseDatasourcesInnerColumnsInnerToJSON,
} from './ListDatasources200ResponseDatasourcesInnerColumnsInner';
import type { ListDatasources200ResponseDatasourcesInnerJoin } from './ListDatasources200ResponseDatasourcesInnerJoin';
import {
    ListDatasources200ResponseDatasourcesInnerJoinFromJSON,
    ListDatasources200ResponseDatasourcesInnerJoinFromJSONTyped,
    ListDatasources200ResponseDatasourcesInnerJoinToJSON,
} from './ListDatasources200ResponseDatasourcesInnerJoin';
import type { ListDatasources200ResponseDatasourcesInnerSchema } from './ListDatasources200ResponseDatasourcesInnerSchema';
import {
    ListDatasources200ResponseDatasourcesInnerSchemaFromJSON,
    ListDatasources200ResponseDatasourcesInnerSchemaFromJSONTyped,
    ListDatasources200ResponseDatasourcesInnerSchemaToJSON,
} from './ListDatasources200ResponseDatasourcesInnerSchema';

/**
 * 
 * @export
 * @interface ListDatasources200ResponseDatasourcesInner
 */
export interface ListDatasources200ResponseDatasourcesInner {
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    description?: string;
    /**
     * 
     * @type {Array<ListDatasources200ResponseDatasourcesInnerColumnsInner>}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    columns: Array<ListDatasources200ResponseDatasourcesInnerColumnsInner>;
    /**
     * 
     * @type {ListDatasources200ResponseDatasourcesInnerJoin}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    join?: ListDatasources200ResponseDatasourcesInnerJoin;
    /**
     * 
     * @type {ListDatasources200ResponseDatasourcesInnerSchema}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    schema?: ListDatasources200ResponseDatasourcesInnerSchema;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    columnUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    lastIngestion?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    lastError?: string;
    /**
     * The type of data this data source expects to ingest. Three options exist for file uploads,
     * comma-separated values (csv), tab separated values (tsv) and pipe (|) separate values (psv).
     * Data sources may also ingest data from an integration, in which case the type is simply
     * integration.
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    dataSourceType?: ListDatasources200ResponseDatasourcesInnerDataSourceTypeEnum | null;
    /**
     * The status of the integration this data source is connected to. This field is empty if the data source
     * is not mapped to an integration. A paused integration is connected but not currently ingesting data; a
     * queued integration is scheduled to ingest data; an ingesting integration is currently ingesting data
     * into the data source; and a not connected integration is not ingesting data, possibly due to an error.
     * An unknown status may occur briefly appear mapping a data source to an integration.
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    integrationConnectionStatus?: ListDatasources200ResponseDatasourcesInnerIntegrationConnectionStatusEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListDatasources200ResponseDatasourcesInner
     */
    isHidden?: boolean;
}


/**
 * @export
 */
export const ListDatasources200ResponseDatasourcesInnerDataSourceTypeEnum = {
    Csv: 'csv',
    Tsv: 'tsv',
    Psv: 'psv',
    Integration: 'integration'
} as const;
export type ListDatasources200ResponseDatasourcesInnerDataSourceTypeEnum = typeof ListDatasources200ResponseDatasourcesInnerDataSourceTypeEnum[keyof typeof ListDatasources200ResponseDatasourcesInnerDataSourceTypeEnum];

/**
 * @export
 */
export const ListDatasources200ResponseDatasourcesInnerIntegrationConnectionStatusEnum = {
    ConnectedPaused: 'connected (paused)',
    ConnectedQueued: 'connected (queued)',
    ConnectedIngesting: 'connected (ingesting)',
    NotConnected: 'not connected',
    Unknown: 'unknown'
} as const;
export type ListDatasources200ResponseDatasourcesInnerIntegrationConnectionStatusEnum = typeof ListDatasources200ResponseDatasourcesInnerIntegrationConnectionStatusEnum[keyof typeof ListDatasources200ResponseDatasourcesInnerIntegrationConnectionStatusEnum];


/**
 * Check if a given object implements the ListDatasources200ResponseDatasourcesInner interface.
 */
export function instanceOfListDatasources200ResponseDatasourcesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "columns" in value;

    return isInstance;
}

export function ListDatasources200ResponseDatasourcesInnerFromJSON(json: any): ListDatasources200ResponseDatasourcesInner {
    return ListDatasources200ResponseDatasourcesInnerFromJSONTyped(json, false);
}

export function ListDatasources200ResponseDatasourcesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDatasources200ResponseDatasourcesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'columns': ((json['columns'] as Array<any>).map(ListDatasources200ResponseDatasourcesInnerColumnsInnerFromJSON)),
        'join': !exists(json, 'join') ? undefined : ListDatasources200ResponseDatasourcesInnerJoinFromJSON(json['join']),
        'schema': !exists(json, 'schema') ? undefined : ListDatasources200ResponseDatasourcesInnerSchemaFromJSON(json['schema']),
        'columnUpdatedAt': !exists(json, 'columnUpdatedAt') ? undefined : json['columnUpdatedAt'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'lastIngestion': !exists(json, 'lastIngestion') ? undefined : json['lastIngestion'],
        'lastError': !exists(json, 'lastError') ? undefined : json['lastError'],
        'dataSourceType': !exists(json, 'dataSourceType') ? undefined : json['dataSourceType'],
        'integrationConnectionStatus': !exists(json, 'integrationConnectionStatus') ? undefined : json['integrationConnectionStatus'],
        'isHidden': !exists(json, 'isHidden') ? undefined : json['isHidden'],
    };
}

export function ListDatasources200ResponseDatasourcesInnerToJSON(value?: ListDatasources200ResponseDatasourcesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'columns': ((value.columns as Array<any>).map(ListDatasources200ResponseDatasourcesInnerColumnsInnerToJSON)),
        'join': ListDatasources200ResponseDatasourcesInnerJoinToJSON(value.join),
        'schema': ListDatasources200ResponseDatasourcesInnerSchemaToJSON(value.schema),
        'columnUpdatedAt': value.columnUpdatedAt,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt,
        'lastIngestion': value.lastIngestion,
        'lastError': value.lastError,
        'dataSourceType': value.dataSourceType,
        'integrationConnectionStatus': value.integrationConnectionStatus,
        'isHidden': value.isHidden,
    };
}

