// Copyright 2024 Merit International Inc. All Rights Reserved.

import { useAlertStore } from "@src/stores";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import type { AlertProps } from "@merit/frontend-components";
import type { OPTestProps } from "@src/types/TestProps";

type Props = {
  readonly text: string;
  readonly testProps?: OPTestProps;
  readonly type?: AlertProps["type"];
};

export const useDisplayAlert = () => {
  const { deleteAlert, setAlert } = useAlertStore();

  const displayAlert = useCallback(
    ({ testProps, text, type = "success" }: Props) => {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps,
        text,
        type,
      });
    },
    [deleteAlert, setAlert]
  );

  return {
    displayAlert,
  };
};
