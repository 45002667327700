// Copyright 2024 Merit International Inc. All Rights Reserved

import { useCallback, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type { DefaultApi, Search200Response, SearchOperationRequest } from "../../gen/org-portal";

type SearchTemplatesArgs = Pick<SearchOperationRequest, "orgID"> & {
  readonly query: Pick<SearchOperationRequest["query"], "objectName">;
} & { readonly templateType?: string };

export const useTemplateDataSearch = (api: DefaultApi) => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Search200Response["templates"]>();
  const { errorHandler } = useServerErrorHandler();

  const data = templates ?? [];

  const searchTemplates = useCallback(
    async ({ orgID, query, templateType }: SearchTemplatesArgs) => {
      try {
        if (orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setLoading(true);

        const searchResponse = await api.search({
          orgID,
          query: {
            objectName: query.objectName,
            objectType: "template",
          },
        });

        if (templateType === undefined) {
          setTemplates(searchResponse.templates);
        } else {
          setTemplates(
            searchResponse.templates?.filter(template => template.type === templateType)
          );
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
    [api, errorHandler]
  );

  return {
    data,
    loading,
    searchTemplates,
  };
};
