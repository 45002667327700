import { Body, TextInput } from "@merit/frontend-components";
import { DateTimePicker } from "../../components/DateTimePicker/DateTimePicker";
import { DurationInput } from "./DurationInput";
import { Helpers } from "@merit/frontend-utils";
import { StyleSheet, View } from "react-native";
import dayjs from "dayjs";
import type { OPTestProps } from "../../../src/types/TestProps";
import type { GetTemplateRules200ResponseRules as PredicateMap } from "../../gen/org-portal";

const { None, Some } = Helpers;

export type Predicate =
  | keyof NonNullable<PredicateMap["bool"]>
  | keyof NonNullable<PredicateMap["date"]>
  | keyof NonNullable<PredicateMap["dateTime"]>
  | keyof NonNullable<PredicateMap["email"]>
  | keyof NonNullable<PredicateMap["markdown"]>
  | keyof NonNullable<PredicateMap["number"]>
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  | keyof NonNullable<PredicateMap["phoneNumber"]>
  | keyof NonNullable<PredicateMap["text"]>;

export type Props = {
  readonly handleChange: (args?: readonly string[]) => void;
  readonly predicate: Predicate;
  readonly value?: readonly string[];
  readonly testProps?: OPTestProps;
};

export const RulesArgumentInput = ({ handleChange, predicate, testProps, value }: Props) => {
  const styles = StyleSheet.create({
    wrapper: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  });

  switch (predicate) {
    case "fieldHasValue":
    case "beforeToday":
      return <></>;

    case "matchesThisString":
    case "equalToX":
    case "moreThanX":
    case "lessThanX":
      return (
        <View style={styles.wrapper}>
          <TextInput
            onChangeText={v => {
              handleChange([v]);
            }}
            placeholder="input"
            testProps={testProps}
            value={Some(value) ? value[0] : ""}
          />
        </View>
      );
    case "beforeTodayMinusXDays":
      return (
        <>
          <View style={styles.wrapper}>
            <TextInput
              onChangeText={v => {
                handleChange([v]);
              }}
              placeholder="days"
              testProps={testProps}
              value={Some(value) ? value[0] : ""}
            />
          </View>
          <Body style={styles.wrapper}>(of days)</Body>
        </>
      );
    case "beforeNowMinusXDuration":
      return (
        <View style={styles.wrapper}>
          <DurationInput
            onChange={duration => {
              handleChange([duration]);
            }}
            value={Some(value) && value.length > 0 ? value[0] : ""}
          />
        </View>
      );

    case "isEmailDomain":
      return (
        <>
          <Body style={styles.wrapper}>@</Body>
          <View style={styles.wrapper}>
            <TextInput
              onChangeText={v => {
                handleChange([v]);
              }}
              placeholder="domain name"
              testProps={testProps}
              value={Some(value) ? value[0] : ""}
            />
          </View>
        </>
      );

    case "afterThisDate":
    case "beforeThisDate":
    case "equalToDate":
      return (
        <View style={styles.wrapper}>
          <DateTimePicker
            defaultValue={Some(value) ? value[0] : undefined}
            onChange={(date: string | undefined): void => {
              if (None(date)) {
                return;
              }
              handleChange([date].concat(value?.slice(1) ?? []));
            }}
            testProps={testProps}
            type="date"
          />
        </View>
      );

    case "afterThisTimeOfDay":
    case "beforeThisTimeOfDay":
      return (
        <View style={styles.wrapper}>
          <DateTimePicker
            defaultValue={Some(value) && value.length === 3 ? `${value[0]}:${value[1]}` : undefined}
            onChange={(date: string | undefined): void => {
              if (None(date)) {
                return;
              }
              handleChange(date.split(":").concat("0"));
            }}
            testProps={testProps}
            type="time"
          />
        </View>
      );

    case "beforeThisDatetime":
    case "afterThisDatetime":
      return (
        <View style={styles.wrapper}>
          <DateTimePicker
            defaultValue={Some(value) ? dayjs(value[0]).format("YYYY-MM-DDTHH:mm") : undefined}
            onChange={(date: string | undefined): void => {
              if (None(date)) {
                return;
              }
              handleChange([dayjs(date).toISOString()].concat(value?.slice(1) ?? []));
            }}
            testProps={testProps}
            type="datetime-local"
          />
        </View>
      );
    default:
      return <></>;
  }
};
