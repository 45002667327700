/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';
import type { Formula } from './Formula';
import {
    FormulaFromJSON,
    FormulaFromJSONTyped,
    FormulaToJSON,
} from './Formula';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * The properties provided when updating a policy
 * @export
 * @interface PolicyUpdate
 */
export interface PolicyUpdate {
    /**
     * The given name of this Policy.
     * @type {string}
     * @memberof PolicyUpdate
     */
    name?: string | null;
    /**
     * A description of this Policy.
     * @type {string}
     * @memberof PolicyUpdate
     */
    description?: string | null;
    /**
     * The message response when a Policy run returns false.
     * @type {string}
     * @memberof PolicyUpdate
     */
    falseMessage?: string | null;
    /**
     * The message response when a Policy run returns true.
     * @type {string}
     * @memberof PolicyUpdate
     */
    trueMessage?: string | null;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof PolicyUpdate
     */
    permissions?: Array<PermissionPair> | null;
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<Atom>}
     * @memberof PolicyUpdate
     */
    rule?: Array<Atom>;
    /**
     * 
     * @type {Formula}
     * @memberof PolicyUpdate
     */
    formula?: Formula;
    /**
     * The state of this policy, whether it's live or paused.
     * @type {string}
     * @memberof PolicyUpdate
     */
    state?: PolicyUpdateStateEnum | null;
}


/**
 * @export
 */
export const PolicyUpdateStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type PolicyUpdateStateEnum = typeof PolicyUpdateStateEnum[keyof typeof PolicyUpdateStateEnum];


/**
 * Check if a given object implements the PolicyUpdate interface.
 */
export function instanceOfPolicyUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolicyUpdateFromJSON(json: any): PolicyUpdate {
    return PolicyUpdateFromJSONTyped(json, false);
}

export function PolicyUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'falseMessage': !exists(json, 'falseMessage') ? undefined : json['falseMessage'],
        'trueMessage': !exists(json, 'trueMessage') ? undefined : json['trueMessage'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'rule': !exists(json, 'rule') ? undefined : ((json['rule'] as Array<any>).map(AtomFromJSON)),
        'formula': !exists(json, 'formula') ? undefined : FormulaFromJSON(json['formula']),
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function PolicyUpdateToJSON(value?: PolicyUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'falseMessage': value.falseMessage,
        'trueMessage': value.trueMessage,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(PermissionPairToJSON)),
        'rule': value.rule === undefined ? undefined : ((value.rule as Array<any>).map(AtomToJSON)),
        'formula': FormulaToJSON(value.formula),
        'state': value.state,
    };
}

