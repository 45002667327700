/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTemplateStatistics200Response
 */
export interface GetTemplateStatistics200Response {
    /**
     * 
     * @type {number}
     * @memberof GetTemplateStatistics200Response
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetTemplateStatistics200Response
     */
    unissued: number;
    /**
     * 
     * @type {number}
     * @memberof GetTemplateStatistics200Response
     */
    pending: number;
    /**
     * 
     * @type {number}
     * @memberof GetTemplateStatistics200Response
     */
    accepted: number;
    /**
     * 
     * @type {number}
     * @memberof GetTemplateStatistics200Response
     */
    rejected: number;
    /**
     * 
     * @type {number}
     * @memberof GetTemplateStatistics200Response
     */
    revoked: number;
}

/**
 * Check if a given object implements the GetTemplateStatistics200Response interface.
 */
export function instanceOfGetTemplateStatistics200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "unissued" in value;
    isInstance = isInstance && "pending" in value;
    isInstance = isInstance && "accepted" in value;
    isInstance = isInstance && "rejected" in value;
    isInstance = isInstance && "revoked" in value;

    return isInstance;
}

export function GetTemplateStatistics200ResponseFromJSON(json: any): GetTemplateStatistics200Response {
    return GetTemplateStatistics200ResponseFromJSONTyped(json, false);
}

export function GetTemplateStatistics200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateStatistics200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'unissued': json['unissued'],
        'pending': json['pending'],
        'accepted': json['accepted'],
        'rejected': json['rejected'],
        'revoked': json['revoked'],
    };
}

export function GetTemplateStatistics200ResponseToJSON(value?: GetTemplateStatistics200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'unissued': value.unissued,
        'pending': value.pending,
        'accepted': value.accepted,
        'rejected': value.rejected,
        'revoked': value.revoked,
    };
}

