/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Column DataType corresponding to Numeric data.
 * @export
 * @interface Number
 */
export interface Number extends DataType {
}

/**
 * Check if a given object implements the Number interface.
 */
export function instanceOfNumber(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NumberFromJSON(json: any): Number {
    return NumberFromJSONTyped(json, false);
}

export function NumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Number {
    return json;
}

export function NumberToJSON(value?: Number | null): any {
    return value;
}

