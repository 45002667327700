/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgentEntityLink,
  AgentList,
  Errors,
} from '../models/index';
import {
    AgentEntityLinkFromJSON,
    AgentEntityLinkToJSON,
    AgentListFromJSON,
    AgentListToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
} from '../models/index';

export interface GetAgentLinksRequest {
    agentID: string;
    authorization?: string;
}

export interface GetEntityLinksRequest {
    entityID: string;
    authorization?: string;
    endingBefore?: string;
    startingAfter?: string;
    limit?: number;
    sortBy?: GetEntityLinksSortByEnum;
}

/**
 * 
 */
export class AgentEntityLinksApi extends runtime.BaseAPI {

    /**
     * `read:agent_entity_link`  Get links to the agent for the entity specified in the Authorization header 
     * Get Agent Links
     */
    async getAgentLinksRaw(requestParameters: GetAgentLinksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgentEntityLink>> {
        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling getAgentLinks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/links/agent/{agentID}`.replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentEntityLinkFromJSON(jsonValue));
    }

    /**
     * `read:agent_entity_link`  Get links to the agent for the entity specified in the Authorization header 
     * Get Agent Links
     */
    async getAgentLinks(requestParameters: GetAgentLinksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgentEntityLink> {
        const response = await this.getAgentLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:agent_entity_link`  Get the agents this entity is linked to 
     * Get Entity Links
     */
    async getEntityLinksRaw(requestParameters: GetEntityLinksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgentList>> {
        if (requestParameters.entityID === null || requestParameters.entityID === undefined) {
            throw new runtime.RequiredError('entityID','Required parameter requestParameters.entityID was null or undefined when calling getEntityLinks.');
        }

        const queryParameters: any = {};

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/links/entity/{entityID}`.replace(`{${"entityID"}}`, encodeURIComponent(String(requestParameters.entityID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentListFromJSON(jsonValue));
    }

    /**
     * `read:agent_entity_link`  Get the agents this entity is linked to 
     * Get Entity Links
     */
    async getEntityLinks(requestParameters: GetEntityLinksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgentList> {
        const response = await this.getEntityLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetEntityLinksSortByEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type GetEntityLinksSortByEnum = typeof GetEntityLinksSortByEnum[keyof typeof GetEntityLinksSortByEnum];
