// Copyright 2024 Merit International Inc. All Rights Reserved

import { v4 as uuidv4 } from "uuid";
import type { FieldRule, TemplateRule } from "./types";

export const newFieldRule = (): FieldRule => ({
  id: uuidv4(),
});

export const newTemplateRule = (): TemplateRule => ({
  fieldCombinationType: "any",
  fieldRules: [],
  fieldValuesType: "any",
  id: uuidv4(),
  templateId: null,
  templateStatus: "any",
});
