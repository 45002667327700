// Copyright 2024 Merit International Inc. All Rights Reserved

import { LegacyOrgSelectScreen } from "@src/screens/OrgSelect/LegacyOrgSelect";
import { OrgAuthOrgSelect } from "@src/screens/OrgSelect/OrgAuthOrgSelect";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { LDFeatureFlags } from "../../configuration/featureFlags";

export const OrgSelectScreen = () => {
  const { rolloutAdminPortalAuth0OrgAuth } = useFlags<LDFeatureFlags>();

  if (!rolloutAdminPortalAuth0OrgAuth) {
    return LegacyOrgSelectScreen();
  }

  return OrgAuthOrgSelect();
};
