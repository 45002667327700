/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RegisterNewOrg201ResponseRegisteredOrg } from './RegisterNewOrg201ResponseRegisteredOrg';
import {
    RegisterNewOrg201ResponseRegisteredOrgFromJSON,
    RegisterNewOrg201ResponseRegisteredOrgFromJSONTyped,
    RegisterNewOrg201ResponseRegisteredOrgToJSON,
} from './RegisterNewOrg201ResponseRegisteredOrg';

/**
 * 
 * @export
 * @interface RegisterNewOrg201Response
 */
export interface RegisterNewOrg201Response {
    /**
     * 
     * @type {RegisterNewOrg201ResponseRegisteredOrg}
     * @memberof RegisterNewOrg201Response
     */
    registeredOrg: RegisterNewOrg201ResponseRegisteredOrg;
}

/**
 * Check if a given object implements the RegisterNewOrg201Response interface.
 */
export function instanceOfRegisterNewOrg201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "registeredOrg" in value;

    return isInstance;
}

export function RegisterNewOrg201ResponseFromJSON(json: any): RegisterNewOrg201Response {
    return RegisterNewOrg201ResponseFromJSONTyped(json, false);
}

export function RegisterNewOrg201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterNewOrg201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registeredOrg': RegisterNewOrg201ResponseRegisteredOrgFromJSON(json['registeredOrg']),
    };
}

export function RegisterNewOrg201ResponseToJSON(value?: RegisterNewOrg201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registeredOrg': RegisterNewOrg201ResponseRegisteredOrgToJSON(value.registeredOrg),
    };
}

