// Copyright 2024 Merit International Inc. All Rights Reserved

import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type {
  DefaultApi,
  ListTemplates200Response,
  ListTemplatesRequest,
} from "../../gen/org-portal";

export const useTemplatesData = (api: DefaultApi, requestParams: ListTemplatesRequest) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState(requestParams);
  const { errorHandler } = useServerErrorHandler();
  const [templates, setTemplates] = useState<ListTemplates200Response["templates"]>([]);

  const refresh = (nextParams: Partial<ListTemplatesRequest> = {}) => {
    if (isLoading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        if (params.orgID === "") {
          throw new Error("Couldn't find Organization ID");
        }
        setIsLoading(true);
        const res = await api.listTemplates(params);

        setTemplates(res.templates);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, [api, errorHandler, params]);

  return {
    isLoading,
    refresh,
    templates,
  };
};
