import { useAppConstantsStore } from "@src/stores";
import { useMemo } from "react";
import { useOrgAddons } from "./useOrgAddOns";
import type { AdminRoleNamesValue, OrgAddOnNamesValue } from "@src/stores";

type UseOrgAllowedAdminRolesReturn = {
  readonly isInitialLoading: boolean;
  readonly roles: readonly AdminRoleNamesValue[];
};

export const useOrgAllowedAdminRoles = (): UseOrgAllowedAdminRolesReturn => {
  const { adminRoleNames, adminRoleNamesSortedByImportance, orgAddOnToAdminRoleMap } =
    useAppConstantsStore();
  const { addOns, isInitialLoading } = useOrgAddons();

  const resolvedAdminRoles = useMemo(() => {
    const addOnRoles = addOns
      .map(ao => orgAddOnToAdminRoleMap[ao as unknown as OrgAddOnNamesValue])
      .filter(ar => ar !== undefined);

    return adminRoleNamesSortedByImportance.filter(
      // Org Admin is a default option, it's not tied to an addOn
      ar => ar === adminRoleNames.orgAdmin || addOnRoles.includes(ar)
    );
  }, [addOns, adminRoleNames.orgAdmin, adminRoleNamesSortedByImportance, orgAddOnToAdminRoleMap]);

  if (isInitialLoading) {
    return {
      isInitialLoading,
      roles: [],
    };
  }

  return {
    isInitialLoading,
    roles: resolvedAdminRoles,
  };
};
