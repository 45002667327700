// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../Spacer";
import { StyleSheet, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import React, { useRef } from "react";
import type { IconName } from "@merit/frontend-components";
import type { OPTestProps } from "../../../src/types/TestProps";

const generateTestIdProps = Helpers.generateTestIdProps;

type Props = {
  readonly iconName: IconName | null;
  readonly isActive: boolean;
  readonly title: string;
  readonly type?: "primary" | "small";
  readonly expanded: boolean;
  readonly testProps?: OPTestProps;
};

export const HelpLink = ({
  expanded = true,
  iconName,
  isActive,
  testProps,
  title,
  type = "primary",
}: Props) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    active: {
      backgroundColor: theme.colors.surface.pressed,
      borderRightColor: theme.colors.action.default,
      borderRightWidth: theme.borderRadii.s,
    },
    base: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      overflow: "hidden",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    hovered: {
      backgroundColor: theme.colors.surface.hovered,
      borderRightColor: theme.colors.action.default,
      borderRightWidth: theme.borderRadii.s,
    },
  });

  return (
    <a
      href="https://knowledge.merits.com/"
      style={{ textDecoration: "none" }}
      target="_blank"
      {...generateTestIdProps(testProps, { isHTML: true })}
    >
      <View ref={ref} style={[styles.base, isHovered && styles.hovered, isActive && styles.active]}>
        {iconName !== null && (
          <>
            <Icon alt="" name={iconName} />
            <HorizontalSpacer />
          </>
        )}
        {expanded && (
          <>
            {type === "primary" ? (
              <Heading level="3" numberOfLines={1}>
                {title}
              </Heading>
            ) : (
              <Body numberOfLines={1}>{title}</Body>
            )}
          </>
        )}
      </View>
    </a>
  );
};
