import { Log } from "@src/utils/logging";
import { getContainerFieldValue } from "@src/utils/getContainerFieldValue";
import { useAppConstantsStore } from "@src/stores";
import { useMemo } from "react";
import { useOrgAccountFolio } from "./useOrgAccountFolio";

type UseOrgAddonsReturn = {
  readonly addOns: readonly string[];
  readonly isInitialLoading: boolean;
};

export const useOrgAddons = (): UseOrgAddonsReturn => {
  const { accountFolioFieldNames } = useAppConstantsStore();
  const { data: accountFolio, isInitialLoading } = useOrgAccountFolio();

  return useMemo(() => {
    if (isInitialLoading) {
      return {
        addOns: [],
        isInitialLoading,
      };
    }

    if (accountFolio === undefined) {
      return {
        addOns: [],
        isInitialLoading: false,
      };
    }

    const addonsValue = getContainerFieldValue(accountFolioFieldNames.addOns, accountFolio);
    if (addonsValue === undefined) {
      return {
        addOns: [],
        isInitialLoading: false,
      };
    }

    try {
      const parsedAddons = JSON.parse(addonsValue);
      if (!(parsedAddons instanceof Array)) {
        throw new Error("parsed Add-ons field value not an Array");
      }

      if (!parsedAddons.every(ao => typeof ao === "string")) {
        throw new Error("parsed Add-ons field array value contains non-string");
      }

      // ESLint incorrectly reports the return type is any[], but TS correctly infers string[] from the type narrowing code above
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        addOns: parsedAddons,
        isInitialLoading: false,
      };
    } catch (error) {
      Log.error("error parsing org add-ons", { error });

      return {
        addOns: [],
        isInitialLoading: false,
      };
    }
  }, [accountFolio, accountFolioFieldNames.addOns, isInitialLoading]);
};
