/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Column DataType corresponding to PhoneNumber data.
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber extends DataType {
    /**
     * The default ISO Code for the country these phone numbers are from.
     * @type {string}
     * @memberof PhoneNumber
     */
    defaultCountryCode: string;
}

/**
 * Check if a given object implements the PhoneNumber interface.
 */
export function instanceOfPhoneNumber(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "defaultCountryCode" in value;

    return isInstance;
}

export function PhoneNumberFromJSON(json: any): PhoneNumber {
    return PhoneNumberFromJSONTyped(json, false);
}

export function PhoneNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...DataTypeFromJSONTyped(json, ignoreDiscriminator),
        'defaultCountryCode': json['defaultCountryCode'],
    };
}

export function PhoneNumberToJSON(value?: PhoneNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DataTypeToJSON(value),
        'defaultCountryCode': value.defaultCountryCode,
    };
}

