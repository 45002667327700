/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExtendTemplatePropertiesRules } from './ExtendTemplatePropertiesRules';
import {
    ExtendTemplatePropertiesRulesFromJSON,
    ExtendTemplatePropertiesRulesFromJSONTyped,
    ExtendTemplatePropertiesRulesToJSON,
} from './ExtendTemplatePropertiesRules';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * The required inputs to extend a Template from the source org's Template.
 * @export
 * @interface ExtendTemplateProperties
 */
export interface ExtendTemplateProperties {
    /**
     * 
     * @type {string}
     * @memberof ExtendTemplateProperties
     */
    sourceTemplateID: string;
    /**
     * The new name for a template.
     * @type {string}
     * @memberof ExtendTemplateProperties
     */
    name: string;
    /**
     * The new description for a template, inserting null or leaving blank will have the description copied from the source
     * @type {string}
     * @memberof ExtendTemplateProperties
     */
    description?: string | null;
    /**
     * Permissions for the new template.
     * @type {Array<PermissionPair>}
     * @memberof ExtendTemplateProperties
     */
    permissions?: Array<PermissionPair>;
    /**
     * 
     * @type {ExtendTemplatePropertiesRules}
     * @memberof ExtendTemplateProperties
     */
    rules?: ExtendTemplatePropertiesRules | null;
    /**
     * Whether or not containers created from this template should be automatically approved.
     * @type {boolean}
     * @memberof ExtendTemplateProperties
     */
    autoApprove?: boolean | null;
    /**
     * Whether or not containers are automatically removed when recipient email is changed.
     * @type {boolean}
     * @memberof ExtendTemplateProperties
     */
    autoRemove?: boolean | null;
    /**
     * Deprecated. Use autoRemove property as replacement.
     * @type {boolean}
     * @memberof ExtendTemplateProperties
     */
    autoRevoke?: boolean | null;
}

/**
 * Check if a given object implements the ExtendTemplateProperties interface.
 */
export function instanceOfExtendTemplateProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceTemplateID" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ExtendTemplatePropertiesFromJSON(json: any): ExtendTemplateProperties {
    return ExtendTemplatePropertiesFromJSONTyped(json, false);
}

export function ExtendTemplatePropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendTemplateProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceTemplateID': json['sourceTemplateID'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'rules': !exists(json, 'rules') ? undefined : ExtendTemplatePropertiesRulesFromJSON(json['rules']),
        'autoApprove': !exists(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRemove': !exists(json, 'autoRemove') ? undefined : json['autoRemove'],
        'autoRevoke': !exists(json, 'autoRevoke') ? undefined : json['autoRevoke'],
    };
}

export function ExtendTemplatePropertiesToJSON(value?: ExtendTemplateProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceTemplateID': value.sourceTemplateID,
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'rules': ExtendTemplatePropertiesRulesToJSON(value.rules),
        'autoApprove': value.autoApprove,
        'autoRemove': value.autoRemove,
        'autoRevoke': value.autoRevoke,
    };
}

