/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Auth0Options } from './Auth0Options';
import {
    Auth0OptionsFromJSON,
    Auth0OptionsFromJSONTyped,
    Auth0OptionsToJSON,
} from './Auth0Options';
import type { CapabilityWithRestriction } from './CapabilityWithRestriction';
import {
    CapabilityWithRestrictionFromJSON,
    CapabilityWithRestrictionFromJSONTyped,
    CapabilityWithRestrictionToJSON,
} from './CapabilityWithRestriction';

/**
 * The properties provided when creating an agent
 * @export
 * @interface AgentCreateProperties
 */
export interface AgentCreateProperties {
    /**
     * Determines how the agent gets a token to act on behalf of an entity. Login agents get a token from a 
     * user providing login credentials. Link agents follow an M2M Oauth 2.0 login flow, and they specify the 
     * entity on whose behalf they wish to act.
     * 
     * @type {string}
     * @memberof AgentCreateProperties
     */
    accessType: AgentCreatePropertiesAccessTypeEnum;
    /**
     * The name of the agent to display to entities that wish to link to the agent
     * @type {string}
     * @memberof AgentCreateProperties
     */
    name: string;
    /**
     * A description of what the agent does to be displayed to entities wishing to link to the agent
     * @type {string}
     * @memberof AgentCreateProperties
     */
    description: string;
    /**
     * How the agent handles an entity's privacy
     * @type {string}
     * @memberof AgentCreateProperties
     */
    privacyTos: string;
    /**
     * Description of acceptable use of the agent
     * @type {string}
     * @memberof AgentCreateProperties
     */
    usageTos: string;
    /**
     * How the agent handles an entity's data from Stellar
     * @type {string}
     * @memberof AgentCreateProperties
     */
    dataTos: string;
    /**
     * URI to which the user is re-directed after a successful login. Must be https.
     * @type {string}
     * @memberof AgentCreateProperties
     */
    loginURI?: string;
    /**
     * The agent's capabilities. Each capability allows an agent to take a certain action on a class of objects
     * on stellar. Entities that wish to use the agent must grant the agent permission to use its capabilities
     * on objects belonging to the entities.
     * 
     * @type {Array<CapabilityWithRestriction>}
     * @memberof AgentCreateProperties
     */
    capabilities: Array<CapabilityWithRestriction>;
    /**
     * Contact information for the agent/app developer
     * @type {string}
     * @memberof AgentCreateProperties
     */
    developerContactEmail: string;
    /**
     * 
     * @type {Auth0Options}
     * @memberof AgentCreateProperties
     */
    auth0Options?: Auth0Options;
    /**
     * Optional additional properties for this agent
     * @type {{ [key: string]: string; }}
     * @memberof AgentCreateProperties
     */
    additionals?: { [key: string]: string; };
}


/**
 * @export
 */
export const AgentCreatePropertiesAccessTypeEnum = {
    Login: 'login',
    Link: 'link',
    Webapp: 'webapp',
    Native: 'native'
} as const;
export type AgentCreatePropertiesAccessTypeEnum = typeof AgentCreatePropertiesAccessTypeEnum[keyof typeof AgentCreatePropertiesAccessTypeEnum];


/**
 * Check if a given object implements the AgentCreateProperties interface.
 */
export function instanceOfAgentCreateProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "privacyTos" in value;
    isInstance = isInstance && "usageTos" in value;
    isInstance = isInstance && "dataTos" in value;
    isInstance = isInstance && "capabilities" in value;
    isInstance = isInstance && "developerContactEmail" in value;

    return isInstance;
}

export function AgentCreatePropertiesFromJSON(json: any): AgentCreateProperties {
    return AgentCreatePropertiesFromJSONTyped(json, false);
}

export function AgentCreatePropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentCreateProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessType': json['accessType'],
        'name': json['name'],
        'description': json['description'],
        'privacyTos': json['privacyTos'],
        'usageTos': json['usageTos'],
        'dataTos': json['dataTos'],
        'loginURI': !exists(json, 'loginURI') ? undefined : json['loginURI'],
        'capabilities': ((json['capabilities'] as Array<any>).map(CapabilityWithRestrictionFromJSON)),
        'developerContactEmail': json['developerContactEmail'],
        'auth0Options': !exists(json, 'auth0Options') ? undefined : Auth0OptionsFromJSON(json['auth0Options']),
        'additionals': !exists(json, 'additionals') ? undefined : json['additionals'],
    };
}

export function AgentCreatePropertiesToJSON(value?: AgentCreateProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessType': value.accessType,
        'name': value.name,
        'description': value.description,
        'privacyTos': value.privacyTos,
        'usageTos': value.usageTos,
        'dataTos': value.dataTos,
        'loginURI': value.loginURI,
        'capabilities': ((value.capabilities as Array<any>).map(CapabilityWithRestrictionToJSON)),
        'developerContactEmail': value.developerContactEmail,
        'auth0Options': Auth0OptionsToJSON(value.auth0Options),
        'additionals': value.additionals,
    };
}

