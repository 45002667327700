/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSourceAllOfSchema
 */
export interface DataSourceAllOfSchema {
    /**
     * The delimiter used for separating values in a CSV/TSV/etc file
     * @type {string}
     * @memberof DataSourceAllOfSchema
     */
    delimiter?: DataSourceAllOfSchemaDelimiterEnum;
}


/**
 * @export
 */
export const DataSourceAllOfSchemaDelimiterEnum = {
    Comma: 'comma',
    Tab: 'tab',
    Pipe: 'pipe'
} as const;
export type DataSourceAllOfSchemaDelimiterEnum = typeof DataSourceAllOfSchemaDelimiterEnum[keyof typeof DataSourceAllOfSchemaDelimiterEnum];


/**
 * Check if a given object implements the DataSourceAllOfSchema interface.
 */
export function instanceOfDataSourceAllOfSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataSourceAllOfSchemaFromJSON(json: any): DataSourceAllOfSchema {
    return DataSourceAllOfSchemaFromJSONTyped(json, false);
}

export function DataSourceAllOfSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceAllOfSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delimiter': !exists(json, 'delimiter') ? undefined : json['delimiter'],
    };
}

export function DataSourceAllOfSchemaToJSON(value?: DataSourceAllOfSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delimiter': value.delimiter,
    };
}

