/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TemplateType } from './TemplateType';
import {
    TemplateTypeFromJSON,
    TemplateTypeFromJSONTyped,
    TemplateTypeToJSON,
} from './TemplateType';

/**
 * Representation of a template search result.
 * @export
 * @interface TemplateSearchResult
 */
export interface TemplateSearchResult {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof TemplateSearchResult
     */
    id?: string;
    /**
     * The name of the object
     * @type {string}
     * @memberof TemplateSearchResult
     */
    name?: string;
    /**
     * The UUID of this object's owner
     * @type {string}
     * @memberof TemplateSearchResult
     */
    owner?: string;
    /**
     * 
     * @type {TemplateType}
     * @memberof TemplateSearchResult
     */
    templateType?: TemplateType;
    /**
     * The description of this Template.
     * @type {string}
     * @memberof TemplateSearchResult
     */
    description?: string;
    /**
     * Whether the template is paused or live.
     * @type {string}
     * @memberof TemplateSearchResult
     */
    state?: TemplateSearchResultStateEnum;
    /**
     * Whether or not containers created from this template should be automatically approved.
     * @type {boolean}
     * @memberof TemplateSearchResult
     */
    autoApprove?: boolean;
}


/**
 * @export
 */
export const TemplateSearchResultStateEnum = {
    Paused: 'paused',
    Live: 'live'
} as const;
export type TemplateSearchResultStateEnum = typeof TemplateSearchResultStateEnum[keyof typeof TemplateSearchResultStateEnum];


/**
 * Check if a given object implements the TemplateSearchResult interface.
 */
export function instanceOfTemplateSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TemplateSearchResultFromJSON(json: any): TemplateSearchResult {
    return TemplateSearchResultFromJSONTyped(json, false);
}

export function TemplateSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'templateType': !exists(json, 'templateType') ? undefined : TemplateTypeFromJSON(json['templateType']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'autoApprove': !exists(json, 'autoApprove') ? undefined : json['autoApprove'],
    };
}

export function TemplateSearchResultToJSON(value?: TemplateSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner': value.owner,
        'templateType': TemplateTypeToJSON(value.templateType),
        'description': value.description,
        'state': value.state,
        'autoApprove': value.autoApprove,
    };
}

