// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Popover } from "..";
import { View } from "react-native";
import { createPortal } from "react-dom";
import React, { useState } from "react";
import type { OPTestProps } from "../../../src/types/TestProps";

const { Some } = Helpers;

type Props = {
  readonly text: string;
  readonly capitalize?: boolean;
  readonly bold?: boolean;
  readonly testProps?: OPTestProps;
};

export const EllipsisText = ({ bold, capitalize = false, testProps, text }: Props) => {
  const { theme } = useTheme();
  const [originElement, setOriginElement] = useState<HTMLDivElement | undefined>();
  const [isHover, setIsHover] = useState(false);
  const [width, setWidth] = useState<number>(0);

  const textLength = Some(text) ? text.length : 0;

  return (
    <>
      <div
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        ref={hoverRef => {
          if (hoverRef !== null) {
            setOriginElement(hoverRef);
          }
        }}
        style={{ flex: 1 }}
      >
        <View
          onLayout={({ nativeEvent }) => {
            const { width: parentWidth } = nativeEvent.layout;
            setWidth(parentWidth);
          }}
          style={{ flex: 1 }}
        >
          {width > 0 && (
            <View style={{ width }}>
              <Body
                capitalize={capitalize ? capitalize : undefined}
                numberOfLines={1}
                style={{
                  fontWeight: bold === true ? theme.fontWeights.semiBold : theme.fontWeights.normal,
                }}
                testProps={testProps}
              >
                {text}
              </Body>
            </View>
          )}
        </View>
      </div>

      {textLength > 15 &&
        isHover &&
        Some(originElement) &&
        createPortal(
          <Popover originElement={originElement} placement="top-start">
            <View
              style={{
                backgroundColor: theme.colors.brand.oceanBlue,
                maxWidth: 631,
                paddingHorizontal: theme.spacing.l,
                paddingVertical: theme.spacing.m,
              }}
            >
              <Body color={theme.colors.surface.default}>{text}</Body>
            </View>
          </Popover>,
          document.body
        )}
    </>
  );
};
