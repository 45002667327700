/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FieldKind } from './FieldKind';
import {
    FieldKindFromJSON,
    FieldKindFromJSONTyped,
    FieldKindToJSON,
} from './FieldKind';
import type { PaginationResponseCursor } from './PaginationResponseCursor';
import {
    PaginationResponseCursorFromJSON,
    PaginationResponseCursorFromJSONTyped,
    PaginationResponseCursorToJSON,
} from './PaginationResponseCursor';

/**
 * An object that contains a list of field kinds.
 * @export
 * @interface FieldKindResponse
 */
export interface FieldKindResponse {
    /**
     * The list of field kinds available.
     * @type {Array<FieldKind>}
     * @memberof FieldKindResponse
     */
    fieldKinds?: Array<FieldKind>;
    /**
     * Indicates whether more data is available after incrementing the offset
     * @type {boolean}
     * @memberof FieldKindResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {PaginationResponseCursor}
     * @memberof FieldKindResponse
     */
    cursor?: PaginationResponseCursor;
}

/**
 * Check if a given object implements the FieldKindResponse interface.
 */
export function instanceOfFieldKindResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FieldKindResponseFromJSON(json: any): FieldKindResponse {
    return FieldKindResponseFromJSONTyped(json, false);
}

export function FieldKindResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldKindResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldKinds': !exists(json, 'fieldKinds') ? undefined : ((json['fieldKinds'] as Array<any>).map(FieldKindFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !exists(json, 'cursor') ? undefined : PaginationResponseCursorFromJSON(json['cursor']),
    };
}

export function FieldKindResponseToJSON(value?: FieldKindResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldKinds': value.fieldKinds === undefined ? undefined : ((value.fieldKinds as Array<any>).map(FieldKindToJSON)),
        'hasMore': value.hasMore,
        'cursor': PaginationResponseCursorToJSON(value.cursor),
    };
}

