/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResponseCursor } from './PaginationResponseCursor';
import {
    PaginationResponseCursorFromJSON,
    PaginationResponseCursorFromJSONTyped,
    PaginationResponseCursorToJSON,
} from './PaginationResponseCursor';
import type { Template } from './Template';
import {
    TemplateFromJSON,
    TemplateFromJSONTyped,
    TemplateToJSON,
} from './Template';

/**
 * An object that contains a list of templates.
 * @export
 * @interface TemplateResponse
 */
export interface TemplateResponse {
    /**
     * The list of templates available.
     * @type {Array<Template>}
     * @memberof TemplateResponse
     */
    templates?: Array<Template>;
    /**
     * Indicates whether more data is available after incrementing the offset
     * @type {boolean}
     * @memberof TemplateResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {PaginationResponseCursor}
     * @memberof TemplateResponse
     */
    cursor?: PaginationResponseCursor;
}

/**
 * Check if a given object implements the TemplateResponse interface.
 */
export function instanceOfTemplateResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TemplateResponseFromJSON(json: any): TemplateResponse {
    return TemplateResponseFromJSONTyped(json, false);
}

export function TemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templates': !exists(json, 'templates') ? undefined : ((json['templates'] as Array<any>).map(TemplateFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !exists(json, 'cursor') ? undefined : PaginationResponseCursorFromJSON(json['cursor']),
    };
}

export function TemplateResponseToJSON(value?: TemplateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templates': value.templates === undefined ? undefined : ((value.templates as Array<any>).map(TemplateToJSON)),
        'hasMore': value.hasMore,
        'cursor': PaginationResponseCursorToJSON(value.cursor),
    };
}

