/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Update credential response
 * @export
 * @interface UpdateCredential200Response
 */
export interface UpdateCredential200Response {
    /**
     * object id
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    id: string;
    /**
     * object creation datetime
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    createdAt: string;
    /**
     * object update datetime
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    updatedAt: string;
    /**
     * The container template id to which the credential should be attached
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    targetContainerTemplateID: string;
    /**
     * The ID of this credential as known to DesignHuddle
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    designHuddleProjectID: string;
    /**
     * A description of the credential
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    description?: string;
    /**
     * The credential name
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    name: string;
    /**
     * Format for date fields on a credential
     * @type {string}
     * @memberof UpdateCredential200Response
     */
    dateFormat?: UpdateCredential200ResponseDateFormatEnum;
    /**
     * Whether the credential is published
     * @type {boolean}
     * @memberof UpdateCredential200Response
     */
    isPublished: boolean;
}


/**
 * @export
 */
export const UpdateCredential200ResponseDateFormatEnum = {
    AlphabeticFull: 'AlphabeticFull',
    AlphabeticShort: 'AlphabeticShort',
    NumericDash: 'NumericDash',
    NumericSlash: 'NumericSlash'
} as const;
export type UpdateCredential200ResponseDateFormatEnum = typeof UpdateCredential200ResponseDateFormatEnum[keyof typeof UpdateCredential200ResponseDateFormatEnum];


/**
 * Check if a given object implements the UpdateCredential200Response interface.
 */
export function instanceOfUpdateCredential200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "targetContainerTemplateID" in value;
    isInstance = isInstance && "designHuddleProjectID" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isPublished" in value;

    return isInstance;
}

export function UpdateCredential200ResponseFromJSON(json: any): UpdateCredential200Response {
    return UpdateCredential200ResponseFromJSONTyped(json, false);
}

export function UpdateCredential200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCredential200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'targetContainerTemplateID': json['targetContainerTemplateID'],
        'designHuddleProjectID': json['designHuddleProjectID'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'dateFormat': !exists(json, 'dateFormat') ? undefined : json['dateFormat'],
        'isPublished': json['isPublished'],
    };
}

export function UpdateCredential200ResponseToJSON(value?: UpdateCredential200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'targetContainerTemplateID': value.targetContainerTemplateID,
        'designHuddleProjectID': value.designHuddleProjectID,
        'description': value.description,
        'name': value.name,
        'dateFormat': value.dateFormat,
        'isPublished': value.isPublished,
    };
}

