/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInner } from './OrgsGet200ResponseContainersInner';
import {
    OrgsGet200ResponseContainersInnerFromJSON,
    OrgsGet200ResponseContainersInnerFromJSONTyped,
    OrgsGet200ResponseContainersInnerToJSON,
} from './OrgsGet200ResponseContainersInner';
import type { Search200ResponseDataSourcesInner } from './Search200ResponseDataSourcesInner';
import {
    Search200ResponseDataSourcesInnerFromJSON,
    Search200ResponseDataSourcesInnerFromJSONTyped,
    Search200ResponseDataSourcesInnerToJSON,
} from './Search200ResponseDataSourcesInner';
import type { Search200ResponseFieldKindsInner } from './Search200ResponseFieldKindsInner';
import {
    Search200ResponseFieldKindsInnerFromJSON,
    Search200ResponseFieldKindsInnerFromJSONTyped,
    Search200ResponseFieldKindsInnerToJSON,
} from './Search200ResponseFieldKindsInner';
import type { Search200ResponsePoliciesInner } from './Search200ResponsePoliciesInner';
import {
    Search200ResponsePoliciesInnerFromJSON,
    Search200ResponsePoliciesInnerFromJSONTyped,
    Search200ResponsePoliciesInnerToJSON,
} from './Search200ResponsePoliciesInner';
import type { Search200ResponseTemplatesInner } from './Search200ResponseTemplatesInner';
import {
    Search200ResponseTemplatesInnerFromJSON,
    Search200ResponseTemplatesInnerFromJSONTyped,
    Search200ResponseTemplatesInnerToJSON,
} from './Search200ResponseTemplatesInner';

/**
 * Results of a Search Query.
 * @export
 * @interface Search200Response
 */
export interface Search200Response {
    /**
     * The list of containers.
     * @type {Array<OrgsGet200ResponseContainersInner>}
     * @memberof Search200Response
     */
    containers?: Array<OrgsGet200ResponseContainersInner>;
    /**
     * The list of dataSources.
     * @type {Array<Search200ResponseDataSourcesInner>}
     * @memberof Search200Response
     */
    dataSources?: Array<Search200ResponseDataSourcesInner>;
    /**
     * The list of fieldKinds.
     * @type {Array<Search200ResponseFieldKindsInner>}
     * @memberof Search200Response
     */
    fieldKinds?: Array<Search200ResponseFieldKindsInner>;
    /**
     * The list of template.
     * @type {Array<Search200ResponseTemplatesInner>}
     * @memberof Search200Response
     */
    templates?: Array<Search200ResponseTemplatesInner>;
    /**
     * The list of template.
     * @type {Array<Search200ResponsePoliciesInner>}
     * @memberof Search200Response
     */
    policies?: Array<Search200ResponsePoliciesInner>;
    /**
     * Whether there are more search results
     * @type {boolean}
     * @memberof Search200Response
     */
    hasMore?: boolean;
}

/**
 * Check if a given object implements the Search200Response interface.
 */
export function instanceOfSearch200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Search200ResponseFromJSON(json: any): Search200Response {
    return Search200ResponseFromJSONTyped(json, false);
}

export function Search200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Search200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containers': !exists(json, 'containers') ? undefined : ((json['containers'] as Array<any>).map(OrgsGet200ResponseContainersInnerFromJSON)),
        'dataSources': !exists(json, 'dataSources') ? undefined : ((json['dataSources'] as Array<any>).map(Search200ResponseDataSourcesInnerFromJSON)),
        'fieldKinds': !exists(json, 'fieldKinds') ? undefined : ((json['fieldKinds'] as Array<any>).map(Search200ResponseFieldKindsInnerFromJSON)),
        'templates': !exists(json, 'templates') ? undefined : ((json['templates'] as Array<any>).map(Search200ResponseTemplatesInnerFromJSON)),
        'policies': !exists(json, 'policies') ? undefined : ((json['policies'] as Array<any>).map(Search200ResponsePoliciesInnerFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
    };
}

export function Search200ResponseToJSON(value?: Search200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containers': value.containers === undefined ? undefined : ((value.containers as Array<any>).map(OrgsGet200ResponseContainersInnerToJSON)),
        'dataSources': value.dataSources === undefined ? undefined : ((value.dataSources as Array<any>).map(Search200ResponseDataSourcesInnerToJSON)),
        'fieldKinds': value.fieldKinds === undefined ? undefined : ((value.fieldKinds as Array<any>).map(Search200ResponseFieldKindsInnerToJSON)),
        'templates': value.templates === undefined ? undefined : ((value.templates as Array<any>).map(Search200ResponseTemplatesInnerToJSON)),
        'policies': value.policies === undefined ? undefined : ((value.policies as Array<any>).map(Search200ResponsePoliciesInnerToJSON)),
        'hasMore': value.hasMore,
    };
}

