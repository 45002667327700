/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * The required inputs to updating a template field. Deprecated. Use UpdateTemplateFieldProperties.
 * @export
 * @interface TemplateFieldPatch
 */
export interface TemplateFieldPatch {
    /**
     * The new name for a template field.
     * @type {string}
     * @memberof TemplateFieldPatch
     */
    name?: string | null;
    /**
     * The new description for a template field. Since this is an optional field, if the field is missing or set to null, then the field will remain unchanged. If the field is set to empty string, then the new value would be empty string.
     * @type {string}
     * @memberof TemplateFieldPatch
     */
    description?: string | null;
    /**
     * Whether this field will be used as a method of contacting the recipient. Leaving this blank when extending template fields will have result in the field defaulting to false. Leaving this blank when updating will leave the field unchanged.
     * @type {boolean}
     * @memberof TemplateFieldPatch
     */
    contact?: boolean | null;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof TemplateFieldPatch
     */
    permissions?: Array<PermissionPair>;
    /**
     * A Rule as added to response objects (always displayed on response, even when empty)
     * @type {Array<Atom>}
     * @memberof TemplateFieldPatch
     */
    fieldValidationRules?: Array<Atom>;
}

/**
 * Check if a given object implements the TemplateFieldPatch interface.
 */
export function instanceOfTemplateFieldPatch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TemplateFieldPatchFromJSON(json: any): TemplateFieldPatch {
    return TemplateFieldPatchFromJSONTyped(json, false);
}

export function TemplateFieldPatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateFieldPatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'fieldValidationRules': !exists(json, 'fieldValidationRules') ? undefined : ((json['fieldValidationRules'] as Array<any>).map(AtomFromJSON)),
    };
}

export function TemplateFieldPatchToJSON(value?: TemplateFieldPatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'contact': value.contact,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'fieldValidationRules': value.fieldValidationRules === undefined ? undefined : ((value.fieldValidationRules as Array<any>).map(AtomToJSON)),
    };
}

