// Copyright 2022 Merit International Inc. All Rights Reserved.

import { create } from "zustand";

export type AcceptedFolioState = {
  readonly userAcceptedAccountFolio: boolean;
  readonly setUserAcceptedAccountFolio: (accepted: boolean) => void;
  readonly clear: () => void;
  readonly isFoliosLoaded: boolean;
};

export const useAcceptedFolioStore = create<AcceptedFolioState>()(set => ({
  clear: () => {
    set(() => ({ isFoliosLoaded: false, userAcceptedAccountFolio: false }));
  },
  isFoliosLoaded: false,
  setUserAcceptedAccountFolio(accepted: boolean) {
    set(() => ({ isFoliosLoaded: true, userAcceptedAccountFolio: accepted }));
  },
  userAcceptedAccountFolio: false,
}));
