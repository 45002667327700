// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Select, TextInput, useTheme } from "@merit/frontend-components";
import { DateTimePicker } from "@src/components/DateTimePicker/DateTimePicker";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Tabs } from "../../components";
import { View } from "react-native";
import { useApi } from "../../api/api";
import { useFocusEffect } from "@react-navigation/native";
import { useFormik } from "formik";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useRecordsSearchFormStyles } from "./styles";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import type { GetContainersTemplateTypeEnum, ListTemplates200Response } from "../../gen/org-portal";
import type { NativeSyntheticEvent, TextInputKeyPressEventData } from "react-native";
import type { SearchFormValues } from "./types";

const { Some } = Helpers;

const SCREEN_NAME = "SearchForm";
const TEMPLATES_FETCH_LIMIT = 2000;

type TabKey = "advanced" | "basic";
const tabs: readonly { readonly key: TabKey; readonly label: string }[] = [
  { key: "basic", label: "Search" },
  { key: "advanced", label: "Advanced Search" },
];

type Props = {
  readonly onSubmit: (values?: SearchFormValues) => void;
  readonly onChangeTab: (nextTab: TabKey) => void;
};

export const TEMPLATE_TYPE_OPTIONS = [
  { label: "Merit", value: "Merit" },
  { label: "Folio", value: "Folio" },
];

export const CONTAINER_STATUS_OPTIONS = [
  { label: "Unissued", value: "unissued" },
  { label: "Pending", value: "pending" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" },
  { label: "Revoked", value: "revoked" },
];

export const INITIAL_FORM_VALUES = {
  authorizedAtEnd: undefined,
  authorizedAtStart: undefined,
  email: undefined,
  fieldId: undefined,
  fieldQuery: undefined,
  firstName: undefined,
  lastName: undefined,
  state: undefined,
  templateName: undefined,
  type: "Merit" as GetContainersTemplateTypeEnum,
};

export const SearchForm = ({ onChangeTab, onSubmit }: Props) => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { theme } = useTheme();
  const styles = useRecordsSearchFormStyles();
  const [selectedTab, setSelectedTab] = useState<TabKey>("basic");
  const [templatesResponse, setTemplatesResponse] = useState<ListTemplates200Response>();
  const [templateNameOptions, setTemplateNameOptions] = useState<
    readonly { readonly label: string; readonly value: string }[]
  >([]);
  const [fieldOptions, setFieldOptions] = useState<
    readonly { readonly label: string; readonly value: string }[]
  >([]);
  const [keyToReRender, setKeyToReRender] = useState(0);
  const { errorHandler } = useServerErrorHandler();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<SearchFormValues>({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: (formValues: SearchFormValues) => {
      onSubmit(formValues);
    },
  });

  useFocusEffect(
    React.useCallback(() => {
      // Had to use let in order to use recursive function
      // eslint-disable-next-line functional/no-let
      let templatesCount = 0;
      const fetchAllTemplates = async (
        abortController: AbortController,
        start: string | undefined = undefined
      ) => {
        setIsLoading(true);
        try {
          const resp = await api.listTemplates(
            {
              limit: 100,
              orgID: selectedOrgId,
              start,
            },
            { signal: abortController.signal }
          );
          templatesCount += resp.templates.length;
          setTemplatesResponse(prevState => {
            if (Some(prevState)) {
              return {
                ...prevState,
                paginationInfo: resp.paginationInfo,
                templates: [...prevState.templates, ...resp.templates],
              };
            }

            return resp;
          });
          const fetchMore =
            resp.paginationInfo?.hasMore === true && templatesCount < TEMPLATES_FETCH_LIMIT;
          setIsLoading(fetchMore);
          if (fetchMore) {
            fetchAllTemplates(abortController, resp.paginationInfo?.nextStartAfter);
          }
        } catch (error) {
          errorHandler(error);
        }
      };

      const abortController = new AbortController();
      fetchAllTemplates(abortController);

      return () => {
        setTemplatesResponse(undefined);
        abortController.abort();
      };
    }, [api, errorHandler, selectedOrgId])
  );

  useEffect(() => {
    if (Some(templatesResponse)) {
      const { paginationInfo, templates } = templatesResponse;
      if (
        (Some(paginationInfo) && paginationInfo.hasMore !== true) ||
        templates.length === TEMPLATES_FETCH_LIMIT
      ) {
        setTemplateNameOptions(
          templates
            .filter(template => template.type === formik.values.type)
            .map(template => ({ label: template.name ?? "", value: template.id }))
        );
      }
    }
  }, [formik.values.type, templatesResponse]);

  useEffect(() => {
    if (Some(templatesResponse) && selectedTab === "advanced") {
      const templateFields = templatesResponse.templates.map(
        template => template.templateFields ?? []
      );
      const uniqueTemplateFields = _.uniqBy(templateFields.flat(), tf => tf.name);
      const options = uniqueTemplateFields.map(f => ({
        label: f.name ?? "",
        value:
          f.lineage === undefined || f.lineage.length === 0
            ? f.fieldID ?? ""
            : f.lineage[f.lineage.length - 1],
      }));

      setFieldOptions(options);
    }
  }, [api, selectedOrgId, selectedTab, templatesResponse]);

  const handleTextInputEnterPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const onTabChange = (tab: TabKey) => {
    setSelectedTab(tab);
    onChangeTab(tab);
    formik.resetForm();
    if (tab === "advanced") {
      formik.setFieldValue("type", undefined);
    } else {
      formik.setFieldValue("type", TEMPLATE_TYPE_OPTIONS[0].value);
    }
  };

  return (
    <>
      <View style={styles.tabContainer}>
        <Tabs items={tabs} onChange={onTabChange} selected={selectedTab} />
      </View>

      <View style={styles.formContainer}>
        {selectedTab === "basic" && (
          <View style={styles.formWrapper}>
            <>
              <View style={styles.inputFieldsContainer}>
                <View style={styles.formRow}>
                  <View style={styles.formItem}>
                    <TextInput
                      label="Email"
                      onChangeText={formik.handleChange("email")}
                      onKeyPress={handleTextInputEnterPress}
                      placeholder="Email"
                      size="medium"
                      testProps={{
                        elementName: "emailTextInput",
                        screenName: SCREEN_NAME,
                      }}
                      value={formik.values.email}
                    />
                  </View>
                  <HorizontalSpacer size={theme.spacing.xxl} />
                  <View style={styles.formItem}>
                    <TextInput
                      label="First name"
                      onChangeText={formik.handleChange("firstName")}
                      onKeyPress={handleTextInputEnterPress}
                      placeholder="First name"
                      size="medium"
                      testProps={{
                        elementName: "firstNameTextInput",
                        screenName: SCREEN_NAME,
                      }}
                      value={formik.values.firstName}
                    />
                  </View>
                  <HorizontalSpacer size={theme.spacing.xxl} />
                  <View style={styles.formItem}>
                    <TextInput
                      label="Last name"
                      onChangeText={formik.handleChange("lastName")}
                      onKeyPress={handleTextInputEnterPress}
                      placeholder="Last name"
                      size="medium"
                      testProps={{
                        elementName: "lastNameTextInput",
                        screenName: SCREEN_NAME,
                      }}
                      value={formik.values.lastName}
                    />
                  </View>
                </View>

                <VerticalSpacer />

                <View style={styles.formRow}>
                  <View style={styles.formItem}>
                    <Select
                      label="State"
                      onSelectOption={option => {
                        formik.setFieldValue("state", option.value);
                      }}
                      options={CONTAINER_STATUS_OPTIONS}
                      placeholder={{ label: "Container state", value: "" }}
                      size="medium"
                      testProps={{
                        elementName: "recordsSearchContainerState",
                        screenName: SCREEN_NAME,
                      }}
                      usePortal
                    />
                  </View>

                  <HorizontalSpacer size={theme.spacing.xxl} />

                  <View style={styles.formItem}>
                    <Select
                      defaultValue={TEMPLATE_TYPE_OPTIONS[0]}
                      label="Type"
                      onSelectOption={option => {
                        if (option.value === "") {
                          formik.setFieldValue("type", undefined);
                        } else {
                          if (formik.values.type !== option.value) {
                            setKeyToReRender(prev => prev + 1);
                            formik.setFieldValue("templateName", undefined);
                          }
                          formik.setFieldValue("type", option.value);
                        }
                      }}
                      options={TEMPLATE_TYPE_OPTIONS}
                      size="medium"
                      testProps={{
                        elementName: "recordsSearchTemplateType",
                        screenName: SCREEN_NAME,
                      }}
                      usePortal
                    />
                  </View>

                  <HorizontalSpacer size={theme.spacing.xxl} />

                  <View style={styles.formItem}>
                    <Select
                      disabled={isLoading}
                      key={keyToReRender}
                      label="Template"
                      onSelectOption={option => {
                        if (option.value === "") {
                          formik.setFieldValue("templateName", undefined);
                        } else {
                          formik.setFieldValue("templateName", option.value);
                        }
                      }}
                      options={templateNameOptions}
                      placeholder={{
                        label: isLoading ? "Loading templates..." : "Template name",
                        value: "",
                      }}
                      size="medium"
                      testProps={{
                        elementName: "recordsSearchTemplateName",
                        screenName: SCREEN_NAME,
                      }}
                      usePortal
                    />
                  </View>

                  <HorizontalSpacer />
                </View>
              </View>
            </>
            <>
              <View style={styles.issueDateContainer}>
                <View
                  style={{
                    padding: theme.spacing.l,
                  }}
                >
                  <View style={styles.formItem}>
                    <Body>Issue date start</Body>
                    <VerticalSpacer size={theme.spacing.xs} />
                    <DateTimePicker
                      onChange={value => {
                        if (value === "") {
                          formik.setFieldValue("authorizedAtStart", undefined);
                        } else {
                          formik.setFieldValue("authorizedAtStart", value);
                        }
                      }}
                      size="medium"
                      testProps={{
                        elementName: "recordsSearchIssueDateStart",
                        screenName: SCREEN_NAME,
                      }}
                      type="date"
                      width={224}
                    />
                  </View>
                  <VerticalSpacer />

                  <View style={styles.formItem}>
                    <Body>Issue date end</Body>
                    <VerticalSpacer size={theme.spacing.xs} />
                    <DateTimePicker
                      onChange={value => {
                        if (value === "") {
                          formik.setFieldValue("authorizedAtEnd", undefined);
                        } else {
                          formik.setFieldValue("authorizedAtEnd", value);
                        }
                      }}
                      size="medium"
                      testProps={{
                        elementName: "recordsSearchIssueDateEnd",
                        screenName: SCREEN_NAME,
                      }}
                      type="date"
                      width={224}
                    />
                  </View>
                </View>
              </View>
            </>

            <HorizontalSpacer />

            <View style={styles.searchButtonContainer}>
              <Button
                onPress={formik.handleSubmit}
                size="medium"
                testProps={{
                  elementName: "recordsBasicSearchButton",
                  screenName: SCREEN_NAME,
                }}
                text="Search"
                type="primary"
              />
            </View>
          </View>
        )}

        {selectedTab === "advanced" && (
          <>
            <View
              style={[styles.formRow, { paddingHorizontal: 32, paddingVertical: theme.spacing.l }]}
            >
              <View style={styles.formItemWide}>
                <Select
                  disabled={isLoading}
                  label="Field"
                  onSelectOption={option => {
                    if (option.value === "") {
                      formik.setFieldValue("fieldId", undefined);
                    } else {
                      formik.setFieldValue("fieldId", option.value);
                    }
                  }}
                  options={fieldOptions}
                  placeholder={{
                    label: isLoading ? "Loading fields..." : "Field",
                    value: "",
                  }}
                  size="medium"
                  testProps={{
                    elementName: "recordsSearchTemplateName",
                    screenName: SCREEN_NAME,
                  }}
                  usePortal
                />
              </View>
              <HorizontalSpacer size={24} />
              <View style={styles.formItemWide}>
                <TextInput
                  label="Query"
                  onChangeText={formik.handleChange("fieldQuery")}
                  onKeyPress={handleTextInputEnterPress}
                  placeholder="Query"
                  size="medium"
                  testProps={{
                    elementName: "fieldQueryTextInput",
                    screenName: SCREEN_NAME,
                  }}
                  value={formik.values.fieldQuery}
                />
              </View>
              <HorizontalSpacer />
              <View style={{ alignSelf: "flex-end" }}>
                <Button
                  onPress={formik.handleSubmit}
                  size="medium"
                  testProps={{
                    elementName: "recordsAdvancedSearchButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Search"
                  type="primary"
                />
              </View>
            </View>
          </>
        )}
      </View>
    </>
  );
};
