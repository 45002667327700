/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Credential text source fields
 * @export
 * @interface CreateCredentialRequestAllOfTextSourcesInner
 */
export interface CreateCredentialRequestAllOfTextSourcesInner {
    /**
     * The container template id which has the template field for the text source
     * @type {string}
     * @memberof CreateCredentialRequestAllOfTextSourcesInner
     */
    sourceContainerTemplateID: string;
    /**
     * The container template field id whose value is used for this text source
     * @type {string}
     * @memberof CreateCredentialRequestAllOfTextSourcesInner
     */
    sourceContainerTemplateFieldID: string;
}

/**
 * Check if a given object implements the CreateCredentialRequestAllOfTextSourcesInner interface.
 */
export function instanceOfCreateCredentialRequestAllOfTextSourcesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceContainerTemplateID" in value;
    isInstance = isInstance && "sourceContainerTemplateFieldID" in value;

    return isInstance;
}

export function CreateCredentialRequestAllOfTextSourcesInnerFromJSON(json: any): CreateCredentialRequestAllOfTextSourcesInner {
    return CreateCredentialRequestAllOfTextSourcesInnerFromJSONTyped(json, false);
}

export function CreateCredentialRequestAllOfTextSourcesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCredentialRequestAllOfTextSourcesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceContainerTemplateID': json['sourceContainerTemplateID'],
        'sourceContainerTemplateFieldID': json['sourceContainerTemplateFieldID'],
    };
}

export function CreateCredentialRequestAllOfTextSourcesInnerToJSON(value?: CreateCredentialRequestAllOfTextSourcesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceContainerTemplateID': value.sourceContainerTemplateID,
        'sourceContainerTemplateFieldID': value.sourceContainerTemplateFieldID,
    };
}

