/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IngestDatasourceLog200Response
 */
export interface IngestDatasourceLog200Response {
    /**
     * 
     * @type {string}
     * @memberof IngestDatasourceLog200Response
     */
    adminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof IngestDatasourceLog200Response
     */
    correlationID: string;
    /**
     * 
     * @type {string}
     * @memberof IngestDatasourceLog200Response
     */
    datasourceID: string;
    /**
     * 
     * @type {string}
     * @memberof IngestDatasourceLog200Response
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof IngestDatasourceLog200Response
     */
    fileURL: string;
}

/**
 * Check if a given object implements the IngestDatasourceLog200Response interface.
 */
export function instanceOfIngestDatasourceLog200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "adminEmail" in value;
    isInstance = isInstance && "correlationID" in value;
    isInstance = isInstance && "datasourceID" in value;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "fileURL" in value;

    return isInstance;
}

export function IngestDatasourceLog200ResponseFromJSON(json: any): IngestDatasourceLog200Response {
    return IngestDatasourceLog200ResponseFromJSONTyped(json, false);
}

export function IngestDatasourceLog200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngestDatasourceLog200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminEmail': json['adminEmail'],
        'correlationID': json['correlationID'],
        'datasourceID': json['datasourceID'],
        'fileName': json['fileName'],
        'fileURL': json['fileURL'],
    };
}

export function IngestDatasourceLog200ResponseToJSON(value?: IngestDatasourceLog200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminEmail': value.adminEmail,
        'correlationID': value.correlationID,
        'datasourceID': value.datasourceID,
        'fileName': value.fileName,
        'fileURL': value.fileURL,
    };
}

