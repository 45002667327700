// Copyright 2023 Merit International Inc. All Rights Reserved

import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Pressable, StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../components/Spacer";
import React from "react";
import type { OPTestProps } from "../types/TestProps";
import type { ReactNode } from "react";

type Props = {
  readonly actionButtons: ReactNode;
  readonly children: ReactNode;
  readonly onPressClose: () => void;
  readonly subTitle?: ReactNode;
  readonly title: string;
  readonly testProps?: OPTestProps;
};

const { Some } = Helpers;

export const DetailsDrawer = ({
  actionButtons,
  children,
  onPressClose,
  subTitle,
  testProps,
  title,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    actionContainer: {
      paddingHorizontal: 32,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      paddingVertical: theme.spacing.xxl,
    },
    headingContainer: {
      alignItems: "flex-start",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 32,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.headingContainer}>
        <View style={{ maxWidth: 650 }}>
          <Heading
            bold
            level="3"
            numberOfLines={1}
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}HeaderText`,
                  }
                : testProps
            }
          >
            {title}
          </Heading>
          <VerticalSpacer size={theme.spacing.s} />
          <Heading
            level="6"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}SubHeaderText`,
                  }
                : testProps
            }
          >
            {subTitle}
          </Heading>
        </View>

        <Pressable
          onPress={() => {
            onPressClose();
          }}
          {...Helpers.generateTestIdProps(
            Some(testProps)
              ? {
                  ...testProps,
                  elementName: `${testProps.elementName}CloseIcon`,
                }
              : testProps
          )}
        >
          <Icon
            name="closeSmallDefault"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}CloseButton`,
                  }
                : testProps
            }
          />
        </Pressable>
      </View>

      <VerticalSpacer size={theme.spacing.xl} />

      <View style={styles.actionContainer}>{actionButtons}</View>

      <VerticalSpacer size={theme.spacing.xxl} />

      {children}
    </View>
  );
};
