/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplatesTemplateIDPATCHRequest
 */
export interface TemplatesTemplateIDPATCHRequest {
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    templateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    templateTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    templateStatus?: TemplatesTemplateIDPATCHRequestTemplateStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    brandId?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    brandCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    userGroupsMatchType?: TemplatesTemplateIDPATCHRequestUserGroupsMatchTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    userGroupIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    userGroupCodes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    replaceUserGroups?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    primaryTemplateCategoryItemId?: number;
    /**
     * Supply an object containing the subcategories and their respective items to assign to the template. Provide the template_category_id (converted to string) as the key mapped to an array of template_category_item_id numbers. Note that this should only include subcategories, not the primary (single item assignment via the field above). The list of categories/items can be retrieved <a href="#tag/Templates/paths/~1template~1categories/get">here</a>. Example: <em>{ "2": [1,2,3], "3": [2,4,6] }</em>
     * @type {object}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    subcategoryItems?: object;
    /**
     * 
     * @type {boolean}
     * @memberof TemplatesTemplateIDPATCHRequest
     */
    replaceSubcategoryItems?: boolean;
}


/**
 * @export
 */
export const TemplatesTemplateIDPATCHRequestTemplateStatusEnum = {
    Active: 'active',
    Hidden: 'hidden'
} as const;
export type TemplatesTemplateIDPATCHRequestTemplateStatusEnum = typeof TemplatesTemplateIDPATCHRequestTemplateStatusEnum[keyof typeof TemplatesTemplateIDPATCHRequestTemplateStatusEnum];

/**
 * @export
 */
export const TemplatesTemplateIDPATCHRequestUserGroupsMatchTypeEnum = {
    Whitelist: 'whitelist',
    Blacklist: 'blacklist'
} as const;
export type TemplatesTemplateIDPATCHRequestUserGroupsMatchTypeEnum = typeof TemplatesTemplateIDPATCHRequestUserGroupsMatchTypeEnum[keyof typeof TemplatesTemplateIDPATCHRequestUserGroupsMatchTypeEnum];


/**
 * Check if a given object implements the TemplatesTemplateIDPATCHRequest interface.
 */
export function instanceOfTemplatesTemplateIDPATCHRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TemplatesTemplateIDPATCHRequestFromJSON(json: any): TemplatesTemplateIDPATCHRequest {
    return TemplatesTemplateIDPATCHRequestFromJSONTyped(json, false);
}

export function TemplatesTemplateIDPATCHRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplatesTemplateIDPATCHRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateCode': !exists(json, 'template_code') ? undefined : json['template_code'],
        'templateTitle': !exists(json, 'template_title') ? undefined : json['template_title'],
        'templateStatus': !exists(json, 'template_status') ? undefined : json['template_status'],
        'brandId': !exists(json, 'brand_id') ? undefined : json['brand_id'],
        'brandCode': !exists(json, 'brand_code') ? undefined : json['brand_code'],
        'userGroupsMatchType': !exists(json, 'user_groups_match_type') ? undefined : json['user_groups_match_type'],
        'userGroupIds': !exists(json, 'user_group_ids') ? undefined : json['user_group_ids'],
        'userGroupCodes': !exists(json, 'user_group_codes') ? undefined : json['user_group_codes'],
        'replaceUserGroups': !exists(json, 'replace_user_groups') ? undefined : json['replace_user_groups'],
        'primaryTemplateCategoryItemId': !exists(json, 'primary_template_category_item_id') ? undefined : json['primary_template_category_item_id'],
        'subcategoryItems': !exists(json, 'subcategory_items') ? undefined : json['subcategory_items'],
        'replaceSubcategoryItems': !exists(json, 'replace_subcategory_items') ? undefined : json['replace_subcategory_items'],
    };
}

export function TemplatesTemplateIDPATCHRequestToJSON(value?: TemplatesTemplateIDPATCHRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template_code': value.templateCode,
        'template_title': value.templateTitle,
        'template_status': value.templateStatus,
        'brand_id': value.brandId,
        'brand_code': value.brandCode,
        'user_groups_match_type': value.userGroupsMatchType,
        'user_group_ids': value.userGroupIds,
        'user_group_codes': value.userGroupCodes,
        'replace_user_groups': value.replaceUserGroups,
        'primary_template_category_item_id': value.primaryTemplateCategoryItemId,
        'subcategory_items': value.subcategoryItems,
        'replace_subcategory_items': value.replaceSubcategoryItems,
    };
}

