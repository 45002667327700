/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchRequestContainerFieldsInner } from './SearchRequestContainerFieldsInner';
import {
    SearchRequestContainerFieldsInnerFromJSON,
    SearchRequestContainerFieldsInnerFromJSONTyped,
    SearchRequestContainerFieldsInnerToJSON,
} from './SearchRequestContainerFieldsInner';

/**
 * Representation of a search query.
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * The type of object to search for
     * @type {string}
     * @memberof SearchRequest
     */
    objectType?: SearchRequestObjectTypeEnum | null;
    /**
     * The prefix or name of the object (template, fieldkind, datasource or policy) to search for
     * @type {string}
     * @memberof SearchRequest
     */
    objectName?: string | null;
    /**
     * Container field values to query.
     * @type {Array<SearchRequestContainerFieldsInner>}
     * @memberof SearchRequest
     */
    containerFields?: Array<SearchRequestContainerFieldsInner>;
    /**
     * The recipient ID to search for
     * @type {string}
     * @memberof SearchRequest
     */
    recipientID?: string | null;
    /**
     * The template ID to search for
     * @type {string}
     * @memberof SearchRequest
     */
    templateID?: string | null;
    /**
     * The template type to search for
     * @type {string}
     * @memberof SearchRequest
     */
    templateType?: SearchRequestTemplateTypeEnum | null;
    /**
     * The state of the container
     * @type {string}
     * @memberof SearchRequest
     */
    state?: SearchRequestStateEnum | null;
    /**
     * RFC 3339, section 5.6 date-time (with optional offset)
     * @type {Date}
     * @memberof SearchRequest
     */
    authorizedAtStart?: Date | null;
    /**
     * RFC 3339, section 5.6 date-time (with optional offset)
     * @type {Date}
     * @memberof SearchRequest
     */
    authorizedAtEnd?: Date | null;
    /**
     * The maximum number of search results to return, max 500
     * @type {number}
     * @memberof SearchRequest
     */
    limit?: number | null;
}


/**
 * @export
 */
export const SearchRequestObjectTypeEnum = {
    Template: 'template',
    Fieldkind: 'fieldkind',
    Datasource: 'datasource',
    Policy: 'policy'
} as const;
export type SearchRequestObjectTypeEnum = typeof SearchRequestObjectTypeEnum[keyof typeof SearchRequestObjectTypeEnum];

/**
 * @export
 */
export const SearchRequestTemplateTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
} as const;
export type SearchRequestTemplateTypeEnum = typeof SearchRequestTemplateTypeEnum[keyof typeof SearchRequestTemplateTypeEnum];

/**
 * @export
 */
export const SearchRequestStateEnum = {
    Unissued: 'unissued',
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Revoked: 'revoked'
} as const;
export type SearchRequestStateEnum = typeof SearchRequestStateEnum[keyof typeof SearchRequestStateEnum];


/**
 * Check if a given object implements the SearchRequest interface.
 */
export function instanceOfSearchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return SearchRequestFromJSONTyped(json, false);
}

export function SearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectType': !exists(json, 'objectType') ? undefined : json['objectType'],
        'objectName': !exists(json, 'objectName') ? undefined : json['objectName'],
        'containerFields': !exists(json, 'containerFields') ? undefined : ((json['containerFields'] as Array<any>).map(SearchRequestContainerFieldsInnerFromJSON)),
        'recipientID': !exists(json, 'recipientID') ? undefined : json['recipientID'],
        'templateID': !exists(json, 'templateID') ? undefined : json['templateID'],
        'templateType': !exists(json, 'templateType') ? undefined : json['templateType'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'authorizedAtStart': !exists(json, 'authorized_at_start') ? undefined : (json['authorized_at_start'] === null ? null : new Date(json['authorized_at_start'])),
        'authorizedAtEnd': !exists(json, 'authorized_at_end') ? undefined : (json['authorized_at_end'] === null ? null : new Date(json['authorized_at_end'])),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function SearchRequestToJSON(value?: SearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectType': value.objectType,
        'objectName': value.objectName,
        'containerFields': value.containerFields === undefined ? undefined : ((value.containerFields as Array<any>).map(SearchRequestContainerFieldsInnerToJSON)),
        'recipientID': value.recipientID,
        'templateID': value.templateID,
        'templateType': value.templateType,
        'state': value.state,
        'authorized_at_start': value.authorizedAtStart === undefined ? undefined : (value.authorizedAtStart === null ? null : value.authorizedAtStart.toISOString()),
        'authorized_at_end': value.authorizedAtEnd === undefined ? undefined : (value.authorizedAtEnd === null ? null : value.authorizedAtEnd.toISOString()),
        'limit': value.limit,
    };
}

