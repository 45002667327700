/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTemplateRules200ResponseRulesBoolFieldHasValue } from './GetTemplateRules200ResponseRulesBoolFieldHasValue';
import {
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON,
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSONTyped,
    GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON,
} from './GetTemplateRules200ResponseRulesBoolFieldHasValue';

/**
 * 
 * @export
 * @interface GetTemplateRules200ResponseRulesBool
 */
export interface GetTemplateRules200ResponseRulesBool {
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesBool
     */
    fieldHasValue?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesBool
     */
    isFalse?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesBool
     */
    isTrue?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
}

/**
 * Check if a given object implements the GetTemplateRules200ResponseRulesBool interface.
 */
export function instanceOfGetTemplateRules200ResponseRulesBool(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseRulesBoolFromJSON(json: any): GetTemplateRules200ResponseRulesBool {
    return GetTemplateRules200ResponseRulesBoolFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseRulesBoolFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200ResponseRulesBool {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldHasValue': !exists(json, 'FieldHasValue') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['FieldHasValue']),
        'isFalse': !exists(json, 'IsFalse') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['IsFalse']),
        'isTrue': !exists(json, 'IsTrue') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['IsTrue']),
    };
}

export function GetTemplateRules200ResponseRulesBoolToJSON(value?: GetTemplateRules200ResponseRulesBool | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FieldHasValue': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.fieldHasValue),
        'IsFalse': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.isFalse),
        'IsTrue': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.isTrue),
    };
}

