/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Abstract representation of a Column's DataType - use one of its implementing children like Bool or Date.
 * @export
 */
export const FieldType = {
    Bool: 'Bool',
    Date: 'Date',
    DateTime: 'DateTime',
    Email: 'Email',
    Markdown: 'Markdown',
    Number: 'Number',
    PhoneNumber: 'PhoneNumber',
    Text: 'Text',
    Blob: 'Blob',
    Json: 'JSON'
} as const;
export type FieldType = typeof FieldType[keyof typeof FieldType];


export function FieldTypeFromJSON(json: any): FieldType {
    return FieldTypeFromJSONTyped(json, false);
}

export function FieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldType {
    return json as FieldType;
}

export function FieldTypeToJSON(value?: FieldType | null): any {
    return value as any;
}

