// Copyright 2024 Merit International Inc. All Rights Reserved

import { HorizontalSpacer } from "@src/components";
import { Select, TextInput } from "@merit/frontend-components";
import { View } from "react-native";
import { useState } from "react";
import type { OPTestProps } from "@src/types/TestProps";

type Props = {
  readonly onChange: (duration: string) => void;
  readonly value: string;
  readonly testProps?: OPTestProps;
  readonly size?: "medium" | "small";
};

const TIME_UNITS = [
  { label: "Hours", value: "h" },
  { label: "Minutes", value: "m" },
  { label: "Seconds", value: "s" },
];

const digitRegEx = new RegExp("\\D", "gu");

export const DurationInput = ({ onChange, size, testProps, value }: Props) => {
  const InitialUnitOfTime = value.slice(value.length - 1);
  const initialDuration = value.slice(0, value.length - 1);

  const [duration, setDuration] = useState(initialDuration);
  const [unitOfTime, setUnitOfTime] = useState(InitialUnitOfTime);

  return (
    <View style={{ flexDirection: "row" }}>
      <TextInput
        onChangeText={text => {
          const durationWithNumbers = text.replace(digitRegEx, "");
          setDuration(durationWithNumbers);
          onChange(`${durationWithNumbers}${unitOfTime}`);
        }}
        placeholder="Duration"
        size={size}
        testProps={testProps}
        value={duration}
        width={90}
      />
      <HorizontalSpacer />
      <Select
        defaultValue={TIME_UNITS.find(_ => _.value === unitOfTime)}
        label=""
        onSelectOption={option => {
          if (typeof option.value === "string") {
            setUnitOfTime(option.value);
            onChange(`${duration}${option.value}`);
          }
        }}
        options={TIME_UNITS}
        placeholder={{ label: "Unit of time", value: "" }}
        showLabel={false}
        size={size}
        usePortal
      />
    </View>
  );
};
