/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     BlobFromJSONTyped,
     BoolFromJSONTyped,
     ModelDateFromJSONTyped,
     DateTimeFromJSONTyped,
     EmailFromJSONTyped,
     JSONFromJSONTyped,
     MarkdownFromJSONTyped,
     NumberFromJSONTyped,
     PhoneNumberFromJSONTyped,
     TextFromJSONTyped
} from './index';

/**
 * Abstract representation of a Column's DataType - use one of its implementing children like Bool or Date.
 * @export
 * @interface DataType
 */
export interface DataType {
    /**
     * 
     * @type {string}
     * @memberof DataType
     */
    dataType: string;
}

/**
 * Check if a given object implements the DataType interface.
 */
export function instanceOfDataType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataType" in value;

    return isInstance;
}

export function DataTypeFromJSON(json: any): DataType {
    return DataTypeFromJSONTyped(json, false);
}

export function DataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['dataType'] === 'Blob') {
            return BlobFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'Bool') {
            return BoolFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'Date') {
            return ModelDateFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'DateTime') {
            return DateTimeFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'Email') {
            return EmailFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'JSON') {
            return JSONFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'Markdown') {
            return MarkdownFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'Number') {
            return NumberFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'PhoneNumber') {
            return PhoneNumberFromJSONTyped(json, true);
        }
        if (json['dataType'] === 'Text') {
            return TextFromJSONTyped(json, true);
        }
    }
    return {
        
        'dataType': json['dataType'],
    };
}

export function DataTypeToJSON(value?: DataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataType': value.dataType,
    };
}

