// Copyright 2023 Merit International Inc. All Rights Reserved

import { Errors, Helpers } from "@merit/frontend-utils";
import { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum as PredicateEnum } from "@src/gen/org-portal";
import { getDateString, getDateTimeString } from "./time";
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from "@src/gen/org-portal";

const { None } = Helpers;

export const getRuleArguments = (
  predicate: PredicateEnum,
  ruleArguments: OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom["arguments"]
) => {
  if (None(ruleArguments)) {
    return "";
  }

  switch (predicate) {
    case PredicateEnum.AfterThisTimeOfDay:
    case PredicateEnum.BeforeThisTimeOfDay:
      return ruleArguments.join(":");
    case PredicateEnum.AfterThisDatetime:
    case PredicateEnum.BeforeThisDatetime:
      return getDateTimeString(ruleArguments[0]);
    case PredicateEnum.AfterToday:
    case PredicateEnum.BeforeToday:
      return getDateString(new Date());
    case PredicateEnum.BeforeTodayMinusXDays:
      return `${getDateString(new Date())} minus ${ruleArguments[0]} ${
        ruleArguments[0] === "1" ? "day" : "days"
      }`;
    case PredicateEnum.AfterThisDate:
    case PredicateEnum.BeforeThisDate:
    case PredicateEnum.EqualToDate:
      return getDateString(ruleArguments[0]);
    case PredicateEnum.BeforeNow:
    case PredicateEnum.AfterNow:
      return getDateTimeString(new Date());
    case PredicateEnum.BeforeNowMinusXDuration:
    case PredicateEnum.FieldHasValue:
    case PredicateEnum.EqualToX:
    case PredicateEnum.IsEmailDomain:
    case PredicateEnum.IsFalse:
    case PredicateEnum.IsTrue:
    case PredicateEnum.LessThanX:
    case PredicateEnum.MatchesThisString:
    case PredicateEnum.MoreThanX:
    case PredicateEnum.ReceivedXContainersFromTemplates:
      return ruleArguments[0];
    default:
      throw new Errors.UnreachableCaseError(predicate);
  }
};
