/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Special credential text source fields
 * @export
 * @interface CreateCredentialRequestAllOfSpecialTextSourcesInner
 */
export interface CreateCredentialRequestAllOfSpecialTextSourcesInner {
    /**
     * The kind of special text source
     * @type {string}
     * @memberof CreateCredentialRequestAllOfSpecialTextSourcesInner
     */
    kind: CreateCredentialRequestAllOfSpecialTextSourcesInnerKindEnum;
}


/**
 * @export
 */
export const CreateCredentialRequestAllOfSpecialTextSourcesInnerKindEnum = {
    FullName: 'FullName',
    IssuedOn: 'IssuedOn'
} as const;
export type CreateCredentialRequestAllOfSpecialTextSourcesInnerKindEnum = typeof CreateCredentialRequestAllOfSpecialTextSourcesInnerKindEnum[keyof typeof CreateCredentialRequestAllOfSpecialTextSourcesInnerKindEnum];


/**
 * Check if a given object implements the CreateCredentialRequestAllOfSpecialTextSourcesInner interface.
 */
export function instanceOfCreateCredentialRequestAllOfSpecialTextSourcesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "kind" in value;

    return isInstance;
}

export function CreateCredentialRequestAllOfSpecialTextSourcesInnerFromJSON(json: any): CreateCredentialRequestAllOfSpecialTextSourcesInner {
    return CreateCredentialRequestAllOfSpecialTextSourcesInnerFromJSONTyped(json, false);
}

export function CreateCredentialRequestAllOfSpecialTextSourcesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCredentialRequestAllOfSpecialTextSourcesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kind': json['kind'],
    };
}

export function CreateCredentialRequestAllOfSpecialTextSourcesInnerToJSON(value?: CreateCredentialRequestAllOfSpecialTextSourcesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kind': value.kind,
    };
}

