// Copyright 2024 Merit International Inc. All Rights Reserved

import { StyleSheet } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

type PreviewDataSourceStyles = {
  readonly checkboxAll: ViewStyle;
  readonly container: ViewStyle;
  readonly content: ViewStyle;
  readonly fakeField: ViewStyle;
  readonly footer: ViewStyle;
  readonly schemaColumnDatatype: ViewStyle;
  readonly schemaColumnFormat: ViewStyle;
  readonly schemaColumnIdentifier: ViewStyle;
  readonly schemaColumnName: ViewStyle;
  readonly schemaContainer: ViewStyle;
  readonly schemaTableRow: ViewStyle;
};

export const usePreviewDataSourceStyles = () => {
  const { theme } = useTheme();

  return StyleSheet.create<PreviewDataSourceStyles>({
    checkboxAll: {
      justifyContent: "center",
      padding: theme.spacing.l,
    },
    container: {
      flex: 1,
    },
    content: {
      alignSelf: "center",
      flex: 1,
      paddingVertical: theme.spacing.xxl,
      width: 960,
    },
    fakeField: {
      backgroundColor: theme.colors.action.disabled,
      borderColor: theme.colors.border.subdued,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      height: 40,
      justifyContent: "center",
      paddingHorizontal: theme.spacing.m,
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    schemaColumnDatatype: {
      flex: 1,
      justifyContent: "center",
      padding: theme.spacing.l,
    },
    schemaColumnFormat: {
      flex: 1,
      justifyContent: "center",
      padding: theme.spacing.l,
    },
    schemaColumnIdentifier: {
      flex: 1,
      justifyContent: "center",
      padding: theme.spacing.l,
    },
    schemaColumnName: {
      flex: 1.5,
      justifyContent: "center",
      padding: theme.spacing.l,
    },
    schemaContainer: {
      backgroundColor: theme.colors.background.white,
    },
    schemaTableRow: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 32,
    },
  });
};
