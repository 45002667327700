// Copyright 2022 Merit International Inc. All Rights Reserved

import { StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ReactNode } from "react";

type Props = {
  readonly children: ReactNode;
  readonly isOpen: boolean;
};

export const Drawer = ({ children, isOpen }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      height: "100%",
      width: "100%",
    },
  });

  return (
    <div
      style={{
        boxShadow: theme.elevations.depth5?.boxShadow,
        height: "100vh",
        position: "fixed",
        right: isOpen ? 0 : -720,
        top: 0,
        transition: "right 300ms",
        transitionTimingFunction: "ease-out",
        width: 720,
      }}
    >
      <View style={styles.container}>{children}</View>
    </div>
  );
};
