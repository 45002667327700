// Copyright 2023 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Body, Button, Heading, Select, TextInput, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { ErrorMessage, Formik } from "formik";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum as PermissionsActionEnum } from "../../gen/org-portal";
import { QueryKeyPrefix } from "@src/api/constants";
import { Spin } from "../../components";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../../components/Spacer";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type {
  CreateTemplateOperationRequest,
  GetDatasource200ResponseMappedTemplatesInner as Template,
} from "../../gen/org-portal";
import type { FormikProps } from "formik";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

export type FormValues = {
  readonly autoApprove: boolean;
  readonly name: string;
  readonly description: string;
  readonly templateType: string;
};

const { None, Some } = Helpers;

const TEMPLATE_TYPE_OPTIONS = [
  { label: "Merit", value: "Merit" },
  { label: "Folio", value: "Folio" },
];

export const CreateTemplateScreen = () => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const routes = useRoute<RouteProp<RouteParams, "CreateTemplate" | "ExtendTemplate">>();
  const { name, params } = routes;
  const { errorHandler } = useServerErrorHandler();
  const { configuration } = useLoadedConfigurationState();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();
  const ref = useRef<FormikProps<FormValues>>(null);
  const [template, setTemplate] = useState<Template>();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      margin: "auto",
      minWidth: 960,
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.subdued,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    wrapper: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
  });

  useEffect(() => {
    const getContainer = async (templateID: string, orgID: string) => {
      try {
        setIsLoading(true);
        const templateResponse = await api.getTemplate({
          orgID,
          templateID,
        });
        setTemplate(templateResponse);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (Some(params)) {
      getContainer(params.id, selectedOrgId);
    }
  }, [api, errorHandler, params, params?.id, selectedOrgId]);

  const validationSchema = Yup.object().shape({
    autoApprove: Yup.boolean().required("Please select auto approve option"),
    description: Yup.string().optional().trim().max(300, "300 maximum character limit"),
    name: Yup.string()
      .trim()
      .required("Template Name is required")
      .max(60, "60 maximum character limit"),
    templateType: Yup.string().required("Please select a Template Type"),
  });

  const errorText = (errorMsg: string, fieldName: string) => (
    <View style={{ paddingVertical: theme.spacing.s }}>
      {/* TODO: Implement 8 font size*/}
      <Body
        style={{ color: theme.colors.text.alert.critical, fontSize: theme.fontSizes.s.fontSize }}
        testProps={{
          elementName: `template${fieldName}FieldError`,
          screenName: name,
        }}
      >
        {errorMsg}
      </Body>
    </View>
  );

  const createTemplate = useCallback(
    async (payLoad: CreateTemplateOperationRequest) => {
      setIsLoading(true);
      try {
        const response = await api.createTemplate(payLoad);
        if (Some(response.id)) {
          setAlert({
            closable: true,
            customContent: <Body>Template has been saved.</Body>,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            type: "success",
          });
          navigation.navigate("ConfigureTemplate", {
            id: response.id,
          });
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
      queryClient.invalidateQueries({ queryKey: [QueryKeyPrefix.OrgPortalTemplates] });
    },
    [api, deleteAlert, errorHandler, navigation, setAlert, queryClient]
  );

  if (isLoading) {
    return (
      <View
        style={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  const handleFormSubmit = () => {
    if (Some(ref) && Some(ref.current)) {
      ref.current.handleSubmit();
    }
  };

  const closeScreen = () => {
    if (Some(ref) && Some(ref.current)) {
      if (ref.current.dirty) {
        setShowWarningModal(true);

        return;
      }
    }

    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Templates", {});
  };

  const defaultPermissions = [
    {
      action: PermissionsActionEnum.Read,
      permissibleToPermit: {
        action: PermissionsActionEnum.Read,
        grantedToName: "None",
      },
      permitted: { action: PermissionsActionEnum.Read, grantedToName: "None" },
    },
    {
      action: PermissionsActionEnum.Extend,
      permissibleToPermit: { action: PermissionsActionEnum.Extend, grantedToName: "None" },
      permitted: { action: PermissionsActionEnum.Extend, grantedToName: "None" },
    },
  ];

  const getTemplateType = (type: string) => {
    if (None(configuration)) {
      throw new Error("Somehow configurations are not found");
    }

    if (type === "Merit") {
      return configuration.baseMeritTemplateUUID;
    }

    if (type === "Folio") {
      return configuration.baseFolioTemplateUUID;
    }

    throw new Error(`Could not find the template type: ${type}`);
  };

  return (
    <>
      <FullScreenModalLayout
        onClose={closeScreen}
        testProps={{
          elementName: `${name === "ExtendTemplate" ? "extend" : "create"}Template`,
          screenName: name,
        }}
        title={name === "ExtendTemplate" ? `Extend ${template?.name ?? ""}` : "Create template"}
      >
        <View style={styles.wrapper}>
          <View style={styles.container}>
            <VerticalSpacer size={theme.spacing.xxl} />
            <Heading bold level="3">
              Template name and type
            </Heading>
            <Formik
              initialValues={{
                autoApprove: true,
                description: Some(template) ? template.description ?? "" : "",
                name: Some(template) ? template.name ?? "" : "",
                templateType:
                  TEMPLATE_TYPE_OPTIONS.find(({ value }) => value === template?.type)?.value ?? "",
              }}
              innerRef={ref}
              onSubmit={(values: FormValues) => {
                if (None(values.templateType) || None(values.name) || None(values.autoApprove)) {
                  return;
                }
                const payLoad: CreateTemplateOperationRequest = {
                  extendTemplateRequest: {
                    autoApprove: values.autoApprove,
                    description: values.description,
                    name: values.name,
                    permissions: [...defaultPermissions],
                  },
                  orgID: selectedOrgId,
                  templateID: Some(params) ? params.id : getTemplateType(values.templateType),
                };
                createTemplate(payLoad);
              }}
              validationSchema={validationSchema}
            >
              {({ handleBlur, handleChange, setFieldTouched, setFieldValue, values }) => (
                <>
                  <VerticalSpacer size={theme.spacing.xxl} />
                  <View>
                    <View
                      style={{
                        maxWidth: 468,
                      }}
                    >
                      <TextInput
                        label="Name *"
                        onBlur={() => {
                          setFieldTouched("name", true);
                          handleBlur("name");
                        }}
                        onChangeText={handleChange("name")}
                        placeholder="Name"
                        size="large"
                        testProps={{
                          elementName: "templateNameTextInput",
                          screenName: name,
                        }}
                        value={values.name}
                      />
                      <ErrorMessage name="name">{error => errorText(error, "Name")}</ErrorMessage>
                    </View>
                  </View>

                  <VerticalSpacer size={theme.spacing.xxl} />
                  <View>
                    <View style={{ minWidth: 960 }}>
                      <TextInput
                        label="Description"
                        numberOfLines={4}
                        onBlur={() => {
                          setFieldTouched("description", true);
                          handleBlur("description");
                        }}
                        onChangeText={handleChange("description")}
                        placeholder="Description"
                        size="large"
                        testProps={{
                          elementName: "templateDescriptionTextInput",
                          screenName: name,
                        }}
                        value={values.description}
                      />
                      <VerticalSpacer size={theme.spacing.s} />
                      {/* TODO: Implement 12 font size*/}
                      <Body>300 maximum character limit</Body>
                      <ErrorMessage name="description">
                        {error => errorText(error, "Description")}
                      </ErrorMessage>
                    </View>
                  </View>

                  <View style={{ maxWidth: 468, paddingTop: theme.spacing.xxl }}>
                    <Select
                      defaultValue={TEMPLATE_TYPE_OPTIONS.find(
                        ({ value }) => value === values.templateType
                      )}
                      disabled={name === "ExtendTemplate"}
                      label="Type *"
                      onSelectOption={option => {
                        setFieldTouched("templateType", true);
                        if (typeof option.value === "string") {
                          setFieldValue("templateType", option.value);
                        }
                      }}
                      options={TEMPLATE_TYPE_OPTIONS}
                      placeholder={{
                        label: "Template type",
                        value: "",
                      }}
                      size="large"
                      testProps={{
                        elementName: "templateType",
                        screenName: name,
                      }}
                      usePortal
                    />
                    <ErrorMessage name="templateType">
                      {error => errorText(error, "Type")}
                    </ErrorMessage>
                  </View>
                </>
              )}
            </Formik>
          </View>
          <View style={styles.footer}>
            <Button
              onPress={handleFormSubmit}
              size="medium"
              testProps={{
                elementName: "createTemplateButton",
                screenName: name,
              }}
              text="Create"
            />
          </View>
        </View>
      </FullScreenModalLayout>

      {showWarningModal && (
        <ConfirmationModal
          onClose={() => {
            setShowWarningModal(false);
          }}
          onOk={() => {
            setShowWarningModal(false);
            navigation.navigate("Templates", {});
          }}
          testProps={{
            elementName: "unSave",
            screenName: name,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </>
  );
};
