// Copyright 2024 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import type {
  DefaultApi,
  GetAllAgentsVersions200ResponseInner,
  GetAllAgentsVersionsRequest,
} from "@src/gen/org-portal";

const { None, Some } = Helpers;

type Pagination = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter: string | undefined;
  readonly prevEndBefore: string | undefined;
};

const initialPaginationInfo = {
  hasNextPage: false,
  hasPrevPage: false,
  nextStartAfter: undefined,
  prevEndBefore: undefined,
};

export const useAgentsVersionsData = (
  api: DefaultApi,
  requestParams: GetAllAgentsVersionsRequest
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState(requestParams);
  const { errorHandler } = useServerErrorHandler();
  const [agentsVersions, setAgentsVersions] = useState<
    readonly GetAllAgentsVersions200ResponseInner[]
  >([]);
  const [paginationData, setPaginationData] = useState<Pagination>(initialPaginationInfo);

  const noOfAgentsPerPage = (params.limit ?? 11) - 1;

  const refresh = (nextParams: Partial<GetAllAgentsVersionsRequest> = {}) => {
    if (isLoading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  const getCurrentPageAgentsVersions = useCallback(
    (response: readonly GetAllAgentsVersions200ResponseInner[]) =>
      response.length === params.limit
        ? response.slice(0, response.length - 1)
        : response.slice(0, response.length),
    [params.limit]
  );

  const fetchAgentsVersions = useCallback(
    async (payload: GetAllAgentsVersionsRequest) => {
      try {
        if (params.orgID === "") {
          throw new Error("Couldn't find Organization ID");
        }
        setIsLoading(true);
        const response = await api.getAllAgentsVersions(payload);

        if (response.length === 0) {
          return;
        }

        if (None(params.start) && None(params.end)) {
          const versions = getCurrentPageAgentsVersions(response);
          const nextPageRecord = versions[versions.length - 1];

          setPaginationData(prevState => ({
            ...prevState,
            hasNextPage: response.length > noOfAgentsPerPage,
            nextStartAfter: `${nextPageRecord.versions[nextPageRecord.versions.length - 1].name},${
              nextPageRecord.id
            }`,
          }));

          setAgentsVersions(versions);
        }

        if (Some(params.start)) {
          const versions = getCurrentPageAgentsVersions(response);
          const nextPageRecord = versions[versions.length - 1];
          const prevPageRecord = versions[0];

          setPaginationData(prevState => ({
            ...prevState,
            hasNextPage: response.length > noOfAgentsPerPage,
            hasPrevPage: true,
            nextStartAfter:
              response.length > noOfAgentsPerPage
                ? `${nextPageRecord.versions[nextPageRecord.versions.length - 1].name},${
                    nextPageRecord.id
                  }`
                : undefined,
            prevEndBefore: `${prevPageRecord.versions[prevPageRecord.versions.length - 1].name},${
              prevPageRecord.id
            }`,
          }));
          setAgentsVersions(versions);
        }

        if (Some(params.end)) {
          const versions = [...getCurrentPageAgentsVersions(response)].reverse();

          const nextPageRecord = versions[versions.length - 1];
          const prevPageRecord = versions[0];

          setPaginationData(prevState => ({
            ...prevState,
            hasNextPage: true,
            hasPrevPage: response.length > noOfAgentsPerPage,
            nextStartAfter: `${nextPageRecord.versions[nextPageRecord.versions.length - 1].name},${
              nextPageRecord.id
            }`,
            prevEndBefore:
              response.length > noOfAgentsPerPage
                ? `${prevPageRecord.versions[prevPageRecord.versions.length - 1].name},${
                    prevPageRecord.id
                  }`
                : undefined,
          }));
          setAgentsVersions(versions);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [api, errorHandler, getCurrentPageAgentsVersions, noOfAgentsPerPage, params]
  );

  useFocusEffect(
    useCallback(() => {
      fetchAgentsVersions(params);
    }, [fetchAgentsVersions, params])
  );

  const nextPage = () => {
    setParams(prevState => ({
      ...prevState,
      end: undefined,
      start: paginationData.nextStartAfter,
    }));
  };

  const prevPage = () => {
    setParams(prevState => ({
      ...prevState,
      end: paginationData.prevEndBefore,
      start: undefined,
    }));
  };

  return {
    agentsVersions,
    isLoading,
    nextPage,
    paginationData,
    prevPage,
    refresh,
  };
};
