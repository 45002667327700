/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListTemplates200ResponseTemplatesInner } from './ListTemplates200ResponseTemplatesInner';
import {
    ListTemplates200ResponseTemplatesInnerFromJSON,
    ListTemplates200ResponseTemplatesInnerFromJSONTyped,
    ListTemplates200ResponseTemplatesInnerToJSON,
} from './ListTemplates200ResponseTemplatesInner';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * A paginated list of templates
 * @export
 * @interface ListTemplates200Response
 */
export interface ListTemplates200Response {
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200Response
     */
    ownerOrgID: string;
    /**
     * 
     * @type {string}
     * @memberof ListTemplates200Response
     */
    orgID: string;
    /**
     * 
     * @type {Array<ListTemplates200ResponseTemplatesInner>}
     * @memberof ListTemplates200Response
     */
    templates: Array<ListTemplates200ResponseTemplatesInner>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof ListTemplates200Response
     */
    paginationInfo?: PaginationInfo;
}

/**
 * Check if a given object implements the ListTemplates200Response interface.
 */
export function instanceOfListTemplates200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ownerOrgID" in value;
    isInstance = isInstance && "orgID" in value;
    isInstance = isInstance && "templates" in value;

    return isInstance;
}

export function ListTemplates200ResponseFromJSON(json: any): ListTemplates200Response {
    return ListTemplates200ResponseFromJSONTyped(json, false);
}

export function ListTemplates200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTemplates200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ownerOrgID': json['OwnerOrgID'],
        'orgID': json['OrgID'],
        'templates': ((json['templates'] as Array<any>).map(ListTemplates200ResponseTemplatesInnerFromJSON)),
        'paginationInfo': !exists(json, 'PaginationInfo') ? undefined : PaginationInfoFromJSON(json['PaginationInfo']),
    };
}

export function ListTemplates200ResponseToJSON(value?: ListTemplates200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OwnerOrgID': value.ownerOrgID,
        'OrgID': value.orgID,
        'templates': ((value.templates as Array<any>).map(ListTemplates200ResponseTemplatesInnerToJSON)),
        'PaginationInfo': PaginationInfoToJSON(value.paginationInfo),
    };
}

