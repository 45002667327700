/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgents200ResponseAgentsInnerAuth0Options } from './GetAgents200ResponseAgentsInnerAuth0Options';
import {
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON,
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSONTyped,
    GetAgents200ResponseAgentsInnerAuth0OptionsToJSON,
} from './GetAgents200ResponseAgentsInnerAuth0Options';
import type { GetAgents200ResponseAgentsInnerCapabilitiesInner } from './GetAgents200ResponseAgentsInnerCapabilitiesInner';
import {
    GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSON,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSONTyped,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerToJSON,
} from './GetAgents200ResponseAgentsInnerCapabilitiesInner';

/**
 * An existing or newly created agent
 * @export
 * @interface UpdateAgent200Response
 */
export interface UpdateAgent200Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateAgent200Response
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    accessType?: UpdateAgent200ResponseAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    state?: UpdateAgent200ResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    status?: UpdateAgent200ResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    description?: string;
    /**
     * The org that owns this agent.
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    org?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    privacyTos?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    usageTos?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    dataTos?: string;
    /**
     * The capabilities that this agent requires
     * @type {Array<GetAgents200ResponseAgentsInnerCapabilitiesInner>}
     * @memberof UpdateAgent200Response
     */
    capabilities?: Array<GetAgents200ResponseAgentsInnerCapabilitiesInner>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateAgent200Response
     */
    additionals?: { [key: string]: string; };
    /**
     * The Auth0 client ID for an agent
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    auth0ClientId?: string;
    /**
     * The Auth0 client secret for an agent
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    auth0ClientSecret?: string;
    /**
     * 
     * @type {GetAgents200ResponseAgentsInnerAuth0Options}
     * @memberof UpdateAgent200Response
     */
    auth0Options?: GetAgents200ResponseAgentsInnerAuth0Options | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgent200Response
     */
    approvedVersionID?: string;
}


/**
 * @export
 */
export const UpdateAgent200ResponseAccessTypeEnum = {
    Login: 'login',
    Link: 'link',
    Webapp: 'webapp',
    Native: 'native'
} as const;
export type UpdateAgent200ResponseAccessTypeEnum = typeof UpdateAgent200ResponseAccessTypeEnum[keyof typeof UpdateAgent200ResponseAccessTypeEnum];

/**
 * @export
 */
export const UpdateAgent200ResponseStateEnum = {
    Live: 'live',
    Removed: 'removed',
    PendingApproval: 'pending_approval'
} as const;
export type UpdateAgent200ResponseStateEnum = typeof UpdateAgent200ResponseStateEnum[keyof typeof UpdateAgent200ResponseStateEnum];

/**
 * @export
 */
export const UpdateAgent200ResponseStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type UpdateAgent200ResponseStatusEnum = typeof UpdateAgent200ResponseStatusEnum[keyof typeof UpdateAgent200ResponseStatusEnum];


/**
 * Check if a given object implements the UpdateAgent200Response interface.
 */
export function instanceOfUpdateAgent200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateAgent200ResponseFromJSON(json: any): UpdateAgent200Response {
    return UpdateAgent200ResponseFromJSONTyped(json, false);
}

export function UpdateAgent200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAgent200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'accessType': !exists(json, 'accessType') ? undefined : json['accessType'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'org': !exists(json, 'org') ? undefined : json['org'],
        'privacyTos': !exists(json, 'privacyTos') ? undefined : json['privacyTos'],
        'usageTos': !exists(json, 'usageTos') ? undefined : json['usageTos'],
        'dataTos': !exists(json, 'dataTos') ? undefined : json['dataTos'],
        'capabilities': !exists(json, 'capabilities') ? undefined : ((json['capabilities'] as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSON)),
        'additionals': !exists(json, 'additionals') ? undefined : json['additionals'],
        'auth0ClientId': !exists(json, 'auth0ClientId') ? undefined : json['auth0ClientId'],
        'auth0ClientSecret': !exists(json, 'auth0ClientSecret') ? undefined : json['auth0ClientSecret'],
        'auth0Options': !exists(json, 'auth0Options') ? undefined : GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON(json['auth0Options']),
        'approvedVersionID': !exists(json, 'approvedVersionID') ? undefined : json['approvedVersionID'],
    };
}

export function UpdateAgent200ResponseToJSON(value?: UpdateAgent200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'accessType': value.accessType,
        'state': value.state,
        'status': value.status,
        'name': value.name,
        'description': value.description,
        'org': value.org,
        'privacyTos': value.privacyTos,
        'usageTos': value.usageTos,
        'dataTos': value.dataTos,
        'capabilities': value.capabilities === undefined ? undefined : ((value.capabilities as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerToJSON)),
        'additionals': value.additionals,
        'auth0ClientId': value.auth0ClientId,
        'auth0ClientSecret': value.auth0ClientSecret,
        'auth0Options': GetAgents200ResponseAgentsInnerAuth0OptionsToJSON(value.auth0Options),
        'approvedVersionID': value.approvedVersionID,
    };
}

