/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Updating the mapping of a template field to a column.
 * @export
 * @interface EditTemplateMapRequestColumnToTemplateFieldInner
 */
export interface EditTemplateMapRequestColumnToTemplateFieldInner {
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMapRequestColumnToTemplateFieldInner
     */
    templateFieldID: string;
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMapRequestColumnToTemplateFieldInner
     */
    columnID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMapRequestColumnToTemplateFieldInner
     */
    containerID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMapRequestColumnToTemplateFieldInner
     */
    containerFieldID?: string | null;
}

/**
 * Check if a given object implements the EditTemplateMapRequestColumnToTemplateFieldInner interface.
 */
export function instanceOfEditTemplateMapRequestColumnToTemplateFieldInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateFieldID" in value;

    return isInstance;
}

export function EditTemplateMapRequestColumnToTemplateFieldInnerFromJSON(json: any): EditTemplateMapRequestColumnToTemplateFieldInner {
    return EditTemplateMapRequestColumnToTemplateFieldInnerFromJSONTyped(json, false);
}

export function EditTemplateMapRequestColumnToTemplateFieldInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTemplateMapRequestColumnToTemplateFieldInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldID': json['templateFieldID'],
        'columnID': !exists(json, 'columnID') ? undefined : json['columnID'],
        'containerID': !exists(json, 'containerID') ? undefined : json['containerID'],
        'containerFieldID': !exists(json, 'containerFieldID') ? undefined : json['containerFieldID'],
    };
}

export function EditTemplateMapRequestColumnToTemplateFieldInnerToJSON(value?: EditTemplateMapRequestColumnToTemplateFieldInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldID': value.templateFieldID,
        'columnID': value.columnID,
        'containerID': value.containerID,
        'containerFieldID': value.containerFieldID,
    };
}

