/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of a search query.
 * @export
 * @interface ContainerFieldQuery
 */
export interface ContainerFieldQuery {
    /**
     * the ID of the exact container field to search
     * @type {string}
     * @memberof ContainerFieldQuery
     */
    templateFieldID?: string | null;
    /**
     * the base field for all fields in scope for the query
     * @type {string}
     * @memberof ContainerFieldQuery
     */
    baseTemplateFieldID?: string | null;
    /**
     * the value of the search query
     * @type {string}
     * @memberof ContainerFieldQuery
     */
    query?: string;
    /**
     * whether to match only full, exact values
     * @type {boolean}
     * @memberof ContainerFieldQuery
     */
    exactMatch?: boolean;
}

/**
 * Check if a given object implements the ContainerFieldQuery interface.
 */
export function instanceOfContainerFieldQuery(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContainerFieldQueryFromJSON(json: any): ContainerFieldQuery {
    return ContainerFieldQueryFromJSONTyped(json, false);
}

export function ContainerFieldQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerFieldQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldID': !exists(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'baseTemplateFieldID': !exists(json, 'baseTemplateFieldID') ? undefined : json['baseTemplateFieldID'],
        'query': !exists(json, 'query') ? undefined : json['query'],
        'exactMatch': !exists(json, 'exactMatch') ? undefined : json['exactMatch'],
    };
}

export function ContainerFieldQueryToJSON(value?: ContainerFieldQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldID': value.templateFieldID,
        'baseTemplateFieldID': value.baseTemplateFieldID,
        'query': value.query,
        'exactMatch': value.exactMatch,
    };
}

