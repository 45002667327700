// Copyright 2024 Merit International Inc. All Rights Reserved

import { AppDetails } from "../AppDetails/AppDetails";
import { AppsTable } from "../AppTable/AppsTable";
import { Drawer } from "../../../components/Drawer";
import { GetAllAgentsVersionsSortByEnum } from "@src/gen/org-portal";
import { Helpers } from "@merit/frontend-utils";
import { Pagination, Spin } from "../../../components";
import { View } from "react-native";
import { useAgentsVersionsData } from "./useAgentsVersionsData";
import { useApi } from "../../../api/api";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useState } from "react";

const { Some } = Helpers;

// Fetching an additional record to manage pagination since the response does not include a cursor.
const AGENTS_LIMIT = 11;

export const MyApps = () => {
  const { api } = useApi();

  const { selectedOrgId } = useLoggedInAuthState();
  const [selectedAppID, setSelectedAppID] = useState<string>();

  const { agentsVersions, isLoading, nextPage, paginationData, prevPage, refresh } =
    useAgentsVersionsData(api, {
      includeApprovedAgents: true,
      includeApprovedVersions: true,
      limit: AGENTS_LIMIT,
      orgID: selectedOrgId,
      ownerID: selectedOrgId,
      sortBy: GetAllAgentsVersionsSortByEnum.Name,
    });

  return (
    <>
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Spin />
        </View>
      ) : (
        <AppsTable
          appsList={agentsVersions}
          onPressView={id => {
            setSelectedAppID(id);
          }}
        />
      )}
      <Pagination
        disableNext={!paginationData.hasNextPage || isLoading}
        disablePrev={!paginationData.hasPrevPage || isLoading}
        onNext={nextPage}
        onPrev={prevPage}
      />
      <Drawer isOpen={Some(selectedAppID)}>
        <AppDetails
          appID={selectedAppID}
          appsListType="own"
          onClose={() => {
            setSelectedAppID(undefined);
          }}
          onStatusChange={refresh}
        />
      </Drawer>
    </>
  );
};
