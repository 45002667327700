/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  ExtendPolicyProperties,
  Policy,
  PolicyResponse,
  PolicyUpdate,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    ExtendPolicyPropertiesFromJSON,
    ExtendPolicyPropertiesToJSON,
    PolicyFromJSON,
    PolicyToJSON,
    PolicyResponseFromJSON,
    PolicyResponseToJSON,
    PolicyUpdateFromJSON,
    PolicyUpdateToJSON,
} from '../models/index';

export interface ExtendPolicyRequest {
    properties: ExtendPolicyProperties;
    authorization?: string;
}

export interface GetPoliciesRequest {
    authorization?: string;
    owner?: string;
    state?: GetPoliciesStateEnum;
    startingAfter?: string;
    endingBefore?: string;
    limit?: number;
    sortBy?: GetPoliciesSortByEnum;
}

export interface GetPolicyRequest {
    policyID: string;
    authorization?: string;
}

export interface UpdatePolicyRequest {
    policyID: string;
    policy: PolicyUpdate;
    authorization?: string;
}

/**
 * 
 */
export class PoliciesApi extends runtime.BaseAPI {

    /**
     * `extend:policy`  Create a new policy by extending an existing policy 
     * Extend Policy
     */
    async extendPolicyRaw(requestParameters: ExtendPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Policy>> {
        if (requestParameters.properties === null || requestParameters.properties === undefined) {
            throw new runtime.RequiredError('properties','Required parameter requestParameters.properties was null or undefined when calling extendPolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtendPolicyPropertiesToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyFromJSON(jsonValue));
    }

    /**
     * `extend:policy`  Create a new policy by extending an existing policy 
     * Extend Policy
     */
    async extendPolicy(requestParameters: ExtendPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Policy> {
        const response = await this.extendPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:policy`  Get a paginated list of policies 
     * Get Policies
     */
    async getPoliciesRaw(requestParameters: GetPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolicyResponse>> {
        const queryParameters: any = {};

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyResponseFromJSON(jsonValue));
    }

    /**
     * `read:policy`  Get a paginated list of policies 
     * Get Policies
     */
    async getPolicies(requestParameters: GetPoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolicyResponse> {
        const response = await this.getPoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:policy`  Get a single policy 
     * Get Policy
     */
    async getPolicyRaw(requestParameters: GetPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Policy>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling getPolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/policies/{policyID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyFromJSON(jsonValue));
    }

    /**
     * `read:policy`  Get a single policy 
     * Get Policy
     */
    async getPolicy(requestParameters: GetPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Policy> {
        const response = await this.getPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:policy`  Update an existing policy 
     * Update Policy
     */
    async updatePolicyRaw(requestParameters: UpdatePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Policy>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling updatePolicy.');
        }

        if (requestParameters.policy === null || requestParameters.policy === undefined) {
            throw new runtime.RequiredError('policy','Required parameter requestParameters.policy was null or undefined when calling updatePolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/policies/{policyID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PolicyUpdateToJSON(requestParameters.policy),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyFromJSON(jsonValue));
    }

    /**
     * `update:policy`  Update an existing policy 
     * Update Policy
     */
    async updatePolicy(requestParameters: UpdatePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Policy> {
        const response = await this.updatePolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetPoliciesStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type GetPoliciesStateEnum = typeof GetPoliciesStateEnum[keyof typeof GetPoliciesStateEnum];
/**
 * @export
 */
export const GetPoliciesSortByEnum = {
    Name: 'name',
    Owner: 'owner',
    CreatedAt: 'createdAt'
} as const;
export type GetPoliciesSortByEnum = typeof GetPoliciesSortByEnum[keyof typeof GetPoliciesSortByEnum];
