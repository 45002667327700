// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { StyleSheet, View } from "react-native";
import type { OPTestProps } from "../../types/TestProps";

type Props = {
  readonly onCancel: () => void;
  readonly onSave: () => void;
  readonly testProps?: OPTestProps;
};

const { Some } = Helpers;

export const Footer = ({ onCancel, onSave, testProps }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: theme.spacing.l,
    },
  });

  return (
    <View style={styles.container}>
      <View style={{ width: 92 }}>
        <Button
          onPress={onCancel}
          testProps={
            Some(testProps)
              ? {
                  ...testProps,
                  elementName: `${testProps.elementName}CancelButton`,
                }
              : testProps
          }
          text="Cancel"
          type="secondary"
        />
      </View>
      <HorizontalSpacer />
      <View style={{ width: 79 }}>
        <Button
          onPress={onSave}
          testProps={
            Some(testProps)
              ? {
                  ...testProps,
                  elementName: `${testProps.elementName}SaveButton`,
                }
              : testProps
          }
          text="Save"
        />
      </View>
    </View>
  );
};
