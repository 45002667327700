/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnProperties } from './ColumnProperties';
import {
    ColumnPropertiesFromJSON,
    ColumnPropertiesFromJSONTyped,
    ColumnPropertiesToJSON,
} from './ColumnProperties';

/**
 * An Org's Schema contains Columns that describe the layout of Records.
 * @export
 * @interface CreateDataSourceWithSchema
 */
export interface CreateDataSourceWithSchema {
    /**
     * The name of the Data Source.
     * @type {string}
     * @memberof CreateDataSourceWithSchema
     */
    name: string;
    /**
     * The description of the Data Source.
     * @type {string}
     * @memberof CreateDataSourceWithSchema
     */
    description?: string;
    /**
     * The delimiter used for separating values in a CSV/TSV/etc file
     * @type {string}
     * @memberof CreateDataSourceWithSchema
     */
    delimiter?: CreateDataSourceWithSchemaDelimiterEnum;
    /**
     * The columns that describe the layout of Records associated with this Schema.
     * @type {Array<ColumnProperties>}
     * @memberof CreateDataSourceWithSchema
     */
    columns: Array<ColumnProperties>;
}


/**
 * @export
 */
export const CreateDataSourceWithSchemaDelimiterEnum = {
    Comma: 'comma',
    Tab: 'tab',
    Pipe: 'pipe'
} as const;
export type CreateDataSourceWithSchemaDelimiterEnum = typeof CreateDataSourceWithSchemaDelimiterEnum[keyof typeof CreateDataSourceWithSchemaDelimiterEnum];


/**
 * Check if a given object implements the CreateDataSourceWithSchema interface.
 */
export function instanceOfCreateDataSourceWithSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "columns" in value;

    return isInstance;
}

export function CreateDataSourceWithSchemaFromJSON(json: any): CreateDataSourceWithSchema {
    return CreateDataSourceWithSchemaFromJSONTyped(json, false);
}

export function CreateDataSourceWithSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDataSourceWithSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'delimiter': !exists(json, 'delimiter') ? undefined : json['delimiter'],
        'columns': ((json['columns'] as Array<any>).map(ColumnPropertiesFromJSON)),
    };
}

export function CreateDataSourceWithSchemaToJSON(value?: CreateDataSourceWithSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'delimiter': value.delimiter,
        'columns': ((value.columns as Array<any>).map(ColumnPropertiesToJSON)),
    };
}

