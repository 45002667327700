/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Column } from './Column';
import {
    ColumnFromJSON,
    ColumnFromJSONTyped,
    ColumnToJSON,
} from './Column';
import type { DataSourceAllOfSchema } from './DataSourceAllOfSchema';
import {
    DataSourceAllOfSchemaFromJSON,
    DataSourceAllOfSchemaFromJSONTyped,
    DataSourceAllOfSchemaToJSON,
} from './DataSourceAllOfSchema';

/**
 * An Orgs Datasource
 * @export
 * @interface DataSource
 */
export interface DataSource {
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    description?: string;
    /**
     * The columns that describe the layout of Records associated with this Datasource.
     * @type {Array<Column>}
     * @memberof DataSource
     */
    columns: Array<Column>;
    /**
     * 
     * @type {DataSourceAllOfSchema}
     * @memberof DataSource
     */
    schema?: DataSourceAllOfSchema;
    /**
     * DataSource creation timestamp
     * @type {Date}
     * @memberof DataSource
     */
    createdAt?: Date;
    /**
     * Last time DataSource was updated
     * @type {Date}
     * @memberof DataSource
     */
    updatedAt?: Date;
    /**
     * Last time a column on a datasource was updated
     * @type {Date}
     * @memberof DataSource
     */
    columnUpdatedAt?: Date | null;
    /**
     * Timestamp for most recent ingestion. Reported for all ingestions regardless of type. The time reported is 
     * the time that an ingestion started.
     * 
     * @type {Date}
     * @memberof DataSource
     */
    lastIngestion?: Date | null;
    /**
     * Timestamp for the most recent record ingested that had an error
     * @type {Date}
     * @memberof DataSource
     */
    lastError?: Date | null;
    /**
     * The type of data this data source expects to ingest. Three options exist for file uploads, 
     * comma-separated values (csv), tab separated values (tsv) and pipe (|) separate values (psv).
     * Data sources may also ingest data from an integration, in which case the type is simply
     * integration.
     * 
     * @type {string}
     * @memberof DataSource
     */
    dataSourceType?: DataSourceDataSourceTypeEnum | null;
    /**
     * The status of the integration this data source is connected to. This field is empty if the data source
     * is not mapped to an integration. A paused integration is connected but not currently ingesting data; a
     * queued integration is scheduled to ingest data; an ingesting integration is currently ingesting data
     * into the data source; and a not connected integration is not ingesting data, possibly due to an error.
     * An unknown status may occur briefly appear mapping a data source to an integration.
     * 
     * @type {string}
     * @memberof DataSource
     */
    integrationConnectionStatus?: DataSourceIntegrationConnectionStatusEnum | null;
}


/**
 * @export
 */
export const DataSourceDataSourceTypeEnum = {
    Csv: 'csv',
    Tsv: 'tsv',
    Psv: 'psv',
    Integration: 'integration'
} as const;
export type DataSourceDataSourceTypeEnum = typeof DataSourceDataSourceTypeEnum[keyof typeof DataSourceDataSourceTypeEnum];

/**
 * @export
 */
export const DataSourceIntegrationConnectionStatusEnum = {
    ConnectedPaused: 'connected (paused)',
    ConnectedQueued: 'connected (queued)',
    ConnectedIngesting: 'connected (ingesting)',
    NotConnected: 'not connected',
    Unknown: 'unknown'
} as const;
export type DataSourceIntegrationConnectionStatusEnum = typeof DataSourceIntegrationConnectionStatusEnum[keyof typeof DataSourceIntegrationConnectionStatusEnum];


/**
 * Check if a given object implements the DataSource interface.
 */
export function instanceOfDataSource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "columns" in value;

    return isInstance;
}

export function DataSourceFromJSON(json: any): DataSource {
    return DataSourceFromJSONTyped(json, false);
}

export function DataSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'columns': ((json['columns'] as Array<any>).map(ColumnFromJSON)),
        'schema': !exists(json, 'schema') ? undefined : DataSourceAllOfSchemaFromJSON(json['schema']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'columnUpdatedAt': !exists(json, 'columnUpdatedAt') ? undefined : (json['columnUpdatedAt'] === null ? null : new Date(json['columnUpdatedAt'])),
        'lastIngestion': !exists(json, 'lastIngestion') ? undefined : (json['lastIngestion'] === null ? null : new Date(json['lastIngestion'])),
        'lastError': !exists(json, 'lastError') ? undefined : (json['lastError'] === null ? null : new Date(json['lastError'])),
        'dataSourceType': !exists(json, 'dataSourceType') ? undefined : json['dataSourceType'],
        'integrationConnectionStatus': !exists(json, 'integrationConnectionStatus') ? undefined : json['integrationConnectionStatus'],
    };
}

export function DataSourceToJSON(value?: DataSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'columns': ((value.columns as Array<any>).map(ColumnToJSON)),
        'schema': DataSourceAllOfSchemaToJSON(value.schema),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'columnUpdatedAt': value.columnUpdatedAt === undefined ? undefined : (value.columnUpdatedAt === null ? null : value.columnUpdatedAt.toISOString()),
        'lastIngestion': value.lastIngestion === undefined ? undefined : (value.lastIngestion === null ? null : value.lastIngestion.toISOString()),
        'lastError': value.lastError === undefined ? undefined : (value.lastError === null ? null : value.lastError.toISOString()),
        'dataSourceType': value.dataSourceType,
        'integrationConnectionStatus': value.integrationConnectionStatus,
    };
}

