// Copyright 2024 Merit International Inc. All Rights Reserved

import { EllipsisText } from "@src/components";
import { FieldHandle } from "./FieldHandle";
import { Position } from "reactflow";
import { View } from "react-native";
import { usePreviewDataSourceStyles } from "../Datasource/styles";
import type { Node } from "reactflow";

export const FieldNode = ({ data, targetPosition }: Omit<Node, "position">) => {
  const styles = usePreviewDataSourceStyles();

  return (
    <div className="nodrag">
      <View style={[styles.fakeField, { minWidth: 207, paddingTop: 10 }]}>
        <EllipsisText text={data.label} />
      </View>

      <FieldHandle id={data.label} position={targetPosition ?? Position.Left} type={data.type} />
    </div>
  );
};
