/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgentVersion } from './AgentVersion';
import {
    AgentVersionFromJSON,
    AgentVersionFromJSONTyped,
    AgentVersionToJSON,
} from './AgentVersion';

/**
 * Current approved and pending versions
 * @export
 * @interface AgentVersions
 */
export interface AgentVersions {
    /**
     * The agent's unique identifier
     * @type {string}
     * @memberof AgentVersions
     */
    id: string;
    /**
     * The unique Identifier of the agent's owner
     * @type {string}
     * @memberof AgentVersions
     */
    owner: string;
    /**
     * The agent state
     * @type {string}
     * @memberof AgentVersions
     */
    state?: AgentVersionsStateEnum;
    /**
     * Whether the agent is able to make requests
     * @type {string}
     * @memberof AgentVersions
     */
    status?: AgentVersionsStatusEnum;
    /**
     * 
     * @type {Array<AgentVersion>}
     * @memberof AgentVersions
     */
    versions?: Array<AgentVersion>;
}


/**
 * @export
 */
export const AgentVersionsStateEnum = {
    Live: 'live',
    PendingApproval: 'pending_approval'
} as const;
export type AgentVersionsStateEnum = typeof AgentVersionsStateEnum[keyof typeof AgentVersionsStateEnum];

/**
 * @export
 */
export const AgentVersionsStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type AgentVersionsStatusEnum = typeof AgentVersionsStatusEnum[keyof typeof AgentVersionsStatusEnum];


/**
 * Check if a given object implements the AgentVersions interface.
 */
export function instanceOfAgentVersions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;

    return isInstance;
}

export function AgentVersionsFromJSON(json: any): AgentVersions {
    return AgentVersionsFromJSONTyped(json, false);
}

export function AgentVersionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentVersions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'versions': !exists(json, 'versions') ? undefined : ((json['versions'] as Array<any>).map(AgentVersionFromJSON)),
    };
}

export function AgentVersionsToJSON(value?: AgentVersions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner': value.owner,
        'state': value.state,
        'status': value.status,
        'versions': value.versions === undefined ? undefined : ((value.versions as Array<any>).map(AgentVersionToJSON)),
    };
}

