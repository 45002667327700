// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Pressable, StyleSheet, View } from "react-native";
import { getPredicateReadableName } from "../../utils/getPredicateReadableName";
import { getRuleArguments } from "../../utils/getRuleArguments";
import { useNavigation } from "@react-navigation/core";
import capitalize from "lodash/capitalize";
import type {
  OrgsGet200ResponseContainersInnerCompletenessFailuresInner as FailureRule,
  GetTemplateRules200ResponseRules,
  OrgsGet200ResponseContainersInnerFieldsInner,
} from "@src/gen/org-portal";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { OPTestProps } from "../../types/TestProps";
import type { RouteParams } from "../../Router";

const { None, Some } = Helpers;

type Props = {
  readonly templateFields: readonly OrgsGet200ResponseContainersInnerFieldsInner[];
  readonly failureRules: readonly FailureRule[];
  readonly predicateRules: GetTemplateRules200ResponseRules;
  readonly rulesType?: "activeness" | "completeness" | "field";
  readonly closeDrawer?: () => void;
  readonly testProps: OPTestProps;
};

export const FailuresList = ({
  closeDrawer,
  failureRules,
  predicateRules,
  rulesType = "activeness",
  templateFields,
  testProps,
}: Props) => {
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "MapDataSource">>();
  const styles = StyleSheet.create({
    container: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
    },
  });

  const getTemplateField = (fieldId: string | undefined) => {
    const templateField = templateFields.find(({ templateFieldID }) => templateFieldID === fieldId);

    if (None(templateField)) {
      throw new Error(`Somehow field is not found with the id ${fieldId ?? ""}`);
    }

    return templateField;
  };

  const getPredicate = (rule: FailureRule) => {
    const predicate = getPredicateReadableName(
      getTemplateField(rule.templateFieldID).fieldKind?.fieldType ?? "",
      rule.atom?.predicate ?? "",
      predicateRules
    );

    return Some(predicate) ? (
      <>
        <Heading
          level="5"
          testProps={{
            ...testProps,
            elementId: rule.templateFieldID,
            elementName: `${testProps.elementName}Predicate`,
          }}
        >
          {predicate}
        </Heading>
        <HorizontalSpacer size={theme.spacing.m} />
      </>
    ) : undefined;
  };

  const getArgumentValue = (rule: FailureRule) => {
    if (None(rule.atom)) {
      throw new Error("Somehow atom is not found");
    }

    if (None(rule.atom.predicate)) {
      throw new Error("Somehow predicate is not found");
    }

    return getRuleArguments(rule.atom.predicate, rule.atom.arguments);
  };

  const getTemplateFieldValue = (templateFieldID: string | undefined) => {
    const value = getTemplateField(templateFieldID).value;
    if (None(value) || value === "") {
      return "--";
    }

    return value;
  };

  return (
    <>
      {failureRules.map(rule => (
        <View key={rule.templateFieldID} style={styles.container}>
          <VerticalSpacer />
          <Body>
            <Body
              testProps={{
                ...testProps,
                elementId: rule.templateFieldID,
                elementName: `${testProps.elementName}FieldName`,
              }}
            >
              If the {getTemplateField(rule.templateFieldID).name}{" "}
            </Body>
            <HorizontalSpacer size={theme.spacing.m} />
            {getPredicate(rule)}
            <Heading
              color={theme.colors.border.success.default}
              level="5"
              testProps={{
                ...testProps,
                elementId: rule.templateFieldID,
                elementName: `${testProps.elementName}Argument`,
              }}
            >
              {getArgumentValue(rule)}
            </Heading>
            <HorizontalSpacer size={theme.spacing.m} />
            <Body>then validate</Body>
          </Body>

          <VerticalSpacer />

          <Body>
            <Body color={theme.colors.text.alert.critical}>{`Error message: `}</Body>
            <Body
              testProps={{
                ...testProps,
                elementId: rule.templateFieldID,
                elementName: `${testProps.elementName}ErrorMessage`,
              }}
            >
              {Some(rule.message) && rule.message !== ""
                ? capitalize(rule.message)
                : "An error has occurred"}
            </Body>
          </Body>

          <VerticalSpacer />

          {rulesType === "field" ? (
            <>
              <Body
                testProps={{
                  ...testProps,
                  elementId: rule.templateFieldID,
                  elementName: `${testProps.elementName}FieldValue`,
                }}
              >
                <Heading level="5">Value: </Heading>
                {getTemplateFieldValue(rule.templateFieldID)}
              </Body>

              <VerticalSpacer />

              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ alignItems: "center", flexDirection: "row" }}>
                  <Body
                    testProps={{
                      ...testProps,
                      elementId: rule.templateFieldID,
                      elementName: `${testProps.elementName}TemplateFieldID`,
                    }}
                  >
                    Template field ID: {rule.templateFieldID}
                  </Body>
                  <HorizontalSpacer size={6} />
                  <Heading color={theme.colors.text.subdued} level="6">
                    |
                  </Heading>
                  <HorizontalSpacer size={6} />
                  <Body
                    testProps={{
                      ...testProps,
                      elementId: rule.templateFieldID,
                      elementName: `${testProps.elementName}TemplateFieldDataType`,
                    }}
                  >
                    Data type: {getTemplateField(rule.templateFieldID).fieldKind?.fieldType}
                  </Body>
                </View>
                <Pressable
                  onPress={() => {
                    navigation.navigate("Fields", { fieldId: rule.fieldKindID ?? "" });
                    if (Some(closeDrawer)) {
                      closeDrawer();
                    }
                  }}
                  {...Helpers.generateTestIdProps({
                    ...testProps,
                    elementId: rule.templateFieldID,
                    elementName: `${testProps.elementName}FieldViewLink`,
                  })}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Body color={theme.colors.border.highlight.default}>View</Body>
                    <HorizontalSpacer size={theme.spacing.s} />
                    <Icon name="arrowExpandMediumHighlight" />
                  </View>
                </Pressable>
              </View>
            </>
          ) : (
            <Body
              testProps={{
                ...testProps,
                elementId: rule.templateFieldID,
                elementName: `${testProps.elementName}FieldValue`,
              }}
            >
              <Heading level="5">Field value: </Heading>
              {getTemplateFieldValue(rule.templateFieldID)}
            </Body>
          )}

          <VerticalSpacer />
        </View>
      ))}
    </>
  );
};
