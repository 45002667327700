/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetLinkedOrgs200ResponseLinkedOrgsInner } from './GetLinkedOrgs200ResponseLinkedOrgsInner';
import {
    GetLinkedOrgs200ResponseLinkedOrgsInnerFromJSON,
    GetLinkedOrgs200ResponseLinkedOrgsInnerFromJSONTyped,
    GetLinkedOrgs200ResponseLinkedOrgsInnerToJSON,
} from './GetLinkedOrgs200ResponseLinkedOrgsInner';

/**
 * 
 * @export
 * @interface GetLinkedOrgs200Response
 */
export interface GetLinkedOrgs200Response {
    /**
     * 
     * @type {string}
     * @memberof GetLinkedOrgs200Response
     */
    orgID: string;
    /**
     * 
     * @type {Array<GetLinkedOrgs200ResponseLinkedOrgsInner>}
     * @memberof GetLinkedOrgs200Response
     */
    linkedOrgs: Array<GetLinkedOrgs200ResponseLinkedOrgsInner>;
}

/**
 * Check if a given object implements the GetLinkedOrgs200Response interface.
 */
export function instanceOfGetLinkedOrgs200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgID" in value;
    isInstance = isInstance && "linkedOrgs" in value;

    return isInstance;
}

export function GetLinkedOrgs200ResponseFromJSON(json: any): GetLinkedOrgs200Response {
    return GetLinkedOrgs200ResponseFromJSONTyped(json, false);
}

export function GetLinkedOrgs200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLinkedOrgs200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgID': json['OrgID'],
        'linkedOrgs': ((json['LinkedOrgs'] as Array<any>).map(GetLinkedOrgs200ResponseLinkedOrgsInnerFromJSON)),
    };
}

export function GetLinkedOrgs200ResponseToJSON(value?: GetLinkedOrgs200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OrgID': value.orgID,
        'LinkedOrgs': ((value.linkedOrgs as Array<any>).map(GetLinkedOrgs200ResponseLinkedOrgsInnerToJSON)),
    };
}

