/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAllAgentsVersions200ResponseInnerVersionsInner } from './GetAllAgentsVersions200ResponseInnerVersionsInner';
import {
    GetAllAgentsVersions200ResponseInnerVersionsInnerFromJSON,
    GetAllAgentsVersions200ResponseInnerVersionsInnerFromJSONTyped,
    GetAllAgentsVersions200ResponseInnerVersionsInnerToJSON,
} from './GetAllAgentsVersions200ResponseInnerVersionsInner';

/**
 * An existing or newly created agent
 * @export
 * @interface GetAllAgentsVersions200ResponseInner
 */
export interface GetAllAgentsVersions200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInner
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInner
     */
    orgName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInner
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInner
     */
    status: string;
    /**
     * 
     * @type {Array<GetAllAgentsVersions200ResponseInnerVersionsInner>}
     * @memberof GetAllAgentsVersions200ResponseInner
     */
    versions: Array<GetAllAgentsVersions200ResponseInnerVersionsInner>;
}

/**
 * Check if a given object implements the GetAllAgentsVersions200ResponseInner interface.
 */
export function instanceOfGetAllAgentsVersions200ResponseInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "versions" in value;

    return isInstance;
}

export function GetAllAgentsVersions200ResponseInnerFromJSON(json: any): GetAllAgentsVersions200ResponseInner {
    return GetAllAgentsVersions200ResponseInnerFromJSONTyped(json, false);
}

export function GetAllAgentsVersions200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllAgentsVersions200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'orgName': !exists(json, 'orgName') ? undefined : json['orgName'],
        'state': json['state'],
        'status': json['status'],
        'versions': ((json['versions'] as Array<any>).map(GetAllAgentsVersions200ResponseInnerVersionsInnerFromJSON)),
    };
}

export function GetAllAgentsVersions200ResponseInnerToJSON(value?: GetAllAgentsVersions200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner': value.owner,
        'orgName': value.orgName,
        'state': value.state,
        'status': value.status,
        'versions': ((value.versions as Array<any>).map(GetAllAgentsVersions200ResponseInnerVersionsInnerToJSON)),
    };
}

