import * as Yup from "yup";
import { Helpers } from "@merit/frontend-utils";

const { Some } = Helpers;

// TODO: long-term we should make a phone number input component in the component library so the validation logic is in one place
const phoneNumberRegEx = new RegExp("^\\d{10}$", "u");

const adminRolesFieldSchema = Yup.array().min(1, "Admins must have at least one role");

export const createAdminValidationSchema = Yup.object().shape({
  adminEmail: Yup.string().trim().email("Please enter a valid email").required("Email is required"),
  adminFirstName: Yup.string().trim().min(1).max(80).required("First name is required"),
  adminLastName: Yup.string().trim().min(1).max(80).required("Last name is required"),
  adminPhone: Yup.string()
    .trim()
    .required("Phone number is required")
    .test("validate-phoneNumber", "Please enter a valid phone number", number => {
      if (Some(number) && phoneNumberRegEx.test(number)) {
        return true;
      }

      return false;
    }),
  adminRoles: adminRolesFieldSchema,
});

export const editAdminValidationSchema = Yup.object().shape({
  adminRoles: adminRolesFieldSchema,
});
