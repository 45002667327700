/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Formula } from './Formula';
import {
    FormulaFromJSON,
    FormulaFromJSONTyped,
    FormulaToJSON,
} from './Formula';

/**
 * A disjunction of formulas that can be used to define a policy.
 * @export
 * @interface Disjunction
 */
export interface Disjunction extends Formula {
    /**
     * The list of formulas that are being disjoined.
     * @type {Array<Formula>}
     * @memberof Disjunction
     */
    disjunction: Array<Formula>;
}



/**
 * Check if a given object implements the Disjunction interface.
 */
export function instanceOfDisjunction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "disjunction" in value;

    return isInstance;
}

export function DisjunctionFromJSON(json: any): Disjunction {
    return DisjunctionFromJSONTyped(json, false);
}

export function DisjunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Disjunction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormulaFromJSONTyped(json, ignoreDiscriminator),
        'disjunction': ((json['disjunction'] as Array<any>).map(FormulaFromJSON)),
    };
}

export function DisjunctionToJSON(value?: Disjunction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormulaToJSON(value),
        'disjunction': ((value.disjunction as Array<any>).map(FormulaToJSON)),
    };
}

