// Copyright 2023 Merit International Inc. All Rights Reserved

import { z } from "zod";

export const RequiredActivityFieldsSchema = z.object({
  activityName: z.string(),
  checkinId: z.string().uuid(),
  checkinTime: z.string().datetime({ offset: true }),
  timezone: z.string(),
});

export const OptionalActivityFieldsSchema = z
  .object({
    checkinLocation: z.string().nullable(),
    checkoutLocation: z.string().nullable(),
    checkoutTime: z.string().datetime({ offset: true }).nullable(),
    duration: z.string().nullable(),
  })
  .partial();

export const ActivitySchema = RequiredActivityFieldsSchema.merge(OptionalActivityFieldsSchema);

export const ActivityTemplateFieldValueSchema = z.object({
  checkInDetails: z.array(ActivitySchema),
});

export const doesActivityContainsAllRequiredFields = (fieldValue: string) => {
  try {
    const { checkInDetails } = JSON.parse(fieldValue) as z.infer<
      typeof ActivityTemplateFieldValueSchema
    >;

    return checkInDetails.every(
      activity => RequiredActivityFieldsSchema.safeParse(activity).success
    );
  } catch (error) {
    return false;
  }
};
