/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateAgentRequestCapabilitiesInner } from './CreateAgentRequestCapabilitiesInner';
import {
    CreateAgentRequestCapabilitiesInnerFromJSON,
    CreateAgentRequestCapabilitiesInnerFromJSONTyped,
    CreateAgentRequestCapabilitiesInnerToJSON,
} from './CreateAgentRequestCapabilitiesInner';
import type { GetAgents200ResponseAgentsInnerAuth0Options } from './GetAgents200ResponseAgentsInnerAuth0Options';
import {
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON,
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSONTyped,
    GetAgents200ResponseAgentsInnerAuth0OptionsToJSON,
} from './GetAgents200ResponseAgentsInnerAuth0Options';

/**
 * Properties to update on an agent
 * @export
 * @interface UpdateAgentRequest
 */
export interface UpdateAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    status?: UpdateAgentRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    description?: string;
    /**
     * Optional additional properties for this agent
     * @type {{ [key: string]: string; }}
     * @memberof UpdateAgentRequest
     */
    additionals?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    name?: string;
    /**
     * 
     * @type {GetAgents200ResponseAgentsInnerAuth0Options}
     * @memberof UpdateAgentRequest
     */
    auth0Options?: GetAgents200ResponseAgentsInnerAuth0Options | null;
    /**
     * The agent's capabilities. Each capability allows an agent to take a certain action on a class of objects
     * on stellar. Entities that wish to use the agent must grant the agent permission to use its capabilities
     * on objects belonging to the entities.
     * 
     * @type {Array<CreateAgentRequestCapabilitiesInner>}
     * @memberof UpdateAgentRequest
     */
    capabilities?: Array<CreateAgentRequestCapabilitiesInner>;
    /**
     * How the agent handles an entity's privacy
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    privacyTos?: string;
    /**
     * Description of acceptable use of the agent
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    usageTos?: string;
    /**
     * How the agent handles an entity's data from Stellar
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    dataTos?: string;
    /**
     * URI to which the user is re-directed after a successful login. Must be https.
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    loginURI?: string;
    /**
     * Contact information for the agent/app developer
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    developerContactEmail?: string;
}


/**
 * @export
 */
export const UpdateAgentRequestStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type UpdateAgentRequestStatusEnum = typeof UpdateAgentRequestStatusEnum[keyof typeof UpdateAgentRequestStatusEnum];


/**
 * Check if a given object implements the UpdateAgentRequest interface.
 */
export function instanceOfUpdateAgentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateAgentRequestFromJSON(json: any): UpdateAgentRequest {
    return UpdateAgentRequestFromJSONTyped(json, false);
}

export function UpdateAgentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAgentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'additionals': !exists(json, 'additionals') ? undefined : json['additionals'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'auth0Options': !exists(json, 'auth0Options') ? undefined : GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON(json['auth0Options']),
        'capabilities': !exists(json, 'capabilities') ? undefined : ((json['capabilities'] as Array<any>).map(CreateAgentRequestCapabilitiesInnerFromJSON)),
        'privacyTos': !exists(json, 'privacyTos') ? undefined : json['privacyTos'],
        'usageTos': !exists(json, 'usageTos') ? undefined : json['usageTos'],
        'dataTos': !exists(json, 'dataTos') ? undefined : json['dataTos'],
        'loginURI': !exists(json, 'loginURI') ? undefined : json['loginURI'],
        'developerContactEmail': !exists(json, 'developerContactEmail') ? undefined : json['developerContactEmail'],
    };
}

export function UpdateAgentRequestToJSON(value?: UpdateAgentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'description': value.description,
        'additionals': value.additionals,
        'name': value.name,
        'auth0Options': GetAgents200ResponseAgentsInnerAuth0OptionsToJSON(value.auth0Options),
        'capabilities': value.capabilities === undefined ? undefined : ((value.capabilities as Array<any>).map(CreateAgentRequestCapabilitiesInnerToJSON)),
        'privacyTos': value.privacyTos,
        'usageTos': value.usageTos,
        'dataTos': value.dataTos,
        'loginURI': value.loginURI,
        'developerContactEmail': value.developerContactEmail,
    };
}

