/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     CompoundAtomFromJSONTyped,
     ConjunctionFromJSONTyped,
     DisjunctionFromJSONTyped
} from './index';

/**
 * a formula that can be used to define a policy.
 * @export
 * @interface Formula
 */
export interface Formula {
    /**
     * 
     * @type {string}
     * @memberof Formula
     */
    formulaType: FormulaFormulaTypeEnum;
}


/**
 * @export
 */
export const FormulaFormulaTypeEnum = {
    Conjunction: 'Conjunction',
    Disjunction: 'Disjunction',
    CompoundAtom: 'CompoundAtom'
} as const;
export type FormulaFormulaTypeEnum = typeof FormulaFormulaTypeEnum[keyof typeof FormulaFormulaTypeEnum];


/**
 * Check if a given object implements the Formula interface.
 */
export function instanceOfFormula(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "formulaType" in value;

    return isInstance;
}

export function FormulaFromJSON(json: any): Formula {
    return FormulaFromJSONTyped(json, false);
}

export function FormulaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Formula {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['formulaType'] === 'CompoundAtom') {
            return CompoundAtomFromJSONTyped(json, true);
        }
        if (json['formulaType'] === 'Conjunction') {
            return ConjunctionFromJSONTyped(json, true);
        }
        if (json['formulaType'] === 'Disjunction') {
            return DisjunctionFromJSONTyped(json, true);
        }
    }
    return {
        
        'formulaType': json['formulaType'],
    };
}

export function FormulaToJSON(value?: Formula | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formulaType': value.formulaType,
    };
}

