// Copyright 2023 Merit International Inc. All Rights Reserved
/* eslint-disable react/no-multi-comp */

import { Body, Button, Heading } from "@merit/frontend-components";
import {
  type OrgsGet200ResponseContainersInner as Container,
  GetContainersTemplateTypeEnum,
} from "../../gen/org-portal";
import { Datagrid, DatagridBody, EllipsisText } from "../../components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { MaybeEliptText } from "./utils";
import { SCREEN_NAME } from "./Records";
import { View } from "react-native";
import { getDateTimeString } from "../../utils/time";
import { useGetContainerValueByBaseFieldName } from "../../utils/getContainerFieldValue";
import { useInferContainerTemplateType } from "@src/utils/inferContainerTemplateType";
import React from "react";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { SearchResult, SearchResultList } from "./types";

const { Some } = Helpers;

type Props = {
  readonly searchResults: SearchResultList;
  readonly onPressDelete: (container: SearchResult) => void;
  readonly onPressView: (container: SearchResult) => void;
};

export const SearchResults = ({ onPressDelete, onPressView, searchResults }: Props) => {
  const { getContainerValueByBaseFieldName } = useGetContainerValueByBaseFieldName();
  const { inferContainerTemplateType } = useInferContainerTemplateType();

  const isFolio = Some(searchResults)
    ? inferContainerTemplateType(searchResults[0]) === GetContainersTemplateTypeEnum.Folio
    : false;

  const meritTemplateColumns: readonly DatagridColumn<Container>[] = [
    {
      key: "email",
      label: "Email",
      renderer: (container, testProps) => (
        <MaybeEliptText
          testProps={testProps}
          text={getContainerValueByBaseFieldName({
            container,
            fieldName: "email",
            templateType: "merit",
          })}
        />
      ),
      size: "flex",
    },
    {
      key: "firstName",
      label: "First name",
      renderer: (container: Container, testProps) => (
        <MaybeEliptText
          testProps={testProps}
          text={getContainerValueByBaseFieldName({
            container,
            fieldName: "firstName",
            templateType: "merit",
          })}
        />
      ),
      size: "flex",
    },
    {
      key: "lastName",
      label: "Last name",
      renderer: (container: Container, testProps) => (
        <MaybeEliptText
          testProps={testProps}
          text={getContainerValueByBaseFieldName({
            container,
            fieldName: "lastName",
            templateType: "merit",
          })}
        />
      ),
      size: "flex",
    },
  ];

  const folioTemplateColumns: readonly DatagridColumn<Container>[] = [
    {
      key: "orgName",
      label: "Organization Name",
      renderer: (container, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getContainerValueByBaseFieldName({
            container,
            fieldName: "organizationName",
            templateType: "folio",
          })}
        />
      ),
      size: "flex",
    },
  ];

  const columns: readonly DatagridColumn<Container>[] = [
    ...(isFolio ? folioTemplateColumns : meritTemplateColumns),
    {
      key: "templateName",
      label: "Template",
      renderer: (container, testProps) => (
        <MaybeEliptText testProps={testProps} text={container.name ?? "--"} />
      ),
      size: "flex",
    },
    {
      key: "containerState",
      label: "Container state",
      renderer: (container, testProps) => (
        <MaybeEliptText capitalize testProps={testProps} text={container.state?.name ?? "--"} />
      ),
      size: "flex",
    },
    {
      key: "authorizedAt",
      label: "Issue date",
      renderer: ({ authorizedAt }, testProps) =>
        Some(authorizedAt) ? (
          <Body testProps={testProps}>{getDateTimeString(authorizedAt.toString())}</Body>
        ) : (
          <Body>--</Body>
        ),
      size: 170,
    },
    {
      key: "actions",
      label: "Actions",
      renderer: record => (
        <>
          <Button
            onPress={() => {
              onPressView(record);
            }}
            size="small"
            testProps={{
              elementId: record.id,
              elementName: "recordsListViewRowItemViewButton",
              screenName: SCREEN_NAME,
            }}
            text="View"
            type="secondary"
          />
          <HorizontalSpacer />
          <Button
            iconLeft="closeSmallDefault"
            onPress={() => {
              onPressDelete(record);
            }}
            shape="square"
            size="small"
            testProps={{
              elementId: record.id,
              elementName: "recordsListViewRowItemRemoveButton",
              screenName: SCREEN_NAME,
            }}
            type="destructive"
          />
        </>
      ),
      size: 200,
    },
  ];

  const numberOfResultsString =
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    searchResults === undefined ? "Results" : `Results (${searchResults.length})`;

  return (
    <>
      <View style={{ paddingLeft: 32 }}>
        <Heading level="4">{numberOfResultsString}</Heading>
      </View>

      <Datagrid
        columns={columns}
        testProps={{
          elementName: "recordsListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={searchResults ?? []}
          getItemKey={(c: Container) => c.id}
          testProps={{
            elementName: "RecordsListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="id"
        />
      </Datagrid>
    </>
  );
};
