export const uploadImage = async (file: string, imageUrl: string, mimeType: string) => {
  const res = await fetch(imageUrl, {
    body: file,
    headers: {
      "Content-Type": mimeType,
    },
    method: "PUT",
  });

  return res;
};
