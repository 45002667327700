// Copyright 2024 Merit International Inc. All Rights Reserved

import { MenuLink } from "./MenuLink";
import { VerticalSpacer } from "../Spacer";
import { View } from "react-native";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLoggedInUserRoles } from "@src/hooks/loggedInUserRoles";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";

export const CreateAndConfigureMenu = () => {
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const { showAutoMappingFeature, showPoliciesFrontend, showSigmaAnalytics, showStudioFrontend } =
    useFlags<LDFeatureFlags>();
  const { isDashboardAdmin, isDesignAdmin, isOrgAdmin } = useLoggedInUserRoles();

  return (
    <View
      style={{
        backgroundColor: theme.colors.background.white,
        borderRadius: 4,
        paddingHorizontal: 24,
        paddingVertical: 16,
        width: 384,
      }}
    >
      {isOrgAdmin && (
        <>
          <MenuLink
            onPress={() => {
              navigation.navigate("Templates");
            }}
            size="large"
            subtext="Create and configure merits and folios"
            text="Templates"
          />
          {showPoliciesFrontend && (
            <MenuLink
              onPress={() => {
                navigation.navigate("Policies");
              }}
              size="large"
              subtext="Create and configure how your policies are verified"
              text="Policies"
            />
          )}
          <>
            <VerticalSpacer size={8} />
            <View style={{ backgroundColor: theme.colors.border.subdued, height: 1, width: 104 }} />
            <VerticalSpacer size={8} />
          </>
          <MenuLink
            onPress={() => {
              navigation.navigate("Datasources");
            }}
            size="small"
            subtext="Create, edit, and sync data"
            text="Data Sources"
          />
          <MenuLink
            onPress={() => {
              navigation.navigate("Fields");
            }}
            size="small"
            subtext="Create, edit, and share fields"
            text="Fields"
          />
        </>
      )}
      {showStudioFrontend && (isOrgAdmin || isDesignAdmin) && (
        <MenuLink
          onPress={() => {
            navigation.navigate("Studio");
          }}
          size="small"
          subtext="Design certificates for your merits"
          text="Studio"
        />
      )}
      {showSigmaAnalytics && (isDashboardAdmin || isOrgAdmin) && (
        <MenuLink
          onPress={() => {
            navigation.navigate("Reports");
          }}
          size="small"
          subtext="View analytics reports"
          text="Reports"
        />
      )}
      {showAutoMappingFeature && isOrgAdmin && (
        <MenuLink
          onPress={() => {
            navigation.navigate("AutoMapTemplate");
          }}
          size="small"
          subtext="Automated template mapping"
          text="Auto Mapping"
        />
      )}
    </View>
  );
};
