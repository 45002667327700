// Copyright 2024 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { persist } from "zustand/middleware";

/*
  Stores a mapping of org logo URLs by id
*/

export type OrgLogoState = {
  readonly orgLogos: Record<string, string | undefined>;
  readonly setOrgLogo: (orgId: string, newOrgLogo: string) => void;
  readonly getOrgLogo: (orgId: string) => string | undefined;
  readonly clear: () => void;
  readonly _hasHydrated: boolean;
  readonly setHasHydrated: (state: boolean) => void;
};

export const useOrgLogoStore = create<OrgLogoState>()(
  persist(
    (set, get) => ({
      _hasHydrated: false,
      clear: () => {
        set(() => ({
          orgLogos: {},
        }));
      },
      getOrgLogo: (orgId: string) => {
        if (orgId in get().orgLogos) {
          return get().orgLogos[orgId];
        }

        return undefined;
      },
      orgLogos: {},
      setHasHydrated: state => {
        set({
          _hasHydrated: state,
        });
      },
      setOrgLogo: (orgId: string, newOrgLogo: string) => {
        set(state => ({ orgLogos: { ...state.orgLogos, [orgId]: newOrgLogo } }));
      },
    }),
    {
      name: "org-logo-storage",
      onRehydrateStorage: () => (state: OrgLogoState | undefined) => {
        if (state !== undefined) {
          state.setHasHydrated(true);
        }
      },
    }
  )
);
