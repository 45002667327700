/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetLoginLink200Response
 */
export interface GetLoginLink200Response {
    /**
     * 
     * @type {string}
     * @memberof GetLoginLink200Response
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof GetLoginLink200Response
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof GetLoginLink200Response
     */
    stateCookie: string;
}

/**
 * Check if a given object implements the GetLoginLink200Response interface.
 */
export function instanceOfGetLoginLink200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "stateCookie" in value;

    return isInstance;
}

export function GetLoginLink200ResponseFromJSON(json: any): GetLoginLink200Response {
    return GetLoginLink200ResponseFromJSONTyped(json, false);
}

export function GetLoginLink200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLoginLink200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'link': json['Link'],
        'state': json['State'],
        'stateCookie': json['StateCookie'],
    };
}

export function GetLoginLink200ResponseToJSON(value?: GetLoginLink200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Link': value.link,
        'State': value.state,
        'StateCookie': value.stateCookie,
    };
}

