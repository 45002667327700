// Copyright 2023 Merit International Inc. All Rights Reserved

import { Apps } from "./Apps";
import { Tabs } from "@src/components";
import { Templates } from "./Templates";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import React, { useState } from "react";
import type {
  ListTemplates200ResponseTemplatesInnerTypeEnum,
  OrgsGet200ResponseContainersInner,
} from "../../gen/org-portal";

export const SCREEN_NAME = "Approvals";

type TabKey = "apps" | "templates";

const TAB_LIST = [
  { key: "templates" as const, label: "Templates" },
  { key: "apps" as const, label: "Apps" },
];

export type ContainerWithTemplateType = OrgsGet200ResponseContainersInner & {
  readonly templateType: ListTemplates200ResponseTemplatesInnerTypeEnum;
};

export const Approvals = () => {
  const { DefaultLayout } = useFlaggedLayout();
  const [selectedTab, setSelectedTab] = useState<TabKey>("templates");
  const { configuration } = useLoadedConfigurationState();
  const auth = useLoggedInAuthState();

  return (
    <>
      <DefaultLayout
        breadcrumbs={[{ name: "Verify & Manage" }, { name: "Approvals" }]}
        tabs={
          auth.selectedOrgId === configuration.solUUID && (
            <Tabs
              items={TAB_LIST}
              onChange={value => {
                setSelectedTab(value);
              }}
              selected={selectedTab}
            />
          )
        }
        testProps={{
          elementName: "approvals",
          screenName: SCREEN_NAME,
        }}
        title="Approvals"
      >
        {selectedTab === "templates" && <Templates />}
        {selectedTab === "apps" && <Apps />}
      </DefaultLayout>
    </>
  );
};
