/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionOnUpdate } from './ActionOnUpdate';
import {
    ActionOnUpdateFromJSON,
    ActionOnUpdateFromJSONTyped,
    ActionOnUpdateToJSON,
} from './ActionOnUpdate';
import type { TemplateFieldColumnMappingProperties } from './TemplateFieldColumnMappingProperties';
import {
    TemplateFieldColumnMappingPropertiesFromJSON,
    TemplateFieldColumnMappingPropertiesFromJSONTyped,
    TemplateFieldColumnMappingPropertiesToJSON,
} from './TemplateFieldColumnMappingProperties';

/**
 * Mapping of a template to a data source.
 * @export
 * @interface TemplateDataSourceMapping
 */
export interface TemplateDataSourceMapping {
    /**
     * The id of the template.
     * @type {string}
     * @memberof TemplateDataSourceMapping
     */
    templateID: string;
    /**
     * The id of the data source.
     * @type {string}
     * @memberof TemplateDataSourceMapping
     */
    dataSourceID?: string | null;
    /**
     * The mapping from a template field to a column.
     * @type {Array<TemplateFieldColumnMappingProperties>}
     * @memberof TemplateDataSourceMapping
     */
    columnToTemplateField?: Array<TemplateFieldColumnMappingProperties>;
    /**
     * 
     * @type {ActionOnUpdate}
     * @memberof TemplateDataSourceMapping
     */
    actionOnUpdate?: ActionOnUpdate | null;
}

/**
 * Check if a given object implements the TemplateDataSourceMapping interface.
 */
export function instanceOfTemplateDataSourceMapping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateID" in value;

    return isInstance;
}

export function TemplateDataSourceMappingFromJSON(json: any): TemplateDataSourceMapping {
    return TemplateDataSourceMappingFromJSONTyped(json, false);
}

export function TemplateDataSourceMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateDataSourceMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateID': json['templateID'],
        'dataSourceID': !exists(json, 'dataSourceID') ? undefined : json['dataSourceID'],
        'columnToTemplateField': !exists(json, 'columnToTemplateField') ? undefined : ((json['columnToTemplateField'] as Array<any>).map(TemplateFieldColumnMappingPropertiesFromJSON)),
        'actionOnUpdate': !exists(json, 'actionOnUpdate') ? undefined : ActionOnUpdateFromJSON(json['actionOnUpdate']),
    };
}

export function TemplateDataSourceMappingToJSON(value?: TemplateDataSourceMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateID': value.templateID,
        'dataSourceID': value.dataSourceID,
        'columnToTemplateField': value.columnToTemplateField === undefined ? undefined : ((value.columnToTemplateField as Array<any>).map(TemplateFieldColumnMappingPropertiesToJSON)),
        'actionOnUpdate': ActionOnUpdateToJSON(value.actionOnUpdate),
    };
}

