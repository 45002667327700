/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType } from './ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType';
import {
    ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeFromJSON,
    ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeFromJSONTyped,
    ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeToJSON,
} from './ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType';

/**
 * 
 * @export
 * @interface ListDatasources200ResponseDatasourcesInnerColumnsInner
 */
export interface ListDatasources200ResponseDatasourcesInnerColumnsInner {
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInner
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInner
     */
    identifier?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInner
     */
    usedAsDeletion: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInner
     */
    name: string;
    /**
     * 
     * @type {ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInner
     */
    dataType: ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInner
     */
    columnPhoneNumberDefaultCountryCode?: string;
}

/**
 * Check if a given object implements the ListDatasources200ResponseDatasourcesInnerColumnsInner interface.
 */
export function instanceOfListDatasources200ResponseDatasourcesInnerColumnsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "usedAsDeletion" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "dataType" in value;

    return isInstance;
}

export function ListDatasources200ResponseDatasourcesInnerColumnsInnerFromJSON(json: any): ListDatasources200ResponseDatasourcesInnerColumnsInner {
    return ListDatasources200ResponseDatasourcesInnerColumnsInnerFromJSONTyped(json, false);
}

export function ListDatasources200ResponseDatasourcesInnerColumnsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDatasources200ResponseDatasourcesInnerColumnsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'usedAsDeletion': json['usedAsDeletion'],
        'name': json['name'],
        'dataType': ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeFromJSON(json['dataType']),
        'columnPhoneNumberDefaultCountryCode': !exists(json, 'columnPhoneNumberDefaultCountryCode') ? undefined : json['columnPhoneNumberDefaultCountryCode'],
    };
}

export function ListDatasources200ResponseDatasourcesInnerColumnsInnerToJSON(value?: ListDatasources200ResponseDatasourcesInnerColumnsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'usedAsDeletion': value.usedAsDeletion,
        'name': value.name,
        'dataType': ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeToJSON(value.dataType),
        'columnPhoneNumberDefaultCountryCode': value.columnPhoneNumberDefaultCountryCode,
    };
}

