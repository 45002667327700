/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTemplateRules200ResponseRulesBool } from './GetTemplateRules200ResponseRulesBool';
import {
    GetTemplateRules200ResponseRulesBoolFromJSON,
    GetTemplateRules200ResponseRulesBoolFromJSONTyped,
    GetTemplateRules200ResponseRulesBoolToJSON,
} from './GetTemplateRules200ResponseRulesBool';
import type { GetTemplateRules200ResponseRulesDate } from './GetTemplateRules200ResponseRulesDate';
import {
    GetTemplateRules200ResponseRulesDateFromJSON,
    GetTemplateRules200ResponseRulesDateFromJSONTyped,
    GetTemplateRules200ResponseRulesDateToJSON,
} from './GetTemplateRules200ResponseRulesDate';
import type { GetTemplateRules200ResponseRulesDateTime } from './GetTemplateRules200ResponseRulesDateTime';
import {
    GetTemplateRules200ResponseRulesDateTimeFromJSON,
    GetTemplateRules200ResponseRulesDateTimeFromJSONTyped,
    GetTemplateRules200ResponseRulesDateTimeToJSON,
} from './GetTemplateRules200ResponseRulesDateTime';
import type { GetTemplateRules200ResponseRulesEmail } from './GetTemplateRules200ResponseRulesEmail';
import {
    GetTemplateRules200ResponseRulesEmailFromJSON,
    GetTemplateRules200ResponseRulesEmailFromJSONTyped,
    GetTemplateRules200ResponseRulesEmailToJSON,
} from './GetTemplateRules200ResponseRulesEmail';
import type { GetTemplateRules200ResponseRulesMarkdown } from './GetTemplateRules200ResponseRulesMarkdown';
import {
    GetTemplateRules200ResponseRulesMarkdownFromJSON,
    GetTemplateRules200ResponseRulesMarkdownFromJSONTyped,
    GetTemplateRules200ResponseRulesMarkdownToJSON,
} from './GetTemplateRules200ResponseRulesMarkdown';
import type { GetTemplateRules200ResponseRulesNumber } from './GetTemplateRules200ResponseRulesNumber';
import {
    GetTemplateRules200ResponseRulesNumberFromJSON,
    GetTemplateRules200ResponseRulesNumberFromJSONTyped,
    GetTemplateRules200ResponseRulesNumberToJSON,
} from './GetTemplateRules200ResponseRulesNumber';
import type { GetTemplateRules200ResponseRulesText } from './GetTemplateRules200ResponseRulesText';
import {
    GetTemplateRules200ResponseRulesTextFromJSON,
    GetTemplateRules200ResponseRulesTextFromJSONTyped,
    GetTemplateRules200ResponseRulesTextToJSON,
} from './GetTemplateRules200ResponseRulesText';

/**
 * 
 * @export
 * @interface GetTemplateRules200ResponseRules
 */
export interface GetTemplateRules200ResponseRules {
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBool}
     * @memberof GetTemplateRules200ResponseRules
     */
    bool?: GetTemplateRules200ResponseRulesBool;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesDate}
     * @memberof GetTemplateRules200ResponseRules
     */
    date?: GetTemplateRules200ResponseRulesDate;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesDateTime}
     * @memberof GetTemplateRules200ResponseRules
     */
    dateTime?: GetTemplateRules200ResponseRulesDateTime;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesEmail}
     * @memberof GetTemplateRules200ResponseRules
     */
    email?: GetTemplateRules200ResponseRulesEmail;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesMarkdown}
     * @memberof GetTemplateRules200ResponseRules
     */
    markdown?: GetTemplateRules200ResponseRulesMarkdown;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesNumber}
     * @memberof GetTemplateRules200ResponseRules
     */
    number?: GetTemplateRules200ResponseRulesNumber;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesMarkdown}
     * @memberof GetTemplateRules200ResponseRules
     */
    phoneNumber?: GetTemplateRules200ResponseRulesMarkdown;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesText}
     * @memberof GetTemplateRules200ResponseRules
     */
    text?: GetTemplateRules200ResponseRulesText;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesMarkdown}
     * @memberof GetTemplateRules200ResponseRules
     */
    blob?: GetTemplateRules200ResponseRulesMarkdown;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesMarkdown}
     * @memberof GetTemplateRules200ResponseRules
     */
    jSON?: GetTemplateRules200ResponseRulesMarkdown;
}

/**
 * Check if a given object implements the GetTemplateRules200ResponseRules interface.
 */
export function instanceOfGetTemplateRules200ResponseRules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseRulesFromJSON(json: any): GetTemplateRules200ResponseRules {
    return GetTemplateRules200ResponseRulesFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200ResponseRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bool': !exists(json, 'Bool') ? undefined : GetTemplateRules200ResponseRulesBoolFromJSON(json['Bool']),
        'date': !exists(json, 'Date') ? undefined : GetTemplateRules200ResponseRulesDateFromJSON(json['Date']),
        'dateTime': !exists(json, 'DateTime') ? undefined : GetTemplateRules200ResponseRulesDateTimeFromJSON(json['DateTime']),
        'email': !exists(json, 'Email') ? undefined : GetTemplateRules200ResponseRulesEmailFromJSON(json['Email']),
        'markdown': !exists(json, 'Markdown') ? undefined : GetTemplateRules200ResponseRulesMarkdownFromJSON(json['Markdown']),
        'number': !exists(json, 'Number') ? undefined : GetTemplateRules200ResponseRulesNumberFromJSON(json['Number']),
        'phoneNumber': !exists(json, 'PhoneNumber') ? undefined : GetTemplateRules200ResponseRulesMarkdownFromJSON(json['PhoneNumber']),
        'text': !exists(json, 'Text') ? undefined : GetTemplateRules200ResponseRulesTextFromJSON(json['Text']),
        'blob': !exists(json, 'Blob') ? undefined : GetTemplateRules200ResponseRulesMarkdownFromJSON(json['Blob']),
        'jSON': !exists(json, 'JSON') ? undefined : GetTemplateRules200ResponseRulesMarkdownFromJSON(json['JSON']),
    };
}

export function GetTemplateRules200ResponseRulesToJSON(value?: GetTemplateRules200ResponseRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Bool': GetTemplateRules200ResponseRulesBoolToJSON(value.bool),
        'Date': GetTemplateRules200ResponseRulesDateToJSON(value.date),
        'DateTime': GetTemplateRules200ResponseRulesDateTimeToJSON(value.dateTime),
        'Email': GetTemplateRules200ResponseRulesEmailToJSON(value.email),
        'Markdown': GetTemplateRules200ResponseRulesMarkdownToJSON(value.markdown),
        'Number': GetTemplateRules200ResponseRulesNumberToJSON(value.number),
        'PhoneNumber': GetTemplateRules200ResponseRulesMarkdownToJSON(value.phoneNumber),
        'Text': GetTemplateRules200ResponseRulesTextToJSON(value.text),
        'Blob': GetTemplateRules200ResponseRulesMarkdownToJSON(value.blob),
        'JSON': GetTemplateRules200ResponseRulesMarkdownToJSON(value.jSON),
    };
}

