// Copyright 2023 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import type { AlertProps } from "@merit/frontend-components";

export type AlertState = {
  readonly alertIdToTimeoutId: Record<AlertProps["id"], NodeJS.Timeout | undefined>;
  readonly alertList: readonly AlertProps[];
  readonly setAlert: (alert: AlertProps) => void;
  readonly deleteAlert: (id: string) => void;
};

const DURATION = 5000;

const deleteAlert = (id: string, state: AlertState) => {
  clearTimeout(state.alertIdToTimeoutId[id]);

  return {
    alertIdToTimeoutId: {
      ...state.alertIdToTimeoutId,
      [id]: undefined,
    },
    alertList: state.alertList.filter(alert => alert.id !== id),
  };
};

// Alert state is not peristed on purpose
export const useAlertStore = create<AlertState>(set => ({
  alertIdToTimeoutId: {},
  alertList: [],
  deleteAlert: (id: string) => {
    set(state => deleteAlert(id, state));
  },
  setAlert: (alert: AlertProps) => {
    set(state => {
      // deduplicate alert with the same id
      const newState = deleteAlert(alert.id, state);

      // Auto hides the alert based on duration
      const timeoutId = setTimeout(() => {
        // state.deleteAlert is needed here to actually persist the change if the timeout fires
        state.deleteAlert(alert.id);
      }, DURATION);

      return {
        alertIdToTimeoutId: {
          ...newState.alertIdToTimeoutId,
          [alert.id]: timeoutId,
        },
        alertList: [...newState.alertList, alert],
      };
    });
  },
}));
