// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { PolicyRulesList } from "./PolicyRulesList";
import { SCREEN_NAME } from "./PolicyDetails";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin, VerticalSpacer } from "@src/components";
import { useApi } from "@src/api/api";
import { useEffect, useState } from "react";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import type {
  GetPolicies200ResponsePoliciesInner,
  ListTemplates200ResponseTemplatesInner,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom,
} from "@src/gen/org-portal";
import type { TabKey } from "../Policies/Policies";

const { Some } = Helpers;

type Props = {
  readonly policyID: string;
  readonly rules: GetPolicies200ResponsePoliciesInner["rules"];
  readonly ownAndInheritedPolicyRules: readonly OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom[];
  readonly policyType: TabKey;
  readonly onPressView: (templateID: string) => void;
};

export const PolicyRules = ({
  onPressView,
  ownAndInheritedPolicyRules,
  policyID,
  policyType,
  rules,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    divider: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
    },
  });

  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<readonly ListTemplates200ResponseTemplatesInner[]>([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setIsLoading(true);
        const res = await api.listTemplates({ limit: 100, orgID: selectedOrgId });

        setTemplates(res.templates);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    // To display template names, we had to fetch the templates list.
    // However, it still doesn't show the names when we have more than 100 templates.
    // The platform needs to provide a solution for this issue"  */

    fetchTemplates();
  }, [api, selectedOrgId, errorHandler]);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Spin />
      </View>
    );
  }

  return (
    <>
      <VerticalSpacer size={28} />
      {Some(rules) && (
        <ScrollView showsVerticalScrollIndicator={false}>
          <Heading level="4">Inherited</Heading>
          <VerticalSpacer />

          {policyType === "shared" ? (
            <>
              {ownAndInheritedPolicyRules.length > 0 ? (
                <PolicyRulesList
                  rules={ownAndInheritedPolicyRules}
                  templates={templates}
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewRulesTab",
                    screenName: SCREEN_NAME,
                  }}
                />
              ) : (
                <Body
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewRulesTabNoOwnRulesPlaceholder",
                    screenName: SCREEN_NAME,
                  }}
                >
                  There are no inherited rules
                </Body>
              )}
            </>
          ) : (
            <>
              {Some(rules.inherited) && rules.inherited.length > 0 ? (
                <PolicyRulesList
                  rules={rules.inherited.flat()}
                  templates={templates}
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewRulesTab",
                    screenName: SCREEN_NAME,
                  }}
                />
              ) : (
                <Body
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewRulesTabNoInheritedRulesPlaceholder",
                    screenName: SCREEN_NAME,
                  }}
                >
                  There are no inherited rules
                </Body>
              )}
              <VerticalSpacer size={24} />
              <View style={styles.divider} />
              <VerticalSpacer size={24} />

              <Heading level="4">Org created</Heading>
              <VerticalSpacer />
              {Some(rules.own) && rules.own.length > 0 ? (
                <PolicyRulesList
                  onPressView={onPressView}
                  rules={rules.own}
                  templates={templates}
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewRulesTab",
                    screenName: SCREEN_NAME,
                  }}
                />
              ) : (
                <Body
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewRulesTabNoOrgRulesPlaceholder",
                    screenName: SCREEN_NAME,
                  }}
                >
                  There are no org created rules
                </Body>
              )}
            </>
          )}
        </ScrollView>
      )}
    </>
  );
};
