/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The current state of the container.
 * @export
 * @interface OrgsGet200ResponseContainersInnerState
 */
export interface OrgsGet200ResponseContainersInnerState {
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerState
     */
    name: OrgsGet200ResponseContainersInnerStateNameEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrgsGet200ResponseContainersInnerState
     */
    occurredAt: Date;
}


/**
 * @export
 */
export const OrgsGet200ResponseContainersInnerStateNameEnum = {
    Unissued: 'unissued',
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Revoked: 'revoked'
} as const;
export type OrgsGet200ResponseContainersInnerStateNameEnum = typeof OrgsGet200ResponseContainersInnerStateNameEnum[keyof typeof OrgsGet200ResponseContainersInnerStateNameEnum];


/**
 * Check if a given object implements the OrgsGet200ResponseContainersInnerState interface.
 */
export function instanceOfOrgsGet200ResponseContainersInnerState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "occurredAt" in value;

    return isInstance;
}

export function OrgsGet200ResponseContainersInnerStateFromJSON(json: any): OrgsGet200ResponseContainersInnerState {
    return OrgsGet200ResponseContainersInnerStateFromJSONTyped(json, false);
}

export function OrgsGet200ResponseContainersInnerStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200ResponseContainersInnerState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'occurredAt': (new Date(json['occurredAt'])),
    };
}

export function OrgsGet200ResponseContainersInnerStateToJSON(value?: OrgsGet200ResponseContainersInnerState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'occurredAt': (value.occurredAt.toISOString()),
    };
}

