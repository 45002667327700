// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "../../../components";
import { EllipsisText } from "../../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { ScrollView, View } from "react-native";
import { getReadableDataType } from "@src/utils/getReadableDataType";
import { useDatasourceDetailsSchemaTabStyles } from "./styles";
import type { DatagridColumn } from "../../../components/Datagrid/types";
import type { GetDatasource200Response } from "../../../gen/org-portal";
import type { OPTestProps } from "../../../types/TestProps";

type Props = {
  readonly datasourceSchema: GetDatasource200Response["datasource"]["columns"];
  readonly testProps: OPTestProps;
};

const { Some } = Helpers;
const SCREEN_NAME = "DatasourcesSchema";

export const Schema = ({ datasourceSchema, testProps }: Props) => {
  const { theme } = useTheme();
  const styles = useDatasourceDetailsSchemaTabStyles();

  const columns: readonly DatagridColumn<
    GetDatasource200Response["datasource"]["columns"][number]
  >[] = [
    {
      key: "name",
      label: "Column name",
      renderer: record => (
        <EllipsisText
          bold={Some(record.identifier) && record.identifier ? true : undefined}
          testProps={{
            elementId: record.id,
            elementName: "ColumnNameEllipsisText",
            screenName: SCREEN_NAME,
          }}
          text={record.name}
        />
      ),
      size: "flex",
    },
    {
      key: "dataType",
      label: "Data type",
      renderer: record => (
        <EllipsisText
          testProps={{
            elementId: record.id,
            elementName: "DataTypeEllipsisText",
            screenName: SCREEN_NAME,
          }}
          text={
            Some(record.dataType.dataType) ? getReadableDataType(record.dataType.dataType) : "--"
          }
        />
      ),
      size: "flex",
    },
  ];

  const uniqueIdentifiers = datasourceSchema.filter(column => column.identifier);
  const removalColumns = datasourceSchema.filter(column => column.usedAsDeletion);

  return (
    <>
      <View style={{ marginTop: theme.spacing.xxl }}>
        <View style={{ paddingHorizontal: 32 }}>
          <View style={{ flexDirection: "row" }}>
            <Body
              style={{ flexBasis: "50%", fontWeight: theme.fontWeights.semiBold }}
              testProps={{ ...testProps, elementName: `${testProps.elementName}UniqueIds` }}
            >
              {Some(datasourceSchema)
                ? "Unique Identifier(s)"
                : "There are no columns mapped for this data source"}
            </Body>

            <Body
              style={{ flexBasis: "50%", fontWeight: theme.fontWeights.semiBold }}
              testProps={{ ...testProps, elementName: `${testProps.elementName}UniqueIds` }}
            >
              <HorizontalSpacer size={theme.spacing.m} />
              {Some(datasourceSchema)
                ? "Removal Identifier"
                : "There are no columns mapped for this data source"}
            </Body>
          </View>

          <VerticalSpacer size={10} />
          <View style={{ flexDirection: "row" }}>
            <View style={{ flexBasis: "50%" }}>
              {Some(datasourceSchema) && (
                <>
                  <View style={{ flexDirection: "row" }}>
                    {uniqueIdentifiers.map(uniqueIdentifier => (
                      <>
                        <View style={styles.badge}>
                          <Body
                            testProps={{
                              ...testProps,
                              elementId: uniqueIdentifier.id,
                              elementName: `${testProps.elementName}UniqueIds`,
                            }}
                          >
                            {uniqueIdentifier.name}
                          </Body>
                        </View>
                        <HorizontalSpacer size={theme.spacing.xs} />
                      </>
                    ))}
                  </View>
                </>
              )}
            </View>

            <View style={{ flexBasis: "50%" }}>
              {Some(datasourceSchema) && (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <HorizontalSpacer size={theme.spacing.m} />
                    {removalColumns.map(removalColumn => (
                      <>
                        <View
                          style={[
                            styles.badge,
                            { backgroundColor: theme.colors.background.default },
                          ]}
                        >
                          <Body
                            testProps={{
                              ...testProps,
                              elementId: removalColumn.id,
                              elementName: `${testProps.elementName}RemovalColumns`,
                            }}
                          >
                            {removalColumn.name}
                          </Body>
                        </View>
                        <HorizontalSpacer size={theme.spacing.xs} />
                      </>
                    ))}
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
        <VerticalSpacer size={theme.spacing.m} />

        {Some(datasourceSchema) && (
          <ScrollView>
            <Datagrid
              columns={columns}
              testProps={{
                elementId: "id",
                elementName: "DatasourcesSchemaView",
                screenName: SCREEN_NAME,
              }}
            >
              <DatagridBody columns={columns} data={datasourceSchema} />
            </Datagrid>
          </ScrollView>
        )}
      </View>
    </>
  );
};
