// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Heading, TextInput, useTheme } from "@merit/frontend-components";
import { FieldHandle } from "./FieldHandle";
import { Helpers } from "@merit/frontend-utils";
import { Position } from "reactflow";
import { VerticalSpacer } from "@src/components";
import { View } from "react-native";
import { usePreviewDataSourceStyles } from "../Datasource/styles";
import React, { useCallback, useEffect, useState } from "react";
import type { Node } from "reactflow";

const { Some } = Helpers;

type Props = {
  readonly label: string;
  readonly templateNames: readonly string[];
  readonly onEdit: (fieldName: string, error: string | undefined) => void;
  readonly type: "source" | "target";
  readonly isBaseField: boolean;
};

export const EditableFieldNode = ({ data, targetPosition }: Omit<Node<Props>, "position">) => {
  const { theme } = useTheme();
  const [name, setName] = useState<string>(data.label);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const styles = usePreviewDataSourceStyles();

  const validate = useCallback(
    (value: string) => {
      const checkDuplicates = (fieldName: string) =>
        data.templateNames.includes(fieldName.toLowerCase());

      switch (true) {
        case value.length === 0:
          setErrorMessage("Template field name is required");
          break;
        case value.length < 2:
          setErrorMessage("Minimum 2 characters");
          break;
        case value.length > 90:
          setErrorMessage("Maximum 90 characters");
          break;
        case checkDuplicates(value):
          setErrorMessage("Template field with above name already exist");
          break;
        default:
          setErrorMessage(undefined);
      }
    },
    [data.templateNames]
  );

  useEffect(() => {
    validate(data.label);
    // Need on initial render only to check external errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nodrag">
      <View>
        {data.isBaseField ? (
          <View style={[styles.fakeField, { height: 40, width: 207 }]}>
            <Body>{data.label}</Body>
          </View>
        ) : (
          <View style={{ height: 40, width: 240 }}>
            <View style={{ width: 207 }}>
              <TextInput
                maxLength={90}
                onBlur={() => {
                  data.onEdit(name, errorMessage);
                }}
                onChange={e => {
                  validate(e.nativeEvent.text.trim());
                }}
                onChangeText={value => {
                  setName(value);
                }}
                placeholder="Field name"
                size="medium"
                value={name}
              />
            </View>

            {Some(errorMessage) && (
              <>
                <VerticalSpacer size={theme.spacing.xs} />
                <Heading color={theme.colors.border.critical.default} level="6">
                  {errorMessage}
                </Heading>
              </>
            )}
          </View>
        )}
      </View>
      <FieldHandle id={data.label} position={targetPosition ?? Position.Left} type={data.type} />
    </div>
  );
};
