/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Pagination information for this response
 * @export
 * @interface PaginationInfo
 */
export interface PaginationInfo {
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    limit: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationInfo
     */
    hasMore?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaginationInfo
     */
    nextStartAfter?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationInfo
     */
    previousEndBefore?: string;
}

/**
 * Check if a given object implements the PaginationInfo interface.
 */
export function instanceOfPaginationInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "limit" in value;

    return isInstance;
}

export function PaginationInfoFromJSON(json: any): PaginationInfo {
    return PaginationInfoFromJSONTyped(json, false);
}

export function PaginationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['Limit'],
        'hasMore': !exists(json, 'HasMore') ? undefined : json['HasMore'],
        'nextStartAfter': !exists(json, 'NextStartAfter') ? undefined : json['NextStartAfter'],
        'previousEndBefore': !exists(json, 'PreviousEndBefore') ? undefined : json['PreviousEndBefore'],
    };
}

export function PaginationInfoToJSON(value?: PaginationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Limit': value.limit,
        'HasMore': value.hasMore,
        'NextStartAfter': value.nextStartAfter,
        'PreviousEndBefore': value.previousEndBefore,
    };
}

