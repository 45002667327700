/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplatesTemplateIDPUTRequest
 */
export interface TemplatesTemplateIDPUTRequest {
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    templateTitle: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    templateStatus?: TemplatesTemplateIDPUTRequestTemplateStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    brandId?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    brandCode?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    primaryTemplateCategoryItemId?: number;
    /**
     * Supply an object containing the subcategories and their respective items to assign to the template. Provide the template_category_id (converted to string) as the key mapped to an array of template_category_item_id numbers. Note that this should only include subcategories, not the primary (single item assignment via the field above). The list of categories/items can be retrieved <a href="#tag/Templates/paths/~1template~1categories/get">here</a>. Example: <em>{ "2": [1,2,3], "3": [2,4,6] }</em>
     * @type {object}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    subcategoryItems?: object;
    /**
     * 
     * @type {boolean}
     * @memberof TemplatesTemplateIDPUTRequest
     */
    replaceSubcategoryItems?: boolean;
}


/**
 * @export
 */
export const TemplatesTemplateIDPUTRequestTemplateStatusEnum = {
    Active: 'active',
    Hidden: 'hidden'
} as const;
export type TemplatesTemplateIDPUTRequestTemplateStatusEnum = typeof TemplatesTemplateIDPUTRequestTemplateStatusEnum[keyof typeof TemplatesTemplateIDPUTRequestTemplateStatusEnum];


/**
 * Check if a given object implements the TemplatesTemplateIDPUTRequest interface.
 */
export function instanceOfTemplatesTemplateIDPUTRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "templateTitle" in value;

    return isInstance;
}

export function TemplatesTemplateIDPUTRequestFromJSON(json: any): TemplatesTemplateIDPUTRequest {
    return TemplatesTemplateIDPUTRequestFromJSONTyped(json, false);
}

export function TemplatesTemplateIDPUTRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplatesTemplateIDPUTRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'templateTitle': json['template_title'],
        'templateStatus': !exists(json, 'template_status') ? undefined : json['template_status'],
        'brandId': !exists(json, 'brand_id') ? undefined : json['brand_id'],
        'brandCode': !exists(json, 'brand_code') ? undefined : json['brand_code'],
        'primaryTemplateCategoryItemId': !exists(json, 'primary_template_category_item_id') ? undefined : json['primary_template_category_item_id'],
        'subcategoryItems': !exists(json, 'subcategory_items') ? undefined : json['subcategory_items'],
        'replaceSubcategoryItems': !exists(json, 'replace_subcategory_items') ? undefined : json['replace_subcategory_items'],
    };
}

export function TemplatesTemplateIDPUTRequestToJSON(value?: TemplatesTemplateIDPUTRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'template_title': value.templateTitle,
        'template_status': value.templateStatus,
        'brand_id': value.brandId,
        'brand_code': value.brandCode,
        'primary_template_category_item_id': value.primaryTemplateCategoryItemId,
        'subcategory_items': value.subcategoryItems,
        'replace_subcategory_items': value.replaceSubcategoryItems,
    };
}

