/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Auth0 options associated with agent
 * @export
 * @interface GetAgents200ResponseAgentsInnerAuth0Options
 */
export interface GetAgents200ResponseAgentsInnerAuth0Options {
    /**
     * logout urls allowed in auth0 for this agent
     * @type {Array<string>}
     * @memberof GetAgents200ResponseAgentsInnerAuth0Options
     */
    allowedLogoutURLs?: Array<string>;
    /**
     * origins allowed in auth0 for this agent
     * @type {Array<string>}
     * @memberof GetAgents200ResponseAgentsInnerAuth0Options
     */
    allowedOrigins?: Array<string>;
    /**
     * auth0 callbacks for this agent
     * @type {Array<string>}
     * @memberof GetAgents200ResponseAgentsInnerAuth0Options
     */
    callbacks?: Array<string>;
    /**
     * auth0 web origins for this agent
     * @type {Array<string>}
     * @memberof GetAgents200ResponseAgentsInnerAuth0Options
     */
    webOrigins?: Array<string>;
    /**
     * logo uri for this agent
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerAuth0Options
     */
    logoURI?: string;
    /**
     * if the agent is first party
     * @type {boolean}
     * @memberof GetAgents200ResponseAgentsInnerAuth0Options
     */
    readonly isFirstParty?: boolean;
    /**
     * Leave blank to create an agent for exclusive use by individuals. Set to 'require' to create an agent
     * for use by org admins exclusively, and set to 'allow' to create an agent for use by either individuals
     * or org admins
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerAuth0Options
     */
    organizationUsage?: GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum | null;
}


/**
 * @export
 */
export const GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum = {
    Require: 'require',
    Allow: 'allow'
} as const;
export type GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum = typeof GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum[keyof typeof GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum];


/**
 * Check if a given object implements the GetAgents200ResponseAgentsInnerAuth0Options interface.
 */
export function instanceOfGetAgents200ResponseAgentsInnerAuth0Options(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON(json: any): GetAgents200ResponseAgentsInnerAuth0Options {
    return GetAgents200ResponseAgentsInnerAuth0OptionsFromJSONTyped(json, false);
}

export function GetAgents200ResponseAgentsInnerAuth0OptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgents200ResponseAgentsInnerAuth0Options {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedLogoutURLs': !exists(json, 'allowedLogoutURLs') ? undefined : json['allowedLogoutURLs'],
        'allowedOrigins': !exists(json, 'allowedOrigins') ? undefined : json['allowedOrigins'],
        'callbacks': !exists(json, 'callbacks') ? undefined : json['callbacks'],
        'webOrigins': !exists(json, 'webOrigins') ? undefined : json['webOrigins'],
        'logoURI': !exists(json, 'logoURI') ? undefined : json['logoURI'],
        'isFirstParty': !exists(json, 'isFirstParty') ? undefined : json['isFirstParty'],
        'organizationUsage': !exists(json, 'organizationUsage') ? undefined : json['organizationUsage'],
    };
}

export function GetAgents200ResponseAgentsInnerAuth0OptionsToJSON(value?: GetAgents200ResponseAgentsInnerAuth0Options | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedLogoutURLs': value.allowedLogoutURLs,
        'allowedOrigins': value.allowedOrigins,
        'callbacks': value.callbacks,
        'webOrigins': value.webOrigins,
        'logoURI': value.logoURI,
        'organizationUsage': value.organizationUsage,
    };
}

