// Copyright 2024 Merit International Inc. All Rights Reserved.

import { Helpers } from "@merit/frontend-utils";
import { allAuthScopesString, audience } from "./common";
import { useAlertStore } from "../../stores/alertStore";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import Constants from "expo-constants";
import type { AuthorizationParams } from "@auth0/auth0-spa-js/src/global";
import type { Configuration as EnvConfig } from "@src/configuration";
import type { GenericError } from "@auth0/auth0-spa-js";
import type { JwtClaims } from "@src/types/auth";
import type { MeritUserInfo } from "../../types/user";
import type { UseMeritAuth0 } from "../../types/auth";

const { Some } = Helpers;

const envConfig = Constants.manifest?.extra as EnvConfig;

type PromptType = AuthorizationParams["prompt"];

const useLogin = () => {
  const { deleteAlert, setAlert } = useAlertStore();
  const { loginWithRedirect } = useAuth0();

  const doPkceFlow = async (promptType: PromptType, auth0_org_id?: string) => {
    try {
      const scope = allAuthScopesString;

      const params =
        promptType === "login"
          ? {
              authorizationParams: {
                audience,
                prompt: promptType,
                redirect_uri: `${window.location.origin}/login-success`,
                scope,
              },
            }
          : {
              authorizationParams: {
                audience,
                organization: auth0_org_id,
                prompt: promptType,
                redirect_uri: `${window.location.origin}/login-success`,
                scope,
              },
            };
      await loginWithRedirect(params);
    } catch (err) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: `Failed to login: ${String(err)}`,
        type: "error",
      });
    }
  };

  const doLoginFlow = async () => {
    await doPkceFlow("login");
  };

  const doSwitchOrgFlow = async (auth0_org_id: string) => {
    await doPkceFlow("select_account", auth0_org_id);
  };

  return { doLoginFlow, doSwitchOrgFlow };
};

const useLogout = () => {
  const { errorHandler } = useServerErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const { logout: auth0Logout } = useAuth0();

  const logout = () => {
    try {
      setIsLoading(true);

      auth0Logout({
        logoutParams: {
          returnTo: `${window.location.origin}/logout-success`,
        },
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, logout };
};

const useMeritAuth0 = (): UseMeritAuth0 => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading, user } =
    useAuth0<MeritUserInfo>();
  const config = envConfig;
  const [accessToken, setAccessToken] = useState<string>();
  const [idToken, setIdToken] = useState<string>();
  const [profile, setProfile] = useState<JwtClaims | undefined>();
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>();
  const [selectedOrgName, setSelectedOrgName] = useState<string | undefined>();
  useEffect(() => {
    // eslint-disable-next-line functional/no-let
    let hasCleanedUp = false;
    const setTokens = async () => {
      // eslint-disable-next-line functional/no-let
      let theAccessToken = "";
      try {
        theAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience,
            scope: allAuthScopesString,
          },
        });
      } catch (err) {
        // catch and suppress "login_required" errors, it's NOT indicative of a real problem, it's a catchable error in case we want to handle it
        // see also https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
        // and https://github.com/auth0/auth0-spa-js/blob/main/EXAMPLES.md#refresh-token-fallback
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        if ((err as GenericError | undefined)?.error !== "login_required") {
          throw err;
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      const theIdToken = (await getIdTokenClaims())?.__raw;
      if (!hasCleanedUp) {
        setAccessToken(theAccessToken);
        setIdToken(theIdToken);
      }
    };

    // Only try to get the accessToken if we are authenticated to avoid console errors
    if (Some(isAuthenticated) && Boolean(isAuthenticated)) {
      setTokens();
    }

    return () => {
      hasCleanedUp = true;
    };
  }, [config.api.orgPortal.baseUrl, getAccessTokenSilently, getIdTokenClaims, isAuthenticated]);

  useEffect(() => {
    const getInformation = async () => {
      const claims = await getIdTokenClaims();
      setSelectedOrgId(claims?.org_uuid);
      setSelectedOrgName(claims?.org_display_name);
      if (user?.email !== undefined) {
        setProfile(user as JwtClaims);
      }
    };
    getInformation();
  }, [getIdTokenClaims, user]);

  return {
    accessToken: accessToken ?? "",
    idToken: idToken ?? "",
    isAuthenticated,
    isLoading,
    profile,
    selectedOrgId,
    selectedOrgName,
    // Looks weird, but convert undefined to null for matching API
    user: user === undefined ? null : user,
  };
};

export { useLogin, useLogout, useMeritAuth0 };
