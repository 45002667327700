/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Export credential request body
 * @export
 * @interface ExportCredentialsAsMemberRequest
 */
export interface ExportCredentialsAsMemberRequest {
    /**
     * Container ids whose credentials are to be exported
     * @type {Array<string>}
     * @memberof ExportCredentialsAsMemberRequest
     */
    containerIds: Array<string>;
    /**
     * EntityID for the requesting user
     * @type {string}
     * @memberof ExportCredentialsAsMemberRequest
     */
    userEntityId: string;
    /**
     * User access token
     * @type {string}
     * @memberof ExportCredentialsAsMemberRequest
     */
    userAccessToken: string;
}

/**
 * Check if a given object implements the ExportCredentialsAsMemberRequest interface.
 */
export function instanceOfExportCredentialsAsMemberRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "containerIds" in value;
    isInstance = isInstance && "userEntityId" in value;
    isInstance = isInstance && "userAccessToken" in value;

    return isInstance;
}

export function ExportCredentialsAsMemberRequestFromJSON(json: any): ExportCredentialsAsMemberRequest {
    return ExportCredentialsAsMemberRequestFromJSONTyped(json, false);
}

export function ExportCredentialsAsMemberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCredentialsAsMemberRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerIds': json['containerIds'],
        'userEntityId': json['userEntityId'],
        'userAccessToken': json['userAccessToken'],
    };
}

export function ExportCredentialsAsMemberRequestToJSON(value?: ExportCredentialsAsMemberRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerIds': value.containerIds,
        'userEntityId': value.userEntityId,
        'userAccessToken': value.userAccessToken,
    };
}

