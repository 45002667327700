/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of a DataSource search result.
 * @export
 * @interface DataSourceSearchResult
 */
export interface DataSourceSearchResult {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof DataSourceSearchResult
     */
    id?: string;
    /**
     * The name of the object
     * @type {string}
     * @memberof DataSourceSearchResult
     */
    name?: string;
    /**
     * The UUID of this object's owner
     * @type {string}
     * @memberof DataSourceSearchResult
     */
    owner?: string;
    /**
     * The last ingested date for this DataSource.
     * @type {Date}
     * @memberof DataSourceSearchResult
     */
    lastIngested?: Date | null;
}

/**
 * Check if a given object implements the DataSourceSearchResult interface.
 */
export function instanceOfDataSourceSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataSourceSearchResultFromJSON(json: any): DataSourceSearchResult {
    return DataSourceSearchResultFromJSONTyped(json, false);
}

export function DataSourceSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'lastIngested': !exists(json, 'lastIngested') ? undefined : (json['lastIngested'] === null ? null : new Date(json['lastIngested'])),
    };
}

export function DataSourceSearchResultToJSON(value?: DataSourceSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner': value.owner,
        'lastIngested': value.lastIngested === undefined ? undefined : (value.lastIngested === null ? null : value.lastIngested.toISOString()),
    };
}

