/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInner } from './OrgsGet200ResponseContainersInner';
import {
    OrgsGet200ResponseContainersInnerFromJSON,
    OrgsGet200ResponseContainersInnerFromJSONTyped,
    OrgsGet200ResponseContainersInnerToJSON,
} from './OrgsGet200ResponseContainersInner';

/**
 * 
 * @export
 * @interface GetOrgDetails200Response
 */
export interface GetOrgDetails200Response {
    /**
     * 
     * @type {string}
     * @memberof GetOrgDetails200Response
     */
    logoUploadURL: string;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInner}
     * @memberof GetOrgDetails200Response
     */
    org: OrgsGet200ResponseContainersInner;
}

/**
 * Check if a given object implements the GetOrgDetails200Response interface.
 */
export function instanceOfGetOrgDetails200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "logoUploadURL" in value;
    isInstance = isInstance && "org" in value;

    return isInstance;
}

export function GetOrgDetails200ResponseFromJSON(json: any): GetOrgDetails200Response {
    return GetOrgDetails200ResponseFromJSONTyped(json, false);
}

export function GetOrgDetails200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrgDetails200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logoUploadURL': json['logoUploadURL'],
        'org': OrgsGet200ResponseContainersInnerFromJSON(json['org']),
    };
}

export function GetOrgDetails200ResponseToJSON(value?: GetOrgDetails200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logoUploadURL': value.logoUploadURL,
        'org': OrgsGet200ResponseContainersInnerToJSON(value.org),
    };
}

