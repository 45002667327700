// Copyright 2024 Merit International Inc. All Rights Reserved

import { ApprovalDetails } from "./ApprovalDetails";
import { Body, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { Drawer } from "../../components/Drawer";
import { Folios } from "./Folios";
import { Helpers } from "@merit/frontend-utils";
import { ListTemplatesTypeEnum, ResponseError } from "../../gen/org-portal";
import { Merits } from "./Merits";
import { Pagination, Spin } from "../../components";
import { ScrollView, StyleSheet, View } from "react-native";
import { TemplatesFilter } from "../Templates/TemplatesFilter";
import { VerticalSpacer } from "../../components/Spacer";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useApprovalsData } from "./useApprovalsData";
import { useDisplayAlert } from "@src/hooks/useDisplayAlert";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useState } from "react";
import type {
  ListTemplates200ResponseTemplatesInnerTypeEnum,
  OrgsGet200ResponseContainersInner,
} from "../../gen/org-portal";
import type { ReactNode } from "react";

const THE_END_OF_TIME = "6666-12-01T00:00:00.000000Z";

const { None, Some } = Helpers;
export const SCREEN_NAME = "Approvals";

export type ContainerWithTemplateType = OrgsGet200ResponseContainersInner & {
  readonly templateType: ListTemplates200ResponseTemplatesInnerTypeEnum;
};

export const Templates = () => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const [filterValue, setFilterValue] = useState<ListTemplatesTypeEnum>(
    ListTemplatesTypeEnum.Merit
  );
  const [isApiCalling, setIsApiCalling] = useState(false);
  const { displayAlert } = useDisplayAlert();
  const [confirmationModal, setConfirmationModal] = useState<ReactNode>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [approvalData, setApprovalData] = useState<ContainerWithTemplateType>();
  const appConstants = useAppConstantsStore();
  const { errorHandler } = useServerErrorHandler();
  const [authorizedOrRemovedContainers, setAuthorizedOrRemovedContainers] = useState<
    readonly string[]
  >([]);

  const { data, isLoading, nextPage, pagination, prevPage, refresh } = useApprovalsData(api, {
    end: THE_END_OF_TIME,
    issuerID: selectedOrgId,
    limit: appConstants.approvalsLimit,
    orgID: selectedOrgId,
    sortBy: "createdAt",
    state: "unissued",
    templateType: ListTemplatesTypeEnum.Merit,
  });

  const approvalContainers = (data ?? []).filter(
    ({ id }) => !authorizedOrRemovedContainers.includes(id)
  );

  const styles = StyleSheet.create({
    wrapper: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
  });

  const deleteContainer = async (
    containerID: string,
    templateType: ListTemplates200ResponseTemplatesInnerTypeEnum
  ) => {
    if (Some(containerID) && Some(selectedOrgId)) {
      try {
        setIsApiCalling(true);

        await api.deleteContainer({
          containerID: String(containerID),
          orgID: selectedOrgId,
        });

        setIsDrawerOpen(false);
        if (approvalContainers.length === 1 && pagination.hasPrevPage) {
          prevPage();
        } else {
          refresh({
            templateType,
          });
        }

        setAuthorizedOrRemovedContainers([...authorizedOrRemovedContainers, containerID]);

        displayAlert({
          testProps: {
            elementName: "removeSuccess",
            screenName: SCREEN_NAME,
          },
          text: `A ${templateType.toLowerCase()} has been removed.`,
        });
      } catch (error) {
        errorHandler(error, {
          elementName: "removeError",
          screenName: SCREEN_NAME,
        });
      } finally {
        setIsApiCalling(false);
      }
    }
  };

  const approveContainer = async (
    containerID: string,
    name: string | undefined,
    templateType: ListTemplates200ResponseTemplatesInnerTypeEnum
  ) => {
    if (Some(containerID) && Some(selectedOrgId)) {
      try {
        setIsApiCalling(true);

        await api.authorizeContainer({
          containerID: String(containerID),
          orgID: selectedOrgId,
        });

        setIsDrawerOpen(false);

        refresh({
          templateType,
        });

        setAuthorizedOrRemovedContainers([...authorizedOrRemovedContainers, containerID]);

        displayAlert({
          testProps: {
            elementName: "approveSuccess",
            screenName: SCREEN_NAME,
          },
          text: `A ${templateType.toLowerCase()} has been approved.`,
        });
      } catch (error) {
        if (error instanceof ResponseError) {
          const errResponse: { readonly errors: readonly string[] } = await error.response
            .clone()
            .json();
          const errorMessage = errResponse.errors.toString();
          if (
            errorMessage.includes("state pending cannot transition to state pending") ||
            errorMessage.includes("state accepted cannot transition to state pending")
          ) {
            displayAlert({
              text: `The container ${
                name ?? containerID
              } has already been approved. Please refresh the page.`,
              type: "error",
            });
          } else {
            errorHandler(error);
          }
        } else {
          errorHandler(error);
        }
      } finally {
        setIsApiCalling(false);
      }
    }
  };

  return (
    <>
      <View style={styles.wrapper}>
        <TemplatesFilter
          defaultFilters={{ type: filterValue }}
          onSelect={values => {
            if (None(values) || None(values.type)) {
              return;
            }
            setFilterValue(values.type);
            refresh({ templateType: values.type });
          }}
          showStatus={false}
        />
        <VerticalSpacer size={theme.spacing.xxl} />
        {(isLoading || isApiCalling) && (
          <View style={{ flex: 1, justifyContent: "center", minHeight: 200 }}>
            <Spin />
          </View>
        )}
        {!(isLoading || isApiCalling) && (
          <ScrollView>
            {Some(approvalContainers) && approvalContainers.length === 0 ? (
              <View style={{ alignItems: "center", padding: theme.spacing.xxl }}>
                <Body
                  testProps={{
                    elementName: "approvalListNoDataPlaceholder",
                    screenName: SCREEN_NAME,
                  }}
                >
                  No data found
                </Body>
              </View>
            ) : (
              <>
                {filterValue === ListTemplatesTypeEnum.Merit && (
                  <Merits
                    merits={approvalContainers}
                    onApproveContainer={(id, name) => approveContainer(id, name, "Merit")}
                    onRemoveContainer={id => {
                      setConfirmationModal(
                        <ConfirmationModal
                          buttonText="remove"
                          onClose={() => {
                            setConfirmationModal(undefined);
                          }}
                          onOk={() => {
                            setConfirmationModal(undefined);
                            deleteContainer(id, "Merit");
                          }}
                          testProps={{
                            elementName: "meritRejectConfirmationModal",
                            screenName: SCREEN_NAME,
                          }}
                          text="Are you sure you want to remove this merit as an admin? You cannot view this merit in your list anymore if you remove it."
                          title="Remove this merit"
                        />
                      );
                    }}
                    onViewContainer={container => {
                      setApprovalData(container);
                      setIsDrawerOpen(true);
                    }}
                  />
                )}
                {filterValue === ListTemplatesTypeEnum.Folio && (
                  <Folios
                    folios={approvalContainers}
                    onApproveContainer={(id, name) => approveContainer(id, name, "Folio")}
                    onRemoveContainer={id => {
                      setConfirmationModal(
                        <ConfirmationModal
                          buttonText="remove"
                          onClose={() => {
                            setConfirmationModal(undefined);
                          }}
                          onOk={() => {
                            setConfirmationModal(undefined);
                            deleteContainer(id, "Folio");
                          }}
                          testProps={{
                            elementName: "folioRejectConfirmationModal",
                            screenName: SCREEN_NAME,
                          }}
                          text="Are you sure you want to remove this folio as an admin? You cannot view this folio in your list anymore if you remove it."
                          title="Remove this folio"
                        />
                      );
                    }}
                    onViewContainer={container => {
                      setApprovalData(container);
                      setIsDrawerOpen(true);
                    }}
                  />
                )}
              </>
            )}
          </ScrollView>
        )}

        <Pagination
          disableNext={!pagination.hasNextPage || isLoading}
          disablePrev={!pagination.hasPrevPage || isLoading}
          onNext={nextPage}
          onPrev={prevPage}
          testProps={{
            elementName: `${filterValue}ListView`,
            screenName: SCREEN_NAME,
          }}
        />
      </View>

      {confirmationModal}

      <Drawer isOpen={isDrawerOpen}>
        {Some(approvalData) && (
          <ApprovalDetails
            containerData={approvalData}
            onApproveContainer={(id, name) => approveContainer(id, name, approvalData.templateType)}
            onClose={() => {
              setIsDrawerOpen(false);
            }}
            onRemoveContainer={id => {
              setConfirmationModal(
                <ConfirmationModal
                  buttonText="remove"
                  onClose={() => {
                    setConfirmationModal(undefined);
                  }}
                  onOk={() => {
                    setConfirmationModal(undefined);
                    deleteContainer(id, approvalData.templateType);
                  }}
                  testProps={{
                    elementName: "rejectConfirmationModal",
                    screenName: SCREEN_NAME,
                  }}
                  text={`Are you sure you want to remove this ${
                    approvalData.templateType
                  } as an admin? You cannot view this ${approvalData.templateType.toLowerCase()} in your list anymore if you remove it.`}
                  title={`Remove this ${approvalData.templateType} `}
                />
              );
            }}
          />
        )}
      </Drawer>
    </>
  );
};
