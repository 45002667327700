// Copyright 2022 Merit International Inc. All Rights Reserved.

export function AssertIsDefined<T>(value: T, msg: string): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(msg);
  }
}

export function AssertIsString(value: unknown, msg: string): asserts value is string {
  if (typeof value !== "string") {
    throw new Error(msg);
  }
}
