/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * a formula that can be used to define a policy.
 * @export
 * @interface GetPolicies200ResponsePoliciesInnerResponseFormulasOwn
 */
export interface GetPolicies200ResponsePoliciesInnerResponseFormulasOwn {
    /**
     * 
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInnerResponseFormulasOwn
     */
    formulaType: GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFormulaTypeEnum;
}


/**
 * @export
 */
export const GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFormulaTypeEnum = {
    Conjunction: 'Conjunction',
    Disjunction: 'Disjunction',
    CompoundAtom: 'CompoundAtom'
} as const;
export type GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFormulaTypeEnum = typeof GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFormulaTypeEnum[keyof typeof GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFormulaTypeEnum];


/**
 * Check if a given object implements the GetPolicies200ResponsePoliciesInnerResponseFormulasOwn interface.
 */
export function instanceOfGetPolicies200ResponsePoliciesInnerResponseFormulasOwn(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "formulaType" in value;

    return isInstance;
}

export function GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSON(json: any): GetPolicies200ResponsePoliciesInnerResponseFormulasOwn {
    return GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSONTyped(json, false);
}

export function GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPolicies200ResponsePoliciesInnerResponseFormulasOwn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formulaType': json['formulaType'],
    };
}

export function GetPolicies200ResponsePoliciesInnerResponseFormulasOwnToJSON(value?: GetPolicies200ResponsePoliciesInnerResponseFormulasOwn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formulaType': value.formulaType,
    };
}

