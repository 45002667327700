// Copyright 2023 Merit International Inc. All Rights Reserved

import { Text } from "react-native";
import { useState } from "react";

/*
  Text wrapper which will shrink the font size to match the container.

  Does not comply with the font sizes used in Merit's design system.
  Does not work with extremely long single words, since the browser will not
  wrap the lines.
*/

type Props = {
  readonly text: string;
  readonly bold?: boolean;
  readonly testID?: string;
};

export const ShrinkText = ({ bold = false, testID, text }: Props) => {
  const [dynamicFontSize, setDynamicFontSize] = useState<number>(18);
  const [renderKey, setRenderKey] = useState<number>(0);

  return (
    <Text
      key={renderKey}
      onLayout={e => {
        if (e.nativeEvent.layout.height > 20) {
          // Should find the correct font size in under 7 renders
          const delta = e.nativeEvent.layout.height > 40 ? 4 : 1;
          setDynamicFontSize(dynamicFontSize - delta);
          setRenderKey(renderKey + 1);
        }
      }}
      style={{
        fontFamily: bold ? "ProximaNovaSemiBold" : "ProximaNova",
        fontSize: dynamicFontSize,
        fontWeight: "400",
        lineHeight: 20,
      }}
      testID={testID}
    >
      {text}
    </Text>
  );
};
