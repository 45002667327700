// Copyright 2024 Merit International Inc. All Rights Reserved.

import { DefaultLayout } from "@src/layouts/DefaultLayout";
import { DetailsDrawer } from "@src/layouts/DetailsDrawer";
import { FullScreenModalLayout } from "@src/layouts/FullScreenModalLayout";
import { NewDefaultLayout } from "@src/layouts/NewDefaultLayout";
import { PreLoginLayout } from "@src/layouts/PreLoginLayout";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";

type Layouts = {
  readonly DefaultLayout: typeof DefaultLayout | typeof NewDefaultLayout;
  readonly FullScreenModalLayout: typeof FullScreenModalLayout;
  readonly PreLoginLayout: typeof PreLoginLayout;
  readonly DetailsDrawer: typeof DetailsDrawer;
};

export const useFlaggedLayout = (): Layouts => {
  const { showUpdatedOrgPortalNavigation } = useFlags<LDFeatureFlags>();

  return {
    DefaultLayout: showUpdatedOrgPortalNavigation ? NewDefaultLayout : DefaultLayout,
    DetailsDrawer,
    FullScreenModalLayout,
    PreLoginLayout,
  };
};
