/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntityInfo
 */
export interface EntityInfo {
    /**
     * 
     * @type {string}
     * @memberof EntityInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EntityInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityInfo
     */
    type: EntityInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityInfo
     */
    state: EntityInfoStateEnum;
}


/**
 * @export
 */
export const EntityInfoTypeEnum = {
    Organization: 'organization',
    Person: 'person'
} as const;
export type EntityInfoTypeEnum = typeof EntityInfoTypeEnum[keyof typeof EntityInfoTypeEnum];

/**
 * @export
 */
export const EntityInfoStateEnum = {
    None: 'none',
    Invited: 'invited',
    Active: 'active',
    Rejected: 'rejected',
    Removed: 'removed'
} as const;
export type EntityInfoStateEnum = typeof EntityInfoStateEnum[keyof typeof EntityInfoStateEnum];


/**
 * Check if a given object implements the EntityInfo interface.
 */
export function instanceOfEntityInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function EntityInfoFromJSON(json: any): EntityInfo {
    return EntityInfoFromJSONTyped(json, false);
}

export function EntityInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': json['type'],
        'state': json['state'],
    };
}

export function EntityInfoToJSON(value?: EntityInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'state': value.state,
    };
}

