/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  SearchQuery,
  SearchResults,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    SearchQueryFromJSON,
    SearchQueryToJSON,
    SearchResultsFromJSON,
    SearchResultsToJSON,
} from '../models/index';

export interface GetSearchResultsRequest {
    authorization?: string;
    searchQuery?: SearchQuery;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     * `read:container`  Run a search for Merit objects 
     * Search
     */
    async getSearchResultsRaw(requestParameters: GetSearchResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchQueryToJSON(requestParameters.searchQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultsFromJSON(jsonValue));
    }

    /**
     * `read:container`  Run a search for Merit objects 
     * Search
     */
    async getSearchResults(requestParameters: GetSearchResultsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResults> {
        const response = await this.getSearchResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
