/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EditTemplateMapRequestColumnToTemplateFieldInner } from './EditTemplateMapRequestColumnToTemplateFieldInner';
import {
    EditTemplateMapRequestColumnToTemplateFieldInnerFromJSON,
    EditTemplateMapRequestColumnToTemplateFieldInnerFromJSONTyped,
    EditTemplateMapRequestColumnToTemplateFieldInnerToJSON,
} from './EditTemplateMapRequestColumnToTemplateFieldInner';

/**
 * The required inputs to update a data source mapping.
 * @export
 * @interface EditTemplateMapRequest
 */
export interface EditTemplateMapRequest {
    /**
     * The mappings from column to template field.
     * @type {Array<EditTemplateMapRequestColumnToTemplateFieldInner>}
     * @memberof EditTemplateMapRequest
     */
    columnToTemplateField: Array<EditTemplateMapRequestColumnToTemplateFieldInner>;
}

/**
 * Check if a given object implements the EditTemplateMapRequest interface.
 */
export function instanceOfEditTemplateMapRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "columnToTemplateField" in value;

    return isInstance;
}

export function EditTemplateMapRequestFromJSON(json: any): EditTemplateMapRequest {
    return EditTemplateMapRequestFromJSONTyped(json, false);
}

export function EditTemplateMapRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTemplateMapRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnToTemplateField': ((json['columnToTemplateField'] as Array<any>).map(EditTemplateMapRequestColumnToTemplateFieldInnerFromJSON)),
    };
}

export function EditTemplateMapRequestToJSON(value?: EditTemplateMapRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnToTemplateField': ((value.columnToTemplateField as Array<any>).map(EditTemplateMapRequestColumnToTemplateFieldInnerToJSON)),
    };
}

