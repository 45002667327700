/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  TemplateDataSourceMapping,
  TemplateDataSourceMappingPatch,
  TemplateDataSourceMappingProperties,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    TemplateDataSourceMappingFromJSON,
    TemplateDataSourceMappingToJSON,
    TemplateDataSourceMappingPatchFromJSON,
    TemplateDataSourceMappingPatchToJSON,
    TemplateDataSourceMappingPropertiesFromJSON,
    TemplateDataSourceMappingPropertiesToJSON,
} from '../models/index';

export interface MapDataSourceRequest {
    templateID: string;
    authorization?: string;
    properties?: TemplateDataSourceMappingProperties;
}

export interface PatchDataSourceMapRequest {
    templateID: string;
    authorization?: string;
    properties?: TemplateDataSourceMappingPatch;
}

/**
 * 
 */
export class DataMappingApi extends runtime.BaseAPI {

    /**
     * `update:template`  Create a mapping of data source columns to template fields 
     * Map Template
     */
    async mapDataSourceRaw(requestParameters: MapDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateDataSourceMapping>> {
        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling mapDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates/{templateID}/map`.replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateDataSourceMappingPropertiesToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateDataSourceMappingFromJSON(jsonValue));
    }

    /**
     * `update:template`  Create a mapping of data source columns to template fields 
     * Map Template
     */
    async mapDataSource(requestParameters: MapDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateDataSourceMapping> {
        const response = await this.mapDataSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:template`  Update a template data mapping 
     * Update Template Mapping
     */
    async patchDataSourceMapRaw(requestParameters: PatchDataSourceMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateDataSourceMapping>> {
        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling patchDataSourceMap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates/{templateID}/map`.replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateDataSourceMappingPatchToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateDataSourceMappingFromJSON(jsonValue));
    }

    /**
     * `update:template`  Update a template data mapping 
     * Update Template Mapping
     */
    async patchDataSourceMap(requestParameters: PatchDataSourceMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateDataSourceMapping> {
        const response = await this.patchDataSourceMapRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
