/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * The required inputs to extending a FieldKind from the source org's FieldKind.
 * @export
 * @interface FieldKindExtension
 */
export interface FieldKindExtension {
    /**
     * The new name for a FieldKind.
     * @type {string}
     * @memberof FieldKindExtension
     */
    name?: string | null;
    /**
     * The new description for a FieldKind. Since this is an optional field, if the field is missing or set to null, then the field will remain unchanged. If the field is set to empty string, then the new value would be empty string.
     * @type {string}
     * @memberof FieldKindExtension
     */
    description?: string | null;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof FieldKindExtension
     */
    permissions?: Array<PermissionPair>;
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<Atom>}
     * @memberof FieldKindExtension
     */
    ruleValid?: Array<Atom>;
    /**
     * 
     * @type {string}
     * @memberof FieldKindExtension
     */
    sourceFieldID: string;
}

/**
 * Check if a given object implements the FieldKindExtension interface.
 */
export function instanceOfFieldKindExtension(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceFieldID" in value;

    return isInstance;
}

export function FieldKindExtensionFromJSON(json: any): FieldKindExtension {
    return FieldKindExtensionFromJSONTyped(json, false);
}

export function FieldKindExtensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldKindExtension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'ruleValid': !exists(json, 'ruleValid') ? undefined : ((json['ruleValid'] as Array<any>).map(AtomFromJSON)),
        'sourceFieldID': json['sourceFieldID'],
    };
}

export function FieldKindExtensionToJSON(value?: FieldKindExtension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'ruleValid': value.ruleValid === undefined ? undefined : ((value.ruleValid as Array<any>).map(AtomToJSON)),
        'sourceFieldID': value.sourceFieldID,
    };
}

