/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExtendPolicyRequestFormula } from './ExtendPolicyRequestFormula';
import {
    ExtendPolicyRequestFormulaFromJSON,
    ExtendPolicyRequestFormulaFromJSONTyped,
    ExtendPolicyRequestFormulaToJSON,
} from './ExtendPolicyRequestFormula';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * information for extending a policy
 * @export
 * @interface ExtendPolicyRequest
 */
export interface ExtendPolicyRequest {
    /**
     * ID of the source policy
     * @type {string}
     * @memberof ExtendPolicyRequest
     */
    sourcePolicyID: string;
    /**
     * Name of the policy
     * @type {string}
     * @memberof ExtendPolicyRequest
     */
    name: string | null;
    /**
     * Description of the policy
     * @type {string}
     * @memberof ExtendPolicyRequest
     */
    description?: string | null;
    /**
     * Message when policy evaluates to true
     * @type {string}
     * @memberof ExtendPolicyRequest
     */
    trueMessage: string | null;
    /**
     * Message when policy evaluates to false
     * @type {string}
     * @memberof ExtendPolicyRequest
     */
    falseMessage: string | null;
    /**
     * Permissions of the new policy
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof ExtendPolicyRequest
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
    /**
     * Rule for the new policy
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof ExtendPolicyRequest
     */
    rule: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>;
    /**
     * 
     * @type {ExtendPolicyRequestFormula}
     * @memberof ExtendPolicyRequest
     */
    formula?: ExtendPolicyRequestFormula | null;
}

/**
 * Check if a given object implements the ExtendPolicyRequest interface.
 */
export function instanceOfExtendPolicyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourcePolicyID" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "trueMessage" in value;
    isInstance = isInstance && "falseMessage" in value;
    isInstance = isInstance && "rule" in value;

    return isInstance;
}

export function ExtendPolicyRequestFromJSON(json: any): ExtendPolicyRequest {
    return ExtendPolicyRequestFromJSONTyped(json, false);
}

export function ExtendPolicyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendPolicyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourcePolicyID': json['sourcePolicyID'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'trueMessage': json['trueMessage'],
        'falseMessage': json['falseMessage'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'rule': ((json['rule'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
        'formula': !exists(json, 'formula') ? undefined : ExtendPolicyRequestFormulaFromJSON(json['formula']),
    };
}

export function ExtendPolicyRequestToJSON(value?: ExtendPolicyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourcePolicyID': value.sourcePolicyID,
        'name': value.name,
        'description': value.description,
        'trueMessage': value.trueMessage,
        'falseMessage': value.falseMessage,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'rule': ((value.rule as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
        'formula': ExtendPolicyRequestFormulaToJSON(value.formula),
    };
}

