/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Column DataType corresponding to JSON schema data. Abides by the JSONSchema draft specification https://json-schema.org/specification.html
 * @export
 * @interface JSON
 */
export interface JSON extends DataType {
    /**
     * The JSON schema of the column, per https://json-schema.org/specification.html
     * @type {{ [key: string]: object; }}
     * @memberof JSON
     */
    schema: { [key: string]: object; };
}

/**
 * Check if a given object implements the JSON interface.
 */
export function instanceOfJSON(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "schema" in value;

    return isInstance;
}

export function JSONFromJSON(json: any): JSON {
    return JSONFromJSONTyped(json, false);
}

export function JSONFromJSONTyped(json: any, ignoreDiscriminator: boolean): JSON {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...DataTypeFromJSONTyped(json, ignoreDiscriminator),
        'schema': json['schema'],
    };
}

export function JSONToJSON(value?: JSON | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DataTypeToJSON(value),
        'schema': value.schema,
    };
}

