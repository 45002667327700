// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { StyleSheet, View } from "react-native";
import type { OPTestProps } from "../../../src/types/TestProps";

const Some = Helpers.Some;

type PaginationProps = {
  readonly disablePrev?: boolean;
  readonly disableNext?: boolean;
  readonly onPrev?: () => void;
  readonly onNext?: () => void;
  readonly testProps?: OPTestProps;
  readonly hasBottomMargin?: boolean;
};

export const Pagination = ({
  disableNext = false,
  disablePrev = false,
  hasBottomMargin = true,
  onNext,
  onPrev,
  testProps,
}: PaginationProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    buttonContainer: {
      flexDirection: "row",
    },
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "center",
      ...(hasBottomMargin && { marginBottom: theme.spacing.xl }),
      paddingBottom: theme.spacing.xl,
      paddingHorizontal: theme.spacing.xl,
      paddingTop: theme.spacing.l,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <Button
          disabled={disablePrev}
          iconLeft={disablePrev ? "chevronLeftMediumDisabled" : "chevronLeftMediumAction"}
          onPress={() => {
            if (onPrev !== undefined) {
              onPrev();
            }
          }}
          shape="square"
          size="small"
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `${testProps.elementName}PrevPaginationButton` }
              : testProps
          }
          type="secondary"
        />

        <View style={{ marginLeft: 8 }} />

        <Button
          disabled={disableNext}
          iconLeft={disableNext ? "chevronRightMediumDisabled" : "chevronRightMediumAction"}
          onPress={() => {
            if (onNext !== undefined) {
              onNext();
            }
          }}
          shape="square"
          size="small"
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `${testProps.elementName}NextPaginationButton` }
              : testProps
          }
          type="secondary"
        />
      </View>
    </View>
  );
};
