/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FieldKindPropsValidation } from './FieldKindPropsValidation';
import {
    FieldKindPropsValidationFromJSON,
    FieldKindPropsValidationFromJSONTyped,
    FieldKindPropsValidationToJSON,
} from './FieldKindPropsValidation';
import type { FieldType } from './FieldType';
import {
    FieldTypeFromJSON,
    FieldTypeFromJSONTyped,
    FieldTypeToJSON,
} from './FieldType';

/**
 * summary information describing a FieldKind
 * @export
 * @interface TemplateFieldKindInfo
 */
export interface TemplateFieldKindInfo {
    /**
     * The id of the field kind.
     * @type {string}
     * @memberof TemplateFieldKindInfo
     */
    id: string;
    /**
     * The assigned name of the field kind
     * @type {string}
     * @memberof TemplateFieldKindInfo
     */
    name?: string;
    /**
     * 
     * @type {FieldType}
     * @memberof TemplateFieldKindInfo
     */
    fieldType?: FieldType;
    /**
     * 
     * @type {FieldKindPropsValidation}
     * @memberof TemplateFieldKindInfo
     */
    validation?: FieldKindPropsValidation;
}

/**
 * Check if a given object implements the TemplateFieldKindInfo interface.
 */
export function instanceOfTemplateFieldKindInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function TemplateFieldKindInfoFromJSON(json: any): TemplateFieldKindInfo {
    return TemplateFieldKindInfoFromJSONTyped(json, false);
}

export function TemplateFieldKindInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateFieldKindInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fieldType': !exists(json, 'fieldType') ? undefined : FieldTypeFromJSON(json['fieldType']),
        'validation': !exists(json, 'validation') ? undefined : FieldKindPropsValidationFromJSON(json['validation']),
    };
}

export function TemplateFieldKindInfoToJSON(value?: TemplateFieldKindInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fieldType': FieldTypeToJSON(value.fieldType),
        'validation': FieldKindPropsValidationToJSON(value.validation),
    };
}

