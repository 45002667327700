/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Container,
  ContainerList,
  CreateContainer,
  Errors,
  PatchContainer,
} from '../models/index';
import {
    ContainerFromJSON,
    ContainerToJSON,
    ContainerListFromJSON,
    ContainerListToJSON,
    CreateContainerFromJSON,
    CreateContainerToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
    PatchContainerFromJSON,
    PatchContainerToJSON,
} from '../models/index';

export interface CreateContainerRequest {
    authorization?: string;
    properties?: CreateContainer;
}

export interface DeleteContainerRequest {
    containerID: string;
    authorization?: string;
}

export interface GetContainerRequest {
    containerID: string;
    authorization?: string;
}

export interface GetContainersRequest {
    authorization?: string;
    aggregateId?: string;
    completed?: boolean;
    issuerId?: string;
    recipientId?: string;
    templateId?: string;
    extendedFrom?: string;
    templateType?: GetContainersTemplateTypeEnum;
    startingAfter?: string;
    endingBefore?: string;
    limit?: number;
    sortBy?: GetContainersSortByEnum;
    state?: GetContainersStateEnum;
}

export interface PatchContainerRequest {
    containerID: string;
    properties: PatchContainer;
    authorization?: string;
}

/**
 * 
 */
export class ContainersApi extends runtime.BaseAPI {

    /**
     * `create:persona`  Create a persona for a person to share with an organization 
     * Create Persona
     */
    async createContainerRaw(requestParameters: CreateContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Container>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/containers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContainerToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerFromJSON(jsonValue));
    }

    /**
     * `create:persona`  Create a persona for a person to share with an organization 
     * Create Persona
     */
    async createContainer(requestParameters: CreateContainerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Container> {
        const response = await this.createContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `delete:container`  Remove a single container 
     * Delete Container
     */
    async deleteContainerRaw(requestParameters: DeleteContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling deleteContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/containers/{containerID}`.replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `delete:container`  Remove a single container 
     * Delete Container
     */
    async deleteContainer(requestParameters: DeleteContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteContainerRaw(requestParameters, initOverrides);
    }

    /**
     * `read:container`  Get a single container 
     * Get Container
     */
    async getContainerRaw(requestParameters: GetContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Container>> {
        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling getContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/containers/{containerID}`.replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerFromJSON(jsonValue));
    }

    /**
     * `read:container`  Get a single container 
     * Get Container
     */
    async getContainer(requestParameters: GetContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Container> {
        const response = await this.getContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:container`  Get a paginated list of containers 
     * Get Containers
     */
    async getContainersRaw(requestParameters: GetContainersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContainerList>> {
        const queryParameters: any = {};

        if (requestParameters.aggregateId !== undefined) {
            queryParameters['aggregate_id'] = requestParameters.aggregateId;
        }

        if (requestParameters.completed !== undefined) {
            queryParameters['completed'] = requestParameters.completed;
        }

        if (requestParameters.issuerId !== undefined) {
            queryParameters['issuer_id'] = requestParameters.issuerId;
        }

        if (requestParameters.recipientId !== undefined) {
            queryParameters['recipient_id'] = requestParameters.recipientId;
        }

        if (requestParameters.templateId !== undefined) {
            queryParameters['template_id'] = requestParameters.templateId;
        }

        if (requestParameters.extendedFrom !== undefined) {
            queryParameters['extended_from'] = requestParameters.extendedFrom;
        }

        if (requestParameters.templateType !== undefined) {
            queryParameters['template_type'] = requestParameters.templateType;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/containers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerListFromJSON(jsonValue));
    }

    /**
     * `read:container`  Get a paginated list of containers 
     * Get Containers
     */
    async getContainers(requestParameters: GetContainersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContainerList> {
        const response = await this.getContainersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `transition:container`  Authorize, accept, reject or revoke a container 
     * Update Container State
     */
    async patchContainerRaw(requestParameters: PatchContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Container>> {
        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling patchContainer.');
        }

        if (requestParameters.properties === null || requestParameters.properties === undefined) {
            throw new runtime.RequiredError('properties','Required parameter requestParameters.properties was null or undefined when calling patchContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/containers/{containerID}`.replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchContainerToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerFromJSON(jsonValue));
    }

    /**
     * `transition:container`  Authorize, accept, reject or revoke a container 
     * Update Container State
     */
    async patchContainer(requestParameters: PatchContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Container> {
        const response = await this.patchContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetContainersTemplateTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
} as const;
export type GetContainersTemplateTypeEnum = typeof GetContainersTemplateTypeEnum[keyof typeof GetContainersTemplateTypeEnum];
/**
 * @export
 */
export const GetContainersSortByEnum = {
    CreatedAt: 'createdAt',
    AuthorizedAt: 'authorizedAt',
    Name: 'name',
    UpdatedAt: 'updatedAt'
} as const;
export type GetContainersSortByEnum = typeof GetContainersSortByEnum[keyof typeof GetContainersSortByEnum];
/**
 * @export
 */
export const GetContainersStateEnum = {
    Unissued: 'unissued',
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Revoked: 'revoked'
} as const;
export type GetContainersStateEnum = typeof GetContainersStateEnum[keyof typeof GetContainersStateEnum];
