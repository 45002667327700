// Copyright 2024 Merit International Inc. All Rights Reserved

import { Log } from "@src/utils/logging";
import { useApi } from "@src/api/api";
import { useAuthStore, useConfigurationStore } from "@src/stores";
import { useQuery } from "@tanstack/react-query";
import type { OrgsGet200ResponseContainersInner } from "@src/gen/org-portal";
import type { UseQueryResult } from "@tanstack/react-query";

const queryKeyPrefix = "OrgPortalSelectedOrgAccountFolioDetails";

type QueryKey = readonly [typeof queryKeyPrefix, { readonly orgID: string }];
type UseOrgAccountFolioResult = OrgsGet200ResponseContainersInner;
type UseSelectedOrgAccountFolioDetails = () => UseQueryResult<UseOrgAccountFolioResult>;

export const useOrgAccountFolio: UseSelectedOrgAccountFolioDetails = () => {
  // Not using useLoggedInAuthState because of the asserts and this hook may be
  // used in the Router component, where there are code branches that the user is
  // not logged in
  const { selectedOrgId } = useAuthStore();
  const client = useApi();
  const configStore = useConfigurationStore();

  return useQuery<UseOrgAccountFolioResult, unknown, UseOrgAccountFolioResult, QueryKey>({
    enabled: selectedOrgId !== null,
    onError: error => {
      Log.error("error getting account folio", { error });
    },
    queryFn: async ({ queryKey }) => {
      if (configStore.configuration === undefined) {
        throw Error("Configuration is undefined");
      }

      const { orgID } = queryKey[1];

      const res = await client.api.getContainers({
        orgID,
        recipientID: orgID,
        templateID: configStore.configuration.accountFolioTemplateUUID,
      });

      const accountFolio = res.containers?.[0];
      if (accountFolio === undefined) {
        throw Error(`No account folio retrieved for currently selected org ${orgID}`);
      }

      return accountFolio;
    },
    queryKey: [
      queryKeyPrefix,
      {
        // coerce to string, safe to do so since query is disabled when selectedOrgId is null
        orgID: selectedOrgId ?? "",
      },
    ],
  });
};
