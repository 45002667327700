// Copyright 2023 Merit International Inc. All Rights Reserved

import * as AgentsApi from "../gen/agents/apis";
import * as IssuanceApi from "../gen/issuance/apis";
import * as WormholeApi from "../gen/wormhole/apis/";
import {
  Configuration as AgentsConfiguration,
  DefaultConfig as DefaultAgentsConfig,
} from "../gen/agents";
import { Configuration, DefaultConfig } from "../gen/org-portal";
import { DefaultApi } from "../gen/org-portal/apis/DefaultApi";
import {
  DefaultConfig as DefaultIssuanceConfig,
  Configuration as IssuanceConfiguration,
} from "../gen/issuance";
import {
  DefaultConfig as DefaultWormholeConfig,
  Configuration as WormholeConfiguration,
} from "../gen/wormhole";
import { useAuthStore } from "../stores/authStore";
import { useMemo } from "react";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "@src/configuration";
import type { ResponseContext } from "../gen/org-portal";

const envConfig = Constants.manifest?.extra as EnvConfig;

const BASE_API_CONFIG = {
  basePath: envConfig.api.orgPortal.baseUrl,
  headers: {},
};

export const useApi = () => {
  const { accessToken, clear: clearAuthStore, session, setSession } = useAuthStore();

  return useMemo(() => {
    const checkForUnauthorized = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>((resolve, reject) => {
        if (context.response.status === 401) {
          clearAuthStore();
          reject(Error("401 Unauthorized"));
        } else {
          resolve(context.response);
        }
      });

      return p;
    };

    // Disabled temporarily
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const useFreshToken = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>(resolve => {
        if (context.response.headers.has("X-Session-Store")) {
          const newSession = context.response.headers.get("X-Session-Store");
          setSession(newSession);
        }

        resolve(context.response);
      });

      return p;
    };

    const getHeaders = () => {
      if (accessToken !== null && session !== null) {
        return {
          ...BASE_API_CONFIG.headers,
          Authorization: `Bearer ${accessToken}`,
          "X-Session-Store": session,
        };
      }
      if (accessToken !== null) {
        return {
          ...BASE_API_CONFIG.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }

      return BASE_API_CONFIG.headers;
    };

    const notAuthorized = accessToken === null;
    const headers = getHeaders();
    const makeConfig = <T>(defaultConfig: T, url?: string): T => ({
      ...defaultConfig,
      basePath: url ?? BASE_API_CONFIG.basePath,
      headers,
      middleware: [notAuthorized ? {} : { post: checkForUnauthorized }],
    });

    const config = makeConfig(DefaultConfig);

    const api = new DefaultApi(new Configuration(config));

    const issuanceApis = {
      containerTemplates: new IssuanceApi.ContainerTemplatesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      containers: new IssuanceApi.ContainersApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      dataMapping: new IssuanceApi.DataMappingApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      entityMerges: new IssuanceApi.EntityMergesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      fieldKinds: new IssuanceApi.FieldKindsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      infrastructure: new IssuanceApi.InfrastructureApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      mergeRequests: new IssuanceApi.MergeRequestsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      policies: new IssuanceApi.PoliciesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      policyRequests: new IssuanceApi.PolicyRequestsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      search: new IssuanceApi.SearchApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      signedEntity: new IssuanceApi.SignedEntityApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      templateFields: new IssuanceApi.TemplateFieldsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
    };

    const agentsApi = {
      agentEntityLinks: new AgentsApi.AgentEntityLinksApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      agentVersions: new AgentsApi.AgentVersionsApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      agents: new AgentsApi.AgentsApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      infrastructure: new AgentsApi.InfrastructureApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
    };

    const wormholeApi = {
      dataSources: new WormholeApi.DataSourcesApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      files: new WormholeApi.FilesApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      infrastructure: new WormholeApi.InfrastructureApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      integrations: new WormholeApi.IntegrationsApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      records: new WormholeApi.RecordsApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
    };

    return { agentsApi, api, config, issuanceApis, wormholeApi };
  }, [clearAuthStore, setSession, accessToken, session]);
};
