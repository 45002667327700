/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEntityMerge,
  Errors,
} from '../models/index';
import {
    CreateEntityMergeFromJSON,
    CreateEntityMergeToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
} from '../models/index';

export interface CreateEntityMergeRequest {
    entityID: string;
    authorization?: string;
    properties?: CreateEntityMerge;
}

/**
 * 
 */
export class EntityMergesApi extends runtime.BaseAPI {

    /**
     * `merge:entity`  Merge two entities 
     * Create Entity Merge
     */
    async createEntityMergeRaw(requestParameters: CreateEntityMergeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entityID === null || requestParameters.entityID === undefined) {
            throw new runtime.RequiredError('entityID','Required parameter requestParameters.entityID was null or undefined when calling createEntityMerge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/entities/{entityID}/merges`.replace(`{${"entityID"}}`, encodeURIComponent(String(requestParameters.entityID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEntityMergeToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `merge:entity`  Merge two entities 
     * Create Entity Merge
     */
    async createEntityMerge(requestParameters: CreateEntityMergeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createEntityMergeRaw(requestParameters, initOverrides);
    }

}
