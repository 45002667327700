// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer } from "../Spacer";
import { ROUTES } from "../../Router";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import { useLinkProps } from "@react-navigation/native";
import React, { useRef } from "react";
import type { IconName } from "@merit/frontend-components";
import type { OPTestProps } from "../../../src/types/TestProps";
import type { RouteParams } from "../../Router";

type Props = {
  readonly iconName: IconName | null;
  readonly isActive: boolean;
  readonly title: string;
  readonly to: keyof RouteParams;
  readonly type?: "primary" | "small";
  readonly expanded: boolean;
  readonly disabled: boolean;
  readonly testProps?: OPTestProps;
};

export const NavLink = ({
  disabled: isDisabled,
  expanded = true,
  iconName,
  isActive,
  testProps,
  title,
  to,
  type = "primary",
}: Props) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const { theme } = useTheme();
  const { onPress, ...linkProps } = useLinkProps<RouteParams>({
    to: ROUTES[to],
  });

  const styles = StyleSheet.create({
    active: {
      backgroundColor: theme.colors.surface.pressed,
      borderRightColor: theme.colors.action.default,
      borderRightWidth: theme.borderRadii.s,
    },
    base: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      overflow: "hidden",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    disabled: {
      color: theme.colors.text.disabled,
    },
    hovered: {
      backgroundColor: theme.colors.surface.hovered,
      borderRightColor: theme.colors.action.default,
      borderRightWidth: theme.borderRadii.s,
    },
    selected: {
      fontWeight: theme.fontWeights.semiBold,
    },
  });

  return (
    <TouchableHighlight disabled={isDisabled} onPress={onPress} {...linkProps}>
      <View ref={ref} style={[styles.base, isHovered && styles.hovered, isActive && styles.active]}>
        {iconName !== null && (
          <>
            <Icon alt="" name={iconName} />
            <HorizontalSpacer />
          </>
        )}
        {expanded && (
          <>
            {isActive && type === "primary" && (
              <Heading
                level="4"
                numberOfLines={1}
                style={isDisabled && styles.disabled}
                testProps={testProps}
              >
                {title}
              </Heading>
            )}

            {isActive && type === "small" && (
              <Body
                numberOfLines={1}
                size="s"
                style={isDisabled ? styles.disabled : styles.selected}
                testProps={testProps}
              >
                {title}
              </Body>
            )}

            {!isActive && type === "primary" && (
              <Body
                numberOfLines={1}
                size="l"
                style={isDisabled && styles.disabled}
                testProps={testProps}
              >
                {title}
              </Body>
            )}
            {!isActive && type === "small" && (
              <Body
                numberOfLines={1}
                size="s"
                style={isDisabled && styles.disabled}
                testProps={testProps}
              >
                {title}
              </Body>
            )}
          </>
        )}
      </View>
    </TouchableHighlight>
  );
};
