/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Meta information on the service
 * @export
 * @interface ServiceInfo
 */
export interface ServiceInfo {
    /**
     * Current version of the service
     * @type {string}
     * @memberof ServiceInfo
     */
    version: string;
}

/**
 * Check if a given object implements the ServiceInfo interface.
 */
export function instanceOfServiceInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function ServiceInfoFromJSON(json: any): ServiceInfo {
    return ServiceInfoFromJSONTyped(json, false);
}

export function ServiceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
    };
}

export function ServiceInfoToJSON(value?: ServiceInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
    };
}

