/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Column DataType corresponding to Markdown data.
 * @export
 * @interface Markdown
 */
export interface Markdown extends DataType {
}

/**
 * Check if a given object implements the Markdown interface.
 */
export function instanceOfMarkdown(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MarkdownFromJSON(json: any): Markdown {
    return MarkdownFromJSONTyped(json, false);
}

export function MarkdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): Markdown {
    return json;
}

export function MarkdownToJSON(value?: Markdown | null): any {
    return value;
}

