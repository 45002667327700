// Copyright 2023 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";

const { None, Some } = Helpers;

export const getFullName = (firstName?: string, lastName?: string) => {
  if (Some(firstName) && Some(lastName)) {
    return `${firstName} ${lastName}`;
  }
  if (Some(firstName) && None(lastName)) {
    return firstName;
  }
  if (None(firstName) && Some(lastName)) {
    return lastName;
  }

  return "--";
};
