/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Formula } from './Formula';
import {
    FormulaFromJSON,
    FormulaFromJSONTyped,
    FormulaToJSON,
} from './Formula';

/**
 * An object that represents a failed condition for a formula.
 * @export
 * @interface FormulaErrorResult
 */
export interface FormulaErrorResult {
    /**
     * 
     * @type {Formula}
     * @memberof FormulaErrorResult
     */
    formula?: Formula;
    /**
     * 
     * @type {string}
     * @memberof FormulaErrorResult
     */
    message?: string;
}

/**
 * Check if a given object implements the FormulaErrorResult interface.
 */
export function instanceOfFormulaErrorResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormulaErrorResultFromJSON(json: any): FormulaErrorResult {
    return FormulaErrorResultFromJSONTyped(json, false);
}

export function FormulaErrorResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormulaErrorResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formula': !exists(json, 'formula') ? undefined : FormulaFromJSON(json['formula']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function FormulaErrorResultToJSON(value?: FormulaErrorResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formula': FormulaToJSON(value.formula),
        'message': value.message,
    };
}

