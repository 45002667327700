// Copyright 2024 Merit International Inc. All Rights Reserved.

import { useFlags } from "launchdarkly-react-client-sdk";
import { useLoggedInUserRoles } from "./loggedInUserRoles";
import { useMemo } from "react";
import { useRoute } from "@react-navigation/native";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";
import type { RouteParams } from "@src/Router";
import type { RouteProp } from "@react-navigation/native";

export const usePermissionChecker = () => {
  const { isCheckinAdmin, isDashboardAdmin, isDesignAdmin, isOrgAdmin } = useLoggedInUserRoles();
  const { name: routeName } = useRoute<RouteProp<RouteParams>>();
  const { params: routeParams } = useRoute<RouteProp<RouteParams, "OrgSettings">>();
  const { rolloutAdminPortalAuth0OrgAuth } = useFlags<LDFeatureFlags>();

  const permissions = useMemo(() => {
    // If feature flag is off, allow all permissions because adminRoles will not exist
    if (!rolloutAdminPortalAuth0OrgAuth) {
      return {
        hasAccess: true,
        hasAdminAccess: true,
        hasCheckinRouteAccess: true,
        hasDashboardRouteAccess: true,
        hasDesignRouteAccess: true,
      };
    }

    const hasAdminAccess = isOrgAdmin;
    const hasDesignRouteAccess = isDesignAdmin && routeName === "Studio";
    const hasDashboardRouteAccess = isDashboardAdmin && routeName === "Reports";
    const hasCheckinRouteAccess =
      isCheckinAdmin && routeName === "OrgSettings" && routeParams?.initialTab === "linkedApps";

    const hasAccess =
      hasAdminAccess || hasDesignRouteAccess || hasDashboardRouteAccess || hasCheckinRouteAccess;

    return {
      hasAccess,
      hasAdminAccess,
      hasCheckinRouteAccess,
      hasDashboardRouteAccess,
      hasDesignRouteAccess,
    };
  }, [
    isCheckinAdmin,
    isDashboardAdmin,
    isDesignAdmin,
    isOrgAdmin,
    rolloutAdminPortalAuth0OrgAuth,
    routeName,
    routeParams?.initialTab,
  ]);

  return permissions;
};
