/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgents200ResponseAgentsInner } from './GetAgents200ResponseAgentsInner';
import {
    GetAgents200ResponseAgentsInnerFromJSON,
    GetAgents200ResponseAgentsInnerFromJSONTyped,
    GetAgents200ResponseAgentsInnerToJSON,
} from './GetAgents200ResponseAgentsInner';

/**
 * An object that contains a list of agents.
 * @export
 * @interface GetAgents200Response
 */
export interface GetAgents200Response {
    /**
     * The agent list.
     * @type {Array<GetAgents200ResponseAgentsInner>}
     * @memberof GetAgents200Response
     */
    agents?: Array<GetAgents200ResponseAgentsInner>;
}

/**
 * Check if a given object implements the GetAgents200Response interface.
 */
export function instanceOfGetAgents200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAgents200ResponseFromJSON(json: any): GetAgents200Response {
    return GetAgents200ResponseFromJSONTyped(json, false);
}

export function GetAgents200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgents200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agents': !exists(json, 'agents') ? undefined : ((json['agents'] as Array<any>).map(GetAgents200ResponseAgentsInnerFromJSON)),
    };
}

export function GetAgents200ResponseToJSON(value?: GetAgents200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agents': value.agents === undefined ? undefined : ((value.agents as Array<any>).map(GetAgents200ResponseAgentsInnerToJSON)),
    };
}

