// Copyright 2024 Merit International Inc. All Rights Reserved

import { Button, MeritLogo, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer } from "@src/components";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { useLogout } from "../hooks/auth/auth";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { ReactNode } from "react";
import type { RouteParams } from "@src/Router";

const SCREEN_NAME = "PreLoginLayout";

type Props = {
  readonly children: ReactNode;
  readonly showHomeButton?: boolean;
  readonly showLogoutButton?: boolean;
};

export const PreLoginLayout = ({
  children,
  showHomeButton = false,
  showLogoutButton = false,
}: Props) => {
  const { theme } = useTheme();
  const { logout } = useLogout();

  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();

  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    content: {
      flex: 1,
    },
    header: {
      alignItems: "center",
      backgroundColor: theme.colors.brand.oceanBlue,
      flexDirection: "row",
      height: 72,
      justifyContent: "center",
    },
  });

  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView style={styles.container}>
        <View style={styles.header}>
          <View style={{ flex: 1 }} />
          <MeritLogo height={22} width={72} />
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
            {showHomeButton && (
              <>
                <View style={{ width: 150 }}>
                  <Button
                    iconLeft="homeMediumDefault"
                    onPress={() => {
                      navigation.navigate("Records");
                    }}
                    size="small"
                    testProps={{ elementName: "backToHomeButton", screenName: SCREEN_NAME }}
                    text="Back to home"
                    type="secondary"
                  />
                </View>
                <HorizontalSpacer />
              </>
            )}
            {showLogoutButton && (
              <>
                <View style={{ width: 110 }}>
                  <Button
                    iconLeft="logoutMediumDefault"
                    onPress={logout}
                    size="small"
                    testProps={{ elementName: "logoutButton", screenName: SCREEN_NAME }}
                    text="Logout"
                    type="secondary"
                  />
                </View>
                <HorizontalSpacer />
              </>
            )}
          </View>
        </View>
        <View style={styles.content}>{children}</View>
      </SafeAreaView>
    </View>
  );
};
