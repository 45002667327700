// Copyright 2023 Merit International Inc. All Rights Reserved

import { ConfirmationModal } from "../../components/Modals";
import { Footer } from "./Footer";
import { Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "./ConfigureTemplate";
import { SelectPermissions } from "../../components/SelectPermissions";
import { View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import React, { useState } from "react";
import type { FormikProps } from "formik";

const { Some } = Helpers;

export type PermissionValue = "All" | "None";

export type Option = "no" | "yes";

export type FormValues = {
  readonly readPermission: Option;
  readonly extendPermission: Option;
};

type Props = {
  readonly unSaveConfirmation: () => void;
  readonly onSave: (readPermission: PermissionValue, extendPermission: PermissionValue) => void;
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
  readonly shareablePermissions: readonly string[];
  readonly permissions: readonly string[];
};

export const Permissions = ({ formRef, onSave, permissions, unSaveConfirmation }: Props) => {
  const { theme } = useTheme();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  return (
    <>
      <View style={{ alignItems: "center", flex: 1, paddingVertical: theme.spacing.xxl }}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={{
            extendPermission: permissions.includes("extend") ? "yes" : "no",
            readPermission: permissions.includes("read") ? "yes" : "no",
          }}
          innerRef={formRef}
          onSubmit={values => {
            const readPermissions = values.readPermission === "yes" ? "All" : "None";
            const extendPermissions =
              values.extendPermission === "yes" && values.readPermission === "yes" ? "All" : "None";

            onSave(readPermissions, extendPermissions);
          }}
        >
          {({ setFieldTouched, setFieldValue, values }) => (
            <View style={{ width: 960 }}>
              <SelectPermissions
                onSelectOption={(permission: string, value: string) => {
                  setFieldTouched(permission, true);
                  setFieldValue(permission, value);
                }}
                permissionFor="template"
                testProps={{
                  elementName: "configureTemplatePermissionsTab",
                  screenName: SCREEN_NAME,
                }}
                values={values}
              />
            </View>
          )}
        </Formik>
      </View>
      <Footer
        onCancel={unSaveConfirmation}
        onSave={() => {
          if (Some(formRef.current) && formRef.current.isValid) {
            setIsConfirmationModalOpen(true);
          }
        }}
        testProps={{
          elementName: "configureTemplatePermissionsTab",
          screenName: SCREEN_NAME,
        }}
      />
      {isConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="save"
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            formRef.current?.handleSubmit();
            setIsConfirmationModalOpen(false);
          }}
          testProps={{
            elementName: "configureTemplatePermissionsTabSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to save? Saving this change will impact any org that has extended this template from you."
          title="Save template"
        />
      )}
    </>
  );
};
