/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetFieldKind200Response } from './GetFieldKind200Response';
import {
    GetFieldKind200ResponseFromJSON,
    GetFieldKind200ResponseFromJSONTyped,
    GetFieldKind200ResponseToJSON,
} from './GetFieldKind200Response';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface GetFieldKinds200Response
 */
export interface GetFieldKinds200Response {
    /**
     * 
     * @type {string}
     * @memberof GetFieldKinds200Response
     */
    ownerOrgID: string;
    /**
     * 
     * @type {string}
     * @memberof GetFieldKinds200Response
     */
    orgID: string;
    /**
     * 
     * @type {Array<GetFieldKind200Response>}
     * @memberof GetFieldKinds200Response
     */
    fieldKinds: Array<GetFieldKind200Response>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof GetFieldKinds200Response
     */
    paginationInfo?: PaginationInfo;
}

/**
 * Check if a given object implements the GetFieldKinds200Response interface.
 */
export function instanceOfGetFieldKinds200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ownerOrgID" in value;
    isInstance = isInstance && "orgID" in value;
    isInstance = isInstance && "fieldKinds" in value;

    return isInstance;
}

export function GetFieldKinds200ResponseFromJSON(json: any): GetFieldKinds200Response {
    return GetFieldKinds200ResponseFromJSONTyped(json, false);
}

export function GetFieldKinds200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFieldKinds200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ownerOrgID': json['OwnerOrgID'],
        'orgID': json['OrgID'],
        'fieldKinds': ((json['FieldKinds'] as Array<any>).map(GetFieldKind200ResponseFromJSON)),
        'paginationInfo': !exists(json, 'PaginationInfo') ? undefined : PaginationInfoFromJSON(json['PaginationInfo']),
    };
}

export function GetFieldKinds200ResponseToJSON(value?: GetFieldKinds200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OwnerOrgID': value.ownerOrgID,
        'OrgID': value.orgID,
        'FieldKinds': ((value.fieldKinds as Array<any>).map(GetFieldKind200ResponseToJSON)),
        'PaginationInfo': PaginationInfoToJSON(value.paginationInfo),
    };
}

