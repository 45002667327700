/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Restricts a given capability to a select object or objects
 * @export
 * @interface CapabilityRestriction
 */
export interface CapabilityRestriction {
    /**
     * The object attribute that determines which objects the agent can act on
     * @type {string}
     * @memberof CapabilityRestriction
     */
    identifier?: string;
    /**
     * The value the object's attribute must match for the capability to apply
     * @type {string}
     * @memberof CapabilityRestriction
     */
    value?: string;
    /**
     * Determines how to interpret the value field
     * @type {string}
     * @memberof CapabilityRestriction
     */
    valueType?: CapabilityRestrictionValueTypeEnum;
}


/**
 * @export
 */
export const CapabilityRestrictionValueTypeEnum = {
    String: 'string',
    Number: 'number'
} as const;
export type CapabilityRestrictionValueTypeEnum = typeof CapabilityRestrictionValueTypeEnum[keyof typeof CapabilityRestrictionValueTypeEnum];


/**
 * Check if a given object implements the CapabilityRestriction interface.
 */
export function instanceOfCapabilityRestriction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CapabilityRestrictionFromJSON(json: any): CapabilityRestriction {
    return CapabilityRestrictionFromJSONTyped(json, false);
}

export function CapabilityRestrictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapabilityRestriction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueType': !exists(json, 'valueType') ? undefined : json['valueType'],
    };
}

export function CapabilityRestrictionToJSON(value?: CapabilityRestriction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'value': value.value,
        'valueType': value.valueType,
    };
}

