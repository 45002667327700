/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Export credential request body
 * @export
 * @interface ExportCredentialsAsOrgRequest
 */
export interface ExportCredentialsAsOrgRequest {
    /**
     * Container ids whose credentials are to be exported
     * @type {Array<string>}
     * @memberof ExportCredentialsAsOrgRequest
     */
    containerIds: Array<string>;
    /**
     * EntityID for the intended recipient
     * @type {string}
     * @memberof ExportCredentialsAsOrgRequest
     */
    userEntityId: string;
}

/**
 * Check if a given object implements the ExportCredentialsAsOrgRequest interface.
 */
export function instanceOfExportCredentialsAsOrgRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "containerIds" in value;
    isInstance = isInstance && "userEntityId" in value;

    return isInstance;
}

export function ExportCredentialsAsOrgRequestFromJSON(json: any): ExportCredentialsAsOrgRequest {
    return ExportCredentialsAsOrgRequestFromJSONTyped(json, false);
}

export function ExportCredentialsAsOrgRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCredentialsAsOrgRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerIds': json['containerIds'],
        'userEntityId': json['userEntityId'],
    };
}

export function ExportCredentialsAsOrgRequestToJSON(value?: ExportCredentialsAsOrgRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerIds': value.containerIds,
        'userEntityId': value.userEntityId,
    };
}

