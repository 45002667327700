// Copyright 2024 Merit International Inc. All Rights Reserved

import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { Spin } from "../components";
import { View } from "react-native";
import { useAcceptedFolioStore } from "../stores";
import { useApi } from "@src/api/api";
import { useAuthStore } from "../stores/authStore";
import React, { useEffect } from "react";

export const LogoutSuccess = () => {
  const { clear: clearAuthStore } = useAuthStore();
  const { clear: clearAcceptedAccountFolioStore } = useAcceptedFolioStore();
  const { api } = useApi();

  useEffect(() => {
    const doLogoutWork = async () => {
      await api.logoutSuccess();

      clearAcceptedAccountFolioStore();
      clearAuthStore();
    };
    doLogoutWork();
  });

  return (
    <PreLoginLayout>
      <View style={{ flex: 1 }}>
        <Spin />
      </View>
    </PreLoginLayout>
  );
};
