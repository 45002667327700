// Copyright 2022 Merit International Inc. All Rights Reserved

import { DefaultLayout } from "../layouts/DefaultLayout";
import { View } from "react-native";
import React from "react";

export const PlaceholderScreen = () => (
  <DefaultLayout title="">
    <View />
  </DefaultLayout>
);
