// Copyright 2023 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Body, Heading, TextInput, useTheme } from "@merit/frontend-components";
import { ErrorMessage, Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { VerticalSpacer } from "../../components/Spacer";
import { View } from "react-native";
import type { FormikProps } from "formik";
import type { OPTestProps } from "../../types/TestProps";

const { Some } = Helpers;

export type FormValues = {
  readonly description: string;
  readonly name: string;
};

type Props = {
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
  readonly initialValues?: FormValues;
  readonly formSubmitCallback?: (values: FormValues) => void;
  readonly testProps: OPTestProps;
};

export const PolicyForm = ({ formRef, formSubmitCallback, initialValues, testProps }: Props) => {
  const { theme } = useTheme();

  const errorText = (errorMessage: string, fieldName: string) => (
    <View style={{ paddingVertical: theme.spacing.s }}>
      <Heading
        color={theme.colors.text.alert.critical}
        level="6"
        testProps={{
          ...testProps,
          elementName: `${testProps.elementName}${fieldName}TextInputErrorMessage`,
        }}
      >
        {errorMessage}
      </Heading>
    </View>
  );

  const validationSchema = Yup.object().shape({
    description: Yup.string().optional().trim().max(300, "300 maximum character limit"),
    name: Yup.string()
      .trim()
      .required("Policy name is required")
      .max(60, "60 maximum character limit"),
  });

  const initialFormValues = initialValues ?? {
    description: "",
    name: "",
  };

  return (
    <Formik
      initialValues={initialFormValues}
      innerRef={formRef}
      onSubmit={values => {
        if (Some(formSubmitCallback)) {
          formSubmitCallback(values);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ handleChange, values }) => (
        <>
          <Heading
            bold
            level="3"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}FormHeaderText`,
            }}
          >
            Policy name and description
          </Heading>
          <VerticalSpacer size={24} />
          <View
            style={{
              maxWidth: 468,
            }}
          >
            <TextInput
              label="Name *"
              onChangeText={handleChange("name")}
              placeholder="Policy name"
              size="large"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}NameTextInput`,
              }}
              value={values.name}
            />
            <ErrorMessage name="name">{error => errorText(error, "Name")}</ErrorMessage>
          </View>

          <VerticalSpacer size={theme.spacing.xxl} />

          <>
            <TextInput
              label="Description"
              numberOfLines={4}
              onChangeText={handleChange("description")}
              placeholder="Description"
              size="large"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}DescriptionTextInput`,
              }}
              value={values.description}
            />
            <VerticalSpacer size={theme.spacing.s} />
            <Heading
              level="6"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}DescriptionMaxLimit`,
              }}
            >
              300 maximum character limit
            </Heading>
            <ErrorMessage name="description">
              {error => errorText(error, "Description")}
            </ErrorMessage>
          </>

          <VerticalSpacer size={30} />

          <>
            <Heading
              level="4"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}PassFailHeaderText`,
              }}
            >
              Policy Pass/Fail Responses
            </Heading>
            <VerticalSpacer size={14} />
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}PassFailBodyText`,
              }}
            >
              Merit provides a generic policy response which cannot be edited. If a member passes
              validation, then “You passed this policy” will be displayed. If a member does not pass
              validation then, “You did not pass this policy. Contact your administrator.” will be
              displayed. Policy responses can be edited by agents/apps using the policy.{" "}
            </Body>
          </>
        </>
      )}
    </Formik>
  );
};
