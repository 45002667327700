/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An integration log event
 * @export
 * @interface IntegrationLogEvent
 */
export interface IntegrationLogEvent {
    /**
     * 
     * @type {string}
     * @memberof IntegrationLogEvent
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof IntegrationLogEvent
     */
    data?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof IntegrationLogEvent
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationLogEvent
     */
    syncedAt?: string;
}

/**
 * Check if a given object implements the IntegrationLogEvent interface.
 */
export function instanceOfIntegrationLogEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntegrationLogEventFromJSON(json: any): IntegrationLogEvent {
    return IntegrationLogEventFromJSONTyped(json, false);
}

export function IntegrationLogEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationLogEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'syncedAt': !exists(json, 'syncedAt') ? undefined : json['syncedAt'],
    };
}

export function IntegrationLogEventToJSON(value?: IntegrationLogEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'data': value.data,
        'createdAt': value.createdAt,
        'syncedAt': value.syncedAt,
    };
}

