/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType
 */
export interface ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType {
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType
     */
    format?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType
     */
    defaultCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType
     */
    dataType?: ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeDataTypeEnum;
}


/**
 * @export
 */
export const ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeDataTypeEnum = {
    Bool: 'Bool',
    Date: 'Date',
    DateTime: 'DateTime',
    Email: 'Email',
    Markdown: 'Markdown',
    Number: 'Number',
    PhoneNumber: 'PhoneNumber',
    Text: 'Text',
    Blob: 'Blob',
    Json: 'JSON'
} as const;
export type ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeDataTypeEnum = typeof ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeDataTypeEnum[keyof typeof ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeDataTypeEnum];


/**
 * Check if a given object implements the ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType interface.
 */
export function instanceOfListDatasources200ResponseDatasourcesInnerColumnsInnerDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeFromJSON(json: any): ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType {
    return ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeFromJSONTyped(json, false);
}

export function ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': !exists(json, 'format') ? undefined : json['format'],
        'defaultCountryCode': !exists(json, 'defaultCountryCode') ? undefined : json['defaultCountryCode'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
    };
}

export function ListDatasources200ResponseDatasourcesInnerColumnsInnerDataTypeToJSON(value?: ListDatasources200ResponseDatasourcesInnerColumnsInnerDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': value.format,
        'defaultCountryCode': value.defaultCountryCode,
        'dataType': value.dataType,
    };
}

