/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgents200ResponseAgentsInnerCapabilitiesInner } from './GetAgents200ResponseAgentsInnerCapabilitiesInner';
import {
    GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSON,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSONTyped,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerToJSON,
} from './GetAgents200ResponseAgentsInnerCapabilitiesInner';

/**
 * 
 * @export
 * @interface GetAllAgentsVersions200ResponseInnerVersionsInner
 */
export interface GetAllAgentsVersions200ResponseInnerVersionsInner {
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    approved?: boolean;
    /**
     * 
     * @type {Array<GetAgents200ResponseAgentsInnerCapabilitiesInner>}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    capabilities?: Array<GetAgents200ResponseAgentsInnerCapabilitiesInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    dataTos?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    privacyTos?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    usageTos?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    version: number;
    /**
     * Contact information for the agent/app developer
     * @type {string}
     * @memberof GetAllAgentsVersions200ResponseInnerVersionsInner
     */
    developerContactEmail: string;
}

/**
 * Check if a given object implements the GetAllAgentsVersions200ResponseInnerVersionsInner interface.
 */
export function instanceOfGetAllAgentsVersions200ResponseInnerVersionsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "developerContactEmail" in value;

    return isInstance;
}

export function GetAllAgentsVersions200ResponseInnerVersionsInnerFromJSON(json: any): GetAllAgentsVersions200ResponseInnerVersionsInner {
    return GetAllAgentsVersions200ResponseInnerVersionsInnerFromJSONTyped(json, false);
}

export function GetAllAgentsVersions200ResponseInnerVersionsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllAgentsVersions200ResponseInnerVersionsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'capabilities': !exists(json, 'capabilities') ? undefined : ((json['capabilities'] as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'dataTos': !exists(json, 'dataTos') ? undefined : json['dataTos'],
        'description': json['description'],
        'name': json['name'],
        'privacyTos': !exists(json, 'privacyTos') ? undefined : json['privacyTos'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'usageTos': !exists(json, 'usageTos') ? undefined : json['usageTos'],
        'version': json['version'],
        'developerContactEmail': json['developerContactEmail'],
    };
}

export function GetAllAgentsVersions200ResponseInnerVersionsInnerToJSON(value?: GetAllAgentsVersions200ResponseInnerVersionsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'approved': value.approved,
        'capabilities': value.capabilities === undefined ? undefined : ((value.capabilities as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerToJSON)),
        'createdAt': value.createdAt,
        'dataTos': value.dataTos,
        'description': value.description,
        'name': value.name,
        'privacyTos': value.privacyTos,
        'updatedAt': value.updatedAt,
        'usageTos': value.usageTos,
        'version': value.version,
        'developerContactEmail': value.developerContactEmail,
    };
}

