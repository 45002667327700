import { QueryKeyPrefix } from "../constants";
import type { Credential } from "@src/studio/types";
import type { QueryFilters } from "@tanstack/react-query";
import type { useExportCredentials } from "./useExportCredentials";

type IsExportCredentialsQueryFor = (credentialId: Credential["id"]) => QueryFilters["predicate"];

export const isExportCredentialsQueryFor: IsExportCredentialsQueryFor = credentialId => query => {
  if (query.queryKey[0] !== QueryKeyPrefix.StudioExportCredentials) {
    return false;
  }

  const data = query.state.data as ReturnType<typeof useExportCredentials>["data"];

  if (
    Object.values(data?.exportedCredentials ?? {})
      .map(item => item.credentialId)
      .includes(credentialId)
  ) {
    return true;
  }

  return false;
};

type IsEmptyExportCredentialsQuery = () => QueryFilters["predicate"];

export const isEmptyExportCredentialsQuery: IsEmptyExportCredentialsQuery = () => query => {
  if (query.queryKey[0] !== QueryKeyPrefix.StudioExportCredentials) {
    return false;
  }

  const data = query.state.data as ReturnType<typeof useExportCredentials>["data"];

  if (Object.values(data?.exportedCredentials ?? {}).length === 0) {
    return true;
  }

  return false;
};
