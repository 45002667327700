// Copyright 2024 Merit International Inc. All Rights Reserved

import { CreatePolicy } from "../CreatePolicy";
import { CreatePolicyDeprecatedScreen } from "./CreatePolicyDeprecated";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";

export const CreatePolicyScreen = () => {
  const { showM6CreateEditPolicyScreens } = useFlags<LDFeatureFlags>();

  if (showM6CreateEditPolicyScreens) {
    return <CreatePolicy />;
  }

  return <CreatePolicyDeprecatedScreen />;
};
