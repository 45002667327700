/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTemplateRules200ResponseRules } from './GetTemplateRules200ResponseRules';
import {
    GetTemplateRules200ResponseRulesFromJSON,
    GetTemplateRules200ResponseRulesFromJSONTyped,
    GetTemplateRules200ResponseRulesToJSON,
} from './GetTemplateRules200ResponseRules';

/**
 * 
 * @export
 * @interface GetTemplateRules200Response
 */
export interface GetTemplateRules200Response {
    /**
     * 
     * @type {GetTemplateRules200ResponseRules}
     * @memberof GetTemplateRules200Response
     */
    rules?: GetTemplateRules200ResponseRules;
}

/**
 * Check if a given object implements the GetTemplateRules200Response interface.
 */
export function instanceOfGetTemplateRules200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseFromJSON(json: any): GetTemplateRules200Response {
    return GetTemplateRules200ResponseFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rules': !exists(json, 'Rules') ? undefined : GetTemplateRules200ResponseRulesFromJSON(json['Rules']),
    };
}

export function GetTemplateRules200ResponseToJSON(value?: GetTemplateRules200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Rules': GetTemplateRules200ResponseRulesToJSON(value.rules),
    };
}

