/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  ExtendTemplateProperties,
  Template,
  TemplatePatch,
  TemplateResponse,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    ExtendTemplatePropertiesFromJSON,
    ExtendTemplatePropertiesToJSON,
    TemplateFromJSON,
    TemplateToJSON,
    TemplatePatchFromJSON,
    TemplatePatchToJSON,
    TemplateResponseFromJSON,
    TemplateResponseToJSON,
} from '../models/index';

export interface ExtendTemplateRequest {
    authorization?: string;
    properties?: ExtendTemplateProperties;
}

export interface GetTemplateRequest {
    templateID: string;
    authorization?: string;
}

export interface GetTemplatesRequest {
    dataSourceId?: string;
    dataSourceIds?: Array<string>;
    owner?: string;
    status?: GetTemplatesStatusEnum;
    templateFieldIds?: Array<string>;
    templateType?: GetTemplatesTemplateTypeEnum;
    parentTemplateId?: string;
    authorization?: string;
    endingBefore?: string;
    limit?: number;
    startingAfter?: string;
    sortBy?: GetTemplatesSortByEnum;
}

export interface UpdateTemplateRequest {
    templateID: string;
    authorization?: string;
    properties?: TemplatePatch;
}

/**
 * 
 */
export class ContainerTemplatesApi extends runtime.BaseAPI {

    /**
     * `extend:template`  Create a new template by extending an existing template 
     * Extend Template
     */
    async extendTemplateRaw(requestParameters: ExtendTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Template>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtendTemplatePropertiesToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFromJSON(jsonValue));
    }

    /**
     * `extend:template`  Create a new template by extending an existing template 
     * Extend Template
     */
    async extendTemplate(requestParameters: ExtendTemplateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Template> {
        const response = await this.extendTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:template`  Get a single template 
     * Get Template
     */
    async getTemplateRaw(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Template>> {
        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling getTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates/{templateID}`.replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFromJSON(jsonValue));
    }

    /**
     * `read:template`  Get a single template 
     * Get Template
     */
    async getTemplate(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Template> {
        const response = await this.getTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:template`  Get a paginated list of templates 
     * Get Templates
     */
    async getTemplatesRaw(requestParameters: GetTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateResponse>> {
        const queryParameters: any = {};

        if (requestParameters.dataSourceId !== undefined) {
            queryParameters['data_source_id'] = requestParameters.dataSourceId;
        }

        if (requestParameters.dataSourceIds) {
            queryParameters['data_source_ids'] = requestParameters.dataSourceIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.templateFieldIds) {
            queryParameters['template_field_ids'] = requestParameters.templateFieldIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.templateType !== undefined) {
            queryParameters['template_type'] = requestParameters.templateType;
        }

        if (requestParameters.parentTemplateId !== undefined) {
            queryParameters['parent_template_id'] = requestParameters.parentTemplateId;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateResponseFromJSON(jsonValue));
    }

    /**
     * `read:template`  Get a paginated list of templates 
     * Get Templates
     */
    async getTemplates(requestParameters: GetTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateResponse> {
        const response = await this.getTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:template`  Update an existing template 
     * Update Template
     */
    async updateTemplateRaw(requestParameters: UpdateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Template>> {
        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling updateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates/{templateID}`.replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TemplatePatchToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFromJSON(jsonValue));
    }

    /**
     * `update:template`  Update an existing template 
     * Update Template
     */
    async updateTemplate(requestParameters: UpdateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Template> {
        const response = await this.updateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetTemplatesStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type GetTemplatesStatusEnum = typeof GetTemplatesStatusEnum[keyof typeof GetTemplatesStatusEnum];
/**
 * @export
 */
export const GetTemplatesTemplateTypeEnum = {
    Merit: 'merit',
    Folio: 'folio',
    Persona: 'persona'
} as const;
export type GetTemplatesTemplateTypeEnum = typeof GetTemplatesTemplateTypeEnum[keyof typeof GetTemplatesTemplateTypeEnum];
/**
 * @export
 */
export const GetTemplatesSortByEnum = {
    Name: 'name',
    TemplateType: 'templateType',
    Owner: 'owner',
    CreatedAt: 'createdAt'
} as const;
export type GetTemplatesSortByEnum = typeof GetTemplatesSortByEnum[keyof typeof GetTemplatesSortByEnum];
