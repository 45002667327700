/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';

/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
 */
export interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner {
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    type?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    fieldID?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    fieldKindID?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    fieldKindName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    base: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    inherited: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    contact: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    rules?: Array<string>;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    mappedColumn?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    mappedContainerField?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField;
    /**
     * 
     * @type {Date}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    createdAt?: Date;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    fieldValidation?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    fieldKindInfoValidation?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation;
    /**
     * The ordered lineage of parent template fields by their external id. The order is from direct parent to
     * grandparent. So if Template3 extended from Template2 which extended from Template1, Template3's TemplateField
     * would have a lineage array of Template2's TemplateField ID and then Template1's TemplateFieldID.
     * 
     * @type {Array<string>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    lineage?: Array<string>;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
}


/**
 * @export
 */
export const GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum = {
    Bool: 'Bool',
    Date: 'Date',
    DateTime: 'DateTime',
    Email: 'Email',
    Markdown: 'Markdown',
    Number: 'Number',
    PhoneNumber: 'PhoneNumber',
    Text: 'Text',
    Blob: 'Blob',
    Json: 'JSON'
} as const;
export type GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum = typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum[keyof typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum];


/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "base" in value;
    isInstance = isInstance && "inherited" in value;
    isInstance = isInstance && "contact" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner {
    return GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'fieldID': !exists(json, 'fieldID') ? undefined : json['fieldID'],
        'fieldKindID': !exists(json, 'fieldKindID') ? undefined : json['fieldKindID'],
        'fieldKindName': !exists(json, 'fieldKindName') ? undefined : json['fieldKindName'],
        'base': json['base'],
        'inherited': json['inherited'],
        'contact': json['contact'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rules': !exists(json, 'rules') ? undefined : json['rules'],
        'mappedColumn': !exists(json, 'mappedColumn') ? undefined : GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnFromJSON(json['mappedColumn']),
        'mappedContainerField': !exists(json, 'mappedContainerField') ? undefined : GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldFromJSON(json['mappedContainerField']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'fieldValidation': !exists(json, 'fieldValidation') ? undefined : GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationFromJSON(json['fieldValidation']),
        'fieldKindInfoValidation': !exists(json, 'fieldKindInfoValidation') ? undefined : GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationFromJSON(json['fieldKindInfoValidation']),
        'lineage': !exists(json, 'lineage') ? undefined : json['lineage'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'fieldID': value.fieldID,
        'fieldKindID': value.fieldKindID,
        'fieldKindName': value.fieldKindName,
        'base': value.base,
        'inherited': value.inherited,
        'contact': value.contact,
        'description': value.description,
        'rules': value.rules,
        'mappedColumn': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnToJSON(value.mappedColumn),
        'mappedContainerField': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldToJSON(value.mappedContainerField),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'fieldValidation': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationToJSON(value.fieldValidation),
        'fieldKindInfoValidation': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationToJSON(value.fieldKindInfoValidation),
        'lineage': value.lineage,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
    };
}

