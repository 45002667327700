// Copyright 2024 Merit International Inc. All Rights Reserved

import { Breadcrumb } from "./Breadcrumb";
import { BreadcrumbLink } from "./BreadcrumbLink";
import { HorizontalSpacer } from "../Spacer";
import { Icon } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import React from "react";
import type { PostLoginRouteParams, RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

type Route = RouteProp<RouteParams>;

export type BreadcrumbProps = {
  readonly name: string;
  readonly link?: keyof PostLoginRouteParams;
};

type Props = {
  readonly items: readonly BreadcrumbProps[];
  readonly testProps?: { readonly elementName: string; readonly screenName: string };
};

export const Breadcrumbs = ({ items }: Props) => {
  const styles = StyleSheet.create({
    breadcrumb: {},
    container: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: 32,
      paddingBottom: 0,
    },
  });

  const route = useRoute<Route>();

  return (
    <View style={styles.container} testID={`breadcrumb:${route.name}`}>
      {items.map((item, index) => (
        <View
          key={item.name}
          style={{ alignItems: "center", flexDirection: "row", justifyContent: "flex-start" }}
        >
          <View key={item.name} style={styles.breadcrumb} testID={`subroute:${index}`}>
            {item.link === undefined ? (
              <Breadcrumb name={item.name} />
            ) : (
              <BreadcrumbLink link={item.link} name={item.name} />
            )}
          </View>
          {index < items.length - 1 && (
            <>
              <HorizontalSpacer size={8} />
              <Icon name="chevronRightMediumAction" style={{ height: 10, width: 10 }} />
              <HorizontalSpacer size={8} />
            </>
          )}
        </View>
      ))}
    </View>
  );
};
