/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectsPOSTRequestOneOf5
 */
export interface ProjectsPOSTRequestOneOf5 {
    /**
     * 
     * @type {string}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    projectTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    mediaType: ProjectsPOSTRequestOneOf5MediaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    backgroundImageUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    sizeToBackgroundImage: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    brandId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    brandCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    tags?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ProjectsPOSTRequestOneOf5
     */
    metadata?: object;
}


/**
 * @export
 */
export const ProjectsPOSTRequestOneOf5MediaTypeEnum = {
    Digital: 'digital',
    Video: 'video',
    Print: 'print',
    Presentation: 'presentation'
} as const;
export type ProjectsPOSTRequestOneOf5MediaTypeEnum = typeof ProjectsPOSTRequestOneOf5MediaTypeEnum[keyof typeof ProjectsPOSTRequestOneOf5MediaTypeEnum];


/**
 * Check if a given object implements the ProjectsPOSTRequestOneOf5 interface.
 */
export function instanceOfProjectsPOSTRequestOneOf5(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mediaType" in value;
    isInstance = isInstance && "backgroundImageUrl" in value;
    isInstance = isInstance && "sizeToBackgroundImage" in value;

    return isInstance;
}

export function ProjectsPOSTRequestOneOf5FromJSON(json: any): ProjectsPOSTRequestOneOf5 {
    return ProjectsPOSTRequestOneOf5FromJSONTyped(json, false);
}

export function ProjectsPOSTRequestOneOf5FromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsPOSTRequestOneOf5 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectTitle': !exists(json, 'project_title') ? undefined : json['project_title'],
        'mediaType': json['media_type'],
        'backgroundImageUrl': json['background_image_url'],
        'sizeToBackgroundImage': json['size_to_background_image'],
        'brandId': !exists(json, 'brand_id') ? undefined : json['brand_id'],
        'brandCode': !exists(json, 'brand_code') ? undefined : json['brand_code'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function ProjectsPOSTRequestOneOf5ToJSON(value?: ProjectsPOSTRequestOneOf5 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_title': value.projectTitle,
        'media_type': value.mediaType,
        'background_image_url': value.backgroundImageUrl,
        'size_to_background_image': value.sizeToBackgroundImage,
        'brand_id': value.brandId,
        'brand_code': value.brandCode,
        'tags': value.tags,
        'metadata': value.metadata,
    };
}

