/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The frequency in minutes that the connector will sync data.
 * @export
 */
export const SyncFrequency = {
    NUMBER_5: 5,
    NUMBER_15: 15,
    NUMBER_30: 30,
    NUMBER_60: 60,
    NUMBER_120: 120,
    NUMBER_180: 180,
    NUMBER_360: 360,
    NUMBER_480: 480,
    NUMBER_720: 720,
    NUMBER_1440: 1440
} as const;
export type SyncFrequency = typeof SyncFrequency[keyof typeof SyncFrequency];


export function SyncFrequencyFromJSON(json: any): SyncFrequency {
    return SyncFrequencyFromJSONTyped(json, false);
}

export function SyncFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncFrequency {
    return json as SyncFrequency;
}

export function SyncFrequencyToJSON(value?: SyncFrequency | null): any {
    return value as any;
}

