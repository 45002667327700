/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetFieldKind200Response } from './GetFieldKind200Response';
import {
    GetFieldKind200ResponseFromJSON,
    GetFieldKind200ResponseFromJSONTyped,
    GetFieldKind200ResponseToJSON,
} from './GetFieldKind200Response';

/**
 * 
 * @export
 * @interface ExtendFieldKind201Response
 */
export interface ExtendFieldKind201Response {
    /**
     * 
     * @type {GetFieldKind200Response}
     * @memberof ExtendFieldKind201Response
     */
    fieldKind?: GetFieldKind200Response;
    /**
     * 
     * @type {string}
     * @memberof ExtendFieldKind201Response
     */
    error?: string;
}

/**
 * Check if a given object implements the ExtendFieldKind201Response interface.
 */
export function instanceOfExtendFieldKind201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExtendFieldKind201ResponseFromJSON(json: any): ExtendFieldKind201Response {
    return ExtendFieldKind201ResponseFromJSONTyped(json, false);
}

export function ExtendFieldKind201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendFieldKind201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldKind': !exists(json, 'fieldKind') ? undefined : GetFieldKind200ResponseFromJSON(json['fieldKind']),
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function ExtendFieldKind201ResponseToJSON(value?: ExtendFieldKind201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldKind': GetFieldKind200ResponseToJSON(value.fieldKind),
        'error': value.error,
    };
}

