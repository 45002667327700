/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDataSourceWithSchema,
  DataSource,
  DataSourceIntegrationMapping,
  DataSourceIntegrationMappingProperties,
  DataSources,
  Errors,
  PatchDataSourceSchema,
  UpdateDataSource,
} from '../models/index';
import {
    CreateDataSourceWithSchemaFromJSON,
    CreateDataSourceWithSchemaToJSON,
    DataSourceFromJSON,
    DataSourceToJSON,
    DataSourceIntegrationMappingFromJSON,
    DataSourceIntegrationMappingToJSON,
    DataSourceIntegrationMappingPropertiesFromJSON,
    DataSourceIntegrationMappingPropertiesToJSON,
    DataSourcesFromJSON,
    DataSourcesToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
    PatchDataSourceSchemaFromJSON,
    PatchDataSourceSchemaToJSON,
    UpdateDataSourceFromJSON,
    UpdateDataSourceToJSON,
} from '../models/index';

export interface CreateDataSourceFromSchemaRequest {
    schema: CreateDataSourceWithSchema;
    authorization?: string;
}

export interface DeleteDataSourceRequest {
    dataSourceID: string;
    authorization?: string;
}

export interface GetDataSourceRequest {
    dataSourceID: string;
    authorization?: string;
}

export interface GetDataSourcesRequest {
    sortBy?: GetDataSourcesSortByEnum;
    startingAfter?: string;
    endingBefore?: string;
    limit?: number;
    authorization?: string;
}

export interface GetRecordErrorsRequest {
    dataSourceID: string;
    authorization?: string;
    startingAfter?: string;
    endingBefore?: string;
    correlationId?: string;
}

export interface MapDataSourceToIntegrationRequest {
    dataSourceID: string;
    authorization?: string;
    properties?: DataSourceIntegrationMappingProperties;
}

export interface PatchDataSourceSchemaRequest {
    dataSourceID: string;
    schema: PatchDataSourceSchema;
    authorization?: string;
}

export interface UpdateDataSourceRequest {
    dataSourceID: string;
    dataSourceUpdates: UpdateDataSource;
    authorization?: string;
}

/**
 * 
 */
export class DataSourcesApi extends runtime.BaseAPI {

    /**
     * `create:datasource`  Create a new data source using the provided schema 
     * Create Data Source
     */
    async createDataSourceFromSchemaRaw(requestParameters: CreateDataSourceFromSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSource>> {
        if (requestParameters.schema === null || requestParameters.schema === undefined) {
            throw new runtime.RequiredError('schema','Required parameter requestParameters.schema was null or undefined when calling createDataSourceFromSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/schema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDataSourceWithSchemaToJSON(requestParameters.schema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSourceFromJSON(jsonValue));
    }

    /**
     * `create:datasource`  Create a new data source using the provided schema 
     * Create Data Source
     */
    async createDataSourceFromSchema(requestParameters: CreateDataSourceFromSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSource> {
        const response = await this.createDataSourceFromSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `delete:datasource`  Delete a data source. This will free the data source name to be re-used by the organization. It will not remove any records in the data source, nor will it remove or revoke any containers created from this data source. 
     * Delete Data Source
     */
    async deleteDataSourceRaw(requestParameters: DeleteDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling deleteDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `delete:datasource`  Delete a data source. This will free the data source name to be re-used by the organization. It will not remove any records in the data source, nor will it remove or revoke any containers created from this data source. 
     * Delete Data Source
     */
    async deleteDataSource(requestParameters: DeleteDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDataSourceRaw(requestParameters, initOverrides);
    }

    /**
     * `read:datasource`  Get a single data source 
     * Get Data Source
     */
    async getDataSourceRaw(requestParameters: GetDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSource>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling getDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSourceFromJSON(jsonValue));
    }

    /**
     * `read:datasource`  Get a single data source 
     * Get Data Source
     */
    async getDataSource(requestParameters: GetDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSource> {
        const response = await this.getDataSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:datasource`  Get a paginated list of data sources 
     * Get Data Sources
     */
    async getDataSourcesRaw(requestParameters: GetDataSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSources>> {
        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSourcesFromJSON(jsonValue));
    }

    /**
     * `read:datasource`  Get a paginated list of data sources 
     * Get Data Sources
     */
    async getDataSources(requestParameters: GetDataSourcesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSources> {
        const response = await this.getDataSourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `create:record`  Get record ingestion errors for a data source 
     * Get Record Ingestion Errors
     */
    async getRecordErrorsRaw(requestParameters: GetRecordErrorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling getRecordErrors.');
        }

        const queryParameters: any = {};

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.correlationId !== undefined) {
            queryParameters['correlation_id'] = requestParameters.correlationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}/errors`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * `create:record`  Get record ingestion errors for a data source 
     * Get Record Ingestion Errors
     */
    async getRecordErrors(requestParameters: GetRecordErrorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getRecordErrorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `create:record`  Map columns from an integration to columns in a data source. The data source will periodically ingest data from the integration. 
     * Map Integration To Data Source
     */
    async mapDataSourceToIntegrationRaw(requestParameters: MapDataSourceToIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSourceIntegrationMapping>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling mapDataSourceToIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}/map`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceIntegrationMappingPropertiesToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSourceIntegrationMappingFromJSON(jsonValue));
    }

    /**
     * `create:record`  Map columns from an integration to columns in a data source. The data source will periodically ingest data from the integration. 
     * Map Integration To Data Source
     */
    async mapDataSourceToIntegration(requestParameters: MapDataSourceToIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSourceIntegrationMapping> {
        const response = await this.mapDataSourceToIntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:datasource`  Add columns to and/or change the delimiter for a data source 
     * Update Data Source Schema
     */
    async patchDataSourceSchemaRaw(requestParameters: PatchDataSourceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSource>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling patchDataSourceSchema.');
        }

        if (requestParameters.schema === null || requestParameters.schema === undefined) {
            throw new runtime.RequiredError('schema','Required parameter requestParameters.schema was null or undefined when calling patchDataSourceSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}/schema`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchDataSourceSchemaToJSON(requestParameters.schema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSourceFromJSON(jsonValue));
    }

    /**
     * `update:datasource`  Add columns to and/or change the delimiter for a data source 
     * Update Data Source Schema
     */
    async patchDataSourceSchema(requestParameters: PatchDataSourceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSource> {
        const response = await this.patchDataSourceSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:datasource`  Change the name or description of a data source 
     * Update Data Source
     */
    async updateDataSourceRaw(requestParameters: UpdateDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSource>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling updateDataSource.');
        }

        if (requestParameters.dataSourceUpdates === null || requestParameters.dataSourceUpdates === undefined) {
            throw new runtime.RequiredError('dataSourceUpdates','Required parameter requestParameters.dataSourceUpdates was null or undefined when calling updateDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDataSourceToJSON(requestParameters.dataSourceUpdates),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSourceFromJSON(jsonValue));
    }

    /**
     * `update:datasource`  Change the name or description of a data source 
     * Update Data Source
     */
    async updateDataSource(requestParameters: UpdateDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSource> {
        const response = await this.updateDataSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetDataSourcesSortByEnum = {
    CreatedAt: 'createdAt',
    Name: 'name',
    Id: 'id'
} as const;
export type GetDataSourcesSortByEnum = typeof GetDataSourcesSortByEnum[keyof typeof GetDataSourcesSortByEnum];
