// Copyright 2024 Merit International Inc. All Rights Reserved

export const formatNumber = (number: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    style: "decimal",
  });

  if (number >= 10000) {
    const millionFormatter = new Intl.NumberFormat("en-US", {
      compactDisplay: "short",
      maximumFractionDigits: 1,
      notation: "compact",
      style: "decimal",
    });

    return millionFormatter.format(number);
  }

  return formatter.format(number);
};
