// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Popover } from "../../components";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import React, { useRef, useState } from "react";
import type { OPTestProps } from "../../../src/types/TestProps";
import type { OrgOptionsGet200ResponseOrgsInner } from "../../gen/org-portal";

type Props = {
  readonly org: OrgOptionsGet200ResponseOrgsInner;
  readonly onPress: () => void;
  readonly testProps?: OPTestProps;
};

export const OrgSelectLink = ({ onPress, org, testProps }: Props) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const { theme } = useTheme();
  const [originElement, setOriginElement] = useState<HTMLDivElement | undefined>();
  const [isShown, setIsShown] = useState(false);

  const styles = StyleSheet.create({
    base: {
      alignItems: "center",
      backgroundColor: theme.colors.action.secondary.default,
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      minHeight: 64,
      minWidth: 420,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.m,
    },
    hovered: {
      backgroundColor: theme.colors.surface.hovered,
      borderColor: theme.colors.action.default,
      borderWidth: 1,
    },
  });

  return (
    <>
      <TouchableHighlight
        key={`${org.orgID}-select`}
        onPress={onPress}
        underlayColor="rbga(0,0,0,0)"
      >
        <View ref={ref} style={[styles.base, isHovered && styles.hovered]}>
          <View style={{ maxWidth: 352 }}>
            {/* TODO: Make this popover component over Ellipsis text a seperate component */}
            <div
              onMouseEnter={() => {
                setIsShown(true);
              }}
              onMouseLeave={() => {
                setIsShown(false);
              }}
              ref={reff => {
                if (reff !== null) {
                  setOriginElement(reff);
                }
              }}
            >
              <View style={{ flex: 1 }}>
                <Body numberOfLines={1} size="l" testProps={testProps}>
                  {org.orgName}
                </Body>
              </View>
            </div>
          </View>
          <Icon alt="text" name="arrowForwardMediumAction" />
        </View>
      </TouchableHighlight>

      {org.orgName.length > 15 && isShown && originElement !== undefined && (
        <Popover originElement={originElement} placement="top-start">
          <View
            style={{
              backgroundColor: theme.colors.brand.oceanBlue,
              minWidth: 631,
              paddingHorizontal: theme.spacing.l,
              paddingVertical: theme.spacing.m,
            }}
          >
            <Body color={theme.colors.surface.default}>{org.orgName}</Body>
          </View>
        </Popover>
      )}
    </>
  );
};
