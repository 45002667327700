/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Formula } from './Formula';
import {
    FormulaFromJSON,
    FormulaFromJSONTyped,
    FormulaToJSON,
} from './Formula';

/**
 * A conjunction of formulas that can be used to define a policy.
 * @export
 * @interface Conjunction
 */
export interface Conjunction extends Formula {
    /**
     * The list of formulas that are being conjoined.
     * @type {Array<Formula>}
     * @memberof Conjunction
     */
    conjunction: Array<Formula>;
}



/**
 * Check if a given object implements the Conjunction interface.
 */
export function instanceOfConjunction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conjunction" in value;

    return isInstance;
}

export function ConjunctionFromJSON(json: any): Conjunction {
    return ConjunctionFromJSONTyped(json, false);
}

export function ConjunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Conjunction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormulaFromJSONTyped(json, ignoreDiscriminator),
        'conjunction': ((json['conjunction'] as Array<any>).map(FormulaFromJSON)),
    };
}

export function ConjunctionToJSON(value?: Conjunction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormulaToJSON(value),
        'conjunction': ((value.conjunction as Array<any>).map(FormulaToJSON)),
    };
}

