// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../Spacer";

type ErrorContentProps = {
  readonly showReload?: boolean;
  readonly statusCode?: number;
};

export const ErrorContent = ({ showReload = false, statusCode }: ErrorContentProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    centerContent: { alignItems: "center", justifyContent: "center" },
  });

  const reload = () => {
    window.location.reload();
  };

  const getErrorText = () => {
    switch (statusCode) {
      case 403: {
        return "You do not have permission to access this content";
      }
      default: {
        return "Something went wrong";
      }
    }
  };

  const heading = getErrorText();

  return (
    <View style={{ flex: 1 }}>
      <View style={[styles.centerContent, { flex: 1 }]}>
        <Heading level="1">{heading}</Heading>
        {showReload && (
          <>
            <VerticalSpacer size={theme.spacing.xxl} />
            <Body center color={theme.colors.action.default} onPress={reload}>
              Please reload to try again
            </Body>
          </>
        )}
      </View>
    </View>
  );
};
