/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EditTemplateMap200ResponseColumnToTemplateFieldInner } from './EditTemplateMap200ResponseColumnToTemplateFieldInner';
import {
    EditTemplateMap200ResponseColumnToTemplateFieldInnerFromJSON,
    EditTemplateMap200ResponseColumnToTemplateFieldInnerFromJSONTyped,
    EditTemplateMap200ResponseColumnToTemplateFieldInnerToJSON,
} from './EditTemplateMap200ResponseColumnToTemplateFieldInner';

/**
 * Mapping of a template to a data source.
 * @export
 * @interface EditTemplateMap200Response
 */
export interface EditTemplateMap200Response {
    /**
     * The id of the org.
     * @type {string}
     * @memberof EditTemplateMap200Response
     */
    orgID: string;
    /**
     * The id of the template.
     * @type {string}
     * @memberof EditTemplateMap200Response
     */
    templateID: string;
    /**
     * The id of the data source.
     * @type {string}
     * @memberof EditTemplateMap200Response
     */
    dataSourceID: string;
    /**
     * The mapping from a template field to a column.
     * @type {Array<EditTemplateMap200ResponseColumnToTemplateFieldInner>}
     * @memberof EditTemplateMap200Response
     */
    columnToTemplateField?: Array<EditTemplateMap200ResponseColumnToTemplateFieldInner>;
}

/**
 * Check if a given object implements the EditTemplateMap200Response interface.
 */
export function instanceOfEditTemplateMap200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgID" in value;
    isInstance = isInstance && "templateID" in value;
    isInstance = isInstance && "dataSourceID" in value;

    return isInstance;
}

export function EditTemplateMap200ResponseFromJSON(json: any): EditTemplateMap200Response {
    return EditTemplateMap200ResponseFromJSONTyped(json, false);
}

export function EditTemplateMap200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTemplateMap200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgID': json['orgID'],
        'templateID': json['templateID'],
        'dataSourceID': json['dataSourceID'],
        'columnToTemplateField': !exists(json, 'columnToTemplateField') ? undefined : ((json['columnToTemplateField'] as Array<any>).map(EditTemplateMap200ResponseColumnToTemplateFieldInnerFromJSON)),
    };
}

export function EditTemplateMap200ResponseToJSON(value?: EditTemplateMap200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orgID': value.orgID,
        'templateID': value.templateID,
        'dataSourceID': value.dataSourceID,
        'columnToTemplateField': value.columnToTemplateField === undefined ? undefined : ((value.columnToTemplateField as Array<any>).map(EditTemplateMap200ResponseColumnToTemplateFieldInnerToJSON)),
    };
}

