/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatasourceVisibilityRequest
 */
export interface DatasourceVisibilityRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DatasourceVisibilityRequest
     */
    hide: boolean | null;
}

/**
 * Check if a given object implements the DatasourceVisibilityRequest interface.
 */
export function instanceOfDatasourceVisibilityRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hide" in value;

    return isInstance;
}

export function DatasourceVisibilityRequestFromJSON(json: any): DatasourceVisibilityRequest {
    return DatasourceVisibilityRequestFromJSONTyped(json, false);
}

export function DatasourceVisibilityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceVisibilityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hide': json['hide'],
    };
}

export function DatasourceVisibilityRequestToJSON(value?: DatasourceVisibilityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hide': value.hide,
    };
}

