/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterNewOrg201ResponseRegisteredOrg
 */
export interface RegisterNewOrg201ResponseRegisteredOrg {
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrg201ResponseRegisteredOrg
     */
    recordID?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrg201ResponseRegisteredOrg
     */
    rowError?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegisterNewOrg201ResponseRegisteredOrg
     */
    validationErrors?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegisterNewOrg201ResponseRegisteredOrg
     */
    values?: Array<string>;
}

/**
 * Check if a given object implements the RegisterNewOrg201ResponseRegisteredOrg interface.
 */
export function instanceOfRegisterNewOrg201ResponseRegisteredOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegisterNewOrg201ResponseRegisteredOrgFromJSON(json: any): RegisterNewOrg201ResponseRegisteredOrg {
    return RegisterNewOrg201ResponseRegisteredOrgFromJSONTyped(json, false);
}

export function RegisterNewOrg201ResponseRegisteredOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterNewOrg201ResponseRegisteredOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordID': !exists(json, 'recordID') ? undefined : json['recordID'],
        'rowError': !exists(json, 'rowError') ? undefined : json['rowError'],
        'validationErrors': !exists(json, 'validationErrors') ? undefined : json['validationErrors'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function RegisterNewOrg201ResponseRegisteredOrgToJSON(value?: RegisterNewOrg201ResponseRegisteredOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordID': value.recordID,
        'rowError': value.rowError,
        'validationErrors': value.validationErrors,
        'values': value.values,
    };
}

