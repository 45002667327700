// Copyright 2024 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Heading, TextInput, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "@src/components";
import { SearchRequestObjectTypeEnum } from "@src/gen/org-portal";
import { View } from "react-native";
import type { FormikProps } from "formik";

const { Some } = Helpers;
export const SCREEN_NAME = "NamesForm";

type AsyncFormField = {
  readonly error: string | undefined;
  readonly previousName: string | undefined;
};

export type AsyncFormData = {
  readonly templateName: AsyncFormField;
  readonly datasourceName: AsyncFormField;
};

export type FormFields = {
  readonly templateName: string;
  readonly datasourceName: string;
};

type Props = FormFields & {
  readonly formRef: React.RefObject<FormikProps<FormFields>>;
  readonly onSubmit: (values: FormFields) => void;
  readonly checkForDuplicateNames: (
    objectName: string,
    objectType: SearchRequestObjectTypeEnum
  ) => Promise<void>;
  readonly asyncData: AsyncFormData;
  readonly updateFormValues: (name: string, value: string) => void;
};

export const NamesForm = ({
  asyncData,
  checkForDuplicateNames,
  datasourceName,
  formRef,
  onSubmit,
  templateName,
  updateFormValues,
}: Props) => {
  const { theme } = useTheme();

  const rulesValidationSchema = yup.object({
    datasourceName: yup
      .string()
      .trim()
      .required("Data source name is required")
      .min(2, "Data source name must be at least 2 characters")
      .max(60, "Data source name must be less than 60 characters"),
    templateName: yup
      .string()
      .trim()
      .required("Template name is required")
      .min(2, "Template name must be at least 2 characters")
      .max(60, "Template name must be less than 60 characters"),
  });

  const errorText = (errorMessage: string) => (
    <View style={{ paddingVertical: theme.spacing.xs }}>
      <Heading color={theme.colors.text.alert.critical} level="6">
        {errorMessage}
      </Heading>
    </View>
  );

  return (
    <Formik
      enableReinitialize
      initialTouched={{ datasourceName: true, templateName: true }}
      initialValues={{ datasourceName, templateName }}
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={rulesValidationSchema}
    >
      {({ setFieldValue, values }) => (
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: 400 }}>
            <TextInput
              label="Template name *"
              maxLength={120}
              onBlur={() => {
                if (asyncData.templateName.previousName !== values.templateName) {
                  checkForDuplicateNames(values.templateName, SearchRequestObjectTypeEnum.Template);
                }
              }}
              onChangeText={value => {
                updateFormValues("templateName", value);
                setFieldValue("templateName", value);
              }}
              placeholder="Template name"
              testProps={{
                elementName: "templateNameInput",
                screenName: SCREEN_NAME,
              }}
              value={values.templateName}
            />
            {Some(asyncData.templateName.error) ? (
              errorText(asyncData.templateName.error)
            ) : (
              <ErrorMessage name="templateName">{error => errorText(error)}</ErrorMessage>
            )}
          </View>
          <HorizontalSpacer size={theme.spacing.xxl} />
          <View style={{ width: 400 }}>
            <TextInput
              label="Data source name *"
              maxLength={120}
              onBlur={() => {
                if (asyncData.datasourceName.previousName !== values.datasourceName) {
                  checkForDuplicateNames(
                    values.datasourceName,
                    SearchRequestObjectTypeEnum.Datasource
                  );
                }
              }}
              onChangeText={value => {
                updateFormValues("datasourceName", value);
                setFieldValue("datasourceName", value);
              }}
              placeholder="Data source name"
              testProps={{
                elementName: "datasourceNameInput",
                screenName: SCREEN_NAME,
              }}
              value={values.datasourceName}
            />
            {Some(asyncData.datasourceName.error) ? (
              errorText(asyncData.datasourceName.error)
            ) : (
              <ErrorMessage name="datasourceName">{error => errorText(error)}</ErrorMessage>
            )}
          </View>
        </View>
      )}
    </Formik>
  );
};
