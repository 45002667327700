// Copyright 2023 Merit International Inc. All Rights Reserved

import { StyleSheet } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

type FolioDetailsStyles = {
  readonly row: ViewStyle;
  readonly tabBody: ViewStyle;
  readonly tabsContainer: ViewStyle;
};

export const useFolioDetailsScreenStyles = () => {
  const { theme } = useTheme();

  return StyleSheet.create<FolioDetailsStyles>({
    row: {
      alignItems: "center",
      flexDirection: "row",
    },
    tabBody: {
      flex: 1,
      paddingVertical: theme.spacing.xxl,
    },
    tabsContainer: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      paddingHorizontal: 32,
    },
  });
};
