/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Determines the action taken on containers following a template update
 * * `recalculateExisting` - only recalculate existing containers. This is the default if the property is not set.
 * * `recalculateExistingAndCreateNew` - recalculate existing containers and create new containers for records that
 * lack a corresponding container
 * 
 * @export
 */
export const ActionOnUpdate = {
    RecalculateExisting: 'recalculateExisting',
    RecalculateExistingAndCreateNew: 'recalculateExistingAndCreateNew'
} as const;
export type ActionOnUpdate = typeof ActionOnUpdate[keyof typeof ActionOnUpdate];


export function ActionOnUpdateFromJSON(json: any): ActionOnUpdate {
    return ActionOnUpdateFromJSONTyped(json, false);
}

export function ActionOnUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionOnUpdate {
    return json as ActionOnUpdate;
}

export function ActionOnUpdateToJSON(value?: ActionOnUpdate | null): any {
    return value as any;
}

