// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, Heading, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import {
  CreateDatasourceRequestIntegrationTypeEnum,
  CreateDatasourceRequestSchemaDelimiterEnum,
} from "../../gen/org-portal";
import { DataSet } from "./DataSet";
import { DetailsDrawer } from "../../layouts/DetailsDrawer";
import { Fields } from "./Fields";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { Overview } from "./Overview";
import { Rules } from "./Rules";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin, Tabs } from "../../components";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../../src/stores";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useCallback, useEffect, useState } from "react";
import type {
  CreateDatasourceRequestSchemaColumnsInner,
  GetDatasource200ResponseMappedTemplatesInner,
} from "../../gen/org-portal";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PostLoginRouteParams } from "../../Router";
import type { TemplateLineage } from "./Overview";

type TabKey = "dataset" | "fields" | "overview" | "rules" | "template";

export const SCREEN_NAME = "TemplateDetails";

const { None, Some } = Helpers;

type Props = {
  readonly templateID?: string;
  readonly onClose: () => void;
  readonly onStatusChange: () => void;
};

export const TemplateDetails = ({ onClose, onStatusChange, templateID }: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { folioFieldNames } = useAppConstantsStore();

  const [activeTab, setActiveTab] = useState<TabKey>("overview");
  const [templateResponse, setTemplateResponse] =
    useState<GetDatasource200ResponseMappedTemplatesInner>();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation<NativeStackNavigationProp<PostLoginRouteParams>>();
  const { errorHandler } = useServerErrorHandler();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [templateLineage, setTemplateLineage] = useState<readonly TemplateLineage[]>([]);
  const [isFetchingTemplate, setIsFetchingTemplate] = useState(false);

  const styles = StyleSheet.create({
    actionContainer: {
      flexDirection: "row",
    },
    divider: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingHorizontal: 32,
    },
  });

  const fetchTemplateData = useCallback(async () => {
    try {
      setLoading(true);
      if (Some(selectedOrgId) && Some(templateID)) {
        const res = await api.getTemplate({ orgID: selectedOrgId, templateID });
        setTemplateResponse(res);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [api, errorHandler, selectedOrgId, templateID]);

  useFocusEffect(
    useCallback(() => {
      fetchTemplateData();
    }, [fetchTemplateData])
  );

  useEffect(() => {
    const getTemplatesForLineage = async () => {
      try {
        setIsFetchingTemplate(true);
        if (Some(selectedOrgId)) {
          const response = await Promise.all(
            templateResponse?.lineage?.slice(0, 3).map(id =>
              api.getTemplate({
                orgID: selectedOrgId,
                templateID: id,
              })
            ) ?? []
          );

          setTemplateLineage(response.map(template => ({ id: template.id, name: template.name })));
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsFetchingTemplate(false);
      }
    };

    getTemplatesForLineage();
  }, [api, errorHandler, selectedOrgId, templateResponse, templateResponse?.lineage]);

  const changeTemplateStatus = async () => {
    if (None(templateResponse)) {
      throw new Error("Somehow, template couldn't found");
    }
    setLoading(true);
    try {
      await api.editTemplate({
        editTemplateRequest: { state: templateResponse.state === "live" ? "paused" : "live" },
        orgID: selectedOrgId,
        templateID: templateResponse.id,
      });
      fetchTemplateData();
      onStatusChange();
    } catch (err) {
      errorHandler(err);
    } finally {
      setShowConfirmationModal(false);
      setLoading(false);
    }
  };

  const getColumnDataFormat = (templateFieldType: string | undefined) => {
    if (None(templateFieldType)) {
      return undefined;
    }

    if (templateFieldType === "Date") {
      return "YYYY-MM-DD";
    }

    if (templateFieldType === "DateTime") {
      return "YYYY-MM-DD HH:mm:ss";
    }

    return undefined;
  };

  const createDataSourceAndNavigateToDataMap = useCallback(async () => {
    if (None(templateResponse) || None(templateResponse.templateFields)) {
      return;
    }

    try {
      setLoading(true);
      const filteredTemplateFields = templateResponse.templateFields.filter(
        templateField => templateField.name !== folioFieldNames.issuingOrgName
      );

      const schemaColumns = filteredTemplateFields.map(templateField => {
        if (Some(templateField.type)) {
          return {
            columnDataType: templateField.type,
            columnDateFormat: getColumnDataFormat(templateField.type),
            columnName: templateField.name ?? "",
            columnPhoneNumberDefaultCountryCode:
              templateField.type === "PhoneNumber" ? "US" : undefined,
            isIdentifier:
              templateResponse.type === "Folio"
                ? templateField.name === folioFieldNames.organizationName
                : templateField.name === "Email" && templateField.type === "Email",
            usedAsDeletion: false,
          };
        }

        return {} as CreateDatasourceRequestSchemaColumnsInner;
      });

      const createDataSourcePayload = {
        description: "",
        integrationType: CreateDatasourceRequestIntegrationTypeEnum.Csv,
        name: `${String(templateResponse.name)}-datasource`,
        schemaColumns,
        schemaDelimiter: CreateDatasourceRequestSchemaDelimiterEnum.Comma,
      };

      const response = await api.createDatasource({
        datasource: createDataSourcePayload,
        orgID: selectedOrgId,
      });
      if (Some(response.id)) {
        navigation.navigate("MapDataSource", {
          datasourceID: response.id.toString(),
          templateID: templateResponse.id,
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [
    api,
    errorHandler,
    folioFieldNames.issuingOrgName,
    folioFieldNames.organizationName,
    navigation,
    selectedOrgId,
    templateResponse,
  ]);

  if (loading) {
    return (
      <View
        style={{
          alignItems: "center",
          backgroundColor: theme.colors.background.white,
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  if (None(templateResponse)) {
    return null;
  }

  const isOwnTemplate = templateResponse.ownerID === selectedOrgId;

  const tabs: readonly { readonly key: TabKey; readonly label: string }[] = [
    {
      key: "overview",
      label: "Overview",
    },
    ...(isOwnTemplate ? [{ key: "dataset" as const, label: "Data set" }] : []),
    { key: "fields", label: "Fields" },
    { key: "rules", label: "Rules" },
  ];

  return (
    <>
      <DetailsDrawer
        actionButtons={
          <View style={styles.actionContainer}>
            {isOwnTemplate && (
              <>
                <View style={{ width: 73 }}>
                  <Button
                    onPress={() => {
                      if (Some(templateResponse.id) && Some(templateResponse.type)) {
                        navigation.navigate("ConfigureTemplate", {
                          id: templateResponse.id,
                        });
                      }
                    }}
                    size="small"
                    testProps={{
                      elementId: templateResponse.id,
                      elementName: "templateDetailsViewEditButton",
                      screenName: SCREEN_NAME,
                    }}
                    text="Edit"
                    type="secondary"
                  />
                </View>
                <HorizontalSpacer size={14} />
              </>
            )}
            {Some(templateResponse.extendable) && templateResponse.extendable && (
              <>
                <View style={{ width: 92 }}>
                  <Button
                    onPress={() => {
                      if (Some(templateResponse.id)) {
                        navigation.navigate("ExtendTemplate", {
                          id: templateResponse.id,
                        });
                      }
                    }}
                    size="small"
                    testProps={{
                      elementId: templateResponse.id,
                      elementName: "templateDetailsViewExtendButton",
                      screenName: SCREEN_NAME,
                    }}
                    text="Extend"
                    type="secondary"
                  />
                </View>
                <HorizontalSpacer size={14} />
              </>
            )}

            {isOwnTemplate && (
              <>
                <View style={{ width: 123 }}>
                  <Button
                    onPress={() => {
                      if (Some(templateResponse.name) && Some(templateResponse.id)) {
                        const { dataSourceID, name } = templateResponse;
                        if (Some(dataSourceID) && dataSourceID !== "") {
                          navigation.navigate("MapDataSource", {
                            datasourceID: dataSourceID,
                            templateID: templateResponse.id,
                          });

                          return;
                        }

                        navigation.navigate("SelectDataSource", {
                          id: templateResponse.id,
                          templateName: name,
                        });
                      }
                    }}
                    size="small"
                    testProps={{
                      elementId: templateResponse.id,
                      elementName: "templateDetailsViewMapToDataButton",
                      screenName: SCREEN_NAME,
                    }}
                    text="Map to data"
                    type="secondary"
                  />
                </View>
                <HorizontalSpacer size={14} />

                {Some(templateResponse.dataSourceID) &&
                  templateResponse.dataSourceID.length === 0 && (
                    <>
                      <View style={{ width: 105 }}>
                        <Button
                          onPress={() => {
                            createDataSourceAndNavigateToDataMap();
                          }}
                          size="small"
                          testProps={{
                            elementId: templateResponse.id,
                            elementName: "templateDetailsViewAutomapButton",
                            screenName: SCREEN_NAME,
                          }}
                          text="Automap"
                          type="secondary"
                        />
                      </View>
                      <HorizontalSpacer size={14} />
                    </>
                  )}
              </>
            )}

            {isOwnTemplate && (
              <View style={{ width: 87 }}>
                <Button
                  onPress={() => {
                    setShowConfirmationModal(true);
                  }}
                  size="small"
                  testProps={{
                    elementId: templateResponse.id,
                    elementName: `templateDetailsView${
                      templateResponse.state === "live" ? "Pause" : "Publish"
                    }TemplateButton`,
                    screenName: SCREEN_NAME,
                  }}
                  text={`${templateResponse.state === "live" ? "Pause" : "Publish"}`}
                  type="secondary"
                />
              </View>
            )}
          </View>
        }
        onPressClose={() => {
          setLoading(true);
          setActiveTab(tabs[0].key);
          setTemplateLineage([]);
          onClose();
        }}
        subTitle={
          <View style={{ flexDirection: "row" }}>
            <Heading
              level="6"
              testProps={{
                elementId: templateResponse.id,
                elementName: "templateDetailsViewTemplateID",
                screenName: SCREEN_NAME,
              }}
            >
              Template ID: {templateID}
            </Heading>
            <HorizontalSpacer size={10} />
            {isOwnTemplate && (
              <>
                <Heading color={theme.colors.text.subdued} level="6">
                  |
                </Heading>
                <HorizontalSpacer size={10} />
                <Heading
                  capitalize
                  level="6"
                  testProps={{
                    elementId: templateResponse.id,
                    elementName: "templateDetailsViewTemplateStatus",
                    screenName: SCREEN_NAME,
                  }}
                >
                  State: {templateResponse.state ?? "--"}
                </Heading>
              </>
            )}
          </View>
        }
        testProps={{
          elementId: templateResponse.id,
          elementName: "templateDetailsView",
          screenName: SCREEN_NAME,
        }}
        title={templateResponse.name === undefined ? "" : templateResponse.name}
      >
        <View style={styles.divider}>
          <Tabs
            items={tabs}
            onChange={event => {
              setActiveTab(event);
            }}
            selected={activeTab}
            testProps={{
              elementId: templateResponse.id,
              elementName: "templateDetailsView",
              screenName: SCREEN_NAME,
            }}
          />
        </View>
        <ScrollView>
          <View>
            {activeTab === "overview" &&
              (isFetchingTemplate ? (
                <View style={{ paddingVertical: theme.spacing.xxl }}>
                  <Spin />
                </View>
              ) : (
                <Overview lineage={templateLineage} template={templateResponse} />
              ))}
            {isOwnTemplate &&
              activeTab === "dataset" &&
              Some(templateResponse) &&
              Some(templateResponse.templateFields) && (
                <DataSet dataSet={templateResponse.templateFields} />
              )}
            {activeTab === "fields" &&
              Some(templateResponse) &&
              Some(templateResponse.templateFields) && (
                <Fields templateFields={templateResponse.templateFields} />
              )}
            {activeTab === "rules" &&
              Some(templateResponse) &&
              Some(templateResponse.templateFields) && (
                <Rules
                  isOwnTemplate={isOwnTemplate}
                  templateDetails={{
                    inheritedActivenessRules: templateResponse.inheritedActivenessRules,
                    inheritedCompletenessRules: templateResponse.inheritedCompletenessRules,
                    ownActivenessRule: templateResponse.ownActivenessRule,
                    ownCompletenessRule: templateResponse.ownCompletenessRule,
                    templateFields: templateResponse.templateFields,
                  }}
                />
              )}
          </View>
        </ScrollView>
      </DetailsDrawer>
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onOk={() => {
            changeTemplateStatus();
          }}
          testProps={{ elementName: "templateDetailsViewPublishPause", screenName: SCREEN_NAME }}
          text={
            templateResponse.state === "live"
              ? "No new data will be allowed to come from this template and any changes you make to this template will not be reflected until you publish."
              : "New data will be allowed to come in from this template and any changes you made to the template while paused will take effect immediately."
          }
          title={`Are you sure you want to ${
            templateResponse.state === "live" ? "pause" : "publish"
          } template?`}
        />
      )}
    </>
  );
};
