/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';
import type { Formula } from './Formula';
import {
    FormulaFromJSON,
    FormulaFromJSONTyped,
    FormulaToJSON,
} from './Formula';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * information for extending a policy
 * @export
 * @interface ExtendPolicyProperties
 */
export interface ExtendPolicyProperties {
    /**
     * ID of the source policy
     * @type {string}
     * @memberof ExtendPolicyProperties
     */
    sourcePolicyID: string;
    /**
     * Name of the policy
     * @type {string}
     * @memberof ExtendPolicyProperties
     */
    name?: string | null;
    /**
     * Description of the policy
     * @type {string}
     * @memberof ExtendPolicyProperties
     */
    description?: string | null;
    /**
     * Message when policy evaluates to true
     * @type {string}
     * @memberof ExtendPolicyProperties
     */
    trueMessage?: string | null;
    /**
     * Message when policy evaluates to false
     * @type {string}
     * @memberof ExtendPolicyProperties
     */
    falseMessage?: string | null;
    /**
     * Permissions of the new policy
     * @type {Array<PermissionPair>}
     * @memberof ExtendPolicyProperties
     */
    permissions?: Array<PermissionPair>;
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<Atom>}
     * @memberof ExtendPolicyProperties
     */
    rule?: Array<Atom>;
    /**
     * 
     * @type {Formula}
     * @memberof ExtendPolicyProperties
     */
    formula?: Formula;
}

/**
 * Check if a given object implements the ExtendPolicyProperties interface.
 */
export function instanceOfExtendPolicyProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourcePolicyID" in value;

    return isInstance;
}

export function ExtendPolicyPropertiesFromJSON(json: any): ExtendPolicyProperties {
    return ExtendPolicyPropertiesFromJSONTyped(json, false);
}

export function ExtendPolicyPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendPolicyProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourcePolicyID': json['sourcePolicyID'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'trueMessage': !exists(json, 'trueMessage') ? undefined : json['trueMessage'],
        'falseMessage': !exists(json, 'falseMessage') ? undefined : json['falseMessage'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'rule': !exists(json, 'rule') ? undefined : ((json['rule'] as Array<any>).map(AtomFromJSON)),
        'formula': !exists(json, 'formula') ? undefined : FormulaFromJSON(json['formula']),
    };
}

export function ExtendPolicyPropertiesToJSON(value?: ExtendPolicyProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourcePolicyID': value.sourcePolicyID,
        'name': value.name,
        'description': value.description,
        'trueMessage': value.trueMessage,
        'falseMessage': value.falseMessage,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'rule': value.rule === undefined ? undefined : ((value.rule as Array<any>).map(AtomToJSON)),
        'formula': FormulaToJSON(value.formula),
    };
}

