// Copyright 2024 Merit International Inc. All Rights Reserved

import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum as DataTypeEnum } from "@src/gen/org-portal";

export const getReadableDataType = (dataType: DataTypeEnum) => {
  const dataTypeMappings: Record<DataTypeEnum, string> = {
    Blob: "Blob",
    Bool: "Boolean",
    Date: "Date",
    DateTime: "Date Time",
    Email: "Email",
    JSON: "JSON",
    Markdown: "Markdown",
    Number: "Number",
    PhoneNumber: "Phone Number",
    Text: "Text",
  };

  return dataTypeMappings[dataType];
};
