/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidData
 */
export interface ValidData {
    /**
     * A globally unique ObjectId base64 encoded for the Record, can be referenced globally
     * @type {string}
     * @memberof ValidData
     */
    recordID: string;
    /**
     * A map of header to value as expected by the schema
     * @type {object}
     * @memberof ValidData
     */
    values: object;
    /**
     * A map of header to validation error in the event a value is invalid (such as badly formatted email)
     * @type {object}
     * @memberof ValidData
     */
    validationErrors?: object;
    /**
     * An error message specifying if the record data has a failure that prevents the record being usable, such as missing a required header value.
     * @type {string}
     * @memberof ValidData
     */
    rowError?: string;
    /**
     * The ingested record is a duplicate of an existing record on the datasource. Therefore that the last ingest at 
     * time on the datasource will not be updated as a result of this ingestion and no new record will be created on 
     * the datasource.
     * 
     * @type {boolean}
     * @memberof ValidData
     */
    duplicateDetected?: boolean;
}

/**
 * Check if a given object implements the ValidData interface.
 */
export function instanceOfValidData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordID" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function ValidDataFromJSON(json: any): ValidData {
    return ValidDataFromJSONTyped(json, false);
}

export function ValidDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordID': json['recordID'],
        'values': json['values'],
        'validationErrors': !exists(json, 'validationErrors') ? undefined : json['validationErrors'],
        'rowError': !exists(json, 'rowError') ? undefined : json['rowError'],
        'duplicateDetected': !exists(json, 'duplicateDetected') ? undefined : json['duplicateDetected'],
    };
}

export function ValidDataToJSON(value?: ValidData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordID': value.recordID,
        'values': value.values,
        'validationErrors': value.validationErrors,
        'rowError': value.rowError,
        'duplicateDetected': value.duplicateDetected,
    };
}

