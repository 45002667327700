/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  Record,
  UploadStatus,
  UploadURL,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    RecordFromJSON,
    RecordToJSON,
    UploadStatusFromJSON,
    UploadStatusToJSON,
    UploadURLFromJSON,
    UploadURLToJSON,
} from '../models/index';

export interface FileStatusRequest {
    correlationID: string;
    authorization?: string;
}

export interface UploadBlobUrlRequest {
    dataSourceID: string;
    columnID: string;
    record: Record;
    authorization?: string;
}

export interface UploadDataUrlRequest {
    dataSourceID: string;
    authorization?: string;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * `read:file_ingestion_status`  Get the status of a file upload 
     * Get Upload Status
     */
    async fileStatusRaw(requestParameters: FileStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadStatus>> {
        if (requestParameters.correlationID === null || requestParameters.correlationID === undefined) {
            throw new runtime.RequiredError('correlationID','Required parameter requestParameters.correlationID was null or undefined when calling fileStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/files/{correlationID}/status`.replace(`{${"correlationID"}}`, encodeURIComponent(String(requestParameters.correlationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadStatusFromJSON(jsonValue));
    }

    /**
     * `read:file_ingestion_status`  Get the status of a file upload 
     * Get Upload Status
     */
    async fileStatus(requestParameters: FileStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadStatus> {
        const response = await this.fileStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `create:record`  Get a URL to upload a Blob value to a data source. 
     * Get BLOB Upload URL
     */
    async uploadBlobUrlRaw(requestParameters: UploadBlobUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadURL>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling uploadBlobUrl.');
        }

        if (requestParameters.columnID === null || requestParameters.columnID === undefined) {
            throw new runtime.RequiredError('columnID','Required parameter requestParameters.columnID was null or undefined when calling uploadBlobUrl.');
        }

        if (requestParameters.record === null || requestParameters.record === undefined) {
            throw new runtime.RequiredError('record','Required parameter requestParameters.record was null or undefined when calling uploadBlobUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}/columns/{columnID}/upload-url`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))).replace(`{${"columnID"}}`, encodeURIComponent(String(requestParameters.columnID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordToJSON(requestParameters.record),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadURLFromJSON(jsonValue));
    }

    /**
     * `create:record`  Get a URL to upload a Blob value to a data source. 
     * Get BLOB Upload URL
     */
    async uploadBlobUrl(requestParameters: UploadBlobUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadURL> {
        const response = await this.uploadBlobUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `create:record`  Get a URL for a CSV upload to add multiple records to a data source 
     * Get CSV Upload URL
     */
    async uploadDataUrlRaw(requestParameters: UploadDataUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadURL>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling uploadDataUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}/upload-url`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadURLFromJSON(jsonValue));
    }

    /**
     * `create:record`  Get a URL for a CSV upload to add multiple records to a data source 
     * Get CSV Upload URL
     */
    async uploadDataUrl(requestParameters: UploadDataUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadURL> {
        const response = await this.uploadDataUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
