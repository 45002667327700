// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { StyleSheet, View } from "react-native";
import { getContainerFieldValue } from "../../utils/getContainerFieldValue";
import { getDateTimeString } from "../../utils/time";
import { useAppConstantsStore } from "../../stores";
import React from "react";
import type { ContainerWithTemplateType } from "./Approvals";
import type { OPTestProps } from "../../types/TestProps";

const { Some } = Helpers;

type Props = {
  readonly container: ContainerWithTemplateType;
  readonly testProps: OPTestProps;
};

export const Overview = ({ container, testProps }: Props) => {
  const { theme } = useTheme();
  const { folioFieldNames, meritFieldNames } = useAppConstantsStore();

  const styles = StyleSheet.create({
    sectionTitle: {
      fontWeight: theme.fontWeights.semiBold,
    },
  });

  return (
    <View style={{ marginHorizontal: 32 }}>
      <VerticalSpacer size={theme.spacing.xxl} />

      <Body style={styles.sectionTitle}>Description</Body>
      <VerticalSpacer size={theme.spacing.xs} />
      <Body
        style={{ lineHeight: theme.spacing.xl }}
        testProps={{
          ...testProps,
          elementName: `${testProps.elementName}Description`,
        }}
      >
        {Some(container.description) && container.description !== "" ? container.description : "--"}
      </Body>

      <VerticalSpacer size={theme.spacing.xxl} />

      <Body style={styles.sectionTitle}>Type</Body>
      <VerticalSpacer size={theme.spacing.xs} />
      <Body
        style={{ lineHeight: theme.spacing.xl }}
        testProps={{
          ...testProps,
          elementName: `${testProps.elementName}TemplateType`,
        }}
      >
        {container.templateType}
      </Body>

      <VerticalSpacer size={theme.spacing.xxl} />

      <Body style={styles.sectionTitle}>Issuing Org</Body>
      <VerticalSpacer size={theme.spacing.xs} />
      <Body
        testProps={{
          ...testProps,
          elementName: `${testProps.elementName}IssuingOrg`,
        }}
      >
        {getContainerFieldValue(
          container.templateType === "Merit"
            ? meritFieldNames.issuingOrgName
            : folioFieldNames.issuingOrgName,
          container
        ) ?? "--"}
      </Body>

      <VerticalSpacer size={theme.spacing.xxl} />

      <Body style={styles.sectionTitle}>Recipient</Body>
      <VerticalSpacer size={theme.spacing.xs} />
      <View style={{ flexDirection: "row" }}>
        {container.templateType === "Merit" && (
          <>
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}RecipientName`,
              }}
            >
              {getContainerFieldValue(meritFieldNames.firstName, container) ?? "--"}{" "}
              {getContainerFieldValue(meritFieldNames.lastName, container) ?? "--"}
            </Body>
            <HorizontalSpacer size={theme.spacing.m} />
            <Body color={theme.colors.border.default}>|</Body>
            <HorizontalSpacer size={theme.spacing.m} />
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}RecipientID`,
              }}
            >
              {container.recipient?.id ?? "--"}
            </Body>
          </>
        )}
        {container.templateType === "Folio" && (
          <Body>
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}RecipientOrganizationName`,
              }}
            >
              {getContainerFieldValue(folioFieldNames.organizationName, container) ?? "--"}
            </Body>
            <HorizontalSpacer size={theme.spacing.m} />
            <Body color={theme.colors.border.default}>|</Body>
            <HorizontalSpacer size={theme.spacing.m} />
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}RecipientID`,
              }}
            >
              {container.recipient?.id ?? "--"}
            </Body>
          </Body>
        )}
      </View>

      <VerticalSpacer size={theme.spacing.xxl} />

      <Body style={styles.sectionTitle}>Created at</Body>
      <VerticalSpacer size={theme.spacing.xs} />
      <Body
        testProps={{
          ...testProps,
          elementName: `${testProps.elementName}CreatedAt`,
        }}
      >
        {Some(container.createdAt) ? getDateTimeString(container.createdAt) : "--"}
      </Body>
    </View>
  );
};
