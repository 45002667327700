/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExportCredentialsAsOrg200ResponseExportedCredentialsValue } from './ExportCredentialsAsOrg200ResponseExportedCredentialsValue';
import {
    ExportCredentialsAsOrg200ResponseExportedCredentialsValueFromJSON,
    ExportCredentialsAsOrg200ResponseExportedCredentialsValueFromJSONTyped,
    ExportCredentialsAsOrg200ResponseExportedCredentialsValueToJSON,
} from './ExportCredentialsAsOrg200ResponseExportedCredentialsValue';

/**
 * Export credential response
 * @export
 * @interface ExportCredentialsAsOrg200Response
 */
export interface ExportCredentialsAsOrg200Response {
    /**
     * Map of exported credentials where the keys are the UUID of the object the Credential is associated with
     * @type {{ [key: string]: ExportCredentialsAsOrg200ResponseExportedCredentialsValue; }}
     * @memberof ExportCredentialsAsOrg200Response
     */
    exportedCredentials: { [key: string]: ExportCredentialsAsOrg200ResponseExportedCredentialsValue; };
}

/**
 * Check if a given object implements the ExportCredentialsAsOrg200Response interface.
 */
export function instanceOfExportCredentialsAsOrg200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "exportedCredentials" in value;

    return isInstance;
}

export function ExportCredentialsAsOrg200ResponseFromJSON(json: any): ExportCredentialsAsOrg200Response {
    return ExportCredentialsAsOrg200ResponseFromJSONTyped(json, false);
}

export function ExportCredentialsAsOrg200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCredentialsAsOrg200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportedCredentials': (mapValues(json['exportedCredentials'], ExportCredentialsAsOrg200ResponseExportedCredentialsValueFromJSON)),
    };
}

export function ExportCredentialsAsOrg200ResponseToJSON(value?: ExportCredentialsAsOrg200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportedCredentials': (mapValues(value.exportedCredentials, ExportCredentialsAsOrg200ResponseExportedCredentialsValueToJSON)),
    };
}

