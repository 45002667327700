// Copyright 2024 Merit International Inc. All Rights Reserved

import { GetContainersStateEnum } from "@src/gen/org-portal";
import { HorizontalSpacer } from "../../../components/Spacer";
import { SCREEN_NAME } from "./ConnectedOrganizations";
import { Select, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";

const OPTIONS = [
  { label: "Issued", value: "issued" },
  { label: "Received", value: "received" },
];

const ISSUED_STATUS_OPTIONS = [
  { label: "Rejected", value: GetContainersStateEnum.Rejected },
  { label: "Revoked", value: GetContainersStateEnum.Revoked },
  { label: "Unissued", value: GetContainersStateEnum.Unissued },
];

const RECEIVED_STATUS_OPTIONS = [
  { label: "Accepted", value: GetContainersStateEnum.Accepted },
  { label: "Pending", value: GetContainersStateEnum.Pending },
];

export type FilterValues = {
  readonly interaction?: string;
  readonly status?: GetContainersStateEnum;
};

type Props = {
  readonly onSelect: (values: FilterValues) => void;
  readonly placeholder: string;
  readonly selectedFilterValues?: FilterValues;
};

export const ConnectedOrgFilter = ({ onSelect, placeholder, selectedFilterValues }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.surface.subdued,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    fieldItem: {
      minWidth: 325,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.fieldItem}>
        <Select
          defaultValue={OPTIONS.find(_ => _.value === selectedFilterValues?.interaction)}
          label="Interaction"
          onSelectOption={option => {
            const value = option.value;
            if (typeof value === "string") {
              onSelect({ interaction: value, status: selectedFilterValues?.status });
            }
          }}
          options={OPTIONS}
          placeholder={{
            label: placeholder,
            value: "",
          }}
          testProps={{
            elementName: "filterModalViewInterActionType",
            screenName: SCREEN_NAME,
          }}
          usePortal
        />
      </View>
      <HorizontalSpacer />
      <View style={styles.fieldItem}>
        <Select
          defaultValue={[...ISSUED_STATUS_OPTIONS, ...RECEIVED_STATUS_OPTIONS].find(
            _ => _.value === selectedFilterValues?.status
          )}
          label="Status"
          onSelectOption={option => {
            const value = option.value;
            if (typeof value === "string") {
              onSelect({
                interaction: selectedFilterValues?.interaction,
                status: value as GetContainersStateEnum,
              });
            }
          }}
          options={
            selectedFilterValues?.interaction === "received"
              ? RECEIVED_STATUS_OPTIONS
              : [...RECEIVED_STATUS_OPTIONS, ...ISSUED_STATUS_OPTIONS]
          }
          placeholder={{
            label: placeholder,
            value: "",
          }}
          testProps={{
            elementName: "filterModalViewStatusActionType",
            screenName: SCREEN_NAME,
          }}
          usePortal
        />
      </View>
    </View>
  );
};
