// Copyright 2024 Merit International Inc. All Rights Reserved

import { Field } from "formik";
import { Select } from "@merit/frontend-components";
import type { FC } from "react";
import type { FieldInputProps, FieldProps } from "formik";
import type { SelectProps } from "@merit/frontend-components";

export type SelectFieldProps = Omit<FieldInputProps<unknown>, "onBlur" | "onChange" | "value"> &
  SelectProps;

export const SelectField: FC<SelectFieldProps> = props => {
  const { name, onSelectOption } = props;

  return (
    <Field {...props}>
      {({ field, form }: FieldProps) => (
        <Select
          {...props}
          {...field}
          onSelectOption={option => {
            form.setFieldValue(name, option.value);
            onSelectOption?.(option);
          }}
        />
      )}
    </Field>
  );
};
