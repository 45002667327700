/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner
 */
export interface GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner {
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner
     */
    condition: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner
     */
    errorMessage: string;
}

/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "condition" in value;
    isInstance = isInstance && "errorMessage" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner {
    return GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition': json['condition'],
        'errorMessage': json['errorMessage'],
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInnerToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleReadableRuleConditionsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition': value.condition,
        'errorMessage': value.errorMessage,
    };
}

