// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Image, View } from "react-native";
import { Images } from "../../utils/Image";
import { SCREEN_NAME } from "./Records";
import { VerticalSpacer } from "../../components/Spacer";
import React from "react";

export const SearchRecords = () => {
  const { theme } = useTheme();

  return (
    <>
      <VerticalSpacer size={76} />
      <View style={{ alignItems: "center" }}>
        <VerticalSpacer size={40} />
        <Image
          accessibilityLabel="search-documents"
          source={Images.documentWithSearch}
          style={{ height: 48, width: 48 }}
        />
        <VerticalSpacer size={theme.spacing.xxl} />
        <View style={{ width: 440 }}>
          <Heading
            bold
            center
            level="3"
            testProps={{
              elementName: "recordsListViewPerformSearchFiltersText",
              screenName: SCREEN_NAME,
            }}
          >
            Perform a search or filter to display records of merits and folios
          </Heading>
          <VerticalSpacer />
          <Body
            center
            size="l"
            testProps={{
              elementName: "recordsListViewPerformSearchFiltersHelpText",
              screenName: SCREEN_NAME,
            }}
          >
            The table will display once you choose a specific filter
          </Body>
        </View>
      </View>
    </>
  );
};
