/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  Record,
  ValidData,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    RecordFromJSON,
    RecordToJSON,
    ValidDataFromJSON,
    ValidDataToJSON,
} from '../models/index';

export interface DeleteRecordRequest {
    dataSourceID: string;
    record: Record;
    authorization?: string;
}

export interface IngestRecordRequest {
    dataSourceID: string;
    record: Record;
    authorization?: string;
}

/**
 * 
 */
export class RecordsApi extends runtime.BaseAPI {

    /**
     * `create:record`  Remove a record from a data source. This will soft-delete the existing record associated with the identifying values. Re-ingesting data with the same identifying values will start a new record history. 
     * Delete Record
     */
    async deleteRecordRaw(requestParameters: DeleteRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling deleteRecord.');
        }

        if (requestParameters.record === null || requestParameters.record === undefined) {
            throw new runtime.RequiredError('record','Required parameter requestParameters.record was null or undefined when calling deleteRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}/record`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RecordToJSON(requestParameters.record),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `create:record`  Remove a record from a data source. This will soft-delete the existing record associated with the identifying values. Re-ingesting data with the same identifying values will start a new record history. 
     * Delete Record
     */
    async deleteRecord(requestParameters: DeleteRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRecordRaw(requestParameters, initOverrides);
    }

    /**
     * `create:record`  Upload a single record to a data source. The data keys should match the data source column names exactly and include all of the identifying columns for the data source. 
     * Ingest Record
     */
    async ingestRecordRaw(requestParameters: IngestRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidData>> {
        if (requestParameters.dataSourceID === null || requestParameters.dataSourceID === undefined) {
            throw new runtime.RequiredError('dataSourceID','Required parameter requestParameters.dataSourceID was null or undefined when calling ingestRecord.');
        }

        if (requestParameters.record === null || requestParameters.record === undefined) {
            throw new runtime.RequiredError('record','Required parameter requestParameters.record was null or undefined when calling ingestRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/datasources/{dataSourceID}/record`.replace(`{${"dataSourceID"}}`, encodeURIComponent(String(requestParameters.dataSourceID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordToJSON(requestParameters.record),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidDataFromJSON(jsonValue));
    }

    /**
     * `create:record`  Upload a single record to a data source. The data keys should match the data source column names exactly and include all of the identifying columns for the data source. 
     * Ingest Record
     */
    async ingestRecord(requestParameters: IngestRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidData> {
        const response = await this.ingestRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
