// Copyright 2023 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { ConfirmationModal } from "../../../components/Modals";
import { Drawer } from "../../../components/Drawer";
import { FieldKindsList } from "./FieldKindsList";
import { Footer } from "../Footer";
import { Form, Formik, type FormikProps } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "../ConfigureTemplate";
import { ScrollView, View } from "react-native";
import { Spin } from "../../../components";
import { TemplateFields } from "./TemplateFields";
import { useAlertStore } from "../../../stores";
import { useApi } from "../../../api/api";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { useTheme } from "@merit/frontend-components";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useMemo, useState } from "react";
import type { FormFields, FormValues } from "./TemplateFields";
import type {
  GetFieldKind200Response,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner as TemplateFieldPermission,
} from "../../../gen/org-portal";
import type { Template } from "../ConfigureTemplate";

const { None, Some } = Helpers;

type Props = {
  readonly unSaveConfirmation: () => void;
  readonly templateID: string;
  readonly getTemplatesContainer: () => void;
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
  readonly templateDetails: Template;
};

type FieldKinds = FormFields & {
  readonly fieldKindID: string;
};

export const Fields = ({
  formRef,
  getTemplatesContainer,
  templateDetails,
  templateID,
  unSaveConfirmation,
}: Props) => {
  const { templateFields } = templateDetails;

  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const [showFieldsListModal, setShowFieldsListModal] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useServerErrorHandler();
  const [fieldKindsToAddTemplate, setFieldKindsToAddTemplate] = useState<readonly FieldKinds[]>([]);

  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();

  if (None(selectedOrgId)) {
    throw new Error("Somehow org id not found");
  }

  const getPermissions = useCallback(
    (isVisible: boolean) => [
      {
        action: "read_data",
        permissibleToPermit: {
          grantedTo: isVisible ? "All" : "None",
        },
        permitted: {
          grantedTo: isVisible ? "All" : "None",
        },
      } as const,
    ],
    []
  );

  const requiredTemplateFieldIDs = useMemo(() => {
    const inheritedCompletenessRulesFieldIDs =
      templateDetails.inheritedCompletenessRules
        ?.flatMap(rules => rules.ruleConditions)
        .map(({ target }) => target) ?? [];

    const ownCompletenessRulesFieldIDs =
      templateDetails.ownCompletenessRule?.ruleConditions.map(({ target }) => target) ?? [];

    return [...inheritedCompletenessRulesFieldIDs, ...ownCompletenessRulesFieldIDs];
  }, [templateDetails]);

  if (None(templateFields)) {
    return null;
  }

  const submitForm = async () => {
    if (Some(formRef.current)) {
      await formRef.current.submitForm();
    }
  };

  const hasReadPermission = (fieldPermission: readonly TemplateFieldPermission[]) => {
    const perms = fieldPermission.reduce<readonly string[]>((prev, permission) => {
      if (
        permission.permitted.grantedToName === "All" &&
        permission.permitted.action === "read_data"
      ) {
        return [...prev, permission.action];
      }

      return prev;
    }, []);

    return perms.length > 0;
  };

  const saveTemplate = async () => {
    if (None(formRef.current)) {
      return;
    }

    const updatedFieldKindsToAddTemplate = fieldKindsToAddTemplate.map(field => {
      const formValues = formRef.current?.values.fields.find(
        _ => _.fieldKindID === field.fieldKindID
      );

      if (Some(formValues)) {
        return {
          method: "AddTemplateFieldProperties",
          ...field,
          ...formValues,
          permissions: getPermissions(formValues.isVisible),
        };
      }

      return field;
    });

    const dirtyFields = formRef.current.values.fields.filter(
      ({ fieldID, name }) =>
        !updatedFieldKindsToAddTemplate.some(
          field => field.name === name && field.fieldID === fieldID
        )
    );
    setIsLoading(true);

    const editedFields = dirtyFields
      .filter(
        ({ description, fieldID, isVisible, name }) =>
          !templateFields.some(
            field =>
              field.name === name &&
              field.description === description &&
              field.fieldID === fieldID &&
              hasReadPermission(field.permissions ?? []) === isVisible
          )
      )
      .map(field => ({
        ...field,
        id: field.fieldID,
        method: "UpdateTemplateFieldProperties",
        permissions: getPermissions(field.isVisible),
      }));

    const fieldsPayload = [...updatedFieldKindsToAddTemplate, ...editedFields];

    if (fieldsPayload.length > 0) {
      try {
        setIsLoading(true);
        await api.editTemplate(
          {
            editTemplateRequest: {},
            orgID: selectedOrgId,
            templateID,
          },
          // Since swagger is not generating the proper types and request body due to discriminator type.
          // So had to pass the body explicitly
          {
            body: {
              fields: fieldsPayload,
            } as unknown as FormData,
          }
        );
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: "Template has been updated",
          type: "success",
        });
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }

    const templateResponse = await api.getTemplate({ orgID: selectedOrgId, templateID });

    const formFieldIdsOrder = formRef.current.values.fields.map(field => {
      const templateField = templateResponse.templateFields?.find(
        ({ name }) => name === field.name
      );

      return {
        ...field,
        fieldID: templateField?.fieldID ?? "",
      };
    });

    const templateIDsToReorder = formFieldIdsOrder
      .filter(_ => _.fieldID !== "")
      .map(({ fieldID }) => fieldID);
    const initialTemplateIDs = templateResponse.templateFields?.map(({ fieldID }) => fieldID);

    if (JSON.stringify(initialTemplateIDs) !== JSON.stringify(templateIDsToReorder)) {
      try {
        setIsLoading(true);
        await api.reorderTemplateFields({
          orgID: selectedOrgId,
          templateFieldReorderRequest: {
            newTemplateFieldsOrder: templateIDsToReorder,
          },
          templateID,
        });
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: "Template fields successfully reordered",
          type: "success",
        });
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
    getTemplatesContainer();
  };

  const validationSchema = yup.object({
    fields: yup.array().of(
      yup.object().shape({
        description: yup.string().optional().max(300, "300 maximum character limit"),
        name: yup
          .string()
          .trim()
          .required("Please enter field name")
          .max(90, "90 maximum character limit"),
      })
    ),
  });

  const addFieldsToTemplate = (selectedFields: readonly GetFieldKind200Response[]) => {
    const selectedFieldsWithoutDuplicates = selectedFields.filter(field => {
      const fieldKind = formRef.current?.values.fields.find(({ name }) => name === field.name);

      if (Some(fieldKind)) {
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: `A field with the name ${fieldKind.name} already exists`,
          type: "error",
        });

        return undefined;
      }

      return field;
    });

    const fieldToAdd = selectedFieldsWithoutDuplicates.map(field => ({
      contact: false,
      description: field.description ?? "",
      fieldID: uuidv4(),
      fieldKindID: field.fieldKindID,
      isInherited: false,
      isVisible: true,
      name: field.name,
      nameToDisplay: field.name,
    }));

    formRef.current?.setValues({
      fields: [...formRef.current.values.fields, ...fieldToAdd],
    });
    setFieldKindsToAddTemplate(prevState => [...prevState, ...fieldToAdd]);
  };

  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ alignItems: "center", flex: 1 }}>
          <View style={{ paddingVertical: theme.spacing.xxl, width: 960 }}>
            <Formik
              enableReinitialize
              initialValues={{
                fields: templateFields.map(field => ({
                  contact: field.contact,
                  description: field.description ?? "",
                  fieldID: field.fieldID ?? "",
                  isInherited: Some(field.lineage) && field.lineage.length > 0,
                  isVisible: hasReadPermission(field.permissions ?? []),
                  name: field.name ?? "",
                  nameToDisplay: field.name ?? "",
                })),
              }}
              innerRef={formRef}
              onSubmit={() => {
                setIsConfirmationModalOpen(true);
              }}
              validationSchema={validationSchema}
            >
              {props => (
                <Form>
                  <Spin
                    spinning={isLoading}
                    {...Helpers.generateTestIdProps({
                      elementName: "configureTemplateFieldsTabLoader",
                      screenName: SCREEN_NAME,
                    })}
                  >
                    <TemplateFields
                      displayFieldsListModal={() => {
                        setShowFieldsListModal(true);
                      }}
                      formProps={props}
                      requiredTemplateFieldIDs={requiredTemplateFieldIDs}
                    />
                  </Spin>
                </Form>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
      <Footer
        onCancel={unSaveConfirmation}
        onSave={() => {
          submitForm();
        }}
        testProps={{ elementName: "configureTemplateFieldsTab", screenName: SCREEN_NAME }}
      />

      <Drawer isOpen={showFieldsListModal}>
        <FieldKindsList
          onAdd={addFieldsToTemplate}
          onDrawerClose={() => {
            setShowFieldsListModal(false);
          }}
          templateID={templateID}
        />
      </Drawer>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            saveTemplate();
            setIsConfirmationModalOpen(false);
          }}
          testProps={{
            elementName: "configureTemplateFieldsTabSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to save? Saving this change will impact any org that has extended this template from you."
          title="Save template"
        />
      )}
    </>
  );
};
