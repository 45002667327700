/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * The rules that govern results of running a Policy against an Entity and its containers.
 * @export
 * @interface GetPolicies200ResponsePoliciesInnerRules
 */
export interface GetPolicies200ResponsePoliciesInnerRules {
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof GetPolicies200ResponsePoliciesInnerRules
     */
    own?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>;
    /**
     * 
     * @type {Array<Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>>}
     * @memberof GetPolicies200ResponsePoliciesInnerRules
     */
    inherited?: Array<Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>>;
}

/**
 * Check if a given object implements the GetPolicies200ResponsePoliciesInnerRules interface.
 */
export function instanceOfGetPolicies200ResponsePoliciesInnerRules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetPolicies200ResponsePoliciesInnerRulesFromJSON(json: any): GetPolicies200ResponsePoliciesInnerRules {
    return GetPolicies200ResponsePoliciesInnerRulesFromJSONTyped(json, false);
}

export function GetPolicies200ResponsePoliciesInnerRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPolicies200ResponsePoliciesInnerRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'own': !exists(json, 'own') ? undefined : ((json['own'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
        'inherited': !exists(json, 'inherited') ? undefined : json['inherited'],
    };
}

export function GetPolicies200ResponsePoliciesInnerRulesToJSON(value?: GetPolicies200ResponsePoliciesInnerRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'own': value.own === undefined ? undefined : ((value.own as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
        'inherited': value.inherited,
    };
}

