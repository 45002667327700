import { Body, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import type { FC } from "react";
import type { Values } from "../types";

type Props = {
  readonly type: Values["ruleCombinationType"];
};

const RuleCombinationTypeLabel: FC<Props> = ({ type }) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      borderColor: theme.colors.border.subdued,
      borderRadius: theme.borderRadii.m,
      borderStyle: "solid",
      borderWidth: 1,
      marginLeft: theme.spacing.xxl,
      marginTop: -15,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.xs,
      position: "absolute",
    },
    text: {
      fontStyle: "italic",
    },
  });

  return (
    <View style={styles.container}>
      <Body style={styles.text}>{type === "any" ? "OR" : "AND"}</Body>
    </View>
  );
};

export { RuleCombinationTypeLabel };
