/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Current status of an uploaded file
 * @export
 * @interface GetFileStatus200Response
 */
export interface GetFileStatus200Response {
    /**
     * 
     * @type {string}
     * @memberof GetFileStatus200Response
     */
    status: GetFileStatus200ResponseStatusEnum;
    /**
     * An error message specifying if the file was malformed
     * @type {string}
     * @memberof GetFileStatus200Response
     */
    error?: string;
}


/**
 * @export
 */
export const GetFileStatus200ResponseStatusEnum = {
    WaitingForUpload: 'waiting for upload',
    MalwareScanIncomplete: 'malware scan incomplete',
    Processing: 'processing',
    Finished: 'finished'
} as const;
export type GetFileStatus200ResponseStatusEnum = typeof GetFileStatus200ResponseStatusEnum[keyof typeof GetFileStatus200ResponseStatusEnum];


/**
 * Check if a given object implements the GetFileStatus200Response interface.
 */
export function instanceOfGetFileStatus200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetFileStatus200ResponseFromJSON(json: any): GetFileStatus200Response {
    return GetFileStatus200ResponseFromJSONTyped(json, false);
}

export function GetFileStatus200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFileStatus200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function GetFileStatus200ResponseToJSON(value?: GetFileStatus200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'error': value.error,
    };
}

