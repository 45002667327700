/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * 
 * @export
 * @interface ExtendFieldKindRequest
 */
export interface ExtendFieldKindRequest {
    /**
     * The field kind name
     * @type {string}
     * @memberof ExtendFieldKindRequest
     */
    name: string | null;
    /**
     * The new description for a FieldKind. Since this is an optional field, if the field is missing or set to null, then the field will remain unchanged. If the field is set to empty string, then the new value would be empty string.
     * @type {string}
     * @memberof ExtendFieldKindRequest
     */
    description?: string | null;
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof ExtendFieldKindRequest
     */
    ruleValid?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>;
    /**
     * 
     * @type {string}
     * @memberof ExtendFieldKindRequest
     */
    readPermission: ExtendFieldKindRequestReadPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtendFieldKindRequest
     */
    shareReadPermission: ExtendFieldKindRequestShareReadPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtendFieldKindRequest
     */
    extendPermission: ExtendFieldKindRequestExtendPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtendFieldKindRequest
     */
    shareExtendPermission: ExtendFieldKindRequestShareExtendPermissionEnum;
}


/**
 * @export
 */
export const ExtendFieldKindRequestReadPermissionEnum = {
    All: 'All',
    None: 'None'
} as const;
export type ExtendFieldKindRequestReadPermissionEnum = typeof ExtendFieldKindRequestReadPermissionEnum[keyof typeof ExtendFieldKindRequestReadPermissionEnum];

/**
 * @export
 */
export const ExtendFieldKindRequestShareReadPermissionEnum = {
    All: 'All',
    None: 'None'
} as const;
export type ExtendFieldKindRequestShareReadPermissionEnum = typeof ExtendFieldKindRequestShareReadPermissionEnum[keyof typeof ExtendFieldKindRequestShareReadPermissionEnum];

/**
 * @export
 */
export const ExtendFieldKindRequestExtendPermissionEnum = {
    All: 'All',
    None: 'None'
} as const;
export type ExtendFieldKindRequestExtendPermissionEnum = typeof ExtendFieldKindRequestExtendPermissionEnum[keyof typeof ExtendFieldKindRequestExtendPermissionEnum];

/**
 * @export
 */
export const ExtendFieldKindRequestShareExtendPermissionEnum = {
    All: 'All',
    None: 'None'
} as const;
export type ExtendFieldKindRequestShareExtendPermissionEnum = typeof ExtendFieldKindRequestShareExtendPermissionEnum[keyof typeof ExtendFieldKindRequestShareExtendPermissionEnum];


/**
 * Check if a given object implements the ExtendFieldKindRequest interface.
 */
export function instanceOfExtendFieldKindRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "readPermission" in value;
    isInstance = isInstance && "shareReadPermission" in value;
    isInstance = isInstance && "extendPermission" in value;
    isInstance = isInstance && "shareExtendPermission" in value;

    return isInstance;
}

export function ExtendFieldKindRequestFromJSON(json: any): ExtendFieldKindRequest {
    return ExtendFieldKindRequestFromJSONTyped(json, false);
}

export function ExtendFieldKindRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendFieldKindRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ruleValid': !exists(json, 'ruleValid') ? undefined : ((json['ruleValid'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
        'readPermission': json['readPermission'],
        'shareReadPermission': json['shareReadPermission'],
        'extendPermission': json['extendPermission'],
        'shareExtendPermission': json['shareExtendPermission'],
    };
}

export function ExtendFieldKindRequestToJSON(value?: ExtendFieldKindRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'ruleValid': value.ruleValid === undefined ? undefined : ((value.ruleValid as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
        'readPermission': value.readPermission,
        'shareReadPermission': value.shareReadPermission,
        'extendPermission': value.extendPermission,
        'shareExtendPermission': value.shareExtendPermission,
    };
}

