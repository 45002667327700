/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     AddTemplateFieldPropertiesFromJSONTyped,
     UpdateTemplateFieldPropertiesFromJSONTyped
} from './index';

/**
 * Abstract representation of a Column's DataType - use one of its implementing children like Bool or Date.
 * @export
 * @interface TemplateFieldModification
 */
export interface TemplateFieldModification {
    /**
     * 
     * @type {string}
     * @memberof TemplateFieldModification
     */
    method: string;
}

/**
 * Check if a given object implements the TemplateFieldModification interface.
 */
export function instanceOfTemplateFieldModification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "method" in value;

    return isInstance;
}

export function TemplateFieldModificationFromJSON(json: any): TemplateFieldModification {
    return TemplateFieldModificationFromJSONTyped(json, false);
}

export function TemplateFieldModificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateFieldModification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['method'] === 'AddTemplateFieldProperties') {
            return AddTemplateFieldPropertiesFromJSONTyped(json, true);
        }
        if (json['method'] === 'UpdateTemplateFieldProperties') {
            return UpdateTemplateFieldPropertiesFromJSONTyped(json, true);
        }
    }
    return {
        
        'method': json['method'],
    };
}

export function TemplateFieldModificationToJSON(value?: TemplateFieldModification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': value.method,
    };
}

