// Copyright 2023 Merit International Inc. All Rights Reserved
/* eslint-disable @typescript-eslint/no-require-imports */

export const Images = {
  arrowExpand: require("../assets/images/icons/arrow_expand.png"),
  camera: require("../assets/images/edit-profile.png"),
  cameraOnHover: require("../assets/images/edit-profile-hover.png"),
  defaultProfile: require("../assets/images/default-profile.png"),
  documentWithSearch: require("../assets/images/document_with_search.png"),
  emptyBin: require("../assets/images/emptyBin.png"),
  emptyDatasource: require("../assets/images/emptyDatasource.png"),
  error500: require("../assets/images/error-500.png"),
  folio: require("../assets/images/folio.svg"),
  lock: require("../assets/images/lock.png"),
  merit: require("../assets/images/merit.svg"),
  noDocumentsFound: require("../assets/images/no_documents_found.png"),
  noSearchResults: require("../assets/images/no_search_results.png"),
  partyPopper: require("../assets/images/partyPopper.png"),
  selectFile: require("../assets/images/upload-file-select.png"),
  uploadSuccess: require("../assets/images/upload-file-success.png"),
};
