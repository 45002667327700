/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';

/**
 * 
 * @export
 * @interface All
 */
export interface All extends Permission {
}

/**
 * Check if a given object implements the All interface.
 */
export function instanceOfAll(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AllFromJSON(json: any): All {
    return AllFromJSONTyped(json, false);
}

export function AllFromJSONTyped(json: any, ignoreDiscriminator: boolean): All {
    return json;
}

export function AllToJSON(value?: All | null): any {
    return value;
}

