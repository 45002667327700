/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ServiceInfo,
} from '../models/index';
import {
    ServiceInfoFromJSON,
    ServiceInfoToJSON,
} from '../models/index';

/**
 * 
 */
export class InfrastructureApi extends runtime.BaseAPI {

    /**
     * Check to see if this service is Live.
     */
    async getLiveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/live`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceInfoFromJSON(jsonValue));
    }

    /**
     * Check to see if this service is Live.
     */
    async getLive(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceInfo> {
        const response = await this.getLiveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check to see if this service is Ready. If Ready, this service can accept new requests.
     */
    async getReadyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/ready`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceInfoFromJSON(jsonValue));
    }

    /**
     * Check to see if this service is Ready. If Ready, this service can accept new requests.
     */
    async getReady(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceInfo> {
        const response = await this.getReadyRaw(initOverrides);
        return await response.value();
    }

}
