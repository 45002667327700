/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTemplateRules200ResponseRulesBoolFieldHasValue } from './GetTemplateRules200ResponseRulesBoolFieldHasValue';
import {
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON,
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSONTyped,
    GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON,
} from './GetTemplateRules200ResponseRulesBoolFieldHasValue';

/**
 * 
 * @export
 * @interface GetTemplateRules200ResponseRulesText
 */
export interface GetTemplateRules200ResponseRulesText {
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesText
     */
    fieldHasValue?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesText
     */
    matchesThisString?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
}

/**
 * Check if a given object implements the GetTemplateRules200ResponseRulesText interface.
 */
export function instanceOfGetTemplateRules200ResponseRulesText(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseRulesTextFromJSON(json: any): GetTemplateRules200ResponseRulesText {
    return GetTemplateRules200ResponseRulesTextFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseRulesTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200ResponseRulesText {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldHasValue': !exists(json, 'FieldHasValue') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['FieldHasValue']),
        'matchesThisString': !exists(json, 'MatchesThisString') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['MatchesThisString']),
    };
}

export function GetTemplateRules200ResponseRulesTextToJSON(value?: GetTemplateRules200ResponseRulesText | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FieldHasValue': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.fieldHasValue),
        'MatchesThisString': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.matchesThisString),
    };
}

