// Copyright 2022 Merit International Inc. All Rights Reserved

import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { View } from "react-native";
import React from "react";

export const LoginFailure = () => (
  <PreLoginLayout>
    <View>Login Failed</View>
  </PreLoginLayout>
);
