/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProjectsProjectIDExportJobsJobIDGETSuccessOneOf } from './ProjectsProjectIDExportJobsJobIDGETSuccessOneOf';
import {
    instanceOfProjectsProjectIDExportJobsJobIDGETSuccessOneOf,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOfFromJSON,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOfFromJSONTyped,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOfToJSON,
} from './ProjectsProjectIDExportJobsJobIDGETSuccessOneOf';
import type { ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1 } from './ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1';
import {
    instanceOfProjectsProjectIDExportJobsJobIDGETSuccessOneOf1,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1FromJSON,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1FromJSONTyped,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1ToJSON,
} from './ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1';

/**
 * @type ProjectsProjectIDExportJobsJobIDGETSuccess
 * 
 * @export
 */
export type ProjectsProjectIDExportJobsJobIDGETSuccess = ProjectsProjectIDExportJobsJobIDGETSuccessOneOf | ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1;

export function ProjectsProjectIDExportJobsJobIDGETSuccessFromJSON(json: any): ProjectsProjectIDExportJobsJobIDGETSuccess {
    return ProjectsProjectIDExportJobsJobIDGETSuccessFromJSONTyped(json, false);
}

export function ProjectsProjectIDExportJobsJobIDGETSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportJobsJobIDGETSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ProjectsProjectIDExportJobsJobIDGETSuccessOneOfFromJSONTyped(json, true), ...ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1FromJSONTyped(json, true) };
}

export function ProjectsProjectIDExportJobsJobIDGETSuccessToJSON(value?: ProjectsProjectIDExportJobsJobIDGETSuccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfProjectsProjectIDExportJobsJobIDGETSuccessOneOf(value)) {
        return ProjectsProjectIDExportJobsJobIDGETSuccessOneOfToJSON(value as ProjectsProjectIDExportJobsJobIDGETSuccessOneOf);
    }
    if (instanceOfProjectsProjectIDExportJobsJobIDGETSuccessOneOf1(value)) {
        return ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1ToJSON(value as ProjectsProjectIDExportJobsJobIDGETSuccessOneOf1);
    }

    return {};
}

