/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';

/**
 * An object that represents a failed condition.
 * @export
 * @interface ValidationErrorResult
 */
export interface ValidationErrorResult {
    /**
     * The id of the template field that had the failed condition.
     * @type {string}
     * @memberof ValidationErrorResult
     */
    templateFieldID?: string;
    /**
     * The template id where the condition originates from
     * @type {string}
     * @memberof ValidationErrorResult
     */
    templateID?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorResult
     */
    fieldKindID?: string | null;
    /**
     * 
     * @type {Atom}
     * @memberof ValidationErrorResult
     */
    atom?: Atom;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorResult
     */
    message?: string;
}

/**
 * Check if a given object implements the ValidationErrorResult interface.
 */
export function instanceOfValidationErrorResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationErrorResultFromJSON(json: any): ValidationErrorResult {
    return ValidationErrorResultFromJSONTyped(json, false);
}

export function ValidationErrorResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationErrorResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldID': !exists(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'templateID': !exists(json, 'templateID') ? undefined : json['templateID'],
        'fieldKindID': !exists(json, 'fieldKindID') ? undefined : json['fieldKindID'],
        'atom': !exists(json, 'atom') ? undefined : AtomFromJSON(json['atom']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ValidationErrorResultToJSON(value?: ValidationErrorResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldID': value.templateFieldID,
        'templateID': value.templateID,
        'fieldKindID': value.fieldKindID,
        'atom': AtomToJSON(value.atom),
        'message': value.message,
    };
}

