import { Scopes } from "./scopes";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "@src/configuration";

export const minimumLoginAuthScopesString = ["openid", "profile", "email", "offline_access"].join(
  " "
);

export const orgPortalAuthScopesString = ["openid", "profile", "email"].join(" ");

export const stellarAuthScopesString = [
  "create:agent_entity_link",
  "read:agent_entity_link",
  "create:persona",
  "read:container",
  "transition:container",
  "read:entity",
  "merge:entity",
  Scopes.CreateAgent,
  Scopes.UpdateAgent,
  Scopes.ReadAgent,
  Scopes.DeleteAgent,
  Scopes.CreateAgent,
  Scopes.CreateAgentEntityLink,
  Scopes.ReadAgentEntityLink,
  Scopes.ReadAgentVersion,
  Scopes.ApproveAgentVersion,
  Scopes.ExtendTemplate,
  Scopes.UpdateTemplate,
  Scopes.ReadTemplate,
  Scopes.CreatePersona,
  Scopes.ReadContainer,
  Scopes.TransitionContainer,
  Scopes.DeleteContainer,
  Scopes.ExtendFieldKind,
  Scopes.ReadFieldKind,
  Scopes.UpdateFieldKind,
  Scopes.ExtendPolicy,
  Scopes.ReadPolicy,
  Scopes.UpdatePolicy,
  Scopes.CreatePolicyRequest,
  Scopes.ReadPolicyRequest,
  Scopes.UpdatePolicyRequest,
  Scopes.ReadEntity,
  Scopes.MergeEntity,
  Scopes.CreateDatasource,
  Scopes.UpdateDatasource,
  Scopes.ReadDatasource,
  Scopes.DeleteDatasource,
  Scopes.ReadFileIngestionStatus,
  Scopes.CreateRecord,
  Scopes.CreateIntegration,
  Scopes.UpdateIntegration,
  Scopes.ReadIntegration,
  Scopes.DeleteIntegration,
].join(" ");

export const allAuthScopesString = [minimumLoginAuthScopesString, stellarAuthScopesString].join(
  " "
);

const envConfig = Constants.manifest?.extra as EnvConfig;

export const audience = envConfig.auth.apiIdentifier;
