/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataSourceIngestionStatus } from './DataSourceIngestionStatus';
import {
    DataSourceIngestionStatusFromJSON,
    DataSourceIngestionStatusFromJSONTyped,
    DataSourceIngestionStatusToJSON,
} from './DataSourceIngestionStatus';
import type { IntegrationSchema } from './IntegrationSchema';
import {
    IntegrationSchemaFromJSON,
    IntegrationSchemaFromJSONTyped,
    IntegrationSchemaToJSON,
} from './IntegrationSchema';
import type { IntegrationSetupTestError } from './IntegrationSetupTestError';
import {
    IntegrationSetupTestErrorFromJSON,
    IntegrationSetupTestErrorFromJSONTyped,
    IntegrationSetupTestErrorToJSON,
} from './IntegrationSetupTestError';
import type { SyncFrequency } from './SyncFrequency';
import {
    SyncFrequencyFromJSON,
    SyncFrequencyFromJSONTyped,
    SyncFrequencyToJSON,
} from './SyncFrequency';

/**
 * Properties of an integration to bring data into the Merit Platform
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * 
     * @type {SyncFrequency}
     * @memberof Integration
     */
    syncFrequency?: SyncFrequency;
    /**
     * The optional parameter that defines the sync start time when the sync frequency is already set 
     * or being set by the current request to 1440. It can be specified in one hour increments starting 
     * from 00:00 to 23:00.
     * 
     * @type {string}
     * @memberof Integration
     */
    dailySyncTime?: string;
    /**
     * The current status of an integration. In a connected state, data can be regularly synchronized. In an 
     * incomplete state, connection details may need to be configured or verified, no data has been synchronized. A 
     * broken state means the access of data may be severed and requires reconfiguration.
     * 
     * @type {string}
     * @memberof Integration
     */
    state: IntegrationStateEnum;
    /**
     * A message accompanying the state of this integration, including warnings about broken and incomplete states,
     * as well as information about paused vs live integrations.
     * 
     * @type {string}
     * @memberof Integration
     */
    status: string;
    /**
     * 
     * @type {Array<DataSourceIngestionStatus>}
     * @memberof Integration
     */
    datasourceStatuses?: Array<DataSourceIngestionStatus>;
    /**
     * 
     * @type {IntegrationSchema}
     * @memberof Integration
     */
    schema?: IntegrationSchema;
    /**
     * 
     * @type {Array<IntegrationSetupTestError>}
     * @memberof Integration
     */
    setupTestErrors?: Array<IntegrationSetupTestError>;
    /**
     * The database this integration writes data to. Only used for integrations from manually created connectors
     * 
     * @type {string}
     * @memberof Integration
     */
    database?: string | null;
    /**
     * The id of this object.
     * @type {string}
     * @memberof Integration
     */
    id: string;
    /**
     * The name of the integration
     * @type {string}
     * @memberof Integration
     */
    name: string;
    /**
     * The name of the service type this integration will connect with, such as 'google_drive' or 'google_sheets', 'airtable', etc. See https://fivetran.com/docs/rest-api/connectors/config for the list of services available to connect with.
     * @type {string}
     * @memberof Integration
     */
    service: string;
    /**
     * The description of the integration
     * @type {string}
     * @memberof Integration
     */
    description?: string;
}


/**
 * @export
 */
export const IntegrationStateEnum = {
    Connected: 'connected',
    Broken: 'broken',
    Incomplete: 'incomplete'
} as const;
export type IntegrationStateEnum = typeof IntegrationStateEnum[keyof typeof IntegrationStateEnum];


/**
 * Check if a given object implements the Integration interface.
 */
export function instanceOfIntegration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "service" in value;

    return isInstance;
}

export function IntegrationFromJSON(json: any): Integration {
    return IntegrationFromJSONTyped(json, false);
}

export function IntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Integration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syncFrequency': !exists(json, 'sync_frequency') ? undefined : SyncFrequencyFromJSON(json['sync_frequency']),
        'dailySyncTime': !exists(json, 'daily_sync_time') ? undefined : json['daily_sync_time'],
        'state': json['state'],
        'status': json['status'],
        'datasourceStatuses': !exists(json, 'datasource_statuses') ? undefined : ((json['datasource_statuses'] as Array<any>).map(DataSourceIngestionStatusFromJSON)),
        'schema': !exists(json, 'schema') ? undefined : IntegrationSchemaFromJSON(json['schema']),
        'setupTestErrors': !exists(json, 'setupTestErrors') ? undefined : ((json['setupTestErrors'] as Array<any>).map(IntegrationSetupTestErrorFromJSON)),
        'database': !exists(json, 'database') ? undefined : json['database'],
        'id': json['id'],
        'name': json['name'],
        'service': json['service'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function IntegrationToJSON(value?: Integration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sync_frequency': SyncFrequencyToJSON(value.syncFrequency),
        'daily_sync_time': value.dailySyncTime,
        'state': value.state,
        'status': value.status,
        'datasource_statuses': value.datasourceStatuses === undefined ? undefined : ((value.datasourceStatuses as Array<any>).map(DataSourceIngestionStatusToJSON)),
        'schema': IntegrationSchemaToJSON(value.schema),
        'setupTestErrors': value.setupTestErrors === undefined ? undefined : ((value.setupTestErrors as Array<any>).map(IntegrationSetupTestErrorToJSON)),
        'database': value.database,
        'id': value.id,
        'name': value.name,
        'service': value.service,
        'description': value.description,
    };
}

