/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Current status of an uploaded file
 * @export
 * @interface UploadStatus
 */
export interface UploadStatus {
    /**
     * 
     * @type {string}
     * @memberof UploadStatus
     */
    status: UploadStatusStatusEnum;
    /**
     * An error message specifying if the file was malformed
     * @type {string}
     * @memberof UploadStatus
     */
    error?: string;
}


/**
 * @export
 */
export const UploadStatusStatusEnum = {
    WaitingForUpload: 'waiting for upload',
    MalwareScanIncomplete: 'malware scan incomplete',
    Processing: 'processing',
    Finished: 'finished'
} as const;
export type UploadStatusStatusEnum = typeof UploadStatusStatusEnum[keyof typeof UploadStatusStatusEnum];


/**
 * Check if a given object implements the UploadStatus interface.
 */
export function instanceOfUploadStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function UploadStatusFromJSON(json: any): UploadStatus {
    return UploadStatusFromJSONTyped(json, false);
}

export function UploadStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function UploadStatusToJSON(value?: UploadStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'error': value.error,
    };
}

