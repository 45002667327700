/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetConfiguration200Response
 */
export interface GetConfiguration200Response {
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    solUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    solName: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    orgRegistrationDatasourceUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    adminRegistrationDatasourceUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    orgAdminDatasourceUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    adminMeritTemplateUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    accountFolioTemplateUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    accountMeritTemplateUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    baseMeritTemplateUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    baseFolioTemplateUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    basePersonaTemplateUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    basePolicyUUID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    launchDarklyClientID: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfiguration200Response
     */
    meritCheckinURL: string;
}

/**
 * Check if a given object implements the GetConfiguration200Response interface.
 */
export function instanceOfGetConfiguration200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "solUUID" in value;
    isInstance = isInstance && "solName" in value;
    isInstance = isInstance && "orgRegistrationDatasourceUUID" in value;
    isInstance = isInstance && "adminRegistrationDatasourceUUID" in value;
    isInstance = isInstance && "orgAdminDatasourceUUID" in value;
    isInstance = isInstance && "adminMeritTemplateUUID" in value;
    isInstance = isInstance && "accountFolioTemplateUUID" in value;
    isInstance = isInstance && "accountMeritTemplateUUID" in value;
    isInstance = isInstance && "baseMeritTemplateUUID" in value;
    isInstance = isInstance && "baseFolioTemplateUUID" in value;
    isInstance = isInstance && "basePersonaTemplateUUID" in value;
    isInstance = isInstance && "basePolicyUUID" in value;
    isInstance = isInstance && "launchDarklyClientID" in value;
    isInstance = isInstance && "meritCheckinURL" in value;

    return isInstance;
}

export function GetConfiguration200ResponseFromJSON(json: any): GetConfiguration200Response {
    return GetConfiguration200ResponseFromJSONTyped(json, false);
}

export function GetConfiguration200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConfiguration200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'solUUID': json['solUUID'],
        'solName': json['solName'],
        'orgRegistrationDatasourceUUID': json['orgRegistrationDatasourceUUID'],
        'adminRegistrationDatasourceUUID': json['adminRegistrationDatasourceUUID'],
        'orgAdminDatasourceUUID': json['orgAdminDatasourceUUID'],
        'adminMeritTemplateUUID': json['adminMeritTemplateUUID'],
        'accountFolioTemplateUUID': json['accountFolioTemplateUUID'],
        'accountMeritTemplateUUID': json['accountMeritTemplateUUID'],
        'baseMeritTemplateUUID': json['baseMeritTemplateUUID'],
        'baseFolioTemplateUUID': json['baseFolioTemplateUUID'],
        'basePersonaTemplateUUID': json['basePersonaTemplateUUID'],
        'basePolicyUUID': json['basePolicyUUID'],
        'launchDarklyClientID': json['launchDarklyClientID'],
        'meritCheckinURL': json['meritCheckinURL'],
    };
}

export function GetConfiguration200ResponseToJSON(value?: GetConfiguration200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'solUUID': value.solUUID,
        'solName': value.solName,
        'orgRegistrationDatasourceUUID': value.orgRegistrationDatasourceUUID,
        'adminRegistrationDatasourceUUID': value.adminRegistrationDatasourceUUID,
        'orgAdminDatasourceUUID': value.orgAdminDatasourceUUID,
        'adminMeritTemplateUUID': value.adminMeritTemplateUUID,
        'accountFolioTemplateUUID': value.accountFolioTemplateUUID,
        'accountMeritTemplateUUID': value.accountMeritTemplateUUID,
        'baseMeritTemplateUUID': value.baseMeritTemplateUUID,
        'baseFolioTemplateUUID': value.baseFolioTemplateUUID,
        'basePersonaTemplateUUID': value.basePersonaTemplateUUID,
        'basePolicyUUID': value.basePolicyUUID,
        'launchDarklyClientID': value.launchDarklyClientID,
        'meritCheckinURL': value.meritCheckinURL,
    };
}

