// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button } from "@merit/frontend-components";
import { Datagrid, DatagridCollapsableSection, EllipsisText } from "../../components";
import { Helpers } from "@merit/frontend-utils";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useCallback, useEffect, useState } from "react";
import { useLinkedOrgs } from "../../api/useLinkedOrgs";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type {
  GetPolicies200Response,
  GetPolicies200ResponsePoliciesInner,
} from "../../gen/org-portal";

const { Some } = Helpers;
const SCREEN_NAME = "Policies";

type Props = {
  readonly onPressView: (templateID: string) => void;
};

export const SharedPolicies = ({ onPressView }: Props) => {
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { configuration } = useLoadedConfigurationState();
  const { linkedOrgsMap } = useLinkedOrgs(api, selectedOrgId);
  const { policiesLimit } = useAppConstantsStore();
  const [orgIDToExpand, setOrgIDToExpand] = useState<string>();

  const [sharedOrgs, SetSharedOrgs] = useState<readonly GetPolicies200Response[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSharedPolicies = useCallback(async () => {
    if (!(selectedOrgId in linkedOrgsMap)) {
      return;
    }

    setIsLoading(true);

    const orgs = await Promise.all(
      linkedOrgsMap[selectedOrgId].map(linkedOrg =>
        api
          .getPolicies({
            limit: policiesLimit,
            orgID: selectedOrgId,
            ownerID: linkedOrg.orgID,
          })
          .then(policiesResponse => ({
            orgID: policiesResponse.orgID,
            ownerOrgID:
              linkedOrg.orgID === configuration.solUUID
                ? configuration.solName
                : linkedOrg.orgInfo.name ?? linkedOrg.orgID,
            policies: policiesResponse.policies,
          }))
      )
    );

    setIsLoading(false);
    SetSharedOrgs(orgs);
  }, [selectedOrgId, configuration, linkedOrgsMap, api, policiesLimit]);

  useEffect(() => {
    getSharedPolicies();
  }, [getSharedPolicies]);

  const sharedColumns: readonly DatagridColumn<GetPolicies200ResponsePoliciesInner>[] = [
    {
      key: "organizationName",
      label: "Organization name",
      size: 200,
    },
    {
      key: "name",
      label: "Policy name",
      renderer: (item, testProps) => <EllipsisText testProps={testProps} text={item.name} />,
      size: "flex",
    },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={Some(description) && description.length > 0 ? description : "--"}
        />
      ),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      renderer: (item, testProps) => (
        <Button
          onPress={() => {
            onPressView(item.id);
          }}
          size="small"
          testProps={
            Some(testProps)
              ? {
                  ...testProps,
                  elementName: `${testProps.elementName}ViewButton`,
                }
              : testProps
          }
          text="View"
          type="secondary"
        />
      ),

      size: 128,
    },
  ];

  return (
    <Datagrid
      columns={sharedColumns}
      loading={isLoading}
      testProps={{
        elementName: "sharedPoliciesListView",
        screenName: SCREEN_NAME,
      }}
    >
      {sharedOrgs.map(
        sharedOrg =>
          Some(sharedOrg.policies) &&
          sharedOrg.policies.length > 0 && (
            <DatagridCollapsableSection
              columns={sharedColumns}
              data={sharedOrg.policies}
              isExpanded={orgIDToExpand === sharedOrg.ownerOrgID}
              key={sharedOrg.ownerOrgID}
              noDataMessage="No Policies have been shared from this organization"
              onToggle={() => {
                setOrgIDToExpand(prevState =>
                  prevState === sharedOrg.ownerOrgID ? undefined : sharedOrg.ownerOrgID
                );
              }}
              testProps={{
                elementName: "sharedPoliciesListView",
                screenName: SCREEN_NAME,
              }}
              testPropsElementIdKey="id"
              testPropsRowId={sharedOrg.orgID}
              title={sharedOrg.ownerOrgID ?? ""}
            />
          )
      )}
    </Datagrid>
  );
};
