/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Column DataType corresponding to DateTime data.
 * @export
 * @interface DateTime
 */
export interface DateTime extends DataType {
    /**
     * The string describing the format the underlying datetime will be in. Formats may optionally include
     * time zone information. If the format includes time zone information, data ingested into the data source
     * must either specify the time zone with a UTC offset (e.g. +08:00), or with an IANA tz database
     * entry (e.g. America/Los_Angeles). Time zone abbreviations, such as PST, are ambiguous and will result
     * in an error on data ingestion.
     * 
     * @type {string}
     * @memberof DateTime
     */
    format: string;
}

/**
 * Check if a given object implements the DateTime interface.
 */
export function instanceOfDateTime(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "format" in value;

    return isInstance;
}

export function DateTimeFromJSON(json: any): DateTime {
    return DateTimeFromJSONTyped(json, false);
}

export function DateTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...DataTypeFromJSONTyped(json, ignoreDiscriminator),
        'format': json['format'],
    };
}

export function DateTimeToJSON(value?: DateTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DataTypeToJSON(value),
        'format': value.format,
    };
}

