// Copyright 2024 Merit International Inc. All Rights Reserved

import { CreatePolicyForm } from "./CreatePolicyForm";
import { Formik } from "formik";
import type { FC } from "react";
import type { Values } from "../types";

const initialValues = {
  policyName: "",
  policyPermissions: {
    othersUse: "yes",
    othersView: "yes",
  },
  ruleCombinationType: "all",
  templateRules: [],
} as Values;

export const CreatePolicyFormikWrapper: FC = () => (
  <Formik<Values>
    initialValues={initialValues}
    onReset={(values, helpers) => {
      helpers.resetForm({ values: initialValues });
    }}
    onSubmit={values => {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(values, null, 2));
    }}
  >
    <CreatePolicyForm />
  </Formik>
);
