// Copyright 2024 Merit International Inc. All Rights Reserved

import { Handle } from "reactflow";
import { useState } from "react";
import { useTheme } from "@merit/frontend-components";
import type { HandleProps } from "reactflow";

export const FieldHandle = (
  props: HandleProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, "id"> &
    React.RefAttributes<HTMLDivElement>
) => {
  const [isHover, setIsHover] = useState(false);
  const { theme } = useTheme();

  return (
    <Handle
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      style={{
        backgroundColor: theme.colors.brand.turquiose,
        ...(isHover ? { height: 14, width: 14 } : { height: 8, width: 8 }),
      }}
      {...props}
    />
  );
};
