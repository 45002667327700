/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Record
 */
export interface Record {
    /**
     * The record data of column header keyed values as strings
     * @type {{ [key: string]: string; }}
     * @memberof Record
     */
    data: { [key: string]: string; };
}

/**
 * Check if a given object implements the Record interface.
 */
export function instanceOfRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function RecordFromJSON(json: any): Record {
    return RecordFromJSONTyped(json, false);
}

export function RecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): Record {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

export function RecordToJSON(value?: Record | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
    };
}

