/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationTableColumn } from './IntegrationTableColumn';
import {
    IntegrationTableColumnFromJSON,
    IntegrationTableColumnFromJSONTyped,
    IntegrationTableColumnToJSON,
} from './IntegrationTableColumn';

/**
 * A table of an integration, specifying its list of columns available
 * @export
 * @interface IntegrationTable
 */
export interface IntegrationTable {
    /**
     * The name of the table
     * @type {string}
     * @memberof IntegrationTable
     */
    name?: string;
    /**
     * 
     * @type {Array<IntegrationTableColumn>}
     * @memberof IntegrationTable
     */
    columns?: Array<IntegrationTableColumn>;
}

/**
 * Check if a given object implements the IntegrationTable interface.
 */
export function instanceOfIntegrationTable(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntegrationTableFromJSON(json: any): IntegrationTable {
    return IntegrationTableFromJSONTyped(json, false);
}

export function IntegrationTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'columns': !exists(json, 'columns') ? undefined : ((json['columns'] as Array<any>).map(IntegrationTableColumnFromJSON)),
    };
}

export function IntegrationTableToJSON(value?: IntegrationTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'columns': value.columns === undefined ? undefined : ((value.columns as Array<any>).map(IntegrationTableColumnToJSON)),
    };
}

