/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListDatasources200ResponseDatasourcesInnerSchema
 */
export interface ListDatasources200ResponseDatasourcesInnerSchema {
    /**
     * 
     * @type {string}
     * @memberof ListDatasources200ResponseDatasourcesInnerSchema
     */
    delimiter?: string;
}

/**
 * Check if a given object implements the ListDatasources200ResponseDatasourcesInnerSchema interface.
 */
export function instanceOfListDatasources200ResponseDatasourcesInnerSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListDatasources200ResponseDatasourcesInnerSchemaFromJSON(json: any): ListDatasources200ResponseDatasourcesInnerSchema {
    return ListDatasources200ResponseDatasourcesInnerSchemaFromJSONTyped(json, false);
}

export function ListDatasources200ResponseDatasourcesInnerSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDatasources200ResponseDatasourcesInnerSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delimiter': !exists(json, 'delimiter') ? undefined : json['delimiter'],
    };
}

export function ListDatasources200ResponseDatasourcesInnerSchemaToJSON(value?: ListDatasources200ResponseDatasourcesInnerSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delimiter': value.delimiter,
    };
}

