/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';

/**
 * 
 * @export
 * @interface ResponseRules
 */
export interface ResponseRules {
    /**
     * A Rule as added to response objects (always displayed on response, even when empty)
     * @type {Array<Atom>}
     * @memberof ResponseRules
     */
    own?: Array<Atom>;
    /**
     * 
     * @type {Array<Array<Atom>>}
     * @memberof ResponseRules
     */
    inherited?: Array<Array<Atom>>;
}

/**
 * Check if a given object implements the ResponseRules interface.
 */
export function instanceOfResponseRules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseRulesFromJSON(json: any): ResponseRules {
    return ResponseRulesFromJSONTyped(json, false);
}

export function ResponseRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'own': !exists(json, 'own') ? undefined : ((json['own'] as Array<any>).map(AtomFromJSON)),
        'inherited': !exists(json, 'inherited') ? undefined : json['inherited'],
    };
}

export function ResponseRulesToJSON(value?: ResponseRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'own': value.own === undefined ? undefined : ((value.own as Array<any>).map(AtomToJSON)),
        'inherited': value.inherited,
    };
}

