/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A permission granted to an entity
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted
 */
export interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted {
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted
     */
    grantedToName: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted
     */
    action: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum;
}


/**
 * @export
 */
export const GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum = {
    Read: 'read',
    Extend: 'extend',
    ReadData: 'read_data'
} as const;
export type GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum = typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum[keyof typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum];


/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "grantedToName" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted {
    return GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grantedToName': json['grantedToName'],
        'action': json['action'],
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grantedToName': value.grantedToName,
        'action': value.action,
    };
}

