// Copyright 2024 Merit International Inc. All Rights Reserved

import { Heading } from "@merit/frontend-components";
import type { Node } from "reactflow";

export const LabelNode = ({ data }: Omit<Node, "position">) => (
  <div className="nodrag">
    <Heading level="3">{data.label}</Heading>
  </div>
);
