// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Modal, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { View } from "react-native";
import capitalize from "lodash.capitalize";
import type { IconName } from "@merit/frontend-components";
import type { OPTestProps } from "../../../src/types/TestProps";

type Props = {
  readonly onClose: () => void;
  readonly text: string;
  readonly title: string;
  readonly titleIconName?: IconName;
  readonly buttonText?:
    | "approve"
    | "ok"
    | "publish"
    | "reissue"
    | "reject"
    | "remove"
    | "revoke"
    | "save"
    | "unpublish";
  readonly onOk: () => void;
  readonly testProps?: OPTestProps;
};

const { Some } = Helpers;

export const ConfirmationModal = ({
  buttonText,
  onClose,
  onOk,
  testProps,
  text,
  title,
  titleIconName,
}: Props) => {
  const { theme } = useTheme();

  return (
    <Modal
      maxWidth={520}
      onClose={onClose}
      testProps={testProps}
      title={title}
      titleIconName={titleIconName}
    >
      <View style={{ maxWidth: Some(titleIconName) ? 456 : 440 }}>
        <Body
          size="l"
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `${testProps.elementName}ModalBody` }
              : testProps
          }
        >
          {text}
        </Body>

        <VerticalSpacer size={theme.spacing.xxl} />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onPress={onClose}
            testProps={
              Some(testProps)
                ? { ...testProps, elementName: `${testProps.elementName}ModalCancelButton` }
                : testProps
            }
            text="Cancel"
            type="secondary"
          />

          <HorizontalSpacer size={theme.spacing.s} />

          <Button
            onPress={onOk}
            testProps={
              Some(testProps)
                ? { ...testProps, elementName: `${testProps.elementName}ModalOkButton` }
                : testProps
            }
            text={Some(buttonText) ? capitalize(buttonText) : "Ok"}
            type={buttonText === "reject" || buttonText === "remove" ? "destructive" : "primary"}
          />
        </View>
      </View>
    </Modal>
  );
};
