// Copyright 2024 Merit International Inc. All Rights Reserved

import { MenuLink } from "./MenuLink";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";

export const VerifyAndManageMenu = () => {
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();

  return (
    <View
      style={{
        backgroundColor: theme.colors.background.white,
        borderRadius: 4,
        paddingHorizontal: 24,
        paddingVertical: 16,
        width: 384,
      }}
    >
      <MenuLink
        onPress={() => {
          navigation.navigate("Records");
        }}
        size="large"
        subtext="Search and manage merits"
        text="Records"
      />

      <View style={{ backgroundColor: theme.colors.border.subdued, height: 1, width: 104 }} />

      <MenuLink
        onPress={() => {
          navigation.navigate("Approvals");
        }}
        size="small"
        subtext="Manage your approval queue"
        text="Approvals"
      />
    </View>
  );
};
