// Copyright 2024 Merit International Inc. All Rights Reserved

import { useFormikContext } from "formik";
import type { Values } from "../types";

type UseNumRules = () => number;

export const useNumRules: UseNumRules = () => {
  const ctx = useFormikContext<Values>();

  return ctx.values.templateRules
    .map(tr => 1 + tr.fieldRules.length)
    .reduce((sum, item) => sum + item);
};
