/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Create credential response body
 * @export
 * @interface CreateCredential201Response
 */
export interface CreateCredential201Response {
    /**
     * object id
     * @type {string}
     * @memberof CreateCredential201Response
     */
    id: string;
    /**
     * object creation datetime
     * @type {string}
     * @memberof CreateCredential201Response
     */
    createdAt: string;
    /**
     * object update datetime
     * @type {string}
     * @memberof CreateCredential201Response
     */
    updatedAt: string;
    /**
     * The container template id to which the credential should be attached
     * @type {string}
     * @memberof CreateCredential201Response
     */
    targetContainerTemplateID: string;
    /**
     * The ID of this credential as known to DesignHuddle
     * @type {string}
     * @memberof CreateCredential201Response
     */
    designHuddleProjectID: string;
    /**
     * A description of the credential
     * @type {string}
     * @memberof CreateCredential201Response
     */
    description?: string;
    /**
     * The credential name
     * @type {string}
     * @memberof CreateCredential201Response
     */
    name: string;
    /**
     * Format for date fields on a credential
     * @type {string}
     * @memberof CreateCredential201Response
     */
    dateFormat?: CreateCredential201ResponseDateFormatEnum;
    /**
     * Whether the credential is published
     * @type {boolean}
     * @memberof CreateCredential201Response
     */
    isPublished: boolean;
}


/**
 * @export
 */
export const CreateCredential201ResponseDateFormatEnum = {
    AlphabeticFull: 'AlphabeticFull',
    AlphabeticShort: 'AlphabeticShort',
    NumericDash: 'NumericDash',
    NumericSlash: 'NumericSlash'
} as const;
export type CreateCredential201ResponseDateFormatEnum = typeof CreateCredential201ResponseDateFormatEnum[keyof typeof CreateCredential201ResponseDateFormatEnum];


/**
 * Check if a given object implements the CreateCredential201Response interface.
 */
export function instanceOfCreateCredential201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "targetContainerTemplateID" in value;
    isInstance = isInstance && "designHuddleProjectID" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isPublished" in value;

    return isInstance;
}

export function CreateCredential201ResponseFromJSON(json: any): CreateCredential201Response {
    return CreateCredential201ResponseFromJSONTyped(json, false);
}

export function CreateCredential201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCredential201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'targetContainerTemplateID': json['targetContainerTemplateID'],
        'designHuddleProjectID': json['designHuddleProjectID'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'dateFormat': !exists(json, 'dateFormat') ? undefined : json['dateFormat'],
        'isPublished': json['isPublished'],
    };
}

export function CreateCredential201ResponseToJSON(value?: CreateCredential201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'targetContainerTemplateID': value.targetContainerTemplateID,
        'designHuddleProjectID': value.designHuddleProjectID,
        'description': value.description,
        'name': value.name,
        'dateFormat': value.dateFormat,
        'isPublished': value.isPublished,
    };
}

