/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin } from './ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin';
import {
    ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginFromJSON,
    ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginFromJSONTyped,
    ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginToJSON,
} from './ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin';

/**
 * 
 * @export
 * @interface ProjectsProjectIDExportPOSTRequestOneOf1Bleed
 */
export interface ProjectsProjectIDExportPOSTRequestOneOf1Bleed {
    /**
     * 
     * @type {ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1Bleed
     */
    margin?: ProjectsProjectIDExportPOSTRequestOneOf1BleedMargin;
    /**
     * By default the PDF will include double the margin supplied with crop marks indicating the actual margin.
     * @type {boolean}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf1Bleed
     */
    noOuterMargin?: boolean;
}

/**
 * Check if a given object implements the ProjectsProjectIDExportPOSTRequestOneOf1Bleed interface.
 */
export function instanceOfProjectsProjectIDExportPOSTRequestOneOf1Bleed(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectsProjectIDExportPOSTRequestOneOf1BleedFromJSON(json: any): ProjectsProjectIDExportPOSTRequestOneOf1Bleed {
    return ProjectsProjectIDExportPOSTRequestOneOf1BleedFromJSONTyped(json, false);
}

export function ProjectsProjectIDExportPOSTRequestOneOf1BleedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportPOSTRequestOneOf1Bleed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'margin': !exists(json, 'margin') ? undefined : ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginFromJSON(json['margin']),
        'noOuterMargin': !exists(json, 'no_outer_margin') ? undefined : json['no_outer_margin'],
    };
}

export function ProjectsProjectIDExportPOSTRequestOneOf1BleedToJSON(value?: ProjectsProjectIDExportPOSTRequestOneOf1Bleed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'margin': ProjectsProjectIDExportPOSTRequestOneOf1BleedMarginToJSON(value.margin),
        'no_outer_margin': value.noOuterMargin,
    };
}

