import { Body, Button } from "@merit/frontend-components";
import { Datagrid, DatagridBody, EllipsisText, HorizontalSpacer } from "../../../components";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, View } from "react-native";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import React from "react";
import type { DatagridColumn } from "../../../components/Datagrid/types";
import type {
  GetAgents200ResponseAgentsInner,
  GetAllAgentsVersions200ResponseInner,
} from "../../../gen/org-portal";

const { None, Some } = Helpers;
const SCREEN_NAME = "LinkedApps";

type Props = {
  readonly appsList:
    | readonly GetAgents200ResponseAgentsInner[]
    | readonly GetAllAgentsVersions200ResponseInner[];
  readonly isLoading?: boolean;
  readonly onPressApprove?: (agentID: string, versionID: string) => void;
  readonly onPressView: (policyID: string) => void;
};

export const AppsTable = ({ appsList, isLoading, onPressApprove, onPressView }: Props) => {
  const { configuration } = useLoadedConfigurationState();

  const getOrgName = (orgId?: string, orgName?: string) => {
    if (None(orgName)) {
      return "--";
    }

    if (configuration.solUUID === orgId) {
      return configuration.solName;
    }

    return orgName;
  };

  const columns: readonly DatagridColumn<
    GetAgents200ResponseAgentsInner | GetAllAgentsVersions200ResponseInner
  >[] = [
    {
      key: "name",
      label: "Name",
      renderer: (app, testProps) => {
        const appName = "versions" in app ? app.versions[0].name : app.name;

        return <EllipsisText testProps={testProps} text={appName} />;
      },
      size: "flex",
    },
    {
      key: "description",
      label: "Description",
      renderer: (app, testProps) => {
        const description = "versions" in app ? app.versions[0].description : app.description;

        return (
          <EllipsisText
            testProps={testProps}
            text={Some(description) && description.length > 0 ? description : "--"}
          />
        );
      },
      size: "flex",
    },
    {
      key: "orgName",
      label: "Owner",
      renderer: (app, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getOrgName("versions" in app ? app.owner : app.org, app.orgName)}
        />
      ),
      size: "flex",
    },
    {
      key: "state",
      label: "State",
      renderer: (item, testProps) => (
        <Body testProps={testProps}>
          {item.state === "pending_approval" ? "Pending approval" : "Live"}
        </Body>
      ),
      size: 160,
    },
    {
      key: "status",
      label: "Status",
      renderer: (item, testProps) => (
        <Body capitalize testProps={testProps}>
          {item.status}
        </Body>
      ),
      size: 120,
    },
    {
      key: "actions",
      label: "Actions",
      renderer: (app, testProps) => (
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: 80 }}>
            <Button
              onPress={() => {
                onPressView(app.id);
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}ViewButton`,
                    }
                  : testProps
              }
              text="View"
              type="secondary"
            />
          </View>
          {Some(onPressApprove) && (
            <>
              <HorizontalSpacer />
              <View style={{ width: 120 }}>
                <Button
                  iconLeft="checkmarkSmallAction"
                  onPress={() => {
                    if ("versions" in app) {
                      onPressApprove(app.id, app.versions[0].id);
                    }
                  }}
                  size="small"
                  testProps={
                    Some(testProps)
                      ? {
                          ...testProps,
                          elementName: `${testProps.elementName}ApproveButton`,
                        }
                      : testProps
                  }
                  text="Approve"
                />
              </View>
            </>
          )}
        </View>
      ),
      size: Some(onPressApprove) ? 240 : 120,
    },
  ];

  return (
    <ScrollView>
      <Datagrid
        columns={columns}
        loading={isLoading}
        testProps={{
          elementName: "linkedAppsListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={appsList}
          getItemKey={g => g.id}
          testProps={{
            elementName: "linkedAppsListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="id"
        />
      </Datagrid>
    </ScrollView>
  );
};
