/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationCursor
 */
export interface PaginationCursor {
    /**
     * 
     * @type {string}
     * @memberof PaginationCursor
     */
    endingBefore?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginationCursor
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginationCursor
     */
    sortBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationCursor
     */
    startingAfter?: string;
}

/**
 * Check if a given object implements the PaginationCursor interface.
 */
export function instanceOfPaginationCursor(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaginationCursorFromJSON(json: any): PaginationCursor {
    return PaginationCursorFromJSONTyped(json, false);
}

export function PaginationCursorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationCursor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endingBefore': !exists(json, 'ending_before') ? undefined : json['ending_before'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'sortBy': !exists(json, 'sort_by') ? undefined : json['sort_by'],
        'startingAfter': !exists(json, 'starting_after') ? undefined : json['starting_after'],
    };
}

export function PaginationCursorToJSON(value?: PaginationCursor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ending_before': value.endingBefore,
        'limit': value.limit,
        'sort_by': value.sortBy,
        'starting_after': value.startingAfter,
    };
}

