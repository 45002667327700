// Copyright 2023 Merit International Inc. All Rights Reserved

import { ErrorScreen } from "../screens/ErrorScreen/ErrorScreen";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

type Props = {
  readonly children: JSX.Element;
};

export const ErrorBoundary = ({ children }: Props) => (
  <ReactErrorBoundary FallbackComponent={ErrorScreen}>{children}</ReactErrorBoundary>
);
