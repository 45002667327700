/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  SignedEntityID,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    SignedEntityIDFromJSON,
    SignedEntityIDToJSON,
} from '../models/index';

export interface GetSignedEntityIDRequest {
    authorization?: string;
}

/**
 * 
 */
export class SignedEntityApi extends runtime.BaseAPI {

    /**
     * `read:entity`  Get a signed token containing containing the authenticated entity\'s ID 
     * Get Signed Token
     */
    async getSignedEntityIDRaw(requestParameters: GetSignedEntityIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignedEntityID>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignedEntityIDFromJSON(jsonValue));
    }

    /**
     * `read:entity`  Get a signed token containing containing the authenticated entity\'s ID 
     * Get Signed Token
     */
    async getSignedEntityID(requestParameters: GetSignedEntityIDRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignedEntityID> {
        const response = await this.getSignedEntityIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
