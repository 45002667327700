/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Error encountered.
 * @export
 * @interface OrgPortalError
 */
export interface OrgPortalError {
    /**
     * Error code.
     * @type {number}
     * @memberof OrgPortalError
     */
    code: number;
    /**
     * Error explanation.
     * @type {Array<string>}
     * @memberof OrgPortalError
     */
    errors: Array<string>;
}

/**
 * Check if a given object implements the OrgPortalError interface.
 */
export function instanceOfOrgPortalError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "errors" in value;

    return isInstance;
}

export function OrgPortalErrorFromJSON(json: any): OrgPortalError {
    return OrgPortalErrorFromJSONTyped(json, false);
}

export function OrgPortalErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgPortalError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'errors': json['errors'],
    };
}

export function OrgPortalErrorToJSON(value?: OrgPortalError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'errors': value.errors,
    };
}

