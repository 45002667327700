// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../Spacer";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import React, { useRef } from "react";
import type { IconName } from "@merit/frontend-components";
import type { OPTestProps } from "../../../src/types/TestProps";

const generateTestIdProps = Helpers.generateTestIdProps;

type Props = {
  readonly iconName: IconName;
  readonly isActive: boolean;
  readonly onPress: () => void;
  readonly testProps?: OPTestProps;
  readonly title: string;
};

export const NavPopoverLink = ({ iconName, isActive, onPress, testProps, title }: Props) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    active: {
      backgroundColor: theme.colors.action.pressed,
    },
    container: {
      alignItems: "center",
      flexDirection: "row",
      paddingHorizontal: theme.spacing.m,
      paddingVertical: 6,
    },
    hovered: {
      backgroundColor: theme.colors.surface.hovered,
    },
  });

  return (
    <TouchableHighlight onPress={onPress} {...generateTestIdProps(testProps)}>
      <View
        key={title}
        ref={ref}
        style={[styles.container, isHovered && styles.hovered, isActive && styles.active]}
      >
        <Icon alt="" name={iconName} />
        <HorizontalSpacer />
        <Body>{title}</Body>
      </View>
    </TouchableHighlight>
  );
};
