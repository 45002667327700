/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation
 */
export interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation {
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation
     */
    own: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>;
    /**
     * 
     * @type {Array<Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation
     */
    inherited: Array<Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>>;
}

/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "own" in value;
    isInstance = isInstance && "inherited" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation {
    return GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'own': ((json['own'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
        'inherited': json['inherited'],
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidationToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFieldValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'own': ((value.own as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
        'inherited': value.inherited,
    };
}

