/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPolicies200ResponsePoliciesInnerResponseFormulasOwn } from './GetPolicies200ResponsePoliciesInnerResponseFormulasOwn';
import {
    GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSON,
    GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSONTyped,
    GetPolicies200ResponsePoliciesInnerResponseFormulasOwnToJSON,
} from './GetPolicies200ResponsePoliciesInnerResponseFormulasOwn';

/**
 * 
 * @export
 * @interface GetPolicies200ResponsePoliciesInnerResponseFormulas
 */
export interface GetPolicies200ResponsePoliciesInnerResponseFormulas {
    /**
     * 
     * @type {GetPolicies200ResponsePoliciesInnerResponseFormulasOwn}
     * @memberof GetPolicies200ResponsePoliciesInnerResponseFormulas
     */
    own?: GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
    /**
     * 
     * @type {Array<GetPolicies200ResponsePoliciesInnerResponseFormulasOwn>}
     * @memberof GetPolicies200ResponsePoliciesInnerResponseFormulas
     */
    inherited?: Array<GetPolicies200ResponsePoliciesInnerResponseFormulasOwn>;
}

/**
 * Check if a given object implements the GetPolicies200ResponsePoliciesInnerResponseFormulas interface.
 */
export function instanceOfGetPolicies200ResponsePoliciesInnerResponseFormulas(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetPolicies200ResponsePoliciesInnerResponseFormulasFromJSON(json: any): GetPolicies200ResponsePoliciesInnerResponseFormulas {
    return GetPolicies200ResponsePoliciesInnerResponseFormulasFromJSONTyped(json, false);
}

export function GetPolicies200ResponsePoliciesInnerResponseFormulasFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPolicies200ResponsePoliciesInnerResponseFormulas {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'own': !exists(json, 'own') ? undefined : GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSON(json['own']),
        'inherited': !exists(json, 'inherited') ? undefined : ((json['inherited'] as Array<any>).map(GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSON)),
    };
}

export function GetPolicies200ResponsePoliciesInnerResponseFormulasToJSON(value?: GetPolicies200ResponsePoliciesInnerResponseFormulas | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'own': GetPolicies200ResponsePoliciesInnerResponseFormulasOwnToJSON(value.own),
        'inherited': value.inherited === undefined ? undefined : ((value.inherited as Array<any>).map(GetPolicies200ResponsePoliciesInnerResponseFormulasOwnToJSON)),
    };
}

