/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationTable } from './IntegrationTable';
import {
    IntegrationTableFromJSON,
    IntegrationTableFromJSONTyped,
    IntegrationTableToJSON,
} from './IntegrationTable';

/**
 * The schema of an Integration
 * @export
 * @interface IntegrationSchema
 */
export interface IntegrationSchema {
    /**
     * The name of the schema
     * @type {string}
     * @memberof IntegrationSchema
     */
    name?: string;
    /**
     * 
     * @type {Array<IntegrationTable>}
     * @memberof IntegrationSchema
     */
    tables?: Array<IntegrationTable>;
}

/**
 * Check if a given object implements the IntegrationSchema interface.
 */
export function instanceOfIntegrationSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntegrationSchemaFromJSON(json: any): IntegrationSchema {
    return IntegrationSchemaFromJSONTyped(json, false);
}

export function IntegrationSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tables': !exists(json, 'tables') ? undefined : ((json['tables'] as Array<any>).map(IntegrationTableFromJSON)),
    };
}

export function IntegrationSchemaToJSON(value?: IntegrationSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'tables': value.tables === undefined ? undefined : ((value.tables as Array<any>).map(IntegrationTableToJSON)),
    };
}

