/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule } from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule';
import {
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';

/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInner
 */
export interface GetDatasource200ResponseMappedTemplatesInner {
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    type?: GetDatasource200ResponseMappedTemplatesInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    ownerName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    ownerID?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    createdBy?: string;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    templateFields?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    templateRules?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    parentID?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    autoRevoke: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    shareablePermissions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    permittedActions?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    isMine?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    dataSourceID?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    autoApprove?: boolean;
    /**
     * The ordered lineage of parent templates by their external id. The order is from direct parent to
     * grandparent. So if Template3 extended from Template2 which extended from Template1, Template3
     * would have a lineage array of Template2's ID and then Template1's ID.
     * 
     * @type {Array<string>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    lineage?: Array<string>;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    state?: string;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    ownCompletenessRule?: GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    inheritedCompletenessRules?: Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule>;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    ownActivenessRule?: GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule>}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    inheritedActivenessRules?: Array<GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule>;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInner
     */
    extendable?: boolean;
}


/**
 * @export
 */
export const GetDatasource200ResponseMappedTemplatesInnerTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
} as const;
export type GetDatasource200ResponseMappedTemplatesInnerTypeEnum = typeof GetDatasource200ResponseMappedTemplatesInnerTypeEnum[keyof typeof GetDatasource200ResponseMappedTemplatesInnerTypeEnum];


/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInner interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "autoRevoke" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInner {
    return GetDatasource200ResponseMappedTemplatesInnerFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'ownerID': !exists(json, 'ownerID') ? undefined : json['ownerID'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'templateFields': !exists(json, 'templateFields') ? undefined : ((json['templateFields'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerFromJSON)),
        'templateRules': !exists(json, 'templateRules') ? undefined : json['templateRules'],
        'parentID': !exists(json, 'parentID') ? undefined : json['parentID'],
        'autoRevoke': json['autoRevoke'],
        'shareablePermissions': !exists(json, 'shareablePermissions') ? undefined : json['shareablePermissions'],
        'permittedActions': !exists(json, 'permittedActions') ? undefined : json['permittedActions'],
        'isMine': !exists(json, 'isMine') ? undefined : json['isMine'],
        'dataSourceID': !exists(json, 'dataSourceID') ? undefined : json['dataSourceID'],
        'autoApprove': !exists(json, 'autoApprove') ? undefined : json['autoApprove'],
        'lineage': !exists(json, 'lineage') ? undefined : json['lineage'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'ownCompletenessRule': !exists(json, 'ownCompletenessRule') ? undefined : GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON(json['ownCompletenessRule']),
        'inheritedCompletenessRules': !exists(json, 'inheritedCompletenessRules') ? undefined : ((json['inheritedCompletenessRules'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON)),
        'ownActivenessRule': !exists(json, 'ownActivenessRule') ? undefined : GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON(json['ownActivenessRule']),
        'inheritedActivenessRules': !exists(json, 'inheritedActivenessRules') ? undefined : ((json['inheritedActivenessRules'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleFromJSON)),
        'extendable': !exists(json, 'extendable') ? undefined : json['extendable'],
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerToJSON(value?: GetDatasource200ResponseMappedTemplatesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'id': value.id,
        'type': value.type,
        'ownerName': value.ownerName,
        'ownerID': value.ownerID,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'templateFields': value.templateFields === undefined ? undefined : ((value.templateFields as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerToJSON)),
        'templateRules': value.templateRules,
        'parentID': value.parentID,
        'autoRevoke': value.autoRevoke,
        'shareablePermissions': value.shareablePermissions,
        'permittedActions': value.permittedActions,
        'isMine': value.isMine,
        'dataSourceID': value.dataSourceID,
        'autoApprove': value.autoApprove,
        'lineage': value.lineage,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'state': value.state,
        'ownCompletenessRule': GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON(value.ownCompletenessRule),
        'inheritedCompletenessRules': value.inheritedCompletenessRules === undefined ? undefined : ((value.inheritedCompletenessRules as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON)),
        'ownActivenessRule': GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON(value.ownActivenessRule),
        'inheritedActivenessRules': value.inheritedActivenessRules === undefined ? undefined : ((value.inheritedActivenessRules as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleToJSON)),
        'extendable': value.extendable,
    };
}

