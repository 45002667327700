/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnProperties } from './ColumnProperties';
import {
    ColumnPropertiesFromJSON,
    ColumnPropertiesFromJSONTyped,
    ColumnPropertiesToJSON,
} from './ColumnProperties';

/**
 * An Schema patch is a partial update to a schema that may contain new name and description fields, a new delimiter, and/or non-identifier columns that adds new columns to the schema.
 * @export
 * @interface PatchDataSourceSchema
 */
export interface PatchDataSourceSchema {
    /**
     * 
     * @type {string}
     * @memberof PatchDataSourceSchema
     */
    delimiter?: string;
    /**
     * The columns that describe the layout of Records associated with this Schema.
     * @type {Array<ColumnProperties>}
     * @memberof PatchDataSourceSchema
     */
    columns?: Array<ColumnProperties>;
}

/**
 * Check if a given object implements the PatchDataSourceSchema interface.
 */
export function instanceOfPatchDataSourceSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchDataSourceSchemaFromJSON(json: any): PatchDataSourceSchema {
    return PatchDataSourceSchemaFromJSONTyped(json, false);
}

export function PatchDataSourceSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchDataSourceSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delimiter': !exists(json, 'delimiter') ? undefined : json['delimiter'],
        'columns': !exists(json, 'columns') ? undefined : ((json['columns'] as Array<any>).map(ColumnPropertiesFromJSON)),
    };
}

export function PatchDataSourceSchemaToJSON(value?: PatchDataSourceSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delimiter': value.delimiter,
        'columns': value.columns === undefined ? undefined : ((value.columns as Array<any>).map(ColumnPropertiesToJSON)),
    };
}

