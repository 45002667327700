/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  FieldKind,
  FieldKindExtension,
  FieldKindPatch,
  FieldKindResponse,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    FieldKindFromJSON,
    FieldKindToJSON,
    FieldKindExtensionFromJSON,
    FieldKindExtensionToJSON,
    FieldKindPatchFromJSON,
    FieldKindPatchToJSON,
    FieldKindResponseFromJSON,
    FieldKindResponseToJSON,
} from '../models/index';

export interface ExtendFieldKindRequest {
    authorization?: string;
    properties?: FieldKindExtension;
}

export interface GetFieldKindRequest {
    fieldID: string;
    authorization?: string;
}

export interface GetFieldKindsRequest {
    dataType?: string;
    owner?: string;
    parentID?: string;
    startingAfter?: string;
    endingBefore?: string;
    limit?: number;
    authorization?: string;
    sortBy?: GetFieldKindsSortByEnum;
}

export interface UpdateFieldRequest {
    fieldID: string;
    authorization?: string;
    properties?: FieldKindPatch;
}

/**
 * 
 */
export class FieldKindsApi extends runtime.BaseAPI {

    /**
     * `extend:field_kind`  Create a new field kind by extending an existing field kind 
     * Extend Field Kind
     */
    async extendFieldKindRaw(requestParameters: ExtendFieldKindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldKind>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/fields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FieldKindExtensionToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldKindFromJSON(jsonValue));
    }

    /**
     * `extend:field_kind`  Create a new field kind by extending an existing field kind 
     * Extend Field Kind
     */
    async extendFieldKind(requestParameters: ExtendFieldKindRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldKind> {
        const response = await this.extendFieldKindRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:field_kind`  Get a single field kind 
     * Get Field Kind
     */
    async getFieldKindRaw(requestParameters: GetFieldKindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldKind>> {
        if (requestParameters.fieldID === null || requestParameters.fieldID === undefined) {
            throw new runtime.RequiredError('fieldID','Required parameter requestParameters.fieldID was null or undefined when calling getFieldKind.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/fields/{fieldID}`.replace(`{${"fieldID"}}`, encodeURIComponent(String(requestParameters.fieldID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldKindFromJSON(jsonValue));
    }

    /**
     * `read:field_kind`  Get a single field kind 
     * Get Field Kind
     */
    async getFieldKind(requestParameters: GetFieldKindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldKind> {
        const response = await this.getFieldKindRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:field_kind`  Get a paginated list of field kinds 
     * Get Field Kinds
     */
    async getFieldKindsRaw(requestParameters: GetFieldKindsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldKindResponse>> {
        const queryParameters: any = {};

        if (requestParameters.dataType !== undefined) {
            queryParameters['data_type'] = requestParameters.dataType;
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.parentID !== undefined) {
            queryParameters['parentID'] = requestParameters.parentID;
        }

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldKindResponseFromJSON(jsonValue));
    }

    /**
     * `read:field_kind`  Get a paginated list of field kinds 
     * Get Field Kinds
     */
    async getFieldKinds(requestParameters: GetFieldKindsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldKindResponse> {
        const response = await this.getFieldKindsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:field_kind`  Update an existing field kind 
     * Update Field Kind
     */
    async updateFieldRaw(requestParameters: UpdateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldKind>> {
        if (requestParameters.fieldID === null || requestParameters.fieldID === undefined) {
            throw new runtime.RequiredError('fieldID','Required parameter requestParameters.fieldID was null or undefined when calling updateField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/fields/{fieldID}`.replace(`{${"fieldID"}}`, encodeURIComponent(String(requestParameters.fieldID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: FieldKindPatchToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldKindFromJSON(jsonValue));
    }

    /**
     * `update:field_kind`  Update an existing field kind 
     * Update Field Kind
     */
    async updateField(requestParameters: UpdateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldKind> {
        const response = await this.updateFieldRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetFieldKindsSortByEnum = {
    Name: 'name',
    CreatedAt: 'createdAt'
} as const;
export type GetFieldKindsSortByEnum = typeof GetFieldKindsSortByEnum[keyof typeof GetFieldKindsSortByEnum];
