/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOrgLoginInfo200Response
 */
export interface GetOrgLoginInfo200Response {
    /**
     * 
     * @type {string}
     * @memberof GetOrgLoginInfo200Response
     */
    auth0ID: string;
}

/**
 * Check if a given object implements the GetOrgLoginInfo200Response interface.
 */
export function instanceOfGetOrgLoginInfo200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "auth0ID" in value;

    return isInstance;
}

export function GetOrgLoginInfo200ResponseFromJSON(json: any): GetOrgLoginInfo200Response {
    return GetOrgLoginInfo200ResponseFromJSONTyped(json, false);
}

export function GetOrgLoginInfo200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrgLoginInfo200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth0ID': json['auth0ID'],
    };
}

export function GetOrgLoginInfo200ResponseToJSON(value?: GetOrgLoginInfo200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth0ID': value.auth0ID,
    };
}

