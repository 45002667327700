/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SyncFrequency } from './SyncFrequency';
import {
    SyncFrequencyFromJSON,
    SyncFrequencyFromJSONTyped,
    SyncFrequencyToJSON,
} from './SyncFrequency';

/**
 * The properties that must be provided in order to update an integration.
 * 
 * @export
 * @interface UpdateIntegration
 */
export interface UpdateIntegration {
    /**
     * The name of the integration
     * @type {string}
     * @memberof UpdateIntegration
     */
    name?: string | null;
    /**
     * The description of the integration
     * @type {string}
     * @memberof UpdateIntegration
     */
    description?: string | null;
    /**
     * Custom SnowflakeDB to use for ingestion
     * @type {string}
     * @memberof UpdateIntegration
     */
    snowflakeDB?: string | null;
    /**
     * Configuration for the connector
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UpdateIntegration
     */
    config?: { [key: string]: object; };
    /**
     * The authentication configuration, paired with the service type
     * @type {{ [key: string]: object; }}
     * @memberof UpdateIntegration
     */
    auth?: { [key: string]: object; };
    /**
     * Set to true to pause ingesting data from this integration, false to resume
     * @type {boolean}
     * @memberof UpdateIntegration
     */
    pause?: boolean | null;
    /**
     * 
     * @type {SyncFrequency}
     * @memberof UpdateIntegration
     */
    syncFrequency?: SyncFrequency;
    /**
     * The optional parameter that defines the sync start time when the sync frequency is already set 
     * or being set by the current request to 1440. It can be specified in one hour increments starting 
     * from 00:00 to 23:00.
     * 
     * @type {string}
     * @memberof UpdateIntegration
     */
    dailySyncTime?: string;
}

/**
 * Check if a given object implements the UpdateIntegration interface.
 */
export function instanceOfUpdateIntegration(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateIntegrationFromJSON(json: any): UpdateIntegration {
    return UpdateIntegrationFromJSONTyped(json, false);
}

export function UpdateIntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIntegration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'snowflakeDB': !exists(json, 'snowflakeDB') ? undefined : json['snowflakeDB'],
        'config': !exists(json, 'config') ? undefined : json['config'],
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
        'pause': !exists(json, 'pause') ? undefined : json['pause'],
        'syncFrequency': !exists(json, 'sync_frequency') ? undefined : SyncFrequencyFromJSON(json['sync_frequency']),
        'dailySyncTime': !exists(json, 'daily_sync_time') ? undefined : json['daily_sync_time'],
    };
}

export function UpdateIntegrationToJSON(value?: UpdateIntegration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'snowflakeDB': value.snowflakeDB,
        'config': value.config,
        'auth': value.auth,
        'pause': value.pause,
        'sync_frequency': SyncFrequencyToJSON(value.syncFrequency),
        'daily_sync_time': value.dailySyncTime,
    };
}

