/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInner } from './OrgsGet200ResponseContainersInner';
import {
    OrgsGet200ResponseContainersInnerFromJSON,
    OrgsGet200ResponseContainersInnerFromJSONTyped,
    OrgsGet200ResponseContainersInnerToJSON,
} from './OrgsGet200ResponseContainersInner';

/**
 * 
 * @export
 * @interface AuthorizeContainer200Response
 */
export interface AuthorizeContainer200Response {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeContainer200Response
     */
    orgID: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeContainer200Response
     */
    containerID: string;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInner}
     * @memberof AuthorizeContainer200Response
     */
    container: OrgsGet200ResponseContainersInner;
}

/**
 * Check if a given object implements the AuthorizeContainer200Response interface.
 */
export function instanceOfAuthorizeContainer200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgID" in value;
    isInstance = isInstance && "containerID" in value;
    isInstance = isInstance && "container" in value;

    return isInstance;
}

export function AuthorizeContainer200ResponseFromJSON(json: any): AuthorizeContainer200Response {
    return AuthorizeContainer200ResponseFromJSONTyped(json, false);
}

export function AuthorizeContainer200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeContainer200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgID': json['OrgID'],
        'containerID': json['ContainerID'],
        'container': OrgsGet200ResponseContainersInnerFromJSON(json['Container']),
    };
}

export function AuthorizeContainer200ResponseToJSON(value?: AuthorizeContainer200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OrgID': value.orgID,
        'ContainerID': value.containerID,
        'Container': OrgsGet200ResponseContainersInnerToJSON(value.container),
    };
}

