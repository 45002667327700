/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDatasourceRequestSchemaColumnsInner
 */
export interface CreateDatasourceRequestSchemaColumnsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequestSchemaColumnsInner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequestSchemaColumnsInner
     */
    columnName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequestSchemaColumnsInner
     */
    columnDataType: CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequestSchemaColumnsInner
     */
    columnDateFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasourceRequestSchemaColumnsInner
     */
    columnPhoneNumberDefaultCountryCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDatasourceRequestSchemaColumnsInner
     */
    isIdentifier: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDatasourceRequestSchemaColumnsInner
     */
    usedAsDeletion: boolean;
}


/**
 * @export
 */
export const CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum = {
    Bool: 'Bool',
    Date: 'Date',
    DateTime: 'DateTime',
    Email: 'Email',
    Markdown: 'Markdown',
    Number: 'Number',
    PhoneNumber: 'PhoneNumber',
    Text: 'Text',
    Blob: 'Blob',
    Json: 'JSON'
} as const;
export type CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum = typeof CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum[keyof typeof CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum];


/**
 * Check if a given object implements the CreateDatasourceRequestSchemaColumnsInner interface.
 */
export function instanceOfCreateDatasourceRequestSchemaColumnsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "columnName" in value;
    isInstance = isInstance && "columnDataType" in value;
    isInstance = isInstance && "isIdentifier" in value;
    isInstance = isInstance && "usedAsDeletion" in value;

    return isInstance;
}

export function CreateDatasourceRequestSchemaColumnsInnerFromJSON(json: any): CreateDatasourceRequestSchemaColumnsInner {
    return CreateDatasourceRequestSchemaColumnsInnerFromJSONTyped(json, false);
}

export function CreateDatasourceRequestSchemaColumnsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDatasourceRequestSchemaColumnsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'columnName': json['columnName'],
        'columnDataType': json['columnDataType'],
        'columnDateFormat': !exists(json, 'columnDateFormat') ? undefined : json['columnDateFormat'],
        'columnPhoneNumberDefaultCountryCode': !exists(json, 'columnPhoneNumberDefaultCountryCode') ? undefined : json['columnPhoneNumberDefaultCountryCode'],
        'isIdentifier': json['isIdentifier'],
        'usedAsDeletion': json['usedAsDeletion'],
    };
}

export function CreateDatasourceRequestSchemaColumnsInnerToJSON(value?: CreateDatasourceRequestSchemaColumnsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'columnName': value.columnName,
        'columnDataType': value.columnDataType,
        'columnDateFormat': value.columnDateFormat,
        'columnPhoneNumberDefaultCountryCode': value.columnPhoneNumberDefaultCountryCode,
        'isIdentifier': value.isIdentifier,
        'usedAsDeletion': value.usedAsDeletion,
    };
}

