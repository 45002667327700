/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The properties provided when creating a container
 * @export
 * @interface CreateContainer
 */
export interface CreateContainer {
    /**
     * The ID of the recipient entity.
     * @type {string}
     * @memberof CreateContainer
     */
    recipientId: string;
    /**
     * The ID of the template.
     * @type {string}
     * @memberof CreateContainer
     */
    templateId: string;
}

/**
 * Check if a given object implements the CreateContainer interface.
 */
export function instanceOfCreateContainer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recipientId" in value;
    isInstance = isInstance && "templateId" in value;

    return isInstance;
}

export function CreateContainerFromJSON(json: any): CreateContainer {
    return CreateContainerFromJSONTyped(json, false);
}

export function CreateContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContainer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipientId': json['recipient_id'],
        'templateId': json['template_id'],
    };
}

export function CreateContainerToJSON(value?: CreateContainer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient_id': value.recipientId,
        'template_id': value.templateId,
    };
}

