import { Datagrid, DatagridBody, EllipsisText, Spin } from "../../../components";
import { Helpers } from "@merit/frontend-utils";
import { View } from "react-native";
import { useApi } from "../../../api/api";
import { useDatasourceDetailsScreenStyles } from "../../../../src/screens/Datasources/DatasourceDetails/styles";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../../src/utils/useServerErrorHandler";
import { useTheme } from "@merit/frontend-components";
import React, { useEffect, useState } from "react";
import type { DatagridColumn } from "../../../components/Datagrid/types";
import type { GetFileStatus200Response } from "../../../gen/org-portal";
import type { OPTestProps } from "../../../types/TestProps";

type Props = {
  readonly correlationId?: string;
  readonly error?: string;
  readonly testProps: OPTestProps;
};

const { Some } = Helpers;

export const Errors = ({ correlationId, error, testProps }: Props) => {
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();
  const { selectedOrgId } = useLoggedInAuthState();
  const styles = useDatasourceDetailsScreenStyles();
  const { theme } = useTheme();
  const [fileStatus, setFileStatus] = useState<GetFileStatus200Response>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getErrors = async () => {
      if (correlationId !== undefined && correlationId !== "") {
        try {
          setIsLoading(true);
          const response = await api.getFileStatus({
            correlationID: correlationId,
            orgID: selectedOrgId,
          });
          setFileStatus(response);
        } catch (statusError) {
          await errorHandler(statusError);
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (correlationId !== undefined && fileStatus === undefined) {
      getErrors();
    }
  }, [api, correlationId, errorHandler, fileStatus, selectedOrgId, testProps, theme]);

  if (isLoading) {
    return (
      <View
        style={[
          styles.container,
          { alignItems: "center", flexDirection: "column", justifyContent: "center" },
        ]}
      >
        <Spin />
      </View>
    );
  }

  const statusColumns: readonly DatagridColumn<GetFileStatus200Response>[] = [
    {
      key: "status",
      label: "Status",
      renderer: field => <EllipsisText testProps={testProps} text={field.status} />,
      size: "flex",
    },
    {
      key: "error",
      label: "Error",
      renderer: field => <EllipsisText testProps={testProps} text={field.error ?? "--"} />,
      size: "flex",
    },
  ];

  if (fileStatus !== undefined) {
    return (
      <View style={{ paddingHorizontal: 32, paddingVertical: theme.spacing.xxl }}>
        <Datagrid columns={statusColumns}>
          <DatagridBody columns={statusColumns} data={[fileStatus]} testProps={testProps} />
        </Datagrid>
      </View>
    );
  }

  const errorColumns: readonly DatagridColumn<Record<string, string>>[] = [
    {
      key: "error",
      label: "Error",
      renderer: field => <EllipsisText testProps={testProps} text={field.error} />,
      size: "flex",
    },
  ];

  return (
    <View style={{ paddingVertical: theme.spacing.s }}>
      <Datagrid columns={errorColumns}>
        <DatagridBody
          columns={errorColumns}
          data={[Some(error) ? { error } : { error: "--" }]}
          testProps={testProps}
        />
      </Datagrid>
    </View>
  );
};
