/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FieldType } from './FieldType';
import {
    FieldTypeFromJSON,
    FieldTypeFromJSONTyped,
    FieldTypeToJSON,
} from './FieldType';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';
import type { ResponseRules } from './ResponseRules';
import {
    ResponseRulesFromJSON,
    ResponseRulesFromJSONTyped,
    ResponseRulesToJSON,
} from './ResponseRules';

/**
 * Representation of a FieldKind search result.
 * @export
 * @interface FieldKindSearchResult
 */
export interface FieldKindSearchResult {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof FieldKindSearchResult
     */
    id?: string;
    /**
     * The name of the object
     * @type {string}
     * @memberof FieldKindSearchResult
     */
    name?: string;
    /**
     * The UUID of this object's owner
     * @type {string}
     * @memberof FieldKindSearchResult
     */
    owner?: string;
    /**
     * 
     * @type {FieldType}
     * @memberof FieldKindSearchResult
     */
    dataType?: FieldType;
    /**
     * The description of this FieldKind.
     * @type {string}
     * @memberof FieldKindSearchResult
     */
    description?: string;
    /**
     * The id of the FieldKind this was extended from.
     * @type {string}
     * @memberof FieldKindSearchResult
     */
    parent?: string;
    /**
     * 
     * @type {ResponseRules}
     * @memberof FieldKindSearchResult
     */
    rules?: ResponseRules;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof FieldKindSearchResult
     */
    permissions?: Array<PermissionPair>;
}

/**
 * Check if a given object implements the FieldKindSearchResult interface.
 */
export function instanceOfFieldKindSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FieldKindSearchResultFromJSON(json: any): FieldKindSearchResult {
    return FieldKindSearchResultFromJSONTyped(json, false);
}

export function FieldKindSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldKindSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'dataType': !exists(json, 'dataType') ? undefined : FieldTypeFromJSON(json['dataType']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'rules': !exists(json, 'rules') ? undefined : ResponseRulesFromJSON(json['rules']),
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
    };
}

export function FieldKindSearchResultToJSON(value?: FieldKindSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner': value.owner,
        'dataType': FieldTypeToJSON(value.dataType),
        'description': value.description,
        'parent': value.parent,
        'rules': ResponseRulesToJSON(value.rules),
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
    };
}

