// Copyright 2022 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { LoggedInHeader } from "../components/LoggedInHeader";
import { Nav } from "../components";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { OPTestProps } from "../types/TestProps";
import type { ReactNode } from "react";

type Props = {
  readonly children: ReactNode;
  readonly headerRightElements?: ReactNode;
  readonly title: string;
  readonly tabs?: ReactNode;
  readonly testProps?: OPTestProps;
};

const { Some } = Helpers;

export const DefaultLayout = ({ children, headerRightElements, tabs, testProps, title }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
    },
    headerContainer: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      height: 104,
      justifyContent: "space-between",
    },
  });

  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView style={styles.container}>
        <Nav />
        <View style={{ flex: 1 }}>
          <View style={styles.headerContainer}>
            <LoggedInHeader
              rightElements={headerRightElements}
              testProps={testProps}
              title={title}
            />
            {Some(tabs) && <View style={{ paddingHorizontal: 32 }}>{tabs}</View>}
          </View>
          <View style={{ flex: 1 }}>{children}</View>
        </View>
      </SafeAreaView>
    </View>
  );
};
