// Copyright 2023 Merit International Inc. All Rights Reserved
/* eslint-disable react/no-multi-comp */

import { Body, Button, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "../../components";
import { EllipsisText } from "../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { SCREEN_NAME } from "./Records";
import { useGetContainerValueByBaseFieldName } from "../../utils/getContainerFieldValue";
import React from "react";
import type { OrgsGet200ResponseContainersInner as Container } from "../../gen/org-portal";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { OPTestProps } from "@src/types/TestProps";

const { None } = Helpers;

type Props = {
  readonly containers: readonly Container[];
  readonly onPressView: (container: Container) => void;
  readonly onPressDelete: (container: Container) => void;
  readonly isMerit: boolean;
};

const maybeEllipsesText =
  (theme: ReturnType<typeof useTheme>["theme"]) =>
  (text: string, testProps: OPTestProps | undefined, capitalize?: boolean) => {
    // linter wants me to do const { length } = text; which does not seem to work as expected
    // eslint-disable-next-line react/destructuring-assignment
    if (text.length > 15) {
      return <EllipsisText capitalize={capitalize} testProps={testProps} text={text} />;
    }

    return (
      <Body
        capitalize={capitalize}
        style={{
          fontWeight: theme.fontWeights.normal,
        }}
        testProps={testProps}
      >
        {text}
      </Body>
    );
  };

export const Containers = ({ containers, isMerit, onPressDelete, onPressView }: Props) => {
  const { theme } = useTheme();
  const ellipsisRender = maybeEllipsesText(theme);
  const { getContainerValueByBaseFieldName } = useGetContainerValueByBaseFieldName();

  const meritColumns: readonly DatagridColumn<Container>[] = [
    {
      key: "email",
      label: "Email",
      renderer: (container, testProps) =>
        ellipsisRender(
          getContainerValueByBaseFieldName({
            container,
            fieldName: "email",
            templateType: "merit",
          }),
          testProps
        ),
      size: "flex",
    },
    {
      key: "firstName",
      label: "First name",
      renderer: (container: Container, testProps) =>
        ellipsisRender(
          getContainerValueByBaseFieldName({
            container,
            fieldName: "firstName",
            templateType: "merit",
          }),
          testProps
        ),
      size: "flex",
    },
    {
      key: "lastName",
      label: "Last name",
      renderer: (container: Container, testProps) =>
        ellipsisRender(
          getContainerValueByBaseFieldName({
            container,
            fieldName: "lastName",
            templateType: "merit",
          }),
          testProps
        ),
      size: "flex",
    },
  ];

  const folioColumn: readonly DatagridColumn<Container>[] = [
    {
      key: "orgName",
      label: "Organization Name",
      renderer: (container, testProps) => {
        const fields = container.fields;
        if (None(fields)) {
          throw new Error("Somehow got to records screen without fields");
        }
        const orgNameFieldValue = getContainerValueByBaseFieldName({
          container,
          fieldName: "organizationName",
          templateType: "folio",
        });

        return ellipsisRender(orgNameFieldValue, testProps);
      },
      size: 236,
    },
    {
      key: "id",
      label: "Organization ID",
      renderer: (container: Container, testProps) => {
        const orgID = container.recipient?.id;

        return ellipsisRender(orgID?.toString() ?? "--", testProps);
      },
      size: 180,
    },
  ];
  const columns: readonly DatagridColumn<Container>[] = [
    ...(isMerit ? meritColumns : folioColumn),
    {
      key: "templateName",
      label: "Template",
      renderer: (container, testProps) => ellipsisRender(container.name ?? "--", testProps),
      size: "flex",
    },
    {
      key: "containerState",
      label: "Container state",
      renderer: (container, testProps) =>
        ellipsisRender(container.state?.name ?? "--", testProps, true),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      renderer: record => (
        <>
          <Button
            onPress={() => {
              onPressView(record);
            }}
            size="small"
            testProps={{
              elementId: record.id,
              elementName: "recordsListViewRowItemViewButton",
              screenName: SCREEN_NAME,
            }}
            text="View"
            type="secondary"
          />
          <HorizontalSpacer />
          <Button
            iconLeft="closeSmallDefault"
            onPress={() => {
              onPressDelete(record);
            }}
            shape="square"
            size="small"
            testProps={{
              elementId: record.id,
              elementName: "recordsListViewRowItemRemoveButton",
              screenName: SCREEN_NAME,
            }}
            type="destructive"
          />
        </>
      ),
      size: 200,
    },
  ];

  return (
    <Datagrid
      columns={columns}
      testProps={{
        elementName: "recordsListView",
        screenName: SCREEN_NAME,
      }}
    >
      <DatagridBody
        columns={columns}
        data={containers}
        getItemKey={(c: Container) => c.id}
        testProps={{
          elementName: "RecordsListView",
          screenName: SCREEN_NAME,
        }}
        testPropsElementIdKey="id"
      />
    </Datagrid>
  );
};
