// Copyright 2022 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { JwtClaims } from "@src/types/auth";

export type AuthState = {
  readonly userCanRegisterOrgs: boolean;
  readonly selectedOrgId: string | null;
  readonly setSelectedOrgId: (newOrgId: string | null) => void;
  readonly selectedOrgName: string | null;
  readonly setSelectedOrgName: (newOrgName: string | null) => void;
  readonly permissions: readonly string[] | null;
  readonly setPermissions: (permissions: readonly string[] | null) => void;
  readonly accessToken: string | null;
  readonly setAccessToken: (newAccessToken: string | null) => void;
  readonly session: string | null;
  readonly setSession: (newSession: string | null) => void;
  readonly profile: JwtClaims | null;
  readonly setProfile: (newProfile: JwtClaims | null) => void;
  readonly clear: () => void;
  readonly _hasHydrated: boolean;
  readonly setHasHydrated: (state: boolean) => void;
};

/*
Do not use this outside of where the auth store is loaded and initialized!
Use the useLoggedInAuthState hook instead.
 */
export const useAuthStore = create<AuthState>()(
  persist(
    set => ({
      _hasHydrated: false,
      accessToken: null,
      clear: () => {
        set(() => ({
          accessToken: null,
          profile: null,
          selectedOrgId: null,
          selectedOrgName: null,
          session: null,
        }));
      },
      permissions: null,
      profile: null,
      selectedOrgId: null,
      selectedOrgName: null,
      session: null,
      setAccessToken: (newAccessToken: string | null) => {
        set(() => ({ accessToken: newAccessToken }));
      },
      setHasHydrated: state => {
        set({
          _hasHydrated: state,
        });
      },
      setPermissions: (permissions: readonly string[] | null) => {
        set(() => ({ permissions }));
      },
      setProfile: (newProfile: JwtClaims | null) => {
        set(() => ({
          authAcceptedAccountFolio: false,
          profile: newProfile,
          userCanRegisterOrgs: newProfile?.name.includes("@gomerits.com"),
        }));
      },
      setSelectedOrgId: (newOrgId: string | null) => {
        set(() => ({ selectedOrgId: newOrgId }));
      },
      setSelectedOrgName: (newOrgName: string | null) => {
        set(() => ({ selectedOrgName: newOrgName }));
      },
      setSession: (newSession: string | null) => {
        set(() => ({ session: newSession }));
      },
      userCanRegisterOrgs: false,
    }),
    {
      name: "orgportal-auth-storage",
      onRehydrateStorage: () => (state: AuthState | undefined) => {
        if (state !== undefined) {
          // state is only undefined when there was an error
          state.setHasHydrated(true);
        }
      },
    }
  )
);
