// Copyright 2023 Merit International Inc. All Rights Reserved

import { ConfirmationModal } from "../../../src/components/Modals";
import { Containers } from "./Containers";
import { Drawer } from "../../components/Drawer";
import { Filters } from "./Filters";
import { Helpers } from "@merit/frontend-utils";
import { NoRecords } from "./NoRecords";
import { Pagination, Spin } from "../../components";
import { RecordDetails } from "../RecordDetails/RecordDetails";
import { ScrollView, StyleSheet, View } from "react-native";
import { SearchRecords } from "./SearchRecords";
import { VerticalSpacer } from "../../components/Spacer";
import { useAlertStore, useAppConstantsStore } from "../../stores";
import { useApi } from "../../api/api";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useFocusEffect, useIsFocused, useRoute } from "@react-navigation/native";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useRecordsData } from "./useRecordsData";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useTheme } from "@merit/frontend-components";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useState } from "react";
import type { FormValues } from "./Filters";
import type {
  GetContainersRequest,
  ListTemplates200ResponseTemplatesInnerTypeEnum,
  OrgsGet200ResponseContainersInner,
} from "../../gen/org-portal";
import type { ReactNode } from "react";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

export const SCREEN_NAME = "Records";

const { None, Some } = Helpers;

export type ContainerWithTemplateType = OrgsGet200ResponseContainersInner & {
  readonly templateType: ListTemplates200ResponseTemplatesInnerTypeEnum;
};

export const Records = () => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const { errorHandler } = useServerErrorHandler();
  const appConstants = useAppConstantsStore();
  const isFocused = useIsFocused();
  const [confirmationModal, setConfirmationModal] = useState<ReactNode>();
  const [filterValues, setFilterValues] = useState<FormValues>();
  const { params: routeParams } = useRoute<RouteProp<RouteParams, "RecordDetails" | "Records">>();
  const [recordToView, setRecordToView] = useState<string | undefined>(routeParams?.id);
  const [templateType, setTemplateType] = useState<ListTemplates200ResponseTemplatesInnerTypeEnum>(
    routeParams?.templateType === undefined ? "Merit" : routeParams.templateType
  );
  const [isRevoking, setIsRevoking] = useState(false);
  const { DefaultLayout } = useFlaggedLayout();

  const {
    data: records,
    isLoading,
    nextPage,
    pagination,
    prevPage,
    refresh,
    setParams,
  } = useRecordsData(
    api,
    {
      issuerID: selectedOrgId,
      limit: appConstants.recordsLimit,
      orgID: selectedOrgId,
      sortBy: "createdAt",
    },
    isFocused
  );

  const callOnUnFocus = useCallback(() => {
    setParams(
      (previousParams): GetContainersRequest =>
        ({
          ...previousParams,
          end: undefined,
          start: undefined,
        } as GetContainersRequest)
    );
  }, [setParams]);

  useFocusEffect(React.useCallback(() => callOnUnFocus, [callOnUnFocus]));

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    tabBody: {
      flex: 1,
      paddingTop: theme.spacing.xxl,
    },
  });

  const handleRemoveContainer = async (recordId: string) => {
    try {
      await api.deleteContainer({
        containerID: recordId,
        orgID: selectedOrgId,
      });

      setRecordToView(undefined);

      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `A ${templateType.toLowerCase()} has been removed.`,
        type: "success",
      });

      refresh();
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const revokeContainer = async (containerID: string) => {
    setIsRevoking(true);
    try {
      await api.revokeContainer({
        containerID,
        orgID: selectedOrgId,
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `A ${templateType.toLowerCase()} has been revoked.`,
        type: "success",
      });
      setRecordToView(undefined);
      refresh();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsRevoking(false);
    }
  };

  const reissueContainer = async (containerID: string) => {
    setIsRevoking(true);
    try {
      await api.authorizeContainer({
        containerID,
        orgID: selectedOrgId,
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `A ${templateType.toLowerCase()} has been reissued.`,
        type: "success",
      });
      setRecordToView(undefined);
      refresh();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsRevoking(false);
    }
  };

  const createConfirmationModal = (recordId: string) => (
    <ConfirmationModal
      buttonText="remove"
      onClose={() => {
        setConfirmationModal(undefined);
      }}
      onOk={() => {
        handleRemoveContainer(recordId);
        setConfirmationModal(undefined);
      }}
      text={`Are you sure you want to remove this ${templateType.toLowerCase()} as an admin? You cannot view this ${templateType.toLowerCase()} in your list anymore if you remove.`}
      title={`Remove this ${templateType.toLowerCase()}`}
      titleIconName="warningMediumCritical"
    />
  );

  const createReissueConfirmationModal = (recordId: string) => (
    <ConfirmationModal
      buttonText="reissue"
      onClose={() => {
        setConfirmationModal(undefined);
      }}
      onOk={() => {
        reissueContainer(recordId);
        setConfirmationModal(undefined);
      }}
      text={`Are you sure you want to reissue this ${templateType.toLowerCase()}?`}
      title={`Reissue this ${templateType.toLowerCase()}`}
    />
  );

  const createRevokeConfirmationModal = (recordId: string) => (
    <ConfirmationModal
      buttonText="revoke"
      onClose={() => {
        setConfirmationModal(undefined);
      }}
      onOk={() => {
        revokeContainer(recordId);
        setConfirmationModal(undefined);
      }}
      text={`Are you sure you want to revoke this ${templateType.toLowerCase()}? In order to reissue it you’ll have to reissue it from the revoked status`}
      title={`Revoke this ${templateType.toLowerCase()}`}
    />
  );

  return (
    <>
      <DefaultLayout
        breadcrumbs={[{ name: "Verify & Manage" }, { name: "Records" }]}
        testProps={{ elementName: "recordsListView", screenName: SCREEN_NAME }}
        title="Records"
      >
        <Spin spinning={isLoading || isRevoking}>
          <View style={styles.container}>
            <VerticalSpacer size={theme.spacing.xxl} />
            <Filters
              onSubmit={(values?: FormValues) => {
                if (Some(values)) {
                  refresh({
                    end: undefined,
                    issuerID: selectedOrgId,
                    start: undefined,
                    state: values.state,
                    templateType: values.templateType,
                  });

                  setFilterValues(values);

                  if (values.templateType !== undefined) {
                    setTemplateType(values.templateType);
                  }

                  setRecordToView(undefined);
                }
              }}
            />

            {Some(records) &&
              (records.length > 0 ? (
                <>
                  <View style={styles.tabBody}>
                    <ScrollView>
                      <Containers
                        containers={records}
                        isMerit={filterValues?.templateType === "Merit"}
                        onPressDelete={record => {
                          if (Some(filterValues) && Some(filterValues.templateType)) {
                            setConfirmationModal(createConfirmationModal(record.id));
                          }
                        }}
                        onPressView={record => {
                          if (Some(filterValues) && Some(filterValues.templateType)) {
                            setRecordToView(record.id);
                          }
                        }}
                      />
                    </ScrollView>
                  </View>
                  <Pagination
                    disableNext={!pagination.hasNextPage}
                    disablePrev={!pagination.hasPrevPage}
                    onNext={nextPage}
                    onPrev={prevPage}
                    testProps={{ elementName: "recordsListView", screenName: SCREEN_NAME }}
                  />
                </>
              ) : (
                <NoRecords />
              ))}

            {None(records) && <SearchRecords />}
          </View>
        </Spin>
      </DefaultLayout>

      {confirmationModal}

      <Drawer isOpen={Some(recordToView)}>
        {Some(recordToView) && (
          <RecordDetails
            id={recordToView}
            onDrawerClose={() => {
              setRecordToView(undefined);
            }}
            onPressDelete={() => {
              setConfirmationModal(createConfirmationModal(recordToView));
            }}
            onPressReissue={() => {
              setConfirmationModal(createReissueConfirmationModal(recordToView));
            }}
            onPressRevoke={() => {
              setConfirmationModal(createRevokeConfirmationModal(recordToView));
            }}
            templateType={templateType}
          />
        )}
      </Drawer>
    </>
  );
};
