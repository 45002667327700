/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted';

/**
 * A pair of permissions applicable for an action to be performed on an object
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner
 */
export interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner {
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner
     */
    action: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner
     */
    permitted: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted;
    /**
     * 
     * @type {GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner
     */
    permissibleToPermit: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermitted;
}


/**
 * @export
 */
export const GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum = {
    Read: 'read',
    Extend: 'extend',
    ReadData: 'read_data'
} as const;
export type GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum = typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum[keyof typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum];


/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "permitted" in value;
    isInstance = isInstance && "permissibleToPermit" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner {
    return GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'permitted': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedFromJSON(json['permitted']),
        'permissibleToPermit': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedFromJSON(json['permissibleToPermit']),
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'permitted': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedToJSON(value.permitted),
        'permissibleToPermit': GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedToJSON(value.permissibleToPermit),
    };
}

