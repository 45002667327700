/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner } from './GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner';
import {
    GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSON,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSONTyped,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerToJSON,
} from './GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner';

/**
 * A capability allows an agent to perform an action on behalf of an entity
 * @export
 * @interface GetAgents200ResponseAgentsInnerCapabilitiesInner
 */
export interface GetAgents200ResponseAgentsInnerCapabilitiesInner {
    /**
     * The unique key that identifies this capability, e.g. read-container
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerCapabilitiesInner
     */
    key: string;
    /**
     * A human readable version of key, e.g. Read Container
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerCapabilitiesInner
     */
    name: string;
    /**
     * A description of what this capability allows an agent to do
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInnerCapabilitiesInner
     */
    description: string;
    /**
     * 
     * @type {Array<GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner>}
     * @memberof GetAgents200ResponseAgentsInnerCapabilitiesInner
     */
    objects?: Array<GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner>;
}

/**
 * Check if a given object implements the GetAgents200ResponseAgentsInnerCapabilitiesInner interface.
 */
export function instanceOfGetAgents200ResponseAgentsInnerCapabilitiesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSON(json: any): GetAgents200ResponseAgentsInnerCapabilitiesInner {
    return GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSONTyped(json, false);
}

export function GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgents200ResponseAgentsInnerCapabilitiesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'description': json['description'],
        'objects': !exists(json, 'objects') ? undefined : ((json['objects'] as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSON)),
    };
}

export function GetAgents200ResponseAgentsInnerCapabilitiesInnerToJSON(value?: GetAgents200ResponseAgentsInnerCapabilitiesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'description': value.description,
        'objects': value.objects === undefined ? undefined : ((value.objects as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerToJSON)),
    };
}

