import { useFocusEffect } from "@react-navigation/native";
import { useState } from "react";

export const useDataRefreshEffect = <T extends () => void>(callback: T) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useFocusEffect(() => {
    if (shouldRefresh) {
      callback();
    }

    setShouldRefresh(false);

    return () => {
      setShouldRefresh(true);
    };
  });
};
