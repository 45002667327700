// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { SectionFieldRules } from "./SectionFieldRules";
import { SelectField } from "./SelectField";
import { StyleSheet, View } from "react-native";
import { newFieldRule } from "../utils";
import { useAllTemplateOptions } from "@src/api/orgportal";
import { useFormikContext } from "formik";
import type { FC } from "react";
import type { Option, Values } from "../types";

type SectionTemplateRuleProps = {
  readonly templateRuleIdx: number;
};

export const SectionTemplateRule: FC<SectionTemplateRuleProps> = ({ templateRuleIdx }) => {
  const { theme } = useTheme();
  const ctx = useFormikContext<Values>();
  const { allTemplates } = useAllTemplateOptions({ type: "Merit" });

  const templateRules = ctx.values.templateRules;

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.default,
      borderRadius: theme.borderRadii.xl,
      flex: 1,
      marginBottom: theme.spacing.xxl,
      padding: theme.spacing.m,
    },
    horizontalRule: {
      borderBlockColor: theme.colors.border.default,
      borderBottomWidth: 1,
    },
    sectionContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.sectionContainer}>
        <Body size="l" style={{ marginRight: theme.spacing.l }}>
          Member has
        </Body>
        <View style={{ flex: 1 }}>
          <SelectField
            defaultValue={
              templateRules[templateRuleIdx].templateId === null
                ? undefined
                : ({
                    label: templateRules[templateRuleIdx].templateId,
                    value: templateRules[templateRuleIdx].templateId,
                  } as Option)
            }
            label="Member has"
            name={`templateRules.${templateRuleIdx}.templateId`}
            options={allTemplates}
            showLabel={false}
            usePortal
          />
        </View>
      </View>
      <View style={styles.sectionContainer}>
        <Body size="l" style={{ marginRight: theme.spacing.l }}>
          with
        </Body>
        <SelectField
          defaultValue={{
            label: templateRules[templateRuleIdx].templateStatus,
            value: templateRules[templateRuleIdx].templateStatus,
          }}
          label="Status"
          name={`templateRules.${templateRuleIdx}.templateStatus`}
          options={[
            { label: "any", value: "any" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ]}
          showLabel={false}
          usePortal
        />
        <Body size="l" style={{ marginHorizontal: theme.spacing.l }}>
          status and
        </Body>
        <SelectField
          defaultValue={{
            label: templateRules[templateRuleIdx].fieldValuesType,
            value: templateRules[templateRuleIdx].fieldValuesType,
          }}
          label="Field Values"
          name={`templateRules.${templateRuleIdx}.fieldValuesType`}
          onSelectOption={option => {
            if (option.value === "specific") {
              ctx.setFieldValue(`templateRules.${templateRuleIdx}.fieldRules`, [newFieldRule()]);
            } else {
              ctx.setFieldValue(`templateRules.${templateRuleIdx}.fieldRules`, []);
            }
          }}
          options={[
            { label: "any", value: "any" },
            { label: "specific", value: "specific" },
          ]}
          showLabel={false}
          usePortal
        />
        <Body size="l" style={{ marginLeft: theme.spacing.l }}>
          field values
        </Body>
      </View>
      {templateRules[templateRuleIdx].fieldValuesType === "specific" &&
        templateRules[templateRuleIdx].templateId !== null && (
          <>
            <View style={styles.horizontalRule} />
            <View style={styles.sectionContainer}>
              <SectionFieldRules
                templateRule={templateRules[templateRuleIdx]}
                templateRuleIdx={templateRuleIdx}
              />
            </View>
          </>
        )}
    </View>
  );
};
