/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPolicies200ResponsePoliciesInner } from './GetPolicies200ResponsePoliciesInner';
import {
    GetPolicies200ResponsePoliciesInnerFromJSON,
    GetPolicies200ResponsePoliciesInnerFromJSONTyped,
    GetPolicies200ResponsePoliciesInnerToJSON,
} from './GetPolicies200ResponsePoliciesInner';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * An object that contains a list of Policies.
 * @export
 * @interface GetPolicies200Response
 */
export interface GetPolicies200Response {
    /**
     * 
     * @type {string}
     * @memberof GetPolicies200Response
     */
    orgID?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPolicies200Response
     */
    ownerOrgID?: string;
    /**
     * The list of policies available.
     * @type {Array<GetPolicies200ResponsePoliciesInner>}
     * @memberof GetPolicies200Response
     */
    policies?: Array<GetPolicies200ResponsePoliciesInner>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof GetPolicies200Response
     */
    paginationInfo?: PaginationInfo;
}

/**
 * Check if a given object implements the GetPolicies200Response interface.
 */
export function instanceOfGetPolicies200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetPolicies200ResponseFromJSON(json: any): GetPolicies200Response {
    return GetPolicies200ResponseFromJSONTyped(json, false);
}

export function GetPolicies200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPolicies200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgID': !exists(json, 'OrgID') ? undefined : json['OrgID'],
        'ownerOrgID': !exists(json, 'OwnerOrgID') ? undefined : json['OwnerOrgID'],
        'policies': !exists(json, 'policies') ? undefined : ((json['policies'] as Array<any>).map(GetPolicies200ResponsePoliciesInnerFromJSON)),
        'paginationInfo': !exists(json, 'PaginationInfo') ? undefined : PaginationInfoFromJSON(json['PaginationInfo']),
    };
}

export function GetPolicies200ResponseToJSON(value?: GetPolicies200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OrgID': value.orgID,
        'OwnerOrgID': value.ownerOrgID,
        'policies': value.policies === undefined ? undefined : ((value.policies as Array<any>).map(GetPolicies200ResponsePoliciesInnerToJSON)),
        'PaginationInfo': PaginationInfoToJSON(value.paginationInfo),
    };
}

