/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityInfo } from './EntityInfo';
import {
    EntityInfoFromJSON,
    EntityInfoFromJSONTyped,
    EntityInfoToJSON,
} from './EntityInfo';
import type { FieldKindPropsValidation } from './FieldKindPropsValidation';
import {
    FieldKindPropsValidationFromJSON,
    FieldKindPropsValidationFromJSONTyped,
    FieldKindPropsValidationToJSON,
} from './FieldKindPropsValidation';
import type { FieldType } from './FieldType';
import {
    FieldTypeFromJSON,
    FieldTypeFromJSONTyped,
    FieldTypeToJSON,
} from './FieldType';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * Representation of a named FieldType belonging to an Org.
 * @export
 * @interface FieldKind
 */
export interface FieldKind {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof FieldKind
     */
    id: string;
    /**
     * 
     * @type {EntityInfo}
     * @memberof FieldKind
     */
    owner: EntityInfo;
    /**
     * 
     * @type {Date}
     * @memberof FieldKind
     */
    createdAt: Date;
    /**
     * 
     * @type {FieldType}
     * @memberof FieldKind
     */
    dataType: FieldType;
    /**
     * The given name of this FieldKind.
     * @type {string}
     * @memberof FieldKind
     */
    name: string;
    /**
     * The description of this FieldKind.
     * @type {string}
     * @memberof FieldKind
     */
    description?: string;
    /**
     * The id of the FieldKind this was extended from.
     * @type {string}
     * @memberof FieldKind
     */
    parent?: string;
    /**
     * 
     * @type {FieldKindPropsValidation}
     * @memberof FieldKind
     */
    validation?: FieldKindPropsValidation;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof FieldKind
     */
    permissions: Array<PermissionPair>;
    /**
     * true if this fieldkind has been extended and has child fieldkinds
     * @type {boolean}
     * @memberof FieldKind
     */
    hasChildren?: boolean;
}

/**
 * Check if a given object implements the FieldKind interface.
 */
export function instanceOfFieldKind(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "dataType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function FieldKindFromJSON(json: any): FieldKind {
    return FieldKindFromJSONTyped(json, false);
}

export function FieldKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldKind {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': EntityInfoFromJSON(json['owner']),
        'createdAt': (new Date(json['createdAt'])),
        'dataType': FieldTypeFromJSON(json['dataType']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'validation': !exists(json, 'validation') ? undefined : FieldKindPropsValidationFromJSON(json['validation']),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'hasChildren': !exists(json, 'hasChildren') ? undefined : json['hasChildren'],
    };
}

export function FieldKindToJSON(value?: FieldKind | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner': EntityInfoToJSON(value.owner),
        'createdAt': (value.createdAt.toISOString()),
        'dataType': FieldTypeToJSON(value.dataType),
        'name': value.name,
        'description': value.description,
        'parent': value.parent,
        'validation': FieldKindPropsValidationToJSON(value.validation),
        'permissions': ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'hasChildren': value.hasChildren,
    };
}

