/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * rules of the template
 * @export
 * @interface EditTemplateRequestRules
 */
export interface EditTemplateRequestRules {
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof EditTemplateRequestRules
     */
    activeness?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>;
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof EditTemplateRequestRules
     */
    completeness?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>;
}

/**
 * Check if a given object implements the EditTemplateRequestRules interface.
 */
export function instanceOfEditTemplateRequestRules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditTemplateRequestRulesFromJSON(json: any): EditTemplateRequestRules {
    return EditTemplateRequestRulesFromJSONTyped(json, false);
}

export function EditTemplateRequestRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTemplateRequestRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeness': !exists(json, 'activeness') ? undefined : ((json['activeness'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
        'completeness': !exists(json, 'completeness') ? undefined : ((json['completeness'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
    };
}

export function EditTemplateRequestRulesToJSON(value?: EditTemplateRequestRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeness': value.activeness === undefined ? undefined : ((value.activeness as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
        'completeness': value.completeness === undefined ? undefined : ((value.completeness as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
    };
}

