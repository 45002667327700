import { Heading, Select, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { StyleSheet, View } from "react-native";
import { useState } from "react";
import type { FieldToMap, FormValues } from "./MapDataSource";
import type { FormikProps } from "formik";
import type { OPTestProps } from "@src/types/TestProps";
import type { OrgsGet200ResponseContainersInner } from "../../gen/org-portal";

const { None, Some } = Helpers;

type Option = {
  readonly label: string;
  readonly value: string;
};

type Props = {
  readonly formProps: {
    readonly getFieldMeta: FormikProps<FormValues>["getFieldMeta"];
    readonly setFieldValue: FormikProps<FormValues>["setFieldValue"];
  };
  readonly rowIndex: number;
  readonly dataSourceColumns: readonly Option[];
  readonly field: FieldToMap;
  readonly fieldType: "additional" | "inherited";
  readonly fieldDataType: string | undefined;
  readonly folioContainers: readonly OrgsGet200ResponseContainersInner[] | undefined;
  readonly folioTemplateOptions: readonly Option[];
  readonly testProps: OPTestProps;
};

// this has to be handled whether the json feature flag is enabled or not, as it is possible to run into templates with json fields regardless
const MAPPABLE_TO_OPTIONS = [
  { label: "Column", value: "column" },
  { label: "Container field", value: "containerField" },
];
const MAPPABLE_TO_OPTIONS_FOR_JSON = [{ label: "Column", value: "column" }];

export const MappableField = ({
  dataSourceColumns,
  field,
  fieldDataType,
  fieldType,
  folioContainers,
  folioTemplateOptions,
  formProps: { getFieldMeta, setFieldValue },
  rowIndex,
  testProps,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    fieldWrapper: {
      width: 208,
    },
  });

  const [keyToRerenderField, setKeyToRerenderField] = useState(0);

  const errorText = (fieldName: string) => {
    if (getFieldMeta(fieldName).touched && getFieldMeta(fieldName).error !== undefined) {
      return (
        <>
          <VerticalSpacer size={theme.spacing.s} />
          <Heading color={theme.colors.text.alert.critical} level="6">
            {getFieldMeta(fieldName).error}
          </Heading>
        </>
      );
    }

    return <></>;
  };

  const getFolioFieldOptions = () => {
    const folio = folioContainers?.find(({ id }) => id === field.containerID);

    if (None(folio)) {
      throw new Error("Trying to find fields on folio which is not found");
    }

    return (
      folio.fields?.map(({ name, templateFieldID }) => ({
        label: name ?? "",
        value: templateFieldID ?? "",
      })) ?? []
    );
  };

  const mappableToOptions =
    fieldDataType === "JSON" ? MAPPABLE_TO_OPTIONS_FOR_JSON : MAPPABLE_TO_OPTIONS;

  return (
    <>
      <View style={styles.fieldWrapper}>
        <Select
          defaultValue={mappableToOptions.find(_ => _.value === field.mappedTo)}
          label=""
          onSelectOption={({ value }) => {
            setFieldValue(`${fieldType}[${rowIndex}].mappedTo`, value);
            if (value !== field.mappedTo) {
              setFieldValue(`${fieldType}[${rowIndex}].columnID`, "");
              setFieldValue(`${fieldType}[${rowIndex}].containerID`, "");
            }
          }}
          options={mappableToOptions}
          placeholder={{
            label: "Column",
            value: "",
          }}
          showLabel={false}
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}MappableTo`,
          }}
          usePortal
        />
        {errorText(`${fieldType}[${rowIndex}].mappedTo`)}
      </View>
      <HorizontalSpacer size={48} />

      {field.mappedTo === "column" ? (
        <View style={styles.fieldWrapper}>
          <Select
            defaultValue={dataSourceColumns.find(_ => _.value === field.columnID)}
            getOptionValue={option => option.label}
            label=""
            onSelectOption={({ value }) => {
              setFieldValue(`${fieldType}[${rowIndex}].columnID`, value);
            }}
            options={dataSourceColumns}
            placeholder={{
              label: "Column name",
              value: "",
            }}
            showLabel={false}
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}Source`,
            }}
            usePortal
          />
          {errorText(`${fieldType}[${rowIndex}].columnID`)}
        </View>
      ) : (
        <>
          <View style={styles.fieldWrapper}>
            <Select
              defaultValue={folioTemplateOptions.find(_ => _.value === field.containerID)}
              getOptionValue={option => option.label}
              label=""
              onSelectOption={({ value }) => {
                if (field.containerID !== value) {
                  setFieldValue(`${fieldType}[${rowIndex}].containerFieldID`, "");
                  setKeyToRerenderField(prevKey => prevKey + 1);
                }
                setFieldValue(`${fieldType}[${rowIndex}].containerID`, value);
              }}
              options={folioTemplateOptions}
              placeholder={{
                label: "Folio",
                value: "",
              }}
              showLabel={false}
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}Source`,
              }}
              usePortal
            />
            {errorText(`${fieldType}[${rowIndex}].containerID`)}
          </View>
          {Some(field.containerID) && field.containerID !== "" && (
            <>
              <HorizontalSpacer />
              <View style={styles.fieldWrapper}>
                <Select
                  defaultValue={getFolioFieldOptions().find(
                    _ => _.value === field.containerFieldID
                  )}
                  key={keyToRerenderField}
                  label=""
                  onSelectOption={({ value }) => {
                    setFieldValue(`${fieldType}[${rowIndex}].containerFieldID`, value);
                  }}
                  options={getFolioFieldOptions()}
                  placeholder={{
                    label: "Field",
                    value: "",
                  }}
                  showLabel={false}
                  testProps={{
                    ...testProps,
                    elementName: `${testProps.elementName}Field`,
                  }}
                  usePortal
                />
                {errorText(`${fieldType}[${rowIndex}].containerFieldID`)}
              </View>
            </>
          )}
        </>
      )}
    </>
  );
};
