/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Agent } from './Agent';
import {
    AgentFromJSON,
    AgentFromJSONTyped,
    AgentToJSON,
} from './Agent';

/**
 * An object that contains a list of agents.
 * @export
 * @interface AgentList
 */
export interface AgentList {
    /**
     * The agent list.
     * @type {Array<Agent>}
     * @memberof AgentList
     */
    agents?: Array<Agent>;
    /**
     * Whether there are more agents beyond the end of this request
     * @type {boolean}
     * @memberof AgentList
     */
    hasMore?: boolean;
}

/**
 * Check if a given object implements the AgentList interface.
 */
export function instanceOfAgentList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgentListFromJSON(json: any): AgentList {
    return AgentListFromJSONTyped(json, false);
}

export function AgentListFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agents': !exists(json, 'agents') ? undefined : ((json['agents'] as Array<any>).map(AgentFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
    };
}

export function AgentListToJSON(value?: AgentList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agents': value.agents === undefined ? undefined : ((value.agents as Array<any>).map(AgentToJSON)),
        'hasMore': value.hasMore,
    };
}

