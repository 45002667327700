/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Holds information on a setup test error
 * @export
 * @interface IntegrationSetupTestError
 */
export interface IntegrationSetupTestError {
    /**
     * The title of the test
     * @type {string}
     * @memberof IntegrationSetupTestError
     */
    title?: string;
    /**
     * The message describing the failure
     * @type {string}
     * @memberof IntegrationSetupTestError
     */
    message?: string;
}

/**
 * Check if a given object implements the IntegrationSetupTestError interface.
 */
export function instanceOfIntegrationSetupTestError(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntegrationSetupTestErrorFromJSON(json: any): IntegrationSetupTestError {
    return IntegrationSetupTestErrorFromJSONTyped(json, false);
}

export function IntegrationSetupTestErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationSetupTestError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function IntegrationSetupTestErrorToJSON(value?: IntegrationSetupTestError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'message': value.message,
    };
}

