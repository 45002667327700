// Copyright 2024 Merit International Inc. All Rights Reserved

import { ErrorContent } from "@src/components/ErrorContent";
import { Heading } from "@merit/frontend-components";
import { ResponseError } from "@src/gen/org-portal";
import { Spin } from "@src/components";
import { StyleSheet, View } from "react-native";
import { isValidUrl } from "@src/utils/validateURL";
import { useApi } from "@src/api/api";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useLoggedInUserRoles } from "@src/hooks/loggedInUserRoles";
import React, { useEffect, useMemo, useState } from "react";

type Props = NonNullable<unknown>;

const SCREEN_NAME = "ReportsScreen";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
});

export const ReportsScreen = ({}: Props) => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { isDashboardAdmin, isOrgAdmin } = useLoggedInUserRoles();
  const [url, setUrl] = useState<string>("");
  const [errorStatusCode, setErrorStatusCode] = useState<number>();
  const { DefaultLayout } = useFlaggedLayout();
  const [isLoading, setIsLoading] = useState(true);

  const { api } = useApi();

  useEffect(() => {
    if (!(isDashboardAdmin || isOrgAdmin)) {
      return;
    }

    const fetchUrl = async () => {
      try {
        const response = await api.getSigmaEmbedURL({
          orgID: selectedOrgId,
        });

        setErrorStatusCode(undefined);
        setUrl(response.url);
      } catch (err) {
        if (err instanceof ResponseError) {
          setErrorStatusCode(err.response.status);
        } else {
          setErrorStatusCode(0); // Arbitrary status code to display error
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchUrl();
  }, [api, selectedOrgId, isDashboardAdmin, isOrgAdmin]);

  const renderReports = useMemo(() => {
    if (isLoading) {
      return (
        <View style={styles.container}>
          <Spin />
        </View>
      );
    }

    if (!isValidUrl(url)) {
      return (
        <View style={styles.container}>
          <Heading center level="1">
            Invalid URL: The page could not be loaded due to an incorrect URL configuration. Please
            contact admin.
          </Heading>
        </View>
      );
    }

    return <iframe frameBorder="0" height="100%" src={url} />;
  }, [isLoading, url]);

  return (
    <DefaultLayout
      breadcrumbs={[{ name: "Create & Configure" }, { name: "Reports" }]}
      testProps={{ elementName: "reports", screenName: SCREEN_NAME }}
      title="Reports"
    >
      {errorStatusCode === undefined ? (
        <View style={{ flex: 1 }}>{renderReports}</View>
      ) : (
        <ErrorContent statusCode={errorStatusCode} />
      )}
    </DefaultLayout>
  );
};
