/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInner } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInner';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInner';
import type { OrgsGet200ResponseContainersInnerFieldsInner } from './OrgsGet200ResponseContainersInnerFieldsInner';
import {
    OrgsGet200ResponseContainersInnerFieldsInnerFromJSON,
    OrgsGet200ResponseContainersInnerFieldsInnerFromJSONTyped,
    OrgsGet200ResponseContainersInnerFieldsInnerToJSON,
} from './OrgsGet200ResponseContainersInnerFieldsInner';
import type { OrgsGet200ResponseContainersInnerIssuer } from './OrgsGet200ResponseContainersInnerIssuer';
import {
    OrgsGet200ResponseContainersInnerIssuerFromJSON,
    OrgsGet200ResponseContainersInnerIssuerFromJSONTyped,
    OrgsGet200ResponseContainersInnerIssuerToJSON,
} from './OrgsGet200ResponseContainersInnerIssuer';
import type { OrgsGet200ResponseContainersInnerRecipient } from './OrgsGet200ResponseContainersInnerRecipient';
import {
    OrgsGet200ResponseContainersInnerRecipientFromJSON,
    OrgsGet200ResponseContainersInnerRecipientFromJSONTyped,
    OrgsGet200ResponseContainersInnerRecipientToJSON,
} from './OrgsGet200ResponseContainersInnerRecipient';
import type { OrgsGet200ResponseContainersInnerState } from './OrgsGet200ResponseContainersInnerState';
import {
    OrgsGet200ResponseContainersInnerStateFromJSON,
    OrgsGet200ResponseContainersInnerStateFromJSONTyped,
    OrgsGet200ResponseContainersInnerStateToJSON,
} from './OrgsGet200ResponseContainersInnerState';

/**
 * A collection of data issued to an entity.
 * @export
 * @interface OrgsGet200ResponseContainersInner
 */
export interface OrgsGet200ResponseContainersInner {
    /**
     * The id of this object.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInner
     */
    id: string;
    /**
     * The id of the template that generated this container.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInner
     */
    templateId?: string;
    /**
     * The name of the template that generated this container.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInner
     */
    name?: string;
    /**
     * The description of the template that generated this container.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInner
     */
    description?: string;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInnerIssuer}
     * @memberof OrgsGet200ResponseContainersInner
     */
    issuer?: OrgsGet200ResponseContainersInnerIssuer;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInnerRecipient}
     * @memberof OrgsGet200ResponseContainersInner
     */
    recipient?: OrgsGet200ResponseContainersInnerRecipient | null;
    /**
     * Whether the container is valid.
     * @type {boolean}
     * @memberof OrgsGet200ResponseContainersInner
     */
    completed?: boolean;
    /**
     * A list of errors that explain failed completeness rules.
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInner>}
     * @memberof OrgsGet200ResponseContainersInner
     */
    completenessFailures?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInner>;
    /**
     * Whether the container is active.
     * @type {boolean}
     * @memberof OrgsGet200ResponseContainersInner
     */
    active?: boolean;
    /**
     * A list of errors that explain failed activeness rules.
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInner>}
     * @memberof OrgsGet200ResponseContainersInner
     */
    activenessFailures?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInner>;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInnerState}
     * @memberof OrgsGet200ResponseContainersInner
     */
    state?: OrgsGet200ResponseContainersInnerState;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInner
     */
    createdAt?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrgsGet200ResponseContainersInner
     */
    acceptedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrgsGet200ResponseContainersInner
     */
    authorizedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrgsGet200ResponseContainersInner
     */
    rejectedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrgsGet200ResponseContainersInner
     */
    updatedAt?: Date | null;
    /**
     * Field level values.
     * @type {Array<OrgsGet200ResponseContainersInnerFieldsInner>}
     * @memberof OrgsGet200ResponseContainersInner
     */
    fields?: Array<OrgsGet200ResponseContainersInnerFieldsInner>;
}

/**
 * Check if a given object implements the OrgsGet200ResponseContainersInner interface.
 */
export function instanceOfOrgsGet200ResponseContainersInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function OrgsGet200ResponseContainersInnerFromJSON(json: any): OrgsGet200ResponseContainersInner {
    return OrgsGet200ResponseContainersInnerFromJSONTyped(json, false);
}

export function OrgsGet200ResponseContainersInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200ResponseContainersInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'issuer': !exists(json, 'issuer') ? undefined : OrgsGet200ResponseContainersInnerIssuerFromJSON(json['issuer']),
        'recipient': !exists(json, 'recipient') ? undefined : OrgsGet200ResponseContainersInnerRecipientFromJSON(json['recipient']),
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'completenessFailures': !exists(json, 'completenessFailures') ? undefined : ((json['completenessFailures'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSON)),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'activenessFailures': !exists(json, 'activenessFailures') ? undefined : ((json['activenessFailures'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSON)),
        'state': !exists(json, 'state') ? undefined : OrgsGet200ResponseContainersInnerStateFromJSON(json['state']),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'acceptedAt': !exists(json, 'acceptedAt') ? undefined : (json['acceptedAt'] === null ? null : new Date(json['acceptedAt'])),
        'authorizedAt': !exists(json, 'authorizedAt') ? undefined : (json['authorizedAt'] === null ? null : new Date(json['authorizedAt'])),
        'rejectedAt': !exists(json, 'rejectedAt') ? undefined : (json['rejectedAt'] === null ? null : new Date(json['rejectedAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(OrgsGet200ResponseContainersInnerFieldsInnerFromJSON)),
    };
}

export function OrgsGet200ResponseContainersInnerToJSON(value?: OrgsGet200ResponseContainersInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'templateId': value.templateId,
        'name': value.name,
        'description': value.description,
        'issuer': OrgsGet200ResponseContainersInnerIssuerToJSON(value.issuer),
        'recipient': OrgsGet200ResponseContainersInnerRecipientToJSON(value.recipient),
        'completed': value.completed,
        'completenessFailures': value.completenessFailures === undefined ? undefined : ((value.completenessFailures as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerToJSON)),
        'active': value.active,
        'activenessFailures': value.activenessFailures === undefined ? undefined : ((value.activenessFailures as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerToJSON)),
        'state': OrgsGet200ResponseContainersInnerStateToJSON(value.state),
        'createdAt': value.createdAt,
        'acceptedAt': value.acceptedAt === undefined ? undefined : (value.acceptedAt === null ? null : value.acceptedAt.toISOString()),
        'authorizedAt': value.authorizedAt === undefined ? undefined : (value.authorizedAt === null ? null : value.authorizedAt.toISOString()),
        'rejectedAt': value.rejectedAt === undefined ? undefined : (value.rejectedAt === null ? null : value.rejectedAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(OrgsGet200ResponseContainersInnerFieldsInnerToJSON)),
    };
}

