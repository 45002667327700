/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Mapping of a DataSource column to an Integration table column, as represented on a datasource mapping body.
 * @export
 * @interface ColumnIntegrationMappingProperties
 */
export interface ColumnIntegrationMappingProperties {
    /**
     * The id of the column belonging to the DataSource
     * @type {string}
     * @memberof ColumnIntegrationMappingProperties
     */
    columnID: string | null;
    /**
     * The name of the column from the Integration
     * @type {string}
     * @memberof ColumnIntegrationMappingProperties
     */
    columnName: string;
}

/**
 * Check if a given object implements the ColumnIntegrationMappingProperties interface.
 */
export function instanceOfColumnIntegrationMappingProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "columnID" in value;
    isInstance = isInstance && "columnName" in value;

    return isInstance;
}

export function ColumnIntegrationMappingPropertiesFromJSON(json: any): ColumnIntegrationMappingProperties {
    return ColumnIntegrationMappingPropertiesFromJSONTyped(json, false);
}

export function ColumnIntegrationMappingPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnIntegrationMappingProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnID': json['columnID'],
        'columnName': json['columnName'],
    };
}

export function ColumnIntegrationMappingPropertiesToJSON(value?: ColumnIntegrationMappingProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnID': value.columnID,
        'columnName': value.columnName,
    };
}

