// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer, VerticalSpacer } from "../Spacer";
import { Spin } from "../Spin";
import { StyleSheet, View } from "react-native";
import type { ReactNode } from "react";

type Datum = {
  readonly title: string;
  readonly value: number;
};

type Props = {
  readonly header?: ReactNode;
  readonly data?: readonly Datum[];
};

export const AnalyticsTable = ({ data, header }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      width: 656,
    },
    header: {
      alignItems: "center",
      backgroundColor: theme.colors.surface.subdued,
      borderRadius: theme.borderRadii.s,
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    horizontalLine: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
    },
    spinnerContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      minHeight: header === undefined ? 80 : 117,
    },
    stat: {
      borderColor: "red",
      // borderWidth: 1,
      flex: 1,
    },
    statsContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    verticalLine: {
      backgroundColor: theme.colors.border.default,
      height: 40,
      width: 1,
    },
  });

  if (data === undefined) {
    return (
      <View style={styles.container}>
        <View style={styles.spinnerContainer}>
          <Spin color={theme.colors.icon.alert.success} size={20} />
          <HorizontalSpacer size={theme.spacing.l} />
          <Body size="l">Loading analytics</Body>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {header !== undefined && (
        <>
          <View style={styles.header}>
            <Body>{header}</Body>
          </View>
          <View style={styles.horizontalLine} />
        </>
      )}

      <View style={styles.statsContainer}>
        {data.map((d, index) => (
          <>
            <View style={styles.stat}>
              <Heading level="3">{d.value}</Heading>
              <VerticalSpacer size={theme.spacing.xs} />
              <Body>{d.title}</Body>
            </View>

            {index < data.length - 1 && (
              <>
                <HorizontalSpacer size={32} />
                <View style={styles.verticalLine} />
                <HorizontalSpacer size={32} />
              </>
            )}
          </>
        ))}
      </View>
    </View>
  );
};
