/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Mapping of a template field to a column, as represented on a datasource mapping body.
 * @export
 * @interface TemplateFieldColumnMappingProperties
 */
export interface TemplateFieldColumnMappingProperties {
    /**
     * 
     * @type {string}
     * @memberof TemplateFieldColumnMappingProperties
     */
    templateFieldID: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateFieldColumnMappingProperties
     */
    columnID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateFieldColumnMappingProperties
     */
    containerID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateFieldColumnMappingProperties
     */
    containerFieldID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateFieldColumnMappingProperties
     */
    metadataMapping?: TemplateFieldColumnMappingPropertiesMetadataMappingEnum | null;
}


/**
 * @export
 */
export const TemplateFieldColumnMappingPropertiesMetadataMappingEnum = {
    RecipientName: 'recipientName'
} as const;
export type TemplateFieldColumnMappingPropertiesMetadataMappingEnum = typeof TemplateFieldColumnMappingPropertiesMetadataMappingEnum[keyof typeof TemplateFieldColumnMappingPropertiesMetadataMappingEnum];


/**
 * Check if a given object implements the TemplateFieldColumnMappingProperties interface.
 */
export function instanceOfTemplateFieldColumnMappingProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateFieldID" in value;

    return isInstance;
}

export function TemplateFieldColumnMappingPropertiesFromJSON(json: any): TemplateFieldColumnMappingProperties {
    return TemplateFieldColumnMappingPropertiesFromJSONTyped(json, false);
}

export function TemplateFieldColumnMappingPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateFieldColumnMappingProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldID': json['templateFieldID'],
        'columnID': !exists(json, 'columnID') ? undefined : json['columnID'],
        'containerID': !exists(json, 'containerID') ? undefined : json['containerID'],
        'containerFieldID': !exists(json, 'containerFieldID') ? undefined : json['containerFieldID'],
        'metadataMapping': !exists(json, 'metadataMapping') ? undefined : json['metadataMapping'],
    };
}

export function TemplateFieldColumnMappingPropertiesToJSON(value?: TemplateFieldColumnMappingProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldID': value.templateFieldID,
        'columnID': value.columnID,
        'containerID': value.containerID,
        'containerFieldID': value.containerFieldID,
        'metadataMapping': value.metadataMapping,
    };
}

