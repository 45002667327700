// Copyright 2024 Merit International Inc. All Rights Reserved

import { LegacyLandingScreen } from "./LegacyLanding";
import { OrgAuthLandingScreen } from "./OrgAuthLanding";
import { useApi } from "../api/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLogin } from "../hooks/auth";
import { useServerErrorHandler } from "../utils/useServerErrorHandler";
import type { LDFeatureFlags } from "../configuration/featureFlags";

export const LandingScreen = () => {
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();
  const { doLoginFlow: promptLogin } = useLogin();

  const { rolloutAdminPortalAuth0OrgAuth } = useFlags<LDFeatureFlags>();
  if (!rolloutAdminPortalAuth0OrgAuth) {
    return LegacyLandingScreen(api, errorHandler);
  }

  return OrgAuthLandingScreen(promptLogin);
};
