/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField
 */
export interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField {
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField
     */
    containerId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField
     */
    containerFieldId?: string;
}

/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField {
    return GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerId': !exists(json, 'containerId') ? undefined : json['containerId'],
        'containerFieldId': !exists(json, 'containerFieldId') ? undefined : json['containerFieldId'],
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerFieldToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedContainerField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerId': value.containerId,
        'containerFieldId': value.containerFieldId,
    };
}

