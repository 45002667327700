// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { Drawer } from "../../components/Drawer";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, Spin, VerticalSpacer } from "../../components";
import { ImmutablePolicyRule } from "./ImmutablePolicyRule";
import { MAX_RULES_PER_POLICY } from "./constants";
import { MeritTemplatesList } from "./MeritTemplatesList";
import {
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum as PermissionsActionEnum,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum as Predicate,
} from "../../gen/org-portal";
import { PolicyForm } from "./PolicyForm";
import { PolicyRule } from "./PolicyRule";
import { ScrollView, StyleSheet, View } from "react-native";
import { SelectPermissions } from "../../components/SelectPermissions";
import { useAlertStore } from "../../stores";
import { useApi } from "../../api/api";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useTemplatesData } from "./useTemplatesData";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import type { FormValues } from "./PolicyForm";
import type { FormikProps } from "formik";
import type {
  GetPolicies200ResponsePoliciesInner,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom,
} from "../../gen/org-portal";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { Permissions } from "./CreatePolicyDeprecated";
import type { PostLoginRouteParams } from "../../Router";
import type { ReactNode } from "react";
import type { RouteProp } from "@react-navigation/native";
import type { Template } from "./PolicyRule";

const { None, Some } = Helpers;

type Route = RouteProp<PostLoginRouteParams, "EditPolicy" | "ExtendPolicy">;
type Navigation = NativeStackNavigationProp<PostLoginRouteParams, "EditPolicy">;
type PermissionsValue = "no" | "yes";

export type Rule = OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom & {
  readonly id: string;
};

export const EditPolicyDeprecatedScreen = () => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();
  const [loading, setLoading] = useState(false);
  const route = useRoute<Route>();
  const navigation = useNavigation<Navigation>();
  const { id: policyId } = route.params;
  const [policy, setPolicy] = useState<GetPolicies200ResponsePoliciesInner>();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const scrollViewRef = useRef<ScrollView>(null);
  const [rules, setRules] = useState<readonly Rule[]>([]);
  const [ruleOpenInDrawer, setRuleOpenInDrawer] = useState<string>();
  const [confirmationModal, setConfirmationModal] = useState<ReactNode>();
  const { deleteAlert, setAlert } = useAlertStore();
  const [permissions, setPermissions] = useState<Permissions>({
    extendPermission: "yes",
    readPermission: "yes",
  });

  const SCREEN_NAME = route.name;

  const toCamelCase = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

  const styles = StyleSheet.create({
    contentContainer: {
      alignSelf: "center",
      paddingVertical: 32,
      width: 960,
    },
    divider: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      width: "100%",
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.subdued,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
  });

  const { isLoading, templates } = useTemplatesData(api, {
    limit: 100,
    orgID: selectedOrgId,
    status: "live",
    type: "Merit",
  });

  const getExistingPermission = (
    existingPermissions: GetPolicies200ResponsePoliciesInner
  ): {
    readonly extendPermission: PermissionsValue;
    readonly readPermission: PermissionsValue;
  } => {
    // Used permissions instead of shareablePermissions since it gets incorrect values
    const policyPermissions = existingPermissions.permissions;

    if (None(policyPermissions)) {
      return {
        extendPermission: "no",
        readPermission: "no",
      };
    }

    const perms = policyPermissions
      .map(permission => {
        if (permission.permitted.grantedToName === "All") {
          return permission.action;
        }

        return undefined;
      })
      .filter(_ => Some(_));

    if (perms.length <= 0) {
      return {
        extendPermission: "no",
        readPermission: "no",
      };
    }

    if (perms.includes("extend")) {
      return {
        extendPermission: "yes",
        readPermission: "yes",
      };
    }

    return {
      extendPermission: "no",
      readPermission: "yes",
    };
  };

  useEffect(() => {
    const getPolicy = async () => {
      try {
        setLoading(true);
        if (Some(selectedOrgId) && Some(policyId)) {
          const response = await api.getPolicy({ orgID: selectedOrgId, policyID: policyId });
          setPolicy(response);
          if (response.rules?.own !== undefined && route.name === "EditPolicy") {
            setRules(response.rules.own.map(rule => ({ ...rule, id: uuidv4() })));
          }
          setPermissions(getExistingPermission(response));
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    getPolicy();
  }, [api, errorHandler, policyId, route.name, selectedOrgId]);

  const rulesPayload = () =>
    rules
      .filter(rule => Some(rule.arguments) && rule.arguments.length > 1)
      .map(rule => ({
        ...rule,
        id: undefined,
        predicate: Some(rule.predicate)
          ? rule.predicate
          : Predicate.ReceivedXContainersFromTemplates,
      }));

  const getPermissions = () => {
    const readPermission = permissions.readPermission === "yes" ? "All" : "None";
    const extendPermission =
      permissions.readPermission === "yes" && permissions.extendPermission === "yes"
        ? "All"
        : "None";

    return [
      {
        action: PermissionsActionEnum.Read,
        permissibleToPermit: {
          action: PermissionsActionEnum.Read,
          grantedToName: readPermission,
        },
        permitted: {
          action: PermissionsActionEnum.Read,
          grantedToName: readPermission,
        },
      },
      {
        action: PermissionsActionEnum.Extend,
        permissibleToPermit: {
          action: PermissionsActionEnum.Extend,
          grantedToName: extendPermission,
        },
        permitted: {
          action: PermissionsActionEnum.Extend,
          grantedToName: extendPermission,
        },
      },
    ];
  };

  const handleEditPolicy = async () => {
    try {
      setLoading(true);
      await api.editPolicy({
        orgID: selectedOrgId,
        policy: {
          description: formRef.current?.values.description,
          name: formRef.current?.values.name,
          permissions: getPermissions(),
          rule: rulesPayload(),
        },
        policyID: policyId,
      });

      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "updatePolicySuccess",
          screenName: SCREEN_NAME,
        },
        text: `Policy has been updated`,
        type: "success",
      });

      setRules([]);
      navigation.navigate("Policies");
    } catch (error) {
      errorHandler(error, {
        elementName: "updatePolicySuccess",
        screenName: SCREEN_NAME,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePressCancel = () => {
    if (
      (Some(formRef.current) && formRef.current.dirty) ||
      (Some(rules[0]?.arguments) && rules[0]?.arguments.length > 0) ||
      rules.length > 1
    ) {
      setConfirmationModal(
        <ConfirmationModal
          onClose={() => {
            setConfirmationModal(undefined);
          }}
          onOk={() => {
            setConfirmationModal(undefined);
            if (navigation.canGoBack()) {
              navigation.goBack();

              return;
            }

            navigation.navigate("Policies");
          }}
          testProps={{
            elementName: `${toCamelCase(SCREEN_NAME)}UnSavedConfirmation`,
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      );

      return;
    }

    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Policies");
  };

  const handleExtendPolicy = async () => {
    if (None(formRef.current)) {
      return;
    }

    const name = formRef.current.values.name;
    const description = formRef.current.values.description;

    try {
      setLoading(true);
      await api.extendPolicy({
        orgID: selectedOrgId,
        properties: {
          description,
          falseMessage: "",
          name,
          permissions: getPermissions(),
          rule: rulesPayload(),
          sourcePolicyID: policyId,
          trueMessage: "",
        },
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "createPolicySuccess",
          screenName: SCREEN_NAME,
        },
        text: `New policy has been added`,
        type: "success",
      });

      navigation.navigate("Policies");
    } catch (error) {
      errorHandler(error, {
        elementName: "createPolicyError",
        screenName: SCREEN_NAME,
      });
    } finally {
      setLoading(false);
    }
  };

  const validateData = async (): Promise<boolean> => {
    await formRef.current?.submitForm();
    if (Some(formRef.current) && !formRef.current.isValid) {
      return false;
    }

    return true;
  };

  const handlePressSave = async () => {
    const isDataValid = await validateData();
    if (isDataValid) {
      if (route.name === "ExtendPolicy") {
        handleExtendPolicy();

        return;
      }

      setConfirmationModal(
        <ConfirmationModal
          buttonText="save"
          onClose={() => {
            setConfirmationModal(undefined);
          }}
          onOk={() => {
            setConfirmationModal(undefined);
            handleEditPolicy();
          }}
          testProps={{
            elementName: `${toCamelCase(SCREEN_NAME)}SaveConfirmation`,
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to save? Saving this change will impact any org that has extended this template from you."
          title="Save policy"
        />
      );
    }
  };

  const addTemplateForRule = (template: Template) => {
    setRules(prevRules =>
      prevRules.map(rule => {
        if (rule.id === ruleOpenInDrawer) {
          const templateData = rule.arguments?.find(id => id === template.id);
          if (Some(templateData)) {
            setAlert({
              closable: true,
              id: uuidv4(),
              onPressDelete: id => {
                deleteAlert(id);
              },
              testProps: {
                elementName: "addTemplateError",
                screenName: SCREEN_NAME,
              },
              text: `Template already added to the policy.`,
              type: "error",
            });

            return rule;
          }

          const updatedRule = {
            arguments: Some(rule.arguments) ? [...rule.arguments, template.id] : [template.id],
            id: rule.id,
          };
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            testProps: {
              elementName: "addTemplateSuccess",
              screenName: SCREEN_NAME,
            },
            text: `Template added to policy.`,
            type: "success",
          });

          return updatedRule;
        }

        return rule;
      })
    );
  };

  const inheritedRules = useMemo(() => {
    if (None(policy)) {
      return [];
    }

    if (route.name === "ExtendPolicy" && Some(policy.rules)) {
      return [...(policy.rules.inherited?.flat() ?? []), ...(policy.rules.own?.flat() ?? [])];
    }

    return [...(policy.rules?.inherited?.flat() ?? [])];
  }, [policy, route.name]);

  if (policyId.length !== 36) {
    return <Body>Invalid Policy ID</Body>;
  }

  if (loading || isLoading || policy === undefined) {
    return (
      <View
        style={{
          backgroundColor: theme.colors.background.white,
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  return (
    <>
      <FullScreenModalLayout
        onClose={() => {
          handlePressCancel();
        }}
        testProps={{
          elementName: toCamelCase(SCREEN_NAME),
          screenName: SCREEN_NAME,
        }}
        title={route.name === "ExtendPolicy" ? `Extend ${policy.name}` : `Edit ${policy.name}`}
      >
        <>
          <ScrollView ref={scrollViewRef} style={{ flex: 1 }}>
            <View style={styles.contentContainer}>
              <PolicyForm
                formRef={formRef}
                initialValues={{
                  description: policy.description ?? "",
                  name: route.name === "ExtendPolicy" ? `Extension of ${policy.name}` : policy.name,
                }}
                testProps={{
                  elementName: toCamelCase(SCREEN_NAME),
                  screenName: SCREEN_NAME,
                }}
              />
              <VerticalSpacer size={60} />
              <View style={styles.divider} />
              <VerticalSpacer size={60} />

              <View
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Heading
                  bold
                  level="3"
                  testProps={{
                    elementName: `${toCamelCase(SCREEN_NAME)}RulesHeaderText`,
                    screenName: SCREEN_NAME,
                  }}
                >
                  Rules
                </Heading>
                <View style={{ width: 180 }}>
                  <Button
                    disabled={inheritedRules.length + rules.length >= MAX_RULES_PER_POLICY}
                    iconLeft="addSmallDefault"
                    onPress={() => {
                      setRules(prevRules => [...prevRules, { arguments: ["1"], id: uuidv4() }]);
                      setTimeout(() => {
                        scrollViewRef.current?.scrollToEnd();
                      }, 100);
                    }}
                    size="small"
                    testProps={{
                      elementName: `${toCamelCase(SCREEN_NAME)}AddRuleButton`,
                      screenName: SCREEN_NAME,
                    }}
                    text="Add rule"
                    type="secondary"
                  />
                </View>
              </View>

              <VerticalSpacer size={40} />

              <>
                <Heading level="4">Inherited</Heading>

                <VerticalSpacer size={20} />

                {inheritedRules.length === 0 && (
                  <>
                    <Body
                      testProps={{
                        elementName: `${toCamelCase(SCREEN_NAME)}NoInheritedRulesPlaceholder`,
                        screenName: SCREEN_NAME,
                      }}
                    >
                      This policy does not have any inherited rules
                    </Body>
                  </>
                )}

                {inheritedRules.map((rule, index) => (
                  <ImmutablePolicyRule
                    key={JSON.stringify(rule)}
                    rule={rule}
                    ruleIndex={index}
                    templatesList={templates}
                    testProps={{
                      elementName: `${toCamelCase(SCREEN_NAME)}InheritedRules`,
                      screenName: SCREEN_NAME,
                    }}
                  />
                ))}

                <VerticalSpacer size={40} />
                <View style={styles.divider} />
                <VerticalSpacer size={40} />
              </>

              <Heading level="4">Org Created</Heading>

              {rules.length === 0 && (
                <>
                  <VerticalSpacer size={20} />
                  <Body
                    testProps={{
                      elementName: `${toCamelCase(SCREEN_NAME)}NoOrgCreatedRulesPlaceholder`,
                      screenName: SCREEN_NAME,
                    }}
                  >
                    This policy does not have any org created rules
                  </Body>
                </>
              )}

              {rules.map((rule, index) => (
                <PolicyRule
                  key={JSON.stringify(rule)}
                  onPressAdditionIcon={() => {
                    setRuleOpenInDrawer(rule.id);
                  }}
                  onRemove={() => {
                    setConfirmationModal(
                      <ConfirmationModal
                        onClose={() => {
                          setConfirmationModal(undefined);
                        }}
                        onOk={() => {
                          setConfirmationModal(undefined);
                          setRules(prevRules => prevRules.filter(r => r.id !== rule.id));
                        }}
                        testProps={{
                          elementName: `${toCamelCase(SCREEN_NAME)}RuleRemoveConfirmation`,
                          screenName: SCREEN_NAME,
                        }}
                        text="By removing this rule, you will impact any event linked to this policy."
                        title="Are you sure you want to remove this rule?"
                      />
                    );
                  }}
                  removeTemplate={templateID => {
                    setRules(prevRules =>
                      prevRules.map(prevRule => {
                        if (prevRule.id === rule.id) {
                          return {
                            arguments: prevRule.arguments?.filter(id => id !== templateID),
                            id: rule.id,
                          };
                        }

                        return prevRule;
                      })
                    );
                  }}
                  rule={rule}
                  ruleIndex={index}
                  templatesList={templates}
                  testProps={{
                    elementName: toCamelCase(SCREEN_NAME),
                    screenName: SCREEN_NAME,
                  }}
                />
              ))}
              <VerticalSpacer size={40} />
              <View style={styles.divider} />
              <VerticalSpacer size={40} />
              <SelectPermissions
                onSelectOption={(permissionKey, value) => {
                  setPermissions(prev => ({ ...prev, [permissionKey]: value }));
                }}
                permissionFor="policy"
                testProps={{
                  elementName: `${toCamelCase(SCREEN_NAME)}Permission`,
                  screenName: SCREEN_NAME,
                }}
                values={permissions}
              />
            </View>
          </ScrollView>
          <View style={styles.footer}>
            <Button
              onPress={() => {
                handlePressCancel();
              }}
              testProps={{
                elementName: `${toCamelCase(SCREEN_NAME)}CancelButton`,
                screenName: SCREEN_NAME,
              }}
              text="Cancel"
              type="secondary"
            />
            <HorizontalSpacer size={16} />
            <Button
              onPress={() => {
                handlePressSave();
              }}
              testProps={{
                elementName: `${toCamelCase(SCREEN_NAME)}SaveButton`,
                screenName: SCREEN_NAME,
              }}
              text="Save"
            />
          </View>
        </>
      </FullScreenModalLayout>

      <Drawer isOpen={Some(ruleOpenInDrawer)}>
        <MeritTemplatesList
          closeDrawer={() => {
            setRuleOpenInDrawer(undefined);
          }}
          onSelect={addTemplateForRule}
          testProps={{
            elementName: toCamelCase(SCREEN_NAME),
            screenName: SCREEN_NAME,
          }}
        />
      </Drawer>

      {confirmationModal}
    </>
  );
};
