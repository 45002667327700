// Copyright 2024 Merit International Inc. All Rights Reserved

import { Field } from "formik";
import { MaskedTextInput, type MaskedTextInputProps } from "@src/components/MaskedTextInput";
import type { FC } from "react";
import type { FieldInputProps, FieldProps } from "formik";

type TextInputFieldProps = MaskedTextInputProps &
  Omit<FieldInputProps<unknown>, "onBlur" | "onChange" | "value">;

export const TextInputField: FC<TextInputFieldProps> = props => {
  const { name } = props;

  return (
    <Field {...props}>
      {({ field }: FieldProps) => (
        <MaskedTextInput
          {...props}
          {...field}
          onBlur={field.onBlur(name)}
          onChangeText={field.onChange(name)}
          value={field.value ?? ""}
        />
      )}
    </Field>
  );
};
