// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import React, { useRef } from "react";
import type { OPTestProps } from "../../../src/types/TestProps";

type Props = {
  readonly title: string;
  readonly type?: "primary" | "small";
  readonly testProps?: OPTestProps;
};

export const NavText = ({ testProps, title, type = "primary" }: Props) => {
  const ref = useRef(null);
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    base: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      overflow: "hidden",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.s,
    },
  });

  return (
    <View ref={ref} style={[styles.base]}>
      {type === "small" && (
        <Body numberOfLines={1} size="s" testProps={testProps}>
          {title}
        </Body>
      )}

      {type === "primary" && (
        <Body numberOfLines={1} size="l" testProps={testProps}>
          {title}
        </Body>
      )}
    </View>
  );
};
