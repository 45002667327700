/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProjectsProjectIDError403,
  ProjectsProjectIDExportJobsJobIDError403,
  ProjectsProjectIDExportJobsJobIDGETSuccess,
  ProjectsProjectIDExportPOSTRequest,
  ProjectsProjectIDExportPOSTSuccess,
} from '../models/index';
import {
    ProjectsProjectIDError403FromJSON,
    ProjectsProjectIDError403ToJSON,
    ProjectsProjectIDExportJobsJobIDError403FromJSON,
    ProjectsProjectIDExportJobsJobIDError403ToJSON,
    ProjectsProjectIDExportJobsJobIDGETSuccessFromJSON,
    ProjectsProjectIDExportJobsJobIDGETSuccessToJSON,
    ProjectsProjectIDExportPOSTRequestFromJSON,
    ProjectsProjectIDExportPOSTRequestToJSON,
    ProjectsProjectIDExportPOSTSuccessFromJSON,
    ProjectsProjectIDExportPOSTSuccessToJSON,
} from '../models/index';

export interface ProjectsProjectIdExportJobsJobIdGetRequest {
    jobId: string;
    projectId: string;
}

export interface ProjectsProjectIdExportPostRequest {
    projectId: string;
    projectsProjectIDExportPOSTRequest: ProjectsProjectIDExportPOSTRequest;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     * Retrieve export job progress
     */
    async projectsProjectIdExportJobsJobIdGetRaw(requestParameters: ProjectsProjectIdExportJobsJobIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsProjectIDExportJobsJobIDGETSuccess>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling projectsProjectIdExportJobsJobIdGet.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectsProjectIdExportJobsJobIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/export/jobs/{job_id}`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsProjectIDExportJobsJobIDGETSuccessFromJSON(jsonValue));
    }

    /**
     * Retrieve export job progress
     */
    async projectsProjectIdExportJobsJobIdGet(requestParameters: ProjectsProjectIdExportJobsJobIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsProjectIDExportJobsJobIDGETSuccess> {
        const response = await this.projectsProjectIdExportJobsJobIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an export job
     */
    async projectsProjectIdExportPostRaw(requestParameters: ProjectsProjectIdExportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsProjectIDExportPOSTSuccess>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectsProjectIdExportPost.');
        }

        if (requestParameters.projectsProjectIDExportPOSTRequest === null || requestParameters.projectsProjectIDExportPOSTRequest === undefined) {
            throw new runtime.RequiredError('projectsProjectIDExportPOSTRequest','Required parameter requestParameters.projectsProjectIDExportPOSTRequest was null or undefined when calling projectsProjectIdExportPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("UserAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AppAccessToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/export`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectsProjectIDExportPOSTRequestToJSON(requestParameters.projectsProjectIDExportPOSTRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsProjectIDExportPOSTSuccessFromJSON(jsonValue));
    }

    /**
     * Create an export job
     */
    async projectsProjectIdExportPost(requestParameters: ProjectsProjectIdExportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsProjectIDExportPOSTSuccess> {
        const response = await this.projectsProjectIdExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
