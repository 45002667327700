// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Pressable, StyleSheet, View } from "react-native";
import { useState } from "react";
import type { LayoutRectangle } from "react-native";
import type { OPTestProps } from "../../../src/types/TestProps";
import type { ReactNode } from "react";

const Some = Helpers.Some;

type TabItem = {
  readonly key: string;
  readonly label: ReactNode | string;
  readonly disabled?: boolean;
};

type TabsProps<T extends TabItem> = {
  readonly items: readonly T[];
  readonly selected: T["key"];
  readonly onChange?: (value: T["key"]) => void;
  readonly testProps?: OPTestProps;
};

export function Tabs<T extends TabItem>({ items, onChange, selected, testProps }: TabsProps<T>) {
  const { theme } = useTheme();

  type TabSizes = Record<string, LayoutRectangle>;
  const itemKeys = items.map(item => item.key);
  const initialTabSizes = itemKeys.reduce<TabSizes>(
    (prev, cur) => ({ ...prev, [cur]: { height: 0, width: 0, x: 0, y: 0 } }),
    {}
  );
  const [tabSizes, setTabSizes] = useState<TabSizes>(initialTabSizes);

  const styles = StyleSheet.create({
    item: {
      alignSelf: "flex-start",
      marginRight: theme.spacing.xxl,
      paddingVertical: theme.spacing.m,
    },
    wrapper: {
      borderRadius: theme.borderRadii.s,
      flexDirection: "row",
      overflow: "hidden",
      position: "relative",
    },
  });

  return (
    <View style={styles.wrapper}>
      {items.map(item => (
        <Pressable
          disabled={item.disabled}
          key={item.key}
          onLayout={e => {
            setTabSizes(prevState => ({
              ...prevState,
              [item.key]: e.nativeEvent.layout,
            }));
          }}
          onPress={() => {
            onChange?.(item.key);
          }}
          style={styles.item}
        >
          {typeof item.label === "string" ? (
            <Body
              color={Some(item.disabled) && item.disabled ? theme.colors.text.disabled : undefined}
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}${item.label}Tab`,
                    }
                  : testProps
              }
            >
              {item.label}
            </Body>
          ) : (
            item.label
          )}
        </Pressable>
      ))}
      <div
        style={{
          backgroundColor: "#00CCC0",
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          bottom: 0,
          height: 2,
          left: tabSizes[selected].x,
          position: "absolute",
          transition: "all 0.2s ease-out",
          width: tabSizes[selected].width,
        }}
      />
    </View>
  );
}
