/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Atom
 */
export interface Atom {
    /**
     * 
     * @type {string}
     * @memberof Atom
     */
    target?: string | null;
    /**
     * Predicates define how to evaluate an object. Each predicate defines its own list of arguments. Each 
     * predicate is presented with its arguments in the list below. The arguments must be strings, but 
     * the predicate attempts to parse the arguments into the given types. A `date` format, for example, could take 
     * the form `2024-05-15`. `None` means the predicate does not take arguments. `...` indicates multiple
     * arguments of the type are allowed. Arguments separated by pipes `|` indicate a choice of options. Arguments
     * in parentheses `()` are optional.
     * | Predicate | Arguments |
     * |-----------|-----------|
     * | AfterThisDate | [date] |
     * | AfterThisDatetime | [datetime] |
     * | AfterThisTimeOfDay | [time] |
     * | AfterToday | None |
     * | AfterNow | None |
     * | BeforeNow | None |
     * | BeforeNowMinusXDuration | [duration] |
     * | BeforeThisDate | [date] |
     * | BeforeThisDatetime | [datetime] |
     * | BeforeThisTimeOfDay | [time] |
     * | BeforeToday | None |
     * | BeforeTodayMinusXDays | [number] |
     * | EqualToX | [number] |
     * | EqualToDate | [date] |
     * | FieldHasValue | [any] |
     * | IsEmailDomain | None |
     * | IsFalse | None |
     * | IsTrue | None |
     * | LessThanX | [integer] |
     * | MatchesThisString | [string] |
     * | MoreThanX | [integer] |
     * | MappableTo | None |
     * | ReceivedXContainersFromTemplates | [number, UUID..., (active\|inactive\|both)]: the number of containers to match, the template IDs to match, and an optional container activeness |
     * | ReceivedContainersFromXTemplates | [number, UUID..., (active\|inactive\|both)]: the number of containers to match, the template IDs to match, and an optional container activeness |
     * 
     * @type {string}
     * @memberof Atom
     */
    predicate?: AtomPredicateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Atom
     */
    arguments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Atom
     */
    errorMessage?: string;
}


/**
 * @export
 */
export const AtomPredicateEnum = {
    AfterThisDate: 'AfterThisDate',
    AfterThisDatetime: 'AfterThisDatetime',
    AfterThisTimeOfDay: 'AfterThisTimeOfDay',
    AfterToday: 'AfterToday',
    AfterNow: 'AfterNow',
    BeforeNow: 'BeforeNow',
    BeforeNowMinusXDuration: 'BeforeNowMinusXDuration',
    BeforeThisDate: 'BeforeThisDate',
    BeforeThisDatetime: 'BeforeThisDatetime',
    BeforeThisTimeOfDay: 'BeforeThisTimeOfDay',
    BeforeToday: 'BeforeToday',
    BeforeTodayMinusXDays: 'BeforeTodayMinusXDays',
    EqualToX: 'EqualToX',
    EqualToDate: 'EqualToDate',
    FieldHasValue: 'FieldHasValue',
    IsEmailDomain: 'IsEmailDomain',
    IsFalse: 'IsFalse',
    IsTrue: 'IsTrue',
    LessThanX: 'LessThanX',
    MatchesThisString: 'MatchesThisString',
    MoreThanX: 'MoreThanX',
    MappableTo: 'MappableTo',
    ReceivedXContainersFromTemplates: 'ReceivedXContainersFromTemplates',
    ReceivedContainersFromXTemplates: 'ReceivedContainersFromXTemplates'
} as const;
export type AtomPredicateEnum = typeof AtomPredicateEnum[keyof typeof AtomPredicateEnum];


/**
 * Check if a given object implements the Atom interface.
 */
export function instanceOfAtom(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AtomFromJSON(json: any): Atom {
    return AtomFromJSONTyped(json, false);
}

export function AtomFromJSONTyped(json: any, ignoreDiscriminator: boolean): Atom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target': !exists(json, 'target') ? undefined : json['target'],
        'predicate': !exists(json, 'predicate') ? undefined : json['predicate'],
        'arguments': !exists(json, 'arguments') ? undefined : json['arguments'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function AtomToJSON(value?: Atom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target': value.target,
        'predicate': value.predicate,
        'arguments': value.arguments,
        'errorMessage': value.errorMessage,
    };
}

