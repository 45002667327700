/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityInfo } from './EntityInfo';
import {
    EntityInfoFromJSON,
    EntityInfoFromJSONTyped,
    EntityInfoToJSON,
} from './EntityInfo';
import type { FieldKindPropsValidation } from './FieldKindPropsValidation';
import {
    FieldKindPropsValidationFromJSON,
    FieldKindPropsValidationFromJSONTyped,
    FieldKindPropsValidationToJSON,
} from './FieldKindPropsValidation';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';
import type { TemplateField } from './TemplateField';
import {
    TemplateFieldFromJSON,
    TemplateFieldFromJSONTyped,
    TemplateFieldToJSON,
} from './TemplateField';
import type { TemplatePropsRules } from './TemplatePropsRules';
import {
    TemplatePropsRulesFromJSON,
    TemplatePropsRulesFromJSONTyped,
    TemplatePropsRulesToJSON,
} from './TemplatePropsRules';
import type { TemplateType } from './TemplateType';
import {
    TemplateTypeFromJSON,
    TemplateTypeFromJSONTyped,
    TemplateTypeToJSON,
} from './TemplateType';

/**
 * Representation of a named Template belonging to an Org.
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof Template
     */
    id: string;
    /**
     * 
     * @type {EntityInfo}
     * @memberof Template
     */
    owner: EntityInfo;
    /**
     * 
     * @type {Date}
     * @memberof Template
     */
    createdAt: Date;
    /**
     * The given name of this Template.
     * @type {string}
     * @memberof Template
     */
    name: string;
    /**
     * The description of this Template.
     * @type {string}
     * @memberof Template
     */
    description?: string;
    /**
     * 
     * @type {TemplateType}
     * @memberof Template
     */
    templateType: TemplateType;
    /**
     * The id of the Template this was extended from.
     * @type {string}
     * @memberof Template
     */
    parent?: string;
    /**
     * The ordered lineage of parent templates by their external id. The order is from direct parent to
     * grandparent. So if Template3 extended from Template2 which extended from Template1, Template3
     * would have a lineage array of Template2's ID and then Template1's ID.
     * 
     * @type {Array<string>}
     * @memberof Template
     */
    lineage?: Array<string>;
    /**
     * 
     * @type {TemplatePropsRules}
     * @memberof Template
     */
    rules?: TemplatePropsRules | null;
    /**
     * Whether the template is paused or live.
     * @type {string}
     * @memberof Template
     */
    state: TemplateStateEnum;
    /**
     * The list of template fields owned by this template.
     * @type {Array<TemplateField>}
     * @memberof Template
     */
    templateFields?: Array<TemplateField>;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof Template
     */
    permissions: Array<PermissionPair>;
    /**
     * 
     * @type {FieldKindPropsValidation}
     * @memberof Template
     */
    activeness?: FieldKindPropsValidation;
    /**
     * The datasource to which this Template is mapped.
     * @type {string}
     * @memberof Template
     */
    dataSourceID?: string | null;
    /**
     * Whether or not containers created from this template should be automatically approved.
     * @type {boolean}
     * @memberof Template
     */
    autoApprove?: boolean;
    /**
     * Whether or not containers are automatically removed when recipient email is changed.
     * @type {boolean}
     * @memberof Template
     */
    autoRemove?: boolean;
    /**
     * Deprecated. Use autoRemove property as replacement.
     * @type {boolean}
     * @memberof Template
     */
    autoRevoke?: boolean;
    /**
     * true if this template has been extended and has child templates
     * @type {boolean}
     * @memberof Template
     */
    hasChildren?: boolean;
}


/**
 * @export
 */
export const TemplateStateEnum = {
    Paused: 'paused',
    Live: 'live'
} as const;
export type TemplateStateEnum = typeof TemplateStateEnum[keyof typeof TemplateStateEnum];


/**
 * Check if a given object implements the Template interface.
 */
export function instanceOfTemplate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "templateType" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function TemplateFromJSON(json: any): Template {
    return TemplateFromJSONTyped(json, false);
}

export function TemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Template {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': EntityInfoFromJSON(json['owner']),
        'createdAt': (new Date(json['createdAt'])),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'templateType': TemplateTypeFromJSON(json['templateType']),
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'lineage': !exists(json, 'lineage') ? undefined : json['lineage'],
        'rules': !exists(json, 'rules') ? undefined : TemplatePropsRulesFromJSON(json['rules']),
        'state': json['state'],
        'templateFields': !exists(json, 'templateFields') ? undefined : ((json['templateFields'] as Array<any>).map(TemplateFieldFromJSON)),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'activeness': !exists(json, 'activeness') ? undefined : FieldKindPropsValidationFromJSON(json['activeness']),
        'dataSourceID': !exists(json, 'dataSourceID') ? undefined : json['dataSourceID'],
        'autoApprove': !exists(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRemove': !exists(json, 'autoRemove') ? undefined : json['autoRemove'],
        'autoRevoke': !exists(json, 'autoRevoke') ? undefined : json['autoRevoke'],
        'hasChildren': !exists(json, 'hasChildren') ? undefined : json['hasChildren'],
    };
}

export function TemplateToJSON(value?: Template | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner': EntityInfoToJSON(value.owner),
        'createdAt': (value.createdAt.toISOString()),
        'name': value.name,
        'description': value.description,
        'templateType': TemplateTypeToJSON(value.templateType),
        'parent': value.parent,
        'lineage': value.lineage,
        'rules': TemplatePropsRulesToJSON(value.rules),
        'state': value.state,
        'templateFields': value.templateFields === undefined ? undefined : ((value.templateFields as Array<any>).map(TemplateFieldToJSON)),
        'permissions': ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'activeness': FieldKindPropsValidationToJSON(value.activeness),
        'dataSourceID': value.dataSourceID,
        'autoApprove': value.autoApprove,
        'autoRemove': value.autoRemove,
        'autoRevoke': value.autoRevoke,
        'hasChildren': value.hasChildren,
    };
}

