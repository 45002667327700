/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner } from './GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner';
import {
    GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSON,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSONTyped,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerToJSON,
} from './GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner';

/**
 * Adds a capability to an agent with the restriction
 * @export
 * @interface CreateAgentRequestCapabilitiesInner
 */
export interface CreateAgentRequestCapabilitiesInner {
    /**
     * The capability to allow the agent
     * @type {string}
     * @memberof CreateAgentRequestCapabilitiesInner
     */
    capability: string;
    /**
     * Allows the agent to limit this capability to a subset of objects of the given type
     * @type {Array<GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner>}
     * @memberof CreateAgentRequestCapabilitiesInner
     */
    objects?: Array<GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInner> | null;
}

/**
 * Check if a given object implements the CreateAgentRequestCapabilitiesInner interface.
 */
export function instanceOfCreateAgentRequestCapabilitiesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "capability" in value;

    return isInstance;
}

export function CreateAgentRequestCapabilitiesInnerFromJSON(json: any): CreateAgentRequestCapabilitiesInner {
    return CreateAgentRequestCapabilitiesInnerFromJSONTyped(json, false);
}

export function CreateAgentRequestCapabilitiesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAgentRequestCapabilitiesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'capability': json['capability'],
        'objects': !exists(json, 'objects') ? undefined : (json['objects'] === null ? null : (json['objects'] as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerFromJSON)),
    };
}

export function CreateAgentRequestCapabilitiesInnerToJSON(value?: CreateAgentRequestCapabilitiesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'capability': value.capability,
        'objects': value.objects === undefined ? undefined : (value.objects === null ? null : (value.objects as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerObjectsInnerToJSON)),
    };
}

