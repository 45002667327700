/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Authenticate200Response,
  CreateCredential201Response,
  CreateCredentialRequest,
  ExportCredentialsAsMemberRequest,
  ExportCredentialsAsOrg200Response,
  ExportCredentialsAsOrgRequest,
  ListCredentialsResponse,
  OrgPortalError,
  UpdateCredential200Response,
  UpdateCredentialRequest,
} from '../models/index';
import {
    Authenticate200ResponseFromJSON,
    Authenticate200ResponseToJSON,
    CreateCredential201ResponseFromJSON,
    CreateCredential201ResponseToJSON,
    CreateCredentialRequestFromJSON,
    CreateCredentialRequestToJSON,
    ExportCredentialsAsMemberRequestFromJSON,
    ExportCredentialsAsMemberRequestToJSON,
    ExportCredentialsAsOrg200ResponseFromJSON,
    ExportCredentialsAsOrg200ResponseToJSON,
    ExportCredentialsAsOrgRequestFromJSON,
    ExportCredentialsAsOrgRequestToJSON,
    ListCredentialsResponseFromJSON,
    ListCredentialsResponseToJSON,
    OrgPortalErrorFromJSON,
    OrgPortalErrorToJSON,
    UpdateCredential200ResponseFromJSON,
    UpdateCredential200ResponseToJSON,
    UpdateCredentialRequestFromJSON,
    UpdateCredentialRequestToJSON,
} from '../models/index';

export interface AuthenticateRequest {
    orgID: string;
}

export interface CreateCredentialOperationRequest {
    orgID: string;
    credential?: CreateCredentialRequest;
}

export interface ExportCredentialsAsMemberOperationRequest {
    body: ExportCredentialsAsMemberRequest;
}

export interface ExportCredentialsAsOrgOperationRequest {
    orgID: string;
    body: ExportCredentialsAsOrgRequest;
}

export interface ListCredentialsRequest {
    orgID: string;
}

export interface PublishCredentialRequest {
    orgID: string;
    credentialID: string;
}

export interface UnpublishCredentialRequest {
    orgID: string;
    credentialID: string;
}

export interface UpdateCredentialOperationRequest {
    orgID: string;
    credentialID: string;
    credential?: UpdateCredentialRequest;
}

/**
 * 
 */
export class StudioApi extends runtime.BaseAPI {

    /**
     * fetch authentication credentials from Design Huddle
     */
    async authenticateRaw(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Authenticate200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling authenticate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{orgID}/studio/authenticate`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Authenticate200ResponseFromJSON(jsonValue));
    }

    /**
     * fetch authentication credentials from Design Huddle
     */
    async authenticate(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Authenticate200Response> {
        const response = await this.authenticateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create a credential
     */
    async createCredentialRaw(requestParameters: CreateCredentialOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCredential201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling createCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orgs/{orgID}/studio/credentials`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCredentialRequestToJSON(requestParameters.credential),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCredential201ResponseFromJSON(jsonValue));
    }

    /**
     * create a credential
     */
    async createCredential(requestParameters: CreateCredentialOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCredential201Response> {
        const response = await this.createCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * As an individual, retrieves exported credential images for given parameters
     */
    async exportCredentialsAsMemberRaw(requestParameters: ExportCredentialsAsMemberOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportCredentialsAsOrg200Response>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling exportCredentialsAsMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/studio/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportCredentialsAsMemberRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportCredentialsAsOrg200ResponseFromJSON(jsonValue));
    }

    /**
     * As an individual, retrieves exported credential images for given parameters
     */
    async exportCredentialsAsMember(requestParameters: ExportCredentialsAsMemberOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportCredentialsAsOrg200Response> {
        const response = await this.exportCredentialsAsMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * As an org, retrieves exported credential images for given parameters
     */
    async exportCredentialsAsOrgRaw(requestParameters: ExportCredentialsAsOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportCredentialsAsOrg200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling exportCredentialsAsOrg.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling exportCredentialsAsOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orgs/{orgID}/studio/export`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportCredentialsAsOrgRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportCredentialsAsOrg200ResponseFromJSON(jsonValue));
    }

    /**
     * As an org, retrieves exported credential images for given parameters
     */
    async exportCredentialsAsOrg(requestParameters: ExportCredentialsAsOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportCredentialsAsOrg200Response> {
        const response = await this.exportCredentialsAsOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * list all credentials owned by an organization
     */
    async listCredentialsRaw(requestParameters: ListCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCredentialsResponse>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling listCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{orgID}/studio/credentials`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCredentialsResponseFromJSON(jsonValue));
    }

    /**
     * list all credentials owned by an organization
     */
    async listCredentials(requestParameters: ListCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCredentialsResponse> {
        const response = await this.listCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create publish request
     */
    async publishCredentialRaw(requestParameters: PublishCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling publishCredential.');
        }

        if (requestParameters.credentialID === null || requestParameters.credentialID === undefined) {
            throw new runtime.RequiredError('credentialID','Required parameter requestParameters.credentialID was null or undefined when calling publishCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{orgID}/studio/credentials/{credentialID}/publish`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"credentialID"}}`, encodeURIComponent(String(requestParameters.credentialID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create publish request
     */
    async publishCredential(requestParameters: PublishCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.publishCredentialRaw(requestParameters, initOverrides);
    }

    /**
     * create unpublish request
     */
    async unpublishCredentialRaw(requestParameters: UnpublishCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling unpublishCredential.');
        }

        if (requestParameters.credentialID === null || requestParameters.credentialID === undefined) {
            throw new runtime.RequiredError('credentialID','Required parameter requestParameters.credentialID was null or undefined when calling unpublishCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{orgID}/studio/credentials/{credentialID}/unpublish`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"credentialID"}}`, encodeURIComponent(String(requestParameters.credentialID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create unpublish request
     */
    async unpublishCredential(requestParameters: UnpublishCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unpublishCredentialRaw(requestParameters, initOverrides);
    }

    /**
     * update credential
     */
    async updateCredentialRaw(requestParameters: UpdateCredentialOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCredential200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling updateCredential.');
        }

        if (requestParameters.credentialID === null || requestParameters.credentialID === undefined) {
            throw new runtime.RequiredError('credentialID','Required parameter requestParameters.credentialID was null or undefined when calling updateCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orgs/{orgID}/studio/credentials/{credentialID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"credentialID"}}`, encodeURIComponent(String(requestParameters.credentialID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCredentialRequestToJSON(requestParameters.credential),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCredential200ResponseFromJSON(jsonValue));
    }

    /**
     * update credential
     */
    async updateCredential(requestParameters: UpdateCredentialOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCredential200Response> {
        const response = await this.updateCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
