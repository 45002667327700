/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of a Policy search result.
 * @export
 * @interface Search200ResponsePoliciesInner
 */
export interface Search200ResponsePoliciesInner {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof Search200ResponsePoliciesInner
     */
    id: string;
    /**
     * The name of the object
     * @type {string}
     * @memberof Search200ResponsePoliciesInner
     */
    name: string;
    /**
     * The UUID of this object's owner
     * @type {string}
     * @memberof Search200ResponsePoliciesInner
     */
    owner?: string;
    /**
     * A description of this Policy.
     * @type {string}
     * @memberof Search200ResponsePoliciesInner
     */
    description?: string;
    /**
     * The state of this policy, whether it's live or paused.
     * @type {string}
     * @memberof Search200ResponsePoliciesInner
     */
    state: Search200ResponsePoliciesInnerStateEnum;
    /**
     * The id of the Policy this was extended from.
     * @type {string}
     * @memberof Search200ResponsePoliciesInner
     */
    parent?: string;
}


/**
 * @export
 */
export const Search200ResponsePoliciesInnerStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type Search200ResponsePoliciesInnerStateEnum = typeof Search200ResponsePoliciesInnerStateEnum[keyof typeof Search200ResponsePoliciesInnerStateEnum];


/**
 * Check if a given object implements the Search200ResponsePoliciesInner interface.
 */
export function instanceOfSearch200ResponsePoliciesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function Search200ResponsePoliciesInnerFromJSON(json: any): Search200ResponsePoliciesInner {
    return Search200ResponsePoliciesInnerFromJSONTyped(json, false);
}

export function Search200ResponsePoliciesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Search200ResponsePoliciesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'state': json['state'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
    };
}

export function Search200ResponsePoliciesInnerToJSON(value?: Search200ResponsePoliciesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner': value.owner,
        'description': value.description,
        'state': value.state,
        'parent': value.parent,
    };
}

