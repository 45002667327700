/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectsProjectIDExportPOSTRequestOneOf2
 */
export interface ProjectsProjectIDExportPOSTRequestOneOf2 {
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    format: ProjectsProjectIDExportPOSTRequestOneOf2FormatEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    sceneId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    sceneIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    sceneSlideId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    sceneSlideIds?: Array<string>;
    /**
     * 30 is the default and maximum value for mp4. 15 is the default and maximum value for gif.
     * @type {number}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    fps?: number;
    /**
     * "high" by default.
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    quality?: ProjectsProjectIDExportPOSTRequestOneOf2QualityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    noAudio?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf2
     */
    notificationUrl?: string;
}


/**
 * @export
 */
export const ProjectsProjectIDExportPOSTRequestOneOf2FormatEnum = {
    Mp4: 'mp4',
    Gif: 'gif'
} as const;
export type ProjectsProjectIDExportPOSTRequestOneOf2FormatEnum = typeof ProjectsProjectIDExportPOSTRequestOneOf2FormatEnum[keyof typeof ProjectsProjectIDExportPOSTRequestOneOf2FormatEnum];

/**
 * @export
 */
export const ProjectsProjectIDExportPOSTRequestOneOf2QualityEnum = {
    Highest: 'highest',
    High: 'high',
    Normal: 'normal',
    Low: 'low',
    Lowest: 'lowest'
} as const;
export type ProjectsProjectIDExportPOSTRequestOneOf2QualityEnum = typeof ProjectsProjectIDExportPOSTRequestOneOf2QualityEnum[keyof typeof ProjectsProjectIDExportPOSTRequestOneOf2QualityEnum];


/**
 * Check if a given object implements the ProjectsProjectIDExportPOSTRequestOneOf2 interface.
 */
export function instanceOfProjectsProjectIDExportPOSTRequestOneOf2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "format" in value;

    return isInstance;
}

export function ProjectsProjectIDExportPOSTRequestOneOf2FromJSON(json: any): ProjectsProjectIDExportPOSTRequestOneOf2 {
    return ProjectsProjectIDExportPOSTRequestOneOf2FromJSONTyped(json, false);
}

export function ProjectsProjectIDExportPOSTRequestOneOf2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportPOSTRequestOneOf2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': json['format'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'sceneId': !exists(json, 'scene_id') ? undefined : json['scene_id'],
        'sceneIds': !exists(json, 'scene_ids') ? undefined : json['scene_ids'],
        'sceneSlideId': !exists(json, 'scene_slide_id') ? undefined : json['scene_slide_id'],
        'sceneSlideIds': !exists(json, 'scene_slide_ids') ? undefined : json['scene_slide_ids'],
        'fps': !exists(json, 'fps') ? undefined : json['fps'],
        'quality': !exists(json, 'quality') ? undefined : json['quality'],
        'noAudio': !exists(json, 'no_audio') ? undefined : json['no_audio'],
        'notificationUrl': !exists(json, 'notification_url') ? undefined : json['notification_url'],
    };
}

export function ProjectsProjectIDExportPOSTRequestOneOf2ToJSON(value?: ProjectsProjectIDExportPOSTRequestOneOf2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': value.format,
        'filename': value.filename,
        'scene_id': value.sceneId,
        'scene_ids': value.sceneIds,
        'scene_slide_id': value.sceneSlideId,
        'scene_slide_ids': value.sceneSlideIds,
        'fps': value.fps,
        'quality': value.quality,
        'no_audio': value.noAudio,
        'notification_url': value.notificationUrl,
    };
}

