/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import type { GetPolicies200ResponsePoliciesInnerRules } from './GetPolicies200ResponsePoliciesInnerRules';
import {
    GetPolicies200ResponsePoliciesInnerRulesFromJSON,
    GetPolicies200ResponsePoliciesInnerRulesFromJSONTyped,
    GetPolicies200ResponsePoliciesInnerRulesToJSON,
} from './GetPolicies200ResponsePoliciesInnerRules';
import type { OrgsGet200ResponseContainersInnerIssuer } from './OrgsGet200ResponseContainersInnerIssuer';
import {
    OrgsGet200ResponseContainersInnerIssuerFromJSON,
    OrgsGet200ResponseContainersInnerIssuerFromJSONTyped,
    OrgsGet200ResponseContainersInnerIssuerToJSON,
} from './OrgsGet200ResponseContainersInnerIssuer';

/**
 * specific props of a named Policy belonging to an entity.
 * @export
 * @interface EditPolicy200ResponsePolicy
 */
export interface EditPolicy200ResponsePolicy {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof EditPolicy200ResponsePolicy
     */
    id: string;
    /**
     * The given name of this Policy.
     * @type {string}
     * @memberof EditPolicy200ResponsePolicy
     */
    name: string;
    /**
     * A description of this Policy.
     * @type {string}
     * @memberof EditPolicy200ResponsePolicy
     */
    description?: string;
    /**
     * The message response when a Policy run returns false.
     * @type {string}
     * @memberof EditPolicy200ResponsePolicy
     */
    falseMessage: string;
    /**
     * The message response when a Policy run returns true.
     * @type {string}
     * @memberof EditPolicy200ResponsePolicy
     */
    trueMessage: string;
    /**
     * The id of the Policy this was extended from.
     * @type {string}
     * @memberof EditPolicy200ResponsePolicy
     */
    parent?: string;
    /**
     * 
     * @type {GetPolicies200ResponsePoliciesInnerRules}
     * @memberof EditPolicy200ResponsePolicy
     */
    rules?: GetPolicies200ResponsePoliciesInnerRules | null;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof EditPolicy200ResponsePolicy
     */
    permissions: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
    /**
     * The state of this policy, whether it's live or paused.
     * @type {string}
     * @memberof EditPolicy200ResponsePolicy
     */
    state: EditPolicy200ResponsePolicyStateEnum;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInnerIssuer}
     * @memberof EditPolicy200ResponsePolicy
     */
    owner?: OrgsGet200ResponseContainersInnerIssuer;
    /**
     * 
     * @type {Date}
     * @memberof EditPolicy200ResponsePolicy
     */
    createdAt?: Date;
}


/**
 * @export
 */
export const EditPolicy200ResponsePolicyStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type EditPolicy200ResponsePolicyStateEnum = typeof EditPolicy200ResponsePolicyStateEnum[keyof typeof EditPolicy200ResponsePolicyStateEnum];


/**
 * Check if a given object implements the EditPolicy200ResponsePolicy interface.
 */
export function instanceOfEditPolicy200ResponsePolicy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "falseMessage" in value;
    isInstance = isInstance && "trueMessage" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function EditPolicy200ResponsePolicyFromJSON(json: any): EditPolicy200ResponsePolicy {
    return EditPolicy200ResponsePolicyFromJSONTyped(json, false);
}

export function EditPolicy200ResponsePolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditPolicy200ResponsePolicy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'falseMessage': json['falseMessage'],
        'trueMessage': json['trueMessage'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'rules': !exists(json, 'rules') ? undefined : GetPolicies200ResponsePoliciesInnerRulesFromJSON(json['rules']),
        'permissions': ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'state': json['state'],
        'owner': !exists(json, 'owner') ? undefined : OrgsGet200ResponseContainersInnerIssuerFromJSON(json['owner']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function EditPolicy200ResponsePolicyToJSON(value?: EditPolicy200ResponsePolicy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'falseMessage': value.falseMessage,
        'trueMessage': value.trueMessage,
        'parent': value.parent,
        'rules': GetPolicies200ResponsePoliciesInnerRulesToJSON(value.rules),
        'permissions': ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'state': value.state,
        'owner': OrgsGet200ResponseContainersInnerIssuerToJSON(value.owner),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

