/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectsProjectIDExportJobsJobIDGETSuccessOneOfData } from './ProjectsProjectIDExportJobsJobIDGETSuccessOneOfData';
import {
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOfDataFromJSON,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOfDataFromJSONTyped,
    ProjectsProjectIDExportJobsJobIDGETSuccessOneOfDataToJSON,
} from './ProjectsProjectIDExportJobsJobIDGETSuccessOneOfData';

/**
 * 
 * @export
 * @interface ProjectsProjectIDExportJobsJobIDGETSuccessOneOf
 */
export interface ProjectsProjectIDExportJobsJobIDGETSuccessOneOf {
    /**
     * 
     * @type {ProjectsProjectIDExportJobsJobIDGETSuccessOneOfData}
     * @memberof ProjectsProjectIDExportJobsJobIDGETSuccessOneOf
     */
    data: ProjectsProjectIDExportJobsJobIDGETSuccessOneOfData;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectsProjectIDExportJobsJobIDGETSuccessOneOf
     */
    success: boolean;
}

/**
 * Check if a given object implements the ProjectsProjectIDExportJobsJobIDGETSuccessOneOf interface.
 */
export function instanceOfProjectsProjectIDExportJobsJobIDGETSuccessOneOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "success" in value;

    return isInstance;
}

export function ProjectsProjectIDExportJobsJobIDGETSuccessOneOfFromJSON(json: any): ProjectsProjectIDExportJobsJobIDGETSuccessOneOf {
    return ProjectsProjectIDExportJobsJobIDGETSuccessOneOfFromJSONTyped(json, false);
}

export function ProjectsProjectIDExportJobsJobIDGETSuccessOneOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportJobsJobIDGETSuccessOneOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ProjectsProjectIDExportJobsJobIDGETSuccessOneOfDataFromJSON(json['data']),
        'success': json['success'],
    };
}

export function ProjectsProjectIDExportJobsJobIDGETSuccessOneOfToJSON(value?: ProjectsProjectIDExportJobsJobIDGETSuccessOneOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ProjectsProjectIDExportJobsJobIDGETSuccessOneOfDataToJSON(value.data),
        'success': value.success,
    };
}

