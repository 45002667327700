// Copyright 2024 Merit International Inc. All Rights Reserved

export const isValidUrl = (url: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);

    return true;
  } catch {
    return false;
  }
};
