// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Pressable, View } from "react-native";
import { getTemplateName } from "@src/utils/getTemplatename";
import React from "react";
import type {
  ListTemplates200ResponseTemplatesInner,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom,
} from "../../gen/org-portal";
import type { OPTestProps } from "../../types/TestProps";

const { Some } = Helpers;

type Props = {
  readonly rules: readonly OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom[];
  readonly onPressView?: (templateID: string) => void;
  readonly testProps: OPTestProps;
  readonly templates: readonly ListTemplates200ResponseTemplatesInner[];
};

export const PolicyRulesList = ({ onPressView, rules, templates, testProps }: Props) => {
  const { theme } = useTheme();

  return (
    <>
      {rules.map((rule, index) => {
        const [, ...templateIDs] = rule.arguments ?? [];

        return (
          <View key={JSON.stringify(rule)}>
            <Heading
              level="5"
              testProps={{
                ...testProps,
                elementId: (index + 1).toString(),
                elementName: `${testProps.elementName}RulesNumber`,
              }}
            >
              Rule {index + 1}
            </Heading>
            <VerticalSpacer size={theme.spacing.xs} />
            {templateIDs.map((templateID, templateIndex) => (
              <View key={templateID}>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <View style={{ flexDirection: "row" }}>
                    <Body>Has an active merit from </Body>
                    <Heading
                      level="5"
                      testProps={{
                        ...testProps,
                        elementId: (templateIndex + 1).toString() + templateID,
                        elementName: `${testProps.elementName}TemplateId`,
                      }}
                    >
                      {getTemplateName(templateID, templates) ?? templateID}
                    </Heading>
                  </View>
                  {Some(onPressView) && (
                    <Pressable
                      onPress={() => {
                        onPressView(templateID);
                      }}
                    >
                      <View
                        style={{ flexDirection: "row" }}
                        {...Helpers.generateTestIdProps({
                          ...testProps,
                          elementId: (index + 1).toString() + templateID,
                          elementName: `${testProps.elementName}TemplateViewLink`,
                        })}
                      >
                        <Body color={theme.colors.border.highlight.default}>View</Body>
                        <HorizontalSpacer size={theme.spacing.s} />
                        <Icon name="arrowExpandMediumHighlight" />
                      </View>
                    </Pressable>
                  )}
                </View>
                {templateIDs.length !== templateIndex + 1 && (
                  <>
                    <VerticalSpacer size={theme.spacing.xs} />
                    <Heading
                      level="5"
                      testProps={{
                        ...testProps,
                        elementId: (templateIndex + 1).toString() + templateID,
                        elementName: `${testProps.elementName}OrText`,
                      }}
                    >
                      or
                    </Heading>
                    <VerticalSpacer size={theme.spacing.xs} />
                  </>
                )}
              </View>
            ))}
            {rules.length !== index + 1 && <VerticalSpacer size={theme.spacing.xxl} />}
          </View>
        );
      })}
    </>
  );
};
