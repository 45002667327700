/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrgLoginSuccess200Response
 */
export interface OrgLoginSuccess200Response {
    /**
     * 
     * @type {string}
     * @memberof OrgLoginSuccess200Response
     */
    accessToken: string;
    /**
     * 
     * @type {object}
     * @memberof OrgLoginSuccess200Response
     */
    profile: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrgLoginSuccess200Response
     */
    permissions: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrgLoginSuccess200Response
     */
    orgID?: string;
}

/**
 * Check if a given object implements the OrgLoginSuccess200Response interface.
 */
export function instanceOfOrgLoginSuccess200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function OrgLoginSuccess200ResponseFromJSON(json: any): OrgLoginSuccess200Response {
    return OrgLoginSuccess200ResponseFromJSONTyped(json, false);
}

export function OrgLoginSuccess200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgLoginSuccess200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['AccessToken'],
        'profile': json['Profile'],
        'permissions': json['Permissions'],
        'orgID': !exists(json, 'OrgID') ? undefined : json['OrgID'],
    };
}

export function OrgLoginSuccess200ResponseToJSON(value?: OrgLoginSuccess200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AccessToken': value.accessToken,
        'Profile': value.profile,
        'Permissions': value.permissions,
        'OrgID': value.orgID,
    };
}

