// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Image, View } from "react-native";
import { Images } from "../../utils/Image";
import { SCREEN_NAME } from "./Records";
import { VerticalSpacer } from "../../components/Spacer";
import React from "react";

export const NoRecords = () => {
  const { theme } = useTheme();

  return (
    <>
      <VerticalSpacer size={76} />
      <View style={{ alignItems: "center" }}>
        <VerticalSpacer size={40} />
        <Image
          accessibilityLabel="document-not-found"
          source={Images.noDocumentsFound}
          style={{ height: 48, width: 48 }}
        />
        <VerticalSpacer size={theme.spacing.xxl} />
        <View style={{ width: 440 }}>
          <Heading
            bold
            center
            level="3"
            testProps={{
              elementName: "recordsListViewNoRecordsMatchText",
              screenName: SCREEN_NAME,
            }}
          >
            No records match your filters
          </Heading>
          <VerticalSpacer />
          <Body
            center
            size="l"
            testProps={{
              elementName: "recordsListViewNoRecordsMatchHelpText",
              screenName: SCREEN_NAME,
            }}
          >
            Please try adjusting your search or filter to find what you’re looking for
          </Body>
        </View>
      </View>
    </>
  );
};
