// Copyright 2024 Merit International Inc. All Rights Reserved

import { Button } from "@merit/frontend-components";
import { PreLoginFooter } from "@src/layouts/PreLoginFooter";
import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { Spin } from "../components";
import { View } from "react-native";
import React, { useState } from "react";

const SCREEN_NAME = "Landing";

export const OrgAuthLandingScreen = (promptLogin: () => Promise<void>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, _] = useState(false);

  return (
    <PreLoginLayout>
      <View style={{ flex: 1 }}>
        <View style={{ alignItems: "center", flex: 1, justifyContent: "center" }}>
          {isLoading ? (
            <Spin />
          ) : (
            <Button
              onPress={() => {
                promptLogin();
              }}
              testProps={{ elementName: "loginButton", screenName: SCREEN_NAME }}
              text="Login"
            />
          )}
        </View>
      </View>
      <PreLoginFooter />
    </PreLoginLayout>
  );
};
