/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectsProjectIDExportPOSTRequestOneOf
 */
export interface ProjectsProjectIDExportPOSTRequestOneOf {
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf
     */
    format: ProjectsProjectIDExportPOSTRequestOneOfFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf
     */
    pageId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf
     */
    notificationUrl?: string;
}


/**
 * @export
 */
export const ProjectsProjectIDExportPOSTRequestOneOfFormatEnum = {
    Jpg: 'jpg',
    Png: 'png'
} as const;
export type ProjectsProjectIDExportPOSTRequestOneOfFormatEnum = typeof ProjectsProjectIDExportPOSTRequestOneOfFormatEnum[keyof typeof ProjectsProjectIDExportPOSTRequestOneOfFormatEnum];


/**
 * Check if a given object implements the ProjectsProjectIDExportPOSTRequestOneOf interface.
 */
export function instanceOfProjectsProjectIDExportPOSTRequestOneOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "format" in value;

    return isInstance;
}

export function ProjectsProjectIDExportPOSTRequestOneOfFromJSON(json: any): ProjectsProjectIDExportPOSTRequestOneOf {
    return ProjectsProjectIDExportPOSTRequestOneOfFromJSONTyped(json, false);
}

export function ProjectsProjectIDExportPOSTRequestOneOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportPOSTRequestOneOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': json['format'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'pageId': !exists(json, 'page_id') ? undefined : json['page_id'],
        'notificationUrl': !exists(json, 'notification_url') ? undefined : json['notification_url'],
    };
}

export function ProjectsProjectIDExportPOSTRequestOneOfToJSON(value?: ProjectsProjectIDExportPOSTRequestOneOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': value.format,
        'filename': value.filename,
        'page_id': value.pageId,
        'notification_url': value.notificationUrl,
    };
}

