/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * 
 * @export
 * @interface EditFieldKindRequest
 */
export interface EditFieldKindRequest {
    /**
     * The field kind name
     * @type {string}
     * @memberof EditFieldKindRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditFieldKindRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof EditFieldKindRequest
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof EditFieldKindRequest
     */
    ruleValid?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>;
}

/**
 * Check if a given object implements the EditFieldKindRequest interface.
 */
export function instanceOfEditFieldKindRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditFieldKindRequestFromJSON(json: any): EditFieldKindRequest {
    return EditFieldKindRequestFromJSONTyped(json, false);
}

export function EditFieldKindRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditFieldKindRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'ruleValid': !exists(json, 'ruleValid') ? undefined : ((json['ruleValid'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
    };
}

export function EditFieldKindRequestToJSON(value?: EditFieldKindRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'ruleValid': value.ruleValid === undefined ? undefined : ((value.ruleValid as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
    };
}

