/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInner } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInner';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInner';
import type { OrgsGet200ResponseContainersInnerFieldsInnerFieldKind } from './OrgsGet200ResponseContainersInnerFieldsInnerFieldKind';
import {
    OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFromJSON,
    OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFromJSONTyped,
    OrgsGet200ResponseContainersInnerFieldsInnerFieldKindToJSON,
} from './OrgsGet200ResponseContainersInnerFieldsInnerFieldKind';

/**
 * The field names and values of this container.
 * @export
 * @interface OrgsGet200ResponseContainersInnerFieldsInner
 */
export interface OrgsGet200ResponseContainersInnerFieldsInner {
    /**
     * The name of the template field that generated this field.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    name?: string;
    /**
     * The description of the template field that generated this field.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    description?: string;
    /**
     * The value of the field.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    value?: string;
    /**
     * The date and time when this field was last updated.
     * @type {Date}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    updatedAt?: Date;
    /**
     * The ID of the mapped template field.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    templateFieldID?: string;
    /**
     * 
     * @type {OrgsGet200ResponseContainersInnerFieldsInnerFieldKind}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    fieldKind?: OrgsGet200ResponseContainersInnerFieldsInnerFieldKind;
    /**
     * The ID of the field on the base Stellar template from which this field was created.
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    baseTemplateFieldID?: string;
    /**
     * Whether or not the recipient of the container can share this field.
     * @type {boolean}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    canShare?: boolean;
    /**
     * A list of errors that explain a failed field validation.
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInner>}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    validationErrors?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInner>;
    /**
     * The ordered lineage of parent template fields by their external id. The order is from direct parent to
     * grandparent. So if Template3 extended from Template2 which extended from Template1, Template3's TemplateField
     * would have a lineage array of Template2's TemplateField ID and then Template1's TemplateFieldID.
     * 
     * @type {Array<string>}
     * @memberof OrgsGet200ResponseContainersInnerFieldsInner
     */
    templateFieldLineage?: Array<string>;
}

/**
 * Check if a given object implements the OrgsGet200ResponseContainersInnerFieldsInner interface.
 */
export function instanceOfOrgsGet200ResponseContainersInnerFieldsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrgsGet200ResponseContainersInnerFieldsInnerFromJSON(json: any): OrgsGet200ResponseContainersInnerFieldsInner {
    return OrgsGet200ResponseContainersInnerFieldsInnerFromJSONTyped(json, false);
}

export function OrgsGet200ResponseContainersInnerFieldsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200ResponseContainersInnerFieldsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'templateFieldID': !exists(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'fieldKind': !exists(json, 'fieldKind') ? undefined : OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFromJSON(json['fieldKind']),
        'baseTemplateFieldID': !exists(json, 'baseTemplateFieldID') ? undefined : json['baseTemplateFieldID'],
        'canShare': !exists(json, 'canShare') ? undefined : json['canShare'],
        'validationErrors': !exists(json, 'validationErrors') ? undefined : ((json['validationErrors'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerFromJSON)),
        'templateFieldLineage': !exists(json, 'templateFieldLineage') ? undefined : json['templateFieldLineage'],
    };
}

export function OrgsGet200ResponseContainersInnerFieldsInnerToJSON(value?: OrgsGet200ResponseContainersInnerFieldsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'value': value.value,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'templateFieldID': value.templateFieldID,
        'fieldKind': OrgsGet200ResponseContainersInnerFieldsInnerFieldKindToJSON(value.fieldKind),
        'baseTemplateFieldID': value.baseTemplateFieldID,
        'canShare': value.canShare,
        'validationErrors': value.validationErrors === undefined ? undefined : ((value.validationErrors as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerToJSON)),
        'templateFieldLineage': value.templateFieldLineage,
    };
}

