/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProjectsProjectIDExportPOSTRequestOneOf } from './ProjectsProjectIDExportPOSTRequestOneOf';
import {
    instanceOfProjectsProjectIDExportPOSTRequestOneOf,
    ProjectsProjectIDExportPOSTRequestOneOfFromJSON,
    ProjectsProjectIDExportPOSTRequestOneOfFromJSONTyped,
    ProjectsProjectIDExportPOSTRequestOneOfToJSON,
} from './ProjectsProjectIDExportPOSTRequestOneOf';
import type { ProjectsProjectIDExportPOSTRequestOneOf1 } from './ProjectsProjectIDExportPOSTRequestOneOf1';
import {
    instanceOfProjectsProjectIDExportPOSTRequestOneOf1,
    ProjectsProjectIDExportPOSTRequestOneOf1FromJSON,
    ProjectsProjectIDExportPOSTRequestOneOf1FromJSONTyped,
    ProjectsProjectIDExportPOSTRequestOneOf1ToJSON,
} from './ProjectsProjectIDExportPOSTRequestOneOf1';
import type { ProjectsProjectIDExportPOSTRequestOneOf2 } from './ProjectsProjectIDExportPOSTRequestOneOf2';
import {
    instanceOfProjectsProjectIDExportPOSTRequestOneOf2,
    ProjectsProjectIDExportPOSTRequestOneOf2FromJSON,
    ProjectsProjectIDExportPOSTRequestOneOf2FromJSONTyped,
    ProjectsProjectIDExportPOSTRequestOneOf2ToJSON,
} from './ProjectsProjectIDExportPOSTRequestOneOf2';
import type { ProjectsProjectIDExportPOSTRequestOneOf3 } from './ProjectsProjectIDExportPOSTRequestOneOf3';
import {
    instanceOfProjectsProjectIDExportPOSTRequestOneOf3,
    ProjectsProjectIDExportPOSTRequestOneOf3FromJSON,
    ProjectsProjectIDExportPOSTRequestOneOf3FromJSONTyped,
    ProjectsProjectIDExportPOSTRequestOneOf3ToJSON,
} from './ProjectsProjectIDExportPOSTRequestOneOf3';
import type { ProjectsProjectIDExportPOSTRequestOneOf4 } from './ProjectsProjectIDExportPOSTRequestOneOf4';
import {
    instanceOfProjectsProjectIDExportPOSTRequestOneOf4,
    ProjectsProjectIDExportPOSTRequestOneOf4FromJSON,
    ProjectsProjectIDExportPOSTRequestOneOf4FromJSONTyped,
    ProjectsProjectIDExportPOSTRequestOneOf4ToJSON,
} from './ProjectsProjectIDExportPOSTRequestOneOf4';

/**
 * @type ProjectsProjectIDExportPOSTRequest
 * 
 * @export
 */
export type ProjectsProjectIDExportPOSTRequest = ProjectsProjectIDExportPOSTRequestOneOf | ProjectsProjectIDExportPOSTRequestOneOf1 | ProjectsProjectIDExportPOSTRequestOneOf2 | ProjectsProjectIDExportPOSTRequestOneOf3 | ProjectsProjectIDExportPOSTRequestOneOf4;

export function ProjectsProjectIDExportPOSTRequestFromJSON(json: any): ProjectsProjectIDExportPOSTRequest {
    return ProjectsProjectIDExportPOSTRequestFromJSONTyped(json, false);
}

export function ProjectsProjectIDExportPOSTRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportPOSTRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ProjectsProjectIDExportPOSTRequestOneOfFromJSONTyped(json, true), ...ProjectsProjectIDExportPOSTRequestOneOf1FromJSONTyped(json, true), ...ProjectsProjectIDExportPOSTRequestOneOf2FromJSONTyped(json, true), ...ProjectsProjectIDExportPOSTRequestOneOf3FromJSONTyped(json, true), ...ProjectsProjectIDExportPOSTRequestOneOf4FromJSONTyped(json, true) };
}

export function ProjectsProjectIDExportPOSTRequestToJSON(value?: ProjectsProjectIDExportPOSTRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfProjectsProjectIDExportPOSTRequestOneOf(value)) {
        return ProjectsProjectIDExportPOSTRequestOneOfToJSON(value as ProjectsProjectIDExportPOSTRequestOneOf);
    }
    if (instanceOfProjectsProjectIDExportPOSTRequestOneOf1(value)) {
        return ProjectsProjectIDExportPOSTRequestOneOf1ToJSON(value as ProjectsProjectIDExportPOSTRequestOneOf1);
    }
    if (instanceOfProjectsProjectIDExportPOSTRequestOneOf2(value)) {
        return ProjectsProjectIDExportPOSTRequestOneOf2ToJSON(value as ProjectsProjectIDExportPOSTRequestOneOf2);
    }
    if (instanceOfProjectsProjectIDExportPOSTRequestOneOf3(value)) {
        return ProjectsProjectIDExportPOSTRequestOneOf3ToJSON(value as ProjectsProjectIDExportPOSTRequestOneOf3);
    }
    if (instanceOfProjectsProjectIDExportPOSTRequestOneOf4(value)) {
        return ProjectsProjectIDExportPOSTRequestOneOf4ToJSON(value as ProjectsProjectIDExportPOSTRequestOneOf4);
    }

    return {};
}

