/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn
 */
export interface GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn {
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn
     */
    dataType?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnDataTypeEnum;
}


/**
 * @export
 */
export const GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnDataTypeEnum = {
    Bool: 'Bool',
    Date: 'Date',
    DateTime: 'DateTime',
    Email: 'Email',
    Markdown: 'Markdown',
    Number: 'Number',
    PhoneNumber: 'PhoneNumber',
    Text: 'Text',
    Blob: 'Blob',
    Json: 'JSON'
} as const;
export type GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnDataTypeEnum = typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnDataTypeEnum[keyof typeof GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnDataTypeEnum];


/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn {
    return GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumnToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerMappedColumn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'dataType': value.dataType,
    };
}

