/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { PaginationResponseCursor } from './PaginationResponseCursor';
import {
    PaginationResponseCursorFromJSON,
    PaginationResponseCursorFromJSONTyped,
    PaginationResponseCursorToJSON,
} from './PaginationResponseCursor';

/**
 * contains a list of groups
 * @export
 * @interface GroupsResponse
 */
export interface GroupsResponse {
    /**
     * 
     * @type {Array<Group>}
     * @memberof GroupsResponse
     */
    groups?: Array<Group>;
    /**
     * Indicates whether more data is available after incrementing the offset
     * @type {boolean}
     * @memberof GroupsResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {PaginationResponseCursor}
     * @memberof GroupsResponse
     */
    cursor?: PaginationResponseCursor;
}

/**
 * Check if a given object implements the GroupsResponse interface.
 */
export function instanceOfGroupsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupsResponseFromJSON(json: any): GroupsResponse {
    return GroupsResponseFromJSONTyped(json, false);
}

export function GroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(GroupFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !exists(json, 'cursor') ? undefined : PaginationResponseCursorFromJSON(json['cursor']),
    };
}

export function GroupsResponseToJSON(value?: GroupsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(GroupToJSON)),
        'hasMore': value.hasMore,
        'cursor': PaginationResponseCursorToJSON(value.cursor),
    };
}

