// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { StyleSheet, View } from "react-native";
import { getPredicateReadableName } from "../../utils/getPredicateReadableName";
import { getRuleArguments } from "../../utils/getRuleArguments";
import capitalize from "lodash/capitalize";
import type {
  GetTemplateRules200ResponseRules,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner as RuleCondition,
} from "../../gen/org-portal";
import type { OPTestProps } from "../../types/TestProps";

type Props = {
  readonly fieldType: string;
  readonly ruleConditions: readonly RuleCondition[];
  readonly rules: GetTemplateRules200ResponseRules;
  readonly testProps: OPTestProps;
};

const { Some } = Helpers;

export const RuleList = ({ fieldType, ruleConditions, rules, testProps }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    argumentText: {
      color: theme.colors.border.success.default,
      fontWeight: theme.fontWeights.semiBold,
    },
    container: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingVertical: theme.spacing.l,
    },
  });

  const getPredicate = (predicateKey: string) => {
    const predicate = getPredicateReadableName(fieldType, predicateKey, rules);

    return Some(predicate) ? (
      <>
        <Body
          style={{ fontWeight: theme.fontWeights.semiBold }}
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}Predicate`,
          }}
        >
          {predicate}
        </Body>
        <HorizontalSpacer size={theme.spacing.m} />
      </>
    ) : (
      <></>
    );
  };

  const getArgument = (ruleCondition: RuleCondition) => {
    const argument = getRuleArguments(ruleCondition.predicate, ruleCondition.arguments);

    return Some(argument) ? (
      <>
        <Body
          style={styles.argumentText}
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}Argument`,
          }}
        >
          {argument}
        </Body>
        <HorizontalSpacer size={theme.spacing.m} />
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      {ruleConditions.map(rule => (
        <View style={styles.container}>
          <Body>
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}FieldName`,
              }}
            >
              If the {rule.targetName}{" "}
            </Body>
            <HorizontalSpacer size={theme.spacing.m} />
            {getPredicate(rule.predicate)}
            {getArgument(rule)}
            <Body>then validate</Body>
          </Body>

          <VerticalSpacer />
          <Body>
            <Body color={theme.colors.text.alert.critical}>{`Error message: `}</Body>
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}ErrorMessage`,
              }}
            >
              {Some(rule.errorMessage) && rule.errorMessage !== ""
                ? capitalize(rule.errorMessage)
                : "--"}
            </Body>
          </Body>
        </View>
      ))}
    </>
  );
};
