/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTemplateRules200ResponseRulesBoolFieldHasValue
 */
export interface GetTemplateRules200ResponseRulesBoolFieldHasValue {
    /**
     * 
     * @type {string}
     * @memberof GetTemplateRules200ResponseRulesBoolFieldHasValue
     */
    readable?: string;
    /**
     * Predicate arguments
     * @type {Array<string>}
     * @memberof GetTemplateRules200ResponseRulesBoolFieldHasValue
     */
    arguments?: Array<string>;
}

/**
 * Check if a given object implements the GetTemplateRules200ResponseRulesBoolFieldHasValue interface.
 */
export function instanceOfGetTemplateRules200ResponseRulesBoolFieldHasValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json: any): GetTemplateRules200ResponseRulesBoolFieldHasValue {
    return GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200ResponseRulesBoolFieldHasValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readable': !exists(json, 'Readable') ? undefined : json['Readable'],
        'arguments': !exists(json, 'Arguments') ? undefined : json['Arguments'],
    };
}

export function GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value?: GetTemplateRules200ResponseRulesBoolFieldHasValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Readable': value.readable,
        'Arguments': value.arguments,
    };
}

