// Copyright 2024 Merit International Inc. All Rights Reserved

import { SearchRequestObjectTypeEnum } from "../../gen/org-portal";
import { useAppConstantsStore } from "../../stores";
import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type { DefaultApi, Search200Response, SearchRequest } from "../../gen/org-portal";

export const useDatasourcesSearchData = (
  api: DefaultApi,
  orgID: string,
  searchQuery?: string | undefined
) => {
  const appConstants = useAppConstantsStore();
  const DEFAULT_PARAMS = {
    limit: appConstants.datasourcesLimit,
    objectName: searchQuery,
    orgID,
  } as const;

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Search200Response>();
  const { errorHandler } = useServerErrorHandler();

  const [params, setParams] = useState<SearchRequest & { readonly orgID: string }>(DEFAULT_PARAMS);

  const data = response?.dataSources ?? [];

  const refresh = (nextParams: Partial<SearchRequest> = {}) => {
    if (loading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setLoading(true);

        const res = await api.search({
          orgID: params.orgID,
          query: {
            objectName: searchQuery,
            objectType: SearchRequestObjectTypeEnum.Datasource,
          },
        });
        setResponse(res);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [api, errorHandler, params, searchQuery]);

  return {
    data,
    loading,
    refresh,
  };
};
