/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectsPOSTRequestOneOf1
 */
export interface ProjectsPOSTRequestOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof ProjectsPOSTRequestOneOf1
     */
    projectTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsPOSTRequestOneOf1
     */
    templateCode: string;
    /**
     * Optionally supply the returned <em>object_hash</em> of a <a target="_parent" href="https://api.designhuddle.com/doc/Using_Template_Customization_Objects.pdf">previously created Template Customization Object</a> to automatically customize/populate this Template per user. The full <em>customizations</em> object will be ignored if this field is supplied.
     * @type {string}
     * @memberof ProjectsPOSTRequestOneOf1
     */
    customizationsHash?: string;
    /**
     * Optionally supply a <a target="_parent" href="https://api.designhuddle.com/doc/Template_Customization_Objects.pdf">Template Customization Object</a> to automatically customize/populate this Template per user. To improve performance when re-using the same object on multiple Project creations, <a href="#tag/Templates/paths/~1template~1customization-objects/post">store the object</a> first and then provide the returned <em>object_hash</em> instead. This can also be accomplished by passing in this parameter on only the first Project creation and storing the returned <em>customizations_hash</em> field for use on further executions. 
     * @type {object}
     * @memberof ProjectsPOSTRequestOneOf1
     */
    customizations?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsPOSTRequestOneOf1
     */
    tags?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ProjectsPOSTRequestOneOf1
     */
    metadata?: object;
}

/**
 * Check if a given object implements the ProjectsPOSTRequestOneOf1 interface.
 */
export function instanceOfProjectsPOSTRequestOneOf1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateCode" in value;

    return isInstance;
}

export function ProjectsPOSTRequestOneOf1FromJSON(json: any): ProjectsPOSTRequestOneOf1 {
    return ProjectsPOSTRequestOneOf1FromJSONTyped(json, false);
}

export function ProjectsPOSTRequestOneOf1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsPOSTRequestOneOf1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectTitle': !exists(json, 'project_title') ? undefined : json['project_title'],
        'templateCode': json['template_code'],
        'customizationsHash': !exists(json, 'customizations_hash') ? undefined : json['customizations_hash'],
        'customizations': !exists(json, 'customizations') ? undefined : json['customizations'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function ProjectsPOSTRequestOneOf1ToJSON(value?: ProjectsPOSTRequestOneOf1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_title': value.projectTitle,
        'template_code': value.templateCode,
        'customizations_hash': value.customizationsHash,
        'customizations': value.customizations,
        'tags': value.tags,
        'metadata': value.metadata,
    };
}

