/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents an exported credential
 * @export
 * @interface ExportCredentialsAsOrg200ResponseExportedCredentialsValue
 */
export interface ExportCredentialsAsOrg200ResponseExportedCredentialsValue {
    /**
     * 
     * @type {string}
     * @memberof ExportCredentialsAsOrg200ResponseExportedCredentialsValue
     */
    credentialId: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCredentialsAsOrg200ResponseExportedCredentialsValue
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCredentialsAsOrg200ResponseExportedCredentialsValue
     */
    url: string;
}

/**
 * Check if a given object implements the ExportCredentialsAsOrg200ResponseExportedCredentialsValue interface.
 */
export function instanceOfExportCredentialsAsOrg200ResponseExportedCredentialsValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "credentialId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function ExportCredentialsAsOrg200ResponseExportedCredentialsValueFromJSON(json: any): ExportCredentialsAsOrg200ResponseExportedCredentialsValue {
    return ExportCredentialsAsOrg200ResponseExportedCredentialsValueFromJSONTyped(json, false);
}

export function ExportCredentialsAsOrg200ResponseExportedCredentialsValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCredentialsAsOrg200ResponseExportedCredentialsValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credentialId': json['credentialId'],
        'name': json['name'],
        'url': json['url'],
    };
}

export function ExportCredentialsAsOrg200ResponseExportedCredentialsValueToJSON(value?: ExportCredentialsAsOrg200ResponseExportedCredentialsValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credentialId': value.credentialId,
        'name': value.name,
        'url': value.url,
    };
}

