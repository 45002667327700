import { Body, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody, Spin, Tooltip } from "../../components";
import { EllipsisText } from "../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { SCREEN_NAME } from "./TemplateDetails";
import { View } from "react-native";
import { getReadableDataType } from "@src/utils/getReadableDataType";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../../src/stores";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type {
  GetContainer200Response,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as GetTemplateFields,
} from "../../gen/org-portal";
import type { OPTestProps } from "../../types/TestProps";

type Props = {
  readonly dataSet: readonly GetTemplateFields[];
};

const { Some } = Helpers;

export const DataSet = ({ dataSet }: Props) => {
  const { theme } = useTheme();
  const { folioFieldNames } = useAppConstantsStore();
  const { selectedOrgId, selectedOrgName } = useLoggedInAuthState();
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [containers, setContainers] = useState<readonly GetContainer200Response["container"][]>([]);

  useEffect(() => {
    const fetchContainer = async (containerId: string) => {
      try {
        setIsLoading(true);
        const response = await api.getContainer({
          containerID: containerId,
          orgID: selectedOrgId,
        });
        setContainers(prevState => [...prevState, response.container]);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    dataSet
      .filter(field => Some(field.mappedContainerField))
      .forEach(field => {
        if (Some(field.mappedContainerField) && Some(field.mappedContainerField.containerId)) {
          fetchContainer(field.mappedContainerField.containerId);
        }
      });
  }, [api, dataSet, errorHandler, selectedOrgId]);

  const getColumnName = (field: GetTemplateFields, testProps: OPTestProps | undefined) => {
    if (Some(field.mappedContainerField)) {
      const container = containers.find(({ id }) => field.mappedContainerField?.containerId === id);

      const templateField = container?.fields?.find(
        ({ templateFieldID }) => field.mappedContainerField?.containerFieldId === templateFieldID
      );

      return (
        <View style={{ flex: 1, flexDirection: "row" }}>
          <Tooltip
            icon="mappedFieldMediumHighlight"
            testProps={{
              elementId: field.fieldID,
              elementName: "detailsViewDataSetTabListViewMappedFieldTooltip",
              screenName: SCREEN_NAME,
            }}
            text={`Mapped container field\n\nContainer name:\n${
              container?.name ?? "--"
            }\nField name:\n${templateField?.name ?? "--"}`}
          />
          <HorizontalSpacer size={6} />
          <EllipsisText testProps={testProps} text={templateField?.name ?? "--"} />
        </View>
      );
    }

    return <EllipsisText testProps={testProps} text={field.mappedColumn?.name ?? "--"} />;
  };

  const columns: readonly DatagridColumn<GetTemplateFields>[] = [
    {
      key: "fieldname",
      label: "Field name",
      renderer: (field, testProps) => (
        <EllipsisText testProps={testProps} text={field.name ?? "--"} />
      ),
      size: "flex",
    },
    {
      key: "name",
      label: "Column name",
      renderer: (field, testProps) =>
        field.name === folioFieldNames.issuingOrgName ? (
          <EllipsisText testProps={testProps} text={selectedOrgName} />
        ) : (
          getColumnName(field, testProps)
        ),
      size: "flex",
    },
    {
      key: "dataType",
      label: "Data type",
      renderer: (field, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={Some(field.type) ? getReadableDataType(field.type) : "--"}
        />
      ),
      size: "flex",
    },
  ];

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", paddingVertical: 200 }}>
        <Spin />
      </View>
    );
  }

  return (
    <>
      <VerticalSpacer size={theme.spacing.xxl} />
      {Some(dataSet) && dataSet.length > 0 ? (
        <Datagrid
          columns={columns}
          testProps={{
            elementName: "detailsViewDataSetTabListView",
            screenName: SCREEN_NAME,
          }}
        >
          <DatagridBody
            columns={columns}
            data={dataSet}
            testProps={{
              elementName: "detailsViewDataSetTabListView",
              screenName: SCREEN_NAME,
            }}
            testPropsElementIdKey="fieldID"
          />
        </Datagrid>
      ) : (
        <View style={{ paddingHorizontal: 32 }}>
          <Body style={{ fontWeight: theme.fontWeights.semiBold }}>
            There are no data sets for this data source
          </Body>
        </View>
      )}
    </>
  );
};
