// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer, Spin } from "@src/components";
import { Linking, Pressable, StyleSheet, View } from "react-native";
import { getDateTimeString } from "@src/utils/time";
import { useCredentials } from "@src/api/studio";
import { useExportCredentials } from "@src/api/studio/useExportCredentials";
import { useGetTestProps } from "@src/hooks/useGetTestProps";
import type { OPTestProps } from "@src/types/TestProps";
import type { TextStyle, ViewStyle } from "react-native";

type CredentialsProps = {
  readonly containerId: string;
  readonly testProps: OPTestProps;
};

export const CredentialsTab = ({ containerId, testProps }: CredentialsProps) => {
  // States
  // dear developer: add states here as needed

  // Hooks
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly bold: TextStyle;
    readonly container: ViewStyle;
    readonly credentialNameLabelRow: ViewStyle;
    readonly spinContainer: ViewStyle;
    readonly viewLinkContainer: ViewStyle;
  }>({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xxl,
    },
    credentialNameLabelRow: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing.s,
    },
    spinContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flex: 1,
      justifyContent: "center",
    },
    viewLinkContainer: {
      flexDirection: "row",
    },
  });

  const getTestProps = useGetTestProps();
  const { data: listCredentialsData } = useCredentials();
  const { data: exportedCredentialsData, error, isLoading } = useExportCredentials([containerId]);

  if (isLoading) {
    return (
      <View style={styles.spinContainer}>
        <Spin />
      </View>
    );
  }

  if (Boolean(error)) {
    return (
      <View style={styles.container}>
        <Body>There was an error getting credentials for this container.</Body>
      </View>
    );
  }

  const exportedCredentials = Object.values(exportedCredentialsData?.exportedCredentials ?? {});
  if (exportedCredentials.length === 0) {
    return (
      <View style={styles.container}>
        <Body>No credentials available for this container.</Body>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {exportedCredentials.map(exportedCredential => {
        const createdAt = listCredentialsData?.credentials.find(
          credential => credential.id === exportedCredential.credentialId
        )?.createdAt;

        return (
          <View key={exportedCredential.credentialId}>
            <View style={styles.credentialNameLabelRow}>
              <Body
                style={styles.bold}
                testProps={{
                  ...testProps,
                  elementId: `${testProps.elementId ?? ""}-credentialName-${
                    exportedCredential.credentialId
                  }`,
                }}
              >
                {exportedCredential.name}
              </Body>
              <Pressable
                onPress={() => {
                  Linking.openURL(exportedCredential.url);
                }}
                {...getTestProps({
                  elementId: `${testProps.elementId ?? ""}-viewCredentialButton-${
                    exportedCredential.credentialId
                  }`,
                  elementName: testProps.elementName,
                })}
              >
                <View style={styles.viewLinkContainer}>
                  <Body color={theme.colors.border.highlight.default}>View</Body>
                  <HorizontalSpacer size={theme.spacing.s} />
                  <Icon name="arrowExpandMediumHighlight" />
                </View>
              </Pressable>
            </View>
            <Body
              testProps={{
                ...testProps,
                elementId: `${testProps.elementId ?? ""}-credentialCreatedAt-${
                  exportedCredential.credentialId
                }`,
              }}
            >
              Created at: {createdAt === undefined ? "--" : getDateTimeString(createdAt)}
            </Body>
          </View>
        );
      })}
    </View>
  );
};
