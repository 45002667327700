/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * The inputs required to create, or add a template field to template.
 * @export
 * @interface TemplateFieldProperties
 */
export interface TemplateFieldProperties {
    /**
     * The new name for a template field.
     * @type {string}
     * @memberof TemplateFieldProperties
     */
    name?: string | null;
    /**
     * The new description for a template field. Since this is an optional field, if the field is missing or set to null, then the field will remain unchanged. If the field is set to empty string, then the new value would be empty string.
     * @type {string}
     * @memberof TemplateFieldProperties
     */
    description?: string | null;
    /**
     * Whether this field will be used as a method of contacting the recipient. Leaving this blank when extending template fields will have result in the field defaulting to false. Leaving this blank when updating will leave the field unchanged.
     * @type {boolean}
     * @memberof TemplateFieldProperties
     */
    contact?: boolean | null;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof TemplateFieldProperties
     */
    permissions?: Array<PermissionPair>;
    /**
     * A Rule as added to response objects (always displayed on response, even when empty)
     * @type {Array<Atom>}
     * @memberof TemplateFieldProperties
     */
    fieldValidationRules?: Array<Atom>;
    /**
     * 
     * @type {string}
     * @memberof TemplateFieldProperties
     */
    fieldKindID: string;
}

/**
 * Check if a given object implements the TemplateFieldProperties interface.
 */
export function instanceOfTemplateFieldProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fieldKindID" in value;

    return isInstance;
}

export function TemplateFieldPropertiesFromJSON(json: any): TemplateFieldProperties {
    return TemplateFieldPropertiesFromJSONTyped(json, false);
}

export function TemplateFieldPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateFieldProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'fieldValidationRules': !exists(json, 'fieldValidationRules') ? undefined : ((json['fieldValidationRules'] as Array<any>).map(AtomFromJSON)),
        'fieldKindID': json['fieldKindID'],
    };
}

export function TemplateFieldPropertiesToJSON(value?: TemplateFieldProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'contact': value.contact,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'fieldValidationRules': value.fieldValidationRules === undefined ? undefined : ((value.fieldValidationRules as Array<any>).map(AtomToJSON)),
        'fieldKindID': value.fieldKindID,
    };
}

