/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The required inputs for creating an Entity Merge.
 * @export
 * @interface CreateEntityMerge
 */
export interface CreateEntityMerge {
    /**
     * The signed JWT of the Auth0 token of the second Entity to be merged. The token can be created by performing an authenticated sign-in with Auth0 and passing off the authentication token into this parameter.
     * @type {string}
     * @memberof CreateEntityMerge
     */
    token: string;
}

/**
 * Check if a given object implements the CreateEntityMerge interface.
 */
export function instanceOfCreateEntityMerge(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function CreateEntityMergeFromJSON(json: any): CreateEntityMerge {
    return CreateEntityMergeFromJSONTyped(json, false);
}

export function CreateEntityMergeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEntityMerge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function CreateEntityMergeToJSON(value?: CreateEntityMerge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}

