import { Auth0Provider } from "@auth0/auth0-react";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "@src/configuration";
import type { FCWithChildren } from "@src/types/component";

const envConfig = Constants.manifest?.extra as EnvConfig;

const AuthProvider: FCWithChildren = ({ children }) => {
  const { auth0Domain, clientId } = envConfig.auth;

  return (
    <Auth0Provider clientId={clientId} domain={auth0Domain}>
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
