/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResponseCursor } from './PaginationResponseCursor';
import {
    PaginationResponseCursorFromJSON,
    PaginationResponseCursorFromJSONTyped,
    PaginationResponseCursorToJSON,
} from './PaginationResponseCursor';
import type { Policy } from './Policy';
import {
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';

/**
 * An object that contains a list of Policies.
 * @export
 * @interface PolicyResponse
 */
export interface PolicyResponse {
    /**
     * The list of policies available.
     * @type {Array<Policy>}
     * @memberof PolicyResponse
     */
    policies?: Array<Policy>;
    /**
     * Indicates whether more data is available after incrementing the offset
     * @type {boolean}
     * @memberof PolicyResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {PaginationResponseCursor}
     * @memberof PolicyResponse
     */
    cursor?: PaginationResponseCursor;
}

/**
 * Check if a given object implements the PolicyResponse interface.
 */
export function instanceOfPolicyResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolicyResponseFromJSON(json: any): PolicyResponse {
    return PolicyResponseFromJSONTyped(json, false);
}

export function PolicyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policies': !exists(json, 'policies') ? undefined : ((json['policies'] as Array<any>).map(PolicyFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !exists(json, 'cursor') ? undefined : PaginationResponseCursorFromJSON(json['cursor']),
    };
}

export function PolicyResponseToJSON(value?: PolicyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policies': value.policies === undefined ? undefined : ((value.policies as Array<any>).map(PolicyToJSON)),
        'hasMore': value.hasMore,
        'cursor': PaginationResponseCursorToJSON(value.cursor),
    };
}

