// Copyright 2024 Merit International Inc. All Rights Reserved

import {
  QueryCache,
  QueryClient,
  QueryClientProvider as RQQueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useMemo } from "react";
import { useQueryErrorHandler } from "@src/hooks";
import type { FCWithChildren } from "@src/types/component";

export const QueryClientProvider: FCWithChildren = ({ children }) => {
  const onError = useQueryErrorHandler();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 60 * 1000, // 5 mins
          },
        },
        queryCache: new QueryCache({
          onError,
        }),
      }),
    [onError]
  );

  return (
    <RQQueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      {children}
    </RQQueryClientProvider>
  );
};
