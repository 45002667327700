/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';

/**
 * 
 * @export
 * @interface None
 */
export interface None extends Permission {
}

/**
 * Check if a given object implements the None interface.
 */
export function instanceOfNone(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoneFromJSON(json: any): None {
    return NoneFromJSONTyped(json, false);
}

export function NoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): None {
    return json;
}

export function NoneToJSON(value?: None | null): any {
    return value;
}

