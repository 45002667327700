// Copyright 2024 Merit International Inc. All Rights Reserved

import { CONTENT_WIDTH, HEADER_HEIGHT } from "./constants";
import { CreateAndConfigureMenu } from "./CreateAndConfigureMenu";
import { HorizontalSpacer } from "../Spacer";
import { Icon, useTheme } from "@merit/frontend-components";
import { Image, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { NavLink } from "./NavLink";
import { NavMenu } from "./NavMenu";
import { OrganizationMenu } from "./OrganizationMenu";
import { ProfileIcon } from "../ProfileIcon";
import { ProfileMenu } from "./ProfileMenu";
import { ShrinkText } from "../ShrinkText/ShrinkText";
import { VerifyAndManageMenu } from "./VerifyAndManageMenu";
import { useAuthStore } from "../../stores/authStore";
import { useLoggedInUserRoles } from "@src/hooks/loggedInUserRoles";
import { useOrgLogo } from "../../hooks/useOrgLogo";
import { useRoute } from "@react-navigation/native";
import React from "react";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

type NavGroup = "create" | "org" | "verify";
const screensBySection: Record<NavGroup, readonly (keyof RouteParams)[]> = {
  create: ["Templates", "Datasources", "Policies", "Fields", "Studio", "Reports"],
  org: ["OrgSettings"],
  verify: ["Records", "RecordDetails", "Approvals"],
};

type Props = {
  readonly disableNav: boolean;
};

export const NewNav = ({ disableNav = false }: Props) => {
  const { theme } = useTheme();
  const { selectedOrgName } = useAuthStore();
  const route = useRoute<RouteProp<RouteParams>>();
  const { orgLogo } = useOrgLogo();
  const { isDashboardAdmin, isDesignAdmin, isOrgAdmin } = useLoggedInUserRoles();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      height: HEADER_HEIGHT,
      justifyContent: "center",
      paddingHorizontal: 32,
      width: CONTENT_WIDTH,
    },
    orgNameSection: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      ...Platform.select({
        web: {
          cursor: "pointer",
        },
      }),
    },
  });

  const isSectionActive = (sectionName: NavGroup) =>
    screensBySection[sectionName].includes(route.name);

  return (
    <View style={styles.container}>
      <NavMenu
        customButtonContent={
          <View style={styles.orgNameSection}>
            <Image
              defaultSource={{ uri: theme.images.orgLogo }}
              source={{ uri: orgLogo }}
              style={[{ height: 32, resizeMode: "contain", width: 32 }]}
            />
            <HorizontalSpacer />
            <ShrinkText
              bold={isSectionActive("org")}
              testID="orgName:NewNav"
              text={selectedOrgName ?? ""}
            />
            <HorizontalSpacer />
            <Icon name="chevronDownExtraSmallDefault" />
          </View>
        }
        isActive={isSectionActive("org")}
        popoverContent={<OrganizationMenu />}
        popoverPlacement="bottom-start"
      />

      <View style={{ flex: 1 }} />

      <NavLink disabled={disableNav || !isOrgAdmin} location="Records" title="Home" />
      <HorizontalSpacer size={8} />
      <NavMenu
        disabled={disableNav || !isOrgAdmin}
        isActive={isSectionActive("verify")}
        popoverContent={<VerifyAndManageMenu />}
        title="Verify & Manage"
      />
      <HorizontalSpacer size={8} />
      <NavMenu
        disabled={disableNav || !(isOrgAdmin || isDashboardAdmin || isDesignAdmin)}
        isActive={isSectionActive("create")}
        popoverContent={<CreateAndConfigureMenu />}
        title="Create & Configure"
      />
      <HorizontalSpacer size={16} />
      <TouchableOpacity
        onPress={() => {
          window.open("https://knowledge.merits.com/", "_blank");
        }}
      >
        <Icon name="helpOutlinedMediumDefault" />
      </TouchableOpacity>
      <HorizontalSpacer size={24} />
      <NavMenu
        customButtonContent={
          <View
            style={{
              flexDirection: "row",
              ...Platform.select({
                web: {
                  cursor: "pointer",
                },
              }),
            }}
          >
            <ProfileIcon />
          </View>
        }
        popoverContent={<ProfileMenu />}
      />
    </View>
  );
};
