/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CapabilityRestriction } from './CapabilityRestriction';
import {
    CapabilityRestrictionFromJSON,
    CapabilityRestrictionFromJSONTyped,
    CapabilityRestrictionToJSON,
} from './CapabilityRestriction';

/**
 * Adds a capability to an agent with the restriction
 * @export
 * @interface CapabilityWithRestriction
 */
export interface CapabilityWithRestriction {
    /**
     * The capability to allow the agent
     * @type {string}
     * @memberof CapabilityWithRestriction
     */
    capability: string;
    /**
     * Allows the agent to limit this capability to a subset of objects of the given type
     * @type {Array<CapabilityRestriction>}
     * @memberof CapabilityWithRestriction
     */
    objects?: Array<CapabilityRestriction> | null;
}

/**
 * Check if a given object implements the CapabilityWithRestriction interface.
 */
export function instanceOfCapabilityWithRestriction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "capability" in value;

    return isInstance;
}

export function CapabilityWithRestrictionFromJSON(json: any): CapabilityWithRestriction {
    return CapabilityWithRestrictionFromJSONTyped(json, false);
}

export function CapabilityWithRestrictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapabilityWithRestriction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'capability': json['capability'],
        'objects': !exists(json, 'objects') ? undefined : (json['objects'] === null ? null : (json['objects'] as Array<any>).map(CapabilityRestrictionFromJSON)),
    };
}

export function CapabilityWithRestrictionToJSON(value?: CapabilityWithRestriction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'capability': value.capability,
        'objects': value.objects === undefined ? undefined : (value.objects === null ? null : (value.objects as Array<any>).map(CapabilityRestrictionToJSON)),
    };
}

