// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body } from "@merit/frontend-components";
import { Pressable, StyleSheet, TouchableOpacity } from "react-native";
import { ROUTES } from "../../Router";
import { useHover } from "react-native-web-hooks";
import { useLinkProps } from "@react-navigation/native";
import React, { useRef } from "react";
import type { PostLoginRouteParams, RouteParams } from "../../Router";

type Props = {
  readonly name: string;
  readonly link: keyof PostLoginRouteParams;
};

export const BreadcrumbLink = ({ link, name }: Props) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const { onPress, ...linkProps } = useLinkProps<RouteParams>({
    to: ROUTES[link],
  });

  const styles = StyleSheet.create({
    hovered: {
      textDecorationLine: "underline",
    },
  });

  return (
    <TouchableOpacity onPress={onPress} {...linkProps}>
      <Pressable accessible ref={ref}>
        <Body style={isHovered && styles.hovered}>{name}</Body>
      </Pressable>
    </TouchableOpacity>
  );
};
