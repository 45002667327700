// Copyright 2024 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { useCallback, useEffect, useState } from "react";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import type {
  DefaultApi,
  GetAgents200ResponseAgentsInner,
  GetAgentsLinkedToEntityRequest,
} from "@src/gen/org-portal";

const { None, Some } = Helpers;

type Pagination = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter: string | undefined;
  readonly prevEndBefore: string | undefined;
};

const initialPaginationInfo = {
  hasNextPage: false,
  hasPrevPage: false,
  nextStartAfter: undefined,
  prevEndBefore: undefined,
};

export const useLinkedAppsData = (
  api: DefaultApi,
  requestParams: GetAgentsLinkedToEntityRequest
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState(requestParams);
  const { errorHandler } = useServerErrorHandler();
  const [agents, setAgents] = useState<readonly GetAgents200ResponseAgentsInner[]>([]);
  const [paginationData, setPaginationData] = useState<Pagination>(initialPaginationInfo);

  const noOfAgentsPerPage = (params.limit ?? 11) - 1;

  const refresh = (nextParams: Partial<GetAgentsLinkedToEntityRequest> = {}) => {
    if (isLoading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  const getCurrentPageAgents = useCallback(
    (response: readonly GetAgents200ResponseAgentsInner[]) =>
      response.length === params.limit
        ? response.slice(0, response.length - 1)
        : response.slice(0, response.length),
    [params.limit]
  );

  const fetchAgentsVersions = useCallback(
    async (payload: GetAgentsLinkedToEntityRequest) => {
      try {
        if (params.orgID === "") {
          throw new Error("Couldn't find Organization ID");
        }
        setIsLoading(true);
        const response = await api.getAgentsLinkedToEntity(payload);

        const agentsResponse = response.agents;
        if (None(agentsResponse) || agentsResponse.length === 0) {
          return;
        }

        if (None(params.start) && None(params.end)) {
          const agentsData = getCurrentPageAgents(agentsResponse);
          const nextPageRecord = agentsData[agentsData.length - 1];

          setPaginationData(prevState => ({
            ...prevState,
            hasNextPage: agentsResponse.length > noOfAgentsPerPage,
            nextStartAfter: `${nextPageRecord.name},${nextPageRecord.id}`,
          }));

          setAgents(agentsData);
        }

        if (Some(params.start)) {
          const agentsData = getCurrentPageAgents(agentsResponse);
          const nextPageRecord = agentsData[agentsData.length - 1];
          const prevPageRecord = agentsData[0];

          setPaginationData(prevState => ({
            ...prevState,
            hasNextPage: agentsResponse.length > noOfAgentsPerPage,
            hasPrevPage: true,
            nextStartAfter:
              agentsResponse.length > noOfAgentsPerPage
                ? `${nextPageRecord.name},${nextPageRecord.id}`
                : undefined,
            prevEndBefore: `${prevPageRecord.name},${prevPageRecord.id}`,
          }));
          setAgents(agentsData);
        }

        if (Some(params.end)) {
          const agentsData = [...getCurrentPageAgents(agentsResponse)].reverse();

          const nextPageRecord = agentsData[agentsData.length - 1];
          const prevPageRecord = agentsData[0];

          setPaginationData(prevState => ({
            ...prevState,
            hasNextPage: true,
            hasPrevPage: agentsResponse.length > noOfAgentsPerPage,
            nextStartAfter: `${nextPageRecord.name},${nextPageRecord.id}`,
            prevEndBefore:
              agentsResponse.length > noOfAgentsPerPage
                ? `${prevPageRecord.name},${prevPageRecord.id}`
                : undefined,
          }));
          setAgents(agentsData);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [api, errorHandler, getCurrentPageAgents, noOfAgentsPerPage, params]
  );

  useEffect(() => {
    fetchAgentsVersions(params);
  }, [fetchAgentsVersions, params]);

  const nextPage = () => {
    setParams(prevState => ({
      ...prevState,
      end: undefined,
      start: paginationData.nextStartAfter,
    }));
  };

  const prevPage = () => {
    setParams(prevState => ({
      ...prevState,
      end: paginationData.prevEndBefore,
      start: undefined,
    }));
  };

  return {
    agents,
    isLoading,
    nextPage,
    paginationData,
    prevPage,
    refresh,
  };
};
