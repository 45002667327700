/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterNewOrgRequest
 */
export interface RegisterNewOrgRequest {
    /**
     * The organization name
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    timeZone: RegisterNewOrgRequestTimeZoneEnum;
    /**
     * The organization's legal name
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    governmentIdType?: RegisterNewOrgRequestGovernmentIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    governmentIdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    governingCountry?: RegisterNewOrgRequestGoverningCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    governingState?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    adminFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    adminLastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    adminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    adminPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewOrgRequest
     */
    adminRole?: string;
}


/**
 * @export
 */
export const RegisterNewOrgRequestTimeZoneEnum = {
    PacificHonolulu: 'Pacific/Honolulu',
    AmericaAnchorage: 'America/Anchorage',
    AmericaLosAngeles: 'America/Los_Angeles',
    AmericaPhoenix: 'America/Phoenix',
    AmericaChicago: 'America/Chicago',
    AmericaNewYork: 'America/New_York'
} as const;
export type RegisterNewOrgRequestTimeZoneEnum = typeof RegisterNewOrgRequestTimeZoneEnum[keyof typeof RegisterNewOrgRequestTimeZoneEnum];

/**
 * @export
 */
export const RegisterNewOrgRequestGovernmentIdTypeEnum = {
    Ein: 'EIN',
    LocalBusinessLicense: 'Local business license'
} as const;
export type RegisterNewOrgRequestGovernmentIdTypeEnum = typeof RegisterNewOrgRequestGovernmentIdTypeEnum[keyof typeof RegisterNewOrgRequestGovernmentIdTypeEnum];

/**
 * @export
 */
export const RegisterNewOrgRequestGoverningCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type RegisterNewOrgRequestGoverningCountryEnum = typeof RegisterNewOrgRequestGoverningCountryEnum[keyof typeof RegisterNewOrgRequestGoverningCountryEnum];


/**
 * Check if a given object implements the RegisterNewOrgRequest interface.
 */
export function instanceOfRegisterNewOrgRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "timeZone" in value;
    isInstance = isInstance && "legalName" in value;
    isInstance = isInstance && "adminFirstName" in value;
    isInstance = isInstance && "adminLastName" in value;
    isInstance = isInstance && "adminEmail" in value;

    return isInstance;
}

export function RegisterNewOrgRequestFromJSON(json: any): RegisterNewOrgRequest {
    return RegisterNewOrgRequestFromJSONTyped(json, false);
}

export function RegisterNewOrgRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterNewOrgRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': json['email'],
        'timeZone': json['timeZone'],
        'legalName': json['legalName'],
        'governmentIdType': !exists(json, 'governmentIdType') ? undefined : json['governmentIdType'],
        'governmentIdNumber': !exists(json, 'governmentIdNumber') ? undefined : json['governmentIdNumber'],
        'governingCountry': !exists(json, 'governingCountry') ? undefined : json['governingCountry'],
        'governingState': !exists(json, 'governingState') ? undefined : json['governingState'],
        'adminFirstName': json['adminFirstName'],
        'adminLastName': json['adminLastName'],
        'adminEmail': json['adminEmail'],
        'adminPhone': !exists(json, 'adminPhone') ? undefined : json['adminPhone'],
        'adminRole': !exists(json, 'adminRole') ? undefined : json['adminRole'],
    };
}

export function RegisterNewOrgRequestToJSON(value?: RegisterNewOrgRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'phone': value.phone,
        'email': value.email,
        'timeZone': value.timeZone,
        'legalName': value.legalName,
        'governmentIdType': value.governmentIdType,
        'governmentIdNumber': value.governmentIdNumber,
        'governingCountry': value.governingCountry,
        'governingState': value.governingState,
        'adminFirstName': value.adminFirstName,
        'adminLastName': value.adminLastName,
        'adminEmail': value.adminEmail,
        'adminPhone': value.adminPhone,
        'adminRole': value.adminRole,
    };
}

