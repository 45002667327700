/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EditPolicy200ResponsePolicy } from './EditPolicy200ResponsePolicy';
import {
    EditPolicy200ResponsePolicyFromJSON,
    EditPolicy200ResponsePolicyFromJSONTyped,
    EditPolicy200ResponsePolicyToJSON,
} from './EditPolicy200ResponsePolicy';

/**
 * Updated policy
 * @export
 * @interface EditPolicy200Response
 */
export interface EditPolicy200Response {
    /**
     * 
     * @type {EditPolicy200ResponsePolicy}
     * @memberof EditPolicy200Response
     */
    policy?: EditPolicy200ResponsePolicy;
}

/**
 * Check if a given object implements the EditPolicy200Response interface.
 */
export function instanceOfEditPolicy200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditPolicy200ResponseFromJSON(json: any): EditPolicy200Response {
    return EditPolicy200ResponseFromJSONTyped(json, false);
}

export function EditPolicy200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditPolicy200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy': !exists(json, 'policy') ? undefined : EditPolicy200ResponsePolicyFromJSON(json['policy']),
    };
}

export function EditPolicy200ResponseToJSON(value?: EditPolicy200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy': EditPolicy200ResponsePolicyToJSON(value.policy),
    };
}

