/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';

/**
 * The required inputs to updating a FieldKind.
 * @export
 * @interface FieldKindPatch
 */
export interface FieldKindPatch {
    /**
     * The new name for a FieldKind.
     * @type {string}
     * @memberof FieldKindPatch
     */
    name?: string | null;
    /**
     * The new description for a FieldKind. Since this is an optional field, if the field is missing or set to null, then the field will remain unchanged. If the field is set to empty string, then the new value would be empty string.
     * @type {string}
     * @memberof FieldKindPatch
     */
    description?: string | null;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof FieldKindPatch
     */
    permissions?: Array<PermissionPair>;
    /**
     * A set of statements that defines the completeness or validity of a field or container
     * @type {Array<Atom>}
     * @memberof FieldKindPatch
     */
    ruleValid?: Array<Atom>;
}

/**
 * Check if a given object implements the FieldKindPatch interface.
 */
export function instanceOfFieldKindPatch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FieldKindPatchFromJSON(json: any): FieldKindPatch {
    return FieldKindPatchFromJSONTyped(json, false);
}

export function FieldKindPatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldKindPatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'ruleValid': !exists(json, 'ruleValid') ? undefined : ((json['ruleValid'] as Array<any>).map(AtomFromJSON)),
    };
}

export function FieldKindPatchToJSON(value?: FieldKindPatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'ruleValid': value.ruleValid === undefined ? undefined : ((value.ruleValid as Array<any>).map(AtomToJSON)),
    };
}

