/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SyncFrequency } from './SyncFrequency';
import {
    SyncFrequencyFromJSON,
    SyncFrequencyFromJSONTyped,
    SyncFrequencyToJSON,
} from './SyncFrequency';

/**
 * The properties that must be provided in order to create an integration.
 * 
 * @export
 * @interface CreateIntegration
 */
export interface CreateIntegration {
    /**
     * The name of the integration
     * @type {string}
     * @memberof CreateIntegration
     */
    name: string;
    /**
     * The name of the service type this integration will connect with, such as 'google_drive' or 'google_sheets', 'airtable', etc. See https://fivetran.com/docs/rest-api/connectors/config for the list of services available to connect with.
     * @type {string}
     * @memberof CreateIntegration
     */
    service: string;
    /**
     * The description of the integration
     * @type {string}
     * @memberof CreateIntegration
     */
    description?: string;
    /**
     * Configuration for the connector
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CreateIntegration
     */
    config?: { [key: string]: object; };
    /**
     * The authentication configuration, paired with the service type
     * @type {{ [key: string]: object; }}
     * @memberof CreateIntegration
     */
    auth?: { [key: string]: object; };
    /**
     * Set to true to pause ingesting data from this integration, false to resume
     * @type {boolean}
     * @memberof CreateIntegration
     */
    pause?: boolean | null;
    /**
     * 
     * @type {SyncFrequency}
     * @memberof CreateIntegration
     */
    syncFrequency?: SyncFrequency;
    /**
     * The optional parameter that defines the sync start time when the sync frequency is already set 
     * or being set by the current request to 1440. It can be specified in one hour increments starting 
     * from 00:00 to 23:00.
     * 
     * @type {string}
     * @memberof CreateIntegration
     */
    dailySyncTime?: string;
    /**
     * Connector ID, found in setup after creating a connector in the UI
     * @type {string}
     * @memberof CreateIntegration
     */
    connectorId?: string;
    /**
     * Snowflake database where data should sync to
     * @type {string}
     * @memberof CreateIntegration
     */
    snowflakeDb?: string;
}

/**
 * Check if a given object implements the CreateIntegration interface.
 */
export function instanceOfCreateIntegration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "service" in value;

    return isInstance;
}

export function CreateIntegrationFromJSON(json: any): CreateIntegration {
    return CreateIntegrationFromJSONTyped(json, false);
}

export function CreateIntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIntegration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'service': json['service'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'config': !exists(json, 'config') ? undefined : json['config'],
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
        'pause': !exists(json, 'pause') ? undefined : json['pause'],
        'syncFrequency': !exists(json, 'sync_frequency') ? undefined : SyncFrequencyFromJSON(json['sync_frequency']),
        'dailySyncTime': !exists(json, 'daily_sync_time') ? undefined : json['daily_sync_time'],
        'connectorId': !exists(json, 'connector_id') ? undefined : json['connector_id'],
        'snowflakeDb': !exists(json, 'snowflake_db') ? undefined : json['snowflake_db'],
    };
}

export function CreateIntegrationToJSON(value?: CreateIntegration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'service': value.service,
        'description': value.description,
        'config': value.config,
        'auth': value.auth,
        'pause': value.pause,
        'sync_frequency': SyncFrequencyToJSON(value.syncFrequency),
        'daily_sync_time': value.dailySyncTime,
        'connector_id': value.connectorId,
        'snowflake_db': value.snowflakeDb,
    };
}

