/* tslint:disable */
/* eslint-disable */
/**
 * agents
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CapabilityRestriction } from './CapabilityRestriction';
import {
    CapabilityRestrictionFromJSON,
    CapabilityRestrictionFromJSONTyped,
    CapabilityRestrictionToJSON,
} from './CapabilityRestriction';

/**
 * A capability allows an agent to perform an action on behalf of an entity
 * @export
 * @interface Capability
 */
export interface Capability {
    /**
     * The unique key that identifies this capability, e.g. read-container
     * @type {string}
     * @memberof Capability
     */
    key: string;
    /**
     * A human readable version of key, e.g. Read Container
     * @type {string}
     * @memberof Capability
     */
    name: string;
    /**
     * A description of what this capability allows an agent to do
     * @type {string}
     * @memberof Capability
     */
    description: string;
    /**
     * 
     * @type {Array<CapabilityRestriction>}
     * @memberof Capability
     */
    objects?: Array<CapabilityRestriction>;
}

/**
 * Check if a given object implements the Capability interface.
 */
export function instanceOfCapability(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function CapabilityFromJSON(json: any): Capability {
    return CapabilityFromJSONTyped(json, false);
}

export function CapabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Capability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'description': json['description'],
        'objects': !exists(json, 'objects') ? undefined : ((json['objects'] as Array<any>).map(CapabilityRestrictionFromJSON)),
    };
}

export function CapabilityToJSON(value?: Capability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'description': value.description,
        'objects': value.objects === undefined ? undefined : ((value.objects as Array<any>).map(CapabilityRestrictionToJSON)),
    };
}

