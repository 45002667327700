/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Container } from './Container';
import {
    ContainerFromJSON,
    ContainerFromJSONTyped,
    ContainerToJSON,
} from './Container';
import type { PaginationResponseCursor } from './PaginationResponseCursor';
import {
    PaginationResponseCursorFromJSON,
    PaginationResponseCursorFromJSONTyped,
    PaginationResponseCursorToJSON,
} from './PaginationResponseCursor';

/**
 * An object that contains a list of containers.
 * @export
 * @interface ContainerList
 */
export interface ContainerList {
    /**
     * The list of containers.
     * @type {Array<Container>}
     * @memberof ContainerList
     */
    containers?: Array<Container>;
    /**
     * Indicates whether more data is available after incrementing the offset
     * @type {boolean}
     * @memberof ContainerList
     */
    hasMore?: boolean;
    /**
     * 
     * @type {PaginationResponseCursor}
     * @memberof ContainerList
     */
    cursor?: PaginationResponseCursor;
}

/**
 * Check if a given object implements the ContainerList interface.
 */
export function instanceOfContainerList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContainerListFromJSON(json: any): ContainerList {
    return ContainerListFromJSONTyped(json, false);
}

export function ContainerListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containers': !exists(json, 'containers') ? undefined : ((json['containers'] as Array<any>).map(ContainerFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !exists(json, 'cursor') ? undefined : PaginationResponseCursorFromJSON(json['cursor']),
    };
}

export function ContainerListToJSON(value?: ContainerList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containers': value.containers === undefined ? undefined : ((value.containers as Array<any>).map(ContainerToJSON)),
        'hasMore': value.hasMore,
        'cursor': PaginationResponseCursorToJSON(value.cursor),
    };
}

