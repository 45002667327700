// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { type FC, useState } from "react";
import { FieldArray, useFormikContext } from "formik";
import { RemoveModal, type RemoveModalState } from "./RemoveModal";
import { RuleCombinationTypeLabel } from "./RuleCombinationTypeLabel";
import { SectionTemplateRule } from "./SectionTemplateRule";
import { SelectField } from "./SelectField";
import { StyleSheet, View } from "react-native";
import { newTemplateRule } from "../utils";
import type { Option, Values } from "../types";

type SectionTemplateRulesProps = unknown;

export const SectionTemplateRules: FC<SectionTemplateRulesProps> = () => {
  const { theme } = useTheme();
  const [removeModal, setRemoveModal] = useState<RemoveModalState>();
  const ctx = useFormikContext<Values>();

  const styles = StyleSheet.create({
    addAnotherRule: { width: 200 },
    container: {
      gap: theme.spacing.xxl,
    },
    noRulesContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.background.default,
      borderRadius: theme.borderRadii.xl,
      justifyContent: "center",
      minHeight: 200,
    },
    ruleCombinationType: { alignItems: "center", flexDirection: "row" },
    rulesContainer: {
      minHeight: 200,
    },
  });

  return (
    <View style={styles.container}>
      <Heading bold level="3">
        Members will pass this policy if…
      </Heading>
      {ctx.values.templateRules.length > 1 && (
        <View style={styles.ruleCombinationType}>
          <Body size="l" style={{ marginRight: theme.spacing.l }}>
            they meet
          </Body>
          <SelectField
            label="They Meet"
            name="ruleCombinationType"
            options={
              [
                { label: "all", value: "all" },
                { label: "any", value: "any" },
              ] as readonly Option[]
            }
            placeholder={{
              label: ctx.values.ruleCombinationType,
              value: ctx.values.ruleCombinationType,
            }}
            showLabel={false}
            usePortal
          />
          <Body size="l" style={{ marginLeft: theme.spacing.l }}>
            of the following rules:
          </Body>
        </View>
      )}

      <View
        style={
          ctx.values.templateRules.length === 0 ? styles.noRulesContainer : styles.rulesContainer
        }
      >
        <FieldArray name="templateRules">
          {arr => (
            <>
              {ctx.values.templateRules.length === 0 && (
                <Button
                  iconLeft="addSmallDefault"
                  onPress={() => {
                    arr.push(newTemplateRule());
                  }}
                  text="Add rule"
                />
              )}
              <View>
                {ctx.values.templateRules.map((rule, idx) => (
                  <View key={rule.id} style={{ flexDirection: "row" }}>
                    <SectionTemplateRule key={rule.id} templateRuleIdx={idx} />
                    <Button
                      disabled={ctx.values.templateRules.length === 1}
                      iconLeft="trash"
                      onPress={() => {
                        setRemoveModal({
                          onContinue: () => {
                            arr.remove(idx);
                          },
                        });
                      }}
                      shape="circle"
                      style={{
                        marginLeft: theme.spacing.xl,
                        marginTop: theme.spacing.xxl,
                      }}
                      type="destructive"
                    />
                    {idx !== 0 && (
                      <RuleCombinationTypeLabel type={ctx.values.ruleCombinationType} />
                    )}
                  </View>
                ))}
                {ctx.values.templateRules.length > 0 && (
                  <View style={styles.addAnotherRule}>
                    <Button
                      iconLeft="addSmallDefault"
                      onPress={() => {
                        arr.push(newTemplateRule());
                      }}
                      text="Add another rule"
                      type="secondary"
                    />
                  </View>
                )}
                {removeModal !== undefined && (
                  <RemoveModal
                    onPressCancel={() => {
                      setRemoveModal(undefined);
                    }}
                    onPressContinue={() => {
                      removeModal.onContinue?.();
                      setRemoveModal(undefined);
                    }}
                    type="rule"
                  />
                )}
              </View>
            </>
          )}
        </FieldArray>
      </View>
    </View>
  );
};
