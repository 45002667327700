/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPolicyStatistics200Response
 */
export interface GetPolicyStatistics200Response {
    /**
     * 
     * @type {number}
     * @memberof GetPolicyStatistics200Response
     */
    pending: number;
    /**
     * 
     * @type {number}
     * @memberof GetPolicyStatistics200Response
     */
    complete: number;
    /**
     * 
     * @type {number}
     * @memberof GetPolicyStatistics200Response
     */
    successful: number;
    /**
     * 
     * @type {number}
     * @memberof GetPolicyStatistics200Response
     */
    failed: number;
}

/**
 * Check if a given object implements the GetPolicyStatistics200Response interface.
 */
export function instanceOfGetPolicyStatistics200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pending" in value;
    isInstance = isInstance && "complete" in value;
    isInstance = isInstance && "successful" in value;
    isInstance = isInstance && "failed" in value;

    return isInstance;
}

export function GetPolicyStatistics200ResponseFromJSON(json: any): GetPolicyStatistics200Response {
    return GetPolicyStatistics200ResponseFromJSONTyped(json, false);
}

export function GetPolicyStatistics200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPolicyStatistics200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pending': json['pending'],
        'complete': json['complete'],
        'successful': json['successful'],
        'failed': json['failed'],
    };
}

export function GetPolicyStatistics200ResponseToJSON(value?: GetPolicyStatistics200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pending': value.pending,
        'complete': value.complete,
        'successful': value.successful,
        'failed': value.failed,
    };
}

