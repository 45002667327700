/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateTemplateFields201ResponseFieldsInner } from './CreateTemplateFields201ResponseFieldsInner';
import {
    CreateTemplateFields201ResponseFieldsInnerFromJSON,
    CreateTemplateFields201ResponseFieldsInnerFromJSONTyped,
    CreateTemplateFields201ResponseFieldsInnerToJSON,
} from './CreateTemplateFields201ResponseFieldsInner';

/**
 * 
 * @export
 * @interface CreateTemplateFields201Response
 */
export interface CreateTemplateFields201Response {
    /**
     * 
     * @type {string}
     * @memberof CreateTemplateFields201Response
     */
    orgID: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTemplateFields201Response
     */
    templateID: string;
    /**
     * 
     * @type {Array<CreateTemplateFields201ResponseFieldsInner>}
     * @memberof CreateTemplateFields201Response
     */
    fields: Array<CreateTemplateFields201ResponseFieldsInner>;
}

/**
 * Check if a given object implements the CreateTemplateFields201Response interface.
 */
export function instanceOfCreateTemplateFields201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgID" in value;
    isInstance = isInstance && "templateID" in value;
    isInstance = isInstance && "fields" in value;

    return isInstance;
}

export function CreateTemplateFields201ResponseFromJSON(json: any): CreateTemplateFields201Response {
    return CreateTemplateFields201ResponseFromJSONTyped(json, false);
}

export function CreateTemplateFields201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTemplateFields201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgID': json['OrgID'],
        'templateID': json['TemplateID'],
        'fields': ((json['Fields'] as Array<any>).map(CreateTemplateFields201ResponseFieldsInnerFromJSON)),
    };
}

export function CreateTemplateFields201ResponseToJSON(value?: CreateTemplateFields201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OrgID': value.orgID,
        'TemplateID': value.templateID,
        'Fields': ((value.fields as Array<any>).map(CreateTemplateFields201ResponseFieldsInnerToJSON)),
    };
}

