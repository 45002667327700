// Copyright 2023 Merit International Inc. All Rights Reserved

import { useEffect, useState } from "react";
import type { DefaultApi } from "../gen/org-portal/apis/DefaultApi";
import type { GetLinkedOrgs200ResponseLinkedOrgsInner } from "../gen/org-portal";

// Hook which caches the result of the getLinkedOrgIDs call since it is
// fairly expensive and used commonly in the app.
type LinkedOrgsMap = Record<string, readonly GetLinkedOrgs200ResponseLinkedOrgsInner[]>;

export const useLinkedOrgs = (api: DefaultApi, selectedOrgId: string | null) => {
  const [linkedOrgsMap, setLinkedOrgsMap] = useState<LinkedOrgsMap>({});

  useEffect(() => {
    const getLinkedOrgs = async () => {
      if (selectedOrgId === null) {
        return;
      }

      if (selectedOrgId in linkedOrgsMap) {
        return;
      }

      const res = await api.getLinkedOrgs({ orgID: selectedOrgId });

      setLinkedOrgsMap({
        ...linkedOrgsMap,
        [selectedOrgId]: res.linkedOrgs,
      });
    };

    getLinkedOrgs();
  }, [api, linkedOrgsMap, selectedOrgId]);

  return { linkedOrgsMap };
};
