/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BaseSuccess } from './BaseSuccess';
import {
    instanceOfBaseSuccess,
    BaseSuccessFromJSON,
    BaseSuccessFromJSONTyped,
    BaseSuccessToJSON,
} from './BaseSuccess';
import type { TemplatesTemplateIDBaseSuccessOneOf } from './TemplatesTemplateIDBaseSuccessOneOf';
import {
    instanceOfTemplatesTemplateIDBaseSuccessOneOf,
    TemplatesTemplateIDBaseSuccessOneOfFromJSON,
    TemplatesTemplateIDBaseSuccessOneOfFromJSONTyped,
    TemplatesTemplateIDBaseSuccessOneOfToJSON,
} from './TemplatesTemplateIDBaseSuccessOneOf';

/**
 * @type TemplatesTemplateIDBaseSuccess
 * 
 * @export
 */
export type TemplatesTemplateIDBaseSuccess = BaseSuccess | TemplatesTemplateIDBaseSuccessOneOf;

export function TemplatesTemplateIDBaseSuccessFromJSON(json: any): TemplatesTemplateIDBaseSuccess {
    return TemplatesTemplateIDBaseSuccessFromJSONTyped(json, false);
}

export function TemplatesTemplateIDBaseSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplatesTemplateIDBaseSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...BaseSuccessFromJSONTyped(json, true), ...TemplatesTemplateIDBaseSuccessOneOfFromJSONTyped(json, true) };
}

export function TemplatesTemplateIDBaseSuccessToJSON(value?: TemplatesTemplateIDBaseSuccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfBaseSuccess(value)) {
        return BaseSuccessToJSON(value as BaseSuccess);
    }
    if (instanceOfTemplatesTemplateIDBaseSuccessOneOf(value)) {
        return TemplatesTemplateIDBaseSuccessOneOfToJSON(value as TemplatesTemplateIDBaseSuccessOneOf);
    }

    return {};
}

