/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Mapping of a template field to a column, as represented on a datasource mapping body.
 * @export
 * @interface EditTemplateMap200ResponseColumnToTemplateFieldInner
 */
export interface EditTemplateMap200ResponseColumnToTemplateFieldInner {
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMap200ResponseColumnToTemplateFieldInner
     */
    templateFieldID: string;
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMap200ResponseColumnToTemplateFieldInner
     */
    columnID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMap200ResponseColumnToTemplateFieldInner
     */
    containerID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditTemplateMap200ResponseColumnToTemplateFieldInner
     */
    containerFieldID?: string | null;
}

/**
 * Check if a given object implements the EditTemplateMap200ResponseColumnToTemplateFieldInner interface.
 */
export function instanceOfEditTemplateMap200ResponseColumnToTemplateFieldInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateFieldID" in value;

    return isInstance;
}

export function EditTemplateMap200ResponseColumnToTemplateFieldInnerFromJSON(json: any): EditTemplateMap200ResponseColumnToTemplateFieldInner {
    return EditTemplateMap200ResponseColumnToTemplateFieldInnerFromJSONTyped(json, false);
}

export function EditTemplateMap200ResponseColumnToTemplateFieldInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTemplateMap200ResponseColumnToTemplateFieldInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateFieldID': json['templateFieldID'],
        'columnID': !exists(json, 'columnID') ? undefined : json['columnID'],
        'containerID': !exists(json, 'containerID') ? undefined : json['containerID'],
        'containerFieldID': !exists(json, 'containerFieldID') ? undefined : json['containerFieldID'],
    };
}

export function EditTemplateMap200ResponseColumnToTemplateFieldInnerToJSON(value?: EditTemplateMap200ResponseColumnToTemplateFieldInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateFieldID': value.templateFieldID,
        'columnID': value.columnID,
        'containerID': value.containerID,
        'containerFieldID': value.containerFieldID,
    };
}

