/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';

/**
 * Column DataType corresponding to Date data.
 * @export
 * @interface ModelDate
 */
export interface ModelDate extends DataType {
    /**
     * The string describing the format the underlying dates will be in.
     * @type {string}
     * @memberof ModelDate
     */
    format: string;
}

/**
 * Check if a given object implements the ModelDate interface.
 */
export function instanceOfModelDate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "format" in value;

    return isInstance;
}

export function ModelDateFromJSON(json: any): ModelDate {
    return ModelDateFromJSONTyped(json, false);
}

export function ModelDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...DataTypeFromJSONTyped(json, ignoreDiscriminator),
        'format': json['format'],
    };
}

export function ModelDateToJSON(value?: ModelDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DataTypeToJSON(value),
        'format': value.format,
    };
}

