/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
} from '../models/index';

export interface GetMergeRequestRequest {
    id: string;
}

/**
 * 
 */
export class MergeRequestsApi extends runtime.BaseAPI {

    /**
     * Create Merge Request (Deprecated)
     */
    async createMergeRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/merge-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Merge Request (Deprecated)
     */
    async createMergeRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createMergeRequestRaw(initOverrides);
    }

    /**
     * Retrieve Merge Request (Deprecated)
     */
    async getMergeRequestRaw(requestParameters: GetMergeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMergeRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/merge-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retrieve Merge Request (Deprecated)
     */
    async getMergeRequest(requestParameters: GetMergeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getMergeRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve Merge Requests (Deprecated)
     */
    async getMergeRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/merge-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retrieve Merge Requests (Deprecated)
     */
    async getMergeRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getMergeRequestsRaw(initOverrides);
    }

}
