/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatasourceVisibility200Response
 */
export interface DatasourceVisibility200Response {
    /**
     * 
     * @type {string}
     * @memberof DatasourceVisibility200Response
     */
    datasourceID?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasourceVisibility200Response
     */
    orgId?: string;
}

/**
 * Check if a given object implements the DatasourceVisibility200Response interface.
 */
export function instanceOfDatasourceVisibility200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DatasourceVisibility200ResponseFromJSON(json: any): DatasourceVisibility200Response {
    return DatasourceVisibility200ResponseFromJSONTyped(json, false);
}

export function DatasourceVisibility200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceVisibility200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datasourceID': !exists(json, 'datasourceID') ? undefined : json['datasourceID'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
    };
}

export function DatasourceVisibility200ResponseToJSON(value?: DatasourceVisibility200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datasourceID': value.datasourceID,
        'orgId': value.orgId,
    };
}

