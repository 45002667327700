// Copyright 2024 Merit International Inc. All Rights Reserved

import { useAuthStore } from "@src/stores";
import { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useLogout } from "@src/hooks/auth";

export const AuthChangedLogoutContainer = (): JSX.Element => {
  const ldClient = useLDClient();
  const { accessToken } = useAuthStore();
  const { logout } = useLogout();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const listener = () => {
      logout();
    };
    if (accessToken !== null) {
      ldClient?.on(`change:rolloutAdminPortalAuth0OrgAuth`, listener);

      return () => {
        ldClient?.off("change", listener);
      };
    }
  }, [accessToken, ldClient, logout]);

  return <div />;
};
