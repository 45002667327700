// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body } from "@merit/frontend-components";
import { View } from "react-native";
import React from "react";

type Props = {
  readonly name: string;
};

export const Breadcrumb = ({ name }: Props) => (
  <View>
    <Body>{name}</Body>
  </View>
);
