// Copyright 2023 Merit International Inc. All Rights Reserved

import { StyleSheet } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

type DatasourceDetailsStyles = {
  readonly buttonsContainer: ViewStyle;
  readonly container: ViewStyle;
  readonly heading: ViewStyle;
  readonly subHeader: ViewStyle;
  readonly tabContainer: ViewStyle;
};

type DatasourceDetailsSchemaTabStyles = {
  readonly badge: ViewStyle;
};

export const useDatasourceDetailsScreenStyles = () => {
  const { theme } = useTheme();

  return StyleSheet.create<DatasourceDetailsStyles>({
    buttonsContainer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      paddingTop: theme.spacing.xxl,
    },
    heading: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    subHeader: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginTop: theme.spacing.s,
    },
    tabContainer: {
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      paddingHorizontal: 32,
    },
  });
};

export const useDatasourceDetailsSchemaTabStyles = () => {
  const { theme } = useTheme();

  return StyleSheet.create<DatasourceDetailsSchemaTabStyles>({
    badge: {
      backgroundColor: theme.colors.action.default,
      borderRadius: theme.spacing.xxl,
      justifyContent: "center",
      minHeight: 20,
      paddingHorizontal: 6,
    },
  });
};
