/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  TemplateField,
  TemplateFieldPatch,
  TemplateFieldProperties,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    TemplateFieldFromJSON,
    TemplateFieldToJSON,
    TemplateFieldPatchFromJSON,
    TemplateFieldPatchToJSON,
    TemplateFieldPropertiesFromJSON,
    TemplateFieldPropertiesToJSON,
} from '../models/index';

export interface AddTemplateFieldRequest {
    templateID: string;
    authorization?: string;
    properties?: TemplateFieldProperties;
}

export interface ReorderTemplateFieldsRequest {
    templateID: string;
    authorization?: string;
    newFieldOrder?: Array<string>;
}

export interface UpdateTemplateFieldRequest {
    templateID: string;
    templateFieldID: string;
    authorization?: string;
    properties?: TemplateFieldPatch;
}

/**
 * 
 */
export class TemplateFieldsApi extends runtime.BaseAPI {

    /**
     * `update:template`  Add a template field to a given template 
     * Add Template Field
     * @deprecated
     */
    async addTemplateFieldRaw(requestParameters: AddTemplateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateField>> {
        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling addTemplateField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates/{templateID}/fields`.replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateFieldPropertiesToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFieldFromJSON(jsonValue));
    }

    /**
     * `update:template`  Add a template field to a given template 
     * Add Template Field
     * @deprecated
     */
    async addTemplateField(requestParameters: AddTemplateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateField> {
        const response = await this.addTemplateFieldRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:template`  Reorder the fields on a template 
     * Reorder Template Fields
     */
    async reorderTemplateFieldsRaw(requestParameters: ReorderTemplateFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateField>>> {
        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling reorderTemplateFields.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates/{templateID}/fields`.replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.newFieldOrder,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateFieldFromJSON));
    }

    /**
     * `update:template`  Reorder the fields on a template 
     * Reorder Template Fields
     */
    async reorderTemplateFields(requestParameters: ReorderTemplateFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateField>> {
        const response = await this.reorderTemplateFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:template`  Update an existing template field 
     * Update Template Field
     * @deprecated
     */
    async updateTemplateFieldRaw(requestParameters: UpdateTemplateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateField>> {
        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling updateTemplateField.');
        }

        if (requestParameters.templateFieldID === null || requestParameters.templateFieldID === undefined) {
            throw new runtime.RequiredError('templateFieldID','Required parameter requestParameters.templateFieldID was null or undefined when calling updateTemplateField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/templates/{templateID}/fields/{templateFieldID}`.replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))).replace(`{${"templateFieldID"}}`, encodeURIComponent(String(requestParameters.templateFieldID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateFieldPatchToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFieldFromJSON(jsonValue));
    }

    /**
     * `update:template`  Update an existing template field 
     * Update Template Field
     * @deprecated
     */
    async updateTemplateField(requestParameters: UpdateTemplateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateField> {
        const response = await this.updateTemplateFieldRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
