/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDatasourceRequestSchemaColumnsInner } from './CreateDatasourceRequestSchemaColumnsInner';
import {
    CreateDatasourceRequestSchemaColumnsInnerFromJSON,
    CreateDatasourceRequestSchemaColumnsInnerFromJSONTyped,
    CreateDatasourceRequestSchemaColumnsInnerToJSON,
} from './CreateDatasourceRequestSchemaColumnsInner';
import type { ListDatasources200ResponseDatasourcesInnerSchema } from './ListDatasources200ResponseDatasourcesInnerSchema';
import {
    ListDatasources200ResponseDatasourcesInnerSchemaFromJSON,
    ListDatasources200ResponseDatasourcesInnerSchemaFromJSONTyped,
    ListDatasources200ResponseDatasourcesInnerSchemaToJSON,
} from './ListDatasources200ResponseDatasourcesInnerSchema';

/**
 * 
 * @export
 * @interface CreateDatasource201Response
 */
export interface CreateDatasource201Response {
    /**
     * 
     * @type {number}
     * @memberof CreateDatasource201Response
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasource201Response
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasource201Response
     */
    description?: string;
    /**
     * 
     * @type {Array<CreateDatasourceRequestSchemaColumnsInner>}
     * @memberof CreateDatasource201Response
     */
    schemaColumns?: Array<CreateDatasourceRequestSchemaColumnsInner>;
    /**
     * 
     * @type {ListDatasources200ResponseDatasourcesInnerSchema}
     * @memberof CreateDatasource201Response
     */
    schemaDelimiter?: ListDatasources200ResponseDatasourcesInnerSchema;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasource201Response
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasource201Response
     */
    integrationType?: string;
}

/**
 * Check if a given object implements the CreateDatasource201Response interface.
 */
export function instanceOfCreateDatasource201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateDatasource201ResponseFromJSON(json: any): CreateDatasource201Response {
    return CreateDatasource201ResponseFromJSONTyped(json, false);
}

export function CreateDatasource201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDatasource201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'schemaColumns': !exists(json, 'schemaColumns') ? undefined : ((json['schemaColumns'] as Array<any>).map(CreateDatasourceRequestSchemaColumnsInnerFromJSON)),
        'schemaDelimiter': !exists(json, 'schemaDelimiter') ? undefined : ListDatasources200ResponseDatasourcesInnerSchemaFromJSON(json['schemaDelimiter']),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'integrationType': !exists(json, 'integrationType') ? undefined : json['integrationType'],
    };
}

export function CreateDatasource201ResponseToJSON(value?: CreateDatasource201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'schemaColumns': value.schemaColumns === undefined ? undefined : ((value.schemaColumns as Array<any>).map(CreateDatasourceRequestSchemaColumnsInnerToJSON)),
        'schemaDelimiter': ListDatasources200ResponseDatasourcesInnerSchemaToJSON(value.schemaDelimiter),
        'createdAt': value.createdAt,
        'integrationType': value.integrationType,
    };
}

