/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * The Admin API represents all functionality that a Design Huddle client administrator may perform. Most API methods do not require a specific user context and can be accessed using an App Access Token for authorization, but some methods must run on behalf of a specific user and therefore must use a User Access Token. The most common integration use cases consist of template management/synchronization and updating/approving/exporting user-created design projects. Read more about authentication <a target=\"_parent\" href=\"https://api.designhuddle.com#authentication\">here</a> and embedding the Design Huddle editor <a target=\"_parent\" href=\"https://api.designhuddle.com#embedding\">here</a>.
 *
 * The version of the OpenAPI document: 2023-09-18T21:46:45Z
 * Contact: support@designhuddle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectsProjectIDExportPOSTRequestOneOf4
 */
export interface ProjectsProjectIDExportPOSTRequestOneOf4 {
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf4
     */
    format: ProjectsProjectIDExportPOSTRequestOneOf4FormatEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf4
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf4
     */
    sceneId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf4
     */
    sceneIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf4
     */
    sceneSlideId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf4
     */
    sceneSlideIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectsProjectIDExportPOSTRequestOneOf4
     */
    notificationUrl?: string;
}


/**
 * @export
 */
export const ProjectsProjectIDExportPOSTRequestOneOf4FormatEnum = {
    Pdf: 'pdf'
} as const;
export type ProjectsProjectIDExportPOSTRequestOneOf4FormatEnum = typeof ProjectsProjectIDExportPOSTRequestOneOf4FormatEnum[keyof typeof ProjectsProjectIDExportPOSTRequestOneOf4FormatEnum];


/**
 * Check if a given object implements the ProjectsProjectIDExportPOSTRequestOneOf4 interface.
 */
export function instanceOfProjectsProjectIDExportPOSTRequestOneOf4(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "format" in value;

    return isInstance;
}

export function ProjectsProjectIDExportPOSTRequestOneOf4FromJSON(json: any): ProjectsProjectIDExportPOSTRequestOneOf4 {
    return ProjectsProjectIDExportPOSTRequestOneOf4FromJSONTyped(json, false);
}

export function ProjectsProjectIDExportPOSTRequestOneOf4FromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsProjectIDExportPOSTRequestOneOf4 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': json['format'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'sceneId': !exists(json, 'scene_id') ? undefined : json['scene_id'],
        'sceneIds': !exists(json, 'scene_ids') ? undefined : json['scene_ids'],
        'sceneSlideId': !exists(json, 'scene_slide_id') ? undefined : json['scene_slide_id'],
        'sceneSlideIds': !exists(json, 'scene_slide_ids') ? undefined : json['scene_slide_ids'],
        'notificationUrl': !exists(json, 'notification_url') ? undefined : json['notification_url'],
    };
}

export function ProjectsProjectIDExportPOSTRequestOneOf4ToJSON(value?: ProjectsProjectIDExportPOSTRequestOneOf4 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': value.format,
        'filename': value.filename,
        'scene_id': value.sceneId,
        'scene_ids': value.sceneIds,
        'scene_slide_id': value.sceneSlideId,
        'scene_slide_ids': value.sceneSlideIds,
        'notification_url': value.notificationUrl,
    };
}

