/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTemplateRules200ResponseRulesBoolFieldHasValue } from './GetTemplateRules200ResponseRulesBoolFieldHasValue';
import {
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON,
    GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSONTyped,
    GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON,
} from './GetTemplateRules200ResponseRulesBoolFieldHasValue';

/**
 * 
 * @export
 * @interface GetTemplateRules200ResponseRulesMarkdown
 */
export interface GetTemplateRules200ResponseRulesMarkdown {
    /**
     * 
     * @type {GetTemplateRules200ResponseRulesBoolFieldHasValue}
     * @memberof GetTemplateRules200ResponseRulesMarkdown
     */
    fieldHasValue?: GetTemplateRules200ResponseRulesBoolFieldHasValue;
}

/**
 * Check if a given object implements the GetTemplateRules200ResponseRulesMarkdown interface.
 */
export function instanceOfGetTemplateRules200ResponseRulesMarkdown(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetTemplateRules200ResponseRulesMarkdownFromJSON(json: any): GetTemplateRules200ResponseRulesMarkdown {
    return GetTemplateRules200ResponseRulesMarkdownFromJSONTyped(json, false);
}

export function GetTemplateRules200ResponseRulesMarkdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateRules200ResponseRulesMarkdown {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldHasValue': !exists(json, 'FieldHasValue') ? undefined : GetTemplateRules200ResponseRulesBoolFieldHasValueFromJSON(json['FieldHasValue']),
    };
}

export function GetTemplateRules200ResponseRulesMarkdownToJSON(value?: GetTemplateRules200ResponseRulesMarkdown | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FieldHasValue': GetTemplateRules200ResponseRulesBoolFieldHasValueToJSON(value.fieldHasValue),
    };
}

