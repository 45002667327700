import { Helpers } from "@merit/frontend-utils";
import { useRoute } from "@react-navigation/native";

// TODO: reconcile this with TestProps.ts#OPTestProps?
export type OPTestPropsWithOmission = Omit<Helpers.TestProps, "androidPrefix" | "screenName">;

/** Simple wrapper to encapsulate `screenName for `generateTestIdProps` */
export const useGetTestProps = () => {
  const route = useRoute();

  return (testProps?: OPTestPropsWithOmission, options?: Helpers.GenerateTestIdPropsOptions) =>
    Helpers.generateTestIdProps(
      {
        ...(testProps ?? ({} as OPTestPropsWithOmission)),
        screenName: route.name,
      },
      options
    );
};
