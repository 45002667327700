// Copyright 2024 Merit International Inc. All Rights Reserved

import { MenuLink } from "./MenuLink";
import { VerticalSpacer } from "../Spacer";
import { View } from "react-native";
import { useAcceptedFolioStore, useAuthStore } from "@src/stores";
import { useLoggedInUserRoles } from "@src/hooks/loggedInUserRoles";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";

export const OrganizationMenu = () => {
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const { setSelectedOrgId, setSelectedOrgName, userCanRegisterOrgs } = useAuthStore();
  const { clear: clearAcceptedAccountFolioStore } = useAcceptedFolioStore();
  const { isOrgAdmin } = useLoggedInUserRoles();

  const handlePressOrgSwitch = () => {
    setSelectedOrgName(null);
    setSelectedOrgId(null);
    clearAcceptedAccountFolioStore();
    navigation.navigate("OrgSelect");
  };

  return (
    <View
      style={{
        backgroundColor: theme.colors.background.white,
        borderRadius: 4,
        minWidth: 365,
        paddingHorizontal: 24,
        paddingVertical: 16,
      }}
    >
      {isOrgAdmin && (
        <>
          <MenuLink
            onPress={() => {
              navigation.navigate("OrgSettings", { initialTab: "organizationProfile" });
            }}
            size="large"
            subtext="Manage your profile"
            text="Profile"
          />

          <MenuLink
            onPress={() => {
              navigation.navigate("OrgSettings", { initialTab: "adminManagement" });
            }}
            size="large"
            subtext="Manage admin access and permissions"
            text="Admins"
          />

          <MenuLink
            onPress={() => {
              navigation.navigate("OrgSettings", { initialTab: "connectedOrganizations" });
            }}
            size="large"
            subtext="Manage your organization's connections"
            text="Connected Organizations"
          />

          <MenuLink
            onPress={() => {
              navigation.navigate("OrgSettings", { initialTab: "linkedApps" });
            }}
            size="large"
            subtext="Manage apps"
            text="Linked Apps"
          />

          <VerticalSpacer size={8} />
          <View style={{ backgroundColor: theme.colors.border.subdued, height: 1, width: 104 }} />
          <VerticalSpacer size={8} />

          {userCanRegisterOrgs && (
            <MenuLink
              onPress={() => {
                navigation.navigate("OrgRegistration");
              }}
              size="oneline"
              subtext=""
              text="Create an organization"
            />
          )}
        </>
      )}

      <MenuLink
        onPress={handlePressOrgSwitch}
        size="oneline"
        subtext=""
        text="Switch organizations"
      />
    </View>
  );
};
